import React from 'react'
import { Checkbox, InputLabel, styled } from '@mui/material'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAuth } from '@clerk/nextjs'
import {
  ModalContainer,
  ModalRow,
  FullColumn,
  ModalButton,
  ModalTitle,
  ModalFullTextField,
  ModalButtonRow,
  ErrorMessage,
  ModalFullTextArea,
} from '@/components'
import { type ChartTemplate } from '@/types/ChartTemplate'
import {
  useMutateAddChartTemplate,
  useMutateUpdateChartTemplate,
} from '@/hook'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  describe: Yup.string().required('Description is required'),
})

interface AddTemplateForm {
  name: string;
  describe: string;
  hidden: boolean;
  public: boolean;
}

const CheckBoxRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: '-12px',
})

const CheckBoxContainer = styled('div')({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  marginLeft: '-12px',
})

export const AddTemplate = ({
  handleClose,
  exisitingTemplate = null,
  refresh,
}: AddTemplateProps): JSX.Element => {
  const { getToken } = useAuth()
  const addNewChartTemplateMutation = useMutateAddChartTemplate(getToken)
  const updateChartTemplateMutation = useMutateUpdateChartTemplate(getToken)

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<AddTemplateForm>({
    resolver: yupResolver(validationSchema),
    ...(exisitingTemplate !== null && {
      defaultValues: {
        name: exisitingTemplate.templateName ?? '',
        describe: exisitingTemplate.templateDescription ?? '',
        public: exisitingTemplate.templateIsPublic,
      },
    }),
  })

  const handleReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault()
    reset()
    handleClose()
  }

  const onSubmit: SubmitHandler<AddTemplateForm> = (data): void => {
    let status = true
    if (data.public !== undefined) {
      status = data.public
    }
    const template: ChartTemplate = {
      templateName: data.name,
      templateDescription: data.describe,
      templateIsPublic: status,
      id: exisitingTemplate?.id ?? 0,
      templateIsHidden: false,
      templateOriginalTemplateId:
        exisitingTemplate?.templateOriginalTemplateId ?? 0,
    }

    const response =
      exisitingTemplate === null
        ? addNewChartTemplateMutation.mutateAsync({ chartTemplate: template })
        : updateChartTemplateMutation.mutateAsync({
            chartTemplateId: template?.id!,
            chartTemplate: template,
          })
    // eslint-disable-line
    Promise.resolve(response)
      .then(() => refresh())
      .catch((error) => {
        throw error
      })
      .finally(() => {
        handleClose()
      })
  }
  return (
    <ModalContainer>
      <form
        style={{ width: '100%' }}
        onSubmit={(...args): void => {
          handleSubmit(onSubmit)(...args).catch((error) => {
            throw error
          })
        }}
      >
        <ModalTitle>
          {exisitingTemplate === null ? 'Add Template' : 'Edit Template'}
        </ModalTitle>
        <ModalRow>
          <FullColumn>
            <InputLabel>Name</InputLabel>
            <ModalFullTextField name="name" register={register} />
            {errors.name !== null && errors.name !== undefined ? (
              <ErrorMessage error={errors.name.message ?? ''} />
            ) : (
              <></>
            )}
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <InputLabel>Description</InputLabel>
            <ModalFullTextArea name="describe" register={register} />
            {errors.describe !== null && errors.describe !== undefined ? (
              <ErrorMessage error={errors.describe.message ?? ''} />
            ) : (
              <></>
            )}
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <InputLabel>Status</InputLabel>
            <CheckBoxContainer>
              <CheckBoxRow>
                <Controller
                  name="public"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      defaultChecked={
                        exisitingTemplate !== null
                          ? exisitingTemplate?.templateIsPublic
                          : true
                      }
                      {...field}
                    />
                  )}
                />
                <InputLabel>Public</InputLabel>
              </CheckBoxRow>
            </CheckBoxContainer>
          </FullColumn>
        </ModalRow>
        <ModalButtonRow>
          <ModalButton
            variant="outlined"
            type="reset"
            onClick={(event) => handleReset(event)}
          >
            Cancel
          </ModalButton>
          <ModalButton variant="contained" type="submit">
            {exisitingTemplate === null ? 'Add' : 'Save'}
          </ModalButton>
        </ModalButtonRow>
      </form>
    </ModalContainer>
  )
}

interface AddTemplateProps {
  handleClose: any;
  exisitingTemplate?: ChartTemplate | null;
  refresh: any;
}
