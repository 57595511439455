import { Button, styled } from '@mui/material'
import React from 'react'

const StyledButton = styled(Button)({
  height: '32px',
  width: 'fit-content',
  maxWidth: '160px',
  minWidth: '74px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
})

export const ModalButton = ({
  children,
  onClick,
  variant,
  type,
  dataTestId,
}: ModalButtonProps): JSX.Element => {
  return (
    <StyledButton
      variant={variant}
      onClick={onClick}
      type={type}
      data-testid={dataTestId}
    >
      {children}
    </StyledButton>
  )
}

interface ModalButtonProps {
  children: string | null | undefined;
  onClick?: ((event: any) => void) | undefined;
  variant: 'text' | 'outlined' | 'contained';
  type?: 'button' | 'submit' | 'reset' | undefined;
  dataTestId?: string | undefined;
  /* prettier-ignore */
  color?:
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | undefined
}
