import React from 'react'
import {
  PopoverTileText,
  PopoverValueText,
  PopoverContainer,
} from '@/components'

export const FamilyHoverOver = ({
  problem,
  relationship,
}: FamilyHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Problem</PopoverTileText>
        <PopoverValueText>{problem}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Relationship</PopoverTileText>
        <PopoverValueText>{relationship}</PopoverValueText>
      </div>
    </PopoverContainer>
  )
}

interface FamilyHoverOverProps {
  problem: string;
  relationship: string;
}
