import React from 'react'
import {
  InputLabel,
  Snackbar,
  Alert,
  Divider,
  styled,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import { type SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { useAuth } from '@clerk/nextjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from '@/utility'
import { type PVerifyInsurance } from '@/types'
import {
  ModalContainer,
  ModalRow,
  FullColumn,
  ModalButton,
  ModalTitle,
  ModalFullTextField,
  ModalButtonRow,
  HalfColumn,
  HalfRow,
  RedText,
  ErrorMessage,
  AddressInput,
} from '@/components'
import { useQueryGetStates } from '@/hook'
import {
  addNewInsuranceEntity,
  addNewInsurancePayer,
  updateInsuranceEntity,
  updateInsurancePayer,
} from '@/services'

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  alignItems: 'center',
})

const validationSchema = (insurancePayer: any): any =>
  Yup.object().shape({
    payerName: Yup.string().required('Payer Name is required'),
    payerstreet1: Yup.string().required('Payer Street1 is required'),
    payercity: Yup.string().required('Payer City is required'),
    payerstate: Yup.string().required('Payer State is required'),
    payerzip: Yup.string().required('Payer Zip is required'),
  })

interface AddInsurancePayerForm {
  payerName?: string | null;
  pVerifyID?: string | null;
  payerGlobalRate?: boolean;
  payerInNetwork?: boolean;
  payerstreet1?: string | null;
  payerstreet2?: string | null;
  payercity?: string | null;
  payerstate?: string | null;
  payerzip?: string | null;
}

export const AddInsurancePayer = ({
  handleClose,
  insurancePayer = null,
  refresh,
  onAddPayer,
}: AddInsurancePayerProps): JSX.Element => {
  const { getToken } = useAuth()
  const [isProcessingInsurance, setIsProcessingInsurance] =
    React.useState<boolean>(false)
  const [toastMessage, setToastMessage] = React.useState<string>('')
  const [globlaRate, setGlobalRate] = React.useState<boolean>(false)
  const [isInNetwork, setIsInNetwork] = React.useState<boolean>(false)
  const { data: states } = useQueryGetStates(getToken)
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
    trigger,
  } = useForm<AddInsurancePayerForm>({
    mode: 'all',
    resolver: yupResolver(validationSchema(insurancePayer)),
    ...(insurancePayer !== null && {
      defaultValues: {
        payerName: insurancePayer.payerName,
        pVerifyID: insurancePayer.pVerifyPayerCode,
        payerGlobalRate: insurancePayer.globalFeeEnabled ?? false,
        payerInNetwork: insurancePayer.inNetwork ?? false,
        payerstreet1: insurancePayer.address1,
        payerstreet2: insurancePayer.address2,
        payercity: insurancePayer.city,
        payerstate:
          states?.find((state) => state.id === insurancePayer?.stateId)?.code ??
          '',
        payerzip: insurancePayer.zip,
      },
    }),
  })
  React.useEffect(() => {
    const stateCode =
      states?.find((state) => state.id === insurancePayer?.stateId)?.code ?? ''
    const globalFeeEnabled = insurancePayer?.globalFeeEnabled ?? false
    const inNetwork = insurancePayer?.inNetwork ?? false
    setValue('payerstreet1', insurancePayer?.address1)
    setValue('payerstreet2', insurancePayer?.address2)
    setValue('payerstate', stateCode)
    setValue('payercity', insurancePayer?.city)
    setValue('payerzip', insurancePayer?.zip)
    setGlobalRate(globalFeeEnabled)
    setIsInNetwork(inNetwork)
    setValue('payerGlobalRate', globalFeeEnabled)
    setValue('payerInNetwork', inNetwork)
  }, [insurancePayer])

  function isMatch(object: any, source: any): boolean {
    // Iterate over each key in the source object
    for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key)) {
        // If the value is an object, recursively check the nested object
        if (typeof source[key] === 'object' && source[key] !== null) {
          if (!isMatch(object[key], source[key])) {
            return false
          }
        } else {
          // Otherwise, check for equality
          if (object[key] !== source[key]) {
            return false
          }
        }
      }
    }
    return true
  }

  const needUpdateEntity = (
    originInsuranceEntity: PVerifyInsurance | null,
    newInsuranceEntity: PVerifyInsurance
  ): boolean => {
    if (
      originInsuranceEntity &&
      isMatch(originInsuranceEntity, newInsuranceEntity)
    ) {
      return false
    } else {
      return true
    }
  }
  const handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return
    }
    setToastMessage('')
  }

  const handleReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault()
    reset()
    handleClose()
  }
  const onSubmit: SubmitHandler<AddInsurancePayerForm> = (data): void => {
    const stateId =
      states?.find((state) => state.code === data.payerstate)?.id ?? 0
    const newInsuranceEntity: PVerifyInsurance = {
      payerName: data.payerName,
      pVerifyPayerCode: data.pVerifyID,
      address1: data.payerstreet1,
      address2: data.payerstreet2 ?? '',
      city: data.payercity,
      stateId,
      zip: data.payerzip,
      eligibility: 'Yes',
    }
    const shouldUpdateEntity = needUpdateEntity(
      insurancePayer,
      newInsuranceEntity
    )
    const response =
      insurancePayer === null
        ? addNewInsuranceEntity(newInsuranceEntity, getToken)
        : insurancePayer.id && shouldUpdateEntity
        ? updateInsuranceEntity(insurancePayer.id, newInsuranceEntity, getToken)
        : null
    setIsProcessingInsurance(true)
    Promise.resolve(response)
      .then((insuranceId) => {
        if (insurancePayer === null) {
          addNewInsurancePayer(
            insuranceId,
            data.payerGlobalRate,
            data.payerInNetwork,
            getToken
          )
            .then((res) => {
              if (refresh) {
                refresh()
              }
              handleClose()
            })
            .catch((error) => {
              if (!isEmpty(error)) {
                if (error.includes('=>')) {
                  setToastMessage(JSON.parse(error).Message.split('=>')[1])
                } else {
                  setToastMessage(error)
                }
              }
              throw error
            })
            .finally(() => {
              setIsProcessingInsurance(false)
            })
        } else {
          if (insurancePayer.id) {
            // to check if we need to update AccountInsurance object by checking globlRate,inNetwork is changed or not
            if (
              data.payerGlobalRate !== insurancePayer.globalFeeEnabled ||
              data.payerInNetwork !== insurancePayer.inNetwork
            ) {
              updateInsurancePayer(
                insurancePayer.id,
                data.payerGlobalRate,
                data.payerInNetwork,
                getToken
              )
                .then((res) => {
                  if (refresh) {
                    refresh()
                  }
                  handleClose()
                })
                .catch((error) => {
                  if (!isEmpty(error)) {
                    if (error.includes('=>')) {
                      setToastMessage(JSON.parse(error).Message.split('=>')[1])
                    } else {
                      setToastMessage(error)
                    }
                  }
                  throw error
                })
                .finally(() => {
                  setIsProcessingInsurance(false)
                })
            } else {
              if (refresh) {
                refresh()
              }
              handleClose()
              setIsProcessingInsurance(false)
            }
          }
        }
      })
      .catch((error) => {
        if (!isEmpty(error)) {
          if (error.includes('=>')) {
            setToastMessage(JSON.parse(error).Message.split('=>')[1])
          } else {
            setToastMessage(error)
          }
        }
        throw error
      })
  }

  const handleSubmitClick = (): void => {
    trigger()
      .then((result) => {
        if (result) {
          handleSubmit(onSubmit)().catch((error) => {
            throw error
          })
        }
      })
      .catch((error) => {
        console.error('Validation failed:', error)
      })
  }

  const handleCheckGlobalRate = (): void => {
    setGlobalRate(!globlaRate)
    setValue('payerGlobalRate', !globlaRate)
  }
  const handleCheckInNetwork = (): void => {
    setIsInNetwork(!isInNetwork)
    setValue('payerInNetwork', !isInNetwork)
  }
  return (
    <>
      <ModalContainer>
        <form
          style={{ width: '100%' }}
          // I don't put submit button as type="submit" because it triggers submission
          // on parent component's form as well
        >
          <ModalTitle>
            {insurancePayer === null ? 'Add Insurance' : 'Edit Insurance'}
          </ModalTitle>
          <ModalRow>
            <HalfColumn>
              <InputLabel>
                Payer Name<RedText>*</RedText>
              </InputLabel>
              <ModalFullTextField
                name={'payerName'}
                register={register}
                error={
                  errors.payerName !== undefined && errors.payerName !== null
                }
              />
              {!isEmpty(errors.payerName) && (
                <ErrorMessage error={errors.payerName?.message ?? ''} />
              )}
            </HalfColumn>
            <HalfRow>
              <HalfColumn>
                <InputLabel>Pverify ID</InputLabel>
                <ModalFullTextField name={'pVerifyID'} register={register} />
              </HalfColumn>
              <Column>
                <InputLabel>Global Rate (S9083)</InputLabel>
                <Checkbox
                  checked={globlaRate}
                  onClick={handleCheckGlobalRate}
                />
              </Column>
              <Column>
                <InputLabel>In Network</InputLabel>
                <Checkbox
                  checked={isInNetwork}
                  onClick={handleCheckInNetwork}
                />
              </Column>
            </HalfRow>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <Divider />
            </FullColumn>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <AddressInput
                control={control}
                register={register}
                sectionName="payer"
                setValue={setValue}
                setError={setError}
                errors={errors}
                fullSize
                required
              />
            </FullColumn>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <Divider />
            </FullColumn>
          </ModalRow>
          <ModalButtonRow>
            <ModalButton
              variant="outlined"
              type="reset"
              onClick={(event) => handleReset(event)}
            >
              Cancel
            </ModalButton>
            {isProcessingInsurance ? (
              <CircularProgress />
            ) : (
              <ModalButton
                variant="contained"
                color="primary"
                onClick={handleSubmitClick}
              >
                {insurancePayer === null ? 'Add' : 'Save'}
              </ModalButton>
            )}
          </ModalButtonRow>
        </form>
      </ModalContainer>
      <Snackbar
        open={!isEmpty(toastMessage)}
        autoHideDuration={6000}
        onClose={handleToastClose}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

interface AddInsurancePayerProps {
  handleClose: any;
  insurancePayer?: PVerifyInsurance | null;
  refresh?: any;
  onAddPayer?: (payer: PVerifyInsurance) => void;
}
