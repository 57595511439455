import { type PreventativeMedNotes } from '@/types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import {
  Category,
  HeaderPMH,
  TileDatePicker,
  TileTextArea,
} from '../../components'
import { TileColumn, TileRow } from '../../layout'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { dateOnlyToDate, parseDateToDateOnly } from '@/utility'

export const PreventativeMedications = ({
  preventativeMedications,
  setPreventativeMedications,
}: PreventativeMedicationsProps): JSX.Element => {
  const [
    isPreventativeMedicationsVisible,
    setIsPreventativeMedicationsVisible,
  ] = React.useState<boolean>(false)

  const [openDialog, setOpenDialog] = React.useState(false)
  const [invalidFields, setInvalidFields] = React.useState<string[]>([])

  const [lastPapSmear, setLastPapSmear] = React.useState<Date | null>(
    dateOnlyToDate(preventativeMedications.prevMedLastPapSmear) ?? null
  )
  const [lastMammogram, setLastMammogram] = React.useState<Date | null>(
    dateOnlyToDate(preventativeMedications.prevMedLastMammogram) ?? null
  )
  const [lastDentalExam, setLastDentalExam] = React.useState<Date | null>(
    dateOnlyToDate(preventativeMedications.prevMedLastDentalExam) ?? null
  )
  const [lastDermatologyExam, setLastDermatologyExam] = React.useState<Date | null>(
      dateOnlyToDate(preventativeMedications.prevMedLastDermatologyExam) ?? null
    )
  const [lastOphthalmologyExam, setLastOphthalmologyExam] = React.useState<Date | null>(
      dateOnlyToDate(preventativeMedications.prevMedLastOphthalmologyExam) ??
        null
    )
  const [lastColonoscopy, setLastColonoscopy] = React.useState<Date | null>(
    dateOnlyToDate(preventativeMedications.prevMedLastColonoscopy) ?? null
  )
  const [lastBoneDensity, setLastBoneDensity] = React.useState<Date | null>(
    dateOnlyToDate(preventativeMedications.prevMedLastBoneDensity) ?? null
  )
  const [lastProstateExam, setLastProstateExam] = React.useState<Date | null>(
    dateOnlyToDate(preventativeMedications.prevMedLastProstateExam) ?? null
  )
  const [lastAnxietyScreening, setLastAnxietyScreening] = React.useState<Date | null>(
      dateOnlyToDate(preventativeMedications.prevMedLastAnxietyScreening) ??
        null
    )
  const [lastDepressionScreening, setLastDepressionScreening] = React.useState<Date | null>(
      dateOnlyToDate(preventativeMedications.prevMedLastDepressionScreening) ??
        null
    )
  const [exerciseHabits, setExerciseHabits] = React.useState<string>(
    preventativeMedications.prevMedExerciseHabits ?? ''
  )

  const containerRef = React.useRef<HTMLDivElement>(null)

  const handleOnBlur = (): void => {
    const invalidDateFormatRegex = /[.,-].*[.,-]/

    const isValidDate = (dateString: string): boolean => {
      const date = new Date(dateString)
      return !isNaN(date.getTime())
    }

    const invalidFields = [
      { date: lastPapSmear, label: 'Last Pap Smear' },
      { date: lastMammogram, label: 'Last Mammogram' },
      { date: lastDentalExam, label: 'Last Dental Exam' },
      { date: lastDermatologyExam, label: 'Last Dermatology Exam' },
      { date: lastOphthalmologyExam, label: 'Last Ophthalmology Exam' },
      { date: lastColonoscopy, label: 'Last Colonoscopy' },
      { date: lastBoneDensity, label: 'Last Bone Density' },
      { date: lastProstateExam, label: 'Last Prostate Exam' },
      { date: lastAnxietyScreening, label: 'Last Anxiety Screening' },
      { date: lastDepressionScreening, label: 'Last Depression Screening' }
    ].filter(field => {
      if (!field.date) return false
      const dateString = field.date ? field.date.toLocaleDateString() : ''
      return invalidDateFormatRegex.test(dateString) || !isValidDate(dateString)
    })

    if (invalidFields.length > 0) {
      setInvalidFields(invalidFields.map(field => field.label))
      setOpenDialog(true)
      return
    }

    const newPreventativeMedications = {
      ...preventativeMedications,
      prevMedLastPapSmear: lastPapSmear
        ? parseDateToDateOnly(lastPapSmear)
        : undefined,
      prevMedLastMammogram: lastMammogram
        ? parseDateToDateOnly(lastMammogram)
        : undefined,
      prevMedLastDentalExam: lastDentalExam
        ? parseDateToDateOnly(lastDentalExam)
        : undefined,
      prevMedLastDermatologyExam: lastDermatologyExam
        ? parseDateToDateOnly(lastDermatologyExam)
        : undefined,
      prevMedLastOphthalmologyExam: lastOphthalmologyExam
        ? parseDateToDateOnly(lastOphthalmologyExam)
        : undefined,
      prevMedLastColonoscopy: lastColonoscopy
        ? parseDateToDateOnly(lastColonoscopy)
        : undefined,
      prevMedLastBoneDensity: lastBoneDensity
        ? parseDateToDateOnly(lastBoneDensity)
        : undefined,
      prevMedLastProstateExam: lastProstateExam
        ? parseDateToDateOnly(lastProstateExam)
        : undefined,
      prevMedLastAnxietyScreening: lastAnxietyScreening
        ? parseDateToDateOnly(lastAnxietyScreening)
        : undefined,
      prevMedLastDepressionScreening: lastDepressionScreening
        ? parseDateToDateOnly(lastDepressionScreening)
        : undefined,
      prevMedExerciseHabits: exerciseHabits,
    }
    if (
      JSON.stringify(newPreventativeMedications) !==
      JSON.stringify(preventativeMedications)
    ) {
      setPreventativeMedications(newPreventativeMedications)
    }
  }

  return (
    <Category>
      <TileRow>
        <TileColumn>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <HeaderPMH>Preventative Medicine</HeaderPMH>
            <IconButton
              onClick={() =>
                setIsPreventativeMedicationsVisible(
                  !isPreventativeMedicationsVisible
                )
              }
              size='large'
            >
              {isPreventativeMedicationsVisible ? (
                <KeyboardArrowDownIcon
                  fontSize='small'
                  sx={{ color: '#000000' }}
                />
              ) : (
                <KeyboardArrowRightIcon
                  fontSize='small'
                  sx={{ color: '#000000' }}
                />
              )}
            </IconButton>
          </div>
        </TileColumn>
      </TileRow>
      {isPreventativeMedicationsVisible ? (
        <div
        onBlur={(e) => {
          if (!containerRef.current?.contains(e.relatedTarget)) {
            handleOnBlur()
          }
        }}
        ref={containerRef}
        >
          <TileRow>
            <TileDatePicker
              label='Last Pap Smear'
              value={lastPapSmear}
              onChange={(newDate: Date | null) => setLastPapSmear(newDate)}
              dataTestId='papSmear'
              monthYearFormat={true}
              twoColumn
            />
            <TileDatePicker
              label='Last Mammogram'
              value={lastMammogram}
              onChange={(newDate: Date | null) => setLastMammogram(newDate)}
              dataTestId='lastMammogram'
              monthYearFormat={true}
              twoColumn
            />
          </TileRow>
          <TileRow>
            <TileDatePicker
              label='Last Dental Exam'
              value={lastDentalExam}
              onChange={(newDate: Date | null) => setLastDentalExam(newDate)}
              dataTestId='lastDentalExam'
              monthYearFormat={true}
              twoColumn
            />
            <TileDatePicker
              label='Last Dermatology Exam'
              value={lastDermatologyExam}
              onChange={(newDate: Date | null) =>
                setLastDermatologyExam(newDate)
              }
              dataTestId='lastDermaExam'
              monthYearFormat={true}
              twoColumn
            />
          </TileRow>
          <TileRow>
            <TileDatePicker
              label='Last Ophthalmology Exam'
              value={lastOphthalmologyExam}
              onChange={(newDate: Date | null) =>
                setLastOphthalmologyExam(newDate)
              }
              dataTestId='lastEyeExam'
              monthYearFormat={true}
              twoColumn
            />
            <TileDatePicker
              label='Last Colonoscopy'
              value={lastColonoscopy}
              onChange={(newDate: Date | null) => setLastColonoscopy(newDate)}
              dataTestId='lastColonoscopy'
              monthYearFormat={true}
              twoColumn
            />
          </TileRow>
          <TileRow>
            <TileDatePicker
              label='Last Bone Density Exam'
              value={lastBoneDensity}
              onChange={(newDate: Date | null) => setLastBoneDensity(newDate)}
              dataTestId='lastBoneDensity'
              monthYearFormat={true}
              twoColumn
            />
            <TileDatePicker
              label='Last Prostate Exam'
              value={lastProstateExam}
              onChange={(newDate: Date | null) => setLastProstateExam(newDate)}
              dataTestId='lastProstate'
              monthYearFormat={true}
              twoColumn
            />
          </TileRow>
          <TileRow>
            <TileDatePicker
              label='Last Anxiety Screening'
              value={lastAnxietyScreening}
              onChange={(newDate: Date | null) =>
                setLastAnxietyScreening(newDate)
              }
              dataTestId='lastAnxietyScreening'
              monthYearFormat={true}
              twoColumn
            />
            <TileDatePicker
              label='Last Depression Screening'
              value={lastDepressionScreening}
              onChange={(newDate: Date | null) =>
                setLastDepressionScreening(newDate)
              }
              dataTestId='lastDepressionScreening'
              monthYearFormat={true}
              twoColumn
            />
          </TileRow>
          <TileRow>
            <TileTextArea
              value={exerciseHabits}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setExerciseHabits(e.target.value)
              }
              label='Exercise Habits'
              fullsize
              dataTestId='prevMedExerciseHabits'
            />
          </TileRow>
        </div>
      ) : null}
                <Dialog
                open={openDialog}
                sx={{ borderRadius: '12px' }}
                onClose={() => setOpenDialog(false)}
                >
      <DialogTitle>Invalid Date Format</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please enter the date in MM/YYYY format for the following fields:
          <br />
          {invalidFields.join(', ')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
    </Category>
  )
}

interface PreventativeMedicationsProps {
  preventativeMedications: PreventativeMedNotes
  setPreventativeMedications: (
    preventativeMedications: PreventativeMedNotes
  ) => void
}