import { Button, InputLabel, styled, TextareaAutosize } from '@mui/material'

export const FreeTextNotesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

export const FreeTextNotesRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

export const StyledTextButton = styled(Button)({
  width: '90x',
  height: '14px',
  padding: 0,

  fontWeight: '300',
  paddingRight: 0,
  fontSize: '12px',
  lineHeight: '14px',
  /* identical to box height */
  textAlign: 'right',
  color: '#808080',
  border: 'none',
  borderRadius: 0,
  '&:hover': {
    width: '90x',
  },
})

export const StyleInputLabel = styled(InputLabel)({
  fontWeight: '300',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#222222',
})

export const StyledTextField = styled(TextareaAutosize)(({ theme }) => ({
  fontFamily: 'Lato',
  width: '502px',
  backgroundColor: theme.palette.white,
  borderRadius: '6px',
  borderWidth: 1,
  borderColor: '#DDDDDD',
  color: theme.palette.black,
  padding: '6px',
  '& > focus:': {
    outline: 0,
  },
  '& > textarea': {
    borderColor: '#808080',
  },
}))
