import { styled, Typography } from '@mui/material'
import React from 'react'
import { type Patient, type State } from '@/types'
import { AvatarImage } from '../AvatarImage'
import { formatDateOnly, isEmpty } from '@/utility'

const PatientResultTileContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '8px',
  cursor: 'pointer',
  padding: '5px',
  '&: hover': {
    backgroundColor: '#EEE',
  },
  '&.active': {
    backgroundColor: '#EEE',
  },
}))
const PatientResultTileRow = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
}))
const PatientResultTileColumn = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const PatientNameText = styled(Typography)((): any => ({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '19.2px',
  color: 'black',
}))

const PatientInfoText = styled(Typography)((): any => ({
  fontSize: '14px',
  lineHeight: '16.8px',
  color: 'black',
}))

export const PatientResultTile = ({
  patient,
  selectPatient,
  className,
}: PatientResultTileProps): JSX.Element => {
  const formatPhoneNumber = (phoneNumberString: string): string => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match != null) {
      const intlCode = match[1]?.length > 0 ? '+1 ' : ''
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return ''
  }

  return (
    <PatientResultTileContainer
      onClick={() => {
        selectPatient(patient)
      }}
      className={className}
    >
      <PatientResultTileRow>
        <AvatarImage
          src={patient.avatarUrl ?? '/user.png'}
          alt="Patient Image"
          width={40}
          height={40}
        />
        <PatientResultTileColumn>
          <PatientResultTileRow>
            <PatientNameText>
              {patient?.firstName} {patient?.lastName}
              {', '}
              {!isEmpty(patient?.dateOfBirth) &&
                formatDateOnly(patient?.dateOfBirth)}
            </PatientNameText>
          </PatientResultTileRow>

          <PatientResultTileRow>
            <PatientInfoText>
              {patient.sex}
              {patient.phoneNumber?.trim().length != null
                ? `, ${formatPhoneNumber(patient.phoneNumber)}`
                : ''}
              {patient.email?.trim().length !== 0 ? `, ${patient.email!}` : ''}
            </PatientInfoText>
          </PatientResultTileRow>
        </PatientResultTileColumn>
      </PatientResultTileRow>
    </PatientResultTileContainer>
  )
}

interface PatientResultTileProps {
  patient: Patient;
  setValue: any;
  setIsResultsVisible: any;
  setPatientId: any;
  setInsuranceCount: any;
  setInsuranceIds: any;
  states: State[];
  className: string;
  selectPatient: any;
}
