import React from 'react'
import {
  Box,
  Popover,
  styled,
  Typography,
  Stack,
  InputLabel,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { makeStyles } from '@mui/styles'
import { ModalButton } from '../Config'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'

const StyledInputLabel = styled(InputLabel)({
  fontSize: '14px',
  marginBottom: '10px',
  color: '#717899',
})

const FilterButton = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '5px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  padding: '2px 5px',
  cursor: 'pointer',
})

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      border: 0,
      fontSize: 14,
    },
    '& .MuiSvgIcon-root': {
      width: '18px',
    },
    '& .MuiRadio-root': {
      width: '15px',
      height: '15px',
      marginRight: '10px',
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      margin: '5px 0',
    },
  },
})

export const TimeRangeFilterPopup = ({
  fromDate,
  toDate,
  onApply,
}: VisitFilterPopupProps): JSX.Element => {
  const classes = useStyles()
  const [startDate, setStartDate] = React.useState<Date | null>(fromDate)
  const [endDate, setEndDate] = React.useState<Date | null>(toDate)

  const onChangeStartDate = (date: any): void => {
    setStartDate(new Date(date))
  }

  const onChangeEndDate = (date: any): void => {
    setEndDate(new Date(date))
  }

  return (
    <PopupState variant="popover" popupId="time-range-popover">
      {(popupState) => (
        <>
          <FilterButton {...bindTrigger(popupState)}>
            <FilterAltOutlinedIcon sx={{ width: '16px', fill: '#0543B0' }} />
          </FilterButton>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Stack direction="column" sx={{ width: '330px', padding: '20px' }}>
              <Typography sx={{ fontSize: '14px', fontWeight: 600 }} mb={1}>
                Date Range
              </Typography>
              <Stack direction="row" spacing={2} marginBottom={1}>
                <Box>
                  <StyledInputLabel>From Date</StyledInputLabel>
                  <DatePicker
                    onError={() => {}}
                    inputFormat="MM/dd/yyyy"
                    className={classes.root}
                    renderInput={(params) => <TextField {...params} />}
                    value={startDate}
                    onChange={onChangeStartDate}
                  />
                </Box>
                <Box>
                  <StyledInputLabel>To Date</StyledInputLabel>
                  <DatePicker
                    onError={() => {}}
                    inputFormat="MM/dd/yyyy"
                    className={classes.root}
                    renderInput={(params) => <TextField {...params} />}
                    value={endDate}
                    onChange={onChangeEndDate}
                  />
                </Box>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" sx={{ mt: 1 }}>
                <ModalButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={() => {
                    popupState.close()
                    onApply(startDate, endDate)
                  }}
                >
                  Done
                </ModalButton>
              </Stack>
            </Stack>
          </Popover>
        </>
      )}
    </PopupState>
  )
}
interface VisitFilterPopupProps {
  fromDate: Date;
  toDate: Date;
  onApply: any;
}
