import React from 'react'
import { useAuth } from '@clerk/nextjs'
import { updatePatientSurgery, deletePatientSurgery } from '@/services'
import {
  usePatientSurgeryStore,
  useQueryGetSurgeryTypes,
  useQueryGetPatientSurgery,
  useMutateAddPatientSurgery,
} from '@/hook'
import { PMHTile } from '../PatientHistoryTile'
import { format } from 'date-fns'
import { isEmpty, parseDateToDateOnly } from '@/utility'

export const PatientSurgicalHistory = ({
  patient,
}: {
  patient: any;
}): JSX.Element => {
  const { getToken } = useAuth()

  const { patientSurgeries } = usePatientSurgeryStore()

  const { data: surgeryTypes } = useQueryGetSurgeryTypes(getToken)

  const addNewPatientSurgery = useMutateAddPatientSurgery(getToken)

  const { isLoading: isSurgeryLoading, refetch } = useQueryGetPatientSurgery(
    patient?.id as number,
    getToken
  )

  if (isSurgeryLoading) {
    return <div>Loading surgery...</div>
  }

  const patientSurgeriesArray = Array.isArray(patientSurgeries)
    ? patientSurgeries
    : []

  const surgeriesArray = Array.isArray(surgeryTypes) ? surgeryTypes : []

  const columns = [
    { accessor: 'surgeryId', Header: 'Surgery' },
    { accessor: 'date', Header: 'Date' },
  ]

  const patientSurgeriesWithName = patientSurgeriesArray.map((surgery) => ({
    ...surgery,
    surgeryId:
      surgery.surgeryId !== null
        ? surgeryTypes?.find((type) => type.id === surgery.surgeryId)?.name
        : surgery.procedure ?? 'Unknown Surgery',
    date: !isEmpty(surgery.procedureDate)
      ? format(
          new Date(
            surgery.procedureDate?.year ?? 0,
            (surgery.procedureDate?.month ?? 0) - 1,
            surgery.procedureDate?.day ?? 0
          ),
          'MM/dd/yyyy'
        )
      : '',
  }))

  const dialogFields = [
    {
      label: 'Surgery',
      accessor: 'surgeryId',
      type: 'select',
      options: surgeriesArray?.map((type) => ({
        id: type.id,
        label: type.name,
      })),
    },
    { label: 'Date', accessor: 'procedureDate', type: 'date' },
  ]

  const handleAdd = async (formData: any): Promise<void> => {
    const {
      surgeryId: id,
      procedureDate: date,
      surgeryId_label: label,
    } = formData
    if (!id && !label) {
      console.error('Surgery ID must be selected.')
      return
    }

    const newSurgery = {
      patientId: patient.id,
      surgeryId: id && !isNaN(id) ? parseInt(id, 10) : null,
      procedureDate: date !== undefined ? parseDateToDateOnly(date) : undefined,
      procedure: id && isNaN(id) ? id : id ? null : label,
      id: 0,
    }

    await addNewPatientSurgery.mutateAsync({ surgery: newSurgery })
    await refetch()
  }

  const handleEdit = async (data: any): Promise<void> => {
    const { surgeryId: id, procedureDate: date, surgeryId_label: label } = data
    if (!id && !label) {
      console.error('Surgery ID must be selected.')
      return
    }

    const newSurgery = {
      patientId: patient.id,
      surgeryId: id && !isNaN(id) ? parseInt(id, 10) : null,
      procedureDate: parseDateToDateOnly(date),
      procedure: id && isNaN(id) ? id : id ? null : label,
      id: data.id,
    }
    await updatePatientSurgery(data.id, newSurgery, getToken)
    await refetch()
  }

  const handleDelete = async (surgeryToDelete: any): Promise<void> => {
    if (!surgeryToDelete) {
      console.error('Could not find surgery to delete')
      return
    }
    await deletePatientSurgery(surgeryToDelete.id, getToken)
    await refetch()
  }

  return (
    <PMHTile
      title="Surgical History"
      data={patientSurgeriesWithName}
      columns={columns}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      dialogFields={dialogFields}
    />
  )
}
