import React from 'react'
import {
  Divider,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
  Dialog,
  Box,
  Checkbox,
  useTheme,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  type UseFormRegister,
  type FieldValues,
  type Control,
  Controller,
  useWatch,
} from 'react-hook-form'
import {
  TileTitleBox,
  AddressInput,
  SectionLabel,
  PhoneNumberInput,
  PharmacySelectionPopup,
  ErrorMessage,
  RedText,
  PCPDropdown,
} from '@/components'
import { TitleRow } from '@/components/Charting/Editor/VitalsTile/styles'
import { isEmpty } from '@/utility/utils'
import { type ExternalProvider } from '@/types'
import { PhotoIdFieldSelectedContext } from '@/context'

const DemographicsContainer = styled('div')(({ theme }): any => ({
  display: 'flex',
  width: '812px',
  padding: '24px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  border: '1px solid #CDD0E3',
  scrollMarginTop: '100px',
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
}))
const DemographicsRow = styled('div')({
  display: 'flex',
})
const DemographicsInnerRow = styled(DemographicsRow)({
  gap: '24px',
})
const AutoCompleteColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  alignContent: 'flex-start',
  paddingRight: '10px',
  paddingBottom: '8px',
  gap: '24px',
})
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '18.75px',
  color: 'black',
}))
const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      maxWidth: 'unset',
    },
  },
})

export const Demographics = ({
  innerRef,
  register,
  control,
  onFocus,
  setValue,
  getValues,
  visitType,
  errors,
  setError,
  externalProviders,
  clinicZip,
}: DemographicsProps): JSX.Element => {
  const theme = useTheme()
  const [isOpenPharmacySelectionDialog, setIsOpenPharmacySelectionDialog] =
    React.useState<boolean>(false)
  const [sSNError, setSSNError] = React.useState<boolean>(false)
  const [privateCareProvider, setPrivateCareProvider] =
    React.useState<ExternalProvider | null>(null)
  const [pcpInputValue, setPCPInputValue] = React.useState<string>('')
  const { photoIdFieldSelected } = React.useContext(
    PhotoIdFieldSelectedContext
  )
  const classes = useStyles()
  const pcpWatch = useWatch({
    control,
    name: 'pcp',
  })

  React.useEffect(() => {
    setPCPInputValue(pcpWatch ?? '')
  }, [pcpWatch])
  React.useEffect(() => {
    setValue('pcp', pcpInputValue)
  }, [pcpInputValue])

  const handleSSNChange = (event: any): void => {
    let ssnVal = event.target.value as string
    if (ssnVal.length < 11) {
      ssnVal = ssnVal.replaceAll('-', '')
      if (ssnVal.length > 3) {
        ssnVal = ssnVal.slice(0, 3) + '-' + ssnVal.slice(3)
      }
      if (ssnVal.length > 6) {
        ssnVal = ssnVal.slice(0, 6) + '-' + ssnVal.slice(6)
      }
    }
    const re = /^\d{3}-?\d{2}-?\d{4}$/

    event.target.value = ssnVal
    setSSNError(!re.test(ssnVal))
  }
  const handleSelectPharmacy = (pharmacy: any): void => {
    setValue('pharmacy', pharmacy.storeName)
    setValue('pharmacyId', pharmacy.id)
    setIsOpenPharmacySelectionDialog(false)
  }

  const handlePCPChange = (
    event: any,
    newValue: ExternalProvider | null
  ): void => {
    setPrivateCareProvider(newValue)
    setValue('pCPPhoneNumber', newValue?.phoneNumber)
    setValue('pCPFaxPhoneNumber', newValue?.faxNumber)
  }

  return (
    <DemographicsContainer ref={innerRef} onMouseOver={onFocus}>
      <TitleRow>
        <TileTitleBox tileTitle="Demographics" />
      </TitleRow>
      <DemographicsRow>
        <AutoCompleteColumn>
          <DemographicsInnerRow>
            <div>
              <InputLabel id="RaceLabel">Race</InputLabel>
              <Controller
                name="race"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    tabIndex={4}
                    {...field}
                    labelId="RaceLabel"
                    sx={{ width: '240px' }}
                    inputProps={{ 'data-testid': 'race' }}
                  >
                    <MenuItem
                      data-testid={'AmericanIndianOrAlaskaNative'}
                      value={'AmericanIndianOrAlaskaNative'}
                    >
                      American Indian or Alaska Native
                    </MenuItem>
                    <MenuItem data-testid={'Asian'} value={'Asian'}>
                      Asian
                    </MenuItem>
                    <MenuItem
                      data-testid={'BlackOrAfricanAmerican'}
                      value={'BlackOrAfricanAmerican'}
                    >
                      Black or African American
                    </MenuItem>
                    <MenuItem
                      data-testid={'NativeHawaiianOrOtherPacificIslander'}
                      value={'NativeHawaiianOrOtherPacificIslander'}
                    >
                      Native Hawaiian or Other Pacific Islander
                    </MenuItem>
                    <MenuItem data-testid={'White'} value={'White'}>
                      White
                    </MenuItem>
                    <MenuItem data-testid={'NoResponse'} value={'NoResponse'}>
                      No Response
                    </MenuItem>
                  </Select>
                )}
              />
            </div>
            <div>
              <InputLabel id="GenderLabel">
                Sex<RedText>{' *'}</RedText>
              </InputLabel>
              <Controller
                name="sex"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Select
                    {...field}
                    tabIndex={5}
                    labelId="GenderLabel"
                    sx={{
                      width: '240px',
                      ...(photoIdFieldSelected.sex && {
                        border: `1px solid ${theme?.palette.primary.main}`,
                        borderRadius: '6px',
                      }),
                    }}
                    data-testid="sex"
                  >
                    <MenuItem data-testid="male" value={'Male'}>
                      Male
                    </MenuItem>
                    <MenuItem data-testid="female" value={'Female'}>
                      Female
                    </MenuItem>
                    <MenuItem data-testid="other" value={'Other'}>
                      Other
                    </MenuItem>
                    <MenuItem data-testid="NoResponse" value={'NoResponse'}>
                      No Response
                    </MenuItem>
                  </Select>
                )}
              />
              {!isEmpty(errors.sex) && (
                <ErrorMessage error={errors.sex.message as string} />
              )}
            </div>
            <div>
              <InputLabel id="SSNLabel">SSN</InputLabel>
              <TextField
                {...register('ssn')}
                error={sSNError}
                onChange={handleSSNChange}
                tabIndex={6}
                inputProps={{ maxLength: 11, 'data-testid': 'ssn' }}
                sx={{ width: '240px' }}
              />
            </div>
          </DemographicsInnerRow>
          <DemographicsInnerRow>
            <div>
              <InputLabel id="EthnicityLabel">Ethnicity</InputLabel>
              <Controller
                name="ethnicity"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Select
                    tabIndex={7}
                    {...field}
                    labelId="EthnicityLabel"
                    sx={{ width: '240px' }}
                    inputProps={{ 'data-testId': 'ethnicity' }}
                  >
                    <MenuItem
                      data-testid={'HispanicOrLatino'}
                      value={'HispanicOrLatino'}
                    >
                      Hispanic or Latino
                    </MenuItem>
                    <MenuItem
                      data-testid={'NotHispanicOrLatino'}
                      value={'NotHispanicOrLatino'}
                    >
                      Not Hispanic or Latino
                    </MenuItem>
                    <MenuItem
                      data-testid={'NoResponseEth'}
                      value={'NoResponse'}
                    >
                      No Response
                    </MenuItem>
                  </Select>
                )}
              />
            </div>
          </DemographicsInnerRow>
        </AutoCompleteColumn>
      </DemographicsRow>
      <Divider
        sx={{ marginTop: '36px', marginBottom: '36px', color: '#DDDFE8' }}
        flexItem
      />
      <AddressInput
        register={register}
        control={control}
        sectionName="demographics"
        setValue={setValue}
        errors={errors}
        setError={setError}
      />
      <Divider
        sx={{ marginTop: '36px', marginBottom: '36px', color: '#DDDFE8' }}
        flexItem
      />
      <SectionLabel sectionLabelText="Contact Information" />
      <AutoCompleteColumn>
        <DemographicsInnerRow>
          <div style={{ width: '240px' }}>
            <InputLabel>
              Email<RedText>{' *'}</RedText>
            </InputLabel>
            <TextField
              tabIndex={14}
              sx={{ width: '240px' }}
              {...register('email', {
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email',
                },
              })}
              type="email"
              inputProps={{ 'data-testid': 'email' }}
            />
            {!isEmpty(errors.email) && (
              <ErrorMessage error={errors.email.message ?? ''} />
            )}
            <StyledFormControlLabel
              control={
                <Checkbox
                  {...register('declinesToProvideEmail')}
                  tabIndex={-1}
                />
              }
              label="Declines to provide Email"
            />
          </div>
          <PhoneNumberInput
            control={control}
            sectionName="patient"
            required
            error={errors.patientPhoneNumber}
            setError={setError}
          />
          <div>
            <InputLabel>Text Message</InputLabel>
            <Controller
              name="leaveMessage"
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <RadioGroup row {...field} tabIndex={16}>
                  <StyledFormControlLabel
                    value={true}
                    control={<Radio data-testId={'leaveMessageYes'} />}
                    label="Yes"
                  />
                  <StyledFormControlLabel
                    value={false}
                    control={<Radio data-testId={'leaveMessageNo'} />}
                    label="No"
                  />
                </RadioGroup>
              )}
            />
          </div>
        </DemographicsInnerRow>
        <DemographicsInnerRow>
          <div
            data-testid={'pcp'}
            style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
          >
            <InputLabel>Primary Care Provider</InputLabel>
            <PCPDropdown
              data={externalProviders ?? []}
              dataTestId="pcp-textfield"
              inputValue={pcpInputValue}
              setInputValue={setPCPInputValue}
              onChange={handlePCPChange}
              value={privateCareProvider}
              hasAddButton={true}
            />
          </div>
          <PhoneNumberInput
            control={control}
            sectionName="pCP"
            labelName="PCP Phone"
            error={errors.pCPPhoneNumber}
            setError={setError}
          />
          <PhoneNumberInput
            control={control}
            sectionName="pCPFax"
            labelName="PCP Fax"
            error={errors.pCPFax}
            setError={setError}
          />
        </DemographicsInnerRow>
        <DemographicsInnerRow>
          <div>
            <InputLabel>
              Pharmacy<RedText>{' *'}</RedText>
            </InputLabel>
            <TextField
              {...register('pharmacy')}
              tabIndex={18}
              sx={{ width: '240px' }}
              defaultValue={''}
              onClick={() => setIsOpenPharmacySelectionDialog(true)}
              inputProps={{ 'data-testid': 'pharmacyId' }}
            />
            {!isEmpty(errors.pharmacy) && (
              <ErrorMessage error={errors.pharmacy.message ?? ''} />
            )}
            <StyledFormControlLabel
              control={
                <Checkbox
                  {...register('declinesToSelectPharmacy')}
                  tabIndex={-1}
                />
              }
              label={
                <Typography noWrap>Declines to select Pharmacy</Typography>
              }
            />
            <Box style={{ visibility: 'hidden', position: 'absolute' }}>
              <TextField {...register('pharmacyId')} defaultValue={''} />
            </Box>
          </div>
          {visitType !== 6 && (
            <div>
              <InputLabel>Employer</InputLabel>
              <TextField
                {...register('employer')}
                tabIndex={19}
                sx={{ width: '240px' }}
                inputProps={{ 'data-testid': 'employer' }}
              />
            </div>
          )}
          <div>
            <InputLabel>Guarantor</InputLabel>
            <TextField
              {...register('guarantor')}
              tabIndex={19}
              sx={{ width: '240px' }}
              inputProps={{ 'data-testid': 'guarantor' }}
            />
          </div>
        </DemographicsInnerRow>
      </AutoCompleteColumn>
      <Divider
        sx={{ marginTop: '36px', marginBottom: '36px', color: '#DDDFE8' }}
        flexItem
      />
      <SectionLabel sectionLabelText="Emergency Contact" />
      <DemographicsInnerRow>
        <div>
          <InputLabel>Emergency Contact</InputLabel>
          <TextField
            sx={{ width: '240px' }}
            {...register('emergencyContactName')}
            tabIndex={20}
            inputProps={{ 'data-testid': 'emergencyContactName' }}
          />
        </div>
        <PhoneNumberInput
          control={control}
          sectionName="emergencyContact"
          error={errors.emergencyContactPhoneNumber}
          setError={setError}
        />
        <div>
          <InputLabel>Relationship</InputLabel>
          <TextField
            sx={{ width: '240px' }}
            {...register('emergencyContactRelationship')}
            tabIndex={22}
            inputProps={{ 'data-testid': 'emergencyContactRelationship' }}
          />
        </div>
        <Dialog
          open={isOpenPharmacySelectionDialog}
          sx={{ borderRadius: '12px', width: '100%' }}
          className={classes.root}
        >
          <PharmacySelectionPopup
            pharmacyId={getValues('pharmacyId')}
            onSelectPharmacy={handleSelectPharmacy}
            setClose={() => {
              setIsOpenPharmacySelectionDialog(false)
            }}
            clinicZip={clinicZip}
          />
        </Dialog>
      </DemographicsInnerRow>
    </DemographicsContainer>
  )
}
interface DemographicsProps {
  innerRef: React.RefObject<HTMLDivElement>;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  onFocus: any;
  setValue: any;
  errors: any;
  visitType: number;
  setError: any;
  getValues: any;
  externalProviders: ExternalProvider[];
  clinicZip: string;
}
