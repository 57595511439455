import { InputLabel, styled } from '@mui/material'
import React from 'react'

const StyleInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '16px',
  marginRight: '10px',
  lineHeight: '24px',
  color: '#464D6F',
  opacity: '0.48',
}))

export const RowTile = ({ children }: RowTileProps): JSX.Element => {
  return <StyleInputLabel>{children}</StyleInputLabel>
}

interface RowTileProps {
  children: string;
}
