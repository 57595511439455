import React from 'react'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { parse, isValid, format, subYears } from 'date-fns'
import { InputLabel, TextField, useTheme } from '@mui/material'
import { ErrorMessage, RedText } from '../ErrorMessage'
import { type Control, Controller } from 'react-hook-form'
import { isEmpty } from '@/utility'

export const DatePicker = ({
  name,
  defaultValue,
  control,
  disabled = false,
  errors,
  dataTestId,
  label,
  required = false,
  minDate = subYears(new Date(), 100),
  maxDate = new Date(Date.now()),
  smaller = false,
  isSelected = false,
}: DatePickerProps): JSX.Element => {
  const theme = useTheme()
  const [internalIsSelected, setInternalIsSelected] =
    React.useState<boolean>(isSelected)
  React.useEffect(() => {
    setInternalIsSelected(isSelected)
  }, [isSelected])

  const handleInputChange = (inputValue: string, onDateChange: any): void => {
    if (!inputValue) {
      onDateChange(null)
      return
    }

    const formats = [
      'M/d/yyyy',
      'MM/dd/yyyy',
      'M/d/yy',
      'MM/dd/yy',
      'Mdyy',
      'Mdyyyy',
      'MMddyy',
      'MMddyyyy',
      'M-d-yyyy',
      'MM-dd-yyyy',
      'M-d-yy',
      'MM-dd-yy',
      'M.d.yyyy',
      'MM.dd.yyyy',
      'M.d.yy',
      'MM.dd.yy',
    ]

    formats.forEach((fmt) => {
      const date = parse(inputValue, fmt, new Date())
      if (isValid(date)) {
        const formattedDate = format(date, 'MM/dd/yyyy')
        onDateChange(formattedDate)
      }
    })
  }

  return (
    <div>
      <InputLabel>
        {label}
        {required && <RedText>{' *'}</RedText>}
      </InputLabel>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field }) => (
          <MuiDatePicker
            {...field}
            disabled={disabled}
            onError={() => {}}
            inputFormat="M/d/yyyy"
            maxDate={maxDate}
            minDate={minDate}
            onChange={(date) => {
              field.onChange(date)
              setInternalIsSelected(true)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="mm/dd/yyyy"
                tabIndex={3}
                sx={{
                  width: smaller ? '170px' : '240px',
                  ...(internalIsSelected && {
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '6px',
                  }),
                }}
                inputProps={{
                  ...params.inputProps,
                  'data-testid': dataTestId,
                  placeholder: 'mm/dd/yyyy',
                }}
                onBlur={(e) =>
                  handleInputChange(e.target.value, field.onChange)
                }
              />
            )}
          />
        )}
      />
      {!isEmpty(errors[name]) && (
        <ErrorMessage error={errors[name].message as string} />
      )}
    </div>
  )
}

interface DatePickerProps {
  name: string;
  defaultValue?: string | number | null;
  control: Control;
  disabled?: boolean;
  errors: any;
  dataTestId: string;
  label: string;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  smaller?: boolean;
  isSelected?: boolean;
}
