import React from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Popover } from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'
import { type PatientSocialHistory } from '@/types'
import {
  RowValue,
  AddColumn,
  AddRow,
  SocialHistoryHoverOver,
  RowTile,
} from '@/components'

export const SocialHistoryRow = ({
  socialHistory,
  delete: deleteDX,
  edit: editDX,
}: SocialHistoryProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const socialHistoryTypes = [
    { id: 1, label: 'Alcohol' },
    { id: 2, label: 'Tobacco' },
    { id: 3, label: 'Drugs' },
    { id: 4, label: 'Other' },
  ]

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <SocialHistoryHoverOver
          type={socialHistory.otherSocialHistory ?? ''}
          frequency={socialHistory.frequency ?? ''}
        />
      </Popover>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={62}>
          <RowValue>
            {socialHistory.socialHistoryId && socialHistory.socialHistoryId > 0
              ? socialHistoryTypes.find(
                  (type) => type.id === socialHistory.socialHistoryId
                )?.label
              : socialHistory.otherSocialHistory ?? ''}
          </RowValue>
        </AddColumn>
        <AddColumn width={30}>
          <RowTile>Frequency</RowTile>
          <RowValue maxWidth="60.2%">{socialHistory.frequency ?? ''}</RowValue>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => editDX(socialHistory)} size="large">
            <ModeIcon />
          </IconButton>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => deleteDX(socialHistory)} size="large">
            <DeleteIcon />
          </IconButton>
        </AddColumn>
      </AddRow>
    </>
  )
}
interface SocialHistoryProps {
  socialHistory: PatientSocialHistory;
  delete: (socialHistory: PatientSocialHistory) => void;
  edit: (socialHistory: PatientSocialHistory) => void;
}
