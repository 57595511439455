import { useMutateUpdateTask } from '@/hook/useTasks'
import { useAuth } from '@clerk/nextjs'
import { TextField, styled } from '@mui/material'
import React, { type ChangeEvent } from 'react'

const StyledTextField = styled(TextField)({
  borderRadius: '4px',
  height: '36px',
  outline: 'none',
  color: '#000000DE',
})

export const DetailsTitle = ({
  taskId,
  taskTitle,
}: DetailsTitleProps): JSX.Element => {
  const { getToken } = useAuth()
  const [title, setTitle] = React.useState<string>(taskTitle)
  const updateTaskMutation = useMutateUpdateTask(getToken)

  const handleInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setTitle(e.currentTarget?.value)
  }

  const handleSave = (): void => {
    void updateTaskMutation.mutateAsync({
      id: taskId,
      task: { title },
    })
  }

  return (
    <StyledTextField
      value={title}
      onChange={(e) => handleInput(e)}
      onBlur={handleSave}
    />
  )
}

interface DetailsTitleProps {
  taskId: number;
  taskTitle: string;
}
