import React from 'react'
import { PopoverContainer } from '../layout/PopoverContainer'
import { PopoverTileText } from '../components/PopoverTileText'
import { PopoverValueText } from '../components/PopoverValueText'
import { type ProcedureCPTCode } from '../../../../types/ProcedureCPTCode'
import { type ProcedureType } from '@/types'
import { isEmpty } from '@/utility'
import { Grid } from '@mui/material'
import { getProcedureValue } from '@/helpers'

export const ProcedureHoverOver = ({
  procedure,
  side,
  procedureName,
  modifier,
  procedureType,
  fields,
}: ProcedureHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Procedure</PopoverTileText>
        <PopoverValueText>{procedureName}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Category</PopoverTileText>
        <PopoverValueText>{procedureType.category}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>CPT Code</PopoverTileText>
        <PopoverValueText>{procedure.cptCodeOther ?? ''}</PopoverValueText>
      </div>
      {(procedure?.visitClinicalProcedureCptModifierCodeList ?? []).length >
        0 && (
        <div>
          <PopoverTileText>Modifiers</PopoverTileText>
          <PopoverValueText>{modifier}</PopoverValueText>
        </div>
      )}
      {!isEmpty(procedure.textResult) && (
        <div>
          <PopoverTileText>Notes</PopoverTileText>
          <PopoverValueText>{procedure.textResult}</PopoverValueText>
        </div>
      )}
      <Grid container spacing={2}>
        {procedure.visitClinicalProcedureFieldList?.map((field) => {
          const fieldData = fields.find(
            (f) => f.id === field.clinicalProcedureFieldId
          )
          return (
            <Grid item xs={6} key={field.id}>
              <div>
                <PopoverTileText>{fieldData?.name}</PopoverTileText>
                <PopoverValueText>{getProcedureValue(field)}</PopoverValueText>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </PopoverContainer>
  )
}

interface ProcedureHoverOverProps {
  procedure: ProcedureCPTCode;
  side: string;
  procedureName: string;
  modifier: string;
  procedureType: ProcedureType;
  fields: any[];
}
