import React from 'react'
import { MenuItem, Select, Typography } from '@mui/material'
import { FileUploader } from 'react-drag-drop-files'
import { type UploadFileStatus } from '@/types'
import { FileItem } from './FileItem'
import { SelectFilesContainer, Container, FileListHeader } from './styles'

interface Props {
  selectedFiles: File | FileList | undefined;
  setSelectedFiles: any;
  multiple?: boolean;
  selectedFileTypes?: number[];
  setSelectedFileTypes?: any;
  fullWidth?: boolean;
  docTypes?: any[];
  allowedFileTypes?: string[];
  fileStatuses?: UploadFileStatus[];
  setFileStatuses?: any;
}

export const FileUploadSelect: React.FC<Props> = ({
  multiple,
  selectedFiles,
  setSelectedFiles,
  fullWidth,
  selectedFileTypes,
  setSelectedFileTypes,
  docTypes = [],
  allowedFileTypes = ['PDF', 'PNG', 'JPG', 'JPEG', 'DOC', 'DOCX', 'TXT'],
  fileStatuses = [],
  setFileStatuses,
}): JSX.Element => {
  const [selectAllType, setSelectAllType] = React.useState<number>(0)

  React.useEffect(() => {
    if (setFileStatuses === undefined) return
    const statuses = []
    if (selectedFiles && multiple) {
      for (let i = 0; i < (selectedFiles as FileList).length; i++) {
        statuses.push({
          progress: 0,
          error: false,
          success: false,
          isUploading: false,
          errorMessage: '',
        })
      }
    } else {
      statuses.push({
        progress: 0,
        error: false,
        success: false,
        isUploading: false,
        errorMessage: '',
      })
    }
    setFileStatuses(statuses)
  }, [selectedFiles])

  const handleDeleteFromList = (indexToRemove: number): void => {
    if (!selectedFiles) return
    const updatedFileArray = Array.from(selectedFiles as FileList).filter(
      (_, index) => index !== indexToRemove
    )
    const dataTransfer = new DataTransfer()
    updatedFileArray.forEach((file) => dataTransfer.items.add(file))
    setSelectedFiles(dataTransfer.files)
    if (setFileStatuses !== undefined) {
      setFileStatuses(
        fileStatuses.filter(
          (_: UploadFileStatus, index: number) => index !== indexToRemove
        )
      )
    }
  }

  const handleSelectChange = (index: number, value: number): void => {
    setSelectedFileTypes((prev: any) => {
      const updated = [...prev]
      updated[index] = value
      return updated
    })
  }

  const handleSelectAllChange = (value: number): void => {
    setSelectAllType(value)
    const allSelectedTypes = Array.from({
      length: (selectedFiles as FileList).length,
    }).map(() => value)
    setSelectedFileTypes(allSelectedTypes)
  }

  return (
    <Container fullWidth={fullWidth}>
      <FileUploader
        handleChange={(files: any) => setSelectedFiles(files)}
        types={allowedFileTypes}
        multiple={multiple}
        fileOrFiles={selectedFiles}
        classes={'Uploader'}
        maxSize={15}
      />
      {selectedFiles && docTypes.length > 0 && (
        <Select
          value={selectAllType}
          onChange={(e) => handleSelectAllChange(e.target.value as number)}
          displayEmpty
          sx={{ width: '100%' }}
        >
          <MenuItem value={0}>Select Type For All</MenuItem>
          {docTypes.map(({ id, name }) => (
            <MenuItem value={id} key={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      )}
      <SelectFilesContainer>
        {selectedFiles && docTypes.length > 0 && (
          <FileListHeader>
            <Typography variant="h6">File Name</Typography>
            <Typography variant="h6">Type</Typography>
          </FileListHeader>
        )}
        {selectedFiles &&
          (multiple ? (
            Array.from(selectedFiles as FileList).map((file, i) => (
              <FileItem
                key={i}
                index={i}
                file={file}
                handleDelete={() => handleDeleteFromList(i)}
                handleSelectChange={handleSelectChange}
                selectedFileTypes={selectedFileTypes}
                fileStatus={fileStatuses[i]}
                docTypes={docTypes}
              />
            ))
          ) : (
            <FileItem
              index={0}
              file={selectedFiles as File}
              handleDelete={() => setSelectedFiles(undefined)}
              handleSelectChange={handleSelectChange}
              selectedFileTypes={selectedFileTypes}
              fileStatus={fileStatuses[0]}
              docTypes={docTypes}
            />
          ))}
      </SelectFilesContainer>
    </Container>
  )
}
