import { Button, TextField, styled } from '@mui/material'
import React from 'react'
import { convertLockingFailureMessage } from '@/utility'
import { ErrorMessage, RedText } from '@/components/ErrorMessage'
import { ModalTitle } from '@/components/Config'

const SignChartPopUpContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '365px',
  padding: '24px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  gap: 16,
}))

const StyledTextField = styled(TextField)({
  flex: 1,
})
const SignChartPopUpBottonRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
})
const SignChartPopUpRow = styled('div')({
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'flex-start',
  flexDirection: 'row',
})
const StyledButton = styled(Button)({
  height: '32px',
  width: 'fit-content',
  maxWidth: '120px',
  minWidth: '74px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
})

export const SignChartPopUp = ({
  setClose,
  handleSignChart,
  isLockedButtonClicked,
  isLocked,
  isEmCodeEmpty,
  hasDX,
  needsLMP,
}: SignChartPopUpProps): JSX.Element => {
  const [pin, setPin] = React.useState<string>('')
  const [errorMessage, setErrorMessage] = React.useState<string>('')
  const onSave = async (): Promise<void> => {
    const res = Promise.resolve(handleSignChart(pin))
      .then((res) => {
        return res
      })
      .catch((err) => {
        setErrorMessage(convertLockingFailureMessage(err))
        throw err
      })
    if (await res) {
      setClose(false)
    } else {
      setErrorMessage('')
    }
  }
  const handleEnter = (event: any): void => {
    if (event.key === 'Enter') {
      onSave().catch((err) => {
        throw err
      })
    }
  }
  const handleChange = (e: any): void => {
    const regex = /^[0-9\b]+$/
    if (e.target.value === '' || regex.test(e.target.value)) {
      setPin(e.target.value)
    }
  }

  return (
    <SignChartPopUpContainer>
      <ModalTitle>Enter PIN to {isLocked ? 'Unlock' : 'Lock'} Chart</ModalTitle>
      <SignChartPopUpRow>
        <StyledTextField
          onKeyPress={handleEnter}
          value={pin}
          onChange={handleChange}
          type="password"
          inputProps={{ 'data-testId': 'sign-chart-pin' }}
        />
      </SignChartPopUpRow>
      {!!errorMessage && !isLocked && <ErrorMessage error={errorMessage} />}
      {isEmCodeEmpty && !isLocked && <RedText>No EM code selected</RedText>}
      {!hasDX  && !isLocked && <RedText>Please add at least one diagnosis</RedText>}
      {needsLMP && !isLocked  && <RedText>Please record last menstrual period</RedText>}
      <SignChartPopUpBottonRow>
        <StyledButton
          data-testid="sign-cancel-button"
          sx={{ width: '80px' }}
          onClick={() => {
            setClose(false)
          }}
        >
          Cancel
        </StyledButton>
        <StyledButton
          data-testid="sign-chart-button"
          disabled={
            (isLockedButtonClicked || (!pin || isEmCodeEmpty || !hasDX || needsLMP)) && !isLocked
          }
          sx={{ width: '80px' }}
          variant="contained"
          onClick={() => {
            onSave().catch((err) => {
              throw err
            })
          }}
        >
          {isLocked ? 'Unlock' : 'Lock'}
        </StyledButton>
      </SignChartPopUpBottonRow>
    </SignChartPopUpContainer>
  )
}
interface SignChartPopUpProps {
  setClose: (close: boolean) => void;
  handleSignChart: (pin: string) => boolean;
  isLockedButtonClicked: boolean;
  isLocked: boolean;
  isEmCodeEmpty: boolean;
  hasDX: boolean;
  needsLMP: boolean;
}
