import { styled, TextareaAutosize } from '@mui/material'
import React from 'react'

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  fontFamily: 'Lato',
  width: '100%',
  maxWidth: '100%',
  minWidth: '100%',
  color: '#222222',
  fontWeight: '500',
  fontSize: '16px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '6px',
  borderColor: '#DDDFE8',
  borderStyle: 'solid',
  borderWidth: '1px',
  paddingLeft: '10px',
  '&:hover': {
    borderColor: 'black',
  },
  '&:focus': {
    borderColor: '#0543B0',
    borderWidth: '2px',
    outline: 'none',
  },
}))

export const ModalFullTextArea = ({
  name,
  register,
}: ModalFullTextFieldProps): JSX.Element => {
  return <StyledTextArea {...register(name)} minRows={4} maxRows={4} />
}

interface ModalFullTextFieldProps {
  name: string;
  register: any;
}
