import { ErrorMessage, RedText } from '@/components/ErrorMessage'
import { InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import {
  type Control,
  Controller,
  type FieldValues,
  type UseFormRegister
} from 'react-hook-form'
import { type VisitCategory } from '../../../../types/VisitCategory'
import { type VisitType } from '@/types'

export const VisitCategorySelector = ({
  register,
  control,
  setVisitType,
  visitTypes,
  setVisitCategory,
  visitCategories,
  errors
}: VisitCategorySelectorProps): JSX.Element => {
  const [availableVisitTypes, setAvailableVisitTypes] =
    React.useState<VisitType[]>(visitTypes)

  const nullCategory: VisitCategory = { name: '-' }

  React.useEffect(() => {
    setAvailableVisitTypes(visitTypes)
  }, [visitTypes])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
      {visitCategories.length > 0 && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
          <InputLabel id="VisitCategoryLabel">Visit Category</InputLabel>
          <Controller
            name="visitCategory"
            control={control}
            defaultValue={null}
            render={({ field }) => (
              <Select
                data-testid="visitCategory"
                tabIndex={37}
                {...register('visitCategory')}
                {...field}
                labelId="VisitCategoryLabel"
                sx={{ width: '240px' }}
                onChange={(event) => {
                  field.onChange(event.target.value)
                  setVisitCategory(event.target.value as number)
                  const categoryVisitTypes = visitCategories.find(
                    (category) => category.id === event.target.value
                  )?.visitTypes
                  if (categoryVisitTypes && categoryVisitTypes.length > 0) {
                    setAvailableVisitTypes(categoryVisitTypes)
                  } else {
                    setAvailableVisitTypes(visitTypes)
                  }
                }}
              >
                {[nullCategory, ...(visitCategories ?? [])].map(
                  (category: VisitCategory) => {
                    return (
                      <MenuItem
                        key={category.id}
                        value={category.id}
                        style={{ fontSize: '14px' }}
                        data-testid={`visitCategory-${
                          (category?.id ?? '') as string
                        }`}
                      >
                        {category.name}{' '}
                      </MenuItem>
                    )
                  }
                )}
              </Select>
            )}
          />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
        <InputLabel id="VisitTypeLabel">
          Visit Type<RedText>{' *'}</RedText>
        </InputLabel>
        <Controller
          name="visitType"
          control={control}
          defaultValue={null}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              data-testid="visitType"
              tabIndex={37}
              {...field}
              labelId="VisitTypeLabel"
              sx={{ width: '240px' }}
              onChange={(event) => {
                const value =
                  event.target.value === '-' ? null : event.target.value
                field.onChange(value)
                setVisitType(value as number)
              }}
              error={!!errors.visitType}
            >
              <MenuItem
                value="-"
                disabled
              >
                Select a visit type
              </MenuItem>
              {availableVisitTypes?.map((type: VisitType) => (
                <MenuItem
                  key={type.id}
                  value={type.id}
                  style={{ fontSize: '14px' }}
                  data-testid={`visitType-${(type?.id ?? '') as string}`}
                >
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors?.visitType && (
          <ErrorMessage error={errors.visitType.message as string} />
        )}
      </div>
    </div>
  )
}

interface VisitCategorySelectorProps {
  register: UseFormRegister<FieldValues>
  control: Control<FieldValues, any>
  setVisitType: React.Dispatch<React.SetStateAction<number>>
  visitTypes: VisitType[]
  setVisitCategory: React.Dispatch<React.SetStateAction<number | null>>
  visitCategories: VisitCategory[]
  errors?: any
}
