import React from 'react'
import { RowTile } from '../components/RowTile'
import { RowValue } from '../components/RowValue'
import { AddColumn } from '../layout/AddColumn'
import { AddRow } from '../layout/AddRow'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Popover } from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'
import { ProcedureHoverOver } from './ProdureHoverOver'
import { type ProcedureCPTCode } from '../../../../types/ProcedureCPTCode'
import { type ProcedureType } from '@/types'

export const ProcedureRow = ({
  index = 0,
  procedure,
  procedureName,
  delete: deleteDX = null,
  edit: editDX = null,
  modifier,
  procedureType,
  fields,
}: DXRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <ProcedureHoverOver
          procedure={procedure}
          side={''}
          procedureName={procedureName}
          modifier={modifier}
          procedureType={procedureType}
          fields={fields}
        />
      </Popover>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={22}>
          <RowValue>{procedureName}</RowValue>
        </AddColumn>
        <AddColumn width={30}>
          <RowTile>Category</RowTile>
          <RowValue maxWidth="52%">{procedureType?.category ?? ''}</RowValue>
        </AddColumn>
        <AddColumn width={20}>
          <RowTile>CPT Code</RowTile>
          <RowValue maxWidth="52%">{procedure.cptCodeOther ?? ''}</RowValue>
        </AddColumn>
        <AddColumn width={20}>
          <RowTile>Modifiers</RowTile>
          <RowValue maxWidth="52%">{modifier ?? ''}</RowValue>
        </AddColumn>
        {
          // prettier-ignore
          editDX !== null
          // prettier-ignore
          ? (
              <AddColumn width={4}>
                <IconButton onClick={() => editDX(procedure, index)} onMouseEnter={handlePopoverClose} size="large">
                  <ModeIcon />
                </IconButton>
              </AddColumn>
            )
          // prettier-ignore
          : <></>
        }
        {
          // prettier-ignore
          deleteDX !== null
          // prettier-ignore
          ? (
              <AddColumn width={4}>
                <IconButton onClick={() => deleteDX(index)} size="large" onMouseEnter={handlePopoverClose}>
                  <DeleteIcon />
                </IconButton>
              </AddColumn>
            )
          // prettier-ignore
          : <></>
        }
      </AddRow>
    </>
  )
}
interface DXRowProps {
  index?: number;
  procedure: ProcedureCPTCode;
  procedureName: string;
  modifier: string;
  delete?: ((index: number) => void) | null;
  edit?: ((procedure: ProcedureCPTCode, index: number) => void) | null;
  procedureType: ProcedureType;
  fields: any[];
}
