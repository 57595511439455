import { IconButton, Typography, styled } from '@mui/material'
import React, { type ReactNode } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const SectionTileContainer = styled('div')(
  ({ isOpen }: SectionTileContainerProps): any => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    ...(isOpen && { borderRadius: '8px' }),
    ...(isOpen && { border: '1px solid #DDDFE8' }),
    ...(!isOpen && { borderBottom: '1px solid rgba(255, 255, 255, 0.16)' }),
    gap: '6px',
    ...{ backgroundColor: 'rgba(255, 255, 255, 0.08)' },
    ...(!isOpen && { height: '44px' }),
    boxShadow: '0px 2px 1px -1px #00000033',
  })
)

const TiltText = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  flex: 1,
  '&:hover': {
    cursor: 'pointer',
  },
})

const SectionHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#EAF3FF',
  padding: '8px 16px',
})

const SectionBody = styled('div')({
  padding: '8px 16px 16px 16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

const SectionTileTitle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  flex: 1,
})

export const TaskDrawerSection = ({
  sectionName,
  children,
}: TaskDrawerSectionProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(true)
  return (
    <SectionTileContainer isOpen={isOpen}>
      <SectionHeader>
        <SectionTileTitle
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <TiltText>{sectionName}</TiltText>
          <IconButton size={'small'} data-testid="toggle-button">
            {isOpen ? (
              <KeyboardArrowDownIcon
                fontSize="small"
                sx={{ color: '#707070' }}
              />
            ) : (
              <KeyboardArrowRightIcon
                fontSize="small"
                sx={{ color: '#707070' }}
              />
            )}
          </IconButton>
        </SectionTileTitle>
      </SectionHeader>
      {isOpen && <SectionBody>{children}</SectionBody>}
    </SectionTileContainer>
  )
}

interface TaskDrawerSectionProps {
  sectionName: string;
  children?: ReactNode;
}

interface SectionTileContainerProps {
  isOpen: boolean;
}
