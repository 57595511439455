import { styled } from '@mui/material'
import React from 'react'

const ChiefComplaintColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  minWidth: '100%',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
})

export const TileColumn = ({ children }: TileColumnProps): JSX.Element => {
  return <ChiefComplaintColumn>{children}</ChiefComplaintColumn>
}

interface TileColumnProps {
  children:
    | JSX.Element
    | JSX.Element[]
    | undefined
    | null
    | Array<JSX.Element | undefined | null>;
}
