import React from 'react'
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@mui/material'
import { StyledEditButton } from '../DocumentTab'
import { FileUploadSelect } from '@/components/FileUpload'
import {
  type AccountUser,
  type Patient,
  type UploadFileStatus,
  type UrgentIQDocuments,
} from '@/types'
import { useAuth, useUser } from '@clerk/nextjs'
import {
  type QueryObserverResult,
  type RefetchOptions,
  type RefetchQueryFilters,
} from '@tanstack/react-query'
import {
  FileUploadingDialog,
  PDFWarningDialog,
  CancelWarningDialog,
} from './WarningDiaglogs'

export const FileUploadDialog = ({
  clinicId,
  isOpen,
  handleClose,
  docTypes,
  patient,
  clinicians,
  refreshDocuments,
  showAllClinicsOption,
}: FileUploadingDialogProps): JSX.Element => {
  const [currentUser, setCurrentUser] = React.useState<AccountUser>()
  const [dialogOpen, setDialogOpen] = React.useState(isOpen)
  const [uploadFile, setUploadFile] = React.useState<FileList>()
  const [uploadType, setUploadType] = React.useState<number[]>([])
  const [isUploading, setIsUploading] = React.useState<boolean>(false)
  const [isWarningOpen, setIsWarningOpen] = React.useState<boolean>(false)
  const [uploadToAllClinics, setUploadToAllClinics] = React.useState(false)
  const [uploadFileStatuses, setUploadFileStatuses] = React.useState<
    UploadFileStatus[]
  >([])
  const [isUploadingWarningOpen, setIsUploadingWarningOpen] =
    React.useState<boolean>(false)
  const [letterTemplateNotPDFOpen, setLetterTemplateNotPDFOpen] =
    React.useState<boolean>(false)

  const { getToken } = useAuth()
  const { user } = useUser()

  const filesUploaded = uploadFileStatuses.every(
    (file) => file.success || file.error
  )

  React.useEffect(() => {
    if (uploadFileStatuses.length === 0) return
    setIsUploading(uploadFileStatuses.some((file) => file.isUploading))
  }, [uploadFileStatuses])

  React.useEffect(() => {
    const cUser = clinicians?.find(
      (userItem: any) =>
        (userItem?.username ?? '').toLowerCase() ===
        (user?.username ?? '').toLowerCase()
    )
    setCurrentUser(cUser)
  }, [clinicians, user])

  const handleEmptiedStates = (): void => {
    setUploadFile(undefined)
    setUploadType([])
    setUploadFileStatuses([])
    handleClose()
  }

  const handleCancelUploadModal = (): void => {
    if (
      (uploadFile !== undefined &&
        uploadFile !== null &&
        uploadFile.length === 0) ||
      uploadFile === undefined ||
      uploadFile === null
    ) {
      handleEmptiedStates()
    } else {
      setIsWarningOpen(true)
    }
  }

  React.useEffect(() => {
    setDialogOpen(isOpen)
  }, [isOpen])

  const handleSaveUploadModal = async (index: number): Promise<void> => {
    if (uploadFile === undefined && uploadFile === null) return
    if (uploadFile?.[index] !== null && uploadFile?.[index] !== undefined) {
      if (
        uploadType[index] === 13 &&
        uploadFile?.[index].type !== 'application/pdf'
      ) {
        setLetterTemplateNotPDFOpen(true)
      } else {
        await saveOtherDocuments(
          `${uploadType[index]}`,
          uploadFile?.[index],
          uploadFileStatuses[index],
          typeof patient?.id === 'number' ? `${patient?.id}` : undefined
        )
        await refreshDocuments()
      }
    }
  }

  const saveOtherDocuments = async (
    id: string,
    file: Blob,
    fileUploadStatus: UploadFileStatus,
    patientId?: string
  ): Promise<void> => {
    fileUploadStatus.isUploading = true
    try {
      const formData = new FormData()
      formData.append('file', file)
      if (!uploadToAllClinics && clinicId) {
        formData.append('clinicId', `${clinicId ?? ''}`)
      }
      if (uploadToAllClinics) {
        formData.append('uploadToAllClinics', 'true')
      }
      if (patientId) {
        formData.append('patientId', patientId)
      }
      formData.append('documentTypeId', id)
      formData.append('AccountUserId', `${currentUser?.id!}`)
      const token = await getToken({ template: 'UrgentIQ' })
      fileUploadStatus.progress = 1
      return await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('POST', `${process.env.API_URL ?? ''}/api/Document`, true)
        xhr.setRequestHeader('origin', 'null')
        xhr.setRequestHeader('Authorization', `Bearer ${token ?? ''}`)

        // Handle the upload progress
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100
            fileUploadStatus.progress = progress
            // You can also call a function to update the UI here
          }
        }

        xhr.onload = () => {
          if (xhr.status === 200) {
            fileUploadStatus.progress = 100
            fileUploadStatus.success = true
            resolve()
          } else {
            reject(new Error(`Error: ${xhr.status}`))
          }
        }

        xhr.onerror = () => {
          reject(new Error('XMLHttpRequest error'))
        }

        xhr.send(formData)
      })
    } catch (error) {
      fileUploadStatus.error = true
      fileUploadStatus.errorMessage = error as string
    } finally {
      fileUploadStatus.isUploading = false
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      sx={{ '& .MuiDialog-paper': { width: '812px', maxWidth: '100%' } }}
    >
      <FileUploadingDialog
        isOpen={isUploadingWarningOpen}
        handleClose={() => {
          setIsUploadingWarningOpen(false)
          handleEmptiedStates()
        }}
      />
      <CancelWarningDialog
        isOpen={isWarningOpen}
        handleClose={() => {
          setIsWarningOpen(false)
          handleEmptiedStates()
          handleClose()
        }}
      />
      <PDFWarningDialog
        isOpen={letterTemplateNotPDFOpen}
        handleClose={() => {
          setLetterTemplateNotPDFOpen(false)
        }}
      />
      <FileUploadingDialog
        isOpen={isUploadingWarningOpen}
        handleClose={() => {
          setIsUploadingWarningOpen(false)
          handleEmptiedStates()
        }}
      />
      <DialogTitle>Upload Document</DialogTitle>
      <DialogContent>
        {
          <FileUploadSelect
            selectedFiles={uploadFile}
            setSelectedFiles={setUploadFile}
            selectedFileTypes={uploadType}
            setSelectedFileTypes={setUploadType}
            multiple
            docTypes={docTypes}
            fullWidth
            fileStatuses={uploadFileStatuses}
            setFileStatuses={setUploadFileStatuses}
          />
        }
      </DialogContent>
      {showAllClinicsOption && (
        <FormControlLabel
          control={
            <Checkbox
              checked={uploadToAllClinics}
              onChange={(event) => setUploadToAllClinics(event.target.checked)}
            />
          }
          label="Upload document(s) to all clinics"
          labelPlacement="start"
          sx={{
            marginTop: '-20px',
            marginRight: '6px',
          }}
        />
      )}
      <DialogActions>
        {!filesUploaded && !isUploading ? (
          <>
            <StyledEditButton onClick={handleCancelUploadModal}>
              Cancel
            </StyledEditButton>
            <StyledEditButton
              variant="contained"
              type="submit"
              onClick={() => {
                setIsUploading(true)
                // eslint-disable-next-line
                const saveUploadPromises = uploadType.map((t, index) =>
                  handleSaveUploadModal(index)
                )
                Promise.all(saveUploadPromises)
                  .catch((error) => {
                    throw error
                  })
                  .finally(() => {
                    Promise.resolve(refreshDocuments()).catch((error) => {
                      throw error
                    })
                  })
              }}
              disabled={
                uploadFile === null ||
                uploadFile === undefined ||
                uploadType === null ||
                uploadType === undefined ||
                uploadType.length === 0
              }
            >
              Save
            </StyledEditButton>
          </>
        ) : (
          <StyledEditButton
            onClick={() =>
              filesUploaded
                ? handleEmptiedStates()
                : setIsUploadingWarningOpen(true)
            }
          >
            Close
          </StyledEditButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

interface FileUploadingDialogProps {
  clinicId?: number;
  isOpen: boolean;
  handleClose: () => void;
  docTypes: any[];
  patient?: Patient | null;
  clinicians?: AccountUser[];
  refreshDocuments: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<UrgentIQDocuments | any[], unknown>>;
  showAllClinicsOption?: boolean;
}
