import React from 'react'
import {
  Divider,
  InputLabel,
  styled,
  TextField,
  Select,
  MenuItem,
} from '@mui/material'
import {
  type UseFormRegister,
  type FieldValues,
  type Control,
  Controller,
} from 'react-hook-form'
import {
  TileTitleBox,
  AddressInput,
  SectionLabel,
  PhoneNumberInput,
  ErrorMessage,
  RedText,
  ModalButton,
  ModalButtonRow,
} from '@/components'
import { useRouter } from 'next/router'
import { useAuth } from '@clerk/nextjs'
import { TitleRow } from '@/components/Charting/Editor/VitalsTile/styles'
import { isEmpty } from '@/utility/utils'
import {
  useClinicStore,
  useQueryGetStates,
  useQueryGetVisitTypes,
} from '@/hook'
import { Box } from '@mui/system'
import { DatePicker } from '@/components/DatePicker'

const DemographicsContainer = styled('div')(({ theme }): any => ({
  display: 'flex',
  width: '812px',
  padding: '24px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  border: '1px solid #CDD0E3',
  scrollMarginTop: '100px',
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
}))
const DemographicsRow = styled('div')({
  display: 'flex',
})
const DemographicsInnerRow = styled(DemographicsRow)({
  gap: '24px',
})
const AutoCompleteColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  alignContent: 'flex-start',
  paddingRight: '10px',
  paddingBottom: '8px',
  gap: '24px',
})

export const WorkCompOccMedTile = ({
  innerRef,
  register,
  control,
  onFocus,
  setValue,
  errors,
  values,
  setError,
  handleClose,
  isModal = false,
  isWorkersComp = true,
}: WorkersCompOccMedProps): JSX.Element => {
  const { getToken } = useAuth()
  const router = useRouter()
  const { clinicId } = useClinicStore()
  const { data: states } = useQueryGetStates(getToken)
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const isIntake = router.pathname.includes('intake')

  const occMedTitle = React.useMemo(() => {
    return visitTypes?.find((type) => type?.id === 4)?.name ?? ''
  }, [visitTypes])

  React.useEffect(() => {
    if (isIntake || isEmpty(values)) return

    Object.keys(values).forEach((key: string) => {
      if (key.includes('Phone')) {
        setValue(`${key}Number`, values[key] ?? '')
      } else if (key.includes('Address') && !key.includes('workersComp')) {
        setValue(`${key.replace('Line', 'street1')}`, values[key] ?? '')
        setValue(`${key.replace('Line2', 'street2')}`, values[key] ?? '')
      } else if (key.includes('Zip')) {
        setValue(
          key.includes('workersComp')
            ? `${key.replace('Zipcode', 'zip')}`
            : `${key.replace('Zip', 'Addresszip')}`,
          values[key] ?? ''
        )
      } else if (key.includes('City')) {
        setValue(
          key.includes('workersComp')
            ? `${key.replace('City', 'city')}`
            : `${key.replace('City', 'Addresscity')}`,
          values[key] ?? ''
        )
      } else if (key.includes('StateId') && !key.includes('Accident')) {
        setValue(
          key.includes('workersComp')
            ? `${key.replace('StateId', 'state')}`
            : `${key.replace('StateId', 'Addressstate')}`,
          states?.find((s: any) => s.id === values[key])?.code ?? ''
        )
      } else if (key.includes('AddressStreet')) {
        setValue(key.replace('Street', 'street'), values[key] ?? '')
      } else {
        setValue(key, values[key] ?? '')
      }
    })
  }, [values])

  return (
    <DemographicsContainer ref={innerRef} onMouseOver={onFocus}>
      <TitleRow>
        <TileTitleBox
          tileTitle={isWorkersComp ? "Worker's Comp" : occMedTitle}
        />
      </TitleRow>
      {isWorkersComp && (
        <>
          <DemographicsRow>
            <AutoCompleteColumn>
              <DemographicsInnerRow>
                <div>
                  <DatePicker
                    name={'workersCompAccidentDate'}
                    control={control}
                    label="Accident Date"
                    errors={errors}
                    maxDate={new Date(Date.now())}
                    dataTestId={'workersCompAccidentDate'}
                    required
                    defaultValue={null}
                  />
                </div>
                <div>
                  <InputLabel id="StateLabel">
                    Accident State<RedText>{' *'}</RedText>
                  </InputLabel>

                  <Controller
                    name={'workersCompAccidentStateId'}
                    control={control}
                    defaultValue={values?.workersCompAccidentStateId}
                    render={({ field }) => (
                      <Select
                        sx={{ width: '240px', background: 'white' }}
                        {...field}
                        labelId="AccidentStateLabel"
                        tabIndex={11}
                        data-testid={'workersCompAccidentStateId'}
                      >
                        {states?.map((state) => {
                          return (
                            <MenuItem
                              key={state.id}
                              value={state.id}
                              data-testid={`state-${state.code!}`}
                            >
                              {state.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    )}
                  />
                  {!isEmpty(errors?.workersCompAccidentStateId) && (
                    <ErrorMessage
                      error={
                        (errors?.workersCompAccidentStateId
                          .message as string) ?? ''
                      }
                    />
                  )}
                </div>
                <div>
                  <InputLabel>Claim #</InputLabel>
                  <TextField
                    {...register('workersCompClaimNumber')}
                    tabIndex={2}
                    sx={{ width: '240px' }}
                    inputProps={{ 'data-testid': 'workersCompClaimNumber' }}
                  />
                </div>
              </DemographicsInnerRow>
            </AutoCompleteColumn>
          </DemographicsRow>
          <Divider
            sx={{ marginTop: '36px', marginBottom: '36px', color: '#DDDFE8' }}
            flexItem
          />
          <SectionLabel sectionLabelText="Patient Employer" />
        </>
      )}
      <DemographicsInnerRow sx={{ mb: 2 }}>
        <div>
          <InputLabel>
            Employer<RedText>{' *'}</RedText>
          </InputLabel>
          <TextField
            {...register(
              `${isWorkersComp ? 'workersCompEmployerName' : 'occMedEmployer'}`
            )}
            tabIndex={2}
            sx={{ width: '240px' }}
            inputProps={{
              'data-testid': `${
                isWorkersComp ? 'workersCompEmployerName' : 'occMedEmployer'
              }`,
            }}
          />
          {!isEmpty(errors?.workersCompEmployerName) && (
            <ErrorMessage
              error={errors?.workersCompEmployerName.message ?? ''}
            />
          )}
          {!isEmpty(errors?.occMedEmployer) && (
            <ErrorMessage error={errors?.occMedEmployer.message ?? ''} />
          )}
        </div>
        <div>
          <InputLabel>Contact Name</InputLabel>
          <TextField
            {...register(
              `${
                isWorkersComp
                  ? 'workersCompEmployerContactName'
                  : 'occMedContactName'
              }`
            )}
            tabIndex={2}
            sx={{ width: '240px' }}
            inputProps={{
              'data-testid': `${
                isWorkersComp
                  ? 'workersCompEmployerContactName'
                  : 'occMedContactName'
              }`,
            }}
          />
        </div>
      </DemographicsInnerRow>
      <AddressInput
        register={register}
        control={control}
        sectionName={`${
          isWorkersComp ? 'workersCompEmployerAddress' : 'occMedAddress'
        }`}
        setValue={setValue}
        errors={errors}
        setError={setError}
      />
      <AutoCompleteColumn sx={{ mt: 2 }}>
        <DemographicsInnerRow>
          <PhoneNumberInput
            control={control}
            sectionName={`${
              isWorkersComp ? 'workersCompEmployer' : 'occMedEmployer'
            }`}
            required
            error={
              isWorkersComp
                ? errors?.workersCompEmployerPhoneNumber
                : errors?.occMedEmployerPhoneNumber
            }
            setError={setError}
          />
          <div>
            <InputLabel>Email</InputLabel>
            <TextField
              tabIndex={14}
              sx={{ width: '240px' }}
              {...register(
                `${
                  isWorkersComp
                    ? 'workersCompEmployerEmail'
                    : 'occMedEmployerEmail'
                }`,
                {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  },
                }
              )}
              type="email"
              inputProps={{
                'data-testid': `${
                  isWorkersComp
                    ? 'workersCompEmployerEmail'
                    : 'occMedEmployerEmail'
                }`,
              }}
            />
            {!isEmpty(errors?.workersCompEmployerEmail) && (
              <ErrorMessage
                error={errors?.workersCompEmployerEmail.message ?? ''}
              />
            )}
            {!isEmpty(errors?.occMedEmployerEmail) && (
              <ErrorMessage error={errors?.occMedEmployerEmail.message ?? ''} />
            )}
          </div>
        </DemographicsInnerRow>
      </AutoCompleteColumn>
      {isModal && (
        <ModalButtonRow>
          <Box sx={{ flex: 1 }} />
          <ModalButton variant="outlined" type="reset" onClick={handleClose}>
            Cancel
          </ModalButton>
          <ModalButton variant="contained" type="submit">
            Save
          </ModalButton>
        </ModalButtonRow>
      )}
    </DemographicsContainer>
  )
}
interface WorkersCompOccMedProps {
  innerRef: React.RefObject<HTMLDivElement> | null;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  onFocus: any;
  setValue: any;
  errors: any;
  values?: any;
  setError: any;
  getValues: any;
  handleClose?: any;
  isWorkersComp?: boolean;
  isModal?: boolean;
}
