import { Chip, styled, Typography } from '@mui/material'
import React from 'react'
const TextBoxContainer = styled('div')({
  display: 'flex',
  backgroundColor: '#F7F8FC',
  gap: '4px',
  boxSizing: 'border-box',
  minHeight: '54px',
  width: '305px',
  border: '1px solid #EFEFEF',
  borderRadius: '11px',
  padding: '5px 8px 5px 8px',
  '& .MuiChip-label': {
    marginLeft: '-4px',
  },
})

const TextValue = styled(Typography)({
  lineHeight: '20px',
  size: '14px',
  weight: '400',
})

const StyledChip = styled(Chip)({
  backgroundColor: '#E5EBFF',
  height: '24px',
  width: '24px',
  size: '14px',
  weight: '400',
})

export const TextBlock = ({ text, handleAdd }: TextBlockProps): JSX.Element => {
  return (
    <TextBoxContainer>
      <StyledChip label="+" onClick={handleAdd} />
      <TextValue>{text}</TextValue>
    </TextBoxContainer>
  )
}

interface TextBlockProps {
  text: string;
  handleAdd: any;
}
