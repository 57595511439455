import {
  Chip,
  CircularProgress,
  Popover,
  Tooltip,
  styled,
  Skeleton,
} from '@mui/material'
import React, { type MouseEvent } from 'react'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import PatientDeniesIcon from './PatientDenies.svg'
import {
  type ExamNode,
  type ExamStatusType,
  type ExamResultIdType,
} from '@/types'
import {
  EXAM_ABNORMAL,
  EXAM_EMPTY,
  EXAM_NORMAL,
  ROS_DENIES,
  ROS_EMPTY,
  ROS_REPORTS,
} from '@/utility'
import { ExamSelect } from './ExamSelect'

const ExamChip = styled(Chip)({})

const ExamCircularProgress = styled(CircularProgress)({
  width: '24px !important',
  height: '24px !important',
})

const status2Color = {
  [EXAM_NORMAL]: 'success',
  [EXAM_ABNORMAL]: 'error',
  [ROS_DENIES]: 'success',
  [ROS_REPORTS]: 'error',
  [EXAM_EMPTY]: 'success',
}

type StatusColorType = 'success' | 'error';

const status2Icon = {
  [EXAM_NORMAL]: <CheckCircleOutlinedIcon />,
  [EXAM_ABNORMAL]: <ErrorIcon />,
  [ROS_DENIES]: (
    <PatientDeniesIcon style={{ marginLeft: '0px', marginRight: '-11px' }} />
  ),
  [ROS_REPORTS]: <ErrorIcon />,
  [EXAM_EMPTY]: <CircleOutlinedIcon />,
}

const statusColors = {
  [EXAM_NORMAL]: ['#2E7D324D', '#2E7D32'],
  [EXAM_ABNORMAL]: ['#DD13114D', '#DD1311'],
  [ROS_DENIES]: ['#2E7D324D', '#2E7D32'],
  [ROS_REPORTS]: ['#DD13114D', '#DD1311'],
  [EXAM_EMPTY]: ['#E0E0E0', '#0000008F'],
}

const statusForeColors = {
  [EXAM_NORMAL]: ['#1B5E20', 'white'],
  [EXAM_ABNORMAL]: ['#8F0A0A', 'white'],
  [ROS_DENIES]: ['#1B5E20', 'white'],
  [ROS_REPORTS]: ['#8F0A0A', 'white'],
  [EXAM_EMPTY]: ['#000000DE', 'white'],
}

type ExaminationStatus =
  | 'Normal'
  | 'Abnormal'
  | 'Empty'
  | 'Patient Reports'
  | 'Patient Denies';

export const ExamStatus = ({
  label,
  exam,
  status,
  examResultFactorIds,
  handleUpdateExam = () => {},
  small = false,
  examResponses,
  isLocked = false,
  type = 'exam',
  dropDownVisible = false,
  handleDropDownVisible = () => {},
  defaultStatus,
  isSaving,
}: ExamStatusProps): JSX.Element => {
  const hasChildren = !small && !!exam?.children.length
  let statusString: ExaminationStatus =
    (Object.entries(examResultFactorIds).find(
      (examResult) => examResult[1] === status
    )?.[0] as ExaminationStatus) ?? EXAM_EMPTY
  if (small && type !== 'exam') {
    if (statusString !== ROS_DENIES) {
      statusString = ROS_EMPTY
    } else {
      if (
        exam.children.some(
          (child) =>
            examResponses[child.id]?.status !== examResultFactorIds[ROS_DENIES]
        )
      ) {
        statusString = ROS_EMPTY
      }
    }
  }
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const chipRef = React.useRef<HTMLDivElement>(null)

  const bgColor = React.useMemo<string>(
    () => statusColors[statusString][+dropDownVisible],
    [statusString, dropDownVisible]
  )

  const foreColor = React.useMemo<string>(
    () => statusForeColors[statusString][+dropDownVisible],
    [statusString, dropDownVisible]
  )

  const handleChipClick = (e: React.MouseEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    if (small && type !== 'exam') {
      let newState = examResultFactorIds[ROS_DENIES]
      if (status === examResultFactorIds[ROS_DENIES]) {
        newState = examResultFactorIds[ROS_EMPTY]
      }
      const symptomIds = exam.children.map(({ id }) => id)
      setIsLoading(true)
      Promise.all(
        symptomIds.map(async (id) => {
          await handleUpdateExam(id, newState)
        })
      ).finally(() => setIsLoading(false))
    }
    if (small || isLocked) {
      return
    }
    const nextState =
      type === 'exam' ? (status % 3) + 1 : (((status % 3) + 1) % 3) + 1
    setIsLoading(true)
    handleUpdateExam(exam?.id, nextState).finally(() => setIsLoading(false))
  }

  React.useEffect(() => {
    if (small && type === 'exam' && status !== examResponses[exam.id]?.status) {
      setIsLoading(true)
      handleUpdateExam(exam?.id, status).finally(() => setIsLoading(false))
    }
  }, [status, type, small])

  const handleOnDelete = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault()
    handleDropDownVisible(true)
  }

  const handlePopoverClose = (): void => {
    handleDropDownVisible(false)
  }

  const handleUpdateProcess = (id: number, status: number): void => {
    setIsLoading(true)
    handleUpdateExam(id, status).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <>
      <Tooltip
        title={
          small && type !== 'exam'
            ? 'Mark all as patient denies'
            : label.join(' - ')
        }
        arrow
        onClick={handleChipClick}
      >
        {!isSaving ? (
          <ExamChip
            disabled={isLocked}
            label={
              small
                ? type === 'exam'
                  ? statusString
                  : 'Mark all as patient denies'
                : label.length < 2
                ? label[0]
                : label.slice(-2).join(' - ') ?? ''
            }
            icon={
              !isLoading ? status2Icon[statusString] : <ExamCircularProgress />
            }
            color={status2Color[statusString] as StatusColorType}
            ref={chipRef}
            sx={{
              backgroundColor: bgColor,
              color: foreColor,
              borderRadius: '5px',
              height: small ? '24px' : '34px',
              '&:hover': {
                backgroundColor: bgColor,
                color: foreColor,
              },
            }}
            onDelete={hasChildren ? handleOnDelete : undefined}
            deleteIcon={
              hasChildren ? (
                <ArrowDropDownIcon
                  sx={{ fill: foreColor, width: '26px', height: '26px' }}
                />
              ) : undefined
            }
          />
        ) : (
          <Skeleton
            variant="rounded"
            width={`${
              (label.length <= 2
                ? label[0].length
                : label.slice(-2).join(' - ').length) *
                7 +
              35
            }px`}
            height={small ? '24px' : '34px'}
          />
        )}
      </Tooltip>
      <Popover
        id={`exampopover-${exam?.id}`}
        open={dropDownVisible}
        anchorEl={dropDownVisible ? chipRef.current : null}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <ExamSelect
          examResultFactorIds={examResultFactorIds}
          examination={exam}
          examResponses={examResponses}
          handleUpdateExam={handleUpdateProcess}
          defaultStatus={defaultStatus}
          isSaving={false}
        />
      </Popover>
    </>
  )
}

interface ExamStatusProps {
  label: string[];
  status: number;
  small?: boolean;
  examResultFactorIds: ExamResultIdType;
  handleUpdateExam?: any;
  exam: ExamNode;
  examResponses: ExamStatusType[];
  defaultStatus?: number;
  isLocked?: boolean;
  type?: string;
  dropDownVisible?: boolean;
  handleDropDownVisible?: any;
  isSaving: boolean;
}
