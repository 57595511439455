import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { StyledEditButton } from '../../DocumentTab'
export const PDFWarningDialog = ({
  isOpen,
  handleClose,
}: PDFWarningDialogProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState(isOpen)
  React.useEffect(() => {
    setDialogOpen(isOpen)
  }, [isOpen])
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <DialogTitle>Upload Error</DialogTitle>
      <DialogContent>
        <DialogContentText>Letter template must be a PDF</DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledEditButton onClick={handleClose}>OK</StyledEditButton>
      </DialogActions>
    </Dialog>
  )
}
interface PDFWarningDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}
