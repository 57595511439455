import React, { type ReactNode } from 'react'

import {
  Chip,
  MenuItem,
  Select,
  type SelectChangeEvent,
  styled,
  useTheme,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

import { TileInputLabel } from '../TileInputLabel'

const StyledSelected = styled(Select)(({ fullsize, compact }: SelectProp) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: compact ? '240px' : fullsize ? '766px' : '502px',
  height: '40px !important',
  backgroundColor: 'white',
  borderRadius: 0,
  paddingTop: 2.5,
  '& .MuiOutlinedInput-input': {
    marginTop: '-10px',
    height: '40px !important',
  },
  '& > *': {
    maxHeight: '40px !important',
  },
}))

export const PillSelect = ({
  options,
  values,
  onChange,
  label,
  setValues,
  fullsize = false,
  compact = false,
  dataTestId,
}: PillSelectProps): JSX.Element => {
  const theme = useTheme()

  return (
    <div data-testid={`${dataTestId}-div`}>
      <TileInputLabel data-testid={`${dataTestId}-label`}>
        {label}
      </TileInputLabel>
      <StyledSelected
        fullsize={fullsize}
        compact={compact}
        multiple
        value={values}
        onChange={onChange}
        inputProps={{
          'data-testid': `${dataTestId}-select`,
        }}
        renderValue={(selected) => (
          <div
            style={{
              display: 'flex',
              gap: 0.5,
              alignContent: 'center',
              alignItems: 'center',
            }}
            data-testid={`${dataTestId}-chip-div`}
          >
            {(selected as number[]).map((value) => (
              <Chip
                sx={{
                  marginTop: '6px',
                  color: theme.palette.primary.main,
                  backgroundColor: 'rgba(5, 67, 176, 0.08)',
                  '& .MuiSvgIcon-root': {
                    color: '#fff',
                  },
                  maxHeight: '30px',
                }}
                key={value}
                label={
                  options?.find((complaint: any) => complaint.id === value)
                    ?.name
                }
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
                onDelete={() => {
                  setValues(values.filter((complaint) => complaint !== value))
                }}
                data-testid={`${dataTestId}-chip-${value}`}
              />
            ))}
          </div>
        )}
      >
        {options &&
          Array.isArray(options) &&
          options.map((type: any) => {
            return (
              <MenuItem
                key={type.id}
                value={type.id}
                data-testid={`${dataTestId}-menuitem-${
                  (type.id as number) ?? 0
                }`}
              >
                {type.name}
              </MenuItem>
            )
          })}
      </StyledSelected>
    </div>
  )
}

interface PillSelectProps {
  options: any;
  values: number[];
  setValues: (values: number[]) => void;
  onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
  label: string;
  fullsize?: boolean;
  compact?: boolean;
  dataTestId: string;
}

interface SelectProp {
  fullsize: boolean;
  compact: boolean;
}
