/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/quotes */
import React from 'react'
import {
  Eventcalendar,
  type MbscResource,
  type MbscCalendarEventData,
  type MbscCalendarEvent,
  type MbscEventcalendarView,
  type MbscEventClickEvent,
} from '@mobiscroll/react'
import { styled, Typography } from '@mui/material'
import {
  type Room,
  type Visit,
  type AccountUser,
  type Complaint,
  type VisitType,
} from '@/types'
import { VisitBlock } from './Visit'
import { isSameDay, add, isBefore, formatISO } from 'date-fns'
import { type OnlineAppointment } from '@/types/OnlineAppointment'
import { AppointmentScheduler } from '../AppointmentsCalendar/AppointmentScheduler'
import { useScheduleState } from '@/context/SchedulingContext'
import { safelyParseJSON } from '../AppointmentsCalendar/AppointmentUtils'
import { type EventcalendarBase } from '@mobiscroll/react/dist/src/core/components/eventcalendar/eventcalendar'
import { useRouter } from 'next/navigation'

const RoomNameText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '16px',
  color: theme.palette.black,
}))

export const RoomsCalendar = ({
  dailySchedule,
  rooms,
  clinicians,
  eventUpdateFail,
  onEventCreated,
  selectedDate,
  groupBy,
  providers,
  complaints,
  visitTypes,
  onMoveBackToQueue,
  onOpenChart,
  onDeleteVisit,
  onProfileView,
  refresh,
  isNavOpen,
}: RoomsCalendarProps): JSX.Element => {
  const router = useRouter()
  const [myResources, setMyResources] = React.useState<MbscResource[]>([])
  const [roomEvents, setRoomEvents] = React.useState<MbscCalendarEvent[]>([])

  const { isOpen } = useScheduleState()

  const view: MbscEventcalendarView = {
    schedule: {
      type: 'day',
      allDay: false,
      startDay: 0,
      endDay: 6,
      startTime: '00:00',
      endTime: '24:00',
      timeCellStep: 15,
      timeLabelStep: 15,
      days: false,
    },
  }

  const renderDay = (args: any): any => {}

  const isVisit = (appointment: any): appointment is Visit => {
    return appointment?.chiefComplaint !== undefined
  }

  const mapRoom = (room: Room): MbscResource => {
    return { id: `${room.id!}`, name: room.name! }
  }

  const mapProvider = (provider: AccountUser): MbscResource => {
    return {
      id: `${provider.id!}`,
      name: `${provider.firstName!} ${provider.lastName!}`,
    }
  }

  const mapRooms = (): MbscResource[] => {
    return rooms?.map(mapRoom)
  }

  const mapProviders = (): MbscResource[] => {
    return providers.map(mapProvider)
  }

  const eventDoubleClick = async (
    e: MbscEventClickEvent,
    inst: EventcalendarBase
  ): Promise<void> => {
    const appointment = JSON.parse(e.event.title!)
    if ('chiefComplaint' in appointment) {
      router.push(
        `/charting/${appointment.id as number}?previousNavOpen=${
          isNavOpen ? 'true' : 'false'
        }`
      )
    } else {
      let firstName = ''
      let lastName = ''
      let phoneNumber = ''
      let email = ''
      let dateOfBirth = ''
      let visitTypeId = 0
      let providerId = 0
      let roomId = 0
      let appointmentStartDate = ''
      let appointmentEndDate = ''
      if (
        appointment.patient &&
        appointment.patient !== null &&
        appointment.patient !== undefined
      ) {
        try {
          const res = appointment.patient
          firstName = res.firstName ?? ''
          lastName = res.lastName ?? ''
          phoneNumber = res.phoneNumber ?? ''
          email = res.email ?? ''
          dateOfBirth = JSON.stringify(res.dateOfBirth) ?? ''
        } catch (error) {
          console.error('Error fetching patient data:', error)
        }
      } else {
        firstName = appointment.firstName
        lastName = appointment.lastName
        phoneNumber = appointment.phoneNumber
        email = appointment.email
        dateOfBirth = JSON.stringify(appointment.dateOfBirth)
      }
      if ('providerId' in appointment) {
        providerId = appointment.providerId
      }
      if ('roomId' in appointment) {
        roomId = appointment.roomId
      }
      if ('visitTypeId' in appointment) {
        visitTypeId = appointment.visitTypeId
      }
      if ('appointmentStartDate' in appointment) {
        appointmentStartDate = appointment.appointmentStartDate
      }
      if ('appointmentEndDate' in appointment) {
        appointmentEndDate = appointment.appointmentEndDate
      }

      goToIntake(
        appointment.id,
        firstName,
        lastName,
        phoneNumber,
        email,
        dateOfBirth,
        providerId,
        roomId,
        visitTypeId,
        appointmentStartDate,
        appointmentEndDate
      )
    }
  }

  const handleEventDoubleClick = (
    e: MbscEventClickEvent,
    inst: EventcalendarBase
  ): void => {
    eventDoubleClick(e, inst).catch((error) => {
      console.error('Error in eventDoubleClick:', error)
    })
  }

  React.useEffect(() => {
    Promise.resolve(refresh()).catch((error) => {
      console.error('Error in RoomsCalendar useEffect:', error)
    })
  }, [isOpen])

  React.useEffect(() => {
    setMyResources(groupBy === 'rooms' ? mapRooms() : mapProviders())
  }, [groupBy, rooms, providers])

  React.useEffect(() => {
    const events: MbscCalendarEvent[] = []
    if (dailySchedule?.length > 0) {
      dailySchedule.forEach((appointment: Visit | OnlineAppointment) => {
        let endTime: Date
        let startTime: Date
        let resourceId: string

        if (isVisit(appointment)) {
          startTime = new Date(appointment.scheduleStart!)
          endTime = appointment.scheduleEnd
            ? new Date(appointment.scheduleEnd)
            : add(startTime, { minutes: 15 })
          resourceId =
            groupBy === 'rooms'
              ? `${appointment.roomId ?? 'Unknown'}`
              : `${appointment.providerAccountUserId ?? 'Unknown'}`
        } else {
          startTime = new Date(appointment.appointmentStartDate!)
          endTime = appointment.appointmentEndDate
            ? new Date(appointment.appointmentEndDate)
            : add(startTime, { minutes: 15 })
          resourceId =
            groupBy === 'rooms'
              ? 'Online/Recurring'
              : `${appointment.providerId ?? 'Unknown'}`
        }

        if (startTime && endTime) {
          events.push({
            start: formatISO(startTime),
            end: formatISO(endTime),
            title: JSON.stringify(appointment),
            resource: resourceId,
            editable: !isBefore(startTime, new Date()),
          })
        }
      })
    }
    setRoomEvents(events)
  }, [dailySchedule, groupBy, rooms, providers])

  const goToIntake = (
    id: number,
    firstName: string,
    lastName: string,
    phoneNumber?: string,
    email?: string,
    dateOfBirth?: string,
    providerId?: number,
    roomId?: number,
    visitTypeId?: number,
    appointmentStartDate?: string,
    appointmentEndDate?: string
  ): void => {
    const queryParams = [
      isNavOpen && 'previousNavOpen=true',
      `firstName=${firstName}`,
      `lastName=${lastName}`,
      phoneNumber && `phone=${phoneNumber}`,
      email && `email=${email}`,
      dateOfBirth && `dateOfBirth=${dateOfBirth}`,
      `appointmentId=${id}`,
      providerId && `providerId=${providerId}`,
      roomId && `roomId=${roomId}`,
      visitTypeId && `visitTypeId=${visitTypeId}`,
      appointmentEndDate &&
        `appointmentEndDate=${new Date(appointmentEndDate).toISOString()}`,
      appointmentStartDate &&
        `appointmentStartDate=${new Date(appointmentStartDate).toISOString()}`,
    ]
      .filter(Boolean)
      .join('&')
    const urlString = `/intake?${queryParams}`
    router.push(urlString)
  }

  const renderScheduleEvent = (data: MbscCalendarEventData): any => {
    const appointment = safelyParseJSON(data.title!) as
      | Visit
      | OnlineAppointment
    let complaint = ''
    let visitType = ''

    if (isVisit(appointment)) {
      complaint =
        complaints?.find((complaint: Complaint) => {
          if (
            !appointment.chiefComplaint?.complaintList ||
            appointment.chiefComplaint.complaintList.length === 0
          ) {
            return false
          }
          return complaint.id === appointment.chiefComplaint.complaintList[0]
        })?.name ?? ''
      visitType =
        visitTypes?.find((visitType: VisitType) => {
          if (
            !appointment.chiefComplaint?.visitTypeId ||
            appointment.chiefComplaint?.visitTypeId === 0
          ) {
            return false
          }
          return visitType.id === appointment.chiefComplaint?.visitTypeId
        })?.name ?? ''
    } else {
      visitType =
        visitTypes?.find((visitType: VisitType) => {
          if (!appointment?.visitTypeId || appointment?.visitTypeId === 0) {
            return false
          }
          return visitType.id === appointment.visitTypeId
        })?.name ?? 'Appointment'
    }
    return (
      <VisitBlock
        visit={appointment}
        clinicians={clinicians}
        rooms={rooms}
        complaint={complaint}
        visitType={visitType}
        groupBy={groupBy}
        onMoveToQueue={onMoveBackToQueue}
        onOpenChart={onOpenChart}
        onDeleteVisit={onDeleteVisit}
        onProfileView={onProfileView}
        refresh={refresh}
        isNavOpen={isNavOpen}
      />
    )
  }
  const renderCustomResource = (resource: MbscResource): any => {
    return (
      <div
        style={{
          alignContent: 'flex-start',
          display: 'flex',
        }}
      >
        <RoomNameText>{resource.name}</RoomNameText>
      </div>
    )
  }
  const renderHeader = (): any => {}
  return (
    <>
      <Eventcalendar
        selectedDate={
          isSameDay(selectedDate, new Date(Date.now()))
            ? new Date(Date.now())
            : selectedDate
        }
        class="EventCalendar"
        renderScheduleEvent={renderScheduleEvent}
        showEventTooltip={false}
        theme="material"
        themeVariant="light"
        clickToCreate="single"
        dragToCreate={false}
        dragToMove={true}
        dragToResize={true}
        eventDelete={true}
        view={view}
        data={roomEvents}
        resources={myResources}
        groupBy="date"
        renderResource={renderCustomResource}
        renderDay={renderDay}
        renderHeader={renderHeader}
        showControl={false}
        externalDrop={true}
        calendarScroll={true}
        onEventCreated={onEventCreated}
        onEventUpdated={onEventCreated}
        onEventCreateFailed={eventUpdateFail}
        onEventUpdateFailed={eventUpdateFail}
        onEventDoubleClick={handleEventDoubleClick}
      />
      <AppointmentScheduler />
    </>
  )
}
interface RoomsCalendarProps {
  dailySchedule: Array<Visit | OnlineAppointment>;
  rooms: Room[];
  clinicians: AccountUser[];
  onEventCreated: any;
  eventUpdateFail: any;
  selectedDate: Date;
  groupBy: string;
  providers: AccountUser[];
  complaints: Complaint[];
  visitTypes: VisitType[];
  onMoveBackToQueue: any;
  onOpenChart: any;
  onDeleteVisit: any;
  onProfileView: any;
  refresh: any;
  isNavOpen: boolean;
}
