import { Typography, IconButton, MenuItem } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import React from 'react'
import { type UploadFileStatus } from '@/types'
import {
  FileContainer,
  FileRow,
  FileNameTypography,
  FileTypeSelect,
  StatusRow,
  StatusIcon,
  ExamCircularProgress,
} from './styles'

export const FileItem = ({
  file,
  handleDelete,
  index,
  handleSelectChange,
  selectedFileTypes,
  fileStatus,
  docTypes,
}: {
  file: File;
  handleDelete: () => void;
  index: number;
  handleSelectChange: (arg0: number, arg1: number) => void;
  selectedFileTypes: number[] | undefined;
  fileStatus: UploadFileStatus | undefined;
  docTypes: any[];
}): JSX.Element => (
  <FileContainer>
    <FileRow>
      <FileNameTypography>{file.name}</FileNameTypography>
      {selectedFileTypes && docTypes.length > 0 && (
        <FileTypeSelect
          value={selectedFileTypes[index] || ''}
          onChange={(e) => handleSelectChange(index, e.target.value as number)}
        >
          {docTypes?.map(({ id, name }) => (
            <MenuItem value={id} key={id}>
              {name}
            </MenuItem>
          ))}
        </FileTypeSelect>
      )}
      <IconButton aria-label="delete" size="small" onClick={handleDelete}>
        <ClearIcon />
      </IconButton>
    </FileRow>
    {fileStatus?.progress === 100 && (
      <StatusRow>
        {fileStatus?.error && <StatusIcon as={ErrorIcon} color="red" />}
        {fileStatus?.errorMessage && (
          <Typography sx={{ color: 'red' }}>
            {fileStatus?.errorMessage}
          </Typography>
        )}
        {fileStatus?.success && (
          <StatusIcon as={CheckCircleIcon} color="green" />
        )}
        {fileStatus?.success && (
          <Typography sx={{ color: 'green' }}>Success</Typography>
        )}
      </StatusRow>
    )}
    {fileStatus?.isUploading && (
      <StatusRow>
        <>
          <ExamCircularProgress
            variant="determinate"
            value={fileStatus.progress}
          />
        </>
      </StatusRow>
    )}
  </FileContainer>
)
