import { deleteDocument } from '@/services'
import { useAuth } from '@clerk/nextjs'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import {
  type RefetchOptions,
  type RefetchQueryFilters,
  type QueryObserverResult,
} from '@tanstack/react-query'
import React from 'react'
import { StyledEditButton } from './DocumentTab'
import { type UrgentIQDocuments } from '@/types'

export const DeleteWarningDialog = ({
  documentName,
  deleteDocId,
  isOpen,
  handleClose,
  refreshDocuments,
}: DeleteWarningDialogProps): JSX.Element => {
  const { getToken } = useAuth()
  const [dialogOpen, setDialogOpen] = React.useState(isOpen)
  React.useEffect(() => {
    setDialogOpen(isOpen)
  }, [isOpen])
  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <DialogTitle>Delete Document</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Are you sure you want to delete ${documentName}?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <StyledEditButton onClick={handleClose}>Cancel</StyledEditButton>
        <StyledEditButton
          variant="contained"
          onClick={() => {
            (async () => {
              await deleteDocument(deleteDocId, getToken)
            })()
              .catch((error) => {
                throw error
              })
              .finally(() => {
                refreshDocuments().catch((error) => {
                  throw error
                })
              })
            handleClose()
          }}
        >
          Delete
        </StyledEditButton>
      </DialogActions>
    </Dialog>
  )
}
interface DeleteWarningDialogProps {
  documentName: string;
  deleteDocId: number;
  isOpen: boolean;
  handleClose: () => void;
  refreshDocuments: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<UrgentIQDocuments | any[], unknown>>;
}
