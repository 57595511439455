import { useReferenceDataStore, useVitalsStore } from '@/hook'
import styled from '@emotion/styled'
import { TextField, Autocomplete } from '@mui/material'
import React from 'react'
import { TileInputLabel } from '../TileInputLabel'
import { useAuth } from '@clerk/nextjs'
import { dateOnlyToDate, isDateOnlyValid, parseDateToDateOnly } from '@/utility'
import { isBefore } from 'date-fns'

const ValueContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
})

interface StyledTextFieldProps {
  fullsize: boolean;
  twoColumns: boolean;
  customWidth?: number;
}

const StyledTextField = styled(TextField)(
  ({ fullsize, twoColumns, customWidth }: StyledTextFieldProps) => ({
    width: customWidth? `${customWidth}px` : fullsize ? '766px' : twoColumns ? '506px' : '240px',
    height: '40px !important',
    '& > div': {
      borderRadius: 0,
      height: '40px',
      backgroundColor: 'white',
      '& > input': {
        marginTop: '-2px',
      },
    },
  })
)

interface LMPInputProps {
  visitId: number
  patientId: number
}

export const LMPInput = ({ visitId, patientId }: LMPInputProps): JSX.Element => {
    const { getToken } = useAuth()
    const { menstrualDetails } = useReferenceDataStore()
    const { chiefVital, mutateChiefVital } = useVitalsStore()

    const defaultLMP =
        menstrualDetails?.find((md) => md.id === chiefVital?.menstruationDetailsId)?.displayValue ??
          (
            isDateOnlyValid(chiefVital?.lastMenstrualPeriodDate) ?
              dateOnlyToDate(chiefVital?.lastMenstrualPeriodDate)?.toLocaleDateString() : null
          ) ??
          chiefVital?.lastMenstrualPeriod ?? null

    const [inputValue, setInputValue] = React.useState<string | null>(defaultLMP)

    const handleInput = (event: React.SyntheticEvent<Element, Event>, value: string, reason: string): void => {
      switch (reason) {
        case 'clear':
          setInputValue(null)
          void mutateChiefVital(
            visitId,
            {
              visitId,
              patientId,
              menstruationDetailsId: null,
              lastMenstrualPeriod: null,
              lastMenstrualPeriodDate: undefined,
            },
            getToken
          )
          break
        case 'reset':
          setInputValue(value)
          void mutateChiefVital(
            visitId,
            {
              visitId,
              patientId,
              menstruationDetailsId: menstrualDetails?.find((md) => md.displayValue === value)?.id ?? undefined,
              lastMenstrualPeriod: undefined,
              lastMenstrualPeriodDate: undefined,
            },
            getToken
          )
          break
        case 'input': {
          setInputValue(value)
          break
        }
        default:
          break
      }
    }

    const handleBlur = (): void => {
      if (inputValue === chiefVital?.lastMenstrualPeriod
        || inputValue === null
      ) return

      const menstruationDetailsId = menstrualDetails?.find((md) => md.displayValue === inputValue)?.id
      const lastMenstrualPeriodDate = !Number.isNaN(new Date(inputValue).getTime()) && isBefore(new Date(inputValue).getTime(), new Date()) ? parseDateToDateOnly(new Date(inputValue)) : undefined
      const lastMenstrualPeriod = !menstruationDetailsId && !lastMenstrualPeriodDate ? inputValue : null

      const payload = {
        visitId,
        patientId,
        lastMenstrualPeriod,
        menstruationDetailsId,
        lastMenstrualPeriodDate,
      }

      void mutateChiefVital(
        visitId,
        payload,
        getToken
      )
    }

    return (
    <ValueContainer>
      <div>
        <TileInputLabel>Last Menstrual Period</TileInputLabel>
        <Autocomplete
          freeSolo={inputValue !== null}
          onInputChange={handleInput}
          options={menstrualDetails?.map((md) => md.displayValue ?? '') ?? []}
          componentsProps={{
            paper: {
              style: { marginTop: '6px', marginBottom: '6px' },
            },
          }}
          renderOption={(props, option) => (
            <li
              {...props}
              style={{ width: '766px' }}
              data-testid={option}
            >
              {option}
            </li>
          )}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              fullsize={false}
              twoColumns={false}
              inputProps={{
                ...params.inputProps,
                'data-testId': 'LMPInput',
                value: inputValue ?? '',
                onBlur: handleBlur,
              }}
            />
          )}
        />
      </div>
    </ValueContainer>
  )
}
