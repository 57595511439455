import React from 'react'
import { PopoverTileText } from '../../components/PopoverTileText'
import { PopoverValueText } from '../../components/PopoverValueText'
import { PopoverContainer } from '../../layout/PopoverContainer'
import { IconButton } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export const MedicationsHoverOver = ({
  name,
  date,
  route,
  isMedicationsMissing = false,
}: MedicationsHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Name</PopoverTileText>
        <PopoverValueText>{name}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Date</PopoverTileText>
        <PopoverValueText>{date}</PopoverValueText>
      </div>
      <div>
        {isMedicationsMissing ? (
          <div style={{ color: 'rgb(223, 115, 45)' }}>
            <IconButton color="warning" size="large">
              <WarningAmberIcon />
            </IconButton>
            Not included in allergy interactions
          </div>
        ) : (
          <div />
        )}
      </div>
    </PopoverContainer>
  )
}

interface MedicationsHoverOverProps {
  name: string;
  date: string;
  route: string;
  isMedicationsMissing?: boolean;
}
