import React from 'react'
import { StyleInputLabel, StyledTextButton } from '../FreeTextNotes/styles'
import { Box, Button, styled } from '@mui/material'
import { TileTextArea } from '../components'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { type Anchor } from './ExamList'

const StyledRow = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
})

const NotesText = styled(Box)({
  width: '100%',
})

const AddItemButton = styled(Button)({
  width: 'auto',
  height: '34px',
  fontSize: '13px',
  borderRadius: '5px',
})

export const ExamNotes = ({
  note,
  handleAddNote,
  addItemButtonAnchorEl,
  handleAddItem,
  isLocked,
  type = 'exam',
}: ExamNotesProps): JSX.Element => {
  const [isNoteShown, setIsNoteShown] = React.useState<boolean>(false)
  const [notes, setNotes] = React.useState<string>(note ?? '')

  const handleOpenNote = (): void => setIsNoteShown(true)

  const handleCloseNote = (): void => setIsNoteShown(false)

  const handleCancelNote = (): void => {
    handleCloseNote()
    setNotes(note ?? '')
  }

  const handleOnBlur = (): void => {
    if (note !== notes) {
      handleAddNote(notes)
    }
  }

  return (
    <>
      {!isNoteShown && (
        <>
          <StyledRow>
            {type === 'exam' ? (
              <AddItemButton
                startIcon={<AddCircleIcon />}
                variant={addItemButtonAnchorEl ? 'contained' : 'outlined'}
                color="primary"
                disabled={isLocked}
                onClick={handleAddItem}
              >
                Add Item
              </AddItemButton>
            ) : (
              <div></div>
            )}
            <StyledTextButton
              sx={{
                fontStyle: 'normal',
                textAlign: 'left',
                textDecorationLine: 'underline',
                color: '#0543B0',
                width: 'auto',
                minWidth: '0px',
              }}
              variant="text"
              disabled={isLocked}
              onClick={handleOpenNote}
            >
              Add Note
            </StyledTextButton>
          </StyledRow>
          <StyledRow>
            <NotesText>{notes}</NotesText>
          </StyledRow>
        </>
      )}
      {isNoteShown && (
        <>
          <StyledRow>
            <StyleInputLabel sx={{ flex: 1 }}>Note</StyleInputLabel>
            <StyledTextButton
              variant="text"
              sx={{
                color: 'rgb(13, 71, 173)',
              }}
              disabled={isLocked}
              onClick={handleCancelNote}
            >
              Cancel Note
            </StyledTextButton>
          </StyledRow>
          <StyledRow>
            <TileTextArea
              onBlur={handleOnBlur}
              value={notes}
              onChange={(e: any) => setNotes(e.target.value)}
              fullsize
              fullWidth
              disabled={isLocked}
              note
              dataTestId="note"
            />
          </StyledRow>
        </>
      )}
    </>
  )
}

interface ExamNotesProps {
  note?: string;
  handleAddNote: any;
  addItemButtonAnchorEl: Anchor;
  handleAddItem: any;
  isLocked: boolean;
  type: string;
}
