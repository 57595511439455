import {
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Checkbox,
} from '@mui/material'
import React from 'react'
import {
  TileInputLabel,
  TileRow,
  TileColumn,
  TileSubtitle,
  NotesRow,
  AddTable,
  MedicalConditionsRow,
  TileSearchBox,
  HeaderPMH,
  Category,
} from '@/components'
import {
  type MedicationConditionType,
  type PatientMedicalCondition,
} from '@/types'
import { isEmpty } from '@/utility/utils'
import { useGlobalStore } from '@/hook'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '18.75px',
  color: 'black',
}))

const CheckBoxRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const MedicalConditions = ({
  patientId,
  medicalConditions,
  addMedicalCondition,
  deleteMedicalCondition,
  notes,
  setNotes,
  problem,
  setProblem,
  medicalConditionsTypes,
  deniesMedicationCondition,
  setDeniesMedicationCondition,
}: MedicalConditionsProps): JSX.Element => {
  const [inputValue, setInputValue] = React.useState<string>('')
  const { setIsEditing } = useGlobalStore()
  const [lastKeyDown, setLastKeyDown] = React.useState<string>('')
  const [activeOrHistorical, setActiveOrHistorical] = React.useState<
    boolean | undefined
  >(true)

  const [
    internalDeniesMedicationCondition,
    setInternalDeniesMedicationCondition,
  ] = React.useState<boolean>(deniesMedicationCondition)
  React.useEffect(() => {
    setInternalDeniesMedicationCondition(deniesMedicationCondition)
  }, [deniesMedicationCondition])

  React.useEffect(() => {
    if (inputValue) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
  }, [inputValue])
  const handleCheckBoxChange = (event: any): void => {
    setInternalDeniesMedicationCondition(event.target.checked)
    setDeniesMedicationCondition(event.target.checked)
  }

  const handleActiveOrHistorical = (event: any): void => {
    setActiveOrHistorical(event.target.value)
  }

  const handleAddMedicalCondition = (problemDefault: any = null): void => {
    const currentProblem = problemDefault || problem
    if (
      (currentProblem.id !== 0 || inputValue !== '') &&
      activeOrHistorical !== undefined
    ) {
      addMedicalCondition({
        patientId,
        ...(currentProblem.id > 0
          ? { medicalConditionId: currentProblem.id }
          : { problem: inputValue }),
        isActive: activeOrHistorical,
      }).catch((err) => {
        throw err
      })
      setProblem({ id: 0, label: '' })
      setIsEditing(false)
    }
  }

  const handleDeleteMedicalCondition = (
    medicalCondition: PatientMedicalCondition
  ): void => {
    deleteMedicalCondition(medicalCondition).catch((err) => {
      throw err
    })
  }
  const handleEditMedicalCondition = (
    medicalCondition: PatientMedicalCondition
  ): void => {
    medicalCondition.problem =
      medicalConditionsTypes.find(
        (mt) => mt.id === medicalCondition.medicalConditionId
      )?.name ??
      medicalCondition.problem ??
      ''
    setInputValue(medicalCondition.problem ?? '')
    const _medicalCondition = medicalConditionsTypes.find(
      (mt) => mt.name === medicalCondition.problem
    )
    setProblem({
      id: _medicalCondition?.id,
      label: _medicalCondition?.name ?? '',
    })
    setActiveOrHistorical(medicalCondition.isActive)
    handleDeleteMedicalCondition(medicalCondition)
  }
  const buildConditions = (): JSX.Element => {
    return (
      <AddTable>
        {medicalConditions.map((condition) => {
          let name = ''
          if (condition.medicalConditionId === null) {
            name = condition.problem ?? ''
          } else {
            name =
              medicalConditionsTypes.find(
                (mt) => mt.id === condition.medicalConditionId
              )?.name ?? ''
          }
          return (
            <MedicalConditionsRow
              key={condition.id}
              medicalCondition={condition}
              name={name}
              delete={handleDeleteMedicalCondition}
              edit={handleEditMedicalCondition}
            />
          )
        })}
      </AddTable>
    )
  }

  const handleProcedureTypeChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    if (newValue === null) {
      setProblem({ id: 0, label: '' })
    } else {
      const newProblem = { id: newValue.id ?? 0, label: newValue.label ?? '' }
      setProblem(newProblem)
      // add medication when medication is picked by keydown
      if (lastKeyDown === 'ArrowDown') {
        handleAddMedicalCondition(newProblem)
      }
    }
  }

  const buildMedicalConditions = (): JSX.Element => {
    return (
      <>
        <TileRow key={'medicalConditions'}>
          <TileSearchBox
            dataTestId="mcProblem"
            label={'Problem'}
            value={problem}
            inputValue={inputValue}
            setInputValue={setInputValue}
            twoColumns
            onChange={(event: any, value: any) =>
              handleProcedureTypeChange(event, value)
            }
            options={medicalConditionsTypes.map((st) => {
              return {
                id: st.id ?? 0,
                label: st.name ?? '',
              }
            })}
            onKeyDown={(e) => setLastKeyDown(e.code)}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              width: '240px',
            }}
          >
            <TileInputLabel>Onset</TileInputLabel>

            <RadioGroup
              row
              onChange={handleActiveOrHistorical}
              value={activeOrHistorical}
            >
              <StyledFormControlLabel
                key={'active'}
                value={true}
                control={<Radio data-testId="mcActive" />}
                label={'Active'}
              />
              <StyledFormControlLabel
                key={'historical'}
                value={false}
                control={<Radio data-testId="mcHistorical" />}
                label={'Historical'}
              />
            </RadioGroup>
          </div>
        </TileRow>
      </>
    )
  }

  return (
    <Category>
      <TileRow>
        <TileColumn>
          <TileRow>
            <HeaderPMH>Medical Conditions</HeaderPMH>
            <CheckBoxRow>
              <TileSubtitle>Patient Denies</TileSubtitle>
              <Checkbox
                checked={internalDeniesMedicationCondition}
                onChange={handleCheckBoxChange}
                data-testId="mcDenies-checkbox"
                sx={{ padding: 0, pl: 1 }}
              />
            </CheckBoxRow>
          </TileRow>
          {buildMedicalConditions()}
          <NotesRow
            notes={notes}
            setNotes={setNotes}
            title={'Add Medical Condition'}
            handleAdd={() => handleAddMedicalCondition()}
            expanded={!isEmpty(notes)}
          />
        </TileColumn>
      </TileRow>
      {
        /* prettier-ignore */
        medicalConditions.length > 0
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              {/* prettier-ignore */}
              {buildConditions()}
              {/* prettier-ignore */}
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }
    </Category>
  )
}

interface MedicalConditionsProps {
  patientId: number;
  medicalConditions: PatientMedicalCondition[];
  addMedicalCondition: (
    medicalCondition: PatientMedicalCondition
  ) => Promise<void>;
  deleteMedicalCondition: (
    medicalCondition: PatientMedicalCondition
  ) => Promise<void>;
  notes: string;
  setNotes: (notes: string) => void;
  problem: { id: number; label: string };
  setProblem: any;
  medicalConditionsTypes: MedicationConditionType[];
  deniesMedicationCondition: boolean;
  setDeniesMedicationCondition: any;
}
