import React from 'react'
import { TileSearchBox } from '@/components/Charting'
import { useDebounce } from '@/hook'

export const PatientHistorySelectWrapper = ({
  options,
  value,
  onChange,
  label,
  accessor,
  defaultValue,
}: {
  options: Array<{ id?: any; label: string; code?: string; value: any }>;
  value: any;
  onChange: any;
  label: string;
  accessor: string;
  defaultValue?: any;
}): JSX.Element => {
  const [selectedValue, setSelectedValue] =
    React.useState<SelectedValueType>(value)
  const [inputState, setInputState] = React.useState<string>('' as string)

  const inputChangeDebounced = useDebounce(inputState, 500)

  React.useEffect(() => {
    if (
      inputChangeDebounced.trim() !== '' &&
      inputChangeDebounced !== selectedValue.label
    ) {
      onChange(
        accessor,
        { id: null, label: inputChangeDebounced, code: '' },
        'select'
      )
    }
  }, [inputChangeDebounced])

  React.useEffect(() => {
    if (
      selectedValue &&
      (selectedValue.label !== value.label || selectedValue.id !== value.id)
    ) {
      onChange(accessor, selectedValue, 'select')
    }
  }, [selectedValue])

  const handleValueChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
      code: string | null | undefined;
    } | null
  ): void => {
    if (
      newValue &&
      typeof newValue === 'object' &&
      Object.hasOwn(newValue, 'id')
    ) {
      setSelectedValue(newValue as SelectedValueType)
      setInputState(newValue.label ?? '')
    } else if (typeof newValue === 'string') {
      setSelectedValue({ id: 0, label: newValue, code: '' })
      setInputState(newValue)
    } else {
      setSelectedValue({ id: 0, label: '', code: '' })
      setInputState('')
    }
  }

  return (
    <TileSearchBox
      dataTestId={accessor}
      value={selectedValue || ''}
      inputValue={inputState}
      setInputValue={setInputState}
      twoColumns
      onChange={handleValueChange}
      options={options.map((option) => ({
        id: option.id,
        label: option.label ?? '',
        code: option?.code ?? '',
      }))}
      defaultValue={defaultValue}
    />
  )
}

interface SelectedValueType {
  id: number;
  label: string;
  code?: string;
}
