import React from 'react'
import { styled } from '@mui/material'
import {
  type UseFormRegister,
  type FieldValues,
  type Control,
} from 'react-hook-form'
import { TileTitleBox, ErrorMessage } from '@/components'
import { TitleRow } from '@/components/Charting/Editor/VitalsTile/styles'
import { DatePicker } from '@/components/DatePicker'
import { subDays } from 'date-fns'
import { TileTextField } from '../../TileTextField'
import { PhotoIdFieldSelectedContext } from '@/context'

const PersonalInfoContainer = styled('div')(({ theme }): any => ({
  display: 'flex',
  width: '812px',
  padding: '24px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  border: '1px solid #CDD0E3',
  scrollMarginTop: '100px',
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
}))
const PersonalInfoRow = styled('div')({
  display: 'flex',
})
const PersonalInfoInnerRow = styled(PersonalInfoRow)({
  gap: '24px',
})
const AutoCompleteColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  alignContent: 'flex-start',
  paddingRight: '10px',
  paddingBottom: '8px',
  gap: '24px',
})

export const PersonalInfo = ({
  innerRef,
  register,
  control,
  onFocus,
  onBlur,
  errors,
  isPatientDuplicated,
}: PersonalInfoProps): JSX.Element => {
  const { photoIdFieldSelected } = React.useContext(
    PhotoIdFieldSelectedContext
  )
  return (
    <PersonalInfoContainer ref={innerRef} onMouseOver={onFocus} onBlur={onBlur}>
      <TitleRow>
        <TileTitleBox tileTitle="Personal Information" />
      </TitleRow>
      <PersonalInfoRow>
        <AutoCompleteColumn>
          <PersonalInfoInnerRow>
            <TileTextField
              id="firstName"
              label="First Name"
              register={register}
              errors={errors}
              required
            />
            <TileTextField
              id="lastName"
              label="Last Name"
              register={register}
              errors={errors}
              required
            />
            <DatePicker
              name={'birthDate'}
              control={control}
              label="DOB"
              errors={errors}
              maxDate={subDays(Date.now(), 1)}
              dataTestId={'birthDate'}
              required
              defaultValue={null}
              isSelected={photoIdFieldSelected.dateOfBirth}
            />
          </PersonalInfoInnerRow>
        </AutoCompleteColumn>
      </PersonalInfoRow>
      {isPatientDuplicated && (
        <ErrorMessage error="Warning: Duplicate patient found." />
      )}
    </PersonalInfoContainer>
  )
}
interface PersonalInfoProps {
  innerRef: React.RefObject<HTMLDivElement>;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  onFocus: any;
  errors: any;
  isPatientDuplicated?: boolean;
  onBlur?: any;
}
