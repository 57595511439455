import React from 'react'
import { RowTile } from '../components/RowTile'
import { RowValue } from '../components/RowValue'
import { AddColumn } from '../layout/AddColumn'
import { AddRow } from '../layout/AddRow'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Modal, Popover } from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'
import { ExternalOrderHoverOver } from './ExternalOrderPopover'
import {
  type Clinic,
  type PVerifyInsurance,
  type Patient,
  type State,
  type UrgentIQDocument,
} from '@/types'
import { PDFViewer } from '@/components/PDFViewer/PDFViewer'
import { getClinic, getDocument } from '@/services'
import { useAuth, useUser } from '@clerk/nextjs'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import { useClinicStore, useQueryGetStates } from '@/hook'
import { format } from 'date-fns'
import { formatPhoneNumber } from 'react-phone-number-input'
import { formatDateOnly, isEmpty } from '@/utility'
import { uploadFile } from '@/helpers'

export const ExternalOrderRow = ({
  externalOrderName,
  externalOrderId,
  externalOrderType,
  externalOrderExplaination,
  documentId,
  delete: deleteExternalOrder = null,
  edit: editExternalOrder = null,
  visitId,
  patientId,
  dxCode,
  dxName,
  patient,
  provider,
  nPI,
  insuranceNames,
}: ExternalOrderRowProps): JSX.Element => {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const { data: states } = useQueryGetStates(getToken)
  const [clinic, setClinic] = React.useState<Clinic>()
  const nameStateRef = React.useRef(externalOrderName)

  React.useEffect(() => {
    if (isEmpty(clinicId)) return
    getClinic(clinicId, getToken)
      .then((clinic) => {
        setClinic(clinic)
      })
      .catch((err) => {
        throw err
      })
  }, [clinicId])

  const { user } = useUser()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }
  const [selectedFile, setSelectedFile] = React.useState<UrgentIQDocument>({})
  const open = Boolean(anchorEl)
  const [openModal, setOpenModal] = React.useState(false)

  React.useEffect(() => {
    if (openModal) {
      Promise.resolve(
        getDocument(documentId ?? 0, getToken).then((doc) => {
          setSelectedFile({ ...doc })
        })
      ).catch((err) => {
        throw err
      })
    }
  }, [openModal])

  const saveOtherDocuments = async (file: Blob): Promise<void> => {
    let id = 0
    if (user?.publicMetadata.account_user_id) {
      id = parseInt(user.publicMetadata.account_user_id as string)
    }
    uploadFile(
      file,
      patientId,
      21,
      undefined,
      visitId,
      nameStateRef.current,
      id,
      getToken
    ).catch((err) => {
      throw err
    })
  }

  const hasInsurance =
    patient.patientInsuranceList !== null &&
    patient.patientInsuranceList !== undefined &&
    patient.patientInsuranceList?.length > 0

  const insurance = hasInsurance
    ? insuranceNames?.find(
        (insurance) =>
          insurance.payerName === patient.patientInsuranceList?.[0].companyName
      )
    : null

  const formFields = {
    patientInfo: `${patient.firstName ?? ''} ${patient.lastName ?? ''} ${
      patient.sex ?? ''
    } ${formatDateOnly(patient.dateOfBirth)}`,
    patientName: `${patient.firstName ?? ''} ${patient.lastName ?? ''}`,
    patientDOB: formatDateOnly(patient.dateOfBirth),
    patientPhoneNumber: formatPhoneNumber(patient.phoneNumber ?? ''),
    orderDate: format(new Date(Date.now()), 'MM/dd/yyyy'),
    orderTime: format(new Date(Date.now()), 'mm:hh aa'),
    clinicPhoneNumber: formatPhoneNumber(clinic?.phoneNumber ?? ''),
    clinicFaxNumber: formatPhoneNumber(clinic?.faxNumber ?? ''),
    patientAddress: `${patient.street1 ?? ''}\n${
      !isEmpty(patient.street2) ? `${patient.street2 ?? ''}\n` : ''
    }${patient.city ?? ''}, ${
      states?.find((state: State) => state.id === patient.stateId)?.code ?? ''
    } ${patient.zipcode ?? ''}`,
    ...(hasInsurance && {
      insuranceName: patient.patientInsuranceList?.[0]?.companyName ?? '',
    }),
    ...(hasInsurance &&
      insurance !== null &&
      insurance !== undefined &&
      !isEmpty(insurance.address1) && {
        insuranceAddress: `${insurance?.address1 ?? ''}\n${
          !isEmpty(insurance?.address2) ? `${insurance?.address2 ?? ''}\n` : ''
        }${insurance?.city ?? ''}, ${
          states?.find((state: State) => state.id === insurance?.stateId)
            ?.code ?? ''
        } ${insurance?.zip ?? ''}`,
      }),
    ...(hasInsurance && {
      subscribersName: `${
        patient.patientInsuranceList?.[0].firstName ?? patient.firstName ?? ''
      } ${
        patient.patientInsuranceList?.[0].lastName ?? patient.lastName ?? ''
      }`,
    }),
    ...(hasInsurance && {
      insuredName: `${patient.firstName ?? ''} ${patient.lastName ?? ''}`,
    }),
    ...(hasInsurance && {
      insuredAddress: `${patient.street1 ?? ''}\n${
        !isEmpty(patient.street2) ? `${patient.street2 ?? ''}\n` : ''
      }${patient.city ?? ''}, ${
        states?.find((state: State) => state.id === patient.stateId)?.code ?? ''
      } ${patient.zipcode ?? ''}`,
    }),
    ...(hasInsurance && {
      insuranceInfo: `${patient.patientInsuranceList?.[0].companyName ?? ''}\n${
        insurance?.address1 ?? ''
      }\n${
        !isEmpty(insurance?.address2) ? `${insurance?.address2 ?? ''}\n` : ''
      }${insurance?.city ?? ''}, ${
        states?.find((state: State) => state.id === insurance?.stateId)?.code ??
        ''
      } ${insurance?.zip ?? ''}`,
    }),
    dxCode,
    dxName,
    clinicName: clinic?.name ?? '',
    clinicAddress: `${clinic?.address ?? ''}\n${
      !isEmpty(clinic?.address2) ? `${clinic?.address2 ?? ''}\n` : ''
    }${clinic?.city ?? ''}, ${
      states?.find((state: State) => state.id === clinic?.stateId)?.code ?? ''
    } ${clinic?.zip ?? ''}`,
    providerName: provider,
    npi: nPI,
    pcpName: patient.primaryCarePhysician,
    sex: patient?.sex
      ? patient.sex[0].toUpperCase() + patient.sex.slice(1)
      : '',
  }
  const pdfViewer = React.useMemo(() => {
    return (
      <PDFViewer
        url={selectedFile?.presignedUrl ?? ''}
        name={nameStateRef?.current}
        handleSave={saveOtherDocuments}
        formFieldsValues={formFields}
        faxTitle={`External Order from ${clinic?.name ?? ''}`}
      />
    )
  }, [selectedFile?.presignedUrl, clinic?.name])

  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <ExternalOrderHoverOver
          externalOrderName={externalOrderType}
          externalOrderExplain={externalOrderExplaination}
        />
      </Popover>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          alignContent: 'center',
        }}
      >
        <div>{pdfViewer}</div>
      </Modal>

      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={25}>
          <RowValue>{externalOrderType}</RowValue>
        </AddColumn>
        <AddColumn width={59}>
          <RowTile>Relevant Info</RowTile>
          <RowValue>{externalOrderExplaination}</RowValue>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton
            onClick={() => setOpenModal(true)}
            data-testid={`viewExternalOrder${externalOrderId}`}
            size="large"
          >
            <DescriptionOutlinedIcon />
          </IconButton>
        </AddColumn>
        {
          // prettier-ignore
          editExternalOrder !== null
          // prettier-ignore
          ? (
              <AddColumn width={4}>
                <IconButton
                  onClick={() => editExternalOrder(externalOrderId, documentId ?? 0, externalOrderName, externalOrderExplaination)}
                  size="large">
                  <ModeIcon />
                </IconButton>
              </AddColumn>)
          // prettier-ignore
          : <></>
        }
        {
          // prettier-ignore
          deleteExternalOrder !== null
          // prettier-ignore
          ? (
              <AddColumn width={4}>
                <IconButton
                  onClick={() => deleteExternalOrder(externalOrderId)}
                  data-testid={`deleteExternalOrder${externalOrderId}`}
                  size="large">
                  <DeleteIcon />
                </IconButton>
              </AddColumn>)
          // prettier-ignore
          : <></>
        }
      </AddRow>
    </>
  )
}
interface ExternalOrderRowProps {
  externalOrderName: string;
  externalOrderId: number;
  externalOrderType: string;
  externalOrderExplaination: string;
  // prettier-ignore
  delete?: ((
    externalOrderId: number
  ) => void)
  | null
  // prettier-ignore
  edit?: ((
    externalOrderId: number,
    documentId: number,
    name: string,
    explaination: string
  ) => void)
  | null
  documentId?: number;
  patientId: number;
  visitId: number;
  dxCode: string;
  dxName: string;
  patient: Patient;
  provider: string;
  nPI: string;
  insuranceNames: PVerifyInsurance[];
}
