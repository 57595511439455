import React from 'react'
import { useAuth } from '@clerk/nextjs'
import {
  updatePatientMedicalCondition,
  deletePatientMedicalCondition,
} from '@/services'
import { useQueryGetMedicalConditions } from '@/hook'
import {
  usePatientMedicalConditionStore,
  useQueryGetPatientMedicalCondition,
  useMutateAddPatientMedicalCondition,
} from '@/hook/usePatientMedicalCondition'
import { PMHTile } from '../PatientHistoryTile'
import { type PatientMedicalCondition } from '@/types'

export const PatientMedicalConditions = ({
  patient,
}: {
  patient: any;
}): JSX.Element => {
  const { getToken } = useAuth()

  const { patientMedicalConditions } = usePatientMedicalConditionStore()

  const { data: medicalConditionTypes } =
    useQueryGetMedicalConditions(getToken)

  const addNewPatientMedicalCondition =
    useMutateAddPatientMedicalCondition(getToken)

  const { isLoading: isMedicalConditionsLoading, refetch } =
    useQueryGetPatientMedicalCondition(patient?.id, getToken)

  if (isMedicalConditionsLoading) {
    return <div>Loading medical conditions...</div>
  }

  if (!medicalConditionTypes || medicalConditionTypes.length === 0) {
    console.error('No medical condition types available')
    return <div>No medical condition data available.</div>
  }

  const patientMedConditionsArray = Array.isArray(patientMedicalConditions)
    ? patientMedicalConditions
    : []

  const medConditionsArray = Array.isArray(medicalConditionTypes)
    ? medicalConditionTypes
    : []

  const columns = [
    { accessor: 'medicalConditionId', Header: 'Condition' },
    { accessor: 'isActive', Header: 'Onset' },
  ]

  const patientConditionsWithName = patientMedConditionsArray.map(
    (condition) => ({
      ...condition,
      medicalConditionId: condition.medicalConditionId
        ? medicalConditionTypes.find(
            (type) => type.id === condition.medicalConditionId
          )?.name
        : condition.problem ?? 'Unknown Condition',
      isActive: condition.isActive,
    })
  )

  const dialogFields = [
    {
      label: 'Condition',
      accessor: 'medicalConditionId',
      type: 'select',
      options: medConditionsArray.map((condition) => ({
        id: condition.id,
        label: condition.name,
      })),
    },
    {
      label: 'Onset',
      accessor: 'isActive',
      type: 'radio',
      options: [
        { value: true, label: 'Active' },
        { value: false, label: 'Historical' },
      ],
    },
  ]

  const handleAdd = async (formData: any): Promise<void> => {
    const {
      medicalConditionId: id,
      medicalConditionId_label: label,
      isActive,
    } = formData

    if (!id && !label) {
      console.error('Either medicalConditionId or problem must be provided')
      return
    }

    const newPatientMedicalCondition: PatientMedicalCondition = {
      patientId: patient?.id,
      medicalConditionId: id && !isNaN(id) ? parseInt(id, 10) : null,
      problem: id && isNaN(id) ? id : id ? null : label,
      isActive,
    }

    await addNewPatientMedicalCondition.mutateAsync({
      condition: newPatientMedicalCondition,
    })
    await refetch()
  }

  const handleEdit = async (data: any): Promise<void> => {
    const { id, medicalConditionId, problem, isActive } = data

    if (!medicalConditionId && !problem) {
      console.error('Either medicalConditionId or problem must be provided')
      return
    }

    const updatedPatientMedicalCondition: PatientMedicalCondition = {
      id,
      patientId: patient?.id,
      medicalConditionId: medicalConditionId || null,
      problem: problem || null,
      isActive,
    }

    await updatePatientMedicalCondition(
      id,
      updatedPatientMedicalCondition,
      getToken
    )
    await refetch()
  }

  const handleDelete = async (
    conditionToDelete: PatientMedicalCondition
  ): Promise<void> => {
    if (!conditionToDelete) {
      console.error('Could not find condition to delete')
      return
    }
    if (conditionToDelete.id) {
      await deletePatientMedicalCondition(conditionToDelete.id, getToken)
    }
    await refetch()
  }

  return (
    <PMHTile
      title="Medical Conditions"
      data={patientConditionsWithName}
      columns={columns}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      dialogFields={dialogFields}
    />
  )
}
