import React from 'react'
import { sendNewPatientMessage } from '@/services/PatientMessagingService'
import { useAuth } from '@clerk/nextjs'
import { Badge, CircularProgress, IconButton, Tooltip } from '@mui/material'
import AttachEmailIcon from '@mui/icons-material/AttachEmail'
import { useClinicStore, usePatientStore, useQueryGetPatient } from '@/hook'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { useToastStore } from '@/hook/useToast'

enum SentStatus {
  Success,
  Failure,
}

const storagePrefix = 'sentStatus-'
const getLocalSentStatus = (documentId: number): SentStatus | null => {
  const sentStatus = localStorage.getItem(`${storagePrefix}${documentId}`)
  return sentStatus ? JSON.parse(sentStatus) : null
}

const setLocalSentStatus = (documentId: number, sent: SentStatus): void => {
  localStorage.setItem(`${storagePrefix}${documentId}`, JSON.stringify(sent))
}

export const DisabledSendDocumentMessageButton = (): JSX.Element => {
  // Not using the "disabled" prop on the IconButton because hides the tooltip
  return (
    <Tooltip title="Patient has no email address." arrow>
      <IconButton onClick={() => {}} size="large">
        <AttachEmailIcon
          sx={{ color: 'grey' }}
          style={{ width: '17px', height: '17px' }}
        />
      </IconButton>
    </Tooltip>
  )
}

export const SendDocumentMessageButton = ({
  patientId,
  message,
  documentId,
}: SendDocumentMessageButtonProps): JSX.Element => {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const { addToast } = useToastStore()
  const { isLoading } = useQueryGetPatient(patientId, getToken)
  const { patient } = usePatientStore()
  const [sending, setSending] = React.useState<boolean>(false)
  const [sentStatus, setSentStatus] = React.useState<SentStatus | null>(
    getLocalSentStatus(documentId)
  )
  const [disabled, setDisabled] = React.useState<boolean>(true)

  React.useEffect(() => {
    setDisabled(!patient?.email)
  }, [isLoading])

  if (disabled) {
    return <DisabledSendDocumentMessageButton />
  }

  const handleSendDocument = async (): Promise<void> => {
    setSending(true)
    setSentStatus(null)
    sendNewPatientMessage(getToken, patientId, clinicId, message, documentId)
      .then(() => {
        setSending(false)
        setSentStatus(SentStatus.Success)
        addToast({ message: 'Document sent successfully', status: 'success' })
        setLocalSentStatus(documentId, SentStatus.Success)
      })
      .catch((error): void => {
        setSending(false)
        setSentStatus(SentStatus.Failure)
        addToast({
          message: `Failed to send document: ${JSON.stringify(error)}`,
          status: 'error',
        })
        setLocalSentStatus(documentId, SentStatus.Failure)
      })
  }

  const getStatusColor = (): 'success' | 'error' | 'info' => {
    switch (sentStatus) {
      case SentStatus.Success:
        return 'success'
      case SentStatus.Failure:
        return 'error'
      default:
        return 'info'
    }
  }

  const badgeContentSizeStyle = {
    width: 10,
    height: 10,
  }

  const getBadgeContent = (): JSX.Element | null => {
    if (sending) {
      return <CircularProgress size={badgeContentSizeStyle.height} />
    } else if (sentStatus === SentStatus.Success) {
      return <CheckCircleIcon style={badgeContentSizeStyle} />
    } else if (sentStatus === SentStatus.Failure) {
      return <ErrorIcon style={badgeContentSizeStyle} />
    } else {
      return null
    }
  }

  return (
    <>
      <Tooltip title={'Email document to patient'} arrow>
        <IconButton
          onClick={() => {
            void handleSendDocument()
          }}
          disabled={sending ?? !!sentStatus}
          size="large"
        >
          <Badge
            badgeContent={getBadgeContent()}
            color={getStatusColor()}
            sx={{
              color: '#252D63',
              '& .MuiBadge-badge': { fontSize: 9, height: 15 },
            }}
          >
            <AttachEmailIcon
              sx={{ color: getStatusColor() }}
              style={{ width: '17px', height: '17px' }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
    </>
  )
}

interface SendDocumentMessageButtonProps {
  patientId: number;
  message: string;
  documentId: number;
}
