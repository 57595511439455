import { type Clinic } from '../../../../types/Clinic'
import React from 'react'
import { ClinicRow } from './ClinicRow'
import { type Room } from '../../../../types/Room'
import { ListContainer } from '../../layout/list/ListContainer'
import { ListTitleRow } from '../../layout/list/ListTitleRow'
import { ListBox } from '../../layout/list/ListBox'
import { TitleEnd } from '../../layout/list/TitleEnd'
import { TitleSmall } from '../../layout/list/TitleSmall'
import { TitleLarge } from '../../layout/list/TitleLarge'
import { TitleText } from '../../components/list/TitleText'
import { type TimeZone } from '../../../../types/Timezone'
import { type State } from '@/types'

export const ClinicManagementList = ({
  clinics,
  rooms,
  refresh,
  refreshRooms,
  timeZones,
  states,
}: ClinicManagmentListProps): JSX.Element => {
  return (
    <ListContainer>
      <ListTitleRow>
        <TitleSmall>
          <TitleText>Name</TitleText>
        </TitleSmall>
        <TitleSmall>
          <TitleText>Address</TitleText>
        </TitleSmall>
        <TitleLarge>
          <TitleText>Room</TitleText>
        </TitleLarge>
        <TitleEnd />
      </ListTitleRow>
      <ListBox>
        {clinics?.map((clinic) => {
          let clinicRooms: Room[] = []
          if (rooms !== undefined) {
            clinicRooms = rooms.filter((room) => {
              return room.clinicId === clinic.id
            })
          }
          return (
            <ClinicRow
              clinic={clinic}
              key={clinic.id}
              rooms={clinicRooms}
              clinics={clinics}
              refresh={refresh}
              refreshRooms={refreshRooms}
              timeZones={timeZones}
              states={states}
            />
          )
        })}
      </ListBox>
    </ListContainer>
  )
}

interface ClinicManagmentListProps {
  clinics: Clinic[];
  rooms: Room[];
  refresh: any;
  refreshRooms: any;
  timeZones: TimeZone[];
  states: State[];
}
