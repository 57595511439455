import { styled } from '@mui/material'
import React from 'react'

const TitleRow = styled('div')({
  display: 'flex',
  paddingLeft: '15px',
  paddingRight: '15px',
  width: '100%',
})

export const ListTitleRow = ({ children }: ListTitleRowProps): JSX.Element => {
  return <TitleRow>{children}</TitleRow>
}

interface ListTitleRowProps {
  children: JSX.Element | JSX.Element[] | undefined;
}
