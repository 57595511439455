import { TaskPatientCard } from '@/components/TaskPatientSearch/TaskPatientCard'
import { usePatientStore } from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { useMutateUpdateTask } from '@/hook/useTasks'
import { Box, IconButton } from '@mui/material'
import { useRouter } from 'next/navigation'
import React from 'react'
import { PatientSearch } from '../PatientSearch'
import Close from '@mui/icons-material/Close'
import EditOutlined from '@mui/icons-material/EditOutlined'
import { type Patient } from '@/types'
import { getPatient } from '@/services'

export const PatientDataItem = ({
  taskId,
  patientId,
  onPatientSelect,
}: PatientDataItemProps): JSX.Element => {
  const router = useRouter()
  const { getToken } = useAuth()
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [patient, setPatient] = React.useState<Patient | null>(null)
  const updateTaskMutation = useMutateUpdateTask(getToken)
  const { patients } = usePatientStore()

  React.useEffect(() => {
    const foundPatient = patients.find((p: Patient) => p.id === patientId)
    if (foundPatient) {
      setPatient(foundPatient)
    } else {
      getPatient(patientId, getToken)
        .then((p) => {
          setPatient(p)
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }, [])

  const handleNewPatient = (newPatientId: number): void => {
    void updateTaskMutation.mutateAsync({
      id: taskId,
      task: { patientId: newPatientId },
    })
    const foundPatient = patients.find((p: Patient) => p.id === newPatientId)
    if (foundPatient) {
      setPatient(foundPatient)
    } else {
      getPatient(newPatientId, getToken)
        .then((p) => {
          setPatient(p)
          onPatientSelect(p.id ?? null)
        })
        .catch((e) => {
          console.error(e)
        })
    }

    setEditMode(false)
  }

  return (
    <Box display="flex">
      {!editMode && patient && (
        <TaskPatientCard
          patient={patient}
          onClick={async () => {
            if (patient?.id) {
              await router.push(`/profile/${patient.id}`)
            }
          }}
        />
      )}
      {(!patient || editMode) && (
        <PatientSearch
          patientId={patientId}
          handleChange={(patientId: number) => {
            handleNewPatient(patientId)
          }}
        />
      )}
      {patient && (
        <IconButton onClick={() => setEditMode(!editMode)} size="large">
          {editMode ? <Close /> : <EditOutlined />}
        </IconButton>
      )}
    </Box>
  )
}

interface PatientDataItemProps {
  taskId: number;
  patientId: number;
  onPatientSelect: (patientId: number | null) => void;
}
