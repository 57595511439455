import { ModalButton } from '@/components/Config'
import { AddressInput } from '@/components/PatientIntake'
import { TileInnerRow } from '@/components/PatientIntake/Tiles/styles'
import { type FinancialResponsibleParty, type State } from '@/types'
import {
  Divider,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
} from '@mui/material'
import { ModalLayout } from './ModalLayout'
import {
  Controller,
  type SubmitHandler,
  useForm,
  useWatch,
} from 'react-hook-form'
import React from 'react'
import {
  dateOnlyToDate,
  isDateOnlyValid,
  isEmpty,
  isInputValid,
  parseDateToDateOnly,
} from '@/utility'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { PhoneNumberInput } from '@/components/PhoneNumberInput'
import { subDays } from 'date-fns'
import { DatePicker } from '@/components/DatePicker'
import { ErrorMessage, RedText } from '@/components/ErrorMessage'
import { useClinicStore, useQueryGetVisitTypes } from '@/hook'
import { useAuth } from '@clerk/nextjs'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }): any => ({
  fontSize: '16px',
  lineHeight: '18.75px',
  color: 'black',
}))
const ButtonRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '780px',
  paddingTop: '20px',
})

export const FinancialResponsiblePartyModal = ({
  states,
  handleClose,
  handleSave,
  financialRP,
  visitType,
}: FinancialResponsiblePartyModalProps): JSX.Element => {
  const validationSchema = Yup.object().shape({
    financialRPPhoneNumber: Yup.lazy((value) =>
      !isEmpty(value)
        ? Yup.string().nullable().min(12, 'Valid phone number is required')
        : Yup.string().nullable().notRequired()
    ),
  })

  const {
    handleSubmit,
    register,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      financialRPFirstName: financialRP.financialRPFirstName,
      financialRPLastName: financialRP.financialRPLastName,
      financialRPstreet1: financialRP.financialRPAddressLine,
      financialRPstreet2: financialRP.financialRPAddressLine2,
      financialRPcity: financialRP.financialRPCity,
      financialRPstate: states?.find(
        (state) => state.id === financialRP.financialRPStateId
      )?.code,
      financialRPzip: financialRP.financialRPZip,
      financialRPPhoneNumber: financialRP.financialRPPhone,
      financialRPEmail: financialRP.financialRPEmail,
      financialRPRelationToPatient: financialRP.financialRPRelationToPatient,
      financialRPRelationToPatientOther:
        financialRP.financialRPRelationToPatientOther,
      financialRPBirthDate: isDateOnlyValid(financialRP.financialRPDateOfBirth)
        ? dateOnlyToDate(financialRP.financialRPDateOfBirth)
        : null,
      financialRPSex: financialRP.financialRPSex,
      financialRpWorkCompInsuranceNumber:
        financialRP.financialRpWorkCompInsuranceNumber,
      financialRpWorkCompPolicyNumber:
        financialRP.financialRpWorkCompPolicyNumber,
      financialRPOccMedGuarantor: financialRP.financialRPOccMedGuarantor,
      financialRPOccMedGuarantorPhoneNumber:
        financialRP.financialRPOccMedGuarantorPhone,
      financialRPOccMedGuarantorFax: financialRP.financialRPOccMedGuarantorFax,
    },
  })
  const { clinicId } = useClinicStore()
  const { getToken } = useAuth()
  const [isRelationshipVisible, setIsRelationshipVisible] =
    React.useState<boolean>(false)
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const holderWatch = useWatch({
    control,
    name: 'financialRPRelationToPatient',
  })

  React.useEffect(() => {
    setIsRelationshipVisible(holderWatch === 'other')
  }, [holderWatch])

  const isWorkersComp = React.useMemo(() => {
    return (
      visitTypes
        ?.find((type) => type.id === visitType)
        ?.name?.includes('Work') === true
    )
  }, [visitTypes, visitType])

  const isOccMed = React.useMemo(() => {
    return visitType === 4
  }, [visitType])

  const onSubmit: SubmitHandler<any> = async (data): Promise<void> => {
    if (!isInputValid(data.financialRPPhoneNumber)) {
      setError('financialRPPhoneNumber', {
        message: 'Valid phone number is required',
      })
    }
    const newFinancialRP: FinancialResponsibleParty = {
      financialRPFirstName: data.financialRPFirstName,
      financialRPLastName: data.financialRPLastName,
      financialRPAddressLine: data.financialRPstreet1,
      financialRPAddressLine2: data.financialRPstreet2,
      financialRPCity: data.financialRPcity,
      financialRPStateId: states?.find(
        (state) => state.code === data.financialRPstate
      )?.id,
      financialRPZip: data.financialRPzip,
      ...(!isEmpty(data.financialRPPhoneNumber) && {
        financialRPPhone: data.financialRPPhoneNumber,
      }),
      financialRPEmail: data.financialRPEmail,
      financialRPRelationToPatient: data.financialRPRelationToPatient,
      financialRPRelationToPatientOther: data.financialRPRelationToPatientOther,
      ...(!isEmpty(data.financialRPBirthDate) && {
        financialRPDateOfBirth: parseDateToDateOnly(
          new Date(data.financialRPBirthDate)
        ),
      }),
      financialRPSex: data.financialRPSex,
      ...(isWorkersComp && {
        financialRpWorkCompInsuranceNumber:
          data.financialRpWorkCompInsuranceNumber,
        financialRpWorkCompPolicyNumber: data.financialRpWorkCompPolicyNumber,
      }),
      ...(isOccMed && {
        financialRPOccMedGuarantor: data.financialRPOccMedGuarantor,
        financialRPOccMedGuarantorPhone:
          data.financialRPOccMedGuarantorPhoneNumber,
        financialRPOccMedGuarantorFax: data.financialRPOccMedGuarantorFax,
      }),
    }
    handleSave(newFinancialRP)
  }
  return (
    <ModalLayout
      width={'810px'}
      handleCancel={handleClose}
      title="Financial Responsible Party"
    >
      <form
        onSubmit={(...args): void => {
          handleSubmit(onSubmit)(...args).catch((error) => {
            throw error
          })
        }}
      >
        {!isWorkersComp && !isOccMed && (
          <>
            <TileInnerRow>
              <div style={{ minHeight: '56px' }}>
                <InputLabel>Relation to Responsible Party</InputLabel>
                <Controller
                  name={'financialRPRelationToPatient'}
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      <StyledFormControlLabel
                        value="spouse"
                        control={<Radio />}
                        label="Spouse"
                      />
                      <StyledFormControlLabel
                        value="child"
                        control={<Radio />}
                        label="Child"
                      />
                      <StyledFormControlLabel
                        value="mother"
                        control={<Radio />}
                        label="Mother"
                      />
                      <StyledFormControlLabel
                        value="father"
                        control={<Radio />}
                        label="Father"
                      />
                      <StyledFormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                  )}
                />
              </div>
              {isRelationshipVisible ? (
                <div style={{ paddingLeft: '10px', marginLeft: '6px' }}>
                  <InputLabel>Specify Relation</InputLabel>
                  <TextField
                    sx={{ width: '240px' }}
                    {...register('financialRPRelationToPatientOther')}
                    tabIndex={20}
                  />
                </div>
              ) : (
                <></>
              )}
            </TileInnerRow>
            <TileInnerRow>
              <div>
                <InputLabel>First Name</InputLabel>
                <TextField
                  sx={{ width: '240px' }}
                  {...register('financialRPFirstName')}
                  tabIndex={20}
                />
              </div>

              <div>
                <InputLabel>Last Name</InputLabel>
                <TextField
                  sx={{ width: '240px' }}
                  {...register('financialRPLastName')}
                  tabIndex={20}
                />
              </div>
              <div>
                <DatePicker
                  label="DOB"
                  name="financialRPBirthDate"
                  control={control}
                  errors={errors}
                  dataTestId="financialRPBirthDate"
                  maxDate={subDays(Date.now(), 1)}
                />
                {!isEmpty(errors.financialRPBirthDate) && (
                  <ErrorMessage
                    error={errors?.financialRPBirthDate?.message as string}
                  />
                )}
              </div>
            </TileInnerRow>
            <TileInnerRow>
              <div>
                <InputLabel id="GenderLabel">Sex</InputLabel>
                <Controller
                  name="financialRPSex"
                  control={control}
                  defaultValue={''}
                  render={({ field }) => (
                    <Select
                      {...field}
                      tabIndex={5}
                      labelId="GenderLabel"
                      sx={{ width: '240px' }}
                      data-testid="financialRPSex"
                    >
                      <MenuItem data-testid="financialRPMale" value={'Male'}>
                        Male
                      </MenuItem>
                      <MenuItem
                        data-testid="financialRPFemale"
                        value={'Female'}
                      >
                        Female
                      </MenuItem>
                      <MenuItem data-testid="financialRPOther" value={'Other'}>
                        Other
                      </MenuItem>
                      <MenuItem
                        data-testid="financialRPNoResponse"
                        value={'NoResponse'}
                      >
                        No Response
                      </MenuItem>
                    </Select>
                  )}
                />
                {!isEmpty(errors?.financialRPSex) && (
                  <ErrorMessage
                    error={errors?.financialRPSex?.message as string}
                  />
                )}
              </div>
              <div>
                <InputLabel>Email</InputLabel>
                <TextField
                  sx={{ width: '240px' }}
                  {...register('financialRPEmail')}
                  type="email"
                />
              </div>
              <PhoneNumberInput
                control={control}
                sectionName="financialRP"
                error={errors.financialRPPhone}
                setError={setError}
              />
            </TileInnerRow>
          </>
        )}
        {isOccMed && (
          <div>
            <InputLabel>
              {visitTypes
                ?.find((type) => type?.id === visitType)
                ?.name?.includes('Occ')
                ? 'Occ Med '
                : ''}
              Guarantor
            </InputLabel>
            <TextField
              sx={{ width: '240px' }}
              {...register('financialRPOccMedGuarantor')}
            />
          </div>
        )}
        {isWorkersComp && (
          <TileInnerRow>
            <div>
              <InputLabel>
                Work Comp Insurance Name<RedText>{' *'}</RedText>
              </InputLabel>
              <TextField
                sx={{ width: '240px' }}
                {...register('financialRpWorkCompInsuranceNumber')}
              />
              {!isEmpty(errors?.financialRpWorkCompInsuranceNumber) && (
                <ErrorMessage
                  error={
                    errors?.financialRpWorkCompInsuranceNumber
                      ?.message as string
                  }
                />
              )}
            </div>
            <div>
              <InputLabel>
                Policy #<RedText>{' *'}</RedText>
              </InputLabel>
              <TextField
                sx={{ width: '240px' }}
                {...register('financialRpWorkCompPolicyNumber')}
              />
              {!isEmpty(errors.financialRpWorkCompPolicyNumber) && (
                <ErrorMessage
                  error={
                    errors?.financialRpWorkCompPolicyNumber?.message as string
                  }
                />
              )}
            </div>
          </TileInnerRow>
        )}
        <Divider sx={{ mt: 2, mb: 2, color: '#DDDFE8' }} flexItem />
        <AddressInput
          register={register}
          control={control}
          sectionName="financialRP"
          setValue={setValue}
          errors={errors}
          setError={setError}
        />
        {isOccMed && (
          <TileInnerRow>
            <div>
              <PhoneNumberInput
                control={control}
                sectionName={'financialRPOccMedGuarantor'}
                error={errors.financialRPOccMedGuarantorPhoneNumber}
                required
                setError={setError}
              />
              {!isEmpty(errors.financialRPOccMedGuarantorPhoneNumber) && (
                <ErrorMessage
                  error={
                    errors?.financialRPOccMedGuarantorPhoneNumber
                      ?.message as string
                  }
                />
              )}
            </div>
            <div>
              <PhoneNumberInput
                control={control}
                sectionName="financialRPOccMedGuarantorFax"
                labelName="Fax"
                profile
                error={errors.financialRPOccMedGuarantorFax}
                setError={setError}
              />
              {!isEmpty(errors.financialRPOccMedGuarantorFax) && (
                <ErrorMessage
                  error={
                    errors?.financialRPOccMedGuarantorFax?.message as string
                  }
                />
              )}
            </div>
          </TileInnerRow>
        )}
        <ButtonRow>
          <ModalButton variant="contained" color="primary" type="submit">
            Save
          </ModalButton>
        </ButtonRow>
      </form>
    </ModalLayout>
  )
}

interface FinancialResponsiblePartyModalProps {
  states: State[];
  handleClose: () => void;
  handleSave: (financialRP: FinancialResponsibleParty) => void;
  financialRP: FinancialResponsibleParty;
  visitType: number;
}
