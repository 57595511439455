import { styled } from '@mui/material'
import React from 'react'

const ChiefComplaintRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
})

export const TileRow = ({ children }: TileRowProps): JSX.Element => {
  return <ChiefComplaintRow>{children}</ChiefComplaintRow>
}

interface TileRowProps {
  children:
    | JSX.Element
    | JSX.Element[]
    | undefined
    | null
    | Array<JSX.Element | undefined | null>;
}
