import React from 'react'
import { styled, Typography } from '@mui/material'
import {
  type Control,
  type FieldErrorsImpl,
  type FieldValues,
  type UseFormRegister,
} from 'react-hook-form'
import {
  EFaxTextBox,
  EFaxTextArea,
  PhoneNumberInput,
  TileInputLabel,
  PCPDropdown
} from '@/components'
import { useExternalProviderStore } from '@/hook'
import { type ExternalProvider, type UrgentIQDocument } from '@/types'

const EFaxFormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '-20px',
  gap: '18px',
  width: '100%',
  height: 'auto',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  overflowY: 'scroll',
}))
const ErrorMessages = styled(Typography)({
  color: 'red',
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.01em',
  textAlign: 'left',
  marginTop: '-18px',
})
export const EFaxForm = ({
  register,
  control,
  errors,
  setError,
  setValue,
  faxTitle = '',
  availableDocumentList,
  setSelectedDocumentIds
}: EFaxFormProps): JSX.Element => {
  const { ExternalProviders: externalContacts } = useExternalProviderStore()
  const [privateCareProvider, setPrivateCareProvider] =
    React.useState<ExternalProvider | null>(null)
  const [pcpInputValue, setPCPInputValue] = React.useState<string>('')

  React.useEffect(() => {
    setValue('to', pcpInputValue)
  }, [pcpInputValue])

  React.useEffect(() => {
    if (faxTitle !== '') {
      setValue('subject', faxTitle)
    }
  }, [faxTitle])

  const handlePCPChange = (
    event: any,
    newValue: ExternalProvider | null
  ): void => {
    const recipientName = `${newValue?.firstName ?? ''} ${
      newValue?.lastName ?? ''
    }`
    setValue('to', recipientName)
    setValue('faxPhoneNumber', newValue?.faxNumber)
    setPrivateCareProvider(newValue)
  }
  return (
    <EFaxFormContainer>
      <div>
        <TileInputLabel>To</TileInputLabel>
        <PCPDropdown
          data={externalContacts ?? []}
          dataTestId="pcp-textfield"
          inputValue={pcpInputValue}
          setInputValue={setPCPInputValue}
          onChange={handlePCPChange}
          value={privateCareProvider}
          width="100%"
          placeholder="Search by name, speciality, or location"
        />
        {errors.to !== undefined && errors.to !== null && (
          <ErrorMessages>{errors.to.message as string}</ErrorMessages>
        )}
      </div>

      <div>
        <TileInputLabel>Fax Number</TileInputLabel>
        <PhoneNumberInput
          control={control}
          error={errors.faxPhoneNumber}
          width="100%"
          labelName="Fax Number"
          sectionName="fax"
          hideLabel
          required
          setError={setError}
        />
      </div>
      <EFaxTextBox label="Subject" name="subject" register={register} />
      {errors.subject !== undefined && errors.subject !== null && (
        <ErrorMessages>{errors.subject.message as string}</ErrorMessages>
      )}
      <EFaxTextArea label="Message" name="message" register={register} />
      {errors.message !== undefined && errors.message !== null && (
        <ErrorMessages>{errors.message.message as string}</ErrorMessages>
      )}
    </EFaxFormContainer>
  )
}

interface EFaxFormProps {
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  errors: FieldErrorsImpl<Record<string, any>>;
  setError: any;
  setValue: any;
  faxTitle?: string;
  availableDocumentList?: UrgentIQDocument[];
  setSelectedDocumentIds: (value: number[]) => void;
}
