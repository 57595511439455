import React from 'react'
import {
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Alert,
  TextField,
} from '@mui/material'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { useAuth, useUser } from '@clerk/nextjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty, ADMIN, URGENT_IQ_EMPLOYEE } from '@/utility'
import { type UserRole, UserRoleNames, type AccountUser } from '@/types'
import {
  ModalContainer,
  ModalRow,
  FullColumn,
  ModalButton,
  ModalTitle,
  ModalFullTextField,
  ModalButtonRow,
  HalfColumn,
  RedText,
  ErrorMessage,
} from '@/components'
import { useMutateAddAccountUser, useMutateUpdateAccountUser } from '@/hook'

const validationSchema = (exisitingUser: any): any =>
  Yup.object().shape({
    userFirstName: Yup.string().required('First Name is required'),
    userLastName: Yup.string().required('Last name is required'),
    userEmail: Yup.string().required('Email is required'),
    userPassword:
      exisitingUser === null
        ? Yup.string().required('Password is required')
        : Yup.string(),
    userName: Yup.string()
      .min(3, 'Username must be at least 3 characters long')
      .matches(
        /^[a-zA-Z0-9_-]+$/,
        'Username can only contain letters, numbers, "_" or "-"'
      )
      .required('Name is required'),
  })

interface AddUserForm {
  userFirstName?: string | null;
  userLastName?: string | null;
  userEmail?: string | null;
  userPassword?: string | null;
  userRole?: UserRole[] | null;
  userName?: string | null;
  userPin?: string | null;
  dosepotUserId?: number | null;
  npiNumber?: string | null;
  doseSpotOnBehalfOfUserId?: number | null;
  amdProviderCode?: string | null;
}

export const AddUser = ({
  handleClose,
  exisitingUser = null,
  refresh,
}: AddUserProps): JSX.Element => {
  const { getToken } = useAuth()
  const { user } = useUser()
  const addNewUserMutation = useMutateAddAccountUser(getToken)
  const updateUserMutation = useMutateUpdateAccountUser(getToken)
  const showDoseNPI = (): boolean => {
    let show = false
    const userRole: number[] = user?.publicMetadata?.role as number[]
    if (userRole.includes(URGENT_IQ_EMPLOYEE)) {
      show = true
    }
    return show
  }
  const showPassword = (): boolean => {
    let show = false
    const userRole: number[] = user?.publicMetadata?.role as number[]
    if (userRole.includes(URGENT_IQ_EMPLOYEE) || userRole.includes(ADMIN)) {
      show = true
    }
    return show
  }
  const [toastMessage, setToastMessage] = React.useState<string>('')

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<AddUserForm>({
    mode: 'all',
    resolver: yupResolver(validationSchema(exisitingUser)),
    ...(exisitingUser !== null && {
      defaultValues: {
        userFirstName: exisitingUser.firstName,
        userLastName: exisitingUser.lastName,
        userEmail: exisitingUser.email,
        userPassword: '',
        userRole: exisitingUser.role,
        userName: exisitingUser.username,
        dosepotUserId: exisitingUser.dosespotUserId,
        doseSpotOnBehalfOfUserId: exisitingUser.doseSpotOnBehalfOfUserId,
        npiNumber: exisitingUser.npiNumber,
        amdProviderCode: exisitingUser.amdProviderCode,
      },
    }),
  })

  React.useEffect(() => {
    if (exisitingUser === null) {
      const chars =
        '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      const passwordLength = 12
      let password = ''
      for (let i = 0; i < passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length)
        password += chars.substring(randomNumber, randomNumber + 1)
      }
      setValue('userPassword', password)
    }
  }, [])

  const handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return
    }
    setToastMessage('')
  }

  const handleReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault()
    reset()
    handleClose()
  }
  const onSubmit: SubmitHandler<AddUserForm> = (data): void => {
    const user: AccountUser = {
      firstName: data.userFirstName,
      lastName: data.userLastName,
      email: data.userEmail,
      id: exisitingUser?.id ?? 0,
      username: data.userName,
      role: [(data.userRole ?? 0) as UserRole].flat(),
      dosespotUserId: data.dosepotUserId,
      npiNumber: data.npiNumber,
      doseSpotOnBehalfOfUserId: data.doseSpotOnBehalfOfUserId,
      amdProviderCode: data.amdProviderCode,
    }
    if (exisitingUser !== null) {
      user.healthGorillaPractitionerId =
        exisitingUser.healthGorillaPractitionerId
      if (data.userPassword) {
        user.resetPassword = true
        user.password = data.userPassword
      } else {
        user.resetPassword = false
      }
    } else {
      user.resetPassword = true
      user.password = data.userPassword
    }
    const response =
      exisitingUser === null
        ? addNewUserMutation.mutateAsync({ user })
        : updateUserMutation.mutateAsync({
            accountUserId: user?.id!,
            user,
          })
    Promise.resolve(response)
      .then((response) => {
        refresh()
        handleClose()
      })
      .catch((error) => {
        if (!isEmpty(error)) {
          if (error.includes('=>')) {
            setToastMessage(JSON.parse(error).Message.split('=>')[1])
          } else {
            setToastMessage(error)
          }
        }
        throw error
      })
  }

  return (
    <>
      <ModalContainer>
        <form
          style={{ width: '100%' }}
          onSubmit={(...args): void => {
            handleSubmit(onSubmit)(...args).catch((error) => {
              throw error
            })
          }}
        >
          <ModalTitle>
            {exisitingUser === null ? 'Add User' : 'Edit User'}
          </ModalTitle>
          <ModalRow>
            <HalfColumn>
              <InputLabel>
                First Name<RedText>*</RedText>
              </InputLabel>
              <ModalFullTextField
                name={'userFirstName'}
                register={register}
                error={
                  errors.userFirstName !== undefined &&
                  errors.userFirstName !== null
                }
              />
              {!isEmpty(errors.userFirstName) && (
                <ErrorMessage error={errors.userFirstName?.message ?? ''} />
              )}
            </HalfColumn>
            <HalfColumn>
              <InputLabel>
                Last Name<RedText>*</RedText>
              </InputLabel>
              <ModalFullTextField
                name={'userLastName'}
                register={register}
                error={
                  errors.userLastName !== undefined &&
                  errors.userLastName !== null
                }
              />
              {!isEmpty(errors.userLastName) && (
                <ErrorMessage error={errors.userLastName?.message ?? ''} />
              )}
            </HalfColumn>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <InputLabel>
                User Name<RedText>*</RedText>
              </InputLabel>
              <ModalFullTextField
                name={'userName'}
                register={register}
                error={
                  errors.userName !== undefined && errors.userName !== null
                }
              />
              {!isEmpty(errors.userName) && (
                <ErrorMessage error={errors.userName?.message ?? ''} />
              )}
            </FullColumn>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <InputLabel>
                User Email<RedText>*</RedText>
              </InputLabel>
              <TextField
                {...register('userEmail', {
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  },
                })}
                type="email"
                inputProps={{ 'data-testid': 'userEmail' }}
                error={
                  errors.userEmail !== undefined && errors.userEmail !== null
                }
              />
              {!isEmpty(errors.userEmail) && (
                <ErrorMessage error={errors.userEmail?.message ?? ''} />
              )}
            </FullColumn>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <InputLabel>
                User Role<RedText>*</RedText>
              </InputLabel>
              <Controller
                name="userRole"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    tabIndex={5}
                    labelId="GenderLabel"
                    error={
                      errors.userRole !== undefined && errors.userRole !== null
                    }
                  >
                    <MenuItem value={0}>{UserRoleNames[0]}</MenuItem>
                    <MenuItem value={1}>{UserRoleNames[1]}</MenuItem>
                    <MenuItem value={2}>{UserRoleNames[2]}</MenuItem>
                    <MenuItem value={5}>{UserRoleNames[5]}</MenuItem>
                    <MenuItem value={6}>{UserRoleNames[6]}</MenuItem>
                  </Select>
                )}
              />
            </FullColumn>
          </ModalRow>
          {(exisitingUser === null || showPassword()) && (
            <ModalRow>
              <FullColumn>
                <InputLabel>
                  Temporary Password<RedText>*</RedText>
                </InputLabel>
                <ModalFullTextField
                  name={'userPassword'}
                  register={register}
                  error={
                    errors.userPassword !== undefined &&
                    errors.userPassword !== null
                  }
                />
                {!isEmpty(errors.userPassword) && (
                  <ErrorMessage error={errors.userPassword?.message ?? ''} />
                )}
              </FullColumn>
            </ModalRow>
          )}
          {showDoseNPI() && (
            <>
              <ModalRow>
                <FullColumn>
                  <InputLabel>Dosespot User ID</InputLabel>
                  <ModalFullTextField
                    name={'dosepotUserId'}
                    register={register}
                  />
                </FullColumn>
              </ModalRow>
              <ModalRow>
                <FullColumn>
                  <InputLabel>Dosespot OnBehalf User ID</InputLabel>
                  <ModalFullTextField
                    name={'doseSpotOnBehalfOfUserId'}
                    register={register}
                  />
                </FullColumn>
              </ModalRow>
              <ModalRow>
                <FullColumn>
                  <InputLabel>NPI Number</InputLabel>
                  <ModalFullTextField name={'npiNumber'} register={register} />
                </FullColumn>
              </ModalRow>
              <ModalRow>
                <FullColumn>
                  <InputLabel>AMD Provider Code</InputLabel>
                  <ModalFullTextField
                    name={'amdProviderCode'}
                    register={register}
                  />
                </FullColumn>
              </ModalRow>
            </>
          )}
          <ModalButtonRow>
            <ModalButton
              variant="outlined"
              type="reset"
              onClick={(event) => handleReset(event)}
            >
              Cancel
            </ModalButton>
            <ModalButton variant="contained" color="primary" type="submit">
              {exisitingUser === null ? 'Add' : 'Save'}
            </ModalButton>
          </ModalButtonRow>
        </form>
      </ModalContainer>
      <Snackbar
        open={!isEmpty(toastMessage)}
        autoHideDuration={6000}
        onClose={handleToastClose}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

interface AddUserProps {
  handleClose: any;
  exisitingUser?: AccountUser | null;
  refresh: any;
}
