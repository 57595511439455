import React from 'react'
import {
  Button,
  Divider,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  styled,
  TextField,
  InputAdornment,
  OutlinedInput,
  Box,
  Stack,
  Autocomplete,
  CircularProgress,
  type Theme,
  useTheme,
  Tooltip,
  Select,
  MenuItem,
  Typography,
} from '@mui/material'
import {
  type Control,
  Controller,
  type FieldValues,
  type UseFormRegister,
  useWatch,
} from 'react-hook-form'
import {
  type InsuranceType,
  type OcrInsurance,
  type PVerifyInsurance,
} from '@/types'
import {
  ErrorMessage,
  RedText,
  InsuranceCard,
  TileSubtitle,
  TileTextArea,
} from '@/components'
import { isEmpty } from '@/utility/utils'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { format, isDate, subDays } from 'date-fns'
import { DatePicker } from '@/components/DatePicker'
import { uploadFile } from '@/helpers'
import { getOCRInsuranceValues } from '@/services/OCRService'

import { useAuth } from '@clerk/nextjs'
import { deleteDocument } from '@/services'

const InsuranceRow = styled('div')({
  display: 'flex',
})
const InsuranceInnerRow = styled(InsuranceRow)({
  gap: '24px',
  width: '768px',
  justifyContent: 'space-between',
  marginTop: '12px',
})
const InsuranceColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})
const ShimmerDiv = styled('div')({
  width: '170px',
  height: '36px',
  borderRadius: '4px',
  background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
  backgroundSize: '200% 100%',
  animation: 'shimmer 1.5s infinite',
  '@keyframes shimmer': {
    '0%': {
      backgroundPosition: '-200% 0',
    },
    '100%': {
      backgroundPosition: '200% 0',
    },
  },
})
const CopayContainer = styled(Typography)({
  fontSize: '16px',
  lineHeight: '18.75px',
  color: 'black',
})

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isOCRed',
})<StyledTextFieldProps>(({ isOCRed = false, theme }) => ({
  ...(isOCRed && {
    border: `1px solid ${theme?.palette.primary.main}`,
    borderRadius: '6px',
  }),
  '& > div': {
    width: '170px',
  },
}))

interface StyledTextFieldProps {
  theme?: Theme;
  isOCRed?: boolean;
}

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }): any => ({
  fontSize: '16px',
  lineHeight: '18.75px',
  color: 'black',
}))
const StyledOutlineInput = styled(OutlinedInput)({})
const StyleTextButton = styled(Button)({
  width: '200px',
  fontWeight: '600',
  border: 'none',
})
const CopayLabel = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '18.75px',
})

export const InsuranceInfoRow = ({
  control,
  register,
  idFile,
  setPhotoFile,
  insuranceType,
  setValue,
  errors,
  insuranceEligibility,
  verifyInsurance,
  insuranceNames,
  isEditing = true,
  isIntake = false,
  isVerifying = false,
  reset = false,
  fullResponse = '',
}: InsuranceInfoProps): JSX.Element => {
  const { getToken } = useAuth()
  const theme = useTheme()
  const [isOCRLoading, setIsOCRLoading] = React.useState<boolean>(false)
  const [didUserReset, setDidUserReset] = React.useState<boolean>(false)
  const [pVerifyCode, setPVerifyCode] = React.useState<string>('')
  const [isOCRDisabled, setIsOCRDisabled] = React.useState(true)
  const [prevIsEditing, setPrevIsEditing] = React.useState(isEditing)
  const [fieldSelected, setFieldSelected] = React.useState<FieldSelectedType>({
    memberId: false,
    insuranceCompanyName: false,
    coverageDate: false,
    groupNumber: false,
    plan: false,
    rxBin: false,
    payerId: false,
    subscriberName: false,
  })
  const holderWatch = useWatch({
    control,
    name: `${insuranceType}InsuranceHolder`,
  })
  const firstNameWatch = useWatch({
    control,
    name: 'firstName',
  })
  const lastNameWatch = useWatch({
    control,
    name: 'lastName',
  })
  const dobWatch = useWatch({
    control,
    name: 'birthDate',
  })
  const insuranceName = useWatch({
    control,
    name: `${insuranceType}company`,
  })
  const insuranceNumber = useWatch({
    control,
    name: `${insuranceType}insuranceNumber`,
  })
  const insuranceGroupNumber = useWatch({
    control,
    name: `${insuranceType}groupNumber`,
  })
  const insuranceHolder = useWatch({
    control,
    name: `${insuranceType}InsuranceHolder`,
  })
  const insuranceFirstNameOfHolder = useWatch({
    control,
    name: `${insuranceType}FirstNameOfHolder`,
  })
  const insuranceLastNameOfHolder = useWatch({
    control,
    name: `${insuranceType}LastNameOfHolder`,
  })
  const insuranceHolderDob = useWatch({
    control,
    name: `${insuranceType}InsuranceBirthDate`,
  })
  const insuranceHolderSex = useWatch({
    control,
    name: `${insuranceType}Sex`,
  })
  const effectiveDate = useWatch({
    control,
    name: `${insuranceType}effectiveDate`,
  })
  const insurancePlan = useWatch({
    control,
    name: `${insuranceType}plan`,
  })
  const rxBin = useWatch({
    control,
    name: `${insuranceType}rxBinNumber`,
  })
  const payerId = useWatch({
    control,
    name: `${insuranceType}payerId`,
  })

  const relationToInsured = useWatch({
    control,
    name: `${insuranceType}relationToInsured`,
  })

  const [isFullResponseVisible, setIsFullResponseVisible] =
    React.useState<boolean>(false)

  const isInfoEntered =
    !isEmpty(insuranceName) ||
    !isEmpty(insuranceNumber) ||
    !isEmpty(insuranceGroupNumber) ||
    !isEmpty(insuranceHolder) ||
    !isEmpty(effectiveDate) ||
    !isEmpty(insurancePlan) ||
    !isEmpty(rxBin) ||
    !isEmpty(payerId) ||
    !isEmpty(relationToInsured)

  const [insuranceNameLabel, setInsuranceNameLabel] =
    React.useState<string>('')
  const [insuranceAddress, setInsuranceAddress] = React.useState<string>('')
  const [selectedInsurance, setSelectedInsurance] = React.useState({
    id: 0,
    label: '',
    address: '',
  })

  const isVerified =
    insuranceEligibility?.urgentCareEligibilityStatus === 'Active Coverage' ||
    insuranceEligibility?.apiResponseMessage === 'Processed'
  const [internalPhoto, setInternalPhoto] = React.useState<
    Blob | null | undefined
  >(null)
  const [informationOCRed, setInformationOCRed] =
    React.useState<boolean>(false)
  const [isRelationshipVisible, setIsRelationshipVisible] =
    React.useState<boolean>(false)
  const [isSubscriberSexVisible, setIsSubscriberSexVisible] =
    React.useState<boolean>(false)

  const previousHolderRef = React.useRef()

  const updateInsuranceHolderInfo = (
    holder: any,
    firstName: any,
    lastName: any,
    dob: any
  ) => {
    if (informationOCRed || holder !== 'self') return
    setValue(
      `${insuranceType}FirstNameOfHolder`,
      holder === 'self' ? firstName : ''
    )
    setValue(
      `${insuranceType}LastNameOfHolder`,
      holder === 'self' ? lastName : ''
    )
    setValue(
      `${insuranceType}InsuranceBirthDate`,
      holder === 'self' ? dob : null
    )
  }

  React.useEffect(() => {
    if (isEditing && prevIsEditing) {
      setIsOCRDisabled(false)
    }
    if (isEditing !== prevIsEditing) {
      setIsOCRDisabled(!isEditing)
      setPrevIsEditing(isEditing)
    }
  }, [isEditing, prevIsEditing])

  React.useEffect(() => {
    if (insuranceName) {
      setInsuranceNameLabel(insuranceName?.label ?? '')
      const insurance: PVerifyInsurance =
        insuranceNames.find((iN) => iN.id === insuranceName?.id) ?? {}
      setPVerifyCode(insurance?.pVerifyPayerCode ?? '')
      setInsuranceAddress(insurance?.address1 ?? '')
    }
  }, [insuranceName])

  React.useEffect(() => {
    setDidUserReset(reset)
  }, [reset])

  React.useEffect(() => {
    setIsRelationshipVisible(holderWatch === 'other')
    setIsSubscriberSexVisible(holderWatch && holderWatch !== 'self')

    if (informationOCRed && holderWatch !== 'self') {
      previousHolderRef.current = holderWatch
      setInformationOCRed(false)
      return
    }

    if (isIntake) {
      updateInsuranceHolderInfo(
        holderWatch,
        firstNameWatch,
        lastNameWatch,
        dobWatch
      )
    } else if (!holderWatch || previousHolderRef.current === 'self') {
      updateInsuranceHolderInfo(
        holderWatch,
        firstNameWatch,
        lastNameWatch,
        dobWatch
      )
      setValue(`${insuranceType}Sex`, '')
    }

    previousHolderRef.current = holderWatch
  }, [
    holderWatch,
    firstNameWatch,
    lastNameWatch,
    dobWatch,
    informationOCRed,
    isIntake,
  ])

  React.useEffect(() => {
    if (informationOCRed) return
    if ((isEmpty(holderWatch) || holderWatch === 'self') && isIntake) {
      updateInsuranceHolderInfo(
        holderWatch,
        firstNameWatch,
        lastNameWatch,
        dobWatch
      )
    }
  }, [
    firstNameWatch,
    lastNameWatch,
    dobWatch,
    holderWatch,
    isIntake,
    informationOCRed,
  ])

  React.useEffect(() => {
    setInternalPhoto(idFile)
    if (isOCRDisabled) return
    if (idFile !== null && idFile !== undefined) {
      setIsOCRLoading(true)
      setDidUserReset(false)
      Promise.resolve(
        uploadFile(
          idFile,
          undefined,
          1,
          undefined,
          undefined,
          `insuranceCard${idFile.type.split('/')[1]}`,
          undefined,
          getToken
        )
      )
        .then((res) => {
          Promise.resolve(getOCRInsuranceValues(res.id as number, getToken))
            .then((res1) => {
              if (res1) {
                const result: OcrInsurance = res1
                const fieldMap: FieldMapType = {
                  memberId: `${insuranceType}insuranceNumber`,
                  insuranceCompanyName: `${insuranceType}company`,
                  coverageDate: `${insuranceType}effectiveDate`,
                  groupNumber: `${insuranceType}groupNumber`,
                  plan: `${insuranceType}plan`,
                  rxBin: `${insuranceType}rxBinNumber`,
                  payerId: `${insuranceType}payerId`,
                  subscriberName: '',
                }
                const isKeyOfFieldMap = (
                  key: string
                ): key is keyof FieldMapType => {
                  return key in fieldMap
                }
                const processOcrResults = (ocrResult: any): any => {
                  Object.entries(ocrResult).forEach(([key, value]) => {
                    if (value !== null && value !== undefined) {
                      if (isKeyOfFieldMap(key)) {
                        if (key === 'insuranceCompanyName') {
                          const insurance: any = insuranceNames.find(
                            (iN) =>
                              iN.payerName?.replace('""', '').toLowerCase() ===
                              (value as string).replace('®', '').toLowerCase()
                          )
                          setValue(fieldMap[key], {
                            id: insurance?.id ?? 0,
                            label: insurance?.payerName ?? '',
                          })
                        } else if (key === 'subscriberName') {
                          setInformationOCRed(true)
                          const name = (value as string).split(' ')
                          setValue(
                            `${insuranceType}FirstNameOfHolder`,
                            name[0]
                          )
                          setValue(
                            `${insuranceType}LastNameOfHolder`,
                            name[name.length > 2 ? 2 : 1]
                          )
                        } else {
                          setValue(fieldMap[key], value)
                        }
                        setFieldSelected((prevState) => ({
                          ...prevState,
                          [key]: true,
                        }))
                      } else {
                        setValue('', value)
                      }
                    }
                  })
                }
                processOcrResults(result)
                setInformationOCRed(true)
                if (
                  insuranceFirstNameOfHolder !== firstNameWatch ||
                  insuranceFirstNameOfHolder !== lastNameWatch
                ) {
                  setValue(`${insuranceType}InsuranceHolder`, 'other')
                  setIsSubscriberSexVisible(true)
                }
              }
            })
            .finally(() => {
              deleteDocument(res?.id as number, getToken).catch((err) => {
                throw err
              })
              setIsOCRLoading(false)
            })
        })
        .catch((err) => {
          console.error(err)
          setIsOCRLoading(false)
        })
    }
  }, [idFile])

  return (
    <>
      <InsuranceRow sx={{ alignItems: 'center' }}>
        <InsuranceColumn sx={{ width: '360px' }}>
          <InsuranceCard
            idFile={internalPhoto}
            setPhotoFile={setPhotoFile}
            register={register}
            disabled={!isEditing}
            isTextEntered={isInfoEntered}
          />
        </InsuranceColumn>
        <Divider
          sx={{ marginRight: '10px', marginLeft: '10px', color: '#DDDFE8' }}
          flexItem
          orientation="vertical"
        />
        <InsuranceColumn sx={{ width: '440px', marginLeft: '5px' }}>
          <InsuranceRow sx={{ gap: '10px' }}>
            <InsuranceColumn sx={{ width: '200px' }}>
              <div>
                <InputLabel>Member ID{<RedText>{' *'}</RedText>}</InputLabel>
                {isEditing ? (
                  isOCRLoading ? (
                    <ShimmerDiv />
                  ) : (
                    <StyledTextField
                      {...register(`${insuranceType}insuranceNumber`)}
                      theme={theme}
                      isOCRed={fieldSelected.memberId && !didUserReset}
                      inputProps={{
                        maxLength: 20,
                        'data-testid': `${insuranceType}insuranceNumber`,
                      }}
                      onChange={(e) => {
                        if (fieldSelected.memberId) {
                          setFieldSelected((prevState) => {
                            return {
                              ...prevState,
                              memberId: false,
                            }
                          })
                        }
                        register(`${insuranceType}insuranceNumber`)
                          .onChange(e)
                          .catch((err) => {
                            throw err
                          })
                      }}
                    />
                  )
                ) : (
                  <TileSubtitle>{insuranceNumber}</TileSubtitle>
                )}
                {!isEmpty(errors[`${insuranceType}insuranceNumber`]) && (
                  <ErrorMessage
                    error={
                      errors[`${insuranceType}insuranceNumber`]
                        ?.message as string
                    }
                  />
                )}
              </div>
              <div>
                <InputLabel>
                  Insurance Name<RedText>{' *'}</RedText>
                </InputLabel>
                {isEditing ? (
                  isOCRLoading ? (
                    <ShimmerDiv />
                  ) : (
                    <Controller
                      name={`${insuranceType}company`}
                      control={control}
                      defaultValue={selectedInsurance}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={insuranceNames
                            ?.sort((a, b) =>
                              (a?.payerName ?? '').localeCompare(
                                b?.payerName ?? ''
                              )
                            )
                            ?.map((option) => {
                              return {
                                id: option.id,
                                label: option.payerName,
                                address: option.address1,
                              }
                            })}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                          onChange={(_, value) => {
                            if (fieldSelected.insuranceCompanyName) {
                              setFieldSelected((prevState) => {
                                return {
                                  ...prevState,
                                  insuranceCompanyName: false,
                                }
                              })
                            }
                            setSelectedInsurance(value)
                            field.onChange(value)
                          }}
                          freeSolo
                          sx={{
                            width: '170px !important',
                            ...(fieldSelected.insuranceCompanyName &&
                              !didUserReset && {
                                border: `1px solid ${theme.palette.primary.main}`,
                                borderRadius: '6px',
                              }),
                          }}
                          onInputChange={(_, value) => {
                            const insurance: any = insuranceNames.find(
                              (iN) =>
                                iN.payerName
                                  ?.replace('""', '')
                                  .toLowerCase() ===
                                value.replace('®', '').toLowerCase()
                            )
                            const newValue = {
                              id: insurance?.id ?? 0,
                              label: value,
                              address: insurance?.address1 ?? '',
                            }
                            setSelectedInsurance(newValue)
                            field.onChange({
                              id: insurance?.id ?? 0,
                              label: value,
                              address: insurance?.address1 ?? '',
                            })
                          }}
                          getOptionLabel={(option) => option.label as string}
                          noOptionsText="Enter a value to search"
                          componentsProps={{
                            paper: {
                              style: {
                                width: '408px',
                                marginTop: '6px',
                                marginBottom: '6px',
                              },
                            },
                          }}
                          data-testid={`${insuranceType}company`}
                          renderOption={(props, option) => (
                            <li {...props} data-testid={option.label}>
                              <Stack direction="column">
                                <Typography
                                  fontWeight="bold"
                                  sx={{ fontSize: '1.1rem' }}
                                >
                                  {option.label}
                                </Typography>
                                <Typography sx={{ color: 'grey.700' }}>
                                  {option.address}
                                </Typography>
                              </Stack>
                            </li>
                          )}
                          renderInput={(params) => (
                            <StyledTextField
                              sx={{
                                '& > div': {
                                  '& > input': {
                                    marginTop: '-10px',
                                  },
                                },
                              }}
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                'data-testid': `${insuranceType}companyInput`,
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  )
                ) : (
                  <>
                    <TileSubtitle>{insuranceNameLabel}</TileSubtitle>
                    <TileSubtitle>{insuranceAddress}</TileSubtitle>
                  </>
                )}
                {!isEmpty(errors[`${insuranceType}company`]) && (
                  <ErrorMessage error={'Insurance Name is required'} />
                )}
              </div>
              {isEditing ? (
                isOCRLoading ? (
                  <div>
                    <InputLabel>Effective Date</InputLabel>
                    <ShimmerDiv />
                  </div>
                ) : (
                  <DatePicker
                    name={`${insuranceType}effectiveDate`}
                    control={control}
                    defaultValue={null}
                    label="Effective Date"
                    errors={errors}
                    dataTestId={`${insuranceType}effectiveDate`}
                    smaller
                    isSelected={fieldSelected.coverageDate && !didUserReset}
                  />
                )
              ) : (
                <div>
                  <InputLabel>Effective Date</InputLabel>
                  <TileSubtitle>
                    {isDate(effectiveDate)
                      ? format(effectiveDate, 'M/d/yyyy')
                      : ''}
                  </TileSubtitle>
                </div>
              )}
              <div style={{ minHeight: '56px' }} />
            </InsuranceColumn>
            <InsuranceColumn
              sx={{
                width: '200px',
                justifyContent: 'flex-start',
              }}
            >
              <div>
                {/* <InputLabel>Group #<RedText>{' *'}</RedText></InputLabel> */}
                <InputLabel>Group #</InputLabel>
                {isEditing ? (
                  isOCRLoading ? (
                    <ShimmerDiv />
                  ) : (
                    <StyledTextField
                      {...register(`${insuranceType}groupNumber`)}
                      inputProps={{
                        'data-testid': `${insuranceType}groupNumber`,
                      }}
                      isOCRed={fieldSelected.groupNumber && !didUserReset}
                      theme={theme}
                      onChange={(e) => {
                        if (fieldSelected.groupNumber) {
                          setFieldSelected((prevState) => {
                            return {
                              ...prevState,
                              groupNumber: false,
                            }
                          })
                        }
                        register(`${insuranceType}groupNumber`)
                          .onChange(e)
                          .catch((err) => {
                            throw err
                          })
                      }}
                    />
                  )
                ) : (
                  <TileSubtitle>{insuranceGroupNumber}</TileSubtitle>
                )}
                {!isEmpty(errors[`${insuranceType}groupNumber`]) && (
                  <ErrorMessage
                    error={errors[`${insuranceType}groupNumber`]?.message}
                  />
                )}
              </div>
              <div>
                <InputLabel>Plan</InputLabel>
                {isEditing ? (
                  isOCRLoading ? (
                    <ShimmerDiv />
                  ) : (
                    <StyledTextField
                      {...register(`${insuranceType}plan`)}
                      inputProps={{ 'data-testid': `${insuranceType}plan` }}
                      isOCRed={fieldSelected.plan && !didUserReset}
                      theme={theme}
                      onChange={(e) => {
                        if (fieldSelected.plan) {
                          setFieldSelected((prevState) => {
                            return {
                              ...prevState,
                              plan: false,
                            }
                          })
                        }
                        register(`${insuranceType}plan`)
                          .onChange(e)
                          .catch((err) => {
                            throw err
                          })
                      }}
                    />
                  )
                ) : (
                  <TileSubtitle>{insurancePlan}</TileSubtitle>
                )}
                {!isEmpty(errors[`${insuranceType}plan`]) && (
                  <ErrorMessage
                    error={errors[`${insuranceType}plan`]?.message}
                  />
                )}
              </div>
              <div>
                <InputLabel>Rx Bin #</InputLabel>
                {isEditing ? (
                  isOCRLoading ? (
                    <ShimmerDiv />
                  ) : (
                    <StyledTextField
                      {...register(`${insuranceType}rxBinNumber`)}
                      inputProps={{
                        maxLength: 6,
                        minLength: 6,
                        'data-testid': `${insuranceType}rxBinNumber`,
                      }}
                      isOCRed={fieldSelected.rxBin && !didUserReset}
                      theme={theme}
                      onChange={(e) => {
                        if (fieldSelected.rxBin) {
                          setFieldSelected((prevState) => {
                            return {
                              ...prevState,
                              rxBin: false,
                            }
                          })
                        }
                        register(`${insuranceType}rxBinNumber`)
                          .onChange(e)
                          .catch((err) => {
                            throw err
                          })
                      }}
                    />
                  )
                ) : (
                  <TileSubtitle>{rxBin}</TileSubtitle>
                )}
              </div>
              <div>
                <InputLabel>Payer ID</InputLabel>
                {isEditing ? (
                  isOCRLoading ? (
                    <ShimmerDiv />
                  ) : (
                    <StyledTextField
                      {...register(`${insuranceType}payerId`)}
                      inputProps={{ 'data-testid': `${insuranceType}payerId` }}
                      isOCRed={fieldSelected.payerId && !didUserReset}
                      theme={theme}
                      onChange={(e) => {
                        if (fieldSelected.payerId) {
                          setFieldSelected((prevState) => {
                            return {
                              ...prevState,
                              payerId: false,
                            }
                          })
                        }
                        register(`${insuranceType}payerId`)
                          .onChange(e)
                          .catch((err) => {
                            throw err
                          })
                      }}
                    />
                  )
                ) : (
                  <TileSubtitle>{payerId}</TileSubtitle>
                )}
                {!isEmpty(errors[`${insuranceType}payerId`]) && (
                  <ErrorMessage
                    error={errors[`${insuranceType}payerId`]?.message as string}
                  />
                )}
              </div>
            </InsuranceColumn>
          </InsuranceRow>
        </InsuranceColumn>
      </InsuranceRow>
      <Divider sx={{ marginTop: '20px', color: '#DDDFE8' }} flexItem />
      <InsuranceInnerRow sx={{ minHeight: '56px' }}>
        <div>
          <InputLabel>Relationship to Policy Holder</InputLabel>
          {isEditing ? (
            <Controller
              name={`${insuranceType}InsuranceHolder`}
              control={control}
              defaultValue={'self'}
              render={({ field }) => (
                <RadioGroup row {...field}>
                  <StyledFormControlLabel
                    value="self"
                    control={<Radio data-testid={'ihSelf'} />}
                    label="Self"
                  />
                  <StyledFormControlLabel
                    value="spouse"
                    control={<Radio data-testid={'ihSpouse'} />}
                    label="Spouse"
                  />
                  <StyledFormControlLabel
                    value="child"
                    control={<Radio data-testid={'ihChild'} />}
                    label="Child"
                  />
                  <StyledFormControlLabel
                    value="mother"
                    control={<Radio data-testid={'ihMother'} />}
                    label="Mother"
                  />
                  <StyledFormControlLabel
                    value="father"
                    control={<Radio data-testid={'ihFather'} />}
                    label="Father"
                  />
                  <StyledFormControlLabel
                    value="other"
                    control={<Radio data-testid={'ihOther'} />}
                    label="Other"
                  />
                </RadioGroup>
              )}
            />
          ) : (
            <TileSubtitle>
              {!isEmpty(insuranceHolder)
                ? (insuranceHolder as string).charAt(0).toUpperCase() +
                  (insuranceHolder as string).slice(1)
                : ''}
            </TileSubtitle>
          )}
        </div>
        {isRelationshipVisible && (
          <div style={{ paddingLeft: '10px', marginLeft: '6px' }}>
            <InputLabel>Relation to Insured</InputLabel>
            {isEditing ? (
              <TextField
                sx={{ width: '240px' }}
                {...register(`${insuranceType}relationToInsured`)}
                inputProps={{
                  'data-testid': `${insuranceType}relationToInsured`,
                }}
              />
            ) : (
              <TileSubtitle>{relationToInsured}</TileSubtitle>
            )}
          </div>
        )}
      </InsuranceInnerRow>
      <InsuranceInnerRow>
        <div>
          <InputLabel>
            Policy Holder&#39;s First Name{<RedText>{' *'}</RedText>}
          </InputLabel>
          {isEditing ? (
            isOCRLoading ? (
              <ShimmerDiv sx={{ width: '240px' }} />
            ) : (
              <TextField
                sx={{
                  width: '240px',
                  ...(fieldSelected.subscriberName && {
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '6px',
                  }),
                }}
                {...register(`${insuranceType}FirstNameOfHolder`)}
                inputProps={{
                  'data-testid': `${insuranceType}FirstNameOfHolder`,
                }}
                onChange={(e) => {
                  if (fieldSelected.subscriberName) {
                    setFieldSelected((prevState) => {
                      return {
                        ...prevState,
                        subscriberName: false,
                      }
                    })
                  }
                  register(`${insuranceType}FirstNameOfHolder`)
                    .onChange(e)
                    .catch((err) => {
                      throw err
                    })
                }}
              />
            )
          ) : (
            <TileSubtitle>{insuranceFirstNameOfHolder}</TileSubtitle>
          )}
          {!isEmpty(errors[`${insuranceType}FirstNameOfHolder`]) && (
            <ErrorMessage
              error={
                errors[`${insuranceType}FirstNameOfHolder`]?.message as string
              }
            />
          )}
        </div>

        <div>
          <InputLabel>
            Policy Holder&#39;s Last Name{<RedText>{' *'}</RedText>}
          </InputLabel>
          {isEditing ? (
            isOCRLoading ? (
              <ShimmerDiv sx={{ width: '240px' }} />
            ) : (
              <TextField
                sx={{
                  width: '240px',
                  ...(fieldSelected.subscriberName && {
                    border: `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '6px',
                  }),
                }}
                {...register(`${insuranceType}LastNameOfHolder`)}
                inputProps={{
                  'data-testid': `${insuranceType}LastNameOfHolder`,
                }}
                onChange={(e) => {
                  if (fieldSelected.subscriberName) {
                    setFieldSelected((prevState) => {
                      return {
                        ...prevState,
                        subscriberName: false,
                      }
                    })
                  }
                  register(`${insuranceType}LastNameOfHolder`)
                    .onChange(e)
                    .catch((err) => {
                      throw err
                    })
                }}
              />
            )
          ) : (
            <TileSubtitle>{insuranceLastNameOfHolder}</TileSubtitle>
          )}
          {!isEmpty(errors[`${insuranceType}LastNameOfHolder`]) && (
            <ErrorMessage
              error={
                errors[`${insuranceType}LastNameOfHolder`]?.message as string
              }
            />
          )}
        </div>

        {isEditing ? (
          isOCRLoading ? (
            <div>
              <InputLabel>
                Policy Holder&#39;s DOB{<RedText>{' *'}</RedText>}
              </InputLabel>
              <ShimmerDiv sx={{ width: '240px' }} />
            </div>
          ) : (
            <DatePicker
              name={`${insuranceType}InsuranceBirthDate`}
              control={control}
              defaultValue={null}
              label="Policy Holder's DOB"
              errors={errors}
              required
              maxDate={subDays(Date.now(), 1)}
              dataTestId={`${insuranceType}InsuranceBirthDate`}
            />
          )
        ) : (
          <div>
            <InputLabel>
              Policy Holder&#39;s DOB{<RedText>{' *'}</RedText>}
            </InputLabel>
            <TileSubtitle>
              {isDate(insuranceHolderDob)
                ? format(insuranceHolderDob, 'MM/dd/yyyy')
                : ''}
            </TileSubtitle>
          </div>
        )}
        <div style={{ minWidth: '300px' }} />
      </InsuranceInnerRow>
      {isSubscriberSexVisible &&
        (isEditing ? (
          <InsuranceColumn>
            <InputLabel>
              Policy Holder&#39;s Sex{<RedText>{' *'}</RedText>}
            </InputLabel>
            <Controller
              name={`${insuranceType}Sex`}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <Select
                  {...field}
                  tabIndex={5}
                  sx={{ width: '240px' }}
                  // data-testid="subscriber-sex"
                >
                  <MenuItem data-testid="male" value={'Male'}>
                    Male
                  </MenuItem>
                  <MenuItem data-testid="female" value={'Female'}>
                    Female
                  </MenuItem>
                  <MenuItem data-testid="other" value={'Other'}>
                    Other
                  </MenuItem>
                  <MenuItem data-testid="NoResponse" value={'NoResponse'}>
                    No Response
                  </MenuItem>
                </Select>
              )}
            />
            {!isEmpty(errors[`${insuranceType}Sex`]) && (
              <ErrorMessage
                error={errors[`${insuranceType}Sex`]?.message as string}
              />
            )}
          </InsuranceColumn>
        ) : (
          <InsuranceColumn>
            <InputLabel>
              Policy Holder&#39;s Sex{<RedText>{' *'}</RedText>}
            </InputLabel>
            <TileSubtitle>{insuranceHolderSex}</TileSubtitle>
          </InsuranceColumn>
        ))}
      {isIntake && (
        <Divider
          sx={{ marginTop: '15px', marginBottom: '10px', color: '#DDDFE8' }}
          flexItem
        />
      )}
      {isIntake && (
        <InsuranceRow>
          <InsuranceColumn>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={'center'}
            >
              <CopayLabel>
                <CopayContainer>Copay/Deductible</CopayContainer>
                {!isEmpty(fullResponse) && (
                  <>
                    <StyleTextButton
                      sx={{
                        width: 'fit-content',
                        marginLeft: '5px',
                        fontSize: '14px',
                        padding: '0 5px',
                      }}
                      onClick={() =>
                        setIsFullResponseVisible(!isFullResponseVisible)
                      }
                    >
                      {isFullResponseVisible ? 'Hide' : 'Show'} Full Response
                    </StyleTextButton>
                  </>
                )}
              </CopayLabel>
              <Tooltip
                arrow
                title={
                  isVerifying
                    ? 'Verifying Insurance'
                    : insuranceName?.id === 0 ||
                      insuranceName?.id === undefined ||
                      isEmpty(insuranceNumber) ||
                      isEmpty(insuranceFirstNameOfHolder) ||
                      isEmpty(insuranceLastNameOfHolder)
                    ? !isEmpty(insuranceName) && insuranceName?.id === 0
                      ? 'Unable to verify manually entered payers'
                      : 'Please enter all required fields'
                    : pVerifyCode !== ''
                    ? 'Verify Insurance'
                    : 'Insurance company must be in PVerify to verify'
                }
              >
                <div>
                  <StyleTextButton
                    sx={{
                      width: 'fit-content',
                      marginLeft: '5px',
                      fontSize: '14px',
                      padding: '0 5px',
                    }}
                    onClick={verifyInsurance}
                    aria-label="Verify Insurance"
                    disabled={
                      isVerifying ||
                      insuranceName?.id === 0 ||
                      insuranceName?.id === undefined ||
                      pVerifyCode === '' ||
                      isEmpty(insuranceNumber) ||
                      isEmpty(insuranceFirstNameOfHolder) ||
                      isEmpty(insuranceLastNameOfHolder)
                    }
                    data-testId="verifyInsurance"
                  >
                    {isVerifying ? (
                      <Box
                        sx={{
                          color: '#0543B0',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress size={14} sx={{ mr: 1 }} /> Verifying
                      </Box>
                    ) : !isEmpty(insuranceEligibility) && isVerified ? (
                      <Box
                        sx={{
                          color: '#00B87C',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CheckCircleIcon /> Verified
                      </Box>
                    ) : !isEmpty(insuranceEligibility) && !isVerified ? (
                      <Box
                        sx={{
                          color: '#f44336',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <HighlightOffIcon /> Verify
                      </Box>
                    ) : (
                      'Verify'
                    )}
                  </StyleTextButton>
                </div>
              </Tooltip>
            </Stack>
            {!isEmpty(fullResponse) && isFullResponseVisible && (
              <InsuranceInnerRow>
                <InsuranceColumn>
                  <div>
                    <InputLabel>Full Response</InputLabel>
                  </div>
                  <TileTextArea
                    value={fullResponse}
                    onChange={() => {}}
                    dataTestId={`${insuranceType}FullResponse`}
                    fullsize
                  />
                </InsuranceColumn>
              </InsuranceInnerRow>
            )}
            <InsuranceInnerRow>
              <div>
                <InputLabel>Copay</InputLabel>
                <StyledOutlineInput
                  sx={{ width: '240px' }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  {...register(`${insuranceType}Copay`)}
                  tabIndex={25}
                  readOnly
                  inputProps={{ 'data-testid': `${insuranceType}Copay` }}
                />
              </div>

              <div>
                <InputLabel>Deductible</InputLabel>
                <StyledOutlineInput
                  sx={{ width: '240px' }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  {...register(`${insuranceType}Deductible`)}
                  inputProps={{ 'data-testid': `${insuranceType}Deductible` }}
                  tabIndex={26}
                  readOnly
                />
              </div>
              <div>
                <InputLabel>Remaining</InputLabel>
                <StyledOutlineInput
                  sx={{ width: '240px' }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  {...register(`${insuranceType}LeftToMeet`)}
                  tabIndex={27}
                  readOnly
                  inputProps={{ 'data-testid': `${insuranceType}LeftToMeet` }}
                />
              </div>
            </InsuranceInnerRow>
            <InsuranceInnerRow>
              <div>
                <InputLabel>Coinsurance</InputLabel>
                <StyledOutlineInput
                  sx={{ width: '240px' }}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  {...register(`${insuranceType}Coinsurance`)}
                  tabIndex={27}
                  readOnly
                />
              </div>
            </InsuranceInnerRow>
          </InsuranceColumn>
        </InsuranceRow>
      )}
    </>
  )
}
interface InsuranceInfoProps {
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  idFile: Blob | null | undefined;
  setPhotoFile: any;
  insuranceType: string;
  setValue: any;
  insuranceTypes: InsuranceType[];
  errors?: any;
  insuranceEligibility?: any;
  verifyInsurance?: any;
  insuranceNames: PVerifyInsurance[];
  isEditing?: boolean;
  isIntake?: boolean;
  isVerifying?: boolean;
  reset?: boolean;
  fullResponse?: string;
}
interface FieldSelectedType {
  memberId: boolean;
  insuranceCompanyName: boolean;
  coverageDate: boolean;
  groupNumber: boolean;
  plan: boolean;
  rxBin: boolean;
  payerId: boolean;
  subscriberName: boolean;
}

interface FieldMapType {
  memberId: string;
  insuranceCompanyName: string;
  coverageDate: string;
  groupNumber: string;
  plan: string;
  rxBin: string;
  payerId: string;
  subscriberName: string;
}
