import { styled } from '@mui/system'
import React from 'react'

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '50%',
})

export const HalfRow = ({ children }: HalfRowProps): JSX.Element => {
  return <Row>{children}</Row>
}
interface HalfRowProps {
  children: any;
}
