import React from 'react'
import { type TaskTableCellProps } from './TaskTableCellProps'
import { AssignAction, StyledTableText } from './styled'
import { useQueryGetTaskPriority } from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { convertToSpaceSeparated } from '@/utility'
import { TaskPriorityColor } from '@/data_helpers/task'
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Tooltip,
} from '@mui/material'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import AddIcon from '@mui/icons-material/Add'
import { useMutateUpdateTask } from '@/hook/useTasks'
import { type Task } from '@/types'

export const PriorityCell = ({
  row,
  cell,
}: TaskTableCellProps): JSX.Element => {
  const { getToken } = useAuth()
  const [hoverAssignPriorityId, setHoverAssignPriorityId] =
    React.useState<number>(-1)
  const { data: userTaskPriority } = useQueryGetTaskPriority(getToken)
  const updateTaskMutation = useMutateUpdateTask(getToken)

  const handleUpdateTask = async (
    taskId: number,
    modification: Partial<Task>
  ): Promise<void> => {
    await updateTaskMutation.mutateAsync({
      id: taskId,
      task: modification,
    })
  }

  const priorityName =
    userTaskPriority?.find(({ id }) => id === row?.original?.priorityId)
      ?.name ?? ''
  return (
    <AssignAction
      className={row?.original?.id === hoverAssignPriorityId ? 'hover' : ''}
      onClick={(e) => {
        e.stopPropagation()
        setHoverAssignPriorityId(row?.original?.id)
      }}
    >
      <Tooltip title={convertToSpaceSeparated(priorityName)} arrow>
        <StyledTableText
          color={TaskPriorityColor[priorityName]}
          id={cell.id}
          sx={{ fontSize: '14px', width: '60px' }}
        >
          {convertToSpaceSeparated(priorityName)}
        </StyledTableText>
      </Tooltip>
      <PopupState variant="popover" popupId="priority-popover">
        {(popupState) => (
          <>
            <Box
              className="btn-assign-provider-action"
              {...bindTrigger(popupState)}
            >
              <IconButton size="large">
                <AddIcon
                  style={{
                    cursor: 'pointer',
                    width: '17px',
                    height: '17px',
                    fill: 'rgb(38, 47, 98)',
                  }}
                />
              </IconButton>
            </Box>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              anchorEl={document.getElementById(cell.id)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={(ev: any): void => {
                popupState.close()
                setHoverAssignPriorityId(-1)
                ev.stopPropagation()
              }}
            >
              <Paper
                sx={{
                  width: '125px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '10px',
                }}
              >
                {userTaskPriority?.map(({ id, name }) => {
                  return (
                    <MenuItem
                      key={id}
                      value={id}
                      sx={{ fontSize: '14px' }}
                      onClick={() => {
                        handleUpdateTask(row?.original?.id, {
                          priorityId: id,
                        })
                          .catch((err: any) => {
                            throw err
                          })
                          .finally(() => popupState.close())
                      }}
                    >
                      {convertToSpaceSeparated(name ?? '')}
                    </MenuItem>
                  )
                })}
              </Paper>
            </Popover>
          </>
        )}
      </PopupState>
    </AssignAction>
  )
}
