import { styled } from '@mui/material'
import { ModalButton } from '@/components/Config/AddModals/components/ModalButton'
import React from 'react'
import { TileTextArea } from '../Charting'
import { ModalLayout } from '../Charting/Editor/IQBar/modals/ModalLayout'
import { useAuth } from '@clerk/nextjs'
import { useMutateUpdatePatient, useQueryGetPatient } from '@/hook'

const ButtonRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  paddingTop: '20px',
  gap: '10px',
})

export const StickyNotesModal = ({
  handleClose,
  patientId,
  getPatient = () => {},
}: StickyNotesModalProps): JSX.Element => {
  const { getToken } = useAuth()
  const { data: patient } = useQueryGetPatient(patientId, getToken)
  const updatePatientMutation = useMutateUpdatePatient(getToken)
  const [notes, setNotes] = React.useState<string>('')

  React.useEffect(() => {
    setNotes(patient?.stickyNote ?? '')
  }, [patient])

  const onSubmit = (): void => {
    const newPatientData = {
      ...patient,
      stickyNote: notes,
    }
    updatePatientMutation
      .mutateAsync({ patientId, patient: newPatientData })
      .then(() => getPatient())
      .catch((err) => {
        throw err
      })
    handleClose()
  }
  return (
    <ModalLayout
      title="Patient Notes"
      handleCancel={handleClose}
      width={'808px'}
    >
      <TileTextArea
        value={notes}
        onChange={(e: any) => setNotes(e.target.value)}
        fullsize
        dataTestId={'sticky-notes-input'}
      />
      <ButtonRow>
        <ModalButton variant="outlined" type="submit" onClick={handleClose}>
          Cancel
        </ModalButton>
        <ModalButton
          variant="contained"
          color="primary"
          type="submit"
          onClick={onSubmit}
        >
          Save
        </ModalButton>
      </ButtonRow>
    </ModalLayout>
  )
}

interface StickyNotesModalProps {
  patientId: number;
  handleClose: () => void;
  getPatient: any;
}
