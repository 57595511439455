import { DocumentsTab } from '@/components/ClinicDocuments'
import React from 'react'
import { type Patient } from '@/types'
import { ModalLayout } from './ModalLayout'

export const PatientDocumentsModal = ({
  patient,
  clinicId,
  handleClose,
  visitId,
}: OrdersProps): JSX.Element => {
  return (
    <ModalLayout
      title="Patient Documents"
      handleCancel={handleClose}
      width="1280px"
    >
      <DocumentsTab
        patient={patient}
        clinicId={clinicId}
        showAllClinicsOption={false}
        showAllPatientDocumentOption
        visitId={visitId}
      />
    </ModalLayout>
  )
}

interface OrdersProps {
  patient: Patient;
  clinicId: number;
  handleClose: () => void;
  visitId: number;
}
