import React from 'react'
import { Stack, Typography, styled } from '@mui/material'
import { SecurityInput } from '@/components/SecurityInput/SecurityInput'
import { type UserPin } from '@/types/UserPin'
import { useAuth } from '@clerk/nextjs'
import { isEmpty } from '@/utility/utils'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { useMutateAddUserPin } from '@/hook/useUserPin'
import { ModalLayout } from '../Charting/Editor/IQBar/modals/ModalLayout'
import { ModalButton } from '../Config'

const StyledLabel = styled(Typography)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '125px',
  fontSize: '14px',
})
const RedText = styled('span')({
  color: 'red',
  fontSize: '12px',
})

export default function EditPinModal({
  setClose,
}: EditPinModalProps): JSX.Element {
  const { getToken } = useAuth()
  const addNewUserPinMutation = useMutateAddUserPin(getToken)
  const [pin, setPin] = React.useState('')
  const [confirmPin, setConfirmPin] = React.useState('')
  const [error, setError] = React.useState('')

  const onConfirmPinChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setConfirmPin(event.target.value)
  }
  const onPinChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPin(event.target.value)
  }
  const onUpdatePin = (): void => {
    if (pin.length < 4) {
      setError('PIN must be at least 4 characters long')
      return
    } else if (pin !== confirmPin) {
      setError('PINs do not match')
      return
    } else {
      setError('')
    }

    const newUserPin: UserPin = {
      newPin: pin,
    }
    addNewUserPinMutation
      .mutateAsync({ user: newUserPin })
      .then(() => {
        setClose()
      })
      .catch((error) => {
        throw error
      })
  }
  return (
    <ModalLayout title="Reset PIN" handleCancel={setClose} width="unset">
      <Stack direction="row" spacing={2}>
        <AdminPanelSettingsIcon style={{ fontSize: 100, color: '#666' }} />
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <StyledLabel>PIN:</StyledLabel>
            <SecurityInput
              value={pin}
              inputProps={{ maxLength: 4 }}
              onChange={onPinChange}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <StyledLabel>Confirm PIN:</StyledLabel>
            <SecurityInput
              value={confirmPin}
              inputProps={{ maxLength: 4 }}
              onChange={onConfirmPinChange}
            />
          </Stack>
          {!isEmpty(error) && (
            <RedText style={{ alignSelf: 'flex-end' }}>{error}</RedText>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        style={{ justifyContent: 'flex-end', marginTop: '15px' }}
      >
        <ModalButton variant="outlined" onClick={setClose}>
          Cancel
        </ModalButton>
        <ModalButton variant="contained" onClick={onUpdatePin}>
          Save
        </ModalButton>
      </Stack>
    </ModalLayout>
  )
}

interface EditPinModalProps {
  setClose: any;
}
