import {
  useClinicStore,
  useQueryGetComplaints,
  useQueryGetVisitTypes,
} from '@/hook'
import { getAccountUserPatientVisitHistory } from '@/services'
import { type AccountUserPatientVisitHistory } from '@/types'
import { useAuth, useUser } from '@clerk/nextjs'
import { keyframes, styled } from '@mui/material'
import React from 'react'
import { RecentChart } from '../RecentChart/RecentChart'

const RecentChartsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  paddingBottom: '80px',
  gap: '10px',
})

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`

const ShimmerCircle = styled('div')({
  width: '60px',
  height: '60px',
  borderRadius: '50%', // make it a circle
  background:
    'linear-gradient(90deg, rgba(250,250,250,1) 25%, rgba(232,232,232,1) 50%, rgba(250,250,250,1) 75%)', // shimmer gradient
  backgroundSize: '468px 100%',
  animation: `${shimmer} 1.5s infinite`,
})

export const RecentCharts = (): JSX.Element => {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const { user } = useUser()
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const [recentCharts, setRecentCharts] =
    React.useState<AccountUserPatientVisitHistory>({})

  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const { data: complaints } = useQueryGetComplaints(getToken)

  React.useEffect(() => {
    reload()
  }, [user, getToken, setRecentCharts, getAccountUserPatientVisitHistory])

  const reload = (): void => {
    setIsLoading(true)
    let id = 0

    if (user?.publicMetadata.account_user_id) {
      id = parseInt((user.publicMetadata.account_user_id as string) ?? '0')
    }
    Promise.resolve(getAccountUserPatientVisitHistory(id, getToken))
      .then((res) => {
        setRecentCharts(res)
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <RecentChartsContainer>
      {isLoading ? (
        <>
          <ShimmerCircle data-testid="shimmerCircle1" />
          <ShimmerCircle data-testid="shimmerCircle2" />
          <ShimmerCircle data-testid="shimmerCircle3" />
        </>
      ) : (
        <>
          {recentCharts?.visit1 && (
            <RecentChart
              reload={reload}
              key={`recentChart1${recentCharts?.visit1?.id ?? 0}`}
              dataTestId="recentChart1"
              visitTypes={visitTypes ?? []}
              complaints={complaints ?? []}
              visit={recentCharts?.visit1 ?? {}}
            />
          )}
          {recentCharts?.visit2 && (
            <RecentChart
              reload={reload}
              key={`recentChart2${recentCharts?.visit2?.id ?? 0}`}
              dataTestId="recentChart2"
              visitTypes={visitTypes ?? []}
              complaints={complaints ?? []}
              visit={recentCharts?.visit2 ?? {}}
            />
          )}
          {recentCharts?.visit3 && (
            <RecentChart
              reload={reload}
              key={`recentChart3${recentCharts?.visit3?.id ?? 0}`}
              dataTestId="recentChart3"
              visitTypes={visitTypes ?? []}
              complaints={complaints ?? []}
              visit={recentCharts?.visit3 ?? {}}
            />
          )}
        </>
      )}
    </RecentChartsContainer>
  )
}
