import React from 'react'
import { SummaryTile } from './SummaryTile'
import { useQueryGetMenstrualDetails, useReferenceDataStore, useVitalsStore } from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { dateOnlyToDate } from '../../../utility/utils'

interface VitalsSummaryProps {
  isVitalsOpen: boolean;
  setIsVitalsOpen: (isOpen: boolean) => void;
  vitalsInFocus: boolean;
  isFemale: boolean;
}

export const VitalsSummary = ({
  isVitalsOpen,
  setIsVitalsOpen,
  vitalsInFocus,
  isFemale
}: VitalsSummaryProps): JSX.Element => {
  const sectionName = 'Vitals'
  const { getToken } = useAuth()
  const { chiefVital: vitals, buildVitalsSummary, outOfRanges } = useVitalsStore()
  const { temperatureMethods, menstrualDetails } = useReferenceDataStore()
  useQueryGetMenstrualDetails(getToken)

  const lmpLabel = vitals?.lastMenstrualPeriod
    ?? menstrualDetails.find((detail) => detail?.id === vitals?.menstruationDetailsId)?.displayValue
    ?? dateOnlyToDate(vitals?.lastMenstrualPeriodDate)?.toLocaleDateString()
    ?? ''

  const vitalsSummary = {
    sectionName,
    sections:
      vitals?.refused === true
        ? [{
            name: '',
            value: [
              { 'Patient Refused': '' }
            ]
          }]
        : buildVitalsSummary(lmpLabel, temperatureMethods ?? [])
  }

  return (
    <SummaryTile
      summary={vitalsSummary}
      isOpen={isVitalsOpen}
      setIsOpen={setIsVitalsOpen}
      title='internalVitals'
      inFocus={vitalsInFocus}
      outOfRanges={outOfRanges}
    />
  )
}
