import { type components } from '../generated/schema'

export const UserRoleNames = [
  'Front Office',
  'Medical Assistant',
  'Clinician (MD, DO, PA, NP)',
  'Office Manager',
  'Office System Admin',
  'UrgentIQ Employee',
  'Admin',
  'Read Only',
  'ElasticSearch',
  'AdvancedMD',
  'DoseSpot',
  'Docuomo',
]

export type UserRole = components['schemas']['Role'];

export type UserRolePermissions = components['schemas']['RoleDTO'];
