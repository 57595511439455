import React from 'react'
import { type WidgetProps } from '@rjsf/utils'
import { TileSelect } from '../../components'

export const CustomSelect = function (props: WidgetProps): any {
  return (
    <TileSelect
      key={props.id}
      value={props.value || []}
      label={props.label}
      options={props.options.enumOptions?.map((option: any) => {
        return { id: option.value, name: option.label }
      })}
      onChange={(event: any) => props.onChange(event.target.value)}
      dataTestId={props.label}
    />
  )
}
