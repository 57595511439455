import { Button, Link, styled, Typography } from '@mui/material'
import { PatientTile } from '../PatientTile'
import React from 'react'
import { type Visit, type VisitType, type Complaint } from '@/types'
import { useAuth } from '@clerk/nextjs'
import { useClinicStore, useQueryGetVisitTypes } from '@/hook'
import { type OnlineAppointment } from '@/types/OnlineAppointment'

const PatientQueueContainer = styled('div')((): any => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#F6F8FD',
  width: '350px',
  height: '100vh',
  padding: '12px 16px',
  gap: 10,
  borderWidth: '0px 1px 1px 0px',
  borderStyle: 'solid',
  borderColor: '#EDEEF3',
  overflowY: 'scroll',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

const PatientQueueHeader = styled(Typography)((): any => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '19px',
  color: '#000000',
  gap: 6,
}))

const QueueHeaderContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const CountCircle = styled('div')(({ theme }): any => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  background: theme.palette.background.paper,
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '12px',
  color: theme.palette.primary.main,
  paddingLeft: '8px',
}))

export const PatientQueue = ({
  visits,
  complaints,
  refresh,
  isNavOpen,
}: PatientQueueProps): JSX.Element => {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)

  const buildPatientQueue = (): JSX.Element[] => {
    return visits?.map((visit) => {
      const visitTypeId =
        'chiefComplaint' in visit
          ? visit.chiefComplaint?.visitTypeId
          : 'visitTypeId' in visit
          ? visit.visitTypeId
          : 'appointmentTypeId' in visit
          ? visit.appointmentTypeId
          : 0
      return (
        <PatientTile
          key={`patient-tile-${visit.id!}`}
          visit={visit}
          visitType={
            visitTypes?.find((visitType: VisitType) => {
              return visitType.id === visitTypeId
            })?.name ?? 'Appointment'
          }
          complaint={
            'chiefComplaint' in visit &&
            visit.chiefComplaint?.complaintList?.[0]
              ? complaints.find(
                  (complaint: Complaint) =>
                    complaint.id === visit.chiefComplaint?.complaintList?.[0]
                )?.name ?? ''
              : ''
          }
          refresh={refresh}
        ></PatientTile>
      )
    })
  }
  return (
    <PatientQueueContainer>
      <PatientQueueHeader>
        <QueueHeaderContent>
          Patient Queue
          <CountCircle>{visits?.length}</CountCircle>
        </QueueHeaderContent>
        <Link href={`/intake?previousNavOpen=${isNavOpen ? 'true' : 'false'}`}>
          <Button
            sx={{ fontWeight: 600, width: 'fit-content' }}
            variant="contained"
            size="medium"
          >
            Add Visit
          </Button>
        </Link>
      </PatientQueueHeader>
      {buildPatientQueue()}
    </PatientQueueContainer>
  )
}

interface PatientQueueProps {
  visits: Array<Visit | OnlineAppointment>;
  complaints: Complaint[];
  refresh: any;
  isNavOpen: boolean;
}
