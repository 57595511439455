import { styled } from '@mui/material'
import React from 'react'

const TitleEndDiv = styled('div')({
  display: 'flex',
  width: '2%',
  alignContent: 'flex-end',
  justifyContent: 'left',
})

export const TitleEnd = ({ children }: TitleEndProps): JSX.Element => {
  return <TitleEndDiv>{children}</TitleEndDiv>
}

interface TitleEndProps {
  children?: JSX.Element | JSX.Element[] | undefined;
}
