import React from 'react'
import {
  InputAdornment,
  OutlinedInput,
  styled,
  Typography,
} from '@mui/material'
import { searchPatient } from '@/services'
import { PatientResultTile } from './PatientResultTile'
import { isEmpty } from '@/utility/utils'
import { useDebounce } from '@/hook'
import { type State, type Patient, type PatientInsurance } from '@/types'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/material/CircularProgress'
import { selectPatient } from '@/helpers'

const PatientSearchContainer = styled('div')(
  ({ isNavOpen }: PatientResultTileContainerProps) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginTop: '80px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    marginLeft: `${isNavOpen ? '362px' : '282px'}`,
    paddingLeft: `${isNavOpen ? '346px' : '308px'}`, // 266
    width: '100%',
  })
)
const PatientSearchEmpty = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
  marginLeft: '36px',
}))

const PatientSearchEmptyText = styled(Typography)((): any => ({
  fontSize: '16px',
  lineHeight: '19.2px',
  color: 'lightgrey',
}))

const StyledOutlineInput = styled(OutlinedInput)(({ theme }) => ({
  width: '812px',
  height: '40px',
  background: theme.palette.background.default,
  borderRadius: '8px',
}))

const DropDownList = styled('div')(({ theme }) => ({
  width: '812px',
  background: theme.palette.background.default,
  borderRadius: '0 8px 8px 8px',
  borderTopRightRadius: '0',
  position: 'absolute',
  marginTop: '36px',
  padding: '8px 8px 8px 8px',
  boxShadow: '0 5px 10px #CCC',
  zIndex: 10,
}))

const OverlayBackground = styled('div')((): any => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
}))

export const PatientSearch = ({
  getToken,
  setValue,
  isNavOpen,
  setPatientId,
  setInsuranceCount,
  setInsuranceIds,
  states,
  setPatientDocuments,
  reset,
  firstLastName: initialFirstLastName = '',
  handleOpenSticky,
  setImportedLastVisitDate,
  setOCRReset,
}: PatientSearchProps): JSX.Element => {
  const [isFetching, setIsFetching] = React.useState<boolean>(false)
  const [patientResults, setPatientResults] = React.useState<Patient[]>([])
  const [isResultsVisible, setIsResultsVisible] =
    React.useState<boolean>(false)
  const [firstLastName, setFirstLastName] = React.useState<string>('')
  const [cursor, setCursor] = React.useState<number>(-1)
  const debouncedFirstLastName = useDebounce<string>(firstLastName, 500)

  React.useEffect(() => {
    setFirstLastName(initialFirstLastName)
  }, [initialFirstLastName])

  const selectPatientHelper = (patient: Patient): void => {
    setOCRReset(true)
    selectPatient(
      patient,
      reset,
      setPatientId,
      setValue,
      states,
      setInsuranceIds,
      getToken,
      setPatientDocuments,
      setIsResultsVisible,
      setInsuranceCount,
      setImportedLastVisitDate
    )
    handleOpenSticky(patient?.stickyNote?.length! > 0)
  }
  const handleKeyPress = (event: any): void => {
    if (event.key === 'Enter' && cursor === -1) {
      searchPatientResult().catch((err) => {
        throw err
      })
    } else if (event.key === 'Enter' && cursor !== -1) {
      selectPatientHelper(patientResults[cursor])
      setIsResultsVisible(false)
      setCursor(-1)
    } else if (event.key === 'Escape') {
      setIsResultsVisible(false)
      setCursor(-1)
    } else if (event.key === 'ArrowUp' && cursor > 0) {
      setCursor(cursor - 1)
    } else if (
      event.key === 'ArrowDown' &&
      cursor < patientResults?.length - 1
    ) {
      setCursor(cursor + 1)
    }
  }
  const onTextChange = (e: any): void => {
    setFirstLastName(e.target.value)
    setIsResultsVisible(false)
    setCursor(-1)
  }
  const handleOnBlur = (): void => {
    setIsResultsVisible(false)
    setCursor(-1)
  }
  const searchPatientResult = async (): Promise<void> => {
    setIsFetching(true)
    const searchPatientPromise = searchPatient(
      debouncedFirstLastName,
      getToken
    )
    Promise.resolve(searchPatientPromise)
      .then((res) => {
        setPatientResults(res.patientDTOList)
        setIsFetching(false)
        setIsResultsVisible(true)
      })
      .catch((error) => {
        throw error
      })
  }

  React.useEffect(() => {
    if (isEmpty(debouncedFirstLastName)) {
      return
    }
    searchPatientResult().catch((err) => {
      throw err
    })
  }, [debouncedFirstLastName])

  return (
    <>
      <PatientSearchContainer isNavOpen={isNavOpen}>
        <StyledOutlineInput
          name="PatientSearchInput"
          tabIndex={-1}
          onKeyDown={handleKeyPress}
          placeholder="Search patient by first/last name or DOB (mm/dd/yyyy)"
          startAdornment={
            <InputAdornment position="start">
              {isFetching ? (
                <CircularProgress color="primary" size={20} />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          }
          value={firstLastName}
          onChange={onTextChange}
        />
        {!isFetching && isResultsVisible && patientResults?.length > 0 && (
          <DropDownList>
            {patientResults
              .filter((patient) => patient !== null)
              .map((patient, i) => {
                return (
                  <PatientResultTile
                    key={patient.id}
                    patient={patient}
                    setValue={setValue}
                    setIsResultsVisible={setIsResultsVisible}
                    setPatientId={setPatientId}
                    setInsuranceCount={setInsuranceCount}
                    setInsuranceIds={setInsuranceIds}
                    states={states}
                    selectPatient={selectPatientHelper}
                    className={cursor === i ? 'active' : ''}
                  />
                )
              })}
          </DropDownList>
        )}
        {!isFetching && isResultsVisible && patientResults?.length === 0 && (
          <DropDownList>
            <PatientSearchEmpty>
              <PatientSearchEmptyText>No Results Found</PatientSearchEmptyText>
            </PatientSearchEmpty>
          </DropDownList>
        )}
      </PatientSearchContainer>
      {isResultsVisible && <OverlayBackground onClick={handleOnBlur} />}
    </>
  )
}

interface PatientSearchProps {
  getToken: any;
  setValue: any;
  isNavOpen: boolean;
  setPatientId: any;
  setInsuranceCount: any;
  setInsuranceIds: any;
  states: State[];
  setPatientDocuments: any;
  reset: any;
  firstLastName?: string;
  handleOpenSticky: any;
  setImportedLastVisitDate: any;
  setOCRReset: any;
  insuranceNames: PatientInsurance[];
}
interface PatientResultTileContainerProps {
  isNavOpen: boolean;
}
