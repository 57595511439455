import { useRouter } from 'next/navigation'
import { type Complaint, type Visit, type VisitType } from '@/types'
import { AvatarImage } from '../AvatarImage'
import { Popover } from '@mui/material'
import React from 'react'
import { PatientTile } from '../PatientTile'
import { type AccountUserPatientVisitUpdate } from '@/types/AccountUserPatientVisitUpdate'
import { putAccountUserPatientVisitHistory } from '@/services'
import { useAuth, useUser } from '@clerk/nextjs'

export const RecentChart = ({
  visit,
  visitTypes,
  complaints,
  dataTestId,
  reload,
}: RecentChartProps): JSX.Element => {
  const { getToken } = useAuth()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  )
  const open = Boolean(anchorEl)
  const { user } = useUser()
  const router = useRouter()
  const updateHistory = (): void => {
    const newAccountUserPatientVisitUpdate: AccountUserPatientVisitUpdate = {}
    if (
      user?.publicMetadata.account_user_id !== undefined &&
      user?.publicMetadata.account_user_id !== null
    ) {
      newAccountUserPatientVisitUpdate.accountUserId = user.publicMetadata
        .account_user_id as number
      newAccountUserPatientVisitUpdate.patientId = visit?.patientId ?? 0
      newAccountUserPatientVisitUpdate.visitId = visit?.id ?? 0
    }
    Promise.resolve(
      putAccountUserPatientVisitHistory(
        user?.publicMetadata.account_user_id as number,
        newAccountUserPatientVisitUpdate,
        getToken
      )
    )
      .catch((err) => {
        throw err
      })
      .finally(() => {
        reload()
      })
    router.push(`/charting/${visit.id ?? 0}?previousNavOpen=true`)
  }
  return (
    <div data-testid={dataTestId}>
      <Popover
        sx={{
          pointerEvents: 'none',
          width: '100%',
        }}
        PaperProps={{
          style: { borderRadius: 12, width: '350px', marginLeft: '10px' },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        <PatientTile
          visit={visit}
          visitType={
            visitTypes?.find((visitType: VisitType) => {
              return visitType.id === visit.chiefComplaint?.visitTypeId
            })?.name ?? ''
          }
          complaint={
            complaints.find((complaint: Complaint) => {
              if (
                visit.chiefComplaint?.complaintList === null ||
                visit.chiefComplaint?.complaintList === undefined
              ) {
                return false
              }
              return complaint.id === visit.chiefComplaint?.complaintList[0]
            })?.name ?? ''
          }
          refresh={() => {}}
          recentChart
        />
      </Popover>
      <AvatarImage
        src={visit.patient?.avatarUrl ?? '/user.png'}
        alt={
          `${visit.patient?.firstName ?? ''} ${
            visit.patient?.lastName ?? ''
          }` ?? 'Patient'
        }
        width={60}
        height={60}
        onClick={() => {
          updateHistory()
        }}
        onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
        disableFullImage
      />
    </div>
  )
}

interface RecentChartProps {
  visit: Visit;
  visitTypes: VisitType[];
  complaints: Complaint[];
  dataTestId: string;
  reload: () => void;
}
