import { styled } from '@mui/material'
import React from 'react'

const RowContainer = styled('div')(({ theme }): any => ({
  display: 'flex',
  width: '100%',
  padding: '15px',
  minHeight: '80px',
  backgroundColor: theme.palette.white,
  borderRadius: '10px',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'relative',
}))

export const ManagementRow = ({
  children,
}: ManagementRowProps): JSX.Element => {
  return <RowContainer>{children}</RowContainer>
}

interface ManagementRowProps {
  children: any;
}
