import React from 'react'
import { styled } from '@mui/system'
import {
  Dialog,
  IconButton,
  Popover,
  SvgIcon,
  Typography,
} from '@mui/material'
import MicNoneIcon from '@mui/icons-material/MicNone'
import { SignChartPopUp } from './SignChartPopUp'
import { type VisitLock } from '@/types/VisitLock'
import { differenceInMinutes } from 'date-fns'
import { useRouter } from 'next/navigation'
import { isEmpty } from '@/utility/utils'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import { type Task } from '@/types'
import { CreateTaskModal } from '@/components/Task/CreateTaskModal'
import { useUser } from '@clerk/nextjs'

const SideBarContainer = styled('div')(
  ({ isOpen, isLoading }: SideBarContainerProps): any => ({
    display: 'flex',
    alignItems: 'flex-start',
    alignContent: 'center',
    isolation: 'isolate',
    position: 'fixed',
    width: '45px',
    height: '210px',
    background: '#1E2342',
    borderRadius: '10px 0px 0px 10px',
    bottom: 70,
    right: 0,
    flexDirection: 'column',
    paddingLeft: '3px',
    paddingTop: '6px',
    transition: 'margin-right 0.7s ease',
    ...(isOpen && { marginRight: '337px' }),
    ...(isLoading && {
      animation: 'shimmer 1.5s infinite linear',
      background:
        'linear-gradient(to right, #1E2342 25%, #2a2d4a 50%, #1E2342 75%)',
      backgroundSize: '200% 100%',
    }),
  })
)

const Divider = styled('div')(({ theme }): any => ({
  width: '30px',
  height: '2px',
  background: '#3D487D',
  marginLeft: '6px',
}))

const TimeBox = styled(Typography)(({ theme }): any => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  alignContent: 'center',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '18px',
  /* identical to box height */
  color: '#FFFFFF',
  marginLeft: '4px',
  marginTop: '10px',
}))

const PopOverText = styled(Typography)(({ theme }): any => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  lineHht: '16px',
  /* identical to box height, or 133% */
  color: '#FFFFFF',
  height: '22px',
  /* Yankees Blue/Shade 2 */
  background: '#2E3353',
  borderRadius: '4px',
  padding: '4px 8px 4px 8px',
}))

export const SideBar = ({
  visitId,
  patientId,
  providerId,
  lockVisit,
  dischargePatient,
  dischargeTime = null,
  startTime,
  isLocked,
  isIQPanelOpen,
  setIsIQPanelOpen,
  isVoiceTranscriptionOpen,
  setIsVoiceTranscriptionOpen,
  isLockedButtonClicked,
  isDischargeButtonClicked,
  isLoading,
  isEmCodeEmpty,
  hasDX,
  needsLMP,
}: SideBarProps): JSX.Element => {
  const router = useRouter()
  const [isOpenLockScreen, setIsOpenLockScreen] =
    React.useState<boolean>(false)
  const [appointmentTime, setAppointmentTime] = React.useState<string>('0')
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [currentTask, setCurrentTask] = React.useState<Task | null>(null)
  const [createTaskOpen, setCreateTaskOpen] = React.useState<boolean>(false)
  const [name, setName] = React.useState<string>('')
  const { user } = useUser()
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    // @ts-expect-error
    setName(event.currentTarget.name)
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setVisitTime()
    }, 1000)
    return () => clearInterval(intervalId)
  }, [startTime, dischargeTime])

  React.useEffect(() => {
    setVisitTime()
  }, [])

  const open = Boolean(anchorEl)
  const setVisitTime = (): void => {
    if (startTime !== null) {
      const time = new Date(startTime)
      const now = new Date(Date.now())
      /* prettier-ignore */
      const endTime =
        /* prettier-ignore */
        !isEmpty(dischargeTime)
          /* prettier-ignore */
          ? new Date(dischargeTime ?? '')
          /* prettier-ignore */
          : new Date(
            /* prettier-ignore */
            Date.UTC(
              /* prettier-ignore */
              now.getUTCFullYear(),
              /* prettier-ignore */
              now.getUTCMonth(),
              /* prettier-ignore */
              now.getUTCDate(),
              /* prettier-ignore */
              now.getUTCHours(),
              /* prettier-ignore */
              now.getUTCMinutes()
              /* prettier-ignore */
            )
            /* prettier-ignore */
          )
      const minutesDiff = differenceInMinutes(endTime, time)
      const minutesString = minutesDiff
      setAppointmentTime(`${minutesString}`)
    }
  }

  const handleSignChart = (pin: string): boolean => {
    const newVisitLock: VisitLock = {
      visitId,
      accountUserPin: pin,
    }
    return lockVisit(newVisitLock)
  }
  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PopOverText>{name ?? 'Time Since Appointment Start'}</PopOverText>
      </Popover>
      <Dialog
        open={isOpenLockScreen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: '12px' }}
      >
        <SignChartPopUp
          handleSignChart={handleSignChart}
          setClose={setIsOpenLockScreen}
          isLockedButtonClicked={isLockedButtonClicked}
          isLocked={isLocked}
          isEmCodeEmpty={isEmCodeEmpty}
          hasDX={hasDX}
          needsLMP={needsLMP}
        />
      </Dialog>
      <SideBarContainer
        isOpen={isIQPanelOpen || isVoiceTranscriptionOpen}
        isLoading={isLoading}
      >
        {!isLoading && (
          <>
            <IconButton
              name={'IQ Panel'}
              disabled
              sx={{ marginTop: '-8px' }}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={() => {
                if (process.env.SHOW_IQ_PANEL) {
                  setIsIQPanelOpen(!isIQPanelOpen)
                  setIsVoiceTranscriptionOpen(false)
                }
              }}
              size="large"
            >
              <SvgIcon sx={{ color: 'white' }}>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.6666 3.33331H4.99992C4.55789 3.33331 4.13397 3.47379 3.82141 3.72384C3.50885 3.97389 3.33325 4.31302 3.33325 4.66665V15.3333C3.33325 15.6869 3.50885 16.0261 3.82141 16.2761C4.13397 16.5262 4.55789 16.6666 4.99992 16.6666H14.9999C15.4419 16.6666 15.8659 16.5262 16.1784 16.2761C16.491 16.0261 16.6666 15.6869 16.6666 15.3333L16.6666 5.33331C16.6666 4.22874 15.7712 3.33331 14.6666 3.33331H11.6666Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.7302 12.8785C6.7302 13.0166 6.61827 13.1285 6.4802 13.1285H5.4502C5.31212 13.1285 5.2002 13.0166 5.2002 12.8785V6.822C5.2002 6.68393 5.31212 6.572 5.4502 6.572H6.4802C6.61827 6.572 6.7302 6.68393 6.7302 6.822V12.8785Z"
                    fill="white"
                  />
                  <path
                    d="M9.17862 9.848C9.17862 10.175 9.22062 10.469 9.30462 10.73C9.38862 10.988 9.50862 11.207 9.66462 11.387C9.82362 11.567 10.0171 11.705 10.2451 11.801C10.4761 11.894 10.7386 11.9405 11.0326 11.9405C11.3236 11.9405 11.5831 11.894 11.8111 11.801C12.0391 11.705 12.2311 11.567 12.3871 11.387C12.5461 11.207 12.6676 10.988 12.7516 10.73C12.8356 10.469 12.8776 10.175 12.8776 9.848C12.8776 9.524 12.8356 9.233 12.7516 8.975C12.6676 8.714 12.5461 8.4935 12.3871 8.3135C12.2311 8.1335 12.0391 7.9955 11.8111 7.8995C11.5831 7.8035 11.3236 7.7555 11.0326 7.7555C10.7386 7.7555 10.4761 7.8035 10.2451 7.8995C10.0171 7.9955 9.82362 8.1335 9.66462 8.3135C9.50862 8.4935 9.38862 8.714 9.30462 8.975C9.22062 9.233 9.17862 9.524 9.17862 9.848ZM14.4201 13.9382C14.567 14.0986 14.4532 14.357 14.2357 14.357H13.5526C13.3726 14.357 13.2106 14.333 13.0666 14.285C12.9256 14.24 12.7936 14.1545 12.6706 14.0285L11.9539 13.2246C11.8935 13.157 11.8014 13.128 11.7121 13.1438C11.4985 13.1816 11.272 13.2005 11.0326 13.2005C10.5226 13.2005 10.0576 13.115 9.63762 12.944C9.21762 12.773 8.85762 12.5375 8.55762 12.2375C8.26062 11.9345 8.02962 11.579 7.86462 11.171C7.70262 10.763 7.62162 10.322 7.62162 9.848C7.62162 9.374 7.70262 8.933 7.86462 8.525C8.02962 8.117 8.26062 7.763 8.55762 7.463C8.85762 7.163 9.21762 6.9275 9.63762 6.7565C10.0576 6.5855 10.5226 6.5 11.0326 6.5C11.5426 6.5 12.0061 6.587 12.4231 6.761C12.8431 6.932 13.2016 7.1675 13.4986 7.4675C13.7956 7.7675 14.0251 8.1215 14.1871 8.5295C14.3521 8.9375 14.4346 9.377 14.4346 9.848C14.4346 10.13 14.4046 10.4015 14.3446 10.6625C14.2876 10.9205 14.2036 11.1635 14.0926 11.3915C13.9846 11.6195 13.8496 11.831 13.6876 12.026C13.5872 12.1491 13.4778 12.2633 13.3595 12.3685C13.2484 12.4673 13.2307 12.6392 13.3311 12.7488L14.4201 13.9382Z"
                    fill="white"
                  />
                </svg>
              </SvgIcon>
            </IconButton>
            <IconButton
              name={'IQ Voice'}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={() => {
                console.log('iq voice')
                setIsVoiceTranscriptionOpen(!isVoiceTranscriptionOpen)
                setIsIQPanelOpen(false)
              }}
              disabled={process.env.DISABLE_IQ_VOICE === 'true'}
              size="large"
            >
              <SvgIcon sx={{ color: 'white' }}>
                <MicNoneIcon fontSize={'large'} />
              </SvgIcon>
            </IconButton>
            <IconButton
              sx={{ marginTop: '-8px' }}
              name={'Discharge'}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              disabled={dischargeTime !== null || isDischargeButtonClicked}
              onClick={() => {
                dischargePatient()
                Promise.resolve(router.push('/')).catch((e) => {
                  console.error(e)
                })
              }}
              size="large"
            >
              <SvgIcon sx={{ color: 'white' }}>
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.25 17.5V15.8333C16.25 14.9493 15.8988 14.1014 15.2737 13.4763C14.6486 12.8512 13.8007 12.5 12.9167 12.5H7.08333C6.19928 12.5 5.35143 12.8512 4.72631 13.4763C4.10119 14.1014 3.75 14.9493 3.75 15.8333V17.5"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.0001 9.16667C11.841 9.16667 13.3334 7.67428 13.3334 5.83333C13.3334 3.99238 11.841 2.5 10.0001 2.5C8.15913 2.5 6.66675 3.99238 6.66675 5.83333C6.66675 7.67428 8.15913 9.16667 10.0001 9.16667Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15 9.16669H19.1667"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.5 7.5L19.1667 9.16667L17.5 10.8333"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SvgIcon>
            </IconButton>
            {process.env.SHOW_TASK_MANAGEMENT_BUTTON === 'true' && (
              <IconButton
                sx={{ marginTop: '-8px' }}
                name={'Create Task'}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                disabled={dischargeTime !== null}
                onClick={() => {
                  setCurrentTask({
                    visitId,
                    patientId,
                    accountUserId: providerId,
                  })
                  setCreateTaskOpen(true)
                }}
                size="large"
              >
                <AssignmentOutlinedIcon
                  style={{
                    cursor: 'pointer',
                    width: '24px',
                    height: '24px',
                    fill: 'white',
                  }}
                />
              </IconButton>
            )}
            <IconButton
              name={isLocked ? 'Unlock' : 'Lock'}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              sx={{ marginTop: '-8px' }}
              onClick={() => {
                setIsOpenLockScreen(!isOpenLockScreen)
              }}
              size="large"
            >
              <SvgIcon sx={{ color: 'white' }} data-testid="lockChartButton">
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.8333 9.16669H4.16667C3.24619 9.16669 2.5 9.91288 2.5 10.8334V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V10.8334C17.5 9.91288 16.7538 9.16669 15.8333 9.16669Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.83325 9.16667V5.83334C5.83222 4.80005 6.21515 3.80323 6.90773 3.03639C7.60031 2.26956 8.55311 1.78742 9.58117 1.68358C10.6092 1.57974 11.6392 1.86159 12.4711 2.47443C13.3031 3.08728 13.8776 3.98738 14.0833 5.00001"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </SvgIcon>
            </IconButton>
            <Divider />
            <TimeBox
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              {appointmentTime}
            </TimeBox>
            <Dialog
              open={createTaskOpen}
              sx={{ borderRadius: '12px' }}
              maxWidth={false}
            >
              <CreateTaskModal
                currentTask={currentTask?.id ?? -1}
                setClose={() => setCreateTaskOpen(false)}
                defaultTask={{
                  patientId,
                  visitId,
                  statusId: 1,
                  createdById:
                    (user?.publicMetadata.account_user_id as number) ?? -1,
                }}
              />
            </Dialog>
          </>
        )}
      </SideBarContainer>
    </>
  )
}
interface SideBarProps {
  visitId: number;
  lockVisit: (visitLock: VisitLock) => any;
  patientId?: number | null;
  providerId?: number | null;
  isLocked: boolean;
  dischargePatient: () => void;
  startTime: string | null;
  dischargeTime: string | null;
  isIQPanelOpen: boolean;
  setIsIQPanelOpen: any;
  isVoiceTranscriptionOpen: boolean;
  setIsVoiceTranscriptionOpen: any;
  isLockedButtonClicked: boolean;
  isDischargeButtonClicked: boolean;
  isLoading: boolean;
  isEmCodeEmpty: boolean;
  hasDX: boolean;
  needsLMP: boolean;
}

interface SideBarContainerProps {
  isOpen: boolean;
  isLoading: boolean;
}
