import {
  styled,
  Typography,
  IconButton,
  Button,
  Box,
  Stack,
  Paper,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import React, { useState, useEffect } from 'react'

const VoiceTranscriptionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  gap: '16px',
})

const HeaderRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
})
const HeaderTile = styled(Typography)({
  size: '16px',
  weight: 'semibold',
  color: 'black',
})
const TranscriptButton = styled(Box)({
  position: 'fixed',
  bottom: '20px',
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.1)',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '305px',
  height: '80px',
  justifyContent: 'center',
})

const TextTileContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  padding: '10px',
  background: '#E2F2E2',
}))
const CrossBtn = styled(IconButton)({
  marginLeft: 'auto',
  padding: '0px',
})

let microphone: any
if (typeof window !== 'undefined' && !navigator.userAgent.includes('Firefox')) {
  const SpeechRecognition =
    (window as any).SpeechRecognition ||
    (window as any).webkitSpeechRecognition
  microphone = new SpeechRecognition()
}
if (microphone) {
  microphone.continuous = true
  microphone.interimResults = true
  microphone.lang = 'en-US'
}

interface TextTileProps {
  content: string;
  onClick?: any;
}
const TextTile: React.FC<TextTileProps> = ({ content, onClick }) => {
  return (
    <TextTileContainer>
      <Typography sx={{ fontSize: '14px' }}>{content}</Typography>
      <CrossBtn onClick={onClick}>
        <ClearIcon />
      </CrossBtn>
    </TextTileContainer>
  )
}
export const VoiceTranscription = ({
  handleClose,
}: VoiceTranscriptionProps): JSX.Element => {
  const [isRecording, setIsRecording] = useState(false)
  const [note, setNote] = useState<string>('')
  const [notesStore, setnotesStore] = useState<string[]>([])

  useEffect(() => {
    startRecordController()
  }, [isRecording])
  const startRecordController = (): void => {
    if (isRecording) {
      microphone.start()
      microphone.onend = () => {
        microphone.start()
      }
    } else {
      microphone.stop()
      microphone.onend = () => {
        console.log('Stopped microphone on Click')
      }
    }

    microphone.onresult = (event: any) => {
      const recordingResult = Array.from(event.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join('')
      console.log('result', recordingResult)
      setNote(recordingResult)
      microphone.onerror = (event: any) => {
        console.log(event.error)
      }
    }
  }

  const handleClickTranscriptBtn = (): void => {
    if (isRecording) {
      setnotesStore([...notesStore, note])
      setNote('')
    }
    setIsRecording(!isRecording)
  }
  return (
    <VoiceTranscriptionContainer>
      <HeaderRow>
        <HeaderTile>IQ Voice</HeaderTile>
        <IconButton onClick={handleClose} size="large">
          <ClearIcon />
        </IconButton>
      </HeaderRow>
      <Box>
        <Stack spacing={2}>
          {note && <TextTile content={note}></TextTile>}
          {[...notesStore].reverse().map((note, index) => (
            <TextTile content={note} key={index} />
          ))}
        </Stack>
      </Box>
      <TranscriptButton>
        <Button variant="contained" onClick={handleClickTranscriptBtn}>
          {' '}
          {isRecording ? 'Stop' : 'Start Transcript'}{' '}
        </Button>
      </TranscriptButton>
    </VoiceTranscriptionContainer>
  )
}

interface VoiceTranscriptionProps {
  handleClose: any;
}
