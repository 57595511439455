import { styled } from '@mui/material'
import React from 'react'

const DetailColumnLargeDiv = styled('div')({
  display: 'flex',
  width: '50%',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '2px',
})

export const DetailColumnLarge = ({
  children,
}: DetailColumnLargeProps): JSX.Element => {
  return <DetailColumnLargeDiv>{children}</DetailColumnLargeDiv>
}

interface DetailColumnLargeProps {
  children: JSX.Element | JSX.Element[] | any[] | undefined;
}
