import React from 'react'
import { styled, Typography, Button, Select, Badge } from '@mui/material'
import Image from 'next/image'
import { DatePicker } from '@mui/x-date-pickers'

export const MainContainter = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
})

export const StyledSelect = styled(Select)({
  flex: 1,
  marginRight: '8px',
})

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  flex: 1,
  marginRight: '8px',
}))

export const StyledEditButton = styled(Button)({
  fontSize: '12px',
  fontWeight: 500,
  borderRadius: '25px',
  padding: '6px 12px',
  lineHeight: '20px',
  height: '30px',
  width: 'fit-content',
  textTransform: 'capitalize',
})

export const Avatar = styled(Image)({
  borderRadius: '50%',
  width: '57px',
  height: '57px',
  marginRight: '8px',
})

export const Name = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '19px',
})

export const BaseInfo = styled('div')({
  fontSize: '14px',
  lineHeight: '17px',
  fontWeight: 'normal !important',
  marginTop: '-6px',
})

export const ContactItem = styled('div')({
  marginRight: '12px',
  display: 'flex',
})

export const ContactIcon = styled('div')({
  marginRight: '4px',
})

export const ContactText = styled('div')({
  fontSize: '14px',
  lineHeight: '17px',
})

export const TabsContainer = styled('div')({
  flex: '1',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
})

export const TabNav = styled('div')({
  height: '36px',
  borderBottom: '1px solid #e6e6e6',
  display: 'flex',
})

export const TabItem = ({
  onClick,
  isSelected,
  children,
}: {
  onClick: React.MouseEventHandler;
  isSelected: boolean;
  children: JSX.Element;
}): JSX.Element => {
  const Tab = styled('div')({
    display: 'flex',
    alignItems: 'center',
    color: isSelected ? '#0543B0' : '#2E3353',
    borderBottom: !isSelected ? 'none' : '1px solid #0543B0',
    marginRight: '27px',
    cursor: 'pointer',
    userSelect: 'none',
  })
  return <Tab onClick={onClick}>{children}</Tab>
}

export const CenterPlace = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
})

export const TabText = styled('div')({
  fontSize: '14px',
  lineHeight: '20px',
})

export const TabContentContainer = styled('div')({
  width: '100%',
  height: 'max-content',
  padding: '30px 0px 0px 0px',
  display: 'flex',
  gap: '23px',
})

export const Card = styled('div')({
  flex: '1',
  backgroundColor: '#f6f8fd',
  borderRadius: '12px',
  position: 'relative',
  padding: '20px',
  marginRight: '40px',
  marginTop: '40px',
})

export const CardIcon = styled('div')({
  position: 'absolute',
  width: '60px',
  height: '60px',
  background: '#f6f8fd',
  border: '3px solid #ffffff',
  borderRadius: '50%',
  right: '48px',
  top: '-30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export const CardTitle = styled('div')({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#1e2342',
  marginBottom: '16px',
  alignItems: 'center',
  display: 'flex',
  fontWeight: 500,
})

export const CardContent = ({
  columnCount,
  children,
}: {
  columnCount: number;
  children: JSX.Element;
}): JSX.Element => {
  const Content = styled('div')({
    display: 'grid',
    gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
    color: 'black',
  })
  return <Content>{children}</Content>
}

export const GridItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '20px',
})

export const GridLabel = styled('div')({
  fontSize: '14px',
  lineHeight: '17px',
  marginBottom: '4px',
  color: '#717899',
})

export const GridContent = styled('div')({
  fontSize: '14px',
  lineHeight: '17px',
  marginBottom: '4px',
})

export const StyledTab = styled('div')(({ theme }) => ({
  display: 'flex',
  textTransform: 'none',
  fontSize: '14px',
  lineHeight: '20px',
  marginRight: theme.spacing(1),
  color: 'black',
  borderRadius: '6px 6px 0 0',
  border: '1px solid #CFD8FD',
  borderTop: '2px solid rgb(13, 71, 173)',
  borderBottom: '0px',
  padding: '12px 12px 12px 12px',
  fontWeight: '600',
  width: 'fit-content',
  marginBottom: '20px',
  gap: '8px',
  cursor: 'pointer',
  boxSizing: 'border-box',
  '&.selected': {
    color: theme.palette.primary.main,
    backgroundColor: '#CFD8FD',
  },
}))

export const StyledTableText = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  color: '#454545',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const UploadButton = styled(Button)({
  width: '250px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 600,
})

export const ModalText = styled('h3')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
})

export const HeaderItem = styled('div')({
  borderLeft: '1px solid #DAE3F3',
})

export const HeaderBadge = styled('div')({
  borderRadius: '50%',
  background: 'rgba(5, 67, 176, 0.2)',
  color: '#252D63',
  marginLeft: '4px',
  width: '16px',
  height: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '12px',
})

export const ContactSection = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  borderRight: '1px solid #DAE3F3',
  padding: '0 12px',
  alignItems: 'left',
  height: '100%',
  justifyContent: 'center',
})

export const HeaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px 0px 8px 16px',
})

export const NameAvatarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '8px',
  marginTop: '-6px',
})

export const WarningTooltip = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  width: '100%',
  '& > div:first-child': {
    fontWeight: 'bold',
    fontSize: '14px',
  },
})

export const StyledBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    top: 4,
    right: 5,
    width: 16,
    height: 16,
    minWidth: 16,
    fontSize: '0.6rem',
    padding: 0,
  },
})

export const NameContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  marginTop: '-6px',
})
