import React from 'react'
import { styled } from '@mui/material'
import {
  PatientAllergies,
  PatientImmunizations,
  PatientMedicalConditions,
  PatientSurgicalHistory,
  PatientPreventativeMedicine,
  PatientSocialHistory,
  PatientFamilyHistory
} from './Tiles'

const HistoryContainer = styled('div')({
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  gap: '30px',
})

export const PatientProfileHistory = ({
  patient,
}: {
  patient: any;
}): JSX.Element => {
  return (
    <HistoryContainer>
      <PatientAllergies patient={patient} />
      <PatientImmunizations patient={patient} />
      <PatientMedicalConditions patient={patient} />
      <PatientSurgicalHistory patient={patient} />
      <PatientSocialHistory patient={patient} />
      <PatientFamilyHistory patient={patient} />
      <PatientPreventativeMedicine patient={patient} />
    </HistoryContainer>
  )
}
