import { styled, useTheme } from '@mui/material'

import { type Theme } from '@mui/system'
import React from 'react'

const LeftInnerNavContainer = styled('div')(
  ({ isNavOpen, theme, isCharting }: LeftInnerNavContainerProps) => ({
    display: 'flex',
    backgroundColor: isCharting ? '#1E2342' : theme.palette.background.default,
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'fixed',
    height: '100%',
    zIndex: 9,
    paddingTop: '60px',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    /* prettier-ignore */
    paddingLeft:
      /* prettier-ignore */
      isNavOpen
        /* prettier-ignore */
        ? isCharting ? '96px' : '80px'
        /* prettier-ignore */
        : isCharting ? '16px' : '0px',
    ...(isCharting && { paddingRight: '16px' }),
    ...(isCharting && { gap: '10px' }),
    ...(isCharting && { overflowY: 'auto', msOverflowY: 'auto' }),
    paddingBottom: isCharting ? '80px' : '0px',
  })
)
export const LeftInnerNav = ({
  isNavOpen,
  children,
  isCharting = false,
}: LeftInnerNavProps): JSX.Element => {
  const theme = useTheme()
  return (
    <LeftInnerNavContainer
      isNavOpen={isNavOpen}
      theme={theme}
      isCharting={isCharting}
    >
      <>{children}</>
    </LeftInnerNavContainer>
  )
}

interface LeftInnerNavProps {
  isNavOpen: boolean;
  isCharting?: boolean;
  children: React.ReactNode;
}

interface LeftInnerNavContainerProps {
  isNavOpen: boolean;
  theme: Theme;
  isCharting: boolean;
}
