import { styled, TextareaAutosize } from '@mui/material'
import React from 'react'
import { ModalLayout } from './ModalLayout'
import { ModalButtonRow } from '@/components/Config/AddModals/layouts/ModalButtonRow'
import { ModalButton } from '@/components/Config'

const StyledTextArea = styled(TextareaAutosize)(({ theme }) => ({
  fontFamily: 'Lato',
  width: '988px',
  maxWidth: '988px',
  minWidth: '988px',
  color: '#222222',
  fontWeight: '500',
  fontSize: '16px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '6px',
  border: `1px solid ${theme.palette.divider}`,
  paddingLeft: '10px',
  '&:hover': {
    borderColor: 'black',
  },
  '&:focus': {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    outline: 'none',
  },
}))

export const GlobalNote = ({
  saveNote,
  note,
  handleClose,
}: GlobalNoteProps): JSX.Element => {
  const [text, setText] = React.useState(note)
  return (
    <ModalLayout title="Addendum" handleCancel={handleClose} width="1032px">
      <StyledTextArea
        value={text}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => {
          setText(e.target.value)
        }}
        maxRows={20}
        minRows={20}
      />
      <ModalButtonRow>
        <ModalButton
          variant="contained"
          onClick={() => {
            saveNote(text)
            handleClose()
          }}
        >
          Save
        </ModalButton>
      </ModalButtonRow>
    </ModalLayout>
  )
}

interface GlobalNoteProps {
  saveNote: (note: string) => void;
  note: string;
  handleClose: () => void;
}
