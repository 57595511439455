import {
  Button,
  ButtonGroup,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  type Theme,
  Typography,
  useTheme,
  Box,
  CircularProgress,
  Stack,
  Checkbox,
} from '@mui/material'
import React, { type RefObject } from 'react'
import {
  type Control,
  Controller,
  type FieldValues,
  type UseFormRegister,
} from 'react-hook-form'
import { Document, Page, pdfjs } from 'react-pdf'
import { FileUpload, FileUploadSelect } from '../../../FileUpload'
import { SectionLabel, TileTitleBox } from '@/components'
import { PastVisits } from './PastVisits'
import {
  type Visit,
  type AccountUser,
  type Order,
  type ProcedureType,
  type VisitType,
} from '@/types'
import {
  FileIcon,
  defaultStyles,
  type DefaultExtensionType,
} from 'react-file-icon'
import Image from 'next/image'
import { format } from 'date-fns'
import { useAuth } from '@clerk/nextjs'
import { uploadFile } from '@/helpers'
import { getOCRPatientIdValues } from '@/services/OCRService'
import { type OcrPatientId } from '@/types/OCRPatientId'
import { deleteDocument } from '@/services'
import { PhotoIdFieldSelectedContext } from '@/context'

const PatientIdentificationContainer = styled('div')(({ theme }): any => ({
  display: 'flex',
  minHeight: '576px',
  width: '812px',
  padding: '24px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  gap: 8,
  justifyContent: 'flex-start',
  color: 'black',
  border: '1px solid #CDD0E3',
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
  scrollMarginTop: '100px',
}))
const PatientIdentificationRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: 20,
})
const PatientIdentificationColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 30,
  alignItems: 'flex-start',
})
const StyledFormControlLabel = styled(FormControlLabel)(({ theme }): any => ({
  fontSize: '16px',
  lineHeight: '18.75px',
  color: 'black',
}))

const StyledImg = styled(Image)({
  borderRadius: '14px',
})

const DocumentSelectButton = styled(Button)(
  ({ buttonIndex, selectedIndex, theme }: DocumentSelectButtonProps): any => ({
    width: '152px',
    height: '32px',
    borderRadius: '8px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '0px',
    borderWidth: '1px',
    backgroundColor:
      buttonIndex === selectedIndex ? theme.palette.primary.main : 'white',
    color: buttonIndex === selectedIndex ? 'white' : '#565656',
    '&:hover': {
      backgroundColor:
        buttonIndex === selectedIndex
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
    },
  })
)
const PastVisitsHeader = styled('div')({
  display: 'flex',
  gap: 6,
  alignContent: 'center',
})
const PastVisitCountsCircle = styled('div')({
  display: 'flex',
  background: '#0543B0',
  height: '20px',
  width: '20px',
  borderRadius: '50%',
  justifyContent: 'center',
  paddingTop: '2px',
  alignItems: 'center',
})

const UploadWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  height: '340px',
})
export const PatientIdentification = ({
  innerRef,
  control,
  register,
  onFocus,
  idFile,
  setIdFile,
  intakeDocument,
  setIntakeDocument,
  profilePictureFile,
  setProfilePictureFile,
  visits,
  users,
  user,
  visitTypes,
  importedPatientLastVisitDate,
  setValue,
  checkForDuplicatePatient,
}: PatientIdentificationProps): JSX.Element => {
  const theme = useTheme()
  const { getToken } = useAuth()
  const [openID, setOpenID] = React.useState(false)
  const [documentUploadIndex, setDocumentUploadIndex] =
    React.useState<number>(0)
  const handleCloseID = (): void => setOpenID(false)
  const [openIntake, setOpenIntake] = React.useState(false)
  const { photoIdFieldSelected, setPhotoIdFieldSelected } = React.useContext(
    PhotoIdFieldSelectedContext
  )
  const handleCloseIntake = (): void => setOpenIntake(false)
  const [parentId, setParentId] = React.useState<boolean>(false)
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${JSON.stringify(
    pdfjs.version
  )}/pdf.worker.js`

  React.useEffect(() => {
    processOCR()
    handleCloseID()
  }, [idFile])
  React.useEffect(() => {
    handleCloseIntake()
  }, [intakeDocument, profilePictureFile])

  const processOCR = (): void => {
    if (idFile !== null && idFile !== undefined) {
      Promise.resolve(
        uploadFile(
          idFile,
          undefined,
          2,
          undefined,
          undefined,
          `id${idFile.type.split('/')[1]}`,
          undefined,
          getToken
        )
      )
        .then((res) => {
          Promise.resolve(getOCRPatientIdValues(res.id as number, getToken))
            .then((res1) => {
              if (res1) {
                const result: OcrPatientId = res1
                interface FieldMapType {
                  firstName: string;
                  lastName: string;
                  dateOfBirth: string;
                  sex: string;
                  street1: string;
                  street2: string;
                  state: string;
                  zip: string;
                  city: string;
                }
                const fieldMap: FieldMapType = {
                  firstName: 'firstName',
                  lastName: 'lastName',
                  dateOfBirth: 'birthDate',
                  sex: 'sex',
                  street1: 'demographicsstreet1',
                  street2: 'demographicsstreet2',
                  state: 'demographicsstate',
                  zip: 'demographicszip',
                  city: 'demographicscity',
                }
                const isKeyOfFieldMap = (
                  key: string
                ): key is keyof FieldMapType => {
                  return key in fieldMap
                }
                const processOcrResults = (ocrResult: any): any => {
                  Object.entries(ocrResult).forEach(([key, value]) => {
                    if (value !== null && value !== undefined) {
                      if (isKeyOfFieldMap(key)) {
                        if (key === 'dateOfBirth') {
                          if (parentId) {
                            return
                          }
                          setValue(fieldMap[key], new Date(value as string))
                        } else if (key === 'firstName' || key === 'lastName') {
                          if (parentId) {
                            return
                          }
                          setValue(fieldMap[key], value)
                        } else if (key === 'sex') {
                          if (parentId) {
                            return
                          }
                          if (value === 'M') {
                            setValue(fieldMap[key], 'Male')
                          } else if (value === 'F') {
                            setValue(fieldMap[key], 'Female')
                          } else {
                            setValue(fieldMap[key], 'Other')
                          }
                        } else {
                          setValue(fieldMap[key], value)
                        }
                        setPhotoIdFieldSelected({
                          ...photoIdFieldSelected,
                          [key]: true,
                        })
                      } else {
                        setValue('', value)
                      }
                    }
                  })
                }
                processOcrResults(result)
              }
            })
            .finally(() => {
              deleteDocument(res?.id as number, getToken).catch((err) => {
                throw err
              })
              checkForDuplicatePatient()
            })
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
  return (
    <PatientIdentificationContainer ref={innerRef} onMouseOver={onFocus}>
      <FileUpload open={openID} onClose={handleCloseID} setFiles={setIdFile} />
      <FileUpload
        open={openIntake && documentUploadIndex === 1}
        onClose={handleCloseIntake}
        setFiles={setIntakeDocument}
        multiple
      />
      <FileUpload
        open={openIntake && documentUploadIndex !== 1}
        onClose={handleCloseIntake}
        setFiles={setProfilePictureFile}
      />
      <TileTitleBox tileTitle="Patient Identification" />
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <DocumentSelectButton
          buttonIndex={0}
          selectedIndex={documentUploadIndex}
          theme={theme}
          onClick={() => setDocumentUploadIndex(0)}
        >
          Identity Document
        </DocumentSelectButton>
        <DocumentSelectButton
          buttonIndex={1}
          selectedIndex={documentUploadIndex}
          theme={theme}
          onClick={() => setDocumentUploadIndex(1)}
        >
          Intake Documents
        </DocumentSelectButton>
        <DocumentSelectButton
          buttonIndex={2}
          selectedIndex={documentUploadIndex}
          theme={theme}
          sx={{ width: '100px' }}
          onClick={() => setDocumentUploadIndex(2)}
        >
          Picture
        </DocumentSelectButton>
      </ButtonGroup>
      {}
      {documentUploadIndex === 0 ? (
        <PatientIdentificationRow>
          <PatientIdentificationColumn>
            <Stack display="row">
              <Controller
                name="idType"
                control={control}
                defaultValue={'license'}
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    <StyledFormControlLabel
                      value="license"
                      control={<Radio />}
                      label="Driver's License"
                    />
                    <StyledFormControlLabel
                      value="id"
                      control={<Radio />}
                      label="ID Card"
                    />
                  </RadioGroup>
                )}
              />
            </Stack>
            <StyledFormControlLabel
              control={
                <Checkbox
                  checked={parentId}
                  onChange={(e) => setParentId(e.target.checked)}
                  tabIndex={-1}
                />
              }
              label="Parent/Guardian ID"
            />
            {idFile !== undefined ? (
              idFile?.type.includes('pdf') ? (
                <Document
                  file={idFile}
                  loading={
                    <Box
                      sx={{
                        display: 'flex',
                        width: '300px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                  error={
                    <Box
                      sx={{
                        display: 'flex',
                        width: '70px',
                        marginRight: '15px',
                      }}
                    >
                      <FileIcon
                        extension={'pdf'}
                        {...defaultStyles['pdf' as DefaultExtensionType]}
                        labelUppercase
                      />
                    </Box>
                  }
                >
                  <Page
                    pageNumber={1}
                    width={350}
                    onClick={() => {
                      setIdFile(undefined)
                    }}
                  />
                </Document>
              ) : (
                <StyledImg
                  alt="id"
                  width={350}
                  height={212}
                  src={URL.createObjectURL(idFile)}
                  onClick={() => {
                    setIdFile(undefined)
                  }}
                />
              )
            ) : (
              <UploadWrapper>
                <FileUploadSelect
                  selectedFiles={idFile as unknown as File}
                  setSelectedFiles={setIdFile}
                  fullWidth
                  allowedFileTypes={['PDF', 'PNG', 'JPG', 'JPEG', 'GIF']}
                />
              </UploadWrapper>
            )}
          </PatientIdentificationColumn>
        </PatientIdentificationRow>
      ) : (
        <PatientIdentificationRow>
          {documentUploadIndex !== 1 && (
            <PatientIdentificationColumn sx={{ marginTop: '54px' }}>
              {profilePictureFile !== undefined ? (
                <Image
                  alt="Profile Picture"
                  width={760}
                  src={URL.createObjectURL(profilePictureFile)}
                  onClick={() => {
                    setProfilePictureFile(undefined)
                  }}
                  height={760}
                  sizes="100vw"
                  style={{
                    width: '100%',
                    height: 'auto',
                    borderRadius: '14px',
                    maxHeight: '380px',
                  }}
                />
              ) : (
                <UploadWrapper>
                  <FileUploadSelect
                    selectedFiles={profilePictureFile as unknown as File}
                    setSelectedFiles={setProfilePictureFile}
                    allowedFileTypes={['PDF', 'PNG', 'JPEG', 'JPG, GIF']}
                    fullWidth
                  />
                </UploadWrapper>
              )}
            </PatientIdentificationColumn>
          )}
          {documentUploadIndex === 1 && (
            <PatientIdentificationColumn sx={{ marginTop: '54px' }}>
              <UploadWrapper>
                <FileUploadSelect
                  multiple
                  selectedFiles={intakeDocument}
                  setSelectedFiles={setIntakeDocument}
                  fullWidth
                />
              </UploadWrapper>
            </PatientIdentificationColumn>
          )}
        </PatientIdentificationRow>
      )}
      {(visits?.length > 0 ||
        (importedPatientLastVisitDate !== undefined &&
          importedPatientLastVisitDate !== null &&
          importedPatientLastVisitDate !== '')) && (
        <PatientIdentificationRow>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                gap: '8px',
              }}
            >
              <PastVisitsHeader>
                <SectionLabel sectionLabelText="Past Visits" />
                <PastVisitCountsCircle>
                  <Typography
                    style={{
                      color: 'white',
                      fontSize: '12px',
                      fontWeight: 'bold',
                    }}
                  >{`${visits.length}`}</Typography>
                </PastVisitCountsCircle>
              </PastVisitsHeader>
              {importedPatientLastVisitDate !== undefined &&
                importedPatientLastVisitDate !== null &&
                importedPatientLastVisitDate !== '' && (
                  <Typography
                    style={{ fontSize: '14px', color: '#565656' }}
                  >{`Imported Last Visit: ${format(
                    new Date(importedPatientLastVisitDate),
                    'MM/dd/yyyy'
                  )}`}</Typography>
                )}
            </div>
            <PastVisits
              visits={visits}
              users={users}
              user={user}
              visitTypes={visitTypes}
            />
          </div>
        </PatientIdentificationRow>
      )}
    </PatientIdentificationContainer>
  )
}
interface PatientIdentificationProps {
  innerRef: RefObject<HTMLDivElement>;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  onFocus: any;
  idFile: Blob | undefined;
  setIdFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  intakeDocument: FileList | undefined;
  setIntakeDocument: React.Dispatch<React.SetStateAction<FileList | undefined>>;
  profilePictureFile: Blob | undefined;
  setProfilePictureFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  visits: Visit[];
  users: AccountUser[];
  user: any;
  visitTypes: VisitType[];
  orders?: Order[];
  procedures?: ProcedureType[];
  importedPatientLastVisitDate: string | undefined | null;
  setValue: any;
  checkForDuplicatePatient: () => void;
}

interface DocumentSelectButtonProps {
  selectedIndex: number;
  buttonIndex: number;
  theme: Theme;
}
