import React from 'react'
import { useAuth, useUser } from '@clerk/nextjs'
import {
  Tooltip,
  IconButton,
  styled,
  Button,
  Stack,
  Modal,
  TextField,
  FormControlLabel,
  Checkbox,
  type Theme,
  StyledEngineProvider
} from '@mui/material'
import { ThemeProvider } from '@mui/styles'
import MaterialReactTable, {
  type MRT_ColumnDef,
  type MRT_SortingState,
  type MRT_RowSelectionState
} from 'material-react-table'
import { StyledTableText } from '@/components/PatientProfile/styles'
import {
  PillSelect,
  TileInputLabel,
  TileTextField
} from '@/components/Charting'
import {
  useAccountUserStore,
  useClinicStore,
  useDebounce,
  useDocumentStore,
  useQueryGetAccountUsers,
  useQueryGetDocumentTypes,
  useQueryGetDocuments,
  useTableTheme
} from '@/hook'
import { getDocument, updateDocument } from '@/services'
import { type DocumoFax, type Patient, type UrgentIQDocuments } from '@/types'
import { convertToSpaceSeparated, isEmpty, truncateText } from '@/utility'
import { format, parseISO } from 'date-fns'
import { SendRecordModal } from '@/components/Charting/Editor/IQBar/modals'
import { FileUploadDialog } from './FileUploadDialog'
import { DeleteWarningDialog } from './DeleteWarningDialog'
import { SendDocumentMessageButton } from '@/components/Messaging/SendDocumentMessageButton'
import { type QueryObserverResult } from '@tanstack/react-query'
import DownloadIcon from '@mui/icons-material/DownloadOutlined'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import { type UrgentIQDocument } from '../../../types/Document'
import FaxOutlinedIcon from '@mui/icons-material/FaxOutlined'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const StyledEditButton = styled(Button)({
  fontSize: '12px',
  fontWeight: 500,
  borderRadius: '25px',
  padding: '8px 16px',
  lineHeight: '20px',
  height: '30px',
  width: 'fit-content',
  textTransform: 'capitalize',
  marginTop: '-6px'
})

const DashboardContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  paddingBottom: '0px'
})

const SortContainer = styled('div')({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  gap: '8px',
  minWidth: '280px'
})

const CustomSnackbar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.text.primary,
  padding: '8px 16px',
  borderRadius: '4px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  boxShadow: theme.shadows[1],
  border: `1px solid ${theme.palette.divider}`
}))

export const DocumentsTab = ({
  clinicId,
  patient = null,
  showAllClinicsOption = true,
  showAllPatientDocumentOption = false,
  visitId
}: DocumentTypeProps): JSX.Element => {
  const { getToken } = useAuth()
  const { clinic } = useClinicStore()
  const [eFaxOpen, setEFaxOpen] = React.useState<boolean>(false)
  const [filteredTableData, setFilteredTableData] = React.useState<any[]>([])
  const [selectedRows, setSelectedRows] = React.useState({})
  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false)
  const [documentName, setDocumentName] = React.useState<string>('')
  const [showAllClinics, setShowAllClinics] = React.useState(false)
  const [uploadOpen, setUploadOpen] = React.useState<boolean>(false)
  const [openDelete, setOpenDelete] = React.useState<boolean>(false)
  const [documentId, setDocumentId] = React.useState<number>(0)
  const [deleteDocName, setDeleteDocName] = React.useState<string>('')
  const [selectedDocuments, setSelectedDocuments] = React.useState<number[]>([])
  const [selectedDocumentIds, setSelectedDocumentIds] = React.useState<
    number[]
  >([])
  const [selectedDocumentType, setSelectedDocumentType] =
    React.useState<number>(0)
  const [selectedAccountUsers, setSelectedAccountUsers] = React.useState<
    number[]
  >([])
  const [isMultipleFax, setIsMultipleFax] = React.useState(false)

  const [pagination, setPagination] = React.useState<{
    pageIndex: number
    pageSize: number
  }>({ pageIndex: 0, pageSize: 10 })

  const [sorting, setSorting] = React.useState<MRT_SortingState>([
    { id: 'dateTime', desc: true }
  ])

  const [showAllPatientDocuments, setShowAllPatientDocuments] = React.useState<boolean>(false)

  const { user } = useUser()
  const { accountUsers } = useAccountUserStore()
  const { data: docTypes } = useQueryGetDocumentTypes(getToken)
  const { data: clinicians } = useQueryGetAccountUsers(getToken)
  const { documents } = useDocumentStore()

  const tableTheme = useTableTheme()

  const debouncedDocumentName = useDebounce(documentName, 500)

  const sortingMap: Record<string, 0 | 1 | 2 | 3> = {
    documentDetail: 0,
    docType: 1,
    dateTime: 2,
    createdBy: 3
  }

  const queryResults: QueryObserverResult<UrgentIQDocuments | any[], unknown> =
    useQueryGetDocuments(
      getToken,
      pagination.pageIndex,
      pagination.pageSize,
      sortingMap[sorting[0] ? sorting[0].id : 'dateTime'],
      !sorting[0]?.desc,
      selectedDocuments,
      selectedAccountUsers,
      // remove -1, if -1 is true, add flag of noAccountUsers
      debouncedDocumentName,
      patient && typeof patient.id === 'number' ? patient.id : undefined,
      (clinicId && !showAllClinics) ?? !showAllPatientDocuments
        ? clinicId
        : undefined,
      !showAllPatientDocuments ? visitId : undefined
    )

  const { refetch: loadDocuments, isFetching: isDocumentTableLoading } =
    queryResults

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version ?? ''}/pdf.worker.js`

  const handleOpenUploadModal = (): void => setUploadOpen(true)

  React.useEffect(() => {
    let docsToFilter: UrgentIQDocument[] = []
    if (
      documents &&
      'documentPageList' in documents &&
      Array.isArray(documents.documentPageList)
    ) {
      docsToFilter = documents.documentPageList
    } else if (Array.isArray(documents)) {
      docsToFilter = documents
    }
    const filteredDocs =
      selectedDocuments.length === 0
        ? docsToFilter
        : docsToFilter.filter((myDoc) => {
            return selectedDocuments.includes(myDoc.documentTypeId ?? 0)
          })
    setFilteredTableData(filteredDocs)
  }, [documents, selectedDocuments])

  React.useEffect(() => {
    loadDocuments().catch((error) => {
      console.error('Failed to load documents:', error)
    })
  }, [showAllClinics])

  React.useEffect(() => {
    const selectedIds = Object.entries(selectedRows)
      .filter(([_, selected]) => selected)
      .map(([index]) => filteredTableData[parseInt(index)].id)

    setSelectedDocumentIds(selectedIds)

    if (selectedIds.length > 0) {
      setSnackbarOpen(true)
    } else {
      setSnackbarOpen(false)
    }
  }, [selectedRows, filteredTableData])

  const handleDocTypeChange = (event: any): void => {
    const {
      target: { value }
    } = event
    setSelectedDocuments(value as number[])
  }

  const getFullName = (accountId: number): string => {
    const clinician = clinicians?.find((c) => c.id === accountId)
    if (isEmpty(clinician)) {
      return '-'
    }
    return `${clinician?.firstName!} ${clinician?.lastName!}`
  }

  const getDocumentType = (docType: number): string => {
    const documentType =
      docTypes &&
      Array.isArray(docTypes) &&
      docTypes.find((type) => type?.id === docType)
    return documentType?.name as string
  }

  const sendFax = async (faxFile: DocumoFax, faxData: Blob): Promise<void> => {
    const formData = new FormData()
    formData.append('File', faxData, deleteDocName)
    for (const key in faxFile) {
      if (Object.prototype.hasOwnProperty.call(faxFile, key)) {
        const element = faxFile[key as keyof DocumoFax]
        formData.append(key, element?.toString() ?? '')
      }
    }
    fetch(`${process.env.API_URL ?? ''}/api/DocumoFax`, {
      method: 'POST',
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({
            template: 'UrgentIQ'
          })) ?? ''
        }`
      },
      body: formData
    }).catch((error) => {
      throw error
    })
  }

  const handleSendFax = (fax: DocumoFax): void => {
    const newFax = {
      ...fax,
      accountUserId: user?.publicMetadata.account_user_id as number,
      clinicId: clinicId ?? clinic?.id ?? 0,
      documentTypeId: selectedDocumentType
    }

    Promise.resolve(getDocument(documentId ?? 0, getToken))
      .then((doc) => {
        if (!doc.presignedUrl) {
          console.error('PresignedUrl is not available in the doc')
          throw new Error('PresignedUrl is not available in the doc')
        }
        Promise.resolve(fetch(doc.presignedUrl))
          .then((response) => {
            Promise.resolve(response.blob())
              .then((blob) => {
                sendFax(newFax, blob).catch((err) => {
                  throw err
                })
              })
              .catch((error) => {
                throw error
              })
          })
          .catch((error) => {
            throw error
          })
      })
      .catch((error) => {
        throw error
      })
  }

  // prettier-ignore
  const documentsColumns = React.useMemo<
  // prettier-ignore
  Array<MRT_ColumnDef<DocumentColumnProps>>
  // prettier-ignore
  >(
    () => [
      {
        id: 'documentDetail',
        header: 'Document Detail',
        sortingFn: 'alphanumeric',
        enableSorting: true,
        accessorFn: (row) => row.visibleDocumentName,
        Cell: ({ cell, row }: any) => {
          const [isEditing, setIsEditing] = React.useState(false)
          const [editValue, setEditValue] = React.useState(row.original.visibleDocumentName)
  
          React.useEffect(() => {
            if (isEditing) {
              setEditValue(row.original.visibleDocumentName)
            }
          }, [isEditing, row.original.visibleDocumentName])
  
          const handleChange = (event: any): void => {
            setEditValue(event.target.value)
          }
  
          const handleSave = (): void => {
            const executeAsync = async (): Promise<void> => {
              if (editValue !== row.original.visibleDocumentName) {
                const documentData = {
                  ...row.original,
                  visibleDocumentName: editValue,
                }
                try {
                  await updateDocument(row.original.id, documentData, getToken)
                  setFilteredTableData((currentData) =>
                    currentData.map((doc) =>
                      doc.id === row.original.id
                        ? { ...doc, visibleDocumentName: editValue }
                        : doc
                    )
                  )
                } catch (error) {
                  console.error('Failed to update document:', error)
                }
              }
              setIsEditing(false)
            }
            void executeAsync()
          }
  
          return (
            <Stack direction="row" alignItems="center" spacing={0.5} sx={{ marginLeft: '-8px' }}>
              {isEditing ? (
                <>
                  <TextField
                    value={editValue}
                    onChange={handleChange}
                    autoFocus
                    size="small"
                    fullWidth
                    variant="outlined"
                  />
                  <IconButton size="small" onClick={handleSave} color="primary">
                    <SaveOutlinedIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <Tooltip title={row?.original?.visibleDocumentName} arrow>
                    <StyledTableText>
                      {truncateText(row?.original?.visibleDocumentName, 50)}
                    </StyledTableText>
                  </Tooltip>
                  <IconButton size="small" onClick={() => setIsEditing(true)}>
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                </>
              )}
            </Stack>
          )
        },
        size: 100,
      },
      {
        id: 'docType',
        header: 'Type',
        sortingFn: 'alphanumeric',
        enableSorting: true,
        accessorFn: (row) => getDocumentType(row.documentTypeId),
        Cell: ({ cell, row }: any) => (
          <StyledTableText>
            {getDocumentType(row?.original?.documentTypeId)}
          </StyledTableText>
        ),
        size: 60
      },
      {
        id: 'dateTime',
        header: 'Created Date and Time',
        sortingFn: 'datetime',
        enableSorting: true,
        accessorFn: (row) => row?.readOnlyCreatedAt ?? '',
        Cell: ({ cell, row }: any) => (
          <StyledTableText>
            {format(parseISO(row?.original?.readOnlyCreatedAt ?? ''), 'MM/dd/yyyy h:mm a')}
          </StyledTableText>
        ),
        size: 80
      },
      {
        id: 'createdBy',
        header: 'Created By',
        sortingFn: 'alphanumeric',
        enableSorting: true,
        accessorFn: (row: any) => getFullName(row?.original?.readOnlyCreatedBy),
        Cell: ({ cell, row }: any) => (
            <StyledTableText>
              {getFullName(row?.original?.readOnlyCreatedBy)}
            </StyledTableText>
        ),
        size: 60
      }
    ],
    [docTypes]
  )

  const table = React.useMemo(() => {
    return (
      <MaterialReactTable
        columns={documentsColumns as any[]}
        data={filteredTableData}
        enableRowActions
        enableRowSelection
        onRowSelectionChange={(
          updater:
            | MRT_RowSelectionState
            | ((prevState: MRT_RowSelectionState) => MRT_RowSelectionState)
        ) => {
          const newSelectedRows =
            typeof updater === 'function' ? updater(selectedRows) : updater
          setSelectedRows(newSelectedRows)
          const selectedIds = Object.entries(newSelectedRows)
            .filter(([_, selected]) => selected)
            .map(([index, _]) => filteredTableData[parseInt(index)].id)
          setSelectedDocumentIds(selectedIds)
        }}
        enableSorting
        enableSortingRemoval={false}
        positionActionsColumn="last"
        state={{
          isLoading: isDocumentTableLoading,
          pagination,
          sorting,
          rowSelection: selectedRows
        }}
        enableColumnActions={false}
        enableTopToolbar={false}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10, 20],
          showFirstButton: false,
          showLastButton: false
        }}
        rowCount={documents.totalDocumentCount}
        manualPagination
        manualSorting
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        renderRowActions={({ row }: any) => (
          <Stack
            direction="row"
            sx={{ alignItems: 'center' }}
          >
            <Tooltip
              title="View Document"
              arrow
            >
              <IconButton
                onClick={() => {
                  ;(async () => {
                    const documentId = row?.original?.id
                    const doc = await getDocument(documentId, getToken)
                    await window.open(doc.presignedUrl!, '_blank')
                  })().catch((error) => {
                    throw error
                  })
                }}
                size="large"
              >
                <VisibilityIcon
                  sx={{ color: '#252D63' }}
                  style={{ width: '17px', height: '17px' }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Download Document"
              arrow
            >
              <IconButton
                onClick={() => {
                  ;(async () => {
                    const documentId = row?.original?.id
                    const doc = await getDocument(documentId, getToken)
                    fetch(doc.presignedUrl!)
                      .then(async (response) => await response.blob())
                      .then((res) => {
                        const aElement = document.createElement('a')
                        aElement.setAttribute(
                          'download',
                          row?.original?.visibleDocumentName
                        )
                        const href = URL.createObjectURL(res)
                        aElement.href = href
                        aElement.setAttribute('target', '_blank')
                        aElement.click()
                        URL.revokeObjectURL(href)
                      })
                      .catch((err) => {
                        throw err
                      })
                  })().catch((error) => {
                    throw error
                  })
                }}
                size="large"
              >
                <DownloadIcon
                  sx={{ color: '#252D63' }}
                  style={{ width: '17px', height: '17px' }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Fax Document to Provider"
              arrow
            >
              <IconButton
                onClick={() => {
                  setDocumentId(row?.original?.id)
                  setDeleteDocName(row?.original?.visibleDocumentName)
                  setSelectedDocumentType(row?.original?.documentTypeId)
                  setIsMultipleFax(false)
                  setEFaxOpen(true)
                }}
                size="large"
              >
                <SendOutlinedIcon
                  sx={{ color: '#252D63' }}
                  style={{ width: '17px', height: '17px' }}
                />
              </IconButton>
            </Tooltip>
            <SendDocumentMessageButton
              patientId={row?.original?.patientId}
              message={'Document sent from UrgentIQ'}
              documentId={row?.original?.id}
            />
            <Tooltip
              title="Delete Document"
              arrow
            >
              <IconButton
                onClick={() => {
                  setDocumentId(row?.original?.id)
                  setDeleteDocName(row?.original?.visibleDocumentName)
                  setOpenDelete(true)
                }}
                size="large"
              >
                <DeleteOutlineIcon
                  sx={{ color: '#252D63' }}
                  style={{ width: '17px', height: '17px' }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
        muiTableProps={{
          sx: {
            tableLayout: 'fixed'
          }
        }}
        muiTableHeadProps={{
          sx: () => ({
            '& tr:nth-of-type(odd)': {
              backgroundColor: 'rgb(246, 248, 253)'
            }
          })
        }}
        muiTableBodyRowProps={{
          sx: () => ({
            '&': {
              backgroundColor: 'white'
            }
          })
        }}
        muiTableBodyCellProps={{
          sx: {
            padding: '4px'
          }
        }}
        muiTableHeadCellProps={{
          sx: {
            padding: '8px'
          }
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: 'Actions',
            minSize: 100
          },
          'mrt-row-select': {
            size: 40
          }
        }}
      />
    )
  }, [
    documentsColumns,
    filteredTableData,
    isDocumentTableLoading,
    pagination,
    sorting,
    selectedRows
  ])

  return (
    <DashboardContainer>
      <Modal
        open={eFaxOpen}
        onClose={() => setEFaxOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {
          <SendRecordModal
            handleClose={() => setEFaxOpen(false)}
            handleSend={handleSendFax}
            availableDocumentList={filteredTableData.filter((doc) =>
              selectedDocumentIds.includes(doc.id)
            )}
            isMultipleFax={isMultipleFax}
          />
        }
      </Modal>
      <Stack
        direction="column"
        style={{
          width: '100%',
          height: '100%',
          background: 'white',
          marginTop: patient ? '4px' : '-20px'
        }}
      >
        <Stack
          direction="row"
          justifyContent="right"
          alignItems="center"
          alignContent="center"
          paddingBottom="20px"
          flexWrap={'wrap'}
          style={{ width: '100%', gap: '24px' }}
        >
          <SortContainer>
            <TileInputLabel>Document Name</TileInputLabel>
            <TileTextField
              value={documentName}
              minHeight={'40px'}
              inputProps={{ 'data-testid': 'documentName' }}
              onChange={(event) => setDocumentName(event.target.value)}
            />
          </SortContainer>
          <SortContainer>
            <TileInputLabel>Account Users</TileInputLabel>
            <PillSelect
              dataTestId="accUsers"
              options={
                accountUsers && Array.isArray(accountUsers)
                  ? accountUsers
                      .map((user) => ({
                        ...user,
                        name: `${user?.firstName ?? ''} ${
                          user?.lastName ?? ''
                        }`.trim()
                      }))
                      .sort((a, b) => a.name.localeCompare(b.name))
                  : []
              }
              compact
              values={selectedAccountUsers}
              setValues={setSelectedAccountUsers}
              onChange={(event) => {
                setSelectedAccountUsers(event.target.value as number[])
              }}
              label={''}
            />
          </SortContainer>
          <SortContainer>
            <TileInputLabel>Document Types</TileInputLabel>
            <PillSelect
              dataTestId="docTypes"
              options={
                docTypes && Array.isArray(docTypes)
                  ? docTypes
                      .map((type) => ({
                        ...type,
                        name: convertToSpaceSeparated(type?.name ?? '')
                      }))
                      .sort((a, b) => a.name.localeCompare(b.name))
                  : []
              }
              compact
              values={selectedDocuments}
              setValues={setSelectedDocuments}
              onChange={handleDocTypeChange}
              label={''}
            />
          </SortContainer>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          paddingBottom="20px"
          flexWrap={'wrap'}
          style={{
            width: '100%',
            gap: '24px',
            marginTop: '-6px',
            marginBottom: '-6px'
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
          >
            {snackbarOpen && (
              <CustomSnackbar>
                <span>{`${
                  Object.keys(selectedRows).length
                } files selected:`}</span>
                <Tooltip title="Fax Documents">
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => {
                      setIsMultipleFax(true)
                      setEFaxOpen(true)
                    }}
                  >
                    <FaxOutlinedIcon
                      fontSize="small"
                      sx={{ color: '#505050' }}
                    />
                  </IconButton>
                </Tooltip>
              </CustomSnackbar>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
          >
            {showAllClinicsOption && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAllClinics}
                    onChange={(e) => setShowAllClinics(e.target.checked)}
                  />
                }
                label="Show documents for all clinics"
              />
            )}
          {showAllPatientDocumentOption && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={showAllPatientDocuments}
                            onChange={(e) => setShowAllPatientDocuments(e.target.checked)}
                          />
                        }
                        label="Show all patient documents (include previous visits)"
                      />
                    )}

            <StyledEditButton
              variant="contained"
              onClick={handleOpenUploadModal}
            >
              Upload
            </StyledEditButton>
          </Stack>
        </Stack>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={tableTheme}>{table}</ThemeProvider>
        </StyledEngineProvider>
      </Stack>
      <FileUploadDialog
        clinicId={clinicId}
        isOpen={uploadOpen}
        handleClose={() => setUploadOpen(false)}
        docTypes={docTypes ?? []}
        patient={patient}
        clinicians={clinicians ?? []}
        refreshDocuments={loadDocuments}
        showAllClinicsOption={showAllClinicsOption}
      />
      <DeleteWarningDialog
        documentName={deleteDocName}
        deleteDocId={documentId}
        isOpen={openDelete}
        handleClose={() => setOpenDelete(false)}
        refreshDocuments={loadDocuments}
      />
    </DashboardContainer>
  )
}

interface DocumentTypeProps {
  clinicId?: number
  patient?: Patient | null
  showAllClinicsOption?: boolean
  showAllPatientDocumentOption?: boolean
  visitId?: number
}

interface DocumentColumnProps {
  visibleDocumentName: string
  documentTypeId: number
  readOnlyCreatedAt: string
}
