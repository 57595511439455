import React from 'react'
import { type PatientSocialHistory } from '@/types'
import {
  TileRow,
  TileColumn,
  AddTable,
  NotesRow,
  SocialHistoryRow,
  TileTextField,
  TileSearchBox,
  HeaderPMH,
  Category,
} from '@/components'
import { IconButton } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { isEmpty } from '@/utility'

export const SocialHistory = ({
  patientId,
  socialHistories,
  addSocialHistory,
  deleteSocialHistory,
  notes,
  setNotes,
  socialHistoryType,
  setSocialHistoryType,
  isSocialHistoryVisible,
  setIsSocialHistoryVisible,
}: SocialHistoryProps): JSX.Element => {
  const [inputValue, setInputValue] = React.useState<string>('')
  const [lastKeyDown, setLastKeyDown] = React.useState<string>('')

  React.useEffect(() => {
    setInputValue('')
  }, [patientId])

  const type = [
    { id: 0, label: '' },
    { id: 1, label: 'Alcohol' },
    { id: 2, label: 'Tobacco' },
    { id: 3, label: 'Drugs' },
    { id: 4, label: 'Other' },
  ]

  const [socialHistoryFrequency, setSocialHistoryFrequency] =
    React.useState<string>('')
  const handleHistoryFrequency = (event: any): void => {
    setSocialHistoryFrequency(event.target.value)
  }

  const handleSocialHistoryChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    if (newValue === null) {
      setSocialHistoryType({ id: 0, label: '' })
    } else {
      const newType = { id: newValue.id ?? 0, label: newValue.label ?? '' }
      setSocialHistoryType(newType)
      if (lastKeyDown === 'ArrowDown') {
        handleAddSocialHistory()
      }
    }
  }

  const handleAddSocialHistory = (): void => {
    if (socialHistoryType?.id !== 0 || inputValue !== '') {
      addSocialHistory({
        patientId,
        otherSocialHistory: inputValue,
        frequency: socialHistoryFrequency,
      }).catch((err) => {
        throw err
      })
      setSocialHistoryType({
        id: 0,
        label: '',
      })
      setSocialHistoryFrequency('')
    }
  }

  const handleDeleteSocialHistory = (
    socialHistory: PatientSocialHistory
  ): void => {
    deleteSocialHistory(socialHistory).catch((err) => {
      throw err
    })
  }

  const handleEditSocialHistory = (
    socialHistory: PatientSocialHistory
  ): void => {
    setSocialHistoryType(
      type.find((t) => t.label === socialHistory.otherSocialHistory)
    )
    setSocialHistoryFrequency(socialHistory.frequency ?? '')
    handleDeleteSocialHistory(socialHistory)
  }

  const buildConditions = (): JSX.Element => {
    return (
      <AddTable>
        {socialHistories.map((condition) => {
          return (
            <SocialHistoryRow
              key={condition.id}
              socialHistory={condition}
              delete={handleDeleteSocialHistory}
              edit={handleEditSocialHistory}
            />
          )
        })}
      </AddTable>
    )
  }

  /* prettier-ignore */
  const buildSocialHistories = (): JSX.Element => {
    return (
      <>
        <TileRow key={'social'} id={'social'}>
          <TileSearchBox
            dataTestId='socialType'
            label={'Type'}
            value={socialHistoryType}
            inputValue={inputValue}
            setInputValue={setInputValue}
            twoColumns
            onChange={(event: any, value: any) =>
              handleSocialHistoryChange(event, value)
            }
            options={type.map(st => {
              return (
                {
                  id: st.id ?? 0,
                  label: st.label ?? ''
                }
              )
            }
            )
            }
            onKeyDown={(e) => setLastKeyDown(e.code)}
          />
          <TileTextField
            label={'Frequency'}
            onChange={handleHistoryFrequency}
            value={socialHistoryFrequency}
            inputProps={{ 'data-TestId': 'frequency' }}
          />
        </TileRow>
      </>
    )
  }
  return (
    <Category>
      <TileRow>
        <TileColumn>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <HeaderPMH>Social History</HeaderPMH>
            <IconButton
              onClick={() => setIsSocialHistoryVisible(!isSocialHistoryVisible)}
              size="large"
            >
              {
                // prettier-ignore
                isSocialHistoryVisible
                  ? (<KeyboardArrowDownIcon fontSize='small' sx={{ color: '#000000' }} />)
                  : (<KeyboardArrowRightIcon fontSize='small' sx={{ color: '#000000' }} />)
              }
            </IconButton>
          </div>
          {
            // prettier-ignore
            isSocialHistoryVisible
              // prettier-ignore
              ? (
                // prettier-ignore
                <>
                  {/* prettier-ignore */}
                  {buildSocialHistories()}
                  {/* prettier-ignore */}
                  <NotesRow
                    notes={notes}
                    setNotes={setNotes}
                    title={'Add Social History'}
                    handleAdd={handleAddSocialHistory}
                    expanded={!isEmpty(notes)}
                  />
                  {/* prettier-ignore */}
                </>)
              /* prettier-ignore */
              : (
                /* prettier-ignore */
                <></>
                /* prettier-ignore */
                )
          }
        </TileColumn>
      </TileRow>
      {
        /* prettier-ignore */
        socialHistories.length > 0 && isSocialHistoryVisible
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              {/* prettier-ignore */}
              {buildConditions()}
              {/* prettier-ignore */}
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }
    </Category>
  )
}

interface SocialHistoryProps {
  patientId: number;
  socialHistories: PatientSocialHistory[];
  addSocialHistory: (socialHistory: PatientSocialHistory) => Promise<void>;
  deleteSocialHistory: (socialHistory: PatientSocialHistory) => Promise<void>;
  notes: string;
  setNotes: (notes: string) => void;
  socialHistoryType: SocialHistoryType;
  setSocialHistoryType: any;
  isSocialHistoryVisible: boolean;
  setIsSocialHistoryVisible: any;
}

interface SocialHistoryType {
  id: number;
  label: string;
}
