import React from 'react'
import { AssignAction, StyledTableText } from './styled'
import { getFullName } from '@/utility'
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Tooltip,
} from '@mui/material'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import { useMutateUpdateTask, useTasksStore } from '@/hook/useTasks'
import { useAuth } from '@clerk/nextjs'
import { type AccountUser, type Task } from '@/types'
import AddIcon from '@mui/icons-material/Add'
import { useAccountUserStore } from '@/hook'

export const AssigneeCell = ({ taskId }: AssigneeCellProps): JSX.Element => {
  const { getToken } = useAuth()
  const { accountUsers } = useAccountUserStore()
  const updateTaskMutation = useMutateUpdateTask(getToken)
  const { taskPage } = useTasksStore()
  const [user, setUser] = React.useState<AccountUser | null>(null)

  React.useEffect(() => {
    const task = taskPage?.items?.find(({ id }) => id === taskId)
    setUser(accountUsers?.find(({ id }) => id === task?.accountUserId) ?? null)
  }, [taskId, taskPage?.items, accountUsers])

  const assignees = React.useMemo(() => {
    return accountUsers.sort((a: AccountUser, b: AccountUser) => {
      const aFullName = getFullName(a) ?? ''
      const bFullName = getFullName(b) ?? ''
      return aFullName.localeCompare(bFullName)
    })
  }, [accountUsers])

  const handleUpdateTask = async (
    taskId: number,
    modification: Partial<Task>
  ): Promise<void> => {
    await updateTaskMutation.mutateAsync({
      id: taskId,
      task: modification,
    })
  }

  const anchorElId = `assignee-${taskId}`
  const fullName = user ? getFullName(user) : '-'

  return (
    <AssignAction
      sx={{ width: '140px' }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Tooltip title={fullName} arrow>
        <StyledTableText
          sx={{
            fontSize: '14px',
            color: '#454545',
            width: '140px',
            fontWeight: '600',
          }}
          id={anchorElId}
        >
          {fullName}
        </StyledTableText>
      </Tooltip>
      <PopupState variant="popover" popupId="assignee-popover">
        {(popupState) => (
          <>
            <Box
              className="btn-assign-provider-action"
              {...bindTrigger(popupState)}
            >
              <IconButton size="large">
                <AddIcon
                  style={{
                    cursor: 'pointer',
                    width: '17px',
                    height: '17px',
                    fill: 'rgb(38, 47, 98)',
                  }}
                />
              </IconButton>
            </Box>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              anchorEl={document.getElementById(anchorElId)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={(ev: any): void => {
                popupState.close()
                ev.stopPropagation()
              }}
            >
              <Paper
                sx={{
                  width: '225px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '10px',
                }}
              >
                {assignees.map((account: AccountUser) => {
                  return (
                    <MenuItem
                      key={account.id}
                      value={account.id}
                      sx={{ fontSize: '14px' }}
                      onClick={() => {
                        handleUpdateTask(taskId, {
                          accountUserId: account.id,
                        })
                          .catch((err: any) => {
                            throw err
                          })
                          .finally(() => popupState.close())
                      }}
                    >
                      {getFullName(account) ?? '-'}
                    </MenuItem>
                  )
                })}
              </Paper>
            </Popover>
          </>
        )}
      </PopupState>
    </AssignAction>
  )
}

export interface AssigneeCellProps {
  taskId: number;
}
