import React from 'react'
import { type Patient } from '@/types'
import { Typography, styled } from '@mui/material'
import { AvatarImage } from '../AvatarImage'
import {
  formatAge,
  formatDateOnly,
  formatGender,
  getFullName,
} from '@/utility'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined'

const CardContainer = styled('div')({
  width: '100%',
  display: 'flex',
  padding: '16px 13px 16px 24px',
  gap: '18px',
  alignItems: 'center',
  borderRadius: '4px',
  border: '1px solid #DDDFE8',
})

const PatientDetails = styled('div')({
  flex: 1,
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
})

const PatientName = styled(Typography)({
  fontSize: '16px',
  flex: 1,
  fontWeight: 600,
})

const PatientGroup = styled(Typography)({
  color: '#686868',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

const PatientPrivateInfo = styled(Typography)({
  width: '110px',
  fontSize: '12px',
})

export const TaskPatientCard = ({
  patient,
  onClick,
}: TaskPatientCardProps): JSX.Element => {
  const formatPhoneNumber = (phoneNumberString: string): string => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match != null) {
      return [match[2], '-', match[3], '-', match[4]].join('')
    }
    return ''
  }

  const privateInfo = React.useMemo(() => {
    return [
      formatGender(patient.sex ?? ''),
      `(${formatAge(patient.dateOfBirth)})`,
      formatDateOnly(patient.dateOfBirth),
    ].join(' ')
  }, [patient])

  return (
    <CardContainer onClick={onClick}>
      <PatientDetails>
        <AvatarImage
          src={patient.avatarUrl ?? '/user.png'}
          alt="Patient Image"
          width={32}
          height={32}
        />
        <PatientName>{getFullName(patient)}</PatientName>
        <PatientGroup>
          <PhoneEnabledIcon />
          {formatPhoneNumber(patient.phoneNumber ?? '')}
        </PatientGroup>
        <PatientGroup>
          <ShieldOutlinedIcon />
          <PatientPrivateInfo>{privateInfo}</PatientPrivateInfo>
        </PatientGroup>
      </PatientDetails>
    </CardContainer>
  )
}

interface TaskPatientCardProps {
  patient: Patient;
  onClick: any;
}
