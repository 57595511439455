import React from 'react'
import { useAuth } from '@clerk/nextjs'
import {
  updatePatientImmunization,
  deletePatientImmunization,
} from '@/services'
import {
  usePatientImmunizationStore,
  useQueryGetImmunization,
  useQueryGetPatientImmunization,
  useMutateAddPatientImmunization,
} from '@/hook'
import { PMHTile } from '../PatientHistoryTile'
import { format } from 'date-fns'
import { isEmpty, parseDateToDateOnly } from '@/utility'

export const PatientImmunizations = ({
  patient,
}: {
  patient: any;
}): JSX.Element => {
  const { getToken } = useAuth()

  const { patientImmunizations } = usePatientImmunizationStore()

  const { data: immunizations } = useQueryGetImmunization(getToken)

  const addPatientImmunizationMutation =
    useMutateAddPatientImmunization(getToken)

  const { isLoading: isImmunizationLoading, refetch } =
    useQueryGetPatientImmunization(patient?.id as number, getToken)

  if (isImmunizationLoading) {
    return <div>Loading immunizations...</div>
  }

  if (!immunizations || immunizations.length === 0) {
    console.error('No immunizations available')
    return <div>No immunization data available.</div>
  }

  const patientImmunizationArray = Array.isArray(patientImmunizations)
    ? patientImmunizations
    : []

  const immunizationsArray = Array.isArray(immunizations) ? immunizations : []

  const columns = [
    { accessor: 'immunizationName', Header: 'Immunization' },
    { accessor: 'date', Header: 'Date' },
  ]

  const patientImmunizationsWithName = patientImmunizationArray.map(
    (immunization) => ({
      ...immunization,
      immunizationName:
        immunization.immunizationId !== null
          ? immunizations?.find(
              (type) => type.id === immunization.immunizationId
            )?.name
          : immunization.otherImmunization ?? 'Unknown Immunization',
      date: !isEmpty(immunization.date)
        ? format(
            new Date(
              immunization.date?.year ?? 0,
              (immunization.date?.month ?? 0) - 1,
              immunization.date?.day ?? 0
            ),
            'MM/dd/yyyy'
          )
        : '',
    })
  )

  const dialogFields = [
    {
      label: 'Immunization',
      accessor: 'immunizationId',
      type: 'select',
      options: immunizationsArray.map((immunization) => ({
        id: immunization.id,
        label: immunization.name,
      })),
    },
    { label: 'Date', accessor: 'date', type: 'date' },
  ]

  const handleAdd = async (data: any): Promise<void> => {
    const newImmunization = {
      patientId: patient.id,
      date:
        data.date !== undefined ? parseDateToDateOnly(data.date) : undefined,
      immunizationId:
        data.immunizationId && !isNaN(data.immunizationId)
          ? parseInt(data.immunizationId, 10)
          : null,
      otherImmunization:
        data.immunizationId && isNaN(data.immunizationId)
          ? data.immunizationId
          : data.immunizationId
          ? null
          : data.immunizationId_label,
    }
    await addPatientImmunizationMutation.mutateAsync({
      immunization: newImmunization,
    })
    await refetch()
  }

  const handleEdit = async (data: any): Promise<void> => {
    const newImmunization = {
      patientId: patient.id,
      date: parseDateToDateOnly(data.date),
      immunizationId:
        data.immunizationId && !isNaN(data.immunizationId)
          ? parseInt(data.immunizationId, 10)
          : null,
      otherImmunization:
        data.immunizationId && isNaN(data.immunizationId)
          ? data.immunizationId
          : data.immunizationId
          ? null
          : data.immunizationId_label,
      id: data.id,
    }
    await updatePatientImmunization(data.id, newImmunization, getToken)
    await refetch()
  }

  const handleDelete = async (immunizationToDelete: any): Promise<void> => {
    if (!immunizationToDelete) {
      console.error('Could not find immunization to delete')
      return
    }
    await deletePatientImmunization(immunizationToDelete.id, getToken)
    await refetch()
  }

  return (
    <PMHTile
      title="Immunizations"
      data={patientImmunizationsWithName}
      columns={columns}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      dialogFields={dialogFields}
    />
  )
}
