import { InputLabel, type Theme, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import Input, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input/input'
import React from 'react'
import { Controller, useWatch, useForm } from 'react-hook-form'
import { ErrorMessage, RedText } from '../ErrorMessage'
import { isEmpty } from '@/utility/utils'

const StyledPhoneInput = styled(Input)(
  ({ width, theme }: StyledPhoneInputProps) => ({
    height: '36px',
    borderRadius: '6px',
    width,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    border: `1px solid ${theme.palette.divider}`,
    paddingLeft: '10px',
    '&:hover': {
      borderColor: 'black',
    },
    '&:focus': {
      borderColor: '#0543B0',
      borderWidth: '2px',
      outline: 'none',
    },
  })
)

export const PhoneNumberInput = ({
  control,
  sectionName = '',
  labelName = 'Phone',
  required = false,
  error,
  width = '240px',
  hideLabel = false,
  profile = false,
  defaultValue = null,
  setError,
}: PhoneNumberInputProps): JSX.Element => {
  const { register } = useForm()
  const phoneNumber = useWatch({
    control,
    name: profile ? sectionName : `${sectionName}PhoneNumber`,
  })
  const theme = useTheme()
  const isInputValid = (): boolean =>
    isEmpty(phoneNumber) ||
    (isPossiblePhoneNumber(phoneNumber ?? '') &&
      isValidPhoneNumber(phoneNumber ?? ''))
  return (
    <div>
      {!hideLabel && (
        <InputLabel>
          {labelName}
          {required && <RedText>{' *'}</RedText>}
        </InputLabel>
      )}
      <Controller
        name={profile ? sectionName : `${sectionName}PhoneNumber`}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <StyledPhoneInput
            width={width}
            theme={theme}
            {...register(profile ? sectionName : `${sectionName}PhoneNumber`)}
            {...field}
            defaultCountry="US"
            maxLength={14}
            mask="(999) 999-9999"
            data-testid={`${sectionName}PhoneNumber`}
            error={!isEmpty(error) || !isInputValid()}
          />
        )}
      />
      {(!isEmpty(error) || !isInputValid()) && (
        <ErrorMessage error={'Valid number is required'} />
      )}
    </div>
  )
}

interface PhoneNumberInputProps {
  control: any;
  labelName?: string;
  sectionName?: string;
  required?: boolean;
  error: any;
  width?: string;
  hideLabel?: boolean;
  profile?: boolean;
  defaultValue?: string | null;
  setError: any;
}
interface StyledPhoneInputProps {
  width: string;
  theme: Theme;
}
