import { styled, TextareaAutosize, type Theme, useTheme } from '@mui/material'
import { TileInputLabel } from '../TileInputLabel'
import React from 'react'
const StyledTextArea = styled(TextareaAutosize)(
  ({ fullsize, theme, fullWidth }: StyledTextAreaProps) => ({
    fontFamily: 'Lato',
    width: fullWidth ? '100%' : fullsize ? '766px' : '502px',
    maxWidth: fullWidth ? '100%' : fullsize ? '766px' : '502px',
    minWidth: fullWidth ? '0px' : fullsize ? '766px' : '502px',
    color: '#222222',
    fontWeight: '500',
    fontSize: '16px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '6px',
    border: `1px solid ${theme.palette.divider}`,
    paddingLeft: '10px',
    '&:hover': {
      borderColor: theme.palette.black,
    },
    '&:focus': {
      borderColor: theme.palette.primary.main,
      borderWidth: '2px',
      outline: 'none',
    },
  })
)

export const TileTextArea = ({
  id = 'tile-text-area',
  label = null,
  value,
  onChange,
  fullsize = false,
  fullWidth = false,
  note = false,
  disabled = false,
  dataTestId,
  onBlur,
  ...props
}: TileTextAreaProps): JSX.Element => {
  const theme = useTheme()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        width: '100%',
      }}
      id={id}
    >
      {label !== null && <TileInputLabel>{label}</TileInputLabel>}
      <StyledTextArea
        theme={theme}
        onBlur={onBlur}
        fullsize={fullsize}
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        maxRows={note ? 4 : 7}
        minRows={note ? 4 : 7}
        disabled={disabled}
        {...props}
        data-testid={dataTestId}
      />
    </div>
  )
}

interface TileTextAreaProps {
  label?: string | null;
  value?: string | number;
  onChange: any;
  fullsize?: boolean;
  fullWidth?: boolean;
  note?: boolean;
  id?: string;
  dataTestId: string;
  disabled?: boolean;
  onBlur?: any;
}

interface StyledTextAreaProps {
  fullWidth?: boolean;
  fullsize: boolean;
  theme: Theme;
}
