import { styled, type Theme, Typography } from '@mui/material'

export const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '80vh',
})

export const ChartingContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  paddingBottom: '40px',
})

export const ChartingContent = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100vw',
  gap: 1,
  height: '100%',
  paddingTop: '68px',
  backgroundColor: theme.palette.background.paper,
}))

export const ChartingContentRightPane = styled('div')(
  ({ isNavOpen, theme }: ChartingContentProps): any => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    marginLeft: `${isNavOpen ? '397px' : '337px'}`,
    padding: `48px 12px 12px ${isNavOpen ? '162px' : '132px'}`,
    marginTop: '80px',
    backgroundColor: theme.palette.background.paper,
    gap: '12px',
  })
)

export const FieldTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.white,
  marginTop: '10px',
}))

export const SummaryTitleRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '358px',
})

export interface ChartingContentProps {
  isNavOpen: boolean;
  theme: Theme;
}
