import React from 'react'

import dynamic from 'next/dynamic'

import { useAuth, useUser } from '@clerk/nextjs'
import { Modal } from '@mui/material'

import { isEmpty } from '@/utility/utils'

import { useClinicStore } from '@/hook'
import { type DocumoFax } from '@/types'

import { SendRecordModal } from '../Charting/Editor/IQBar/modals'
import { format } from 'date-fns'

const PDFViewerComponent = ({
  url,
  formFieldsValues,
  handleSave,
  name,
  disableTaskBar = false,
  fullSize = false,
  shorter = false,
  eSign = false,
  eSignature = '',
  visitId = null,
  patientId = null,
  faxTitle = '',
}: PDFViewerProps): JSX.Element => {
  const { user } = useUser()
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const viewer = React.useRef<HTMLDivElement>(null)
  const [instance, setInstance] = React.useState<any>(null)
  const [eFaxOpen, setEFaxOpen] = React.useState<boolean>(false)
  const [faxData, setFaxData] = React.useState<Blob>(new Blob())
  const [urlState, setUrlState] = React.useState<string>('')
  const [nameState, setNameState] = React.useState<string>('')
  const nameStateRef = React.useRef(nameState)
  const sigStateRef = React.useRef('')
  const instanceMethods = React.useRef<PDFViewerMethods>({})

  React.useEffect(() => {
    setUrlState(url)
  }, [url])
  React.useEffect(() => {
    sigStateRef.current = eSignature
  }, [eSignature])
  React.useEffect(() => {
    setNameState(name)
    nameStateRef.current = name
    instanceMethods.current.eSignHandler = eSignHandler
  }, [name])

  const fillFormFields = (instance: any, data: any): void => {
    const { documentViewer, annotationManager } = instance.Core
    documentViewer.addEventListener('documentLoaded', async () => {
      documentViewer.getAnnotationsLoadedPromise().then(() => {
        const fieldManager = annotationManager.getFieldManager()
        Promise.all(
          Object.keys(data).map(async (key) => {
            const field = fieldManager.getField(key)
            if (field) {
              try {
                await field.setValue(data[key])
              } catch (err) {
                console.error(err) // or re-throw if you want
              }
            }
          })
        ).catch((err) => {
          throw err
        })
      })

      documentViewer.refreshAll()
      documentViewer.updateView()
    })
  }
  const printHandler = (instance: any): void => {
    // Add header button that will get file data on click
    instance.UI.setHeaderItems((header: any) => {
      const { documentViewer, annotationManager } = instance.Core
      header.push({
        type: 'actionButton',
        img: '/print-svgrepo-com.svg',
        buttonName: 'Print',
        title: 'Print',
        onClick: async () => {
          const doc = documentViewer.getDocument()
          const xfdfString = await annotationManager.exportAnnotations()
          const data = await doc.getFileData({
            // saves the document with annotations in it
            xfdfString,
          })
          const arr = new Uint8Array(data)
          const blob = new Blob([arr], { type: 'application/pdf' })
          handleSave(blob)
          instance.UI.print()
        },
      })
    })
  }

  const handleDownload = (instance: any): void => {
    const { documentViewer, annotationManager } = instance.Core
    // Add header button that will get file data on click
    instance.UI.setHeaderItems((header: any) => {
      header.push({
        type: 'actionButton',
        img: '/down-round-svgrepo-com.svg',
        buttonName: 'Download',
        title: 'Download',
        onClick: async () => {
          const doc = documentViewer.getDocument()
          const xfdfString = await annotationManager.exportAnnotations()
          const data = await doc.getFileData({
            // saves the document with annotations in it
            xfdfString,
          })
          const arr = new Uint8Array(data)
          const blob = new Blob([arr], { type: 'application/pdf' })
          try {
            handleSave(blob)
            const aElement = document.createElement('a')
            aElement.setAttribute('download', `${nameStateRef.current}`)
            const href = URL.createObjectURL(blob)
            aElement.href = href
            aElement.setAttribute('target', '_blank')
            aElement.click()
            URL.revokeObjectURL(href)
          } catch (err) {
            // Show error message to user
            instance.UI.displayErrorMessage('Error downloading document')
          }
        },
      })
    })
  }

  const saveCompleteHandler = (instance: any): void => {
    const { documentViewer, annotationManager } = instance.Core
    // Add header button that will get file data on click
    instance.UI.setHeaderItems((header: any) => {
      header.push({
        type: 'actionButton',
        img: '/floppy-disk-svgrepo-com.svg',
        buttonName: 'Save',
        title: 'Save',
        onClick: async () => {
          const doc = documentViewer.getDocument()
          const xfdfString = await annotationManager.exportAnnotations()
          const data = await doc.getFileData({
            // saves the document with annotations in it
            xfdfString,
          })
          const arr = new Uint8Array(data)
          const blob = new Blob([arr], { type: 'application/pdf' })
          try {
            handleSave(blob)
            instance.UI.showWarningMessage({
              title: 'Success!',
              message: 'Document saved successfully!',
              confirmBtnText: 'Okay!',
            })
          } catch (err) {
            // Show error message to user
            instance.UI.displayErrorMessage('Error saving document')
          }
        },
      })
    })
  }

  const faxButtonHandler = (instance: any): void => {
    const { documentViewer, annotationManager } = instance.Core
    // Add header button that will get file data on click
    instance.UI.setHeaderItems((header: any) => {
      header.push({
        type: 'actionButton',
        img: '/fax-free-5-svgrepo-com.svg',
        buttonName: 'Fax',
        title: 'Fax',
        onClick: async () => {
          const doc = documentViewer.getDocument()
          const xfdfString = await annotationManager.exportAnnotations()
          const data = await doc.getFileData({
            // saves the document with annotations in it
            xfdfString,
          })
          const arr = new Uint8Array(data)
          const blob = new Blob([arr], { type: 'application/pdf' })
          try {
            handleSave(blob)
            setFaxData(blob)
            setEFaxOpen(true)
          } catch (err) {
            // Show error message to user
            instance.UI.displayErrorMessage('Error saving document')
          }
        },
      })
    })
  }

  const eSignHandler = async (instance: any): Promise<void> => {
    const { PDFNet, documentViewer } = instance.Core
    documentViewer.zoomTo(1.5)
    documentViewer.addEventListener('documentLoaded', async () => {
      await PDFNet.initialize()
      const doc = await documentViewer.getDocument().getPDFDoc()
      await PDFNet.runWithCleanup(async () => {
        doc.lock()

        const page1 = await doc.getPage(1)

        // Create and add the patientName field
        const patientNameFieldRect = await PDFNet.Rect.init(0, 0, 0, 0)
        const patientNameField = await doc.fieldCreateFromStrings(
          'patientName',
          PDFNet.Field.Type.e_text,
          '',
          ''
        )
        const patientNameWidget = await PDFNet.WidgetAnnot.create(
          doc,
          patientNameFieldRect,
          patientNameField
        )
        page1.annotPushBack(patientNameWidget)
        patientNameField.setValueAsString(formFieldsValues.patientName)

        // Create and add the date field
        const dateFieldRect = await PDFNet.Rect.init(0, 0, 0, 0)
        const dateField = await doc.fieldCreateFromStrings(
          'date',
          PDFNet.Field.Type.e_text,
          '',
          ''
        )
        const dateWidget = await PDFNet.WidgetAnnot.create(
          doc,
          dateFieldRect,
          dateField
        )
        page1.annotPushBack(dateWidget)
        dateField.setValueAsString(format(new Date(Date.now()), 'MM/dd/yyyy'))

        const foundApprovalField = await doc.getField('signature')
        const approvalSigField =
          await PDFNet.DigitalSignatureField.createFromField(
            foundApprovalField
          )
        const img = await PDFNet.Image.createFromURL(doc, sigStateRef.current)
        const approvalSignatureWidget =
          await PDFNet.SignatureWidget.createWithDigitalSignatureField(
            doc,
            await PDFNet.Rect.init(75, 100, 200, 150),
            approvalSigField
          )
        await approvalSignatureWidget.createSignatureAppearance(img)
        page1.annotPushBack(approvalSignatureWidget)
        const buf = await doc.saveMemoryBuffer(0)
        const blob = new Blob([buf], { type: 'application/pdf' })
        handleSave(blob)
      })
    })
  }

  const sendFax = async (faxFile: DocumoFax): Promise<void> => {
    const formData = new FormData()
    formData.append('File', faxData, `${nameStateRef.current}`)
    for (const key in faxFile) {
      if (Object.prototype.hasOwnProperty.call(faxFile, key)) {
        const element = faxFile[key as keyof DocumoFax]
        formData.append(key, element?.toString() ?? '')
      }
    }
    fetch(`${process.env.API_URL ?? ''}/api/DocumoFax`, {
      method: 'POST',
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({
            template: 'UrgentIQ',
          })) ?? ''
        }`,
      },
      body: formData,
    }).catch((error) => {
      throw error
    })
  }

  const handleSendFax = (fax: DocumoFax): void => {
    const newFax = fax
    newFax.accountUserId = user?.publicMetadata.account_user_id as number
    newFax.clinicId = clinicId
    newFax.patientId = patientId
    newFax.visitId = visitId
    Promise.resolve(sendFax(newFax)).catch((error) => {
      throw error
    })
  }

  React.useEffect(() => {
    if (isEmpty(nameState)) return
    if (!viewer.current) return
    if (viewer.current && !instance) {
      import('@pdftron/webviewer')
        .then(({ default: WebViewer }) => {
          WebViewer(
            {
              fullAPI: eSign,
              path: '/webviewer/lib',
              licenseKey:
                'UrgentIQ, Inc. (urgentiq.com):OEM:Urgent IQ::B+:AMS(20240507):58B5772204E7C60A0360B13AC9A2537860616F7CF7402A539BADB48A9D300E9632C431F5C7',
              ...(disableTaskBar && {
                disabledElements: [
                  'ribbons',
                  'toggleNotesButton',
                  'searchButton',
                  'menuButton',
                  'rubberStampToolGroupButton',
                  'stampToolGroupButton',
                  'fileAttachmentToolGroupButton',
                  'calloutToolGroupButton',
                  'undo',
                  'redo',
                  'eraserToolButton',
                  'header',
                  'headerTools',
                ],
              }),
            },
            viewer.current!
          )
            .then((newInstance) => {
              if (!instance) {
                if (!disableTaskBar) {
                  saveCompleteHandler(newInstance)
                  printHandler(newInstance)
                  faxButtonHandler(newInstance)
                  handleDownload(newInstance)
                }

                if (eSign) {
                  Promise.resolve(eSignHandler(newInstance)).catch((err) => {
                    throw err
                  })
                }
                setInstance(newInstance)
              }
            })
            .catch((err) => {
              throw err
            })
        })
        .catch((err) => {
          throw err
        })
    }
  }, [nameState])

  React.useEffect(() => {
    if (!instance) return
    if (isEmpty(urlState)) return
    instance.UI.loadDocument(urlState)
    if (formFieldsValues !== undefined) {
      fillFormFields(instance, formFieldsValues)
    }
  }, [urlState, instance])

  const eFaxModel = React.useMemo(() => {
    return (
      <Modal
        open={eFaxOpen}
        onClose={() => setEFaxOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {!disableTaskBar ? (
          <SendRecordModal
            handleClose={() => setEFaxOpen(false)}
            handleSend={handleSendFax}
            faxTitle={faxTitle}
          />
        ) : (
          <></>
        )}
      </Modal>
    )
  }, [eFaxOpen, instance])

  return (
    <div className="MyComponent">
      {eFaxModel}
      <div
        className="webviewer"
        ref={viewer}
        style={{
          height: shorter ? '46vh' : '80vh',
          width: fullSize ? '100%' : '985px',
          borderRadius: '12px',
        }}
      ></div>
    </div>
  )
}

export const PDFViewer = dynamic(
  async () =>
    await Promise.resolve(PDFViewerComponent).catch((err) => {
      throw err
    }),
  { ssr: false }
)

interface PDFViewerProps {
  url: string;
  formFieldsValues?: any;
  handleSave?: any;
  name: string;
  disableTaskBar?: boolean;
  fullSize?: boolean;
  shorter?: boolean;
  eSign?: boolean;
  eSignature?: string;
  patientId?: number | null;
  visitId?: number | null;
  faxTitle?: string;
}

interface PDFViewerMethods {
  eSignHandler?: (instance: any) => Promise<void>;
}
