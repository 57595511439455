import { styled } from '@mui/material'
import React from 'react'

const ModalRowContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '10px',
  paddingTop: '16px',
})

export const ModalButtonRow = ({ children }: ModalRowProps): JSX.Element => {
  return <ModalRowContainer>{children}</ModalRowContainer>
}

interface ModalRowProps {
  children: JSX.Element | JSX.Element[] | undefined;
}
