import React from 'react'
import { Box } from '@mui/material'
import { TaskDrawer } from '@/components/TaskDrawer'
import { TaskTable } from '@/components/TaskTable/TaskTable'

export const PatientTasks = ({
  patientId,
}: {
  patientId: number
}): JSX.Element => {
  const [currentTask, setCurrentTask] = React.useState<number | null>(null)

  return (
    <>
      <Box>
        <TaskTable
          patientId={patientId}
          editTask={(taskId) => setCurrentTask(taskId)}
          hidePatientColumn={true}
        />
      </Box>
      <TaskDrawer
        open={!!currentTask}
        onClose={() => setCurrentTask(null)}
        taskId={currentTask ?? -1}
        patientId={patientId}
      />
    </>
  )
}

export default PatientTasks
