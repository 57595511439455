import React from 'react'
import {
  PopoverTileText,
  PopoverValueText,
  PopoverContainer,
} from '@/components'

export const ImmunizationsHoverOver = ({
  name,
  date,
}: ImmunizationsHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Name</PopoverTileText>
        <PopoverValueText>{name}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Date</PopoverTileText>
        <PopoverValueText>{date}</PopoverValueText>
      </div>
    </PopoverContainer>
  )
}

interface ImmunizationsHoverOverProps {
  name: string;
  date: string;
}
