import { styled } from '@mui/material'
import React from 'react'

const ModalContainerContainer = styled('div')(({ theme }) => ({
  minWidth: '444px',
  gap: 16,
  backgroundColor: theme.palette.background.default,
  borderRadius: '10px',
  padding: '20px',
}))

export const ModalContainer = ({
  children,
}: ModalContainerProps): JSX.Element => {
  return <ModalContainerContainer>{children}</ModalContainerContainer>
}
interface ModalContainerProps {
  children: JSX.Element | JSX.Element[];
}
