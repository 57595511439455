import { styled, Typography } from '@mui/material'
import React from 'react'

const ErrorMessages = styled(Typography)({
  color: 'red',
  fontSize: '12px',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.01em',
  textAlign: 'left',
  marginTop: '3px',
})

export const RedText = styled('span')({
  color: 'red',
})

export const ErrorMessage = ({ error }: ErrorMessageProps): JSX.Element => {
  return <ErrorMessages>{error}</ErrorMessages>
}

interface ErrorMessageProps {
  error: string;
}
