import React, { type ReactNode } from 'react'
import {
  type ExamNode,
  type ExamStatusType,
  type ExamResultIdType,
} from '@/types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Popover,
  Typography,
  styled,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ExamStatus } from './ExamStatus'
import { ExamSelect } from './ExamSelect'
import { ExamNotes } from './ExamNotes'
import {
  ROS_DENIES,
  ROS_EMPTY,
  EXAM_EMPTY,
  EXAM_ABNORMAL,
  ROS_REPORTS,
  EXAM_NORMAL,
} from '@/utility'

const ExamListContainer = styled(Box)({
  width: '100%',
})

const StyledAccordion = styled(Accordion)({
  '&.Mui-expanded': {
    marginTop: '0px',
  },
})

const StyledAccordionSummary = styled(AccordionSummary)({
  backgroundColor: '#EAF3FF',
  boxShadow: '0px 1px 1px 0px #00000033',
  height: '40px',
  minHeight: '40px !important',
})

const AccordionSummaryContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const AccordionTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
})

const ExamAccordionDetails = styled(AccordionDetails)({
  padding: '8px 16px 16px 16px',
  gap: '10px',
  display: 'flex',
  flexWrap: 'wrap',
})

export type Anchor = HTMLButtonElement | null;

export const ExamList = ({
  examinations,
  examResponses,
  examResultFactorIds,
  handleUpdateExam,
  isLocked = false,
  handleAddExamNote,
  type = 'exam',
  isSaving = false,
  setIsExamListRendering,
}: ExamListProps): JSX.Element => {
  const [expanded, setExpanded] = React.useState<boolean[]>([])
  const [anchorEl, setAnchorEl] = React.useState<Anchor[]>([])
  const [dropdownVisible, setDropDownVisible] = React.useState<boolean[]>([])
  const STR_EMPTY = React.useMemo(
    () => (type === 'exam' ? EXAM_EMPTY : ROS_EMPTY),
    [type]
  )
  const STR_ABNORMAL = React.useMemo(
    () => (type === 'exam' ? EXAM_ABNORMAL : ROS_REPORTS),
    [type]
  )
  const STR_NORMAL = React.useMemo(
    () => (type === 'exam' ? EXAM_NORMAL : ROS_DENIES),
    [type]
  )

  const handlePopoverClick =
    (id: number) => (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl((prev) => {
        const temp = [...prev]
        temp[id] = event.currentTarget
        return temp
      })
    }

  const handleDropDownVisible =
    (id: number) =>
    (value: boolean): void => {
      setDropDownVisible((prev) => {
        const temp = [...prev]
        temp[id] = value
        return temp
      })
    }

  const handlePopoverClose = (id: number) => () => {
    setAnchorEl((prev) => {
      const temp = [...prev]
      temp[id] = null
      return temp
    })
  }

  const handleExpandChange = (id: number) => () =>
    setExpanded((prev: any) => {
      const temp = [...prev]
      const realId = type === 'exam' ? id : -id
      temp[realId] = type === 'exam' ? !temp[realId] : temp[realId] === false
      return temp
    })

  const renderExamStatuses = (
    exam: ExamNode,
    curPath: string[],
    isRoot: boolean,
    defaultStatus: number
  ): ReactNode => {
    const res = exam.children
      .map(
        (child): ReactNode =>
          renderExamStatuses(
            child,
            [...curPath, exam.name],
            false,
            defaultStatus
          )
      )
      .filter((elem): ReactNode => elem)
      .flat(Infinity)
    const renderResult: JSX.Element[] = []
    if (
      ((examResponses[exam.id] || isRoot) &&
        exam.children.every(
          (child: ExamNode) =>
            getExamStatus(child) === examResultFactorIds[STR_EMPTY]
        )) ||
      !!dropdownVisible[exam.id]
    ) {
      const { id, name } = exam
      renderResult.push(
        <ExamStatus
          examResultFactorIds={examResultFactorIds}
          label={[...curPath, name]}
          isLocked={isLocked}
          type={type}
          exam={exam}
          key={`exam-${id}+${curPath.join('-')}+${name}`}
          defaultStatus={examResponses[id]?.status ?? defaultStatus}
          dropDownVisible={!!dropdownVisible[id]}
          handleDropDownVisible={handleDropDownVisible(id)}
          examResponses={examResponses}
          status={examResponses[id]?.status ?? examResultFactorIds[STR_EMPTY]}
          handleUpdateExam={handleUpdateExam}
          isSaving={isSaving}
        />
      )
    }
    const finalRenderResult = [...renderResult, ...(res ?? [])]
    return finalRenderResult.length ? finalRenderResult : null
  }

  const getExamStatus = React.useCallback((exam: ExamNode): number => {
    let flag = false
    for (const child of exam.children) {
      const childStatus = getExamStatus(child)
      if (childStatus === examResultFactorIds[STR_ABNORMAL]) {
        return examResultFactorIds[STR_ABNORMAL]
      }
      if (childStatus === examResultFactorIds[STR_NORMAL]) {
        flag = true
      }
    }
    if (!flag && exam.id < 0) {
      return examResultFactorIds[ROS_EMPTY]
    }
    if (flag) {
      return examResultFactorIds[STR_NORMAL]
    }
    return !flag && examResponses[exam.id]?.status
      ? examResponses[exam.id]?.status
      : examResultFactorIds[STR_EMPTY]
  }, [STR_ABNORMAL, STR_EMPTY, STR_NORMAL, examResponses, examResultFactorIds])

  React.useEffect(() => {
    setExpanded((prev: any) => {
      const temp = [...prev]
      const shouldExpandExams = examinations.filter(
        (exam) =>
          getExamStatus(exam) === examResultFactorIds[STR_ABNORMAL] &&
          prev[exam.id] === undefined
      )
      shouldExpandExams.forEach(({ id }) => {
        temp[type === 'exam' ? id : -id] = true
      })
      return shouldExpandExams.length > 0 ? temp : prev
    })
  }, [examinations, examResponses, examResultFactorIds])

  const examRenderList = React.useMemo(() => {
  return (
    <ExamListContainer>
      {examinations
        .filter(
          ({ id }) =>
            examResponses[id] &&
            examResponses[id].status !== examResultFactorIds[STR_EMPTY]
        )
        .map((exam: ExamNode) => (
          <StyledAccordion
            key={exam.id}
            expanded={
              type === 'exam' ? !!expanded[exam.id] : expanded[-exam.id] ?? true
            }
            onChange={handleExpandChange(exam.id)}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
            >
              <AccordionSummaryContainer>
                <AccordionTitle>{exam.name}</AccordionTitle>
                <ExamStatus
                  examResultFactorIds={examResultFactorIds}
                  small
                  isLocked={isLocked || isSaving}
                  dropDownVisible={!!dropdownVisible[exam.id]}
                  handleDropDownVisible={handleDropDownVisible(exam.id)}
                  examResponses={examResponses}
                  exam={exam}
                  type={type}
                  label={[
                    examResponses[exam.id]?.status ===
                    examResultFactorIds[EXAM_NORMAL]
                      ? 'Normal'
                      : 'Abnormal',
                  ]}
                  isSaving={isSaving}
                  status={getExamStatus(exam)}
                  handleUpdateExam={handleUpdateExam}
                />
              </AccordionSummaryContainer>
            </StyledAccordionSummary>
            <ExamAccordionDetails>
              {exam.children.map((curExam): ReactNode => {
                return renderExamStatuses(
                  curExam,
                  [],
                  true,
                  examResponses[exam.id]?.status
                )
              })}
              <ExamNotes
                note={examResponses[exam.id]?.notes}
                handleAddNote={(notes: string) =>
                  handleAddExamNote(exam.id, notes)
                }
                addItemButtonAnchorEl={anchorEl[exam.id]}
                handleAddItem={handlePopoverClick(exam.id)}
                isLocked={isLocked}
                type={type}
              />
              <Popover
                id={`exampopover-${exam.id}`}
                open={Boolean(anchorEl[exam.id])}
                anchorEl={anchorEl[exam.id]}
                onClose={handlePopoverClose(exam.id)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <ExamSelect
                  examResultFactorIds={examResultFactorIds}
                  examination={exam}
                  examResponses={examResponses}
                  handleUpdateExam={handleUpdateExam}
                  isSaving={isSaving}
                />
              </Popover>
            </ExamAccordionDetails>
          </StyledAccordion>
        ))
      }
      </ExamListContainer>
    )
  }, [STR_EMPTY, anchorEl, dropdownVisible, examResponses, examResultFactorIds, examinations, expanded, getExamStatus, handleAddExamNote, handleExpandChange, handleUpdateExam, isLocked, isSaving, renderExamStatuses, type])

  React.useEffect(() => {
    setExpanded((prev: any) => {
      const temp = [...prev]
      const shouldExpandExams = examinations.filter(
        (exam) =>
          getExamStatus(exam) === examResultFactorIds[STR_ABNORMAL] &&
          prev[exam.id] === undefined
      )
      shouldExpandExams.forEach(({ id }) => {
        temp[type === 'exam' ? id : -id] = true
      })
      return shouldExpandExams.length > 0 ? temp : prev
    })
  }, [examinations, examResponses, examResultFactorIds])

  return <ExamListContainer>{examRenderList}</ExamListContainer>
}

interface ExamListProps {
  examinations: ExamNode[];
  examResponses: ExamStatusType[];
  examResultFactorIds: ExamResultIdType;
  handleUpdateExam: any;
  handleAddExamNote: any;
  isLocked: boolean;
  type?: string;
  isSaving?: boolean;
  setIsExamListRendering: (value: boolean) => void;
}
