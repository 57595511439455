import { useQueryGetVisit } from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { Skeleton, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import React from 'react'

const NoVisitText = (): JSX.Element => (
  <Typography>Task not associated with any visit</Typography>
)

export const TaskVisitSection = ({
  visitId,
}: TaskVisitSectionProps): JSX.Element => {
  const { getToken } = useAuth()
  const {
    data: VisitData = {},
    isLoading,
    error,
  } = useQueryGetVisit(visitId, getToken)
  const router = useRouter()
  if (visitId === -1 || error) {
    return <NoVisitText />
  } else if (isLoading) {
    return <Skeleton />
  }
  return (
    <Typography
      sx={{ fontSize: '14px', cursor: 'pointer' }}
      onClick={() => {
        router
          .push(`/charting/${visitId}`)
          .then(() => {
            console.log('done routing')
          })
          .catch((err: Error) => {
            console.log(`failed routing: ${err.message}`)
          })
      }}
    >
      {!!VisitData &&
        [
          new Date(VisitData.arrivalDateTime!).toDateString(),
          VisitData.chiefComplaint?.description,
        ].join(' - ')}
    </Typography>
  )
}

interface TaskVisitSectionProps {
  visitId: number;
}
