import { IconButton, Modal, styled, SvgIcon } from '@mui/material'
import React from 'react'
import { AddTemplate } from '../../AddModals/AddTemplate'
import { ManagementRow } from '../../layout/row/ManagementRow/ManagementRow'
import { IconBox } from '../../layout/row/IconBox'
import { NameBox } from '../../components/row/NameBox'
import { DetailRow } from '../../layout/row/DetailRow'
import { ValueBox } from '../../components/row/ValueBox'
import { type ChartTemplate } from '../../../../types/ChartTemplate'
import { TemplateMenu } from './TemplateMenu'
import { format, parse } from 'date-fns'
import { type AccountUser } from '@/types'

export const TemplateRow = ({
  template,
  refresh,
  isNavOpen,
  users,
}: TemplateRowProps): JSX.Element => {
  const [openAddTemplate, setOpenAddTemplate] = React.useState(false)
  const handleCloseAddTemplate = (): void => setOpenAddTemplate(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const TemplateColumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '14.5%',
    maxWidth: '470px',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    justifyContent: 'center',
  })

  const findStatus = (): string => {
    if (template === null) {
      return ''
    } else if (template.templateIsPublic === null) {
      return ''
    } else if (template.templateIsPublic === true) {
      return 'Public'
    } else {
      return 'Private'
    }
  }
  const formatDate = (date: string): string => {
    if (date === null || date === undefined) {
      return ''
    } else if (
      date !== '' ||
      date !== undefined ||
      date !== null ||
      date !== '0001-01-01T00:00:00' ||
      format(date ?? 0, 'yyyy') !== '1969'
    ) {
      return format(
        parse(date.slice(0, 10), 'yyyy-MM-dd', new Date()),
        'MM/dd/yyyy'
      )
    } else {
      return ''
    }
  }
  const findUser = (): string => {
    if (template === null) {
      return ''
    } else if (template.createdBy === null) {
      return ''
    } else {
      const firstName: string =
        users?.find((user: AccountUser) => user.id === template.createdBy)
          ?.firstName ?? ''
      const lastName: string =
        users?.find((user: AccountUser) => user.id === template.createdBy)
          ?.lastName ?? ''
      return firstName + ' ' + lastName
    }
  }

  return (
    <ManagementRow>
      <TemplateMenu
        anchorEl={anchorEl}
        open={menuOpen}
        handleClose={handleClose}
        template={template}
        refresh={refresh}
        isNavOpen={isNavOpen}
      />
      <Modal
        open={openAddTemplate}
        onClose={handleCloseAddTemplate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AddTemplate
          handleClose={handleCloseAddTemplate}
          exisitingTemplate={template}
          refresh={refresh}
        />
      </Modal>
      <DetailRow onClick={() => setOpenAddTemplate(true)}>
        <TemplateColumn>
          <NameBox>{template.templateName ?? ''}</NameBox>
        </TemplateColumn>
        <TemplateColumn>
          <ValueBox>{template.templateDescription ?? ''}</ValueBox>
        </TemplateColumn>
        <TemplateColumn>
          <NameBox>{findUser()}</NameBox>
        </TemplateColumn>
        <TemplateColumn>
          <NameBox>{formatDate(template.updatedAt ?? '')}</NameBox>
        </TemplateColumn>
        <TemplateColumn>
          <NameBox>{formatDate(template.createdAt ?? '')}</NameBox>
        </TemplateColumn>
        <TemplateColumn>
          <NameBox>{findStatus()}</NameBox>
        </TemplateColumn>
      </DetailRow>
      <IconBox>
        <IconButton onClick={handleClickListItem} size="large">
          <SvgIcon sx={{ color: '#68719F' }}>
            <svg
              width="16"
              height="5"
              viewBox="0 0 16 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.60001 4.09993C2.48367 4.09993 3.20002 3.38358 3.20002 2.49991C3.20002 1.61625 2.48367 0.899902 1.60001 0.899902C0.716349 0.899902 0 1.61625 0 2.49991C0 3.38358 0.716349 4.09993 1.60001 4.09993Z"
                fill="#252D63"
              />
              <path
                d="M7.99991 4.09993C8.88358 4.09993 9.59993 3.38358 9.59993 2.49991C9.59993 1.61625 8.88358 0.899902 7.99991 0.899902C7.11625 0.899902 6.3999 1.61625 6.3999 2.49991C6.3999 3.38358 7.11625 4.09993 7.99991 4.09993Z"
                fill="#252D63"
              />
              <path
                d="M16.0001 2.49991C16.0001 3.38358 15.2837 4.09993 14.4001 4.09993C13.5164 4.09993 12.8 3.38358 12.8 2.49991C12.8 1.61625 13.5164 0.899902 14.4001 0.899902C15.2837 0.899902 16.0001 1.61625 16.0001 2.49991Z"
                fill="#252D63"
              />
            </svg>
          </SvgIcon>
        </IconButton>
      </IconBox>
    </ManagementRow>
  )
}

interface TemplateRowProps {
  template: ChartTemplate;
  refresh: any;
  isNavOpen: boolean;
  users: AccountUser[];
}
