import React from 'react'
import {
  InputAdornment,
  OutlinedInput,
  styled,
  Typography,
} from '@mui/material'
import { searchPatient } from '@/services'
import { isEmpty } from '@/utility/utils'
import { useDebounce } from '@/hook'
import { type Patient } from '@/types'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/material/CircularProgress'

import { useAuth } from '@clerk/nextjs'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { TaskPatientResultTile } from '../../TaskPatientSearch/TaskPatientResultTile'

const PatientSearchContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  width: '100%',
})
const PatientSearchEmpty = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
  marginLeft: '36px',
}))

const PatientSearchEmptyText = styled(Typography)((): any => ({
  fontSize: '16px',
  lineHeight: '19.2px',
  color: 'lightgrey',
}))

export const StyledOutlineInput = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  height: '40px',
  background: theme.palette.background.default,
  borderRadius: '8px',
}))

const DropDownList = styled('div')(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.default,
  borderRadius: '0 8px 8px 8px',
  borderTopRightRadius: '0',
  marginTop: '40px',
  position: 'absolute',
  padding: '10px 0px',
  boxShadow: '0 5px 10px #CCC',
  gap: '10px',
  zIndex: 10,
}))

const OverlayBackground = styled('div')((): any => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
}))

export const PatientSearch = ({
  handleChange,
  patientId,
}: PatientSearchProps): JSX.Element => {
  const { getToken } = useAuth()
  const [isFetching, setIsFetching] = React.useState<boolean>(false)
  const [patientResults, setPatientResults] = React.useState<Patient[]>([])
  const [isResultsVisible, setIsResultsVisible] =
    React.useState<boolean>(false)
  const [firstLastName, setFirstLastName] = React.useState<string>('')
  const [cursor, setCursor] = React.useState<number>(-1)
  const debouncedFirstLastName = useDebounce<string>(firstLastName, 500)

  const selectPatient = (patient: Patient): void => {
    handleChange(patient?.id ?? -1)
    setIsResultsVisible(false)
  }

  const handleKeyPress = (event: any): void => {
    if (event.key === 'Enter' && cursor === -1) {
      searchPatientResult().catch((err) => {
        throw err
      })
    } else if (event.key === 'Enter' && cursor !== -1) {
      selectPatient(patientResults[cursor])
      setIsResultsVisible(false)
      setCursor(-1)
    } else if (event.key === 'Escape') {
      setIsResultsVisible(false)
      setCursor(-1)
    } else if (event.key === 'ArrowUp' && cursor > 0) {
      setCursor(cursor - 1)
    } else if (
      event.key === 'ArrowDown' &&
      cursor < patientResults?.length - 1
    ) {
      setCursor(cursor + 1)
    }
  }
  const onTextChange = (e: any): void => {
    setFirstLastName(e.target.value)
    setIsResultsVisible(false)
    setCursor(-1)
  }
  const handleOnBlur = (): void => {
    setIsResultsVisible(false)
    setCursor(-1)
  }
  const searchPatientResult = async (): Promise<void> => {
    setIsFetching(true)
    const searchPatientPromise = searchPatient(
      debouncedFirstLastName,
      getToken
    )
    Promise.resolve(searchPatientPromise)
      .then((res) => {
        setPatientResults(res.patientDTOList)
        setIsFetching(false)
        setIsResultsVisible(true)
      })
      .catch((error) => {
        throw error
      })
  }

  React.useEffect(() => {
    if (isEmpty(debouncedFirstLastName)) {
      return
    }
    searchPatientResult().catch((err) => {
      throw err
    })
  }, [debouncedFirstLastName])

  return (
    <>
      <PatientSearchContainer>
        <StyledOutlineInput
          name="PatientSearchInput"
          tabIndex={-1}
          onKeyDown={handleKeyPress}
          placeholder="Type to search"
          endAdornment={
            <InputAdornment position="end">
              <ArrowDropDownOutlinedIcon />
            </InputAdornment>
          }
          startAdornment={
            <InputAdornment position="start">
              {isFetching ? (
                <CircularProgress color="primary" size={20} />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          }
          value={firstLastName}
          onChange={onTextChange}
        />
        {!isFetching && isResultsVisible && patientResults?.length > 0 && (
          <DropDownList>
            {patientResults
              .filter((patient) => patient !== null)
              .map((patient) => {
                return (
                  <TaskPatientResultTile
                    key={patient.id}
                    patient={patient}
                    onClick={() => selectPatient(patient)}
                  />
                )
              })}
          </DropDownList>
        )}
        {!isFetching && isResultsVisible && patientResults?.length === 0 && (
          <DropDownList>
            <PatientSearchEmpty>
              <PatientSearchEmptyText>No Results Found</PatientSearchEmptyText>
            </PatientSearchEmpty>
          </DropDownList>
        )}
      </PatientSearchContainer>
      {isResultsVisible && <OverlayBackground onClick={handleOnBlur} />}
    </>
  )
}

interface PatientSearchProps {
  handleChange: (patientId: number) => void;
  patientId?: number;
}
