import React from 'react'
import {
  PopoverTileText,
  PopoverValueText,
  PopoverContainer,
} from '@/components'

export const SocialHistoryHoverOver = ({
  type,
  frequency,
}: SocialHistoryHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Type</PopoverTileText>
        <PopoverValueText>{type}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Frequency</PopoverTileText>
        <PopoverValueText>{frequency}</PopoverValueText>
      </div>
    </PopoverContainer>
  )
}

interface SocialHistoryHoverOverProps {
  type: string;
  frequency: string;
}
