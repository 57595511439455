import React from 'react'
import { useAuth } from '@clerk/nextjs'
import { type MbscCalendarEvent } from '@mobiscroll/react'
import { useToastStore } from '@/hook/useToast'
import { type NextPage } from 'next'
import { useSearchParams } from 'next/navigation'
import {
  useTheme,
  Dialog,
  Stack,
  LinearProgress,
  ButtonGroup,
} from '@mui/material'
import { format, isBefore, startOfDay, isSameDay } from 'date-fns'
import { type Room, type Visit, type AccountUser } from '@/types'
import {
  DeleteVisitPopUp,
  RoomsCalendarFilter,
  DateSelector,
  NavColumn,
  PatientQueue,
  RoomsCalendar,
  TitleBar,
  AppointmentsCalendar,
} from '@/components'
import { formatInUTC } from '@/utility/TimeZoneHelper'
import {
  useClinicStore,
  useQueryGetAccountUsers,
  useQueryGetComplaints,
  useQueryGetDailyPatientSummary,
  useQueryGetRooms,
  useQueryGetSchedule,
  useQueryGetVisitTypes,
  useMutateUpdateVisit,
  useMutateDeleteVisit,
  useQueryGetClinics,
  useQueryGetAppointments,
} from '@/hook'
import { updateVisit, updateRecurringAppointment } from '@/services'
import { Box } from '@mui/system'
import { type OnlineAppointment } from '@/types/OnlineAppointment'
import { useScheduleState } from '@/context/SchedulingContext'
import {
  ScheduleStats,
  ScheduleStatTile,
  ScheduleStatTitle,
  ScheduleStatValueTile,
  ScheduleStatValue,
  FilterRow,
  FilterDropDowns,
  StyledButton,
  SchedulingContainer,
  SchedulingContent,
  SchedulingContentRightPane,
} from '@/components/AppointmentsCalendar/AppointmentUtils'
import { isEmpty } from '@/utility'

export enum ViewType {
  DAY_VIEW = 'DAY_VIEW',
  MONTHLY_VIEW = 'MONTHLY_VIEW',
  WEEKLY_VIEW = 'WEEKLY_VIEW',
}

const getNextDay = (date: Date): Date => {
  const nextDay = new Date(date)
  nextDay.setDate(date.getDate() + 1)
  return nextDay
}

const Scheduling: NextPage = () => {
  const { getToken } = useAuth()
  const { clinicId, clinics } = useClinicStore()
  const { refetch: getClinics } = useQueryGetClinics(getToken)
  const { addToast } = useToastStore()
  const searchParams = useSearchParams()
  const previousNavOpen = searchParams?.get('previousNavOpen')
  const { data: complaints } = useQueryGetComplaints(getToken)
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const [internalVisits, setVisits] = React.useState<
    Array<Visit | OnlineAppointment>
  >([])
  const [isNavOpen, setIsNavOpen] = React.useState<boolean>(
    previousNavOpen! === 'true'
  )
  const [averageTime, setAverageTime] = React.useState<number>(0)

  const {
    setOpen,
    setEdit,
    setAnchor,
    setOriginalRecurringEvent,
    loadPopupForm,
    mySelectedDate,
    setSelectedDate,
  } = useScheduleState()

  const { data: dailyPatientSummary, refetch: reloadDailyPatientSummary } =
    useQueryGetDailyPatientSummary(
      format(
        isSameDay(mySelectedDate, new Date())
          ? new Date()
          : new Date(mySelectedDate),
        'yyyy-MM-dd'
      ),
      clinicId,
      getToken
    )
  const { data: rooms } = useQueryGetRooms(getToken)
  const { data: clinicians } = useQueryGetAccountUsers(getToken)
  const {
    data: onlineAppointments = [],
    refetch: loadOnlineAppointments,
    isLoading: isAppointmentLoading,
  } = useQueryGetAppointments(
    getToken,
    undefined,
    clinicId,
    format(
      isSameDay(mySelectedDate, new Date())
        ? new Date()
        : new Date(mySelectedDate),
      'yyyy-MM-dd'
    ),
    format(
      isSameDay(mySelectedDate, new Date())
        ? getNextDay(new Date())
        : getNextDay(new Date(mySelectedDate)),
      'yyyy-MM-dd'
    )
  )
  const {
    data: visits = [],
    refetch: loadVisits,
    isLoading,
  } = useQueryGetSchedule(
    format(
      isSameDay(mySelectedDate, new Date())
        ? new Date()
        : new Date(mySelectedDate),
      'yyyy-MM-dd'
    ),
    clinicId,
    getToken
  )

  React.useEffect(() => {
    setSelectedDate(new Date())
  }, [])

  React.useEffect(() => {
    if (visits === undefined || visits === internalVisits) return
    setVisits([
      ...visits,
      ...onlineAppointments.filter(
        (app) => app.status !== 4 && app.status !== 3
      ),
    ])
  }, [visits, mySelectedDate, clinicId, onlineAppointments])
  const [selectedProviders, setSelectedProviders] = React.useState<
    AccountUser[]
  >(JSON.parse(localStorage.getItem('selectedProviders')!) ?? [])
  const [selectedRooms, setSelectedRooms] = React.useState<Room[]>(
    JSON.parse(localStorage.getItem('selectedRooms')!) ?? []
  )
  const [filterValue, setFilterValue] = React.useState<string>(
    localStorage.getItem('filterValue') ?? 'rooms'
  )
  const [currentVisit, setCurrentVisit] = React.useState<Visit>()
  const [queueList, setQueueList] = React.useState<
    Array<Visit | OnlineAppointment>
  >([])
  const updateVisitMutation = useMutateUpdateVisit(getToken)
  const deleteVisitMutation = useMutateDeleteVisit(getToken)

  React.useEffect(() => {
    if (clinics?.length === 0) {
      getClinics().catch((err: any) => {
        throw err
      })
    }
  }, [])

  React.useEffect(() => {
    localStorage.setItem('filterValue', filterValue)
  }, [filterValue])
  React.useEffect(() => {
    if (selectedRooms.length === 0) return
    localStorage.setItem('selectedRooms', JSON.stringify(selectedRooms))
  }, [selectedRooms])
  React.useEffect(() => {
    if (selectedProviders.length === 0) return
    localStorage.setItem(
      'selectedProviders',
      JSON.stringify(selectedProviders)
    )
  }, [selectedProviders])

  React.useEffect(() => {
    const filteredVisits =
      visits
        ?.filter((visit) =>
          filterValue === 'rooms'
            ? 'roomId' in visit && visit.roomId === null
            : 'providerAccountUserId' in visit &&
              visit.providerAccountUserId === null
        )
        .filter(
          (visit) => visit.visitStatusId !== 6 && visit.visitStatusId !== 9
        ) || []

    const filteredOnlineAppointments =
      filterValue === 'rooms'
        ? onlineAppointments.filter(
            (app) => app.roomId === null && app.status !== 4
          )
        : onlineAppointments.filter(
            (app) => app.status !== 4 && app.providerId === null
          )
    setQueueList([...filteredVisits, ...filteredOnlineAppointments])
  }, [visits, onlineAppointments, mySelectedDate, filterValue])

  const [currentView, setCurrentView] = React.useState<ViewType>(
    ViewType.DAY_VIEW
  )

  React.useEffect(() => {
    if (clinicId === 0) return
    if (clinicId === parseInt(localStorage.getItem('clinicId') ?? '')) return
    setSelectedRooms([])
  }, [clinicId])

  const resizeVisit = async (event: MbscCalendarEvent): Promise<void> => {
    // Parse the event title to determine the appointment type
    const appointment = JSON.parse(event.title!) as Visit | OnlineAppointment
    if (
      'appointmentStartDate' in appointment &&
      'appointmentEndDate' in appointment
    ) {
      appointment.appointmentStartDate = formatInUTC(event.start as string)
      appointment.appointmentEndDate = formatInUTC(event.end as string)
    }
    if ('scheduleStart' in appointment && 'scheduleEnd' in appointment) {
      appointment.scheduleStart = formatInUTC(event.start as string)
      appointment.scheduleEnd = formatInUTC(event.end as string)
    }
    // Based on the filterValue, update roomId or providerId/providerAccountUserId
    if (filterValue === 'rooms') {
      if ('roomId' in appointment) {
        appointment.roomId = event.resource as number
      }
    } else if (filterValue === 'providers') {
      if ('providerAccountUserId' in appointment) {
        appointment.providerAccountUserId = parseInt(event.resource as string)
      } else if ('providerId' in appointment) {
        appointment.providerId = parseInt(event.resource as string)
      }
    }
    // Reset properties if it's a Visit type
    if ('patient' in appointment) {
      appointment.patient = undefined
    }
    if ('chiefComplaint' in appointment) {
      appointment.chiefComplaint = undefined
    }
    // Update the appointment using a common update function or API endpoint
    // You'll need to ensure that updateVisit can handle all types (Visit, OnlineAppointment, RecurringAppointment)
    if ('chiefComplaint' in appointment) {
      await updateVisit(appointment?.id ?? 0, appointment, getToken)
      await loadVisits()
    } else {
      await updateRecurringAppointment(
        appointment?.id ?? 0,
        appointment,
        getToken
      )
      await loadOnlineAppointments()
    }
  }
  const scheduleVisit = async (event: MbscCalendarEvent): Promise<void> => {
    const appointment = JSON.parse(event.title!) as Visit | OnlineAppointment
    const updateAppointment = (appt: typeof appointment): void => {
      const resource = parseInt(event.resource as string)
      if (filterValue === 'rooms' && 'roomId' in appt) {
        appt.roomId = resource
      } else if (filterValue === 'providers') {
        if ('providerAccountUserId' in appt) {
          appt.providerAccountUserId = resource
        } else if ('providerId' in appt) {
          appt.providerId = resource
        }
      }

      if ('scheduleStart' in appt && 'scheduleEnd' in appt) {
        appt.scheduleStart = formatInUTC(event.start as string)
        appt.scheduleEnd = formatInUTC(event.end as string)
      }
      if ('appointmentStartDate' in appt && 'appointmentEndDate' in appt) {
        appt.appointmentStartDate = formatInUTC(event.start as string)
        appt.appointmentEndDate = formatInUTC(event.end as string)
      }
      if ('patient' in appt) {
        appt.patient = undefined
      }
      if ('chiefComplaint' in appt) {
        appt.chiefComplaint = undefined
      }
    }

    updateAppointment(appointment)

    // Here, decide which update function to call based on the type of appointment
    if ('chiefComplaint' in appointment) {
      await updateVisit(appointment?.id ?? 0, appointment, getToken)
      await loadVisits()
    } else {
      // You may need to adjust this if there are different update functions for each type
      await updateRecurringAppointment(
        appointment?.id ?? 0,
        appointment,
        getToken
      )
      await loadOnlineAppointments()
    }
  }

  const moveBackToQueue = async (
    appointment: Visit | OnlineAppointment,
    roomMode: boolean
  ): Promise<void> => {
    if (roomMode && 'roomId' in appointment) {
      appointment.roomId = null
    } else if (!roomMode && 'providerAccountUserId' in appointment) {
      appointment.providerAccountUserId = null
    } else if (!roomMode && 'providerId' in appointment) {
      appointment.providerId = null
    }
    if ('chiefComplaint' in appointment) {
      appointment.chiefComplaint = undefined
    }
    if ('patient' in appointment) {
      appointment.patient = undefined
    }

    // Here, decide which update function to call based on the type of appointment
    if ('chiefComplaint' in appointment) {
      await updateVisitMutation.mutateAsync({
        visitId: appointment?.id ?? 0,
        visit: appointment,
      })
      await loadVisits()
    } else {
      // You may need to adjust this if there are different update functions for each type
      await updateRecurringAppointment(
        appointment?.id ?? 0,
        appointment,
        getToken
      )
      await loadOnlineAppointments()
    }
  }
  React.useEffect(() => {
    if (clinicId === 0) return
    loadVisits().catch((error) => {
      throw error
    })
    reloadDailyPatientSummary().catch((error) => {
      throw error
    })
    loadOnlineAppointments().catch((error) => {
      throw error
    })
  }, [mySelectedDate, clinicId])

  React.useEffect(() => {
    if (
      dailyPatientSummary?.averageCurrentPatientTimeInClinic !== undefined &&
      dailyPatientSummary?.averageCurrentPatientTimeInClinic >= 0 &&
      dailyPatientSummary?.averageCurrentPatientTimeInClinic !== averageTime
    ) {
      setAverageTime(
        Math.floor(dailyPatientSummary?.averageCurrentPatientTimeInClinic)
      )
    }
  }, [dailyPatientSummary?.averageCurrentPatientTimeInClinic, mySelectedDate])

  const onEventCreated = React.useCallback(
    async (args: any, inst: any) => {
      try {
        if (args.type === 'onEventUpdated') {
          await resizeVisit(args.event)
        } else if (args.event.title === 'New event') {
          await onEventClick(args)
        } else {
          await scheduleVisit(args.event)
        }
      } catch (error) {
        console.error('Error handling event:', error)
      }
    },
    [filterValue]
  )

  const onEventClick = React.useCallback(
    async (args: any): Promise<void> => {
      const event = args.event
      setEdit(true)
      setAnchor(args.target)
      setOriginalRecurringEvent({})
      loadPopupForm(event)
      setOpen(true)
    },
    [loadPopupForm]
  )

  const onOpenChart = React.useCallback((args: any) => {
    window.location.href = `/charting/${
      (args.id as number) ?? 0
    }?previousNavOpen=true`
    localStorage.setItem('past-chart', args.id)
  }, [])
  const onProfileView = React.useCallback((args: any) => {
    window.location.href = `/profile/${
      (args.patientId as number) ?? 0
    }?previousNavOpen=true`
  }, [])
  const onMoveBackToQueue = React.useCallback(
    (args: any) => {
      const visit: any = args
      if (isBefore(new Date(visit.scheduleStart), startOfDay(new Date()))) {
        return
      }
      moveBackToQueue(visit, filterValue === 'rooms').catch((error) => {
        throw error
      })
    },
    [filterValue]
  )
  const onDeleteVisit = React.useCallback(async (args: any) => {
    setCurrentVisit(args)
    setOpenDialog(true)
  }, [])
  const handleClose = (): void => {
    setOpenDialog(false)
  }
  const handleDelete = (): void => {
    deleteVisitMutation
      .mutateAsync({ visitId: currentVisit?.id! })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        setOpenDialog(false)
        loadVisits()
          .catch((error) => {
            throw error
          })
          .finally(() => {
            setVisits([...internalVisits, ...onlineAppointments])
          })
      })
  }
  const eventUpdateFail = React.useCallback(() => {
    addToast({
      status: 'error',
      message: "Can't create event on this date",
    })
  }, [])
  const theme = useTheme()
  const providers = React.useMemo(() => {
    return clinicians?.filter((clinician) => !isEmpty(clinician.npiNumber))
  }, [clinicians])

  const buttons = React.useMemo(() => {
    return (
      <ButtonGroup aria-label="outlined primary button group">
        <StyledButton
          selected={currentView === ViewType.DAY_VIEW}
          onClick={() => setCurrentView(ViewType.DAY_VIEW)}
        >
          Day
        </StyledButton>
        <StyledButton
          selected={currentView === ViewType.WEEKLY_VIEW}
          onClick={() => setCurrentView(ViewType.WEEKLY_VIEW)}
        >
          Week
        </StyledButton>
        <StyledButton
          selected={currentView === ViewType.MONTHLY_VIEW}
          onClick={() => setCurrentView(ViewType.MONTHLY_VIEW)}
        >
          Month
        </StyledButton>
      </ButtonGroup>
    )
  }, [currentView])

  if (rooms?.length === 0 || providers?.length === 0) {
    return (
      <SchedulingContainer>
        <TitleBar
          pageTitle="Scheduling"
          isNavOpen={isNavOpen}
          setIsNavOpen={setIsNavOpen}
        />
        <SchedulingContent>
          <NavColumn selectedIndex={4} isNavOpen={isNavOpen}></NavColumn>
          <SchedulingContentRightPane
            isNavOpen={isNavOpen}
            theme={theme}
            isMainView
          >
            <Box
              alignItems="center"
              justifyContent="center"
              display="flex"
              minHeight="100vh"
            >
              Please create a{rooms?.length === 0 ? ' room' : ' provider'}
            </Box>
          </SchedulingContentRightPane>
        </SchedulingContent>
      </SchedulingContainer>
    )
  }
  const calendar = React.useMemo(() => {
    return (
      <RoomsCalendar
        dailySchedule={internalVisits}
        rooms={
          selectedRooms.length > 0
            ? selectedRooms
            : rooms?.filter((room) => room.clinicId === clinicId) ?? []
        }
        clinicians={
          clinicians?.filter((clinician) => !isEmpty(clinician.npiNumber))!
        }
        providers={selectedProviders}
        complaints={complaints!}
        visitTypes={visitTypes!}
        groupBy={filterValue}
        selectedDate={
          isSameDay(mySelectedDate, new Date())
            ? new Date()
            : new Date(format(mySelectedDate, 'MM/dd/yyyy'))
        }
        onEventCreated={onEventCreated}
        eventUpdateFail={eventUpdateFail}
        onMoveBackToQueue={onMoveBackToQueue}
        onOpenChart={onOpenChart}
        onProfileView={onProfileView}
        onDeleteVisit={onDeleteVisit}
        refresh={() => {
          Promise.resolve(loadOnlineAppointments()).catch((err) => {
            throw err
          })
          Promise.resolve(loadVisits()).catch((err) => {
            throw err
          })
        }}
        isNavOpen={isNavOpen}
      />
    )
  }, [
    internalVisits,
    rooms,
    providers,
    filterValue,
    mySelectedDate,
    selectedRooms,
    selectedProviders,
    clinicId,
    clinicians,
    complaints,
    visitTypes,
    onEventCreated,
    eventUpdateFail,
    onMoveBackToQueue,
    onOpenChart,
    onProfileView,
    onDeleteVisit,
  ])

  const appointmentsCalendar = React.useMemo(() => {
    return (
      <AppointmentsCalendar
        providers={selectedProviders}
        currentView={currentView}
      />
    )
  }, [
    internalVisits,
    providers,
    filterValue,
    selectedProviders,
    currentView,
    clinicId,
    mySelectedDate,
  ])

  const queue = React.useMemo(() => {
    return (
      <PatientQueue
        visits={queueList ?? []}
        complaints={complaints!}
        refresh={(id: number) =>
          setQueueList(queueList?.filter((v) => v.id !== id))
        }
        isNavOpen={isNavOpen}
      />
    )
  }, [queueList, filterValue, complaints, isNavOpen])

  const AverageTimeBox = React.useMemo(() => {
    return <ScheduleStatValue>{averageTime} Minutes</ScheduleStatValue>
  }, [averageTime])

  const filter = React.useMemo(() => {
    return (
      <RoomsCalendarFilter
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        setSelectRooms={setSelectedRooms}
        setSelectProviders={setSelectedProviders}
        selectProviders={selectedProviders}
        selectRooms={selectedRooms}
        providers={providers ?? []}
        rooms={rooms?.filter((room) => room.clinicId === clinicId) ?? []}
        viewType={currentView}
      />
    )
  }, [
    filterValue,
    setFilterValue,
    setSelectedRooms,
    setSelectedProviders,
    selectedProviders,
    selectedRooms,
    providers,
    rooms,
    clinicId,
  ])

  return (
    <SchedulingContainer>
      <TitleBar
        pageTitle="Scheduling"
        isNavOpen={isNavOpen}
        setIsNavOpen={setIsNavOpen}
      />
      {(isLoading || isAppointmentLoading) && (
        <Box sx={{ position: 'absolute', left: '0', right: 0, top: 60 }}>
          <LinearProgress />
        </Box>
      )}
      <SchedulingContent>
        {currentView === ViewType.DAY_VIEW ? (
          <NavColumn selectedIndex={4} isNavOpen={isNavOpen}>
            {queue}
          </NavColumn>
        ) : (
          <NavColumn selectedIndex={4} isNavOpen={isNavOpen}></NavColumn>
        )}
        <SchedulingContentRightPane
          isNavOpen={isNavOpen}
          theme={theme}
          isMainView={currentView === ViewType.DAY_VIEW}
        >
          <ScheduleStats>
            <ScheduleStatTile>
              <ScheduleStatTitle>Average Time Since Arrival</ScheduleStatTitle>
              <ScheduleStatValueTile>{AverageTimeBox}</ScheduleStatValueTile>
            </ScheduleStatTile>
          </ScheduleStats>
          {currentView === ViewType.DAY_VIEW ? (
            <>
              <FilterRow>
                <Box sx={{ ml: '-4px' }}>
                  <DateSelector
                    selectedDate={mySelectedDate}
                    setSelectedDate={setSelectedDate}
                  />
                </Box>
                <Stack
                  justifyContent={'flex-end'}
                  width={'100%'}
                  direction={'row'}
                  padding={'7px'}
                  gap={4}
                >
                  {buttons}
                  <FilterDropDowns>{filter}</FilterDropDowns>
                </Stack>
              </FilterRow>
              {calendar}
            </>
          ) : (
            <Stack flexDirection={'column'} height={'100%'}>
              <Stack
                justifyContent={'flex-end'}
                width={'100%'}
                direction={'row'}
                padding={'7px'}
                gap={4}
              >
                {buttons}
                <FilterDropDowns>{filter}</FilterDropDowns>
              </Stack>
              {appointmentsCalendar}
            </Stack>
          )}
        </SchedulingContentRightPane>
      </SchedulingContent>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: '12px' }}
      >
        <DeleteVisitPopUp handleDelete={handleDelete} setClose={handleClose} />
      </Dialog>
    </SchedulingContainer>
  )
}

export default Scheduling
