import React from 'react'
import { type FieldValues, type UseFormRegister } from 'react-hook-form'
import {
  FreeTextNotesContainer,
  StyledTextButton,
  FreeTextNotesRow,
  StyleInputLabel,
} from './styles'

import { TileTextArea } from '@/components'
import { isEmpty } from '@/utility/utils'

export const FreeTextNotes = ({
  sectionName,
  notes,
  register,
  setNotes,
  expanded = false,
  setExpanded = null,
}: FreeTextNotesProps): JSX.Element => {
  const [notesVisibile, setNotesVisible] = React.useState<boolean>(expanded)

  const handleCancel = (): void => {
    if (setExpanded !== null) {
      setExpanded(false)
    }
    setNotes('')
    setNotesVisible(false)
  }

  React.useEffect(() => {
    if (setExpanded !== null) {
      setExpanded(notesVisibile)
    }
  }, [notesVisibile])

  React.useEffect(() => {
    if (!isEmpty(notes)) {
      setNotesVisible(true)
    }
  })

  return (
    <FreeTextNotesContainer>
      {!notesVisibile && (
        <StyledTextButton
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            textAlign: 'left',
            textDecorationLine: 'underline',
            color: '#0543B0',
          }}
          variant="text"
          onClick={() => setNotesVisible(true)}
        >
          Add Note
        </StyledTextButton>
      )}
      {notesVisibile && (
        <div>
          <FreeTextNotesRow>
            <StyleInputLabel>Note</StyleInputLabel>
            <StyledTextButton
              size="small"
              variant="text"
              sx={{
                color: 'rgb(13, 71, 173)',
              }}
              onClick={handleCancel}
            >
              Cancel Note
            </StyledTextButton>
          </FreeTextNotesRow>
          <TileTextArea
            value={notes}
            onChange={(e: any) => setNotes(e.target.value)}
            fullsize
            note
            dataTestId={sectionName}
          />
        </div>
      )}
    </FreeTextNotesContainer>
  )
}

interface FreeTextNotesProps {
  sectionName: string;
  register?: UseFormRegister<FieldValues> | undefined;
  notes: string;
  setNotes: any;
  expanded?: boolean;
  setExpanded?: any;
}
