import { useQueryGetTaskTypes } from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { MenuItem } from '@mui/material'
import { convertToSpaceSeparated } from '@/utility'
import React from 'react'
import { useMutateUpdateTask } from '@/hook/useTasks'
import { StyledSelect } from '../..'

export const DetailsType = ({
  taskId,
  taskTypeId,
}: DetailsTypeProps): JSX.Element => {
  const { getToken } = useAuth()
  const { data: userTaskTypes } = useQueryGetTaskTypes(getToken)
  const updateTaskMutation = useMutateUpdateTask(getToken)

  return (
    <StyledSelect
      value={taskTypeId}
      onChange={(e) => {
        void updateTaskMutation.mutateAsync({
          id: taskId,
          task: { typeId: e.target.value as number },
        })
      }}
    >
      {userTaskTypes?.map(({ id, name }) => (
        <MenuItem data-testid={`type-${id ?? ''}`} key={id} value={id}>
          {convertToSpaceSeparated(name ?? '')}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

interface DetailsTypeProps {
  taskId: number;
  taskTypeId: number;
}
