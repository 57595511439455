import React from 'react'
import { Drawer, Select, TextField, Typography, styled } from '@mui/material'
import { type AccountUser } from '@/types'
import { formatDateOnly, getFullName } from '@/utility'
import { TaskVisitSection } from './components/TaskVisitSection'
import { AttachmentDrawerSection } from './components/AttachmentDrawerSection'
import {
  TaskDataItemGroup,
  type TaskDataItemProps,
} from './components/TaskDataItems/TaskDataItemGroup'
import { DetailsTitle } from './components/TaskDataItems/DetailsTitle'
import { DetailsType } from './components/TaskDataItems/DetailsType'
import { PatientDataItem } from './components/TaskDataItems/PatientDataItem'
import { DetailsPriority } from './components/TaskDataItems/DetailsPriority'
import { DetailsClinic } from './components/TaskDataItems/DetailsClinic'
import { AssignedData } from './components/TaskDataItems/AssignedData'
import { CompletedData } from './components/TaskDataItems/CompletedData'
import { TaskNotes } from './components/TaskDataItems/TaskNotes'
import { useMutateUpdateTask, useTasksStore } from '@/hook/useTasks'
import { useAuth } from '@clerk/nextjs'
import { AssigneeDataItem } from './components/AssigneeDataItem'
import { useAccountUserStore } from '@/hook'

const DrawerContainer = styled('div')({
  width: '610px',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  overflow: 'scroll',
  alignItems: 'center',
  paddingBottom: '40px',
  padding: '30px',
  gap: '10px',
})

const DrawerTitle = styled(Typography)({
  width: '100%',
  fontSize: '20px',
  lineHeight: '20px',
})

export const InputRow = styled('div')({
  display: 'flex',
  width: '100%',
  gap: '10px',
})

export const InputGroup = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  flex: 1,
})

export const StyledTextField = styled(TextField)({
  borderRadius: '4px',
  height: '36px',
  outline: 'none',
  color: '#000000DE',
})

export const StyledSelect = styled(Select)({
  borderRadius: '4px',
  height: '36px',
  outline: 'none',
  color: '#000000DE',
})

export const TaskDrawer = ({
  open,
  onClose,
  taskId
}: TaskDrawerProps): JSX.Element | null => {
  const { taskPage } = useTasksStore()
  const { getToken } = useAuth()
  const { accountUsers: users } = useAccountUserStore()
  const updateTaskMutation = useMutateUpdateTask(getToken)
  const task = taskPage?.items?.find(({ id }) => id === taskId)

  if (!task) {
    return null
  }

  const taskDetails: TaskDataItemProps[][] = [
    [
      {
        label: 'Title',
        render: () => (
          <DetailsTitle taskId={task?.id ?? -1} taskTitle={task?.title ?? ''} />
        ),
      },
    ],
    [
      {
        label: 'Assignee',
        render: () => <AssigneeDataItem taskId={task?.id ?? -1} />,
      },
    ],
    [
      {
        label: 'Type',
        render: () => (
          <DetailsType
            taskId={task?.id ?? -1}
            taskTypeId={task?.typeId ?? -1}
          />
        ),
      },
      {
        label: 'Priority',
        render: () => (
          <DetailsPriority
            taskId={task?.id ?? -1}
            taskPriorityId={task?.priorityId ?? -1}
          />
        ),
      },
    ],
    [
      {
        label: 'Clinic',
        render: () => (
          <DetailsClinic
            taskId={task?.id ?? -1}
            taskClinicId={task?.clinicId ?? -1}
          />
        ),
      },
    ],
    [
      {
        label: 'Patient',
        render: () => (
          <PatientDataItem
            taskId={task?.id ?? -1}
            patientId={task?.patientId ?? -1}
            onPatientSelect={(patientId: number | null) => {
              if (patientId && task?.id) {
                void updateTaskMutation.mutateAsync({
                  id: task.id,
                  task: { ...task, patientId },
                })
              }
            }}
          />
        ),
      },
    ],
  ]

  const taskVisit: TaskDataItemProps[][] = [
    [
      {
        label: 'Visit',
        render: () => {
          return <TaskVisitSection visitId={task?.visitId ?? -1} />
        },
      },
    ],
  ]

  const taskAdditional: TaskDataItemProps[][] = [
    [
      {
        label: 'Created By',
        render: () => (
          <StyledTextField
            value={getFullName(
              users.find(({ id }) => id === task?.createdById)
            )}
            disabled
          />
        ),
      },
    ],
    [
      {
        // Seems like this shouldn't be editable, but just reflect whenever the task's assignee was last updated
        label: 'Assigned Date',
        render: () => (
          <AssignedData
            taskId={task?.id ?? -1}
            assignedOn={formatDateOnly(task?.dateAssigned)}
          />
        ),
      },
    ],
    [
      {
        // Also seems like it should be uneditable
        label: 'Completed Date',
        render: () => (
          <CompletedData
            taskId={task?.id ?? -1}
            completedOn={formatDateOnly(task?.dateCompleted)}
          />
        ),
      },
    ], // Should this section include the assignee?
  ]

  const taskNotes: TaskDataItemProps[][] = [
    [
      {
        label: 'Add any specific notes here',
        render: () => (
          <TaskNotes taskId={task?.id ?? -1} notes={task?.notes ?? ''} />
        ),
      },
    ],
  ]

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <DrawerContainer>
        <DrawerTitle>Edit Task</DrawerTitle>
        <TaskDataItemGroup itemProps={taskDetails} groupTitle="Task Details" />
        <TaskDataItemGroup itemProps={taskVisit} groupTitle="Task Visit" />
        <TaskDataItemGroup
          itemProps={taskAdditional}
          groupTitle="Additional Details"
        />
        <TaskDataItemGroup itemProps={taskNotes} groupTitle="Notes" />
        <AttachmentDrawerSection taskId={task?.id} />
      </DrawerContainer>
    </Drawer>
  )
}

interface TaskDrawerProps {
  open: boolean;
  onClose: any;
  taskId: number;
  users?: AccountUser[];
  patientId?: number;
}
