import React from 'react'
import { UnifiedTable } from '@/components/UnifiedTable'
import { useGetAppointmentColumns } from '@/components/Columns'
import { type Patient, type RecurringAppointment } from '@/types'
import router from 'next/router'
import { useMutateUpdateAppointmentStatus, useTableTheme, useQueryGetAccountUsers } from '@/hook'
import {
  Dialog,
  ThemeProvider,
  type Theme,
  StyledEngineProvider,
} from '@mui/material'
import { useAuth } from '@clerk/nextjs'
import { parseTableDataFromAppointment } from '@/utility/utils'
import { isBefore, parseISO, startOfToday } from 'date-fns'
import { DeleteVisitPopUp } from '@/components/DeleteVisitPopUp'
import { updateRecurringAppointment } from '@/services'
import {
  usePatientAppointmentStore,
  useQueryGetPatientAppointments,
} from '@/hook/usePatientAppointments'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const UpcomingVisits = ({
  patient,
  previousNavOpen,
  tableInstanceRef,
  isColumnFixed,
  pinnedColumn,
  selectedTab,
  tabList,
  pagination,
  handleDoubleClickTable,
  handleChangeTab,
  tabsInfo,
  filterList,
  handleRemoveFilter,
  setPagination,
}: UpcomingVisitsProps): JSX.Element => {
  const { getToken } = useAuth()
  const [appointmentId, setAppointmentId] = React.useState<number>(0)
  const [isParsing, setIsParsing] = React.useState<boolean>(false)
  const { data: clinicians } = useQueryGetAccountUsers(getToken)
  const [open, setOpen] = React.useState<boolean>(false)
  const theme = useTableTheme()
  const { isLoading, isFetching, isRefetching, refetch } =
    useQueryGetPatientAppointments(patient.id ?? -1, getToken)
  const { patientAppointments } = usePatientAppointmentStore()

  const [parsedAppointments, setParsedAppointments] = React.useState<any>([])

  const updateAppointmentStatus = useMutateUpdateAppointmentStatus(getToken)

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      const data = await parseTableDataFromAppointment(
        patientAppointments.get(patient.id ?? -1),
        getToken,
        true
      )
      setParsedAppointments(data)
    }

    fetchData()
      .catch(console.error)
      .finally(() => {
        setIsParsing(false)
      })
  }, [patient, patientAppointments.get(patient.id ?? -1)])

  const goToIntake = (data: any) => (): void => {
    const queryParams = [
      previousNavOpen && 'previousNavOpen=true',
      data?.patientDetails?.firstName &&
        `firstName=${data?.patientDetails?.firstName as string}`,
      data?.patientDetails?.lastName &&
        `lastName=${data?.patientDetails?.lastName as string}`,
      data?.phone && `phone=${data?.phone as string}`,
      data?.email && `email=${data?.email as string}`,
      data?.patientDetails?.dateOfBirth &&
        `dateOfBirth=${JSON.stringify(data?.patientDetails?.dateOfBirth)}`,
      data?.id && `appointmentId=${data?.id as number}`,
      data?.providerId && `providerId=${data?.providerId as number}`,
      data?.roomId && `roomId=${data?.roomId as string}`,
      data?.visitTypeId && `visitTypeId=${data?.visitTypeId as string}`,
      data?.appointmentEndDate &&
        `appointmentEndDate=${new Date(
          data?.appointmentEndDate
        ).toISOString()}`,
      data?.appointmentStartDate &&
        `appointmentStartDate=${new Date(
          data?.appointmentStartDate
        ).toISOString()}`,
      data?.visitTypeCategoryId &&
        `visitTypeCategoryId=${data?.visitTypeCategoryId as string}`,
    ]
      .filter(Boolean)
      .join('&')

    const urlString = `/intake?${queryParams}`
    router.push(urlString).catch(console.error)
  }

  const columns = useGetAppointmentColumns(
    goToIntake,
    setAppointmentId,
    setOpen,
    updateAppointmentStatus,
    refetch,
    clinicians ?? [],
    theme
  ).filter(
    (column) =>
      column.id !== 'patient_details' &&
      column.id !== 'phone' &&
      column.id !== 'email'
  )

  const filteredTableData = parsedAppointments
    .filter((appointment: RecurringAppointment) => {
      const today = startOfToday()
      const appointmentStartDate = parseISO(appointment.appointmentStartDate!)
      return !isBefore(appointmentStartDate, today)
    })
    .filter((appointment: RecurringAppointment) => {
      return appointment.status !== 4 && appointment.status !== 3
    })

  const onDeleteAppointment = (): void => {
    if (appointmentId === undefined) {
      return
    }
    const onlineAppointment = patientAppointments
      .get(patient.id ?? -1)
      ?.find(({ id }) => id === appointmentId)
    if (onlineAppointment === undefined) {
      return
    }
    const newOnlineAppointment: RecurringAppointment = {
      ...onlineAppointment,
      status: 3,
      statusString: 'Cancelled',
    }
    updateRecurringAppointment(appointmentId, newOnlineAppointment, getToken)
      .catch((error) => {
        throw error
      })
      .finally(() => {
        refetch().catch((error) => {
          throw error
        })
      })
    setOpen(false)
  }

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <UnifiedTable
            columns={columns}
            filteredTableData={filteredTableData}
            tableInstanceRef={tableInstanceRef}
            isTableLoading={
              isRefetching || isLoading || isFetching || isParsing
            }
            isColumnFixed={isColumnFixed}
            pinnedColumn={pinnedColumn}
            selectedTab={selectedTab}
            tabList={tabList}
            pagination={pagination}
            handleDoubleClickTable={handleDoubleClickTable}
            handleChangeTab={handleChangeTab}
            tabsInfo={tabsInfo}
            filterList={filterList}
            handleRemoveFilter={handleRemoveFilter}
            setPagination={setPagination}
            enableTopToolbar={false}
            enableColumnResizing
          />
        </ThemeProvider>
      </StyledEngineProvider>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: '12px' }}
      >
        <DeleteVisitPopUp
          handleDelete={onDeleteAppointment}
          setClose={() => setOpen(false)}
        />
      </Dialog>
    </>
  )
}

interface UpcomingVisitsProps {
  patient: Patient;
  previousNavOpen: boolean;
  tableInstanceRef: any;
  filteredAppointments: any;
  isColumnFixed: boolean;
  pinnedColumn: string[];
  selectedTab: string;
  tabList: string[];
  pagination: any;
  handleDoubleClickTable: (data: any) => void;
  handleChangeTab: (event: React.ChangeEvent<{}>, newValue: number) => void;
  tabsInfo: any;
  filterList: any;
  handleRemoveFilter: (id: string) => void;
  setPagination: (page: number, pageSize: number) => void;
}
