export const CalendarColors = [
  { body: '#EEEEEE', highline: '#757575' },
  { highline: '#E53935', body: '#EF9A9A' },
  { highline: '#43A047', body: '#A5D6A7' },
  { highline: '#1E88E5', body: '#90CAF9' },
  { highline: '#FB8C00', body: '#FFCC80' },
  { highline: '#00897B', body: '#80CBC4' },
  { highline: '#8E24AA', body: '#CE93D8' },
  { highline: '#FDD835', body: '#FFF59D' },
  { highline: '#E6EE9C', body: '#C0CA33' },
  { highline: '#6D4C41', body: '#BCAAA4' },
  { highline: '#3949AB', body: '#9FA8DA' },
  { highline: '#FF4500', body: '#FFA07A' },
  { highline: '#4169E1', body: '#87CEFA' },
  { highline: '#8B4513', body: '#DEB887' },
  { highline: '#20B2AA', body: '#66CDAA' },
  { highline: '#4682B4', body: '#B0E0E6' },
  { highline: '#B22222', body: '#FF7F50' },
  { highline: '#228B22', body: '#98FB98' },
  { highline: '#9370DB', body: '#E6E6FA' },
  { highline: '#FF1493', body: '#FFB6C1' },
  { highline: '#FFD700', body: '#FFFACD' },
  { highline: '#FF6347', body: '#FFA07A' },
  { highline: '#00FF7F', body: '#98FB98' },
  { highline: '#00FFFF', body: '#AFEEEE' },
  { highline: '#FF00FF', body: '#DA70D6' },
  { highline: '#FF69B4', body: '#FFC0CB' },
  { highline: '#7CFC00', body: '#ADFF2F' },
  { highline: '#00FF00', body: '#7FFF00' },
  { highline: '#FF4500', body: '#FF6347' },
  { highline: '#FFD700', body: '#FFEC8B' },
  { highline: '#FF6347', body: '#FFA07A' },
  { highline: '#00FF7F', body: '#98FB98' },
  { highline: '#00FFFF', body: '#AFEEEE' },
  { highline: '#FF00FF', body: '#DA70D6' },
  { highline: '#FF69B4', body: '#FFC0CB' },
  { highline: '#7CFC00', body: '#ADFF2F' },
  { highline: '#00FF00', body: '#7FFF00' },
  { highline: '#FF4500', body: '#FF6347' },
  { highline: '#FFD700', body: '#FFEC8B' },
  { highline: '#FF6347', body: '#FFA07A' },
  { highline: '#00FF7F', body: '#98FB98' },
  { highline: '#00FFFF', body: '#AFEEEE' },
  { highline: '#FF00FF', body: '#DA70D6' },
  { highline: '#FF69B4', body: '#FFC0CB' },
  { highline: '#7CFC00', body: '#ADFF2F' },
  { highline: '#00FF00', body: '#7FFF00' },
  { highline: '#FF4500', body: '#FF6347' },
  { highline: '#FFD700', body: '#FFEC8B' },
  { highline: '#FF6347', body: '#FFA07A' },
  { highline: '#00FF7F', body: '#98FB98' },
  { highline: '#00FFFF', body: '#AFEEEE' },
  { highline: '#FF00FF', body: '#DA70D6' },
  { highline: '#FF69B4', body: '#FFC0CB' },
  { highline: '#7CFC00', body: '#ADFF2F' },
  { highline: '#00FF00', body: '#7FFF00' },
  { highline: '#FF4500', body: '#FF6347' },
  { highline: '#FFD700', body: '#FFEC8B' },
  { highline: '#FF6347', body: '#FFA07A' },
  { highline: '#00FF7F', body: '#98FB98' },
  { highline: '#00FFFF', body: '#AFEEEE' },
  { highline: '#FF00FF', body: '#DA70D6' },
  { highline: '#FF69B4', body: '#FFC0CB' },
  { highline: '#7CFC00', body: '#ADFF2F' },
  { highline: '#00FF00', body: '#7FFF00' },
  { highline: '#FF4500', body: '#FF6347' },
  { highline: '#FFD700', body: '#FFEC8B' },
  { highline: '#FF6347', body: '#FFA07A' },
  { highline: '#00FF7F', body: '#98FB98' },
  { highline: '#00FFFF', body: '#AFEEEE' },
  { highline: '#FF00FF', body: '#DA70D6' },
  { highline: '#FF69B4', body: '#FFC0CB' },
  { highline: '#7CFC00', body: '#ADFF2F' },
  { highline: '#00FF00', body: '#7FFF00' },
  { highline: '#FF4500', body: '#FF6347' },
]
