import { type UIQDocument } from '@/types'
import { getDocumentById } from '../services/DocumentService'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface DocumentState {
  documents: Map<number, UIQDocument>;
  fetchingIds: Set<number>;
  getDocument: (documentId: number, getToken: any) => UIQDocument | null;
  getMatchingDocuments: (
    matches: Partial<UIQDocument>,
    strict: boolean
  ) => UIQDocument[];
  _addDocumentToLookup: (document: UIQDocument) => void;
  _addDocumentToFetching: (documentId: number) => void;
  _removeDocumentFromFetching: (documentId: number) => void;
}

export const useDocument = create<DocumentState>()(
  devtools((set, get) => ({
    documents: new Map<number, UIQDocument>(),
    fetchingIds: new Set<number>(),
    getDocument: (documentId: number, getToken: any) => {
      const currentDocs = get().documents
      if (!currentDocs.has(documentId) && !get().fetchingIds.has(documentId)) {
        get()._addDocumentToFetching(documentId)
        getDocumentById(documentId, getToken)
          .then((document: UIQDocument) => {
            get()._addDocumentToLookup(document)
            get()._removeDocumentFromFetching(documentId)
          })
          .catch((error) => {
            throw error
          })
      }
      return currentDocs.get(documentId) ?? null
    },
    getMatchingDocuments: (matches: Partial<UIQDocument>, strict: boolean) => {
      const docs = Array.from(get().documents.values())
      const matchesKeys = Object.keys(matches)
      if (strict) {
        return docs.filter((document: UIQDocument) =>
          matchesKeys.every(
            (key) =>
              matches[key as keyof UIQDocument] ===
              document[key as keyof UIQDocument]
          )
        )
      } else {
        return docs.filter((document: UIQDocument) =>
          matchesKeys.some(
            (key) =>
              matches[key as keyof UIQDocument] ===
              document[key as keyof UIQDocument]
          )
        )
      }
    },
    _addDocumentToLookup: (document: UIQDocument) => {
      set((state) => {
        const updatedDocs = new Map(state.documents)
        updatedDocs.set(document.id, document)
        return {
          documents: updatedDocs,
        }
      })
    },
    _addDocumentToFetching: (documentId: number) => {
      set((state) => {
        const updatedIds = new Set(state.fetchingIds)
        updatedIds.add(documentId)
        return {
          fetchingIds: updatedIds,
        }
      })
    },
    _removeDocumentFromFetching: (documentId: number) => {
      set((state) => {
        const updatedIds = new Set(state.fetchingIds)
        updatedIds.delete(documentId)
        return {
          fetchingIds: updatedIds,
        }
      })
    },
  }))
)
