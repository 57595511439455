import {
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
  styled,
} from '@mui/material'
import React, { type ReactNode } from 'react'
import { TileInputLabel } from '../TileInputLabel'

const StyledSelected = styled(Select)(
  ({ fullsize, template }: StyledSelectProps) => ({
    width: fullsize ? '502px' : '240px',
    height: '40px !important',
    backgroundColor: 'white',
    paddingTop: '14px',
    borderRadius: 0,
    '& > svg': {
      marginTop: '-2px',
    },
    '& .MuiOutlinedInput-input': {
      marginTop: '-2px',
      height: '40px !important',
    },
    '& > *': {
      maxHeight: '40px !important',
    },
  })
)

export const TileSelect = ({
  label = null,
  value,
  onChange,
  options,
  fullsize = false,
  template = false,
  dataTestId,
  multiple = false,
}: TileSelectProps): JSX.Element => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}>
      {label !== null && <TileInputLabel>{label}</TileInputLabel>}
      <FormControl>
        <StyledSelected
          multiple={multiple}
          value={value}
          onChange={onChange}
          fullsize={fullsize}
          template={template}
          inputProps={{
            'data-testId': dataTestId,
          }}
        >
          {options.map((option: any) => {
            const isObject = typeof option === 'object'
            return (
              <MenuItem
                key={isObject ? option.id : option}
                value={isObject ? option.id : option}
                data-testid={`${dataTestId}-option-${
                  isObject ? (option.id as number) ?? 0 : (option as string)
                }`}
              >
                {isObject ? option.name : option}
              </MenuItem>
            )
          })}
        </StyledSelected>
      </FormControl>
    </div>
  )
}

interface TileSelectProps {
  label?: string | null;
  value: number | string;
  onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
  options: any;
  fullsize?: boolean;
  template?: boolean;
  dataTestId: string;
  multiple?: boolean;
}

interface StyledSelectProps {
  fullsize: boolean;
  template: boolean;
}
