import React from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Popover } from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'
import {
  RowTile,
  RowValue,
  AddColumn,
  AddRow,
  ImmunizationsHoverOver,
} from '@/components'
import { type PatientImmunization } from '@/types'
import { formatDateOnly } from '@/utility'

export const ImmunizationRow = ({
  immunization,
  immunizationName,
  delete: deleteDX,
  edit: editDX,
}: ImmunizationRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <ImmunizationsHoverOver
          name={immunizationName}
          date={formatDateOnly(immunization.date)}
        />
      </Popover>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={62}>
          <RowValue>{immunizationName}</RowValue>
        </AddColumn>
        <AddColumn width={30}>
          <RowTile>Date</RowTile>
          <RowValue>{formatDateOnly(immunization.date)}</RowValue>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => editDX(immunization)} size="large">
            <ModeIcon />
          </IconButton>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => deleteDX(immunization)} size="large">
            <DeleteIcon />
          </IconButton>
        </AddColumn>
      </AddRow>
    </>
  )
}
interface ImmunizationRowProps {
  immunization: PatientImmunization;
  immunizationName: string;
  delete: (immunization: PatientImmunization) => void;
  edit: (immunization: PatientImmunization) => void;
}
