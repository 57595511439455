import { useQueryGetTaskPriority } from '@/hook'
import { useMutateUpdateTask } from '@/hook/useTasks'
import { useAuth } from '@clerk/nextjs'
import { MenuItem } from '@mui/material'
import React from 'react'
import { StyledSelect } from '../..'

export const DetailsPriority = ({
  taskId,
  taskPriorityId,
}: DetailsPriorityProps): JSX.Element => {
  const { getToken } = useAuth()
  const updateTaskMutation = useMutateUpdateTask(getToken)
  const { data: userTaskPriority } = useQueryGetTaskPriority(getToken)

  return (
    <StyledSelect
      value={taskPriorityId}
      onChange={(e) => {
        void updateTaskMutation.mutateAsync({
          id: taskId,
          task: { priorityId: e.target.value as number },
        })
      }}
    >
      {userTaskPriority?.map(({ id, name }) => (
        <MenuItem data-testid={`priority-${id ?? ''}`} key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

interface DetailsPriorityProps {
  taskId: number;
  taskPriorityId: number;
}
