import React from 'react'
import { type Patient, type State } from '@/types'
import { Card, styled, Typography } from '@mui/material'
import { formatPhoneNumber } from 'react-phone-number-input'
import { formatDateOnly, isEmpty } from '@/utility'

const RegistrationConfirmationContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '100%',
  padding: '12px',
})
const RegistrationConfirmationRow = styled('div')({
  display: 'flex',
  padding: '12px',
  width: '100%',
})

export const CardTitle = styled('div')({
  fontSize: '28px',
  lineHeight: '44px',
  color: '#1e2342',
  marginBottom: '16px',
  alignItems: 'center',
  display: 'flex',
  fontWeight: 500,
})
const RegistrationConfirmationColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '33%',
  justifyContent: 'left',
})

const FieldName = styled(Typography)`
  font-weight: 600;
  font-size: 24px;
  color: ${({ theme }) => theme.palette.text.secondary};
`
const Title = styled(Typography)`
    font-weight: 800;
    font-size: 36px;
    color: ${({ theme }) => theme.palette.text.primary};ß
`

const FieldValue = styled(Typography)`
    minimumHeight: '24px'
    color: ${({ theme }) => theme.palette.text.primary};
    font-size: 24px;
`

const StyledCard = styled(Card)({
  padding: '12px',
  backgroundColor: '#EAF3FF',
})

const addSpaceBeforeCaps = (input: string): string => {
  return input.replace(/(?<!^)([A-Z])/g, ' $1')
}

export const RegistrationConfirmation = ({
  patient,
  states,
}: RegistrationConfirmationProps): JSX.Element => {
  return (
    <RegistrationConfirmationContainer>
      <Title>Please confirm the information below is correct</Title>
      <StyledCard key={'PatInfo'}>
        <CardTitle>Personal Information</CardTitle>
        <RegistrationConfirmationRow>
          <RegistrationConfirmationColumn>
            <FieldName>First Name</FieldName>
            <FieldValue>{patient.firstName ?? ''}</FieldValue>
          </RegistrationConfirmationColumn>
          <RegistrationConfirmationColumn>
            <FieldName>Last Name</FieldName>
            <FieldValue>{patient.lastName ?? ''}</FieldValue>
          </RegistrationConfirmationColumn>
          <RegistrationConfirmationColumn>
            <FieldName>Date of Birth</FieldName>
            <FieldValue>{formatDateOnly(patient.dateOfBirth)}</FieldValue>
          </RegistrationConfirmationColumn>
        </RegistrationConfirmationRow>
      </StyledCard>
      {!(
        isEmpty(patient.race) &&
        isEmpty(patient.sex) &&
        isEmpty(patient.socialSecurityNumber) &&
        isEmpty(patient.ethnicity)
      ) && (
        <StyledCard key={'demo'}>
          <CardTitle>Demographics</CardTitle>
          <RegistrationConfirmationRow>
            {!isEmpty(patient.race) && (
              <RegistrationConfirmationColumn>
                <FieldName>Race</FieldName>
                <FieldValue>
                  {addSpaceBeforeCaps(patient.race ?? '')}
                </FieldValue>
              </RegistrationConfirmationColumn>
            )}
            {!isEmpty(patient.sex) && (
              <RegistrationConfirmationColumn>
                <FieldName>Sex</FieldName>
                <FieldValue>{addSpaceBeforeCaps(patient.sex ?? '')}</FieldValue>
              </RegistrationConfirmationColumn>
            )}
            {!isEmpty(patient.socialSecurityNumber) && (
              <RegistrationConfirmationColumn>
                <FieldName>SSN</FieldName>
                <FieldValue>{patient.socialSecurityNumber}</FieldValue>
              </RegistrationConfirmationColumn>
            )}
          </RegistrationConfirmationRow>
          {!isEmpty(patient.ethnicity) && (
            <RegistrationConfirmationRow>
              <RegistrationConfirmationColumn>
                <FieldName>Ethnicity</FieldName>
                <FieldValue>
                  {addSpaceBeforeCaps(patient.ethnicity ?? '')}
                </FieldValue>
              </RegistrationConfirmationColumn>
            </RegistrationConfirmationRow>
          )}
        </StyledCard>
      )}
      <StyledCard key={'Address'}>
        <CardTitle>Address</CardTitle>
        <RegistrationConfirmationRow>
          <RegistrationConfirmationColumn sx={{ width: '100%' }}>
            <FieldName>Street</FieldName>
            <FieldValue>{patient.street1}</FieldValue>
          </RegistrationConfirmationColumn>
        </RegistrationConfirmationRow>
        {!isEmpty(patient.street2) && (
          <RegistrationConfirmationRow>
            <RegistrationConfirmationColumn sx={{ width: '100%' }}>
              <FieldName>Street 2</FieldName>
              <FieldValue>{patient.street2}</FieldValue>
            </RegistrationConfirmationColumn>
          </RegistrationConfirmationRow>
        )}
        <RegistrationConfirmationRow>
          <RegistrationConfirmationColumn>
            <FieldName>City</FieldName>
            <FieldValue>{patient.city}</FieldValue>
          </RegistrationConfirmationColumn>
          <RegistrationConfirmationColumn>
            <FieldName>State</FieldName>
            <FieldValue>
              {states.find((state: State) => state.id === patient.stateId)
                ?.name ?? ''}
            </FieldValue>
          </RegistrationConfirmationColumn>
          <RegistrationConfirmationColumn>
            <FieldName>ZIP Code</FieldName>
            <FieldValue>{patient.zipcode}</FieldValue>
          </RegistrationConfirmationColumn>
        </RegistrationConfirmationRow>
      </StyledCard>
      <StyledCard key={'contactInfo'}>
        <CardTitle>Contact Information</CardTitle>
        <RegistrationConfirmationRow>
          {!isEmpty(patient.email) && (
            <RegistrationConfirmationColumn>
              <FieldName>Email</FieldName>
              <FieldValue>{patient.email}</FieldValue>
            </RegistrationConfirmationColumn>
          )}
          <RegistrationConfirmationColumn>
            <FieldName>Phone</FieldName>
            <FieldValue>
              {formatPhoneNumber(patient.phoneNumber ?? '')}
            </FieldValue>
          </RegistrationConfirmationColumn>
          {!isEmpty(patient.leaveMessage) && (
            <RegistrationConfirmationColumn>
              <FieldName>Leave Message</FieldName>
              <FieldValue>{patient.leaveMessage ? 'Yes' : 'No'}</FieldValue>
            </RegistrationConfirmationColumn>
          )}
        </RegistrationConfirmationRow>
      </StyledCard>
      {!(
        isEmpty(patient.primaryCarePhysician) &&
        isEmpty(patient.primaryCarePhysicianContact) &&
        isEmpty(patient.primaryCareFaxNumber)
      ) && (
        <StyledCard key={'pcpInfo'}>
          <CardTitle>Primary Card Information</CardTitle>
          <RegistrationConfirmationRow>
            {!isEmpty(patient.primaryCarePhysician) && (
              <RegistrationConfirmationColumn>
                <FieldName>Primary Care Provider</FieldName>
                <FieldValue>{patient.primaryCarePhysician}</FieldValue>
              </RegistrationConfirmationColumn>
            )}
            {!isEmpty(patient.primaryCarePhysicianContact) && (
              <RegistrationConfirmationColumn>
                <FieldName>PCP Phone</FieldName>
                <FieldValue>
                  {formatPhoneNumber(patient.primaryCarePhysicianContact ?? '')}
                </FieldValue>
              </RegistrationConfirmationColumn>
            )}
            {!isEmpty(patient.primaryCareFaxNumber) && (
              <RegistrationConfirmationColumn>
                <FieldName>PCP Fax</FieldName>
                <FieldValue>
                  {formatPhoneNumber(patient.primaryCareFaxNumber ?? '')}
                </FieldValue>
              </RegistrationConfirmationColumn>
            )}
          </RegistrationConfirmationRow>
        </StyledCard>
      )}
      {!(
        isEmpty(patient.pharmacyId) &&
        isEmpty(patient.employer) &&
        isEmpty(patient.guarantorName)
      ) && (
        <StyledCard key={'other'}>
          <CardTitle>Other Information</CardTitle>
          <RegistrationConfirmationRow>
            {!isEmpty(patient.pharmacyId) && (
              <RegistrationConfirmationColumn>
                <FieldName>Pharmacy</FieldName>
                <FieldValue>{patient.pharmacyId}</FieldValue>
              </RegistrationConfirmationColumn>
            )}
            {!isEmpty(patient.employer) && (
              <RegistrationConfirmationColumn>
                <FieldName>Employer</FieldName>
                <FieldValue>{patient.employer}</FieldValue>
              </RegistrationConfirmationColumn>
            )}
            {!isEmpty(patient.guarantorName) && (
              <RegistrationConfirmationColumn>
                <FieldName>Guarantor</FieldName>
                <FieldValue>{patient.guarantorName}</FieldValue>
              </RegistrationConfirmationColumn>
            )}
          </RegistrationConfirmationRow>
        </StyledCard>
      )}
      {!(
        isEmpty(patient.emergencyContactName) &&
        isEmpty(patient.emergencyContactPhone) &&
        isEmpty(patient.emergencyContactRelation)
      ) && (
        <StyledCard key={'emergency'}>
          <CardTitle>Emergency Contact Information</CardTitle>
          <RegistrationConfirmationRow>
            {!isEmpty(patient.emergencyContactName) && (
              <RegistrationConfirmationColumn>
                <FieldName>Emergency Contact</FieldName>
                <FieldValue>{patient.emergencyContactName}</FieldValue>
              </RegistrationConfirmationColumn>
            )}
            {!isEmpty(patient.emergencyContactPhone) && (
              <RegistrationConfirmationColumn>
                <FieldName>Emergency Contact Phone</FieldName>
                <FieldValue>
                  {formatPhoneNumber(patient.emergencyContactPhone ?? '')}
                </FieldValue>
              </RegistrationConfirmationColumn>
            )}
            {!isEmpty(patient.emergencyContactRelation) && (
              <RegistrationConfirmationColumn>
                <FieldName>Emergency Contact Relationship</FieldName>
                <FieldValue>{patient.emergencyContactRelation}</FieldValue>
              </RegistrationConfirmationColumn>
            )}
          </RegistrationConfirmationRow>
        </StyledCard>
      )}
    </RegistrationConfirmationContainer>
  )
}

interface RegistrationConfirmationProps {
  patient: Patient;
  states: State[];
}
