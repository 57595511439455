import { styled, TextField } from '@mui/material'
import React from 'react'

const FullTextField = styled(TextField)({
  width: '100%',
})

export const ModalFullTextField = ({
  name,
  register,
  error = false,
}: ModalFullTextFieldProps): JSX.Element => {
  return <FullTextField error={error} {...register(name)} />
}

interface ModalFullTextFieldProps {
  name: string;
  register: any;
  error?: boolean;
}
