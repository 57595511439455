import * as React from 'react'
import { ListItem, Typography, Box, styled, Divider } from '@mui/material'
import Image from 'next/image'
import { type ExternalProvider } from '@/types'
import { formatPhoneNumber } from 'react-phone-number-input'
import { useReferenceDataStore } from '@/hook'

const ListSmallRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '2px',
})

const ListFullRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
})

const Text = styled(Typography)({
  fontWeight: 400,
  fontFamily: 'Lato',
  fontSize: '14px',
  color: 'rgb(69, 69, 69)',
})

const Caption = styled(Typography)({
  fontWeight: 400,
  fontFamily: 'Lato',
  fontSize: '16px',
  color: 'black',
})

const PCPDropdownItem: React.FC<PCPDropdownItemProps> = (props) => {
  const { providerData, ...otherProps } = props
  const { states } = useReferenceDataStore()
  const getStateName = (stateId: number | null | undefined) => {
    if (!stateId) return ''
    const state = states.find(s => s.id === stateId)
    return state ? state.code : ''
  }
  return (
    <ListItem
      {...otherProps}
      data-testid={`pcp-dropdown-item${providerData.id!}`}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
        <Caption>
          {providerData.firstName} {providerData.lastName}
        </Caption>
        <ListFullRow>
          <ListSmallRow>
            <Image
              src="/icon-apartment.svg"
              alt="Send Record"
              height={14}
              width={14}
              style={{ cursor: 'pointer' }}
            />
            <Text>{providerData.practiceName}</Text>
          </ListSmallRow>
          <ListSmallRow>
            <Image
              src="/icon-stethoscope.svg"
              alt="Send Record"
              height={14}
              width={14}
              style={{ cursor: 'pointer' }}
            />
            <Text>{providerData.specialty}</Text>
          </ListSmallRow>
        </ListFullRow>
        <ListSmallRow>
          <Image
            src="/icon-location.svg"
            alt="Send Record"
            height={14}
            width={14}
            style={{ cursor: 'pointer' }}
          />
          <Text>
            {providerData.address1} {providerData.city} {getStateName(providerData.stateId)}{' '}
            {providerData.zipCode}
          </Text>
        </ListSmallRow>
        <ListFullRow>
          <ListSmallRow>
            <Image
              src="/icon-office-phone.svg"
              alt="Send Record"
              height={14}
              width={14}
              style={{ cursor: 'pointer' }}
            />
            <Text>{formatPhoneNumber(providerData.phoneNumber ?? '')}</Text>
          </ListSmallRow>
          <ListSmallRow>
            <Image
              src="/icon-fax.svg"
              alt="Send Record"
              height={14}
              width={14}
              style={{ cursor: 'pointer' }}
            />
            <Text>{formatPhoneNumber(providerData.faxNumber ?? '')}</Text>
          </ListSmallRow>
        </ListFullRow>
      </Box>

      <Divider sx={{ mt: 2, color: '#DDDFE8' }} flexItem />
    </ListItem>
  )
}

interface PCPDropdownItemProps extends React.HTMLAttributes<HTMLElement> {
  providerData: ExternalProvider;
}

export default PCPDropdownItem
