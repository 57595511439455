import { TextField } from '@mui/material'
import React from 'react'
import { useAuth } from '@clerk/nextjs'
import { useMutateUpdateTask } from '@/hook/useTasks'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  largeInput: {
    '& .MuiInputBase-root': {
      height: '96px !important',
    },
    '& .MuiInputBase-inputMultiline': {
      height: '90px !important',
    },
  },
})

export const TaskNotes = ({ taskId, notes }: TaskNotesProps): JSX.Element => {
  const classes = useStyles()
  const { getToken } = useAuth()
  const [taskNote, setTaskNote] = React.useState(notes)
  const updateTaskMutation = useMutateUpdateTask(getToken)

  const handleBlur = (): void => {
    void updateTaskMutation.mutateAsync({
      id: taskId,
      task: { notes: taskNote },
    })
  }

  return (
    <TextField
      value={taskNote}
      onChange={(e) => {
        setTaskNote(e.target.value)
      }}
      onBlur={handleBlur}
      className={classes.largeInput}
      multiline
    />
  )
}

interface TaskNotesProps {
  taskId: number;
  notes: string;
}
