import React from 'react'
import { StyledTableText } from '../styles'
import { format, parseISO } from 'date-fns'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import { dateOnlyToDate, isEmpty } from '@/utility/utils'
import { VitalsPopup } from '@/components/VitalsPopup'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined'
import { type Patient, type Visit, type Vital, type Procedure } from '@/types'
import {
  useClinicStore,
  useQueryGetAccountUsers,
  useQueryGetClinics,
  useQueryGetOrders,
  useQueryGetVisitsByPatient,
  useQueryGetVisitTypes,
} from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { type MRT_ColumnDef } from 'material-react-table'
import {
  getStatusIcon,
  getVisitStatusColor,
  getVisitStatusString,
} from '@/components/Columns/ColumnUtils'

const Tag = styled(Typography)({
  backgroundColor: '#EBF0F9',
  fontSize: '12px',
  fontWeight: 500,
  borderRadius: '5px',
  textAlign: 'center',
  marginRight: '10px',
  width: 'fit-content',
  height: 'fit-content',
  padding: '2px 4px',
  color: 'black',
  cursor: 'pointer',
})

const StyleTextButton = styled(Button)({
  width: '200px',
  fontWeight: '600',
  border: 'none',
})

const labels = ['Primary', 'Secondary', 'Tertiary']

export function useVisitColumns(
  patient: Patient,
  handleOpenChart: (visit: Visit) => void,
  handleVerifyInsurance: (dateOfVisit: string) => Promise<void>,
  vitals: Vital[],
  procedures: Procedure[],
  insuranceStatus: Record<string, any>
): Array<MRT_ColumnDef<any>> {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const { data: accounts } = useQueryGetAccountUsers(getToken)
  const { data: visits } = useQueryGetVisitsByPatient(patient?.id!, getToken)
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const { data: orders } = useQueryGetOrders(getToken)
  const { data: clinics } = useQueryGetClinics(getToken)

  const columns = React.useMemo(
    () => [
      {
        header: 'Actions',
        accessorFn: () => 'actions',
        id: 'appointment-actions',
        enableSorting: false,
        size: 70,
        Cell: ({ row }: any) => (
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Tooltip title="View Chart" arrow>
              <IconButton
                onClick={() => handleOpenChart(row?.original as Visit)}
                size="large"
              >
                <AssignmentIndOutlinedIcon
                  style={{
                    cursor: 'pointer',
                    width: '17px',
                    height: '17px',
                    fill: 'rgb(38, 47, 98)',
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      },
      {
        id: 'visitID',
        header: 'Visit ID',
        accessorKey: 'id',
        enableSorting: true,
        sortingFn: 'alphanumeric',
        size: 85,
        Cell: ({ cell, row }: any) => (
          <StyledTableText>{row?.original?.id}</StyledTableText>
        ),
      },
      {
        id: 'date',
        header: 'Date',
        size: 95,
        Cell: ({ cell, row }: any) => (
          <StyledTableText>
            {row?.original?.scheduleStart !== null
              ? format(new Date(row?.original?.scheduleStart), 'MM/dd/yyyy')
              : row?.original?.arrivalDateTime !== undefined
              ? format(new Date(row?.original?.arrivalDateTime), 'MM/dd/yyyy')
              : '-'}
          </StyledTableText>
        ),
      },
      {
        id: 'procedures',
        header: 'Orders & Procedures',
        size: 160,
        Cell: ({ cell, row }: any) => (
          <Stack direction="row" alignItems="center">
            <StyledTableText>{row?.original?.procedures?.[0]}</StyledTableText>
            {row?.original?.procedures !== undefined && (
              <Tooltip
                title={
                  <>
                    {row?.original?.procedures
                      ?.slice(1)
                      .map((procedure: string, index: number) => (
                        <Box
                          key={(procedure ?? '').concat(`${index}`)}
                          style={{ marginBottom: '5px' }}
                        >
                          {procedure}
                        </Box>
                      ))}
                  </>
                }
                arrow
              >
                {/* prettier-ignore */}
                {
                  // prettier-ignore
                  row?.original?.procedures.length > 1
                    ? (
                    // prettier-ignore
                    <Tag>+{row?.original?.procedures?.length - 1}</Tag>
                    // prettier-ignore
                      )
                    : (
                    // prettier-ignore
                    <div />
                    // prettier-ignore
                      )
                }
                {/* prettier-ignore */}
              </Tooltip>
            )}
          </Stack>
        ),
      },
      {
        id: 'visitType',
        header: 'Visit Type',
        size: 100,
        Cell: ({ cell, row }: any) => (
          <StyledTableText>{row?.original?.visitType}</StyledTableText>
        ),
      },
      {
        id: 'provider',
        header: 'Provider',
        size: 120,
        Cell: ({ cell, row }: any) => {
          return (
            <StyledTableText>{row?.original?.physician ?? ''}</StyledTableText>
          )
        },
      },
      {
        id: 'location',
        header: 'Location',
        size: 120,
        Cell: ({ cell, row }: any) => (
          <StyledTableText>{row?.original?.location}</StyledTableText>
        ),
      },
      {
        id: 'vitals',
        header: 'Vitals',
        size: 100,
        Cell: ({ row }: any) => (
          <VitalsPopup
            vitals={row?.original?.vitals}
            dateOfBirth={dateOnlyToDate(patient.dateOfBirth)!}
          />
        ),
      },
      {
        id: 'verify',
        header: 'Verify Insurance',
        size: 120,
        Cell: ({ row }: any) => {
          const [isInsuranceVerified, setIsInsuranceVerified] =
            React.useState<boolean>(true)
          return (
            <Stack
              direction={'column'}
              alignItems={'left'}
              justifyContent={'left'}
            >
              <Stack direction={'row'} alignItems={'left'}>
                <Tooltip
                  arrow
                  title={
                    insuranceStatus[row?.original?.arrivalDateTime]?.isVerifying
                      ? 'Verifying Insurance'
                      : !insuranceStatus[row?.original?.arrivalDateTime]
                          ?.isInsuranceResultsValid
                      ? 'Could not verify insurance, please try again'
                      : insuranceStatus[row?.original?.arrivalDateTime]
                          ?.isInsuranceValid
                      ? 'Verify Insurance'
                      : "Patient's insurance must be complete"
                  }
                >
                  <div>
                    <StyleTextButton
                      sx={{
                        width: 'fit-content',
                        marginLeft: '5px',
                        fontSize: '14px',
                        padding: '0 5px',
                      }}
                      onClick={() => {
                        handleVerifyInsurance(
                          row?.original?.arrivalDateTime ?? ''
                        ).catch((error) => {
                          throw error
                        })
                      }}
                      aria-label="Verify Insurance"
                      disabled={
                        insuranceStatus[row?.original?.arrivalDateTime]
                          ?.isVerifying ||
                        !insuranceStatus[row?.original?.arrivalDateTime]
                          ?.isInsuranceValid
                      }
                      data-testId="verifyInsurance"
                    >
                      {insuranceStatus[row?.original?.arrivalDateTime]
                        ?.isVerifying ? (
                        <Box
                          sx={{
                            color: '#0543B0',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <CircularProgress size={14} sx={{ mr: 1 }} />{' '}
                          Verifying
                        </Box>
                      ) : insuranceStatus[row?.original?.arrivalDateTime]
                          ?.isVerified &&
                        insuranceStatus[row?.original?.arrivalDateTime]
                          ?.isInsuranceResultsValid ? (
                        <Box
                          sx={{
                            color: '#00B87C',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleIcon color="success" />
                          Verified
                        </Box>
                      ) : insuranceStatus[row?.original?.arrivalDateTime]
                          ?.isVerified &&
                        !insuranceStatus[row?.original?.arrivalDateTime]
                          ?.isInsuranceResultsValid ? (
                        <Box
                          sx={{
                            color: '#f44336',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <HighlightOffIcon
                            sx={{ color: '#f44336' }}
                            color="error"
                          />
                          Verify
                        </Box>
                      ) : (
                        'Verify'
                      )}
                    </StyleTextButton>
                  </div>
                </Tooltip>
                {insuranceStatus[row?.original?.arrivalDateTime]?.coverage
                  .length > 0 &&
                  insuranceStatus[row?.original?.arrivalDateTime]
                    ?.isInsuranceResultsValid && (
                    <div
                      style={{
                        display: 'flex',
                        alignContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <StyledTableText>
                        {isInsuranceVerified ? 'Show' : 'Hide'} Coverage
                      </StyledTableText>
                      <IconButton
                        onClick={() =>
                          setIsInsuranceVerified(!isInsuranceVerified)
                        }
                        data-testid="verifyInsuranceCoverage"
                        size="large"
                      >
                        {
                          // prettier-ignore
                          isInsuranceVerified
                          ? (<KeyboardArrowDownIcon fontSize='small' sx={{ color: '#000000' }} />)
                          : (<KeyboardArrowRightIcon fontSize='small' sx={{ color: '#000000' }} />)
                        }
                      </IconButton>
                    </div>
                  )}
              </Stack>
              <Box>
                {insuranceStatus[row?.original?.arrivalDateTime]?.coverage?.map(
                  (coverage: any, index: number) => (
                    <Stack
                      direction={'column'}
                      alignItems={'flex-start'}
                      key={index}
                      sx={{ width: 'fit-content' }}
                      justifyContent={'flex-start'}
                    >
                      <Box
                        key={index}
                        sx={{
                          mb: 2,
                          ...(isInsuranceVerified && { display: 'none' }),
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          {labels[index]}
                        </Typography>
                        <StyledTableText>
                          Copay: ${coverage?.copay}
                        </StyledTableText>
                        <StyledTableText>
                          Deductible: ${coverage?.deductible}
                        </StyledTableText>
                        <StyledTableText>
                          Left To Meet: ${coverage?.leftToMeet}
                        </StyledTableText>
                        <StyledTableText>
                          Coinsurance: ${coverage?.coinsurance}
                        </StyledTableText>
                      </Box>
                    </Stack>
                  )
                )}
              </Box>
            </Stack>
          )
        },
      },
      {
        id: 'status',
        header: 'Status',
        enableSorting: true,
        accessorFn: ({ row }: any) => {
          const visitDetails = row?.original?.allDetails?.visit
          if (visitDetails) {
            const statusId = visitDetails.visitStatusId ?? 'no-status'
            const visitId = visitDetails.id ?? 'no-id'
            return `${statusId as number}_${visitId as number}`
          }
          return 'missing-details'
        },
        sortingFn: 'alphanumeric',
        size: 120,
        Cell: ({ row }: any) => {
          const statusId = row?.original?.visitStatusId
          const isLocked = row?.original?.isLocked
          const status = getVisitStatusString(statusId)
          const calculatedStatus =
            isLocked && status !== 'Discharged' ? 'Completed' : status
          const statusColor = getVisitStatusColor(calculatedStatus)

          return (
            <Stack
              direction={'row'}
              alignContent={'center'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              <div style={{ textAlign: 'center', width: '100%' }}>
                <Chip
                  key={row?.original?.allDetails?.visit?.id}
                  label={calculatedStatus}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: statusColor,
                  }}
                  icon={getStatusIcon(calculatedStatus)}
                />
              </div>
            </Stack>
          )
        },
      },
      {
        id: 'amd_synced_at',
        header: 'AdvancedMD Sync Time',
        Cell: ({ row }: any) => (
          <StyledTableText>
            {!isEmpty(row?.original?.amdSyncedAt)
              ? format(
                  parseISO(row?.original?.amdSyncedAt),
                  'MM/dd/yyyy hh:mm a'
                )
              : ''}
          </StyledTableText>
        ),
      },
    ],
    [
      patient,
      handleOpenChart,
      handleVerifyInsurance,
      accounts,
      clinics,
      orders,
      visitTypes,
      vitals,
      procedures,
      visits,
      insuranceStatus,
    ]
  )

  return columns
}
