import React from 'react'
import { styled } from '@mui/material'
import { ExternalProviderRow } from './ExternalProviderRow'
import { ListContainer } from '../../layout/list/ListContainer'
import { TitleText } from '../../components/list/TitleText'
import { ListBox } from '../../layout/list/ListBox'
import { DetailRow, DetailColumnSmall } from '@/components'
import { type ExternalProvider } from '@/types'

const OuterRow = styled('div')({
  display: 'flex',
  padding: '0px 15px',
})
const ActionGap = styled('div')({
  width: 100,
})
export const ExternalProviderTable = ({
  externalProviders,
  refresh,
}: ExternalProviderTableProps): JSX.Element => {
  return (
    <ListContainer>
      <OuterRow>
        <DetailRow>
          <DetailColumnSmall>
            <TitleText>Name</TitleText>
          </DetailColumnSmall>
          <DetailColumnSmall>
            <TitleText>Practice Name</TitleText>
          </DetailColumnSmall>
          <DetailColumnSmall>
            <TitleText>Specialty</TitleText>
          </DetailColumnSmall>
          <DetailColumnSmall>
            <TitleText>Email</TitleText>
          </DetailColumnSmall>
          <DetailColumnSmall>
            <TitleText>Phone</TitleText>
          </DetailColumnSmall>
          <DetailColumnSmall>
            <TitleText>Fax</TitleText>
          </DetailColumnSmall>
        </DetailRow>
        <ActionGap />
      </OuterRow>
      <ListBox>
        {externalProviders?.map((externalProvider) => {
          return (
            <ExternalProviderRow
              externalProvider={externalProvider}
              key={externalProvider.id}
              refresh={refresh}
            />
          )
        })}
      </ListBox>
    </ListContainer>
  )
}

interface ExternalProviderTableProps {
  externalProviders: ExternalProvider[];
  refresh: any;
}
