import React from 'react'
import {
  PopoverTileText,
  PopoverValueText,
  PopoverContainer,
} from '@/components'
import { IconButton } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export const AllergyHoverOver = ({
  name,
  reaction,
  isAllergyMissing = false,
}: AllergyHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Name</PopoverTileText>
        <PopoverValueText>{name}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Reaction</PopoverTileText>
        <PopoverValueText>{reaction}</PopoverValueText>
      </div>
      <div>
        {isAllergyMissing ? (
          <div style={{ color: 'rgb(223, 115, 45)' }}>
            <IconButton color="warning" size="large">
              <WarningAmberIcon />
            </IconButton>
            Not included in allergy interactions
          </div>
        ) : (
          <div />
        )}
      </div>
    </PopoverContainer>
  )
}

interface AllergyHoverOverProps {
  name: string;
  reaction: string;
  isAllergyMissing?: boolean;
}
