import { Checkbox, styled, Typography } from '@mui/material'
import React from 'react'

const CheckBoxRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'center',
})
const CheckboxText = styled(Typography)({
  fontSize: '18px',
  lineHeight: '22px',
})
export const TitleCheckBox = ({
  title,
  value,
  setValue,
}: TitleCheckBoxProps): JSX.Element => {
  return (
    <CheckBoxRow>
      <CheckboxText>{title}</CheckboxText>
      <Checkbox checked={value} onClick={() => setValue(!value)} />
    </CheckBoxRow>
  )
}

interface TitleCheckBoxProps {
  title: string;
  value: boolean;
  setValue: any;
}
