import React from 'react'
import { TemplateRow } from './TemplateRow'
import { ListContainer } from '../../layout/list/ListContainer'
import { ListTitleRow } from '../../layout/list/ListTitleRow'
import { ListBox } from '../../layout/list/ListBox'

import { TitleText } from '../../components/list/TitleText'
import { type ChartTemplate } from '../../../../types/ChartTemplate'
import { styled } from '@mui/material'
import { type AccountUser } from '@/types'

const TemplateColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '14.2%',
  maxWidth: '470px',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  justifyContent: 'center',
})
export const ChartTemplateManagementList = ({
  templates,
  refresh,
  isNavOpen,
  users,
}: ChartTemplateManagmentListProps): JSX.Element => {
  return (
    <ListContainer>
      <ListTitleRow>
        <TemplateColumn>
          <TitleText>Name</TitleText>
        </TemplateColumn>
        <TemplateColumn>
          <TitleText>Description</TitleText>
        </TemplateColumn>
        <TemplateColumn>
          <TitleText>Created By</TitleText>
        </TemplateColumn>
        <TemplateColumn>
          <TitleText>Last Updated</TitleText>
        </TemplateColumn>
        <TemplateColumn>
          <TitleText>Created On</TitleText>
        </TemplateColumn>
        <TemplateColumn>
          <TitleText>Status</TitleText>
        </TemplateColumn>
        <TemplateColumn />
      </ListTitleRow>
      <ListBox>
        {templates
          .sort((a, b) => ((a.createdAt ?? '') > (b.createdAt ?? '') ? 1 : -1))
          .map((template) => {
            return (
              <TemplateRow
                key={template.id}
                template={template}
                refresh={refresh}
                isNavOpen={isNavOpen}
                users={users}
              />
            )
          })}
      </ListBox>
    </ListContainer>
  )
}

interface ChartTemplateManagmentListProps {
  templates: ChartTemplate[];
  refresh: any;
  isNavOpen: boolean;
  users: AccountUser[];
}
