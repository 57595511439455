import { styled } from '@mui/system'
import React from 'react'

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
})

export const HalfColumn = ({ children }: HalfColumnProps): JSX.Element => {
  return <Column>{children}</Column>
}
interface HalfColumnProps {
  children: any;
}
