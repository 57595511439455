import React from 'react'
import { Popover, MenuItem, styled, Box } from '@mui/material'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined'
import NotesOutlinedIcon from '@mui/icons-material/NotesOutlined'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined'
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined'
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined'
import { ProviderIcon, VisitTypeIcon } from '../Charting/Editor/IQBar/Icons'

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  gap: '10px',
}))

export const VisitFilterPopup = ({
  isOpen = false,
  anchorEl,
  onClose,
  onFilterClick,
}: VisitFilterPopupProps): JSX.Element => {
  const menu = [
    {
      icon: PersonOutlineOutlinedIcon,
      category: 'Patient Name',
    },
    {
      icon: DateRangeOutlinedIcon,
      category: 'Date Of Birth',
    },
    {
      icon: HealthAndSafetyOutlinedIcon,
      category: 'Insurance Status',
    },
    {
      icon: NotesOutlinedIcon,
      category: 'Reason',
    },
    {
      icon: VisitTypeIcon,
      category: 'Type of Visit',
    },
    {
      icon: TimerOutlinedIcon,
      category: 'Total Time',
    },
    {
      icon: SupervisorAccountOutlinedIcon,
      category: 'Staff',
    },
    {
      icon: MeetingRoomOutlinedIcon,
      category: 'Room',
    },
    {
      icon: ProviderIcon,
      category: 'Provider',
    },
    {
      icon: DescriptionOutlinedIcon,
      category: 'Orders',
    },
    {
      icon: VaccinesOutlinedIcon,
      category: 'Procedures',
    },
  ]

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box
        sx={{
          padding: '10px 0',
          width: '280px',
        }}
      >
        {menu.map((item, index) => (
          <StyledMenuItem
            key={index}
            onClick={(e) => onFilterClick(e, item.category)}
          >
            <item.icon sx={{ color: '#707070' }} />
            <span>{item.category}</span>
          </StyledMenuItem>
        ))}
      </Box>
    </Popover>
  )
}
interface VisitFilterPopupProps {
  isOpen: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: any;
  onFilterClick: any;
}
