import React from 'react'
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { type Clinic } from '@/types/Clinic'

const SelectClinicContainer = styled('div')(({ theme }) => ({
  width: '450px',
  height: '225px',
  display: 'flex',
  flex: 'column',
  gap: 16,
  backgroundColor: theme.palette.background.default,
  marginTop: '15%',
  marginLeft: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',
  padding: '12px',
}))

const SelectClinicRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 12,
  paddingTop: '16px',
})
const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const StyledButton = styled(Button)({
  width: '50%',
})

const FormTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '24px',
  color: '#000000',
})

const validationSchema = Yup.object().shape({
  clinicId: Yup.number().required('First Name is required'),
})

interface SelectClinicForm {
  clinicId: number;
}

export const SelectClinic = ({
  handleClose,
  clinics,
  setClinic,
}: SelectClinicProps): JSX.Element => {
  const { handleSubmit, reset, control } = useForm<SelectClinicForm>({
    resolver: yupResolver(validationSchema),
  })

  const handleReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault()
    reset()
    handleClose()
  }

  const onSubmit: SubmitHandler<SelectClinicForm> = (data): void => {
    setClinic(clinics.find((clinic) => clinic?.id === data?.clinicId))
  }
  return (
    <SelectClinicContainer>
      <form
        style={{ width: '100%' }}
        onSubmit={(...args): void => {
          handleSubmit(onSubmit)(...args).catch((error) => {
            throw error
          })
        }}
      >
        <FormTitle>Schedule Vist</FormTitle>
        <SelectClinicRow>
          <Column>
            <InputLabel id="clinicdabel">Patient</InputLabel>

            <Controller
              name="clinicId"
              control={control}
              defaultValue={0}
              render={({ field }) => (
                <Select {...field} labelId="clinicIdLabel" tabIndex={11}>
                  {clinics.map((clinic) => {
                    return (
                      <MenuItem key={clinic.id} value={clinic.id}>
                        {clinic.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            />
          </Column>
        </SelectClinicRow>
        <SelectClinicRow>
          <StyledButton
            variant="outlined"
            type="reset"
            onClick={(event) => handleReset(event)}
          >
            Cancel
          </StyledButton>
          <StyledButton variant="contained" color="secondary" type="submit">
            Save
          </StyledButton>
        </SelectClinicRow>
      </form>
    </SelectClinicContainer>
  )
}

interface SelectClinicProps {
  handleClose: any;
  clinics: Clinic[];
  setClinic: any;
}
