import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material'

import { FileUploadSelect } from '@/components/FileUpload'
import { type ExternalProvider, type UploadFileStatus } from '@/types'
import { useAuth } from '@clerk/nextjs'
import {
  CancelWarningDialog,
  FileUploadingDialog,
} from '@/components/ClinicDocuments/DocumentsTab/FileUploadDialog/WarningDiaglogs'
import { StyledEditButton } from '@/components/ClinicDocuments/DocumentsTab/DocumentTab'

import * as XLSX from 'xlsx'
import { useMutateAddExternalProvider, useQueryGetStates } from '@/hook'

export const BulkImport = ({
  isOpen,
  handleClose,
}: FileUploadingDialogProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState(isOpen)
  const [uploadFile, setUploadFile] = React.useState<File>()
  const [uploadType, setUploadType] = React.useState<number[]>([])
  const [uploadFileStatuses, setUploadFileStatuses] = React.useState<
    UploadFileStatus[]
  >([])
  const [isUploadingWarningOpen, setIsUploadingWarningOpen] =
    React.useState<boolean>(false)
  const [isWarningOpen, setIsWarningOpen] = React.useState<boolean>(false)
  const filesUploaded = uploadFileStatuses.every(
    (file) => file.success || file.error
  )
  const [isUploading, setIsUploading] = React.useState<boolean>(false)
  const { getToken } = useAuth()
  const { data: states } = useQueryGetStates(getToken)
  const addNewProviderMutation = useMutateAddExternalProvider(getToken)
  const [rowsTotal, setRowsTotal] = React.useState<number>(0)
  const [index, setIndex] = React.useState<number>(0)
  const [letClose, setLetClose] = React.useState<boolean>(false)
  React.useEffect(() => {
    if (uploadFileStatuses.length === 0) return
    setIsUploading(uploadFileStatuses.some((file) => file.isUploading))
  }, [uploadFileStatuses])

  const handleEmptiedStates = (): void => {
    setUploadFile(undefined)
    setUploadType([])
    setUploadFileStatuses([])
    handleClose()
  }
  const handleCancelUploadModal = (): void => {
    if (
      uploadFile !== undefined ||
      uploadFile !== null ||
      uploadFile === undefined ||
      uploadFile === null
    ) {
      handleEmptiedStates()
    } else {
      setIsWarningOpen(true)
    }
  }
  React.useEffect(() => {
    setDialogOpen(isOpen)
  }, [isOpen])

  React.useEffect(() => {}, [uploadFile])

  const importRows = (): void => {
    if (!uploadFile) return

    const file = uploadFile
    const reader = new FileReader()

    reader.onload = (e) => {
      const binaryStr = e?.target?.result
      const workbook = XLSX.read(binaryStr, { type: 'binary' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const data: any[] = XLSX.utils.sheet_to_json(sheet)
      setRowsTotal(data.length)
      const providers: ExternalProvider[] = data.map((row) => ({
        id: undefined, // Populate or set undefined
        firstName: parseName(row.Name).firstName || row.Name,
        lastName: parseName(row.Name).lastName || ' ',
        specialty: row.Specialty || '',
        practiceName: row['Practice Name'] || ' ',
        npi: row.NPI || ' ',
        address1: row['Address 1'] || ' ',
        address2: row['Address 2'] || ' ',
        city: row.City || ' ',
        stateId:
          states?.find(
            (state) => state.code === row.State || state.name === row.State
          )?.id ?? 1,
        zipCode: row['Zip Code'] || '99999',
        emailAddress: row['Email Address'] || ' ',
        phoneNumber: formatPhoneNumber(row['Phone Number']) ?? null,
        faxNumber: formatPhoneNumber(row['Fax Number']) ?? null,
        notes: row.Notes || null,
      }))
      providers.forEach((provider, i) => {
        setIndex(i + 1)
        addNewProviderMutation
          .mutateAsync({ ExternalProvider: provider })
          .catch((error) => {
            console.error(error)
          })
      })
    }

    reader.onerror = (error) => console.log(error)

    reader.readAsBinaryString(file)
    setLetClose(true)
  }

  const parseName = (
    input: string
  ): { firstName: string; lastName: string } => {
    let firstName = ''
    let lastName = ''
    const inputTrimmed = input.trim()

    // Check for organizational name
    if (
      inputTrimmed === inputTrimmed.toUpperCase() &&
      !inputTrimmed.includes(',')
    ) {
      return { firstName, lastName }
    }

    // Remove title if present
    const namePart = inputTrimmed.replace(/^(Dr\.\s+)/i, '')

    // Split name and degree
    const [name, degree] = namePart
      .split(/,\s*(?=[A-Z]+\.)/)
      .map((part) => part.trim())

    if (!degree && !name.includes(',')) {
      const parts = name.split(/\s+/)
      if (parts.length > 1) {
        firstName = parts.slice(0, -1).join(' ')
        lastName = parts[parts.length - 1]
      } else {
        lastName = parts[0]
      }
    } else {
      const names = name.split(/,\s*/)
      if (names.length > 1) {
        lastName = names[0]
        firstName = names[1]
        if (degree) lastName += ` ${degree}`
      } else {
        lastName = name
      }
    }

    // Truncate if longer than 35 characters and append '...'
    firstName =
      firstName.length > 35 ? `${firstName.substring(0, 32)}...` : firstName
    lastName =
      lastName.length > 35 ? `${lastName.substring(0, 32)}...` : lastName

    return { firstName, lastName }
  }

  const formatPhoneNumber = (
    input: string | undefined | null
  ): string | null => {
    if (!input) return null
    // Remove all non-numeric characters except '+' from the input
    const numericOnly = input.toString().replace(/[^\d+]/g, '')
    // Check if the number starts with '+1', '1', or does not have the country code
    if (numericOnly.startsWith('+1')) {
      // If the number already starts with '+1', return as is
      return numericOnly
    } else if (numericOnly.startsWith('1')) {
      // If the number starts with '1', add '+' in front
      return '+' + numericOnly
    } else {
      // If the number does not have the country code, add '+1'
      return '+1' + numericOnly
    }
  }

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      sx={{ '& .MuiDialog-paper': { width: '812px', maxWidth: '100%' } }}
    >
      <FileUploadingDialog
        isOpen={isUploadingWarningOpen}
        handleClose={() => {
          setIsUploadingWarningOpen(false)
          handleEmptiedStates()
        }}
      />
      <CancelWarningDialog
        isOpen={isWarningOpen}
        handleClose={() => {
          setIsWarningOpen(false)
          handleEmptiedStates()
          handleClose()
        }}
      />
      <FileUploadingDialog
        isOpen={isUploadingWarningOpen}
        handleClose={() => {
          setIsUploadingWarningOpen(false)
          handleEmptiedStates()
        }}
      />
      <DialogTitle>Upload Document</DialogTitle>
      <DialogContent>
        {
          <FileUploadSelect
            selectedFiles={uploadFile}
            setSelectedFiles={setUploadFile}
            selectedFileTypes={uploadType}
            setSelectedFileTypes={setUploadType}
            docTypes={[]}
            fullWidth
            allowedFileTypes={['xlsx']}
            fileStatuses={uploadFileStatuses}
            setFileStatuses={setUploadFileStatuses}
          />
        }
      </DialogContent>
      {isUploading && (
        <DialogContent>
          <LinearProgress value={(index / rowsTotal) * 100} />
        </DialogContent>
      )}
      <DialogActions>
        {!filesUploaded && !isUploading ? (
          <>
            <StyledEditButton onClick={handleCancelUploadModal}>
              Cancel
            </StyledEditButton>
          </>
        ) : (
          <StyledEditButton onClick={handleClose}>Close</StyledEditButton>
        )}
        {!letClose && (
          <StyledEditButton
            variant="contained"
            type="submit"
            onClick={importRows}
            disabled={isUploading || !uploadFile}
          >
            Import
          </StyledEditButton>
        )}
      </DialogActions>
    </Dialog>
  )
}

interface FileUploadingDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}
