import React from 'react'
import { type WidgetProps } from '@rjsf/utils'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

export const CustomRadio = (props: WidgetProps): JSX.Element => {
  const { options, label, value, required, disabled, readonly, onChange } =
    props

  // If options.enumOptions is not provided, throw an error or handle accordingly
  if (!options.enumOptions) {
    console.error('enumOptions must be provided for radio group widget')
    return <></>
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value)
  }

  const length =
    label.length > 70 ? '766px' : label.length > 33 ? '502px' : '240px'
  return (
    <FormControl
      component="fieldset"
      required={required}
      disabled={disabled ?? readonly}
      sx={{ minWidth: length, maxWidth: length, minHeight: '65px' }}
    >
      <FormLabel
        component="legend"
        sx={{
          fontSize: '14px',
          lineHeight: '24px',
          color: '#222222',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%', // Ensure the width matches the FormControl width
          display: 'inline-block', // Allows text-overflow to work
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup
        aria-label={label}
        name={label}
        value={value}
        onChange={handleChange}
        row
      >
        {options.enumOptions.map((option: any, i: number) => (
          <FormControlLabel
            key={i}
            value={option.value}
            control={<Radio />}
            label={option.label}
            disabled={disabled ?? readonly}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
