import { styled } from '@mui/material'
import React from 'react'

const TileRowContainer = styled('div')(({ wide }: ContainerProps) => ({
  justifyContent: 'space-between',
  display: 'flex',
  gap: '22px',
  scrollMarginTop: '60px',
  ...(wide && {
    justifyContent: 'space-between',
    width: '768px',
    minHeight: '24px',
    alignContent: 'center',
    alignItems: 'center',
  }),
}))

export const TileRow = ({
  id = '',
  children,
  wide = false,
}: TileRowProps): JSX.Element => {
  return (
    <TileRowContainer id={id} wide={wide}>
      {children}
    </TileRowContainer>
  )
}

interface TileRowProps {
  children:
    | JSX.Element
    | JSX.Element[]
    | undefined
    | null
    | Array<JSX.Element | undefined | null>;
  wide?: boolean;
  id?: string;
}

interface ContainerProps {
  wide: boolean;
}
