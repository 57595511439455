import { convertToSpaceSeparated } from '@/utility'
import { IconButton, Tooltip } from '@mui/material'
import { Stack } from '@mui/system'
import React, { type ReactNode } from 'react'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import ReceiptIcon from '@mui/icons-material/Receipt'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import { useAuth } from '@clerk/nextjs'
import { useQueryGetTaskTypes } from '@/hook'
import { type TaskTableCellProps } from './TaskTableCellProps'

export const TypeCell = ({ row }: TaskTableCellProps): JSX.Element => {
  const { getToken } = useAuth()
  const { data: userTaskTypes } = useQueryGetTaskTypes(getToken)

  const typeName =
    userTaskTypes?.find(({ id }) => id === row?.original?.typeId)?.name ?? ''

  const getTypeIcon = (type: string): ReactNode => {
    switch (type) {
      case 'LabResult':
        return <ScienceOutlinedIcon />
      case 'ImagingResult':
        return <PhotoOutlinedIcon />
      case 'Referral':
        return <MailOutlinedIcon />
      case 'Billing':
        return <ReceiptIcon />
      case 'Other':
        return <TaskOutlinedIcon />
      default:
        return null
    }
  }

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        gap: '1rem',
      }}
    >
      <Tooltip title={convertToSpaceSeparated(typeName)} arrow>
        <IconButton size="large">{getTypeIcon(typeName)}</IconButton>
      </Tooltip>
    </Stack>
  )
}
