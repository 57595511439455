import React from 'react'
import Link from 'next/link'
import { useAuth } from '@clerk/nextjs'
import { Tooltip, IconButton, StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/styles'
import { Stack } from '@mui/system'
import { StyledTableText } from '@/components/PatientProfile/styles'
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table'
import { isEmpty } from '@/utility/utils'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import DownloadIcon from '@mui/icons-material/DownloadOutlined'
import { format } from 'date-fns'
import {
  useClinicStore,
  useMutateRetryFax,
  useQueryGetAccountUsers,
  useQueryGetDocumentTypes,
  useQueryGetFaxes,
  useTableTheme,
} from '@/hook'
import { type DocumoFax } from '@/types'

export const FaxTab = ({ incoming }: DocumentTypeProps): JSX.Element => {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const { data: documoFaxes, isLoading: isDocumentTableLoading } =
    useQueryGetFaxes(clinicId, getToken)
  const [documents, setDocuments] = React.useState<DocumoFax[] | undefined>([])
  const { data: docTypes } = useQueryGetDocumentTypes(getToken)
  const { data: clinicians } = useQueryGetAccountUsers(getToken)
  const retryFaxMutation = useMutateRetryFax(getToken)
  const tableTheme = useTableTheme()
  const getFullName = (accountId: number): string => {
    const clinician = clinicians?.find(
      (clinician) => clinician.id === accountId
    )
    if (isEmpty(clinician)) {
      return '-'
    }
    return `${clinician?.firstName!} ${clinician?.lastName!}`
  }
  const getDocumentType = (docType: number): string => {
    const documentType = docTypes?.find((type) => type?.id === docType)
    return documentType?.name as string
  }

  const formatPhoneNumber = (phoneNumberString: string): string => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match !== null) {
      const intlCode = match[1] === null ? '+1 ' : ''
      return `${[intlCode, '(', match[2], ') ', match[3], '-', match[4]].join(
        ''
      )}`
    }
    return ''
  }
  // prettier-ignore
  const documentsColumns = React.useMemo<
  // prettier-ignore
  Array<MRT_ColumnDef<DocumentColumnProps>>
  // prettier-ignore
  >(
    () => [
      {
        id: 'documentDetail',
        header: 'Document Detail',
        sortingFn: 'alphanumeric',
        accessorFn: (row) => row.visibleDocumentName,
        enableSorting: true,
        Cell: ({ row }: any) => (
          <StyledTableText>
            {row?.original?.visibleDocumentName}
          </StyledTableText>
        )
      },
      {
        id: 'docType',
        header: 'Type',
        sortingFn: 'alphanumeric',
        enableSorting: true,
        accessorFn: (row) => getDocumentType(row.documentTypeId),
        Cell: ({ row }: any) => (
          <StyledTableText>
            {getDocumentType(row?.original?.documentTypeId)}
          </StyledTableText>
        )
      },
      {
        id: 'scheduledDate',
        header: 'Date Faxed',
        sortingFn: 'datetime',
        enableSorting: true,
        accessorFn: (row) => row.readOnlyCreatedAt,
        Cell: ({ row }: any) => (
          <StyledTableText>
            {!isEmpty(row?.original?.readOnlyCreatedAt) ? format(new Date(row?.original?.readOnlyCreatedAt ?? ''), 'MM/dd/yyyy hh:mm a') : ''}
          </StyledTableText>
        )
      },
      {
        id: 'faxNumber',
        header: 'Fax Number',
        sortingFn: 'alphanumeric',
        accessorFn: (row) => row.faxNumber,
        enableSorting: true,
        Cell: ({ row }: any) => (
          <StyledTableText>{formatPhoneNumber(row?.original?.faxNumber)}</StyledTableText>
        )
      },
      {
        id: 'recipientName',
        header: 'Recipient Name',
        sortingFn: 'alphanumeric',
        accessorFn: (row) => row.recipientName,
        enableSorting: true,
        Cell: ({ row }: any) => (
          <StyledTableText>{row?.original?.recipientName}</StyledTableText>
        )
      },
      {
        id: 'subject',
        header: 'Subject',
        sortingFn: 'alphanumeric',
        accessorFn: (row) => row.subject,
        enableSorting: true,
        Cell: ({ row }: any) => (
          <StyledTableText>{row?.original?.subject}</StyledTableText>
        )
      },
      {
        id: 'documoFaxStatus',
        header: 'Status',
        sortingFn: 'alphanumeric',
        accessorFn: (row) => row.documoFaxStatus,
        enableSorting: true,
        Cell: ({ row }: any) => (
          <StyledTableText>{row?.original?.documoFaxStatus}</StyledTableText>
        )
      },
      {
        id: 'createdBy',
        header: 'Created By',
        sortingFn: 'alphanumeric',
        accessorFn: (row) => getFullName(row?.accountUserId),
        enableSorting: true,
        Cell: ({ row }: any) => (
          <StyledTableText>{getFullName(row?.original?.accountUserId)}</StyledTableText>
        )
      }
    ],
    [docTypes]
  )
  React.useEffect(() => {
    setDocuments(documoFaxes?.filter((fax) => fax.isInboundFax === incoming))
  }, [documoFaxes, isDocumentTableLoading, incoming])

  const handleSendFax = (fax: DocumoFax): void => {
    retryFaxMutation
      .mutateAsync({ documoFaxId: fax.id ?? 0 })
      .catch((error) => {
        throw error
      })
  }
  return (
    <>
      {!isDocumentTableLoading && (
        <Stack direction="column" style={{ width: '100%', height: '100%' }}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={tableTheme}>
              <MaterialReactTable
                columns={documentsColumns as any[]}
                data={documents ?? []}
                enableRowActions
                positionActionsColumn="last"
                enableColumnActions={false}
                enableTopToolbar={false}
                muiTablePaginationProps={{
                  rowsPerPageOptions: [5, 10, 20],
                  showFirstButton: false,
                  showLastButton: false,
                }}
                renderRowActions={({ row }: any) => (
                  <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <Tooltip title="Download Document" arrow>
                      <Link href={row?.original?.presignedUrl} target="_blank">
                        <IconButton size="large">
                          <DownloadIcon
                            sx={{ color: '#252D63' }}
                            style={{ width: '17px', height: '17px' }}
                          />{' '}
                        </IconButton>
                      </Link>
                    </Tooltip>
                    {!incoming && (
                      <Tooltip title="Resend Fax" arrow>
                        <IconButton
                          onClick={() => {
                            handleSendFax(row?.original ?? {})
                          }}
                          size="large"
                        >
                          <SendOutlinedIcon
                            sx={{ color: '#252D63' }}
                            style={{ width: '17px', height: '17px' }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                )}
                muiTableHeadProps={{
                  sx: () => ({
                    '& tr:nth-of-type(odd)': {
                      backgroundColor: 'rgb(246, 248, 253)',
                    },
                  }),
                }}
                muiTableBodyRowProps={{
                  sx: () => ({
                    '&': {
                      backgroundColor: 'white',
                    },
                  }),
                }}
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    header: 'Actions',
                    minSize: 100,
                  },
                }}
                state={{ isLoading: isDocumentTableLoading }}
              />
            </ThemeProvider>
          </StyledEngineProvider>
        </Stack>
      )}
    </>
  )
}

interface DocumentTypeProps {
  incoming: boolean;
}
interface DocumentColumnProps {
  visibleDocumentName: string;
  documentTypeId: number;
  accountUserId: number;
  documentDetail: string;
  readOnlyCreatedAt: string;
  faxNumber: string;
  createdBy: string;
  recipientName: string;
  senderName: string;
  subject: string;
  documoFaxStatus: string;
}
