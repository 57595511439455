import React from 'react'
import { Skeleton, Box, styled } from '@mui/material'

const SummaryTileSkeletonContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '358px',
  padding: '12px',
  gap: '6px'
})

export const SummaryTileSkeleton = (): JSX.Element => {
  return (
    <SummaryTileSkeletonContainer>
      <Skeleton variant="text" width={150} height={20} />
      <Skeleton variant="rectangular" width="100%" height={44} />
      <Skeleton variant="text" width="80%" height={20} />
      <Skeleton variant="rectangular" width="100%" height={44} />
      <Skeleton variant="text" width="70%" height={20} />
    </SummaryTileSkeletonContainer>
  )
}

