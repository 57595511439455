import { styled, Typography } from '@mui/material'
import React from 'react'

const SubTitleContainer = styled(Typography)({
  fontSize: '16px',
  lineHeight: '24px',
  minHeight: '25px',
})

export const TileSubtitle = ({ children }: SubTitleProps): JSX.Element => {
  return <SubTitleContainer>{children}</SubTitleContainer>
}

interface SubTitleProps {
  children: string;
}
