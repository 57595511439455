import React from 'react'
import {
  InputAdornment,
  lighten,
  OutlinedInput,
  styled,
  Typography,
} from '@mui/material'
import { getPatient, searchPatient } from '@/services'
import { isEmpty } from '@/utility/utils'
import { useDebounce, usePatientStore } from '@/hook'
import { type Patient } from '@/types'
import SearchIcon from '@mui/icons-material/Search'
import CircularProgress from '@mui/material/CircularProgress'
import { TaskPatientResultTile } from './TaskPatientResultTile'
import { useAuth } from '@clerk/nextjs'
import { TaskPatientCard } from './TaskPatientCard'

const PatientSearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  width: '100%',
  '&:hover': {
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
  },
}))

const PatientSearchEmpty = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
  marginLeft: '36px',
}))

const PatientSearchEmptyText = styled(Typography)((): any => ({
  fontSize: '16px',
  lineHeight: '19.2px',
  color: 'lightgrey',
}))

export const StyledOutlineInput = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  height: '40px',
  background: theme.palette.background.default,
  borderRadius: '8px',
}))

const DropDownList = styled('div')(({ theme }) => ({
  width: '100%',
  background: theme.palette.background.default,
  borderRadius: '0 8px 8px 8px',
  borderTopRightRadius: '0',
  marginTop: '40px',
  position: 'absolute',
  padding: '10px 0px',
  boxShadow: '0 5px 10px #CCC',
  gap: '10px',
  zIndex: 10,
}))

const OverlayBackground = styled('div')((): any => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
}))

export const TaskPatientSearch = ({
  handleChange,
  initialPatientId,
  openSearch = false,
  onClearTile = () => {},
}: TaskPatientSearchProps): JSX.Element => {
  const { getToken } = useAuth()
  const { patient: storedPatient } = usePatientStore()

  const [patient, setPatient] = React.useState<Patient | null>(null)
  const [isFetching, setIsFetching] = React.useState<boolean>(false)
  const [patientResults, setPatientResults] = React.useState<Patient[]>([])
  const [patientId, setPatientId] = React.useState<number | null>(initialPatientId)
  const [isResultsVisible, setIsResultsVisible] =
    React.useState<boolean>(false)
  const [firstLastName, setFirstLastName] = React.useState<string>('')
  const [cursor, setCursor] = React.useState<number>(-1)
  const [isInput, setIsInput] = React.useState<boolean>(openSearch)
  const debouncedFirstLastName = useDebounce<string>(firstLastName, 500)

  React.useEffect(() => {
    if (patientId === null) {
      setPatient(null)
    } else if (storedPatient?.id === patientId) {
      setPatient(storedPatient)
    } else if (patientId) {
      getPatient(patientId, getToken)
        .then((newPatient) => {
          setPatient(newPatient)
        })
        .catch((error) => {
          console.log('Error fetching patient', error)
        })
    }
  }, [getToken, patientId, storedPatient])

  const selectPatient = (patient: Patient): void => {
    handleChange(patient)
    setPatientId(patient?.id ?? null)
    setIsInput(false)
    setIsResultsVisible(false)
  }

  const handleKeyPress = (event: any): void => {
    if (event.key === 'Enter' && cursor === -1) {
      searchPatientResult().catch((err) => {
        throw err
      })
    } else if (event.key === 'Enter' && cursor !== -1) {
      selectPatient(patientResults[cursor])
      setIsResultsVisible(false)
      setCursor(-1)
    } else if (event.key === 'Escape') {
      setIsResultsVisible(false)
      setCursor(-1)
    } else if (event.key === 'ArrowUp' && cursor > 0) {
      setCursor(cursor - 1)
    } else if (
      event.key === 'ArrowDown' &&
      cursor < patientResults?.length - 1
    ) {
      setCursor(cursor + 1)
    }
  }
  const onTextChange = (e: any): void => {
    setFirstLastName(e.target.value)
    setIsResultsVisible(false)
    setCursor(-1)
  }
  const handleOnBlur = (): void => {
    setIsResultsVisible(false)
    setCursor(-1)
  }
  const searchPatientResult = async (): Promise<void> => {
    setIsFetching(true)
    const searchPatientPromise = searchPatient(
      debouncedFirstLastName,
      getToken
    )
    Promise.resolve(searchPatientPromise)
      .then((res) => {
        setPatientResults(res.patientDTOList)
        setIsFetching(false)
        setIsResultsVisible(true)
      })
      .catch((error) => {
        throw error
      })
  }

  React.useEffect(() => {
    if (isEmpty(debouncedFirstLastName)) {
      return
    }
    searchPatientResult().catch((err) => {
      throw err
    })
  }, [debouncedFirstLastName])

  React.useEffect(() => {
    if (patient && !isEmpty(patient)) {
      handleChange(patient)
    }
  }, [patient, handleChange])

  return (
    <>
      <PatientSearchContainer>
        {patient && !isInput ? (
          <TaskPatientCard
            patient={patient}
            onClick={onClearTile}
          />
        ) : (
          <StyledOutlineInput
            name="PatientSearchInput"
            tabIndex={-1}
            onKeyDown={handleKeyPress}
            placeholder="Type to search"
            startAdornment={
              <InputAdornment position="start">
                {isFetching ? (
                  <CircularProgress color="primary" size={20} />
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            }
            value={firstLastName}
            onChange={onTextChange}
          />
        )}
        {!isFetching && isResultsVisible && patientResults?.length > 0 && (
          <DropDownList>
            {patientResults
              .filter((patient) => patient !== null)
              .map((patient) => {
                return (
                  <TaskPatientResultTile
                    key={patient.id}
                    patient={patient}
                    onClick={() => selectPatient(patient)}
                  />
                )
              })}
          </DropDownList>
        )}
        {!isFetching && isResultsVisible && patientResults?.length === 0 && (
          <DropDownList>
            <PatientSearchEmpty>
              <PatientSearchEmptyText>No Results Found</PatientSearchEmptyText>
            </PatientSearchEmpty>
          </DropDownList>
        )}
      </PatientSearchContainer>
      {isResultsVisible && <OverlayBackground onClick={handleOnBlur} />}
    </>
  )
}

interface TaskPatientSearchProps {
  handleChange: any;
  initialPatientId: number | null;
  openSearch?: boolean;
  onClearTile: () => void;
}
