import React from 'react'
import { PopoverContainer } from '../layout/PopoverContainer'
import { PopoverTileText } from '../components/PopoverTileText'
import { PopoverValueText } from '../components/PopoverValueText'
import { type Prescription } from '@/types/Prescription'

export const PrescriptionHoverOver = ({
  prescription,
}: DXHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Display Name</PopoverTileText>
        <PopoverValueText>{prescription.displayName}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Route</PopoverTileText>
        <PopoverValueText>{prescription.route}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Strenghth</PopoverTileText>
        <PopoverValueText>{prescription.strength}</PopoverValueText>
      </div>
    </PopoverContainer>
  )
}

interface DXHoverOverProps {
  prescription: Prescription;
}
