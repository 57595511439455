import { useDocument } from '@/hook/useDocument'
import { type UIQDocument } from '@/types'
import { useAuth } from '@clerk/nextjs'
import { Box, CircularProgress, styled, Typography } from '@mui/material'
import React from 'react'
import {
  type DefaultExtensionType,
  defaultStyles,
  FileIcon,
} from 'react-file-icon'

const PopoverContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: '12px',
  minHeight: '57px',
  flexDirection: 'column',
  background: 'white',
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
}))
const StyledBox = styled(Box)(({ theme }) => ({
  height: '45px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
}))
const DocumentName = styled(Typography)({
  fontSize: '14px',
  color: '#454545',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '150px',
  whiteSpace: 'nowrap',
})
const PAGE_SIZE = 5
export const DocumentsPopover = ({
  documentIds,
}: DocumentsPopoverProps): JSX.Element => {
  const { getToken } = useAuth()
  const { documents, getDocument } = useDocument()

  const [taskDocuments, setTaskDocuments] = React.useState<UIQDocument[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [notLoaded, setNotLoaded] = React.useState<number[]>([...documentIds])

  React.useEffect(() => {
    // if all docs are loaded, return early
    if (taskDocuments.length === documentIds.length) {
      setLoading(false)
      return
    }

    setLoading(true)
    const nextNotLoaded = [...notLoaded]
    const docsPage: number[] = nextNotLoaded.splice(0, PAGE_SIZE)
    const docs = docsPage.reduce((acc: UIQDocument[], docId) => {
      const doc = getDocument(docId, getToken)
      if (doc !== null) {
        acc.push(doc)
      } else {
        nextNotLoaded.push(docId)
      }

      return acc
    }, [])

    setNotLoaded(nextNotLoaded)
    setTaskDocuments([...taskDocuments, ...docs])
    setLoading(false)
  }, [documentIds, documents, getDocument, getToken, notLoaded, taskDocuments])

  return (
    <PopoverContainer>
      {taskDocuments.map((doc) => {
        const ext = doc?.visibleDocumentName?.split('.').reverse()[0]
        return (
          <StyledBox
            key={doc.id}
            onClick={() => {
              void window.open(doc.presignedUrl!, '_blank')
            }}
          >
            <Box sx={{ display: 'flex', width: '20px', marginRight: '15px' }}>
              <FileIcon
                extension={ext}
                {...defaultStyles[ext as DefaultExtensionType]}
                labelUppercase
              />
            </Box>
            <DocumentName>{doc.visibleDocumentName ?? ''}</DocumentName>
          </StyledBox>
        )
      })}
      {loading && (
        <Box sx={{ display: 'flex', gap: '12px' }}>
          <CircularProgress size={20} />
          <Typography>Loading Documents</Typography>
        </Box>
      )}
    </PopoverContainer>
  )
}

interface DocumentsPopoverProps {
  documentIds: number[];
}
