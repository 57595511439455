import React from 'react'
import {
  useForm,
  Controller,
  type FieldValues,
  type SubmitHandler
} from 'react-hook-form'
import {
  IconButton,
  MenuItem,
  Select,
  TextField,
  Dialog,
  Box,
  useTheme
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  dateOnlyToDate,
  formatAgeWithRules,
  formatPhoneNumber,
  isEmpty,
  isInputValid,
  parseDateToDateOnly
} from '@/utility/utils'
import {
  GridContent,
  GridItem,
  GridLabel,
  Card,
  CardContent,
  CardIcon,
  CardTitle,
  CenterPlace
} from '../styles'
import {
  AddressInput,
  PharmacySelectionPopup,
  ErrorMessage,
  PhoneNumberInput
} from '@/components'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { DatePicker } from '@mui/x-date-pickers'
import { format } from 'date-fns'
import { useAuth } from '@clerk/nextjs'
import { type Patient, type State } from '@/types'
import { type AnyObject } from 'yup/lib/object'
import { useGlobalStore, useMutateUpdatePatient } from '@/hook'
import FaxIcon from '@mui/icons-material/Fax'
import { useQueryGetUrgentIQPharmacy } from '@/hook/useDoseSpotPharmacy'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined'
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined'
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined'

const useStyles = makeStyles({
  root: {
    '& .MuiPaper-root': {
      maxWidth: 'unset'
    }
  }
})

const icons = [
  CoPresentOutlinedIcon,
  PermContactCalendarOutlinedIcon,
  ContactEmergencyOutlinedIcon
]

function PatientDemographic({
  patient,
  states,
  getPatient,
  clinicZip
}: PatientDemographicsProps): JSX.Element {
  const theme = useTheme()
  const [editCard, setEditCard] = React.useState<string>('')
  const [patientData, setPatientData] = React.useState<Patient>(patient)
  // const [sSNError, setSSNError] = React.useState<boolean>(false)
  const { getToken } = useAuth()
  const [isOpenPharmacySelectionDialog, setIsOpenPharmacySelectionDialog] =
    React.useState<boolean>(false)
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .nullable()
      .min(12, 'Valid phone number is required')
      .required('Phone number is required'),
    emergencyContactPhone: Yup.lazy((value) =>
      !isEmpty(value)
        ? Yup.string().nullable().min(12, 'Valid phone number is required')
        : Yup.string().nullable().notRequired()
    )
  })
  const { data: dsPharmacy } = useQueryGetUrgentIQPharmacy(
    patient?.pharmacyId!,
    getToken
  )
  const updatePatientMutation = useMutateUpdatePatient(getToken)
  const { setIsEditing } = useGlobalStore()
  const classes = useStyles()

  const {
    handleSubmit,
    control,
    register,
    setValue,
    getValues,
    setError,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  React.useEffect(() => {
    if (editCard === 'Contact Information') {
      setValue('street1', patient.street1)
      setValue('street2', patient.street2)
      setValue('city', patient.city)
      setValue(
        'state',
        states.find((state) => state.id === patient.stateId)?.code
      )
      setValue('zip', patient.zipcode)
    }
  }, [editCard, states])

  React.useEffect(() => {
    setPatientData(patient)
    setValue('phoneNumber', patient.phoneNumber)
    setValue('emergencyContactPhone', patient.emergencyContactPhone)
    setValue('email', patient.email)
    setValue('phoneNumber', patient.phoneNumber)
  }, [patient])

  const medicationCardInfo: AnyObject = [
    [
      'Personal Information',
      2,
      0,
      [
        ['First Name', 'firstName'],
        ['Last Name', 'lastName'],
        ['Middle Name', 'middleName'],
        [
          'DOB(Age)',
          'dateOfBirth',
          (dob: any, isEditMode: boolean) => {
            const date = dateOnlyToDate(dob[0])!
            if (isEditMode) {
              return (
                <Controller
                  name="dateOfBirth"
                  control={control}
                  defaultValue={date}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      onError={() => {}}
                      inputFormat="MM/dd/yyyy"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ background: 'white', width: '90%' }}
                          inputProps={{
                            ...params.inputProps,
                            'data-testid': 'birthDate'
                          }}
                        />
                      )}
                    />
                  )}
                />
              )
            }
            return `${format(date, 'MM/dd/yyyy')} (${formatAgeWithRules(
              parseDateToDateOnly(date)
            )})`
          }
        ],
        ['Prefix', 'prefix'],
        ['Suffix', 'suffix'],
        [
          'Race',
          'race',
          ([race]: string[], isEditMode: boolean) => {
            const options = [
              'American Indian or Alaska Native',
              'Asian',
              'Black or African American',
              'Native Hawaiian or Other Pacific Islander',
              'White',
              'No Response'
            ]
            const option = options.find(
              (option) => option.replace(/\s/g, '') === race
            )
            if (isEditMode) {
              return (
                <Controller
                  name="race"
                  control={control}
                  defaultValue={option !== undefined ? race : 'NoResponse'}
                  render={({ field }) => (
                    <Select
                      sx={{ mr: 1 }}
                      style={{ width: '90%', background: 'white' }}
                      {...field}
                      data-testid="race"
                    >
                      {options.map((option) => (
                        <MenuItem
                          data-testid={`race${option}`}
                          key={option}
                          value={option.replace(/\s/g, '').replace('or', 'Or')}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )
            } else {
              const option = options.find(
                (option) =>
                  option.replace(/\s/g, '').replace('or', 'Or') === race
              )
              return option
            }
          }
        ],
        [
          'Sex',
          'sex',
          ([sex]: string[], isEditMode: boolean) => {
            const options = ['Male', 'Female', 'Other', 'No Response']
            const option = options.find(
              (option) => option.replace(/\s/g, '') === sex
            )
            if (isEditMode) {
              return (
                <Controller
                  name="sex"
                  control={control}
                  defaultValue={option !== undefined ? sex : 'NoResponse'}
                  render={({ field }) => (
                    <Select
                      sx={{ mr: 1 }}
                      style={{ width: '90%', background: 'white' }}
                      {...field}
                      data-testId="sex"
                    >
                      {options.map((option) => (
                        <MenuItem
                          data-testid={`sex${option}`}
                          key={option}
                          value={option.replace(/\s/g, '')}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )
            } else {
              return option
            }
          }
        ],
        [
          'SSN',
          'socialSecurityNumber',
          ([SSN]: string[], isEditMode: boolean) => {
            if (isEditMode) {
              return (
                <Controller
                  name="socialSecurityNumber"
                  control={control}
                  defaultValue={SSN}
                  render={() => (
                    <TextField
                      {...register('socialSecurityNumber')}
                      style={{ width: '90%', background: 'white' }}
                      onChange={handleSSNChange}
                      inputProps={{
                        'data-testid': 'ssn'
                      }}
                    />
                  )}
                />
              )
            } else {
              return SSN
            }
          }
        ],
        [
          'Ethnicity',
          'ethnicity',
          ([ethnicity]: string[], isEditMode: boolean) => {
            const options = [
              'Hispanic or Latino',
              'Not Hispanic or Latino',
              'No Response'
            ]
            const option = options.find(
              (option) =>
                option.replace(/\s/g, '').replace('or', 'Or') === ethnicity
            )
            if (isEditMode) {
              return (
                <Controller
                  name="ethnicity"
                  control={control}
                  defaultValue={option !== undefined ? ethnicity : 'NoResponse'}
                  render={({ field }) => (
                    <Select
                      sx={{ mr: 1 }}
                      style={{ width: '90%', background: 'white' }}
                      {...field}
                      data-testId="ethnicity"
                    >
                      {options.map((option) => (
                        <MenuItem
                          data-testid={`ethnicity${option}`}
                          key={option}
                          value={option.replace(/\s/g, '').replace('or', 'Or')}
                        >
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              )
            } else {
              return option
            }
          }
        ],
        ['Guarantor', 'guarantorName']
      ]
    ],
    [
      'Contact Information',
      1,
      1,
      [
        [
          'Address',
          'street1,street2,city,stateId,zipcode',
          (
            addresses: [
              street: string,
              street2: string,
              city: string,
              stateId: number,
              zipcode: string
            ],
            isEditMode: boolean
          ) => {
            const [street, street2, city, stateId = null, zipcode] = addresses
            if (isEditMode) {
              return (
                <AddressInput
                  register={register}
                  control={control}
                  sectionName={''}
                  setValue={setValue}
                  errors={errors}
                  setError={setError}
                />
              )
            }
            return (
              <>
                {street} <br />
                {!isEmpty(street2) && `${street2}`}{' '}
                {!isEmpty(street2) && <br />} {city}
                {stateId !== null
                  ? `, ${
                      states.find((state) => state.id === stateId)?.code! ?? ''
                    }`
                  : ''}{' '}
                {zipcode}
              </>
            )
          }
        ],
        [
          'Contact',
          'email,phoneNumber',
          (contacts: [email: string, phone: string], isEditMode: boolean) => {
            const [email, phone] = contacts
            return (
              <>
                <CenterPlace>
                  <EmailOutlinedIcon
                    fontSize="small"
                    sx={{ color: '#717899', marginRight: '10px' }}
                  />{' '}
                  {isEditMode ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '816px'
                      }}
                    >
                      <Controller
                        name="email"
                        control={control}
                        defaultValue={email}
                        render={({ field }) => (
                          <TextField
                            style={{ width: '90%', background: 'white' }}
                            {...field}
                            {...register('email')}
                            inputProps={{
                              'data-testid': 'email'
                            }}
                          />
                        )}
                      />
                      {errors.email !== null && errors.email !== undefined ? (
                        <ErrorMessage
                          error={errors.email.message?.toString() ?? ''}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    email
                  )}
                </CenterPlace>
                <br />
                <CenterPlace>
                  <PhoneOutlinedIcon
                    fontSize="small"
                    sx={{ color: '#717899', marginRight: '10px' }}
                  />{' '}
                  &nbsp;
                  {isEditMode ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '816px'
                      }}
                    >
                      <PhoneNumberInput
                        required
                        control={control}
                        error={errors.phoneNumber}
                        hideLabel
                        width="90%"
                        sectionName="phoneNumber"
                        profile
                        setError={setError}
                      />
                    </div>
                  ) : (
                    formatPhoneNumber(phone)
                  )}
                </CenterPlace>
              </>
            )
          }
        ],
        [
          'Pharmacy',
          'pharmacyId',
          ([pharmacyId]: number[], isEditMode: boolean) => {
            if (isEditMode) {
              return (
                <>
                  <TextField
                    {...register('pharmacy')}
                    tabIndex={18}
                    sx={{ width: '240px', backgroundColor: '#fff' }}
                    defaultValue={dsPharmacy?.storeName}
                    onClick={() => setIsOpenPharmacySelectionDialog(true)}
                    inputProps={{
                      'data-testid': 'pharmacy'
                    }}
                  />
                  <Box style={{ visibility: 'hidden', position: 'absolute' }}>
                    <TextField
                      {...register('pharmacyId')}
                      defaultValue={''}
                      inputProps={{
                        'data-testid': 'pharmacyId'
                      }}
                    />
                  </Box>
                </>
              )
            } else {
              return dsPharmacy?.storeName
            }
          }
        ]
      ]
    ],
    [
      'Emergency Contact',
      2,
      2,
      [
        ['Name', 'emergencyContactName'],
        ['Relation', 'emergencyContactRelation'],
        [
          'Contact',
          'emergencyContactPhone',
          (contacts: string[], isEditMode: boolean) => {
            const phone = contacts
            return (
              <>
                <CenterPlace>
                  {(phone.filter((item: string) => !isEmpty(item))?.length >=
                    1 ||
                    isEditMode) && (
                    <PhoneOutlinedIcon
                      fontSize="small"
                      sx={{ color: '#717899', marginRight: '10px' }}
                    />
                  )}{' '}
                  &nbsp;
                  {isEditMode ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '350px'
                      }}
                    >
                      <PhoneNumberInput
                        required
                        control={control}
                        error={errors.emergencyContactPhone}
                        hideLabel
                        width="90%"
                        sectionName="emergencyContactPhone"
                        profile
                        setError={setError}
                      />
                    </div>
                  ) : (
                    phone.map((item) => formatPhoneNumber(item)).join(' ')
                  )}
                </CenterPlace>
              </>
            )
          }
        ],
        [
          'Primary Care Provider',
          'primaryCarePhysician,primaryCarePhysicianContact,primaryCareFaxNumber',
          (provider: string[], isEditMode: boolean) => {
            const [physician = '', contact = '', fax = ''] = provider || []
            return (
              <>
                <CenterPlace>
                  {isEditMode ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '350px'
                      }}
                    >
                      <DirectionsRunIcon
                        sx={{ color: '#717899', mr: '10px', fontSize: 16 }}
                      />{' '}
                      <Controller
                        name="primaryCarePhysician"
                        control={control}
                        defaultValue={physician}
                        render={({ field }) => (
                          <TextField
                            style={{ width: '100%', background: 'white' }}
                            {...field}
                            {...register('primaryCarePhysician')}
                            inputProps={{
                              'data-testid': 'primaryCarePhysician'
                            }}
                          />
                        )}
                      />
                      {errors.primaryCarePhysician !== null &&
                      errors.primaryCarePhysician !== undefined ? (
                        <ErrorMessage
                          error={
                            errors.primaryCarePhysician.message?.toString() ??
                            ''
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <>
                      {!isEmpty(physician) && (
                        <DirectionsRunIcon
                          sx={{ color: '#717899', mr: '10px', fontSize: 16 }}
                        />
                      )}{' '}
                      {physician}
                    </>
                  )}
                </CenterPlace>
                <br />
                <CenterPlace>
                  {isEditMode ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '350px'
                      }}
                    >
                      <PhoneOutlinedIcon
                        sx={{ color: '#717899', mr: '10px', fontSize: 16 }}
                      />{' '}
                      <div style={{ flex: '1' }}>
                        <PhoneNumberInput
                          control={control}
                          error={errors.primaryCarePhysicianContact}
                          defaultValue={contact}
                          hideLabel
                          width="100%"
                          sectionName="primaryCarePhysicianContact"
                          profile
                          setError={setError}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      {!isEmpty(contact) && (
                        <PhoneOutlinedIcon
                          fontSize="small"
                          sx={{ color: '#717899', marginRight: '10px' }}
                        />
                      )}{' '}
                      {formatPhoneNumber(contact)}
                    </>
                  )}
                </CenterPlace>
                <br />
                <CenterPlace>
                  {isEditMode ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '350px'
                      }}
                    >
                      <FaxIcon
                        sx={{ color: '#717899', mr: '10px', fontSize: 16 }}
                      />{' '}
                      <div style={{ flex: '1' }}>
                        <PhoneNumberInput
                          control={control}
                          error={errors.primaryCarePhysicianContact}
                          defaultValue={fax}
                          hideLabel
                          width="100%"
                          sectionName="primaryCareFaxNumber"
                          profile
                          setError={setError}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      {!isEmpty(contact) && (
                        <FaxIcon
                          fontSize="small"
                          sx={{ color: '#717899', marginRight: '10px' }}
                        />
                      )}{' '}
                      {formatPhoneNumber(contact)}
                    </>
                  )}
                </CenterPlace>
              </>
            )
          }
        ]
      ]
    ]
  ]

  const handleSelectPharmacy = (pharmacy: any): void => {
    if (isEmpty(pharmacy)) return
    setValue('pharmacy', pharmacy.storeName)
    setValue('pharmacyId', pharmacy.id)
    setIsOpenPharmacySelectionDialog(false)
  }

  const getCardValue = (
    field: string,
    func: Function,
    isEditMode: boolean
  ): string => {
    if (isEmpty(patientData)) return ''
    const data = field.split(',').map((key: any) => (patientData as any)[key])

    return !isEmpty(func) ? (
      func(data, isEditMode)
    ) : isEditMode ? (
      <Controller
        name={field}
        control={control}
        defaultValue={data[0]}
        render={() => (
          <TextField
            style={{ width: '90%', fontSize: '14px', background: 'white' }}
            {...register(field)}
            inputProps={{
              'data-testid': field
            }}
          />
        )}
      />
    ) : (
      data[0]
    )
  }
  const onClickEdit = (
    ev: React.MouseEvent<Element, MouseEvent>,
    cardTitle: string
  ): void => {
    ev.preventDefault()
    setEditCard(cardTitle)
    setIsEditing(true)
  }

  const handleSubmitPatientProfile: SubmitHandler<FieldValues> = async (
    data
  ): Promise<void> => {
    if (
      !isEmpty(data.emergencyContactPhone) &&
      !isInputValid(data.emergencyContactPhone)
    ) {
      setError('emergencyContactPhone', {
        message: 'Valid emergency phone number is required'
      })
    }
    if (!isInputValid(data.phoneNumber)) {
      setError('phoneNumber', { message: 'Valid phone number is required' })
    }
    setEditCard('')
    Object.keys(data).forEach((key: any) => {
      if (key !== 'pharmacyId') {
        (patient as any)[key] = data[key]
      }
    })
    if (data?.dateOfBirth !== undefined) {
      patient.dateOfBirth = parseDateToDateOnly(data?.dateOfBirth)
    }
    if (data?.zip !== undefined) patient.zipcode = data?.zip
    if (data?.state !== undefined) {
      patient.stateId = states.find((sItem) => sItem.code === data?.state)?.id
    }

    if (isEmpty(patient?.emergencyContactPhone)) {
      patient.emergencyContactPhone = null
    }
    if (!isEmpty(data.pharmacyId)) {
      patient.pharmacyId = data.pharmacyId
    }

    await updatePatientMutation.mutateAsync({
      patientId: patient?.id!,
      patient
    })
    getPatient()
  }

  const handleSSNChange = (event: any): void => {
    let ssnVal = event.target.value as string
    if (ssnVal.length < 11) {
      ssnVal = ssnVal.replaceAll('-', '')
      if (ssnVal.length > 3) {
        ssnVal = ssnVal.slice(0, 3) + '-' + ssnVal.slice(3)
      }
      if (ssnVal.length > 6) {
        ssnVal = ssnVal.slice(0, 6) + '-' + ssnVal.slice(6)
      }
    }
    // const re: RegExp = /^\d{3}-?\d{2}-?\d{4}$/
    event.target.value = ssnVal
    // setSSNError(!re.test(ssnVal))
  }

  return (
    <form
      style={{ display: 'flex', width: '100%' }}
      onSubmit={(...args): void => {
        handleSubmit(handleSubmitPatientProfile)(...args).catch((error) => {
          throw error
        })
        setIsEditing(false)
      }}
    >
      {medicationCardInfo.map(
        ([title, columnCount, iconIndex, info]: any): JSX.Element => (
          <Card key={title}>
            <CardIcon>
              {React.createElement(icons[iconIndex], {
                style: { fontSize: 28, color: theme.palette.primary.main }
              })}
            </CardIcon>
            <CardTitle>
              {title}
              {title === editCard ? (
                <IconButton
                  type="submit"
                  size="large"
                >
                  <SaveOutlinedIcon
                    fontSize="small"
                    style={{ fill: '#252D63' }}
                    data-testid="save"
                  />
                </IconButton>
              ) : (
                <IconButton
                  id={title as string}
                  onClick={(ev: React.MouseEvent<Element, MouseEvent>) =>
                    onClickEdit(ev, title as string)
                  }
                  size="large"
                >
                  <CreateOutlinedIcon
                    data-testid={`edit${title as string}`}
                    fontSize="small"
                    style={{ fill: '#252D63' }}
                  />
                </IconButton>
              )}
            </CardTitle>
            <CardContent columnCount={columnCount as number}>
              {info?.map(
                ([disp, field, func]: any): JSX.Element => (
                  <GridItem key={disp}>
                    <GridLabel>{disp}</GridLabel>
                    <GridContent>
                      {getCardValue(field, func, editCard === title)}
                    </GridContent>
                  </GridItem>
                )
              )}
            </CardContent>
          </Card>
        )
      )}
      <Dialog
        open={isOpenPharmacySelectionDialog}
        sx={{ borderRadius: '12px' }}
        className={classes.root}
      >
        <PharmacySelectionPopup
          pharmacyId={getValues('pharmacyId')}
          onSelectPharmacy={handleSelectPharmacy}
          setClose={() => {
            setIsOpenPharmacySelectionDialog(false)
          }}
          clinicZip={clinicZip}
        />
      </Dialog>
    </form>
  )
}

interface PatientDemographicsProps {
  patient: Patient
  states: State[]
  getPatient: any
  clinicZip: string
}

export const PatientDemographics = React.memo(PatientDemographic)
