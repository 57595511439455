export const values = [
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Sore throat',
      value_id: 48,
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    17,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Sore throat',
      value_id: 48,
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    17,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Sore throat',
      value_id: 48,
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    17,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Sore throat',
      value_id: 48,
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    16,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Sore throat',
      value_id: 48,
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    16,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Sore throat',
      value_id: 48,
    },
    17,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    14,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Sore throat',
      value_id: 48,
    },
    17,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    14,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Sore throat',
      value_id: 48,
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    13,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Earache',
      value_id: 17,
    },
    12,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    12,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Earache',
      value_id: 17,
    },
    12,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    11,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Earache',
      value_id: 17,
    },
    12,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    10,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Earache',
      value_id: 17,
    },
    12,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    9,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Earache',
      value_id: 17,
    },
    12,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    9,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Congestion',
      value_id: 8,
    },
    10,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    10,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Congestion',
      value_id: 8,
    },
    10,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    8,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Congestion',
      value_id: 8,
    },
    10,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    8,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Congestion',
      value_id: 8,
    },
    10,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    8,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Congestion',
      value_id: 8,
    },
    10,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    8,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Cough',
      value_id: 10,
    },
    10,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    10,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Cough',
      value_id: 10,
    },
    10,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    10,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Cough',
      value_id: 10,
    },
    10,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    9,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Cough',
      value_id: 10,
    },
    10,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    9,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Cough',
      value_id: 10,
    },
    10,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    9,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Cough',
      value_id: 10,
    },
    10,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    9,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Cough',
      value_id: 10,
    },
    10,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    9,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Cough',
      value_id: 10,
    },
    10,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    8,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Cough',
      value_id: 10,
    },
    10,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    8,
  ],
  [
    {
      field_name: 'ChiefComplaint',
      value: 'Cough',
      value_id: 10,
    },
    10,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    8,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    46,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    43,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    41,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    39,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    38,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    36,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    35,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    47,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    68,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    66,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    68,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    62,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    68,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    59,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    68,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    58,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    68,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    57,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    68,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    53,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    68,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    49,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    68,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    49,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    68,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    47,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    68,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    43,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    64,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    62,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    64,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    62,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    64,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    55,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    64,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    54,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    64,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    51,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    64,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    49,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    64,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    48,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    64,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    47,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    64,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    46,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    64,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    41,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    84,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    71,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    84,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    67,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    84,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    66,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    84,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    63,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    84,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    62,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    84,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    57,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    43,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    40,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    38,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    38,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    38,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    37,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    44,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    30,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    50,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    48,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    50,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    47,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    50,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    47,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    50,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    46,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    50,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    45,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    50,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    44,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    50,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    43,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    50,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    42,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    50,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    38,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    50,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    34,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    17,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    16,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    16,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    15,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
    {
      field_name: 'ChiefComplaint',
      value: 'Sore throat',
      value_id: 48,
    },
    14,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    14,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Empty',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    13,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    58,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    57,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    55,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    53,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    50,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    49,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    48,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    47,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    45,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    37,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    73,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    71,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    73,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    66,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    73,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    63,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    73,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    59,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    73,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    54,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    73,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    53,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    73,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    47,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    73,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    46,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    42,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    38,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    35,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    42,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    29,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    69,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    67,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    69,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    66,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    69,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    61,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    69,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    57,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    69,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    51,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    69,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    50,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    69,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    45,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    69,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    44,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    17,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    16,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    16,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    17,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    16,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    15,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    15,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    17,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    14,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    17,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    14,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    17,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    14,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Cough',
      value_id: 58,
      result_factor: 'Empty',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    17,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    13,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    52,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    50,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    52,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    49,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    52,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    48,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    52,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    48,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    52,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    47,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    52,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    47,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    52,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    46,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    52,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    44,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    52,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    39,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    52,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    37,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    35,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    27,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    65,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    63,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    65,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    63,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    65,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    61,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    65,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    53,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    65,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    49,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    65,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    47,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    36,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    37,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    27,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    35,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    35,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    26,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    36,
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    24,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    34,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    27,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    52,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    50,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    52,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    49,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    52,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    49,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    52,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    47,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    52,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    47,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    52,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    47,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    52,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    45,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    52,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    43,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    52,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    40,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    52,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    33,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    27,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    26,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    34,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    24,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    32,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    31,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    30,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    29,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    28,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    27,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    27,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    26,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    24,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    22,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    22,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    22,
  ],
  [
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    33,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    34,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    33,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    32,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    32,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    31,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    31,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    31,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    30,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    30,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    29,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    29,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    29,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    28,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    28,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    27,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    34,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    31,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    31,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    31,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    30,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    30,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    29,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    28,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    27,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    24,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    24,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    24,
  ],
  [
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    35,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    25,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    25,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    25,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    31,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    29,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    28,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    28,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    27,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Abdominal pain',
      value_id: 66,
      result_factor: 'Patient Reports',
      ros_system_id: 6,
      ros_system_name: 'Gastrointestinal',
    },
    32,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    25,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    25,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    25,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    29,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    27,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    27,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    27,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    25,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    30,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    25,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    25,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    25,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Dyspnea',
      value_id: 59,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    26,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Shortness of breath',
      value_id: 63,
      result_factor: 'Patient Reports',
      ros_system_id: 5,
      ros_system_name: 'Respiratory',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'VisitCodingCptCode',
      value: 'New Patient, Straightforward, 15-29 minutes',
      value_id: 185,
      code: '99202',
      category: 'E/M',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Vision changes',
      value_id: 21,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of conjunctivae and lids',
      value_id: 2,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    17,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    17,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Tinnitus',
      value_id: 45,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    24,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Diplopia',
      value_id: 12,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    17,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    27,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    27,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    26,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of oropharynx: oral mucosa, salivary glands, hard and soft palates, tongue, tonsils and posterior pharynx',
      value_id: 10,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    24,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    23,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of neck (e.g., masses, overall appearance, symmetry, tracheal position, crepitus)',
      value_id: 11,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sore throat',
      value_id: 43,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    27,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ExamSystemComponent',
      value:
        'General appearance of the patient (e.g., development, nutrition, body habitus, deformities, attention to grooming)',
      value_id: 1,
      result_factor: 'Empty',
      exam_system_id: 1,
      exam_system_name: 'Constitutional ',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of lungs (e.g., breath sounds, adventitious sounds, rubs)',
      value_id: 16,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus Pain',
      value_id: 41,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    21,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Assessment of respiratory effort (e.g., intercostal retractions, use of accessory muscles, diaphragmatic movement)',
      value_id: 13,
      result_factor: 'Empty',
      exam_system_id: 5,
      exam_system_name: 'Respiratory',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fever',
      value_id: 4,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    20,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Chills',
      value_id: 1,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Rhinorrhea / nasal drainage',
      value_id: 39,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus drainage',
      value_id: 40,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    19,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ExamSystemComponent',
      value:
        'External inspection of ear and nose (e.g., overall appearance, scars, lesions, masses)',
      value_id: 5,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Auscultation of heart with notation of abnormal sounds and murmurs',
      value_id: 18,
      result_factor: 'Empty',
      exam_system_id: 6,
      exam_system_name: 'Cardiovascular',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Fatigue',
      value_id: 3,
      result_factor: 'Patient Reports',
      ros_system_id: 1,
      ros_system_name: 'Constitutional',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Post-nasal drip',
      value_id: 38,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    18,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Otoscopic examination of external auditory canals and tympanic membranes',
      value_id: 6,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    17,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of lips, teeth and gums',
      value_id: 9,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    17,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Angina / chest pain/discomfort',
      value_id: 48,
      result_factor: 'Patient Reports',
      ros_system_id: 4,
      ros_system_name: 'Cardiovascular',
    },
    17,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ExamSystemComponent',
      value:
        'Examination of pupils and irises (e.g., reaction to light and accommodation, size and symmetry)',
      value_id: 3,
      result_factor: 'Empty',
      exam_system_id: 2,
      exam_system_name: 'Eyes',
    },
    16,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ExamSystemComponent',
      value: 'Inspection of nasal mucosa, septum and turbinates',
      value_id: 8,
      result_factor: 'Empty',
      exam_system_id: 3,
      exam_system_name: 'ENT/Mouth',
    },
    16,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ExamSystemComponent',
      value: 'Examination of thyroid (e.g., enlargement, tenderness, mass)',
      value_id: 12,
      result_factor: 'Empty',
      exam_system_id: 4,
      exam_system_name: 'Neck',
    },
    16,
  ],
  [
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Sinus pressure',
      value_id: 42,
      result_factor: 'Patient Reports',
      ros_system_id: 3,
      ros_system_name: 'ENT/Mouth',
    },
    22,
    {
      field_name: 'ReviewOfSystemSymptom',
      value: 'Blurred vision',
      value_id: 11,
      result_factor: 'Patient Reports',
      ros_system_id: 2,
      ros_system_name: 'Eyes',
    },
    16,
  ],
]
