import { styled } from '@mui/material'
import React from 'react'

const UserManagmentListContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: 12,
  position: 'relative',
})

export const ListContainer = ({
  children,
}: ListBoxContainerProps): JSX.Element => {
  return <UserManagmentListContainer>{children}</UserManagmentListContainer>
}

interface ListBoxContainerProps {
  children: JSX.Element | JSX.Element[] | undefined;
}
