import { styled } from '@mui/material'
import React from 'react'

const TitleLargeDiv = styled('div')({
  display: 'flex',
  width: '50%',
  alignContent: 'flex-end',
  justifyContent: 'left',
})

export const TitleLarge = ({ children }: TitleLargeProps): JSX.Element => {
  return <TitleLargeDiv>{children}</TitleLargeDiv>
}

interface TitleLargeProps {
  children: JSX.Element | JSX.Element[] | undefined;
}
