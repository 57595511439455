import { styled } from '@mui/material'
import { LeftInnerNav } from '../LeftInnerNav'
import { LeftOuterNav } from '../LeftOuterNav'
import React from 'react'

const NavColumnContainer = styled('div')({
  display: 'flex',
})
export const NavColumn = ({
  selectedIndex,
  isNavOpen,
  children,
}: NavColumnProps): JSX.Element => {
  return (
    <NavColumnContainer>
      {isNavOpen && (
        <LeftOuterNav selectedIndex={selectedIndex} isNavOpen={isNavOpen} />
      )}
      <LeftInnerNav isNavOpen={isNavOpen}>{children}</LeftInnerNav>
    </NavColumnContainer>
  )
}
interface NavColumnProps {
  selectedIndex: number;
  isNavOpen: boolean;
  children?: React.ReactNode;
}
