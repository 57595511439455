import React from 'react'
import { styled } from '@mui/system'
import QRCode from 'react-qr-code'

const StyledRectangle = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '600px',
  padding: '24px',
  backgroundColor: theme.palette.background.paper,
  flexDirection: 'column',
  gap: 8,
  height: 'auto',
  borderRadius: '12px',
}))

export const QRModal = ({ vId }: QRModalProp): JSX.Element => {
  return (
    <StyledRectangle>
      <QRCode
        size={256}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={`${
          window.location.href.split('scheduling')[0]
        }intake-confirmation/${vId}`}
        viewBox={'0 0 256 256'}
      />
    </StyledRectangle>
  )
}

interface QRModalProp {
  vId: number;
}
