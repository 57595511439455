import React from 'react'
import { styled, Typography } from '@mui/material'
import { PastVisitRow } from './TimeStampRow'
import { type AccountUser } from '@/types'
import { type AuditRecord } from '@/types/AudtiRecord'
import { isSameDay } from 'date-fns'

const PastVisitsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})
const TableBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '340px',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  overflowY: 'scroll',
})

const PastVisitHeader = styled('div')({
  display: 'flex',
  background: '#F6F8FD',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: '#DDDFE8',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  height: '40px',
  paddingLeft: '5px',
  paddingRight: '5px',
})
const DateColumn = styled('div')({
  width: '15%',
})
const TimeColumn = styled('div')({
  width: '15%',
})
const ProblemColumn = styled('div')({
  width: '10%',
})
const OrdersColumn = styled('div')({
  width: '22%',
})
const ProviderColumn = styled('div')({
  width: '16%',
})
const ColumnHeader = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',

  /* identical to box height, or 143% */

  color: 'black',
})
export const TimeStampTable = ({
  auditRecords,
  users,
}: PastVisitsProps): JSX.Element => {
  const findProvider = (id: number): string => {
    const provider = users.find((user) => user.id === id)
    if (provider !== undefined) {
      const firstName: string = provider.firstName ?? ''
      const lastName: string = provider.lastName ?? ''
      return `${firstName} ${lastName}`
    } else {
      return ''
    }
  }
  const Rows = React.useMemo(() => {
    let previousDate = ''
    return auditRecords.map((auditRecord) => {
      if (
        auditRecord.oldValues === undefined ||
        auditRecord.oldValues === null
      ) {
        return <></>
      }
      if (
        auditRecord.newValues === undefined ||
        auditRecord.newValues === null
      ) {
        return <></>
      }
      if (auditRecord.newValues === auditRecord.oldValues) {
        return <></>
      }
      let isNewDate
      if (previousDate === '') {
        isNewDate = true
      } else {
        isNewDate = !isSameDay(
          new Date(previousDate),
          new Date(auditRecord.dateTime ?? '')
        )
      }
      previousDate = auditRecord.dateTime ?? ''
      return (
        <PastVisitRow
          key={auditRecord.id}
          auditRecord={auditRecord}
          user={findProvider(auditRecord.accountUserId ?? 0)}
          isNewDate={isNewDate}
        />
      )
    })
  }, [auditRecords])

  return (
    <PastVisitsContainer>
      <PastVisitHeader>
        <DateColumn>
          <ColumnHeader>Date</ColumnHeader>
        </DateColumn>
        <TimeColumn>
          <ColumnHeader>Time</ColumnHeader>
        </TimeColumn>
        <ProblemColumn>
          <ColumnHeader>Field</ColumnHeader>
        </ProblemColumn>
        <OrdersColumn>
          <ColumnHeader>Old Value</ColumnHeader>
        </OrdersColumn>
        <OrdersColumn>
          <ColumnHeader>New Value</ColumnHeader>
        </OrdersColumn>
        <ProviderColumn>
          <ColumnHeader>User</ColumnHeader>
        </ProviderColumn>
      </PastVisitHeader>
      <TableBody>{Rows}</TableBody>
    </PastVisitsContainer>
  )
}
interface PastVisitsProps {
  auditRecords: AuditRecord[];
  users: AccountUser[];
}
