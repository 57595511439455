import { styled, useTheme, type Theme } from '@mui/material'
import React from 'react'

const TileLayoutContainer = styled('div')(
  ({ theme, inFocus, isLocked }: TileLayoutContainerProps) => ({
    boxSizing: 'border-box',
    /* Auto layout */
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '16px 24px 20px',
    gap: '20px',
    isolation: 'isolate',
    width: '812px',
    /* Black & White/White */
    background: theme.palette.background.default,
    /* Indigo/Shade 4 */
    border: '1px solid #DADCEC',
    borderRadius: '12px',
    ...(inFocus && {
      boxShadow:
        '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
    }),
    ...(isLocked && {
      pointerEvents: 'none',
      opacity: '0.75',
    }),
    scrollMarginTop: '200px',
  })
)

const Indicator = styled('div')({
  width: '3px',
  height: '22px',
  left: '0px',

  marginLeft: '-24px',

  /* Cobalt Blue/Shade 1 */
  background: '#0543B0',
  borderRadius: '0px 16px 16px 0px',
  marginBottom: '-42px',
  marginRight: '-3px',
})

export const TileLayout = ({
  children,
  onBlur = null,
  ref,
  id,
  inFocus,
  setInFocus,
  isLocked = false,
}: TileLayoutProps): JSX.Element => {
  const theme = useTheme()
  const handleOnBlur = (e: any): void => {
    if (onBlur !== null) {
      onBlur(e)
    }
    setInFocus(false)
  }
  const handleOnFocus = (): void => {
    setInFocus(true)
  }

  return (
    <TileLayoutContainer
      id={id}
      theme={theme}
      onFocus={handleOnFocus}
      inFocus={inFocus}
      ref={ref}
      onBlur={handleOnBlur}
      isLocked={isLocked}
    >
      {inFocus && <Indicator />}
      {children}
    </TileLayoutContainer>
  )
}

interface TileLayoutProps {
  onBlur?: any;
  children:
    | JSX.Element
    | JSX.Element[]
    | undefined
    | null
    | Array<JSX.Element | undefined | null>;
  ref: React.RefObject<HTMLDivElement>;
  id?: string | undefined;
  inFocus: boolean;
  setInFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
}

interface TileLayoutContainerProps {
  inFocus: boolean;
  theme: Theme;
  isLocked: boolean;
}
