import React from 'react'
import {
  Box,
  Typography,
  Stack,
  Radio,
  RadioGroup,
  Popover,
  FormControlLabel,
  type FormControlLabelProps,
  useRadioGroup,
  Tooltip,
  styled,
  FormGroup,
  Chip,
  Link,
  useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  type Patient,
  type AccountUser,
  type ProcedureCPTCode,
  type Procedure,
  type ProcedureType,
  type UrgentIQDocument,
  type DashboardProcedure,
} from '@/types'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import { format } from 'date-fns'
import { isEmpty } from '@/utility/utils'
import { useAuth, useUser } from '@clerk/nextjs'
import { useQueryGetAccountUsers, useMutateUpdateProcedure } from '@/hook'
import {
  StyledButton,
  StyledTextField,
  VitalsCard,
  StyledLabel,
  StyledProcedureStatus,
  BpCheckbox,
  OrdersActionButton,
} from './styles'
import {
  getClinicalProcedureFields,
  getDocumentsByProcedure,
} from '@/services'
import { uploadFile } from '@/helpers'

const StyledFormGroup = styled(FormGroup)({
  marginLeft: 'auto',
})

const MyFormControlLabel = (props: FormControlLabelProps): JSX.Element => {
  const radioGroup = useRadioGroup()

  let checked = false

  if (radioGroup != null) {
    checked = radioGroup.value === props.value
  }

  return <FormControlLabel checked={checked} {...props} />
}

const useStyles = makeStyles({
  root: {
    '& .MuiOutlinedInput-input': {
      border: 0,
      fontSize: 14,
    },
    '& .MuiSvgIcon-root': {
      width: '20px',
    },
    '& .MuiRadio-root': {
      width: '15px',
      height: '15px',
      marginRight: '5px',
      color: '#0543B0',
    },
    '& .MuiFormControlLabel-root': {
      alignItems: 'center',
      margin: '5px 0',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row',
      gap: '10px',
    },
  },
})

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#E5EBFF',
  borderColor: theme.palette.primary.main,
  borderWidth: '1px',
  borderStyle: 'solid',
  height: '24px',
  size: '14px',
  weight: '400',
  maxWidth: '60px',
}))

export const ProcedureStatusPopup = ({
  patient,
  procedure,
  showStatus = true,
  chiefProcedures,
  onUpdate,
  providerId,
  visitId,
}: ProcedureStatusPopupProps): JSX.Element => {
  const { getToken } = useAuth()
  const { user } = useUser()
  const [posNegField, setPosNegField] = React.useState({ id: 0, value: '' })
  const inputRef = React.useRef<HTMLInputElement>(null)

  const theme = useTheme()

  React.useEffect(() => {
    Promise.resolve(
      getClinicalProcedureFields(procedure?.clinicalProcedureId ?? 0, getToken)
    )
      .then((res) => {
        if (
          res?.find(
            (field: any) =>
              field?.dataType?.toLowerCase() === 'positivenegative'
          ) !== undefined
        ) {
          const tempId = res?.find(
            (field: any) =>
              field?.dataType?.toLowerCase() === 'positivenegative'
          )?.id
          const result = procedure?.visitClinicalProcedureFieldList?.find(
            (field: any) => field?.clinicalProcedureFieldId === tempId
          )?.valueBoolean
          setPosNegField({
            id: tempId,
            value:
              result === true ? 'positive' : result === false ? 'negative' : '',
          })
        }
      })
      .catch((err) => {
        throw err
      })
  }, [procedure?.clinicalProcedureId])

  const { data: providers } = useQueryGetAccountUsers(getToken)
  const [currentProvider, setCurrentProvider] = React.useState<AccountUser>()
  const mprocedureStatusId = procedure.clinicalProcedureStatusId ?? 1
  const [procedureStatus, setProcedureStatus] =
    React.useState(mprocedureStatusId)
  const [currentChiefProcedure, setCurrentChiefProcedure] =
    React.useState<Procedure>()
  const mtextResults = procedure.textResult ?? ''
  const [textResults, setTextResults] = React.useState(mtextResults)
  const classes = useStyles()
  const updateProcedureMutation = useMutateUpdateProcedure(getToken)
  const [documents, setDocuments] = React.useState<UrgentIQDocument[]>([])

  // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version ?? ''}/pdf.worker.js`

  React.useEffect(() => {
    if (isEmpty(providers) || providerId === 0) return
    const provider = providers?.find(
      (provider: AccountUser) => provider.id === providerId
    )
    setCurrentProvider({ ...provider })
  }, [providers, providerId])
  React.useEffect(() => {
    if (isEmpty(chiefProcedures)) return
    chiefProcedures?.forEach((chiefProcedure: Procedure) => {
      const cProcedure = chiefProcedure?.visitClinicalProcedureList?.find(
        (cpt: ProcedureCPTCode) => cpt.id === procedure.id
      )
      if (cProcedure !== undefined) {
        setCurrentChiefProcedure({ ...chiefProcedure })
      }
    })
    loadDocuments()
  }, [chiefProcedures])

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setProcedureStatus(parseInt((event.target as HTMLInputElement).value))
  }

  const formatProcedureColor = (status: number): string[] => {
    switch (status) {
      case 1:
        return ['#ffccbc', '#d84315']
      case 2:
        return ['#fff9c4', '#f9a825']
      case 3:
        return ['#b2dfdb', '#00796b']
      default:
        return ['#ffccbc', '#d84315']
    }
  }
  const parseProcedureStatus = (status: number): string => {
    switch (status) {
      case 1:
        return 'New'
      case 2:
        return 'Pending'
      case 3:
        return 'Done'
      default:
        return 'New'
    }
  }

  const onChangeTextResult = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setTextResults(event.target.value)
  }

  const updateTextPDFResults = async (): Promise<void> => {
    if (isEmpty(procedure?.visitId) || isEmpty(currentChiefProcedure)) return
    const cProcedureCptCodeList =
      currentChiefProcedure?.visitClinicalProcedureList?.map(
        (cProcedure: ProcedureCPTCode) => {
          if (procedure?.id === cProcedure?.id) {
            cProcedure.clinicalProcedureStatusId = procedureStatus
            cProcedure.textResult = textResults
            if (posNegField.id > 0) {
              const newProcedureCptCodeList =
                cProcedure.visitClinicalProcedureFieldList?.filter(
                  (cpt: any) => cpt.clinicalProcedureFieldId !== posNegField.id
                ) ?? []
              cProcedure.visitClinicalProcedureFieldList =
                newProcedureCptCodeList
              if (!isEmpty(posNegField.value)) {
                cProcedure.visitClinicalProcedureFieldList = [
                  ...cProcedure.visitClinicalProcedureFieldList,
                  {
                    clinicalProcedureFieldId: posNegField.id,
                    valueBoolean: posNegField.value === 'positive',
                    visitClinicalProcedureId:
                      cProcedure.clinicalProcedureId ?? 0,
                  },
                ]
              }
            }
          }
          return cProcedure
        }
      )

    updateProcedureMutation
      .mutateAsync({
        vId: procedure?.visitId,
        chiefProcedure: {
          ...currentChiefProcedure,
          visitClinicalProcedureList: cProcedureCptCodeList,
        },
      })
      .then(() => onUpdate())
      .catch((err) => {
        throw err
      })
  }
  const onCancel = (): void => {
    setTextResults(procedure?.procedureTextResult ?? '')
    setProcedureStatus(procedure.visitProcedureCptCodeOrderStatusId ?? 1)
  }
  const onSave = (): void => {
    updateTextPDFResults().catch((error) => {
      throw error
    })
  }
  const onFileBrowse = (): void => {
    if (!isEmpty(inputRef.current)) {
      inputRef?.current?.click()
    }
  }
  const onViewPDFResult = (document: UrgentIQDocument): void => {
    if (isEmpty(document?.id)) return
    window.open(document?.presignedUrl!, '_blank')
  }
  const loadDocuments = (): void => {
    Promise.resolve(getDocumentsByProcedure(procedure?.id ?? 0, getToken))
      .then((res) => {
        setDocuments(res)
      })
      .catch((err) => {
        throw err
      })
  }
  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const files = event.target.files
    const errors = []
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        try {
          uploadFile(
            file,
            patient.id ?? 0,
            4,
            procedure?.id ?? 0,
            procedure?.visitId ?? 0,
            undefined,
            user?.publicMetadata.account_user_id as number,
            getToken
          )
            .then(() => {
              loadDocuments()
            })
            .catch((err) => {
              throw err
            })
        } catch (error) {
          errors.push(error)
        }
      }

      if (errors.length > 0) {
        // Handle errors here
        console.error(errors)
      }
    }
  }

  React.useEffect(() => {
    setProcedureStatus(mprocedureStatusId)
  }, [mprocedureStatusId])

  React.useEffect(() => {
    setTextResults(mtextResults)
  }, [mtextResults])

  return (
    <PopupState variant="popover" popupId="orders-popover">
      {/* prettier-ignore */}
      {(popupState) =>
        // prettier-ignore
        !isEmpty(procedure?.name)
            // prettier-ignore
            ? (
              // prettier-ignore
              <Box>
                <StyledProcedureStatus {...bindTrigger(popupState)} style={{ backgroundColor: formatProcedureColor(mprocedureStatusId)[0], color: 'black' }}>
                  <Tooltip title={procedure?.name} arrow>
                    <Box style={{ textOverflow: 'ellipsis', width: 'fit-content', maxWidth: '125px', overflow: 'hidden' }}>{showStatus ? parseProcedureStatus(mprocedureStatusId) : procedure?.name}</Box>
                  </Tooltip>
                </StyledProcedureStatus>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                  }}
                >
                  <VitalsCard>
                    <Stack direction="column">
                      <Typography
                        sx={{ fontSize: '14px', fontWeight: 600, marginTop: '-2px' }}
                        mb={1}
                      >
                        {procedure?.name}
                      </Typography>
                      <Stack direction="column">
                      <Link href={
                        `/charting/${visitId}?previousNavOpen=false&sectionId=procedures`
                      }
                        style={{
                          marginTop: '-8px',
                          marginBottom: '4px',
                          fontSize: '14px',
                          fontWeight: 600,
                          textAlign: 'left',
                        color: theme.palette.primary.main,
                        textDecorationColor: theme.palette.primary.main
                        }}
                      >
                        View in Chart
                      </Link>
                        <Stack direction="row">
                          <StyledLabel>
                            Patient Name:
                          </StyledLabel>
                          <Typography
                            sx={{ fontSize: '12px' }}
                            mb={1}
                          >
                            {`${patient?.firstName!} ${patient?.lastName!}`}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <StyledLabel>
                            Date & Time:
                          </StyledLabel>
                          <Typography
                            sx={{ fontSize: '12px' }}
                            mb={1}
                          >
                            {format(new Date(procedure?.updatedAt === '0001-01-01T00:00:00+00:00' ? procedure?.readOnlyCreatedAt : procedure?.updatedAt), 'MM/dd/yyyy hh:mm a')}
                          </Typography>
                        </Stack>
                        <Stack direction="row">
                          <StyledLabel>
                            Provider:
                          </StyledLabel>
                          <Stack direction="column">
                            <Typography
                              sx={{ fontSize: '12px' }}
                              mb={1}
                            >
                              {`${currentProvider?.firstName! ?? ''} ${currentProvider?.lastName! ?? ''}`}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack direction="column">
                        <Stack
                          direction="column"
                          className={classes.root}
                          sx={{ mb: 2 }}
                        >
                          <Typography
                            sx={{ fontSize: '13px', fontWeight: 700, color: '#0A1A32' }}
                            mb={1}
                          >
                            Status
                          </Typography>
                          <RadioGroup
                            name="use-radio-group"
                            defaultValue={1}
                            value={procedureStatus}
                            onChange={handleRadioChange}
                          >
                            <MyFormControlLabel
                              value={1}
                              label="New"
                              control={<Radio />}
                            />
                            <MyFormControlLabel
                              value={2}
                              label="Pending"
                              control={<Radio />}
                            />
                            <MyFormControlLabel
                              value={3}
                              label="Done"
                              control={<Radio />}
                            />
                          </RadioGroup>
                        </Stack>
                      <Stack
                        direction="row"
                        alignItems='center'
                        sx={{ width: '100%', mb: 1 }}
                        className={classes.root}
                      >
                          <Typography
                              sx={{ fontSize: '13px', fontWeight: 700, color: '#0A1A32' }}
                            >
                              Note
                            </Typography>
                            {posNegField.id > 0 && <StyledFormGroup row>
                              <FormControlLabel
                                control={
                                  <BpCheckbox
                                    checked={posNegField.value === 'positive'}
                                    onChange={() => {
                                      setPosNegField({
                                        id: posNegField.id,
                                        value: posNegField.value === 'positive' ? '' : 'positive'
                                      })
                                    }}
                                    name='positive'
                                  />
                                }
                                label='Positive'
                              />
                              <FormControlLabel
                                control={
                                  <BpCheckbox
                                    checked={posNegField.value === 'negative'}
                                    onChange={() => {
                                      setPosNegField({
                                        id: posNegField.id,
                                        value: posNegField.value === 'negative' ? '' : 'negative'
                                      })
                                    }}
                                    name="negative"
                                  />
                                }
                                label="Negative"
                              />
                          </StyledFormGroup>}
                      </Stack>
                      <Stack
                          direction="column"
                          className={classes.root}
                          sx={{ mb: 2 }}
                        >
                          <StyledTextField
                            value={textResults}
                            onChange={onChangeTextResult}
                            maxRows={4}
                            minRows={2}
                          />
                        </Stack>
                        <Stack
                          direction="column"
                          className={classes.root}
                          sx={{ mb: 2 }}
                        >
                          <Typography
                            sx={{ fontSize: '13px', fontWeight: 700, color: '#0A1A32' }}
                            mb={1}
                          >
                            PDF Results
                          </Typography>
                          <Stack direction='row' gap={1}>
                            <Box sx={{ width: '210px' }}>
                              <Stack direction='row' gap={1} flexWrap='wrap'>
                                {
                                  documents?.map((document: UrgentIQDocument) => {
                                    return (
                                      <Tooltip key={document.readOnlyCreatedAt} title={document?.visibleDocumentName} arrow>
                                        <StyledChip label={document?.visibleDocumentName} onClick={() => onViewPDFResult(document)} />
                                      </Tooltip>
                                    )
                                  })
                                }
                              </Stack>
                              <input
                                id="pdf-uploader"
                                type="file"
                                accept="application/pdf"
                                onChange={(e) => { void handleFileSelect(e) }}
                                style={{ display: 'none' }}
                                ref={inputRef}
                                multiple
                              />
                            </Box>
                            <Stack direction='column' gap={1}>
                              <Tooltip title={'Upload PDF'} arrow>
                                <OrdersActionButton onClick={onFileBrowse}>
                                  <FileUploadIcon style={{ width: '14px', height: '14px', color: '#252D63' }} />
                                </OrdersActionButton>
                              </Tooltip>
                            </Stack>
                          </Stack>
                        </Stack>

                        <Stack
                          direction="row"
                          sx={{ gap: 1 }}
                          justifyContent={'flex-end'}
                        >
                          <StyledButton
                            sx={{ backgroundColor: 'white', color: '#0543B0', border: '1px solid #0543B0' }}
                            onClick={() => {
                              onCancel()
                              popupState.close()
                            }}
                          >
                            Cancel
                          </StyledButton>
                          <StyledButton variant='contained' sx={{ backgroundColor: '#0543B0' }} onClick={() => {
                            onSave()
                            popupState.close()
                          }}>
                            Save
                          </StyledButton>
                        </Stack>
                    </Stack>
                  </VitalsCard>
                </Popover>
              </Box>
              )
            : <></>
      }
    </PopupState>
  )
}

interface ProcedureStatusPopupProps {
  patient: Patient;
  procedure: DashboardProcedure;
  mode: string;
  isProcedure: boolean;
  isOrder: boolean;
  showStatus?: boolean;
  chiefProcedures: Procedure[];
  clinicalProcedure: ProcedureType;
  onUpdate: () => void;
  providerId: number;
  visitId?: number;
}
