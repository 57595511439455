import { styled, type Theme, Typography } from '@mui/material'

export const TemplateButtonRow = styled('div')({
  display: 'flex',
  height: '60px',
  gap: 8,
  padding: '4px',
  marginRight: '4px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: '#F7F8FC',
  overflow: 'hidden',
  position: 'fixed',
  bottom: 0,
  width: '100vw',
  zIndex: 8,
})

export const TemplateNameContainer = styled('div')(
  ({ isNavOpen, theme }: TemplateNameContainerProps) => ({
    /* Auto layout */
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',
    padding: '10px 20px',
    position: 'fixed',
    width: '100vw',
    height: '62px',
    gap: '5px',
    background: theme.palette.background.default,
    borderBottom: '1px solid #E6E7EF',
    boxShadow: '0px 4px 4px rgba(64, 71, 118, 0.05)',
    zIndex: 11,
    marginTop: '60px',
    ...(isNavOpen && { marginLeft: '80px' }),
    ...(isNavOpen && { paddingRight: '100px' }),
  })
)

export const TemplateTitle = styled(Typography)({
  fontSize: '16px',
  lineHeight: '19px',
  textDecorationLine: 'underline',
  color: '#000000',
})

export const TemplateName = styled(Typography)({
  fontWeight: 700,
  fontSize: '16px',
  lineHeight: '19px',
  color: '#000000',
})

export interface TemplateNameContainerProps {
  isNavOpen: boolean;
  theme: Theme;
}
