import { Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'

const SectionLabelContainer = styled(Typography)({
  fontSize: '16px',
  lineHeight: '18.75px',
  color: 'black',
  marginBottom: '20px',
})

export const SectionLabel = ({
  sectionLabelText,
}: SectionLabelProps): JSX.Element => {
  return <SectionLabelContainer>{sectionLabelText}</SectionLabelContainer>
}

interface SectionLabelProps {
  sectionLabelText: string;
}
