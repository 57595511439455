import React from 'react'
import {
  TileRow,
  TileColumn,
  NotesRow,
  AddTable,
  FamilyHistoryRow,
  TileSearchBox,
  HeaderPMH,
} from '@/components'
import { IconButton } from '@mui/material'
import {
  type MedicationConditionType,
  type PatientFamilyHistory,
} from '@/types'
import { isEmpty } from '@/utility/utils'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useRelationshipStore } from '@/hook'

export const FamilyHistory = ({
  patientId,
  familyHistories,
  addFamilyHistory,
  deleteFamilyHistory,
  notes,
  setNotes,
  familyHistoryMedicalConditionId,
  setFamilyHistoryMedicalConditionId,
  familyHistoryRelationshipId,
  setFamilyHistoryRelationshipId,
  isFamilyHistoryVisible,
  setIsFamilyHistoryVisible,
  medicalConditionsTypes,
}: FamilyHistoryProps): JSX.Element => {
  const [problem, setProblem] = React.useState<{ id: number; label: string }>({
    id: 0,
    label: '',
  })
  const [inputValue, setInput] = React.useState<string>('')
  const [relationship, setRelationship] = React.useState<{
    id: number;
    label: string;
  }>({ id: 0, label: '' })
  const [inputRelationshipValue, setInputRelationship] =
    React.useState<string>('')
  const { relationships } = useRelationshipStore()

  React.useEffect(() => {
    setProblem({ id: 0, label: '' })
    setRelationship({ id: 0, label: '' })
    setInput('')
    setInputRelationship('')
  }, [patientId])

  const handleAddFamilyHistory = (): void => {
    if (
      (familyHistoryRelationshipId >= 0 || inputValue !== '') &&
      (relationship.id >= 0 || inputRelationshipValue !== '')
    ) {
      addFamilyHistory({
        patientId,
        ...(familyHistoryRelationshipId > 0
          ? { relationshipId: familyHistoryRelationshipId }
          : { otherRelationship: inputRelationshipValue }),
        ...(familyHistoryMedicalConditionId > 0
          ? { medicalConditionId: familyHistoryMedicalConditionId }
          : { otherProblem: inputValue }),
      }).catch((err) => {
        throw err
      })
      setFamilyHistoryRelationshipId(0)
      setFamilyHistoryMedicalConditionId('')
      setInput('')
      setInputRelationship('')
      setRelationship({ id: 0, label: '' })
      setProblem({ id: 0, label: '' })
    }
  }
  const handleRelationshipChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    if (newValue === null) {
      setRelationship({ id: 0, label: '' })
      setFamilyHistoryRelationshipId(0)
    } else {
      setRelationship({ id: newValue.id ?? 0, label: newValue.label ?? '' })
      setFamilyHistoryRelationshipId(newValue.id ?? 0)
    }
  }

  const handleProcedureTypeChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    if (newValue === null) {
      setProblem({ id: 0, label: '' })
      setFamilyHistoryMedicalConditionId(0)
    } else {
      setProblem({ id: newValue.id ?? 0, label: newValue.label ?? '' })
      setFamilyHistoryMedicalConditionId(newValue.id ?? 0)
    }
  }
  const handleDeleteFamilyHistory = (
    familyHistory: PatientFamilyHistory
  ): void => {
    deleteFamilyHistory(familyHistory).catch((err) => {
      throw err
    })
  }

  const handleEditFamilyHistory = (
    familyHistory: PatientFamilyHistory
  ): void => {
    const medicalConditionName = medicalConditionsTypes.find(
      (medicalCondition: MedicationConditionType) =>
        medicalCondition.id === familyHistory.medicalConditionId
    )?.name
    const relationshipName = relationships.find(
      (relationship: any) => relationship.id === familyHistory.relationshipId
    )?.name
    if (familyHistory.medicalConditionId) {
      setProblem({
        id: familyHistory.medicalConditionId ?? 0,
        label: medicalConditionName ?? '',
      })
    } else {
      setProblem({ id: 0, label: '' })
    }
    if (familyHistory.relationshipId) {
      setRelationship({
        id: familyHistory.relationshipId ?? 0,
        label: relationshipName ?? '',
      })
    } else {
      setRelationship({ id: 0, label: '' })
    }
    if (familyHistory.otherProblem) {
      setInput(familyHistory.otherProblem)
    } else {
      setInput(medicalConditionName ?? '')
    }
    if (familyHistory.otherRelationship) {
      setInputRelationship(familyHistory.otherRelationship)
    } else {
      setInputRelationship(relationshipName ?? '')
    }
    setFamilyHistoryRelationshipId(familyHistory.relationshipId ?? 0)
    setFamilyHistoryMedicalConditionId(familyHistory.medicalConditionId ?? '')
    handleDeleteFamilyHistory(familyHistory ?? '')
  }

  const buildConditions = (): JSX.Element => {
    return (
      <AddTable>
        {familyHistories.map((condition) => {
          return (
            <FamilyHistoryRow
              key={condition.id}
              familyHistory={condition}
              relationships={relationships}
              delete={handleDeleteFamilyHistory}
              edit={handleEditFamilyHistory}
            />
          )
        })}
      </AddTable>
    )
  }
  /* prettier-ignore */
  const buildFamilyHistories = (): JSX.Element => {
    return (
      <>
        <TileRow key={'family'} id={'family'}>
          <TileSearchBox
            dataTestId='familyHistoryMedicalConditionId'
            label={'Problem'}
            value={problem}
            inputValue={inputValue}
            setInputValue={setInput}
            twoColumns
            onChange={(event: any, value: any) =>
              handleProcedureTypeChange(event, value)
            }
            options={medicalConditionsTypes.map(st => {
              return (
                {
                  id: st.id ?? 0,
                  label: st.name ?? ''
                }
              )
            }
            )
            }
          />
          <TileSearchBox
            dataTestId='familyHistoryRelationship'
            label={'Relationship'}
            value={relationship}
            inputValue={inputRelationshipValue}
            setInputValue={setInputRelationship}
            onChange={(event: any, value: any) =>
              handleRelationshipChange(event, value)
            }
            options={relationships.map(relationship => {
              return (
                {
                  id: relationship.id ?? 0,
                  label: relationship.name ?? ''
                }
              )
            })}
            />
        </TileRow>
      </>
    )
  }
  return (
    <div>
      <TileRow>
        <TileColumn>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <HeaderPMH>Family History</HeaderPMH>
            <IconButton
              onClick={() => setIsFamilyHistoryVisible(!isFamilyHistoryVisible)}
              data-testid="familyHistoryButton"
              size="large"
            >
              {
                // prettier-ignore
                isFamilyHistoryVisible
                  ? (<KeyboardArrowDownIcon fontSize='small' sx={{ color: '#000000' }} />)
                  : (<KeyboardArrowRightIcon fontSize='small' sx={{ color: '#000000' }} />)
              }
            </IconButton>
          </div>
          {
            // prettier-ignore
            isFamilyHistoryVisible
              // prettier-ignore
              ? (
                // prettier-ignore
                <>
                  {/* prettier-ignore */}
                  {buildFamilyHistories()}
                  {/* prettier-ignore */}
                  <NotesRow
                    notes={notes}
                    setNotes={setNotes}
                    title={'Add Family History'}
                    handleAdd={handleAddFamilyHistory}
                    expanded={!isEmpty(notes)}
                  />
                  {/* prettier-ignore */}
                </>)
              /* prettier-ignore */
              : (
                /* prettier-ignore */
                <></>
                /* prettier-ignore */
                )
          }
        </TileColumn>
      </TileRow>
      {
        /* prettier-ignore */
        familyHistories.length > 0 && isFamilyHistoryVisible
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              {/* prettier-ignore */}
              {buildConditions()}
              {/* prettier-ignore */}
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }
    </div>
  )
}

interface FamilyHistoryProps {
  patientId: number;
  familyHistories: PatientFamilyHistory[];
  addFamilyHistory: (familyHistory: PatientFamilyHistory) => Promise<void>;
  deleteFamilyHistory: (familyHistory: PatientFamilyHistory) => Promise<void>;
  notes: string;
  setNotes: (notes: string) => void;
  familyHistoryRelationshipId: number;
  setFamilyHistoryRelationshipId: any;
  familyHistoryMedicalConditionId: number;
  setFamilyHistoryMedicalConditionId: any;
  isFamilyHistoryVisible: boolean;
  setIsFamilyHistoryVisible: any;
  medicalConditionsTypes: MedicationConditionType[];
}
