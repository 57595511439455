import React from 'react'
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Slider,
  Stack,
  styled,
  TextField,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { formatISO } from 'date-fns'
import {
  type HistoryOfPresentIllness,
  type Complaint,
  type Symptom,
} from '@/types'
import {
  Title,
  TileLayout,
  TileRow,
  TileColumn,
  TileTextArea,
  AddButton,
  AddTable,
  HPIRow,
  PillSearchBox,
  IQSuggestionAutoComplete,
} from '@/components'
import { formatInUTC } from '@/utility/TimeZoneHelper'
import { type IllnessLocation } from '@/types/IllnessLocation'
import { useQueryGetVisit, useIQSuggestionStore, useGlobalStore } from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { type IQSuggestionOptionType } from '../components/IQSuggestionAutoComplete/IQSuggestionAutoComplete'
import { dateOnlyToDate } from '@/utility'
import { type IllnessModifyingFactor } from '@/types/IllnessModifyingFactor'

const suggestionsCount = process.env.IQ_SUGGESTIONS_COUNT
  ? parseInt(process.env.IQ_SUGGESTIONS_COUNT)
  : 10

const StyleInputLabel = styled(InputLabel)({
  fontSize: '14px',
  lineHeight: '24px',
  color: '#222222',
})
const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '240px',
  height: '40px !important',
  '& > div': {
    borderRadius: 0,
    height: '40px',
    backgroundColor: theme.palette.white,
  },
}))

const onsetAmountOptions = [0, 1, 2, 3, 4, 5, 6]
const onsetIncrementOptions = ['Day(s)', 'Week(s)', 'Month(s)', 'Year(s)']

export const StyledTextButton = styled(Button)({
  width: '90x',
  height: '14px',
  padding: 0,
  fontWeight: '300',
  paddingRight: 0,
  fontSize: '12px',
  lineHeight: '14px',
  /* identical to box height */
  textAlign: 'right',
  color: '#808080',
  border: 'none',
  borderRadius: 0,
  '&:hover': {
    width: '90x',
  },
})

interface Mark {
  value: number;
  label: string;
}

export const HistoryPresentIllness = ({
  chiefComplaints,
  symptoms,
  historyPresentIllness,
  setHistoryPresentIllness,
  visitId,
  ref,
  inFocus,
  setInFocus,
  locations,
  modifyingFactors,
  totalLocations,
  isLocked = false,
  isDirty = false,
  setIsDirty = () => {},
  symptomIds,
  setSymptomIds,
  locationIds,
  setLocationIds,
  modifyingfactorIds,
  setModifyingFactorIds,
  selectComplaint,
  setSelectComplaint,
}: HistoryPresentIllnessProps): JSX.Element => {
  const [marks, setMarks] = React.useState<Mark[]>([])
  const { setIsEditing } = useGlobalStore()
  const handleComplaintChange = (
    event: any,
    newValue: {
      id: number;
      label: string;
    } | null
  ): void => {
    setSelectComplaint(newValue)
    setOnset(new Date())
    setOnsetAmount(0)
    setOnsetIncrement('Day(s)')
    setContext('')
    setSeverityValue(null)
    setSymptomIds([])
    setLocationIds([])
    setModifyingFactorIds([])
    setIsEditing(true)
  }
  const [severityValue, setSeverityValue] = React.useState<
    number | null | undefined
  >(null)
  const [currentEditingId, setCurrentEditingId] = React.useState<number>(-1)
  const handleSeverityValueChange = (event: any): void => {
    setSeverityValue(event.target.value)
  }
  const { getToken } = useAuth()
  const { data: VisitData = {} } = useQueryGetVisit(visitId, getToken)
  const { iqSuggestions } = useIQSuggestionStore()
  const [finalSymptoms, setFinalSymptoms] = React.useState<
    IQSuggestionOptionType[]
  >([])
  const [finalLocations, setFinalLocations] = React.useState<
    IQSuggestionOptionType[]
  >([])
  React.useEffect(() => {
    const updatedSymptoms = symptoms.map((symptom) => {
      const score =
        iqSuggestions.find((item) => item.hpiSymptomId === symptom.id)?.score ??
        0
      return { ...symptom, score }
    })
    const updatedLocations = locations.map((location) => {
      const score =
        iqSuggestions.find((item) => item.hpiLocationId === location.id)
          ?.score ?? 0
      return { ...location, score }
    })
    const finalOptions = [] as IQSuggestionOptionType[]
    const finalLocations = [] as IQSuggestionOptionType[]
    updatedSymptoms
      .sort((a, b) => b.score - a.score)
      .forEach((symptom, index) => {
        if (index < suggestionsCount) {
          finalOptions.push({
            id: symptom.id ?? 0,
            label: symptom.name ?? '',
            category: 'SUGGESTED',
          })
        } else {
          finalOptions.push({
            id: symptom.id ?? 0,
            label: symptom.name ?? '',
            category: 'OTHER SYMPTOMS',
          })
        }
      })
    updatedLocations
      .sort((a, b) => b.score - a.score)
      .forEach((location, index) => {
        if (index < suggestionsCount) {
          finalLocations.push({
            id: location.id ?? 0,
            label: location.name ?? '',
            category: 'SUGGESTED',
          })
        } else {
          finalLocations.push({
            id: location.id ?? 0,
            label: location.name ?? '',
            category: 'OTHER LOCATIONS',
          })
        }
      })
    const otherSets = finalOptions
      .filter((option) => option.category === 'OTHER SYMPTOMS')
      .sort((a, b) => a.label.localeCompare(b.label))
    const result = finalOptions
      .filter((option) => option.category === 'SUGGESTED')
      .concat(otherSets)
    setFinalLocations(finalLocations)
    setFinalSymptoms(result)

  }, [iqSuggestions, symptoms, locations])
  const handleSymptomsChange = (
    event: any,
    newValue: Array<{
      id: number;
      label: string;
    }> | null
  ): void => {
    if (newValue === null) {
      setSymptomIds([])
    } else {
      setSymptomIds(newValue)
    }
    setIsEditing(true)
  }
  const handleLocationChange = (
    event: any,
    newValue: Array<{
      id: number;
      label: string;
    }> | null
  ): void => {
    if (newValue === null) {
      setLocationIds([])
    } else {
      setLocationIds(newValue)
    }
    setIsEditing(true)
  }
  const handleModifyingFactorsChange = (
    event: any,
    newValue: Array<{
      id: number
      label: string
    }> | null
  ): void => {
    if (newValue === null) {
      setModifyingFactorIds([])
    } else {
      setModifyingFactorIds(newValue)
    }
    setIsEditing(true)
  }
  const [context, setContext] = React.useState<string>('')
  const handleContextChange = (event: any): void => {
    setContext(event.target.value)
    setIsEditing(true)
  }
  const [inputMethod, setInputMethod] = React.useState<string>('')
  const [onsetAmount, setOnsetAmount] = React.useState<number>(0)
  const [onsetIncrement, setOnsetIncrement] = React.useState<string>('Day(s)')
  const [onset, setOnset] = React.useState<Date>(new Date())
  const handleOnsetChange = (event: any): void => {
    setInputMethod('date')
    setOnset(event)
    setIsEditing(true)
  }

  React.useEffect(() => {
    const tempMarks: Mark[] = []
    for (let i = 0; i <= 10; i++) {
      tempMarks.push({
        value: i,
        label: i.toString(),
      })
    }
    setMarks(tempMarks)
  }, [])

  React.useEffect(() => {
    if (chiefComplaints.length === 1) {
      setSelectComplaint({
        id: chiefComplaints[0].id,
        label: chiefComplaints[0].name,
      })
    }
  }, [chiefComplaints])
  const handleAddHPISymptom = (): void => {
    setIsDirty(true)
    if (!selectComplaint && symptomIds.length === 0) {
      return
    }
    setHistoryPresentIllness([
      ...historyPresentIllness,
      {
        visitId,
        onset: formatInUTC(formatISO(new Date(onset))),
        historyOfPresentIllnessComplaintList: [selectComplaint].map((c) => ({
          complaintId: c?.id,
        })),
        historyOfPresentIllnessSymptomList: symptomIds.map((s) => ({
          historyOfPresentIllnessSymptomId: s.id,
        })),
        historyOfPresentIllnessLocationList: locationIds.map((l) => {
          return { historyOfPresentIllnessLocationId: l.id }
        }),
        historyOfPresentIllnessModifyingFactorList: modifyingfactorIds.map((m) => {
          return { historyOfPresentIllnessModifyingFactorsId: m.id }
        }),
        context,
        severity: severityValue,
      },
    ])

    setOnset(new Date())
    setOnsetAmount(0)
    setOnsetIncrement('Day(s)')
    setSelectComplaint(null)
    setContext('')
    setSeverityValue(null)
    setSymptomIds([])
    setLocationIds([])
    setModifyingFactorIds([])
    setIsEditing(false)
  }

  const handleSaveHPISymptom = (): void => {
    setIsDirty(true)
    if (!selectComplaint && symptomIds.length === 0) {
      return
    }
    setHistoryPresentIllness(
      historyPresentIllness.map((condition) => {
        if (condition.id === currentEditingId) {
          return {
            visitId,
            onset: formatInUTC(formatISO(new Date(onset))),
            historyOfPresentIllnessComplaintList: [selectComplaint].map(
              (c) => ({
                complaintId: c?.id,
              })
            ),
            historyOfPresentIllnessSymptomList: symptomIds.map((s) => ({
              historyOfPresentIllnessSymptomId: s.id,
            })),
            historyOfPresentIllnessLocationList: locationIds.map((l) => {
              return { historyOfPresentIllnessLocationId: l.id }
            }),
            historyOfPresentIllnessModifyingFactorList: modifyingfactorIds.map((m) => {
              return { historyOfPresentIllnessModifyingFactorsId: m.id }
            }),
            context,
            severity: severityValue,
          }
        }
        return condition
      })
    )

    setOnset(new Date())
    setOnsetAmount(0)
    setOnsetIncrement('Day(s)')
    setSelectComplaint(null)
    setContext('')
    setSeverityValue(null)
    setSymptomIds([])
    setLocationIds([])
    setModifyingFactorIds([])
    setCurrentEditingId(-1)
    setIsEditing(false)
  }

  const handleChangeOnsetIncrement = (e: SelectChangeEvent<string>): void => {
    setInputMethod('increment')
    setOnsetIncrement(e.target.value)
    setIsEditing(true)
  }

  const handleChangeOnsetAmount = (e: SelectChangeEvent<number>): void => {
    setOnsetAmount(+e.target.value)
    setInputMethod('increment')
    setIsEditing(true)
  }

  React.useEffect(() => {
    updateOnsetFromIncrement()
  }, [onsetAmount, onsetIncrement])

  const updateOnsetFromIncrement = (): void => {
    const onsetDate: Date = new Date(VisitData?.arrivalDateTime ?? '')
    switch (onsetIncrement) {
      case 'Day(s)':
        onsetDate.setDate(onsetDate.getDate() - onsetAmount)
        break
      case 'Week(s)':
        onsetDate.setDate(onsetDate.getDate() - onsetAmount * 7)
        break
      case 'Month(s)':
        onsetDate.setMonth(onsetDate.getMonth() - onsetAmount)
        break
      case 'Year(s)':
        onsetDate.setFullYear(onsetDate.getFullYear() - onsetAmount)
        break
      default:
    }
    setOnset(onsetDate)
  }

  const handleDeleteHPISymptom = (hpi: HistoryOfPresentIllness): void => {
    setIsDirty(true)
    setHistoryPresentIllness(historyPresentIllness.filter((h) => h !== hpi))
  }

  const handleEditHPISymptom = (hpiId: number | undefined): void => {
    if (!hpiId) {
      return
    }
    const hpi = historyPresentIllness.find(({ id }) => id === hpiId)
    if (!hpi) {
      return
    }
    setIsDirty(true)
    const complaints = (
      hpi.historyOfPresentIllnessComplaintList?.map(
        (c) => c.complaintId ?? 0
      ) ?? []
    ).map((id) => {
      const item = chiefComplaints.find((s) => s.id === id)
      return { id: item?.id ?? 0, label: item?.name ?? '' }
    })
    const symptomValues =
      hpi.historyOfPresentIllnessSymptomList?.map((s) => {
        return {
          id: s.historyOfPresentIllnessSymptomId ?? 0,
          label:
            symptoms.find(
              (sym) => sym.id === s.historyOfPresentIllnessSymptomId
            )?.name ?? '',
        }
      }) ?? []

    const locationValues =
      hpi.historyOfPresentIllnessLocationList?.map((l) => {
        return {
          id: l.historyOfPresentIllnessLocationId ?? 0,
          label:
            totalLocations.find(
              (loc) => loc.id === l.historyOfPresentIllnessLocationId
            )?.name ?? '',
        }
      }) ?? []

    const modifyingFactorValues =
      hpi.historyOfPresentIllnessModifyingFactorList?.map((m) => {
        return {
          id: m.historyOfPresentIllnessModifyingFactorsId ?? 0,
          label:
            modifyingFactors.find(
              (mod) => mod.id === m.historyOfPresentIllnessModifyingFactorsId
            )?.name ?? ''
        }
      }) ?? []

    setSelectComplaint(complaints[0] ?? null)
    setSymptomIds(symptomValues)
    setLocationIds(locationValues)
    setModifyingFactorIds(modifyingFactorValues)
    setOnset(dateOnlyToDate(hpi.onset)!)
    setContext(hpi.context ?? '')
    setSeverityValue(hpi.severity)
    setCurrentEditingId(hpiId)
  }

  const buildRows = (): JSX.Element => {
    return (
      <AddTable>
        {historyPresentIllness.map((condition) => {
          if (
            condition.historyOfPresentIllnessComplaintList === undefined ||
            condition.historyOfPresentIllnessSymptomList === undefined ||
            condition.historyOfPresentIllnessComplaintList === null ||
            condition.historyOfPresentIllnessSymptomList === null ||
            condition.historyOfPresentIllnessLocationList === undefined ||
            condition.historyOfPresentIllnessLocationList === null ||
            condition.historyOfPresentIllnessModifyingFactorList === undefined ||
            condition.historyOfPresentIllnessModifyingFactorList === null ||
            condition.id === currentEditingId
          ) {
            return <></>
          }
          const illnessnames = condition.historyOfPresentIllnessComplaintList
            .map(
              (c) =>
                chiefComplaints.find((cc) => cc.id === c.complaintId)?.name ??
                ''
            )
            .join(', ')
          const symptomNames = condition.historyOfPresentIllnessSymptomList
            .map(
              (s) =>
                symptoms.find(
                  (sym) => sym.id === s.historyOfPresentIllnessSymptomId
                )?.name ?? ''
            )
            .join(', ')
          const locationNames = condition.historyOfPresentIllnessLocationList
            .map(
              (l) =>
                totalLocations.find(
                  (loc) => loc.id === l.historyOfPresentIllnessLocationId
                )?.name ?? ''
            )
            .join(', ')
          const modifyingFactorNames = condition.historyOfPresentIllnessModifyingFactorList
            .map(
              (m) =>
                modifyingFactors.find(
                  (mod) => mod.id === m.historyOfPresentIllnessModifyingFactorsId
                )?.name ?? ''
            )
            .join(', ')

          return (
            <HPIRow
              key={condition.id}
              hpi={condition}
              illness={illnessnames}
              symptoms={symptomNames}
              locations={locationNames}
              modifyingFactors={modifyingFactorNames}
              edit={handleEditHPISymptom}
              delete={handleDeleteHPISymptom}
            />
          )
        })}
      </AddTable>
    )
  }

  return (
    <TileLayout
      ref={ref}
      inFocus={inFocus}
      setInFocus={setInFocus}
      id="hpi"
      isLocked={isLocked}
    >
      <Title titleText="History of Present Illness" />
      <TileRow>
        <PillSearchBox
          label="Present Illness"
          values={selectComplaint ? [selectComplaint] : []}
          multiple={false}
          onChange={handleComplaintChange}
          options={chiefComplaints.map((s) => ({
            id: s.id ?? 0,
            label: s.name ?? '',
          }))}
          dataTestId={'presentIllness'}
        />
      </TileRow>
      <TileRow>
        <IQSuggestionAutoComplete
          label="Symptoms"
          values={symptomIds}
          onChange={handleSymptomsChange}
          dataTestId={'symptoms'}
          options={finalSymptoms}
        />
      </TileRow>
      <TileRow>
        <PillSearchBox
          label="Modifying Factors"
          values={modifyingfactorIds}
          onChange={handleModifyingFactorsChange}
          options={modifyingFactors.map((m) => ({
            id: m.id ?? 0,
            label: m.name ?? ''
          }))}
          dataTestId={'modifyingFactors'}
        />
      </TileRow>
      <TileRow>
        <IQSuggestionAutoComplete
          label="Location"
          values={locationIds}
          onChange={handleLocationChange}
          dataTestId={'locations'}
          options={finalLocations}
        />
        {/* <PillSearchBox
          label="Location"
          values={locationIds}
          onChange={handleLocationChange}
          dataTestId="location"
          options={locations.map((l) => ({
            id: l.id ?? 0,
            label: l.name ?? ''
          }))}
        /> */}
      </TileRow>
      <TileRow>
        <TileColumn>
          <div>
            <StyleInputLabel>Onset</StyleInputLabel>
            <Stack direction="row" spacing={2}>
              <Select
                value={onsetAmount}
                onChange={handleChangeOnsetAmount}
                disabled={inputMethod === 'date'}
                style={{
                  width: '100%',
                  background: 'white',
                  marginBottom: '10px',
                }}
              >
                {onsetAmountOptions.map((val) => (
                  <MenuItem key={val} value={val}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
              <Select
                value={onsetIncrement}
                disabled={inputMethod === 'date'}
                onChange={handleChangeOnsetIncrement}
                style={{
                  width: '100%',
                  background: 'white',
                  marginBottom: '10px',
                }}
              >
                {onsetIncrementOptions.map((period) => (
                  <MenuItem key={period} value={period}>
                    {period}
                  </MenuItem>
                ))}
              </Select>
            </Stack>
            <DatePicker
              value={onset}
              disabled={inputMethod === 'increment'}
              onChange={handleOnsetChange}
              inputFormat="MM/dd/yyyy"
              renderInput={(params: any) => (
                <StyledTextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'Enter onset date',
                    'data-testid': 'onset',
                  }}
                />
              )}
            />
          </div>
          <div>
            <StyleInputLabel>Severity</StyleInputLabel>
            <Slider
              aria-label="Restricted values"
              value={severityValue ?? 0}
              onChange={handleSeverityValueChange}
              step={null}
              min={0}
              max={10}
              valueLabelDisplay="auto"
              marks={marks}
              data-testId="painScale"
            />
          </div>
          <div style={{ minHeight: '1px' }} />
        </TileColumn>
        <TileTextArea
          label="Context"
          value={context}
          onChange={handleContextChange}
          dataTestId="context"
        />
      </TileRow>
      <TileRow wide>
        <div></div>
        <AddButton
          onClick={
            currentEditingId === -1 ? handleAddHPISymptom : handleSaveHPISymptom
          }
          disabled={!selectComplaint}
          title={currentEditingId === -1 ? 'Add HPI' : 'Save HPI'}
          dataTestId="addHPI"
        />
      </TileRow>
      {
        /* prettier-ignore */
        historyPresentIllness.length > 0
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              {/* prettier-ignore */}
              {buildRows()}
              {/* prettier-ignore */}
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }
    </TileLayout>
  )
}

interface HistoryPresentIllnessProps {
  visitId: number;
  chiefComplaints: Complaint[];
  symptoms: Symptom[];
  historyPresentIllness: HistoryOfPresentIllness[];
  setHistoryPresentIllness: any;
  ref: React.RefObject<HTMLDivElement>;
  inFocus: boolean;
  setInFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
  isDirty?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  symptomIds: Array<{ id: number; label: string }>;
  setSymptomIds: React.Dispatch<
    React.SetStateAction<Array<{ id: number; label: string }>>
  >;
  locationIds: Array<{ id: number; label: string }>;
  setLocationIds: React.Dispatch<
    React.SetStateAction<Array<{ id: number; label: string }>>
  >;
  locations: IllnessLocation[];
  totalLocations: IllnessLocation[];
  modifyingFactors: IllnessModifyingFactor[];
  modifyingfactorIds: Array<{ id: number; label: string }>;
  setModifyingFactorIds: React.Dispatch<
    React.SetStateAction<Array<{ id: number; label: string }>>
  >;
  selectComplaint: { id: number; label: string } | null;
  setSelectComplaint: any;
}
