import React from 'react'

import { Button, IconButton, styled } from '@mui/material'
import { type Visit } from '@/types/Visit'
import { type MbscCalendarEvent } from '@mobiscroll/react'
import CloseIcon from '@mui/icons-material/Close'
import { isEmpty } from '@/utility/utils'

const DeleteVisitPopUpContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '400px',
  padding: '24px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  gap: 8,
}))

const DeleteVisitPopUpRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
})
const DeleteVisitRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
})
export const MoveVisitPopUp = ({
  moveBackToQueue,
  event,
  setClose,
}: DeleteVisitPopUpProps): JSX.Element => {
  const visit: any = JSON.parse(event.title!)
  const handleOpenChart = React.useCallback((visit: Visit) => {
    if (isEmpty(visit)) return
    window.location.href = `/charting/${visit.id! ?? 0}?previousNavOpen=true`
    localStorage.setItem('past-chart', visit?.id?.toString()!)
  }, [])
  return (
    <DeleteVisitPopUpContainer>
      <DeleteVisitRow>
        {`You selected the visit for ${
          (visit?.patient?.firstName as string) ?? ''
        } ${(visit?.patient?.lastName as string) ?? ''}`}
        <IconButton onClick={() => setClose(true)} size="large">
          <CloseIcon data-testid="closeMoveVisitPopup" />
        </IconButton>
      </DeleteVisitRow>
      <DeleteVisitPopUpRow>
        <Button
          sx={{ width: '200px' }}
          disabled={!(visit?.editable as boolean)}
          onClick={() => {
            moveBackToQueue(visit)
              .catch((error) => {
                throw error
              })
              .finally(() => setClose(true))
          }}
        >
          Move Back to Queue
        </Button>
        <Button
          sx={{ width: '150px' }}
          variant="contained"
          onClick={() => handleOpenChart(visit)}
        >
          Open Chart
        </Button>
      </DeleteVisitPopUpRow>
    </DeleteVisitPopUpContainer>
  )
}

interface DeleteVisitPopUpProps {
  moveBackToQueue: (visit: Visit) => Promise<void>;
  event: MbscCalendarEvent;
  setClose: any;
  refresh: any;
}
