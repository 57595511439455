import React from 'react'
import { IconButton, InputLabel, Typography, styled } from '@mui/material'
import { TileTitleBox } from '@/components/PatientIntake'
import { TitleRow } from '@/components/Charting/Editor/VitalsTile/styles'
import {
  TileContainer,
  TileInnerRow,
} from '@/components/PatientIntake/Tiles/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { type PatientBilling } from '@/types'
import { getPatientBilling, getPatientBillingMemo } from '@/services'
import { useAuth } from '@clerk/nextjs'

const StyledTileInnerRow = styled('div')({
  gap: '24px',
  marginTop: '12px',
  display: 'flex',
  flexDirection: 'column',
  width: '780px',
})

export const PatientBillingHistory = ({
  patientId,
  innerRef,
  onFocus,
  isOpen = true,
  setIsOpen,
}: PatientBillingProps): JSX.Element => {
  const { getToken } = useAuth()
  const [aMDBalance, setAMDBalance] = React.useState<
    PatientBilling | null | undefined
  >(undefined)
  const [aMDMemo, setAMDMemo] = React.useState<
    PatientBillingMemoProps[] | null | undefined
  >(undefined)

  React.useEffect(() => {
    Promise.resolve(getPatientBilling(patientId, getToken))
      .then((res) => {
        setAMDBalance(res)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [patientId, getToken])

  React.useEffect(() => {
    Promise.resolve(getPatientBillingMemo(patientId, getToken))
      .then((res) => {
        setAMDMemo(res)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [patientId, getToken])

  const formatCurrency = (value: number | null | undefined): string => {
    if (value == null) return 'n/a'
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value)
  }

  return (
    <TileContainer id={'pfh'} ref={innerRef} onMouseOver={onFocus}>
      <TitleRow>
        <TileTitleBox tileTitle="Patient Billing" />
        {setIsOpen ? (
          <IconButton
            size={'small'}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            {
              // prettier-ignore
              isOpen
                ? (<KeyboardArrowDownIcon fontSize='small' />)
                : (<KeyboardArrowRightIcon fontSize='small' />)
            }
          </IconButton>
        ) : (
          <></>
        )}
      </TitleRow>
      {isOpen ? (
        <div style={{ marginTop: '-20px' }}>
          <TileInnerRow>
            <InputLabel>Total Balance:</InputLabel>
            <Typography variant="h3" sx={{ marginLeft: '-15px' }}>
              {formatCurrency(aMDBalance?.patientTotalBalance)}
            </Typography>
          </TileInnerRow>
          <TileInnerRow>
            <InputLabel>Amount Paid:</InputLabel>
            <Typography variant="h3" sx={{ marginLeft: '-15px' }}>
              {formatCurrency(aMDBalance?.patientPaid)}
            </Typography>
          </TileInnerRow>
          <TileInnerRow>
            <InputLabel>Current Balance:</InputLabel>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 'bold',
                marginLeft: '-15px',
                color:
                  (aMDBalance?.patientCurrentBalance ?? 0) > 0
                    ? 'red'
                    : 'inherit',
              }}
            >
              {formatCurrency(aMDBalance?.patientCurrentBalance)}
            </Typography>
          </TileInnerRow>
          <StyledTileInnerRow>
            <InputLabel>Notes:</InputLabel>
            <Typography variant="h3" sx={{ marginTop: '-15px' }}>
              {aMDMemo && aMDMemo.length > 0 ? (
                aMDMemo
                  .filter((memo) => !memo.isSticky)
                  .map((memo) => <div key={memo.id}>{memo.text}</div>)
              ) : (
                <p>n/a</p>
              )}{' '}
            </Typography>
          </StyledTileInnerRow>
        </div>
      ) : (
        <></>
      )}
    </TileContainer>
  )
}

interface PatientBillingProps {
  patientId: number;
  innerRef?: React.RefObject<HTMLDivElement> | undefined;
  onFocus?: () => void;
  isOpen?: boolean | undefined;
  setIsOpen?: ((isOpen: boolean) => void) | undefined;
}

export interface PatientBillingMemoProps {
  id?: number;
  text?: string;
  type?: string;
  isSticky?: boolean;
}
