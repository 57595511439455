import React, { useState } from 'react'
import Tabs from '@mui/material/Tabs'
import Box from '@mui/material/Box'
import { type Patient } from '@/types'
import { PastVisits } from '../PastVisits'
import { UpcomingVisits } from '../UpcomingVisits'
import { PrimaryStyledTab } from '@/components/PrimaryStyledTab'
import { ThemeProvider } from '@mui/styles'
import theme from '@/styles/theme/theme'
import { StyledEngineProvider } from '@mui/material'

export const PatientVisits = ({
  patient,
  previousNavOpen = false,
}: PatientVisitsProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setSelectedTab(newValue)
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="patient visits tabs"
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              <PrimaryStyledTab label="Past Visits" />
              <PrimaryStyledTab label="Upcoming Visits" />
            </Tabs>
          </Box>
          <Box sx={{ mt: -1 }}>
            {selectedTab === 0 && (
              <PastVisits
                patient={patient}
                previousNavOpen={previousNavOpen}
                {...defaultProps}
              />
            )}
            {selectedTab === 1 && (
              <UpcomingVisits
                patient={patient}
                previousNavOpen={previousNavOpen}
                {...defaultProps}
              />
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

interface PatientVisitsProps {
  patient: Patient;
  previousNavOpen?: boolean;
}

const defaultProps = {
  tableInstanceRef: React.createRef(),
  filteredAppointments: [],
  isTableLoading: false,
  isColumnFixed: false,
  pinnedColumn: [],
  selectedTab: '',
  tabList: [],
  pagination: { pageIndex: 0, pageSize: 10 },
  handleDoubleClickTable: () => {},
  handleChangeTab: () => {},
  tabsInfo: [],
  filterList: [],
  handleRemoveFilter: () => {},
  setPagination: () => {},
}
