import React from 'react'
import { type TaskTableCellProps } from './TaskTableCellProps'
import { Dialog, IconButton, Portal, Stack, Tooltip } from '@mui/material'
import { useAuth } from '@clerk/nextjs'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import UploadIcon from '@mui/icons-material/Upload'
import { DeleteVisitPopUp } from '@/components/DeleteVisitPopUp'
import { DocumentUploadDialog } from '@/components/Task/DocumentUploadDialog'
import { useMutateDeleteTask, useMutateUpdateTask } from '@/hook'
import { TaskToast, TaskToastSeverity } from '../TaskToast'
import DoneIcon from '@mui/icons-material/Done'
import { parseDateToDateOnly } from '@/utility'

export const ActionsCell = ({
  row
}: TaskTableCellProps): JSX.Element => {
  const { getToken } = useAuth()
  const deleteTask = useMutateDeleteTask(getToken)
  const updateTask = useMutateUpdateTask(getToken)

  const [deleteDialogOpen, setDeleteDialogOpen] =
    React.useState<boolean>(false)
  const [uploadOpen, setUploadOpen] = React.useState<boolean>(false)
  const [toastMessage, setToastMessage] = React.useState<string | null>(null)
  const [toastSeverity, setToastSeverity] = React.useState<TaskToastSeverity>(
    TaskToastSeverity.Success
  )

  const taskId = row?.original?.id ?? -1

  const handleDeleteTask = (): void => {
    deleteTask
      .mutateAsync(taskId)
      .then(() => {
        setToastMessage('Task deleted successfully')
        setToastSeverity(TaskToastSeverity.Success)
      })
      .catch(() => {
        setToastMessage('Failed to delete task')
        setToastSeverity(TaskToastSeverity.Error)
      })
      .finally(() => {
        setDeleteDialogOpen(false)
      })
  }

  const handleCompleteTask = (): void => {
    updateTask
      .mutateAsync({
        id: taskId,
        task: {
          statusId: 3,
          dateCompleted: parseDateToDateOnly(new Date(Date.now())),
        },
      })
      .then(() => {
        setToastMessage('Task completed')
        setToastSeverity(TaskToastSeverity.Success)
      })
      .catch(() => {
        setToastMessage('Failed to complete task')
        setToastSeverity(TaskToastSeverity.Error)
      })
  }

  return (
    <Stack
      direction="row"
      sx={{ alignItems: 'center' }}
      onClick={(e) => e.stopPropagation()}
    >
      <Tooltip title="Upload Documents" arrow>
        <IconButton onClick={(e) => setUploadOpen(true)} size="large">
          <UploadIcon
            style={{
              cursor: 'pointer',
              width: '17px',
              height: '17px',
              fill: 'rgb(38, 47, 98)',
            }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Complete Task" arrow>
        <>
          <IconButton
            title="Complete Task Button"
            onClick={(e) => {
              handleCompleteTask()
            }}
            size="large"
          >
            <DoneIcon
              style={{
                cursor: 'pointer',
                width: '17px',
                height: '17px',
                fill: 'rgb(38, 47, 98)',
              }}
            />
          </IconButton>
        </>
      </Tooltip>
      <Tooltip title="Delete Task" arrow>
        <IconButton onClick={() => setDeleteDialogOpen(true)} size="large">
          <DeleteOutlineOutlinedIcon
            style={{
              cursor: 'pointer',
              width: '17px',
              height: '17px',
              fill: 'rgb(38, 47, 98)',
            }}
          />
        </IconButton>
      </Tooltip>
      <Dialog
        open={deleteDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: '12px' }}
      >
        <DeleteVisitPopUp
          handleDelete={handleDeleteTask}
          setClose={() => setDeleteDialogOpen(false)}
        />
      </Dialog>
      <DocumentUploadDialog
        UserTaskId={taskId}
        open={uploadOpen}
        onClose={() => setUploadOpen(false)}
        PatientId={row?.original?.patientDetails?.id}
        onSuccess={() => {
          setToastMessage('Document uploaded successfully')
          setToastSeverity(TaskToastSeverity.Success)
        }}
      />
      <Portal>
        <TaskToast
          message={toastMessage}
          severity={toastSeverity}
          onClose={() => setToastMessage(null)}
        />
      </Portal>
    </Stack>
  )
}
