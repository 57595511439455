import React from 'react'
import { type WidgetProps } from '@rjsf/utils'
import { PillSearchBox } from '../../components'

export const CustomPillSearch = function (props: WidgetProps): any {
  const [value, setValue] = React.useState<
    Array<{ id: number; label: string }>
  >(
    props.value?.map((value: string) => {
      return { id: value, label: value }
    }) ?? []
  )

  const handleChange = (
    event: any,
    newValue: Array<{
      id: number;
      label: string;
    }> | null
  ): void => {
    if (newValue) {
      setValue(newValue)
      props.onChange(newValue.map((value) => value.label))
    } else {
      setValue([])
    }
  }
  return (
    <PillSearchBox
      label={props.label}
      values={value}
      multiple={true}
      onChange={handleChange}
      options={
        props.options.enumOptions?.map((option: any) => {
          return { id: option.value, label: option.label, code: option.code }
        }) as any[]
      }
      dataTestId={'presentIllness'}
    />
  )
}
