import React from 'react'
import { useAuth } from '@clerk/nextjs'
import { Dialog, IconButton, Modal, Tooltip } from '@mui/material'
import { type State } from '@/types'
import { RoomChip } from './RoomChip'
import { useMutateDeleteClinic } from '@/hook'
import { type Room } from '../../../../types/Room'
import { type Clinic } from '../../../../types/Clinic'
import { type TimeZone } from '../../../../types/Timezone'
import { IconBox } from '../../layout/row/IconBox'
import { DetailRow } from '../../layout/row/DetailRow'
import { DetailColumnSmall } from '../../layout/row/DetailColumnSmall'
import { DetailColumnLarge } from '../../layout/row/DetailColumnLarge'
import { ManagementRow } from '../../layout/row/ManagementRow/ManagementRow'
import { AddClinic } from '../../AddModals/AddClinic'
import { AddRoom } from '../../AddModals/AddRoom/AddRoom'
import { NameBox } from '../../components/row/NameBox'
import { ValueBox } from '../../components/row/ValueBox'
import { DeleteVisitPopUp } from '@/components/DeleteVisitPopUp'
import { ShowAllButton } from '../../components/row/ShowAllButton/ShowAllButton'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import { TransactionLog } from '@/components/TransactionLog/TransactionLog'

export const ClinicRow = ({
  clinic,
  rooms,
  clinics,
  refresh,
  refreshRooms,
  timeZones,
  states,
}: ClinicRowProps): JSX.Element => {
  const { getToken } = useAuth()
  const [open, setOpen] = React.useState<boolean>(false)
  const [openAddRoom, setOpenAddRoom] = React.useState(false)
  const [viewAll, setViewAll] = React.useState<boolean>(true)
  const [roomList, setRoomList] = React.useState<Room[]>([])
  const [roomTotal, setRoomTotal] = React.useState<number>(0)
  const [openAddClinic, setOpenAddClinic] = React.useState(false)
  const [openTransactionLog, setOpenTransactionLog] = React.useState(false)

  const deleteClinicMutation = useMutateDeleteClinic(getToken)

  const handleOpenAddClinic = (): void => setOpenAddClinic(true)

  const handleCloseAddClinic = (): void => {
    setOpenAddClinic(false)
    refresh()
    refreshRooms()
  }

  const handleOpenAddRoom = (): void => setOpenAddRoom(true)
  const handleCloseAddRoom = (): void => setOpenAddRoom(false)

  const handleOpenTransactionLog = (): void => setOpenTransactionLog(true)
  const handleCloseTransactionLog = (): void => setOpenTransactionLog(false)

  React.useEffect(() => {
    let newRoomTotal = rooms.length
    if (rooms.length > 6 && !viewAll) {
      newRoomTotal = 6
    }
    setRoomTotal(newRoomTotal)
  }, [viewAll, rooms])

  const handleDelete = (id: number): void => {
    deleteClinicMutation
      .mutateAsync({ clinicId: id })
      .then(() => refresh())
      .catch((error) => {
        throw error
      })
  }

  React.useEffect(() => {
    const newRoomList = rooms.slice(0, roomTotal)
    setRoomList(newRoomList)
  }, [roomTotal])

  const buildRooms = (): JSX.Element[] => {
    return roomList.map((room) => {
      return (
        <RoomChip
          key={room.id}
          room={room}
          clinics={clinics}
          clinicID={clinic.id!}
          refreshRooms={refreshRooms}
        />
      )
    })
  }

  return (
    <ManagementRow>
      <Modal
        open={openAddClinic}
        onClose={handleCloseAddClinic}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AddClinic
          handleClose={handleCloseAddClinic}
          exisitingClinic={clinic}
          refresh={refresh}
          timeZones={timeZones}
          states={states}
        />
      </Modal>
      <Modal
        open={openAddRoom}
        onClose={handleCloseAddRoom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AddRoom
          handleClose={handleCloseAddRoom}
          clinics={clinics}
          clinicID={clinic.id!}
          refresh={refreshRooms}
        />
      </Modal>
      <Modal
        open={openTransactionLog}
        onClose={handleCloseTransactionLog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TransactionLog
          startDate={''}
          endDate={''}
          handleClose={handleCloseTransactionLog}
        />
      </Modal>
      <DetailRow>
        <DetailColumnSmall>
          <NameBox>{clinic.name!}</NameBox>
        </DetailColumnSmall>
        <DetailColumnSmall>
          <ValueBox>{clinic.address!}</ValueBox>
        </DetailColumnSmall>
        <DetailColumnLarge>
          {buildRooms()}
          <IconButton onClick={handleOpenAddRoom} size="large">
            <AddCircleIcon color="primary" fontSize="small" />
          </IconButton>
          {rooms.length > 6 && (
            <ShowAllButton
              onClick={() => setViewAll(!viewAll)}
              showAll={!viewAll}
            />
          )}
        </DetailColumnLarge>
      </DetailRow>
      <IconBox>
        <Tooltip title="Delete Clinic" arrow>
          <IconButton onClick={() => setOpen(true)} size="large">
            <DeleteOutlinedIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Download Transaction Logs" arrow>
          <IconButton onClick={handleOpenTransactionLog} size="large">
            <ReceiptOutlinedIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit Clinic" arrow>
          <IconButton onClick={handleOpenAddClinic} size="large">
            <EditOutlinedIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
      </IconBox>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: '12px' }}
      >
        <DeleteVisitPopUp
          handleDelete={() => handleDelete(clinic.id!)}
          setClose={() => setOpen(false)}
        />
      </Dialog>
    </ManagementRow>
  )
}

interface ClinicRowProps {
  clinic: Clinic;
  rooms: Room[];
  clinics: Clinic[];
  refresh: any;
  refreshRooms: any;
  timeZones: TimeZone[];
  states: State[];
}
