import React from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormLabel,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { TileDatePicker, TileTextField } from '@/components/Charting'
import { PatientHistorySelectWrapper } from './PatientHistorySelectWrapper'

export const PMHDialog = ({
  open,
  onClose,
  mode,
  title,
  onAdd,
  onEdit,
  onDelete,
  fields,
  selectedItem,
}: {
  open: boolean;
  onClose: () => void;
  mode: string;
  title: string;
  onAdd: (formData: any) => void;
  onEdit: (formData: any) => void;
  onDelete: () => void;
  fields: DialogField[];
  selectedItem?: any | null;
}): JSX.Element => {
  const [formValues, setFormValues] = React.useState<FormValues>(() => {
    return fields?.reduce<FormValues>((acc, field) => {
      if (field.type === 'radio' && field.accessor === 'isActive') {
        acc[field.accessor] = true
      }
      return acc
    }, {})
  })

  const editFields: EditFields = {
    allergenId: { id: 'id', label: 'name' },
    immunizationId: { id: 'id', label: 'immunizationName' },
    medicalConditionId: { id: 'id', label: 'medicalConditionId' },
    surgeryId: { id: 'id', label: 'surgeryId' },
    socialHistoryId: { id: 'id', label: 'type' },
    condition: { id: 'id', label: 'condition' },
    relationshipId: { id: 'id', label: 'relationship' },
  }

  React.useEffect(() => {
    if (selectedItem) {
      setFormValues(selectedItem as FormValues)
    } else {
      setFormValues(fields?.reduce<FormValues>((acc, field) => {
        if (field?.type === 'radio' && field?.accessor === 'isActive') {
            acc[field.accessor] = true
          }
          return acc
        }, {})
      )
    }
  }, [selectedItem, open, fields])

  React.useEffect(() => {
    if (!open) {
      setFormValues({})
    }
  }, [open])

  const handleAddNewEntry = (): void => {
    if (mode === 'add') {
      onAdd(formValues)
    } else {
      onEdit(formValues)
    }
    onClose()
    setFormValues({})
  }

  const handleDeleteEntry = (): void => {
    onDelete()
    onClose()
  }

  const handleFormValueChange = (
    accessor: string,
    value: any,
    type: 'select' | 'text' | 'date' | 'radio'
  ): void => {
    setFormValues((prevValues) => {
      const newState = { ...prevValues }
      if (type === 'select') {
        if (value && typeof value === 'object' && 'id' in value) {
          newState[accessor] = value.id
          newState[`${accessor}_label`] = value.label
          newState[`${accessor}_code`] = value.code
        } else {
          newState[accessor] = null
          newState[`${accessor}_label`] = value
        }
      } else {
        newState[accessor] = value
      }
      return newState
    })
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {mode === 'add'
          ? `Add ${title}`
          : mode === 'edit'
          ? `Edit ${title}`
          : `Delete ${title}`}
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '4px' }}>
        {mode === 'add' || mode === 'edit' ? (
          fields?.map((field) => {
            return (
              <FormControl key={field.accessor} fullWidth>
                <FormLabel>{field.label}</FormLabel>
                {field.type === 'select' ? (
                  <PatientHistorySelectWrapper
                    options={field.options ?? []}
                    value={{
                      id: formValues[editFields[field.accessor]?.id] ?? 0,
                      label:
                        formValues[editFields[field.accessor]?.label] ?? '',
                    }}
                    onChange={(
                      accessor: string,
                      value: any,
                      type: 'select' | 'text' | 'date' | 'radio'
                    ) => {
                      handleFormValueChange(accessor, value, type)
                      field.onChange?.(value)
                    }}
                    label={field.label}
                    accessor={field.accessor}
                    defaultValue={field.defaultValue}
                  />
                ) : field.type === 'radio' ? (
                  <RadioGroup
                    value={formValues[field.accessor] ?? ''}
                    onChange={(e) =>
                      handleFormValueChange(
                        field.accessor,
                        e.target.value,
                        'radio'
                      )
                    }
                  >
                    {field.options?.map((option) => (
                      <FormControlLabel
                        key={String(option.value)}
                        value={String(option.value)}
                        control={<Radio />}
                        label={option.label}
                      />
                    ))}
                  </RadioGroup>
                ) : field.type === 'date' ? (
                  <TileDatePicker
                    label=""
                    onChange={(value: any) =>
                      handleFormValueChange(field.accessor, value, 'date')
                    }
                    value={
                      (formValues[field.accessor] ??
                        formValues.procedureDate) ||
                      null
                    }
                    monthYearFormat
                    dataTestId={field.accessor}
                  />
                ) : (
                  <TileTextField
                    onChange={(e) =>
                      handleFormValueChange(
                        field.accessor,
                        e.target.value,
                        'text'
                      )
                    }
                    value={formValues[field.accessor] || ''}
                    inputProps={{ 'data-testid': field.accessor }}
                    fullsize
                  />
                )}
              </FormControl>
            )
          })
        ) : (
          <Typography sx={{ marginBottom: '10px' }}>
            Are you sure you want to delete this?
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {mode === 'add' ? (
          <Button onClick={handleAddNewEntry} variant="contained">
            Add
          </Button>
        ) : mode === 'edit' ? (
          <Button onClick={handleAddNewEntry} variant="contained">
            Save
          </Button>
        ) : (
          <Button onClick={handleDeleteEntry} variant="contained">
            Delete
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

interface DialogField {
  label: string;
  accessor: string;
  type?: 'text' | 'select' | 'radio' | 'date';
  options?: Array<{ value: boolean | string; label: string; id?: number }>;
  onChange?: (value: any) => void;
  defaultValue?: any;
}

interface EditField {
  id: string;
  label: string;
}

type EditFields = Record<string, EditField>;

interface FormValues {
  [key: string]: any;
  isActive?: boolean;
}
