import { type Visit } from '@/types/Visit'
import React from 'react'
import { styled, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'

const PastVisitRowContainer = styled('div')({
  display: 'flex',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: '#DDDFE8',
  width: '100%',
  justifyContent: 'center',
  paddingLeft: '20px',
})
const DateColumn = styled('div')({
  width: '20%',
})
const ProblemColumn = styled('div')({
  width: '20%',
})
const DiagnosisColumn = styled('div')({
  width: '20%',
})
const OrdersColumn = styled('div')({
  width: '20%',
})
const ProviderColumn = styled('div')({
  width: '20%',
})
const ColumnValue = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',

  /* identical to box height, or 143% */

  color: '#6F6F6F',
})
// const PastVisitCountsCircle = styled('div')({
//   display: 'flex',
//   background: '#0543B0',
//   height: '20px',
//   width: '20px',
//   borderRadius: '50%',
//   justifyContent: 'center',
//   paddingTop: '2px',
//   alignItems: 'center'
// })
// const CircleValue = styled(Typography)({
//   color: 'white',
//   fontSize: '12px',
//   fontWeight: 'bold'
// })
export const PastVisitRow = ({
  visit,
  provider,
  visitTypes,
}: PastVisitRowProps): JSX.Element => {
  return (
    <PastVisitRowContainer>
      <DateColumn>
        <ColumnValue>
          {format(parseISO(visit.arrivalDateTime!), 'MM/dd/yyyy')}
        </ColumnValue>
      </DateColumn>
      <OrdersColumn>
        <ColumnValue>
          {!isNaN(visit?.chiefComplaint?.visitTypeId!) &&
            visitTypes.find(
              (visitType: any) =>
                visitType.id === visit?.chiefComplaint?.visitTypeId
            )?.name}
        </ColumnValue>
      </OrdersColumn>
      <ProviderColumn>
        <ColumnValue>{provider}</ColumnValue>
      </ProviderColumn>
      <ProblemColumn>
        <ColumnValue></ColumnValue>
      </ProblemColumn>
      <DiagnosisColumn>
        <ColumnValue></ColumnValue>
      </DiagnosisColumn>
    </PastVisitRowContainer>
  )
}
interface PastVisitRowProps {
  visit: Visit;
  provider: string;
  visitTypes: any;
}
