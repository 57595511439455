import {
  Dialog,
  Divider,
  styled,
  type Theme,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
  Modal,
  Skeleton,
} from '@mui/material'
import { Draggable, type MbscCalendarEvent } from '@mobiscroll/react'
import React from 'react'
import { type Visit } from '@/types/Visit'
import { DeleteVisitPopUp } from '../DeleteVisitPopUp'
import { useAuth } from '@clerk/nextjs'
import { formatAgeWithRules } from '@/utility/utils'
import { useMutateDeleteVisit } from '@/hook'
import { AvatarImage } from '../AvatarImage'
import { CalendarColors } from '@/data_helpers/calendercolors'
import QrCode2 from '@mui/icons-material/QrCode2'
import { QRModal } from './QRModal'
import { type OnlineAppointment } from '@/types/OnlineAppointment'
import { type Patient } from '@/types'
import { getPatient } from '@/services/PatientRegistrationService'

const PatientTileContainer = styled('div')(({ theme }): any => ({
  display: 'flex',
  backgroundColor: theme.palette.background.default,
  height: '71px',
  padding: '12px',
  gap: 8,
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  border: '1px solid #EEF1F7',
  borderRadius: '12px;',
  position: 'relative',
}))
const PatientInfoRowLeft = styled('div')({
  display: 'flex',
  gap: 8,
  alignItems: 'center',
})
const PatientNameColumn = styled('div')((): any => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  justifyContent: 'center',
}))
const PatientNameText = styled('div')(
  ({ isWide = false }: PatientNameTextProps): any => ({
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19.2px',
    color: 'black',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    width: `${!isWide ? '160px' : '110px'}`,
  })
)
const PatientInfoText = styled(Typography)((): any => ({
  fontSize: '14px',
  lineHeight: '16.8px',
  color: 'black',
}))
const PatientInfoRow = styled('div')((): any => ({
  display: 'flex',
}))
const TypeChip = styled('div')((): any => ({
  display: 'flex',
  width: 'fit-content',
  height: '18px',
  background: '#E9ECF4',
  borderRadius: '10px',
  fontSize: '14px',
  lineHeight: '18px',
  color: 'black',
  textAlign: 'left',
  paddingLeft: '6px',
  paddingRight: '6px',
  alignContent: 'flex-start',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  right: '10px',
}))
const TypeIndicator = styled('div')(
  ({ index, theme }: IndiacatorProps): any => ({
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    background: `${CalendarColors[index]?.highline}`,
    marginLeft: '5px',
  })
)

const BottomRow = styled('div')(({ isSchedule }: BottomRowProps): any => ({
  display: 'flex',
  justifyContent: isSchedule ? 'space-between' : 'flex-start',
  alignContent: 'center',
  alignItems: 'center',
  width: '252px',
  marginTop: isSchedule ? '-6px' : 0,
}))

export const PatientTile = ({
  visit,
  visitType,
  complaint,
  refresh,
  recentChart = false,
}: PatientProps): JSX.Element => {
  const theme = useTheme()
  const [draggable, setDraggable] = React.useState()
  const [open, setOpen] = React.useState<boolean>(false)
  const [isQRCodeOpen, setIsQRCodeOpen] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const { getToken } = useAuth()
  const deleteVisitMutation = useMutateDeleteVisit(getToken)
  const [patient, setPatient] = React.useState<Patient>({
    firstName: '',
    lastName: '',
  })
  const visitTypeId =
    'chiefComplaint' in visit
      ? visit.chiefComplaint?.visitTypeId
      : 'visitTypeId' in visit
      ? visit.visitTypeId
      : 'appointmentTypeId' in visit
      ? visit.appointmentTypeId
      : 0

  React.useEffect(() => {
    if (
      'patient' in visit &&
      visit.patient !== undefined &&
      visit.patient !== null
    ) {
      setPatient(visit?.patient ?? {})
    } else if ('patientId' in visit) {
      if (
        visit.patientId === 0 ||
        visit.patientId === undefined ||
        visit.patientId === null
      ) {
        if (
          'firstName' in visit &&
          'lastName' in visit &&
          'dateOfBirth' in visit
        ) {
          setPatient({
            firstName: visit.firstName!,
            lastName: visit.lastName!,
            dateOfBirth: visit.dateOfBirth!,
          })
        }
      } else {
        getPatient(visit.patientId ?? 0, getToken)
          .then((res) => {
            setPatient(res)
          })
          .catch((err) => {
            throw err
          })
      }
    }
    setIsLoading(false)
  }, [visit])

  const handleClickOpen = (): void => {
    if (process.env.DISABLE_PATIENT_REMOVE_BUTTON !== 'true') {
      setOpen(true)
    }
  }
  const handleClose = (): void => {
    setOpen(false)
  }
  const setDragElm = React.useCallback((elm: any) => {
    setDraggable(elm)
  }, [])
  const event: MbscCalendarEvent = {
    title: JSON.stringify(visit),
    length: '10 m',
  }

  const formatGender = (): string => {
    if (patient?.sex !== '') {
      switch (patient?.sex) {
        case 'Male':
          return ', M'
        case 'Female':
          return ', F'
        case 'NoResponse':
          return ', NR'
        default:
          return ', O'
      }
    }
    return ''
  }
  const formatName = (showfull = false): string => {
    const nameString = `${patient?.firstName!} ${patient?.lastName!}`
    if (!showfull && nameString.length > 13) {
      return `${nameString.substring(0, 13)}...`
    }
    return nameString
  }
  const handleDeleteVisit = (): void => {
    const id = visit?.id ?? 0
    deleteVisitMutation
      .mutateAsync({ visitId: id })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        setOpen(false)
        refresh(id)
      })
  }

  return (
    <>
      <Modal
        open={isQRCodeOpen}
        onClose={() => setIsQRCodeOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <QRModal vId={visit?.id ?? 0} />
      </Modal>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: '12px' }}
      >
        <DeleteVisitPopUp
          handleDelete={handleDeleteVisit}
          setClose={handleClose}
        />
      </Dialog>
      <PatientTileContainer ref={setDragElm} onDoubleClick={handleClickOpen}>
        <PatientInfoRowLeft>
          <AvatarImage
            src={patient?.avatarUrl ?? '/user.png'}
            alt="Patient Image"
            width={40}
            height={40}
          />
          <PatientNameColumn>
            <Tooltip title={formatName(true)} placement="top-start">
              {isLoading ? (
                <Skeleton variant="text" width={100} />
              ) : (
                <PatientNameText>{formatName()}</PatientNameText>
              )}
            </Tooltip>
            <BottomRow isSchedule={!recentChart}>
              <PatientInfoRow>
                <PatientInfoText>{`(${formatAgeWithRules(
                  patient?.dateOfBirth
                )})${formatGender()}`}</PatientInfoText>
                {complaint !== '' && (
                  <Divider
                    sx={{
                      marginRight: '4px',
                      marginLeft: '4px',
                      color: '#DDDFE8',
                    }}
                    flexItem
                    orientation="vertical"
                  />
                )}
                {recentChart || complaint.length < 26 ? (
                  <PatientInfoText>{complaint}</PatientInfoText>
                ) : (
                  <Tooltip title={complaint} placement="bottom">
                    <PatientInfoText>
                      {complaint.substring(0, 26)}...
                    </PatientInfoText>
                  </Tooltip>
                )}
              </PatientInfoRow>
              {!recentChart && (
                <Tooltip
                  title="View Patient Confirmation QR Code"
                  placement="bottom"
                >
                  <IconButton
                    onClick={() => setIsQRCodeOpen(true)}
                    size="large"
                  >
                    <QrCode2 sx={{ fontSize: '14px' }} />
                  </IconButton>
                </Tooltip>
              )}
            </BottomRow>
          </PatientNameColumn>
        </PatientInfoRowLeft>
        <TypeChip>
          {visitType}
          {''}
          <TypeIndicator index={visitTypeId ?? 0} theme={theme} />
        </TypeChip>
        <Draggable dragData={event} element={draggable} />
      </PatientTileContainer>
    </>
  )
}
interface PatientProps {
  visit: Visit | OnlineAppointment;
  visitType: string;
  complaint: string;
  refresh: any;
  recentChart?: boolean;
}
interface IndiacatorProps {
  index: number;
  theme: Theme;
}

interface PatientNameTextProps {
  isWide?: boolean;
}

interface BottomRowProps {
  isSchedule: boolean;
}
