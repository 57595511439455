import React from 'react'
import { PopoverContainer } from '../layout/PopoverContainer'
import { PopoverTileText } from '../components/PopoverTileText'
import { PopoverValueText } from '../components/PopoverValueText'

export const ExternalOrderHoverOver = ({
  externalOrderName,
  externalOrderExplain,
}: ExternalOrderHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>External Order</PopoverTileText>
        <PopoverValueText>{externalOrderName}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Revelant Information</PopoverTileText>
        <PopoverValueText>{externalOrderExplain}</PopoverValueText>
      </div>
    </PopoverContainer>
  )
}

interface ExternalOrderHoverOverProps {
  externalOrderName: string;
  externalOrderExplain: string;
}
