import { useAuth } from '@clerk/nextjs'
import React from 'react'
import {
  Divider,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@mui/material'
import {
  type Control,
  Controller,
  type FieldValues,
  type UseFormRegister,
  useWatch,
} from 'react-hook-form'
import { type Complaint, type VisitType } from '@/types'
import {
  TitleCheckBox,
  FreeTextNotes,
  RedText,
  ErrorMessage,
  SectionLabel,
  TileTitleBox,
  PillSearchBox,
} from '@/components'
import { TitleRow } from '@/components/Charting/Editor/VitalsTile/styles'
import { isEmpty } from '@/utility/utils'
import {
  useClinicStore,
  useQueryGetComplaints,
  useQueryGetInformationSources,
  useQueryGetVisitCategories,
  useReferenceDataStore,
} from '@/hook'
import { VisitCategorySelector } from './VisitCategorySelector'
import { type PatientType } from '../../../../types/PatientType'
import { getPatientTypes } from '@/services'

const ChiefComplaintContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '812px',
  padding: '24px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  border: '1px solid #CDD0E3',
  gap: 8,
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
}))
const ChiefComplaintRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
})
const ChiefComplaintInnerRow = styled(ChiefComplaintRow)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  marginTop: '12px',
})
const ChiefComplaintColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  minWidth: '100%',
  alignContent: 'flex-start',
  alignItems: 'flex-start',
})

export const ChiefComplaintTile = ({
  innerRef,
  register,
  control,
  onFocus,
  patientConsent,
  setPatientConsent,
  setVisitType,
  visitTypes,
  setVisitCategory,
  notes,
  setNotes,
  setValue,
  errors,
  selectComplaint,
  setSelectComplaint,
  patientType,
  setPatientType,
}: ChiefComplaintProps): JSX.Element => {
  const { getToken } = useAuth()
  const sourceWatch = useWatch({ control, name: 'sourceOfInformation' })
  const [isNameVisible, setIsNameVisible] = React.useState<boolean>(false)
  const [patientTypes, setPatientTypes] = React.useState<PatientType[]>([])
  const { setComplaints } = useReferenceDataStore()
  const { data: complaints } = useQueryGetComplaints(getToken)
  const { data: informationSources } = useQueryGetInformationSources(getToken)
  const { clinicId } = useClinicStore()
  const { data: visitCategories } = useQueryGetVisitCategories(
    clinicId,
    getToken
  )

  const [expanded, setExpanded] = React.useState<boolean>(false)

  React.useEffect(() => {
    void getPatientTypes(getToken).then((response) => {
      setPatientTypes(response)
    })
  }, [])

  React.useEffect(() => {
    const sortedComplaints = complaints?.sort((a: Complaint, b: Complaint) => {
      const aName = a.name ?? ''
      const bName = b.name ?? ''
      return aName.localeCompare(bName)
    })
    setComplaints(sortedComplaints!)
  }, [complaints])

  React.useEffect(() => {
    setIsNameVisible(sourceWatch === 3)
  }, [sourceWatch])

  const complaintsList = React.useMemo(() => {
    return complaints?.map((complaint) => {
      return { id: complaint.id ?? 0, label: complaint.name ?? '' }
    })
  }, [complaints])

  const handleComplaintChange = (
    event: any,
    newValue: Array<{
      id: number;
      label: string;
    }> | null
  ): void => {
    if (newValue === null) {
      setSelectComplaint([])
      setValue('complaint', [])
    } else {
      setSelectComplaint(newValue)
      setValue('complaint', newValue)
    }
  }

  const nullPatientType: PatientType = { name: '-' }

  return (
    <ChiefComplaintContainer ref={innerRef} onMouseOver={onFocus}>
      <TitleRow>
        <TileTitleBox tileTitle="Reason for Visit" />
        <TitleCheckBox
          title="Patient Consents to Share Medical History"
          value={patientConsent}
          setValue={setPatientConsent}
          data-testid="patientConsent"
        />
      </TitleRow>
      <ChiefComplaintRow>
        <ChiefComplaintColumn>
          <ChiefComplaintInnerRow>
            <VisitCategorySelector
              register={register}
              control={control}
              errors={errors}
              visitTypes={visitTypes}
              setVisitType={setVisitType}
              visitCategories={visitCategories ?? []}
              setVisitCategory={setVisitCategory}
            />
            <div style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}
              >
                <InputLabel id="VisitTypeLabel">
                  Reason for Visit<RedText>{' *'}</RedText>
                </InputLabel>
                <PillSearchBox
                  values={selectComplaint}
                  onChange={handleComplaintChange}
                  options={complaintsList ?? []}
                  dataTestId="complaint"
                  singleColumn
                />
                {!isEmpty(errors?.complaint) && (
                  <ErrorMessage error={errors?.complaint.message as string} />
                )}
              </div>
              {patientTypes.length > 0 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2px',
                  }}
                >
                  <InputLabel id="PatientTypeSelectLabel">
                    Patient Status
                  </InputLabel>
                  <Controller
                    name="patientType"
                    control={control}
                    defaultValue={patientType}
                    render={({ field }) => (
                      <Select
                        data-testid="patientType"
                        tabIndex={37}
                        {...register('patientType')}
                        {...field}
                        labelId="PatientTypeLabel"
                        sx={{ width: '240px' }}
                        onChange={(event) => {
                          field.onChange(event.target.value)
                          setPatientType(event.target.value)
                        }}
                      >
                        {[nullPatientType, ...patientTypes]
                          .filter((type: PatientType) => !type.isDeprecated)
                          .map((type: PatientType) => {
                            return (
                              <MenuItem
                                key={type.id}
                                value={type.id}
                                style={{ fontSize: '14px' }}
                                data-testid={`patientType-${
                                  (type?.id ?? '') as string
                                }`}
                              >
                                {type.name}{' '}
                              </MenuItem>
                            )
                          })}
                      </Select>
                    )}
                  />
                </div>
              )}
            </div>
          </ChiefComplaintInnerRow>
        </ChiefComplaintColumn>
      </ChiefComplaintRow>
      <Divider
        flexItem
        sx={{ marginTop: '10x', marginBottom: '10px', color: '#DDDFE8' }}
      />
      <SectionLabel sectionLabelText="Source of Information" />
      <ChiefComplaintInnerRow
        sx={{
          alignItems: 'flex-start',
          alignContent: 'flex-start',
          marginTop: '-19px',
          marginBottom: '16px',
        }}
      >
        <Controller
          name="sourceOfInformation"
          control={control}
          defaultValue={1}
          render={({ field }) => (
            <Select
              data-testid="sourceOfInformation"
              {...field}
              labelId="VisitTypeLabel"
              sx={{ width: '240px', marginTop: '12px' }}
              {...register('sourceOfInformation')}
            >
              {informationSources?.map((informationSource) => {
                return (
                  <MenuItem
                    data-testid={`informationSource${
                      informationSource.id ?? 0
                    }`}
                    key={informationSource.id}
                    value={informationSource.id}
                    style={{ fontSize: '14px' }}
                  >
                    {' '}
                    {informationSource.name}{' '}
                  </MenuItem>
                )
              })}
            </Select>
          )}
        />
        {isNameVisible && (
          <div style={{ marginTop: '-8px' }}>
            <InputLabel>Source</InputLabel>
            <TextField
              {...register('sourceOfInformationName')}
              tabIndex={38}
              sx={{ width: '240px' }}
            />
          </div>
        )}
      </ChiefComplaintInnerRow>
      <FreeTextNotes
        notes={notes}
        setNotes={setNotes}
        register={register}
        sectionName="Chief Complaint"
        expanded={expanded}
        setExpanded={setExpanded}
      />
    </ChiefComplaintContainer>
  )
}

interface ChiefComplaintProps {
  innerRef: React.RefObject<HTMLDivElement>;
  register: UseFormRegister<FieldValues>;
  control: Control<FieldValues, any>;
  onFocus: any;
  patientConsent: boolean;
  setPatientConsent: React.Dispatch<React.SetStateAction<boolean>>;
  setVisitType: React.Dispatch<React.SetStateAction<number>>;
  visitTypes: VisitType[];
  setVisitCategory: React.Dispatch<React.SetStateAction<number | null>>;
  notes: string;
  setNotes: React.Dispatch<React.SetStateAction<string>>;
  setValue: any;
  errors?: any;
  selectComplaint: Array<{ id: number; label: string }>;
  setSelectComplaint: any;
  patientType?: number;
  setPatientType: React.Dispatch<React.SetStateAction<number | null>>;
}
