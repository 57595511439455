import React from 'react'
import { IconButton, Popover } from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'
import {
  RowTile,
  RowValue,
  AddColumn,
  AddRow,
  FamilyHoverOver,
} from '@/components'
import { type PatientFamilyHistory } from '@/types'
import DeleteIcon from '@mui/icons-material/Delete'
import { type Relationship } from '@/types/Relationship'
import { useReferenceDataStore } from '@/hook'

export const FamilyHistoryRow = ({
  familyHistory,
  delete: deleteDX,
  edit: editDX,
  relationships,
}: FamilyHistoryRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const { medicationConditionType } = useReferenceDataStore()

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const problem =
    medicationConditionType.find(
      ({ id }) => id === familyHistory.medicalConditionId
    )?.name ?? familyHistory.otherProblem
  const relationshipName =
    relationships?.find(({ id }) => id === familyHistory?.relationshipId)
      ?.name ?? familyHistory.otherRelationship
  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <FamilyHoverOver
          problem={problem ?? ''}
          relationship={
            relationships?.find(
              ({ id }) => id === familyHistory?.relationshipId
            )?.name ?? ''
          }
        />
      </Popover>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={50.5}>
          <RowValue>{problem ?? ''}</RowValue>
        </AddColumn>
        <AddColumn width={41.5}>
          <RowTile>Relationship</RowTile>
          <RowValue maxWidth={'56%'}>{relationshipName ?? ''}</RowValue>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => editDX(familyHistory)} size="large">
            <ModeIcon />
          </IconButton>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => deleteDX(familyHistory)} size="large">
            <DeleteIcon />
          </IconButton>
        </AddColumn>
      </AddRow>
    </>
  )
}
interface FamilyHistoryRowProps {
  familyHistory: PatientFamilyHistory;
  delete: (familyHistory: PatientFamilyHistory) => void;
  edit: (familyHistory: PatientFamilyHistory) => void;
  relationships: Relationship[];
}
