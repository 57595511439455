import React from 'react'
import { type TaskTableCellProps } from './TaskTableCellProps'
import { useQueryGetTaskStatus } from '@/hook'
import { useAuth } from '@clerk/nextjs'
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Tooltip,
} from '@mui/material'
import { convertToSpaceSeparated, parseDateToDateOnly } from '@/utility'
import { useMutateUpdateTask } from '@/hook/useTasks'
import { type Task } from '@/types'
import { TaskStatusColor } from '@/data_helpers/task'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import AddIcon from '@mui/icons-material/Add'
import { AssignAction, StyledTableText } from './styled'

export const StatusCell = ({
  row,
  cell
}: TaskTableCellProps): JSX.Element => {
  const { getToken } = useAuth()
  const { data: userTaskStatus } = useQueryGetTaskStatus(getToken)
  const updateTaskMutation = useMutateUpdateTask(getToken)
  const [hoverAssignStatusId, setHoverAssignStatusId] = React.useState(-1)

  const statusName =
    userTaskStatus?.find(({ id }) => id === row?.original?.statusId)?.name ??
    ''

  const handleUpdateTask = async (
    taskId: number,
    modification: Partial<Task>
  ): Promise<void> => {
    const newStatusName =
      userTaskStatus?.find(({ id }) => id === modification.statusId)?.name ??
      ''
    if (newStatusName === 'Completed') {
      modification.dateCompleted = parseDateToDateOnly(new Date())
    }
    try {
      await updateTaskMutation.mutateAsync({
        id: taskId,
        task: modification,
      })
    } catch (error) {
      console.error('Error updating task:', error)
    }
  }

  return (
    <AssignAction
      sx={{ width: '120px' }}
      className={row?.original?.id === hoverAssignStatusId ? 'hover' : ''}
      onClick={(e) => {
        e.stopPropagation()
        setHoverAssignStatusId(row?.original?.id)
      }}
    >
      <Tooltip title={convertToSpaceSeparated(statusName)} arrow>
        <StyledTableText
          color={TaskStatusColor[statusName] ?? ''}
          id={cell.id}
          sx={{ fontSize: '14px', width: '120px' }}
        >
          {convertToSpaceSeparated(statusName)}
        </StyledTableText>
      </Tooltip>
      <PopupState variant="popover" popupId="status-popover">
        {(popupState) => (
          <>
            <Box
              className="btn-assign-provider-action"
              {...bindTrigger(popupState)}
            >
              <IconButton size="large">
                <AddIcon
                  style={{
                    cursor: 'pointer',
                    width: '17px',
                    height: '17px',
                    fill: 'rgb(38, 47, 98)',
                  }}
                />
              </IconButton>
            </Box>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              anchorEl={document.getElementById(cell.id)}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              onClose={(ev: any): void => {
                popupState.close()
                setHoverAssignStatusId(-1)
                ev.stopPropagation()
              }}
            >
              <Paper
                sx={{
                  width: '125px',
                  border: '1px solid rgba(0, 0, 0, 0.12)',
                  borderRadius: '10px',
                }}
              >
                {userTaskStatus?.map(({ id, name }) => {
                  return (
                    <MenuItem
                      key={id}
                      value={id}
                      sx={{ fontSize: '14px' }}
                      onClick={() => {
                        handleUpdateTask(row?.original?.id, {
                          statusId: id,
                        })
                          .catch((err: any) => {
                            throw err
                          })
                          .finally(() => popupState.close())
                      }}
                    >
                      {convertToSpaceSeparated(name ?? '')}
                    </MenuItem>
                  )
                })}
              </Paper>
            </Popover>
          </>
        )}
      </PopupState>
    </AssignAction>
  )
}
