import { Button, InputLabel, Popover, styled } from '@mui/material'
import React from 'react'
import {
  type ExamNode,
  type ExamStatusType,
  type VisitExamination,
  type ExamResultIdType,
} from '@/types'
import { Title, TileLayout, AddButton } from '@/components'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { ExamModal } from './ExamModal'
import { useAuth } from '@clerk/nextjs'
import { ExamList } from './ExamList'
import {
  useExaminationStore,
  useMutateAddExamination,
  useMutateUpdateExamination,
} from '@/hook/useExaminations'
import { StyledTextButton } from '../FreeTextNotes/styles'
import { EXAM_EMPTY } from '@/utility'

const StyleInputLabel = styled(InputLabel)({
  fontFamily: 'Lato',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '20px',
  color: '#222222',
})

const DropdownButton = styled(Button)({
  width: '100%',
  backgroundColor: 'transparent',
  borderRadius: '5px',
  color: '#A7A7A7',
  border: '1px solid #DDDFE8 !important',
  flex: 'display',
  justifyContent: 'space-between',
  textTransform: 'lowercase',
})

const TileRow = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  gap: '24px',
  scrollMarginTop: '60px',
  width: '768px',
  minHeight: '24px',
  alignContent: 'center',
  alignItems: 'center',
})

export const NewExamTile = ({
  ref,
  visitId,
  inFocus,
  setInFocus,
  isLocked = false,
  examResponses,
  examResultFactorIds,
  examinations,
  setIsExamListRendering,
}: NewExamTileProps): JSX.Element => {
  const { getToken } = useAuth()
  const { examinations: visitExams } = useExaminationStore()
  const updateVisitExamination = useMutateUpdateExamination(getToken)
  const addVisitExamination = useMutateAddExamination(getToken)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  )

  const handleUpdateExam = async (
    examId: number,
    status: number
  ): Promise<void> => {
    const curExamResponse = examResponses[examId]
    if (!curExamResponse) {
      if (status === examResultFactorIds[EXAM_EMPTY]) {
        return
      }
      await addVisitExamination.mutateAsync({
        visitExamination: {
          visitId,
          examinationId: examId,
          examResultFactorId: status,
        },
      })
    } else {
      if (status === curExamResponse.status) {
        return
      }
      await updateVisitExamination.mutateAsync({
        vId: curExamResponse.id ?? -1,
        visitExamination: {
          ...visitExams.find(({ id }) => id === curExamResponse.id),
          examResultFactorId: status,
        },
      })
    }
  }

  const handleAddExamNote = (examId: number, note: string): void => {
    const curExamResponse = examResponses[examId]
    if (!curExamResponse) {
      return
    }
    updateVisitExamination
      .mutateAsync({
        vId: curExamResponse.id ?? -1,
        visitExamination: {
          ...visitExams.find(({ id }) => id === curExamResponse.id),
          notes: note,
        },
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (isLocked) {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const popoverOpen = Boolean(anchorEl)
  const popoverId = popoverOpen ? 'simple-popover' : undefined

  if (!Object.keys(examResultFactorIds).length) {
    return <></>
  }

  return (
    <TileLayout
      ref={ref}
      inFocus={inFocus}
      setInFocus={setInFocus}
      id={'exam'}
      isLocked={isLocked}
    >
      <>
        <Title titleText="Exam" />
        <ExamList
          type="exam"
          examinations={examinations ?? []}
          examResponses={examResponses}
          isLocked={isLocked}
          examResultFactorIds={examResultFactorIds}
          handleUpdateExam={handleUpdateExam}
          handleAddExamNote={handleAddExamNote}
          setIsExamListRendering={setIsExamListRendering}
        />
        <StyleInputLabel>Select Exams</StyleInputLabel>
        <DropdownButton
          variant="outlined"
          endIcon={<ArrowDropDownOutlined />}
          onClick={handlePopoverClick}
        >
          select one or multiple exams
        </DropdownButton>
        <Popover
          id={popoverId}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <ExamModal
            examResultFactorIds={examResultFactorIds}
            examSystemComponents={examinations}
            examResponses={examResponses}
            handleUpdateExam={handleUpdateExam}
            type="exam"
          />
        </Popover>
        {process.env.SHOW_EXAM_LINK_BUTTON === 'true' && (
          <TileRow>
            <StyledTextButton
              sx={{
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                textAlign: 'left',
                textDecorationLine: 'underline',
                color: '#0543B0',
                width: 'auto',
                minWidth: '0px',
              }}
              variant="text"
            >
              Link
            </StyledTextButton>
            <AddButton
              dataTestId="add-exam"
              title="Add Exam"
              auto
              onClick={() => {}}
            />
          </TileRow>
        )}
      </>
    </TileLayout>
  )
}

interface NewExamTileProps {
  ref: React.RefObject<HTMLDivElement>;
  visitId: number;
  inFocus: boolean;
  setInFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
  examResponses: ExamStatusType[];
  examResultFactorIds: ExamResultIdType;
  visitExams: VisitExamination[];
  examinations: ExamNode[];
  setIsExamListRendering: (value: boolean) => void;
}
