import { InputLabel, styled, type Theme, useTheme } from '@mui/material'
import React from 'react'

const StyleInputLabel = styled(InputLabel)(
  ({ maxWidth, theme }: LabelProps) => ({
    fontSize: '16px',
    lineHeight: '24px',
    color: theme.palette.black,
    overflow: 'hidden',
    maxWidth,
  })
)

export const RowValue = ({
  children,
  maxWidth = '100%',
}: RowValueProps): JSX.Element => {
  const theme = useTheme()
  return (
    <StyleInputLabel maxWidth={maxWidth} theme={theme}>
      {children}
    </StyleInputLabel>
  )
}

interface RowValueProps {
  children: string | null | undefined;
  maxWidth?: string;
}

interface LabelProps {
  maxWidth: string;
  theme: Theme;
}
