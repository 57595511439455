import { styled, TextField } from '@mui/material'
import { TileInputLabel } from '../TileInputLabel'
import React from 'react'

const StyledTextField = styled(TextField)(
  ({ fullsize }: StyledTextFieldProps) => ({
    width: fullsize ? '502px' : '240px',
    maxHeight: '40px !important',
    '& > div': {
      borderRadius: 0,
      maxHeight: '40px !important',
      backgroundColor: 'white',
    },
  })
)

export const TileTextField = ({
  id = 'tile-text-field',
  label = null,
  value,
  onChange,
  fullsize = false,
  onKeyPress,
  password = false,
  minHeight = '64px',
  ...props
}: TileTextFieldProps): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        minHeight,
      }}
      id={id}
    >
      {label !== null && <TileInputLabel>{label}</TileInputLabel>}
      <StyledTextField
        fullsize={fullsize}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        {...props}
        type={password ? 'password' : 'text'}
      />
    </div>
  )
}

interface TileTextFieldProps {
  label?: string | null;
  value: string | number | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fullsize?: boolean;
  inputProps?: any | undefined;
  InputProps?: any | undefined;
  disabled?: boolean;
  placeholder?: string | undefined;
  minHeight?: string | undefined;
  /* prettier-ignore */
  onKeyPress?: ((event: React.KeyboardEvent<HTMLInputElement>) => void) | undefined
  password?: boolean;
  id?: string;
}

interface StyledTextFieldProps {
  fullsize: boolean;
}
