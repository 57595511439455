import {
  IconButton,
  InputLabel,
  Modal,
  Typography,
  TextareaAutosize,
  Button,
  styled,
} from '@mui/material'
import React from 'react'
import RuleIcon from '@mui/icons-material/Rule'
import { ExamModal } from './ExamModal'
import ClearIcon from '@mui/icons-material/Clear'
import { TileColumn } from '../layout/TileColumn'
import { TileRow } from '../layout/TileRow'
import { type Exam, type ReviewOfSystem } from '@/types'
import { isEmpty } from '@/utility/utils'

const StyleInputLabel = styled(InputLabel)({
  fontWeight: '300',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#222222',
})

const SystemText = styled(Typography)({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
})

const ExamColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

const StyledTextButton = styled(Button)({
  width: '90x',
  height: '14px',
  padding: 0,

  fontWeight: '300',
  paddingRight: 0,
  fontSize: '12px',
  lineHeight: '14px',
  /* identical to box height */
  textAlign: 'right',
  color: '#808080',
  border: 'none',
  borderRadius: 0,
  '&:hover': {
    width: '90x',
  },
})

const StyledTextField = styled(TextareaAutosize)({
  fontFamily: 'Lato',
  width: '764px',
  backgroundColor: 'white',
  borderWidth: 1,
  color: '#222222',

  fontWeight: '500',
  fontSize: '16px',
  borderColor: '#808080',
  '& > focus:': {
    outline: 0,
  },
  '& > textarea': {
    borderColor: '#808080',
  },
})
const ListBoxContainer = styled('div')({
  display: 'flex',
  marginLeft: '-10px',
})
export const ExamRow = ({
  examSystems,
  examSystemComponents,
  count,
  setCount,
  index,
  type,
  handleDelete,
  handleResultChange,
  addToNotesList,
  notesList,
  previousId = 0,
  outPut,
  displayPopup,
}: ExamRowProps): JSX.Element => {
  const [selectedId, setSelectedId] = React.useState<number>(previousId)
  const [note, setNote] = React.useState<string>('')
  const [responses, setResponses] = React.useState<any>({})
  const [isNoteVisible, setIsNoteVisible] = React.useState<boolean>(false)
  const [openExamModal, setOpenExamModal] = React.useState(false)

  const handleOpenExamModal = (): void => setOpenExamModal(true)
  const handleCloseExamModal = (): void => setOpenExamModal(false)
  React.useEffect(() => {
    if (displayPopup) {
      handleOpenExamModal()
    }
  }, [displayPopup])
  React.useEffect(() => {
    if (selectedId !== 0) {
      setCount(count + 1)
    }
  }, [selectedId])

  React.useEffect(() => {
    const value = outPut.find((item) =>
      type === 'exam'
        ? (item as Exam).examSystemId === previousId
        : (item as ReviewOfSystem).rosSystemId === previousId
    )
    if (type === 'exam') {
      const exam = value as Exam
      if (exam !== undefined && exam !== null) {
        exam?.visitExamSystemComponentList?.forEach((item) => {
          responses[`${item?.examSystemComponentId ?? 0}`] =
            item?.examResultFactorId ?? 0
        })
      }
    } else {
      const ros = value as ReviewOfSystem
      if (ros !== undefined && ros !== null) {
        ros?.visitRosSymptomList?.forEach((item) => {
          responses[`${item?.rosSymptomId ?? 0}`] = item?.rosResultFactorId
        })
      }
    }
  }, [previousId])

  React.useEffect(() => {
    if (`${previousId}` in notesList) {
      setNote(notesList[`${previousId}`])
    }
  }, [previousId])

  React.useEffect(() => {
    if (index === count) {
      setSelectedId(0)
    }
  }, [count])
  React.useEffect(() => {
    setIsNoteVisible(!isEmpty(note))
  }, [note])
  React.useEffect(() => {
    for (const property in responses) {
      handleResultChange(previousId, property, responses[property])
    }
  }, [openExamModal])

  const buildList = (type: number): JSX.Element => {
    let list = ''
    for (const property in responses) {
      if (responses[property] === type) {
        const componentName: string =
          examSystemComponents.find(
            (examSystemComponent) =>
              examSystemComponent.id === parseInt(property)
          )?.name ?? '' + ', '
        list += `${componentName}, `
      }
    }
    return (
      <ListBoxContainer>{list.substring(0, list.length - 2)}</ListBoxContainer>
    )
  }

  const onNoteChange = (event: any): void => {
    setNote(event.target.value)
  }
  return (
    <TileColumn>
      <TileRow>
        <Modal
          open={openExamModal}
          onClose={handleCloseExamModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          data-testid="exam-modal"
          sx={{
            paddingLeft: '30%',
            paddingTop: '100px',
            width: '800px',
            borderRadius: '12px',
          }}
        >
          <ExamModal
            examSystemComponents={examSystemComponents.filter(
              (examSystemComponent) =>
                examSystemComponent.examSystemId === previousId ||
                examSystemComponent.rosSystemId === previousId
            )}
            examResponses={responses}
            setExamResponses={setResponses}
            type={type}
            handleClose={handleCloseExamModal}
          />
        </Modal>
        <ExamColumn sx={{ width: '100px' }}>
          <StyleInputLabel>System</StyleInputLabel>
          <SystemText>
            {examSystems.find((examSystem) => examSystem.id === previousId)
              ?.name ?? ''}
          </SystemText>
        </ExamColumn>
        <ExamColumn>
          <IconButton
            sx={{ marginTop: '9px' }}
            onClick={handleOpenExamModal}
            size="large"
          >
            <RuleIcon />
          </IconButton>
        </ExamColumn>
        <ExamColumn sx={{ width: '190px' }}>
          <StyleInputLabel sx={{ marginLeft: '-11px' }}>
            {type === 'exam' ? 'WNL' : 'Patient Reports'}
          </StyleInputLabel>
          <SystemText>{buildList(1)}</SystemText>
        </ExamColumn>
        <ExamColumn sx={{ width: '190px' }}>
          <StyleInputLabel sx={{ marginLeft: '-11px' }}>
            {type === 'exam' ? 'ABN' : 'Patient Denies'}
          </StyleInputLabel>
          <SystemText>{buildList(2)}</SystemText>
        </ExamColumn>
        <ExamColumn sx={{ paddingTop: '24px', width: '100px' }}>
          <StyledTextButton
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              textAlign: 'left',
              textDecorationLine: 'underline',
              color: '#0543B0',
              marginLeft: '-11px',
            }}
            variant="text"
            onClick={() => setIsNoteVisible(!isNoteVisible)}
          >
            {isNoteVisible
              ? 'Hide Note'
              : isEmpty(note)
              ? 'Add Note'
              : 'Edit Note'}
          </StyledTextButton>
        </ExamColumn>
        <ExamColumn>
          <IconButton
            sx={{ marginTop: '11px' }}
            data-testid="delete-button"
            onClick={() => {
              handleDelete(previousId)
              setCount(count - 1)
            }}
            size="large"
          >
            <ClearIcon />
          </IconButton>
        </ExamColumn>
      </TileRow>
      {
        /* prettier-ignore */
        isNoteVisible
          ? (
            /* prettier-ignore */
            <TileRow>
              <div>
                <StyleInputLabel>Note</StyleInputLabel>
                <StyledTextField
                  onChange={onNoteChange}
                  value={note}
                  minRows={5}
                  onBlur={() => addToNotesList(previousId, note)}
                />
              </div>
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : (
            /* prettier-ignore */
            <></>
            /* prettier-ignore */
            )
      }
    </TileColumn>
  )
}

interface ExamRowProps {
  examSystems: any[];
  examSystemComponents: any[];
  index: number;
  count: number;
  setCount: any;
  type: string;
  outPut: any[];
  setOutPut: any[];
  handleResultChange: (
    systemId: number,
    componentId: string,
    value: number
  ) => void;
  handleDelete: (index: number) => void;
  addToNotesList: (index: number, note: string) => void;
  notesList: any;
  filterExamedSystems: any[];
  systemResults: any;
  previousId?: number;
  examId?: number;
  displayPopup: boolean;
}
