import { type paths } from '../generated/schema'

import { Fetcher } from 'openapi-typescript-fetch'

// declare fetcher for paths
const fetcher = Fetcher.for<paths>()

// global configuration

// create fetch operations
const setupGetOCRInsuranceValues = fetcher
  .path('/api/FormRecognizer/Insurance/{documentId}')
  .method('get')
  .create()

export const getOCRInsuranceValues = async (
  documentId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetOCRInsuranceValues({ documentId })).data
  } catch (error) {
    if (error instanceof setupGetOCRInsuranceValues.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}

// create fetch operations
const setupGetOCRPatientIdValues = fetcher
  .path('/api/FormRecognizer/PatientId/{documentId}')
  .method('get')
  .create()

export const getOCRPatientIdValues = async (
  documentId: number,
  getToken: any
): Promise<any> => {
  const token = ((await getToken({ template: 'UrgentIQ' })) as string) ?? ''
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${token}`,
      },
    },
  })
  try {
    return (await setupGetOCRPatientIdValues({ documentId })).data
  } catch (error) {
    if (error instanceof setupGetOCRInsuranceValues.Error) {
      // get discriminated union { status, data }
      const errorType = error.getActualType()
      if (errorType.status === 400) {
        throw errorType.data.validationErrors // 400 response data
      } else if (errorType.status === 500) {
        throw errorType.data.errorMessage // 500 response data
      }
    }
  }
}
