import React from 'react'
import { styled, Typography } from '@mui/material'
import { ModalButton } from '../Config'
import { ModalLayout } from '../Charting/Editor/IQBar/modals/ModalLayout'

const DeleteVisitPopUpRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
})

export const DeleteVisitPopUp = ({
  handleDelete,
  setClose,
}: DeleteVisitPopUpProps): JSX.Element => {
  return (
    <ModalLayout title="Confirm" handleCancel={setClose} width="300px">
      <Typography style={{ fontSize: '14px', marginBottom: '15px' }}>
        Are you sure you want to delete?
      </Typography>
      <DeleteVisitPopUpRow>
        <ModalButton
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation()
            setClose()
          }}
        >
          No
        </ModalButton>
        <ModalButton
          variant="contained"
          onClick={(e) => {
            e.stopPropagation()
            handleDelete()
          }}
        >
          Yes
        </ModalButton>
      </DeleteVisitPopUpRow>
    </ModalLayout>
  )
}

interface DeleteVisitPopUpProps {
  handleDelete: any;
  setClose: any;
}
