import {
  InputLabel,
  styled,
  TextField,
  type Theme,
  useTheme,
} from '@mui/material'
import React from 'react'
import { type UseFormRegister, type FieldValues } from 'react-hook-form'
import { PhotoIdFieldSelectedContext } from '@/context'
import { type PhotoIdFieldSelected } from '@/types'
import { ErrorMessage, RedText } from '@/components'
import { isEmpty } from '@/utility'

const StyledTextField = styled(TextField)(
  ({ fullsize, selected, theme }: StyledTextFieldProps) => ({
    width: fullsize ? '502px' : '240px',
    '& > div': {
      borderRadius: 0,
      backgroundColor: 'white',
    },
    ...(selected && {
      border: `1px solid ${theme?.palette.primary.main}`,
      borderRadius: '6px',
    }),
  })
)

export const TileTextField = ({
  id = 'tile-text-field',
  label = null,
  register,
  errors,
  fullsize = false,
  required = false,
}: TileTextFieldProps): JSX.Element => {
  const theme = useTheme()
  const { photoIdFieldSelected } = React.useContext(
    PhotoIdFieldSelectedContext
  )
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '64px' }}
      id={id}
    >
      {label !== null && (
        <InputLabel>
          {label}
          {required && <RedText>{' *'}</RedText>}
        </InputLabel>
      )}
      <StyledTextField
        fullsize={fullsize}
        {...register(id)}
        theme={theme}
        inputProps={{ 'data-testid': id }}
        selected={
          id in photoIdFieldSelected
            ? photoIdFieldSelected[id as keyof PhotoIdFieldSelected]
            : false
        }
      />
      {!isEmpty(errors[id]) && (
        <ErrorMessage error={errors[id]?.message as string} />
      )}
    </div>
  )
}

interface TileTextFieldProps {
  label?: string | null;
  fullsize?: boolean;
  id?: string;
  register: UseFormRegister<FieldValues>;
  required?: boolean;
  errors: any;
}

interface StyledTextFieldProps {
  fullsize: boolean;
  selected: boolean;
  theme: Theme;
}
