import React from 'react'
import { styled, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { type AuditRecord } from '@/types/AudtiRecord'

const PastVisitRowContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
})

const DateColumn = styled('div')({
  width: '100%',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: '#DDDFE8',
})
const TimeColumn = styled('div')({
  width: '15%',
  marginLeft: '15%',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: '#DDDFE8',
  flexDirection: 'column',
})
const TimeColumnNo = styled('div')({
  width: '15%',
  marginLeft: '15%',
})
const ProblemColumn = styled('div')({
  width: '10%',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: '#DDDFE8',
  flexDirection: 'column',
})
const OrdersColumn = styled('div')({
  width: '22%',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: '#DDDFE8',
  flexDirection: 'column',
})
const ProviderColumn = styled('div')({
  width: '16%',
  borderTopWidth: '1px',
  borderTopStyle: 'solid',
  borderTopColor: '#DDDFE8',
  flexDirection: 'column',
})
const ProviderColumnNo = styled('div')({
  width: '16%',
  flexDirection: 'column',
})
const ColumnValue = styled(Typography)({
  paddingLeft: '5px',
  paddingRight: '5px',
  fontSize: '14px',
  lineHeight: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: '40px',
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',

  /* identical to box height, or 143% */

  color: '#6F6F6F',
})
export const PastVisitRow = ({
  auditRecord,
  user,
  isNewDate,
}: PastVisitRowProps): JSX.Element => {
  let parsedOld: Record<string, string> = {}
  let parsedNew: Record<string, string> = {}
  try {
    parsedOld = JSON.parse(auditRecord.oldValues ?? '')
    parsedNew = JSON.parse(auditRecord.newValues ?? '')
  } catch (e) {
    parsedOld = {}
    parsedNew = {}
  }

  const builderRows = (): JSX.Element[] => {
    if (auditRecord.oldValues === undefined || auditRecord.oldValues === null) {
      return [<></>]
    }
    if (auditRecord.newValues === undefined || auditRecord.newValues === null) {
      return [<></>]
    }
    if (Object.keys(parsedOld).length === 0) {
      return [<></>]
    }
    if (Object.keys(parsedNew).length === 0) {
      return [<></>]
    }
    return Object.keys(parsedOld).map((key, i) => {
      if (parsedOld[key] === parsedNew[key]) {
        return <></>
      }
      return (
        <>
          <PastVisitRowContainer>
            {i === 0 ? (
              <TimeColumn>
                <ColumnValue>
                  {format(parseISO(auditRecord.dateTime ?? ''), 'hh:mm aa')}
                </ColumnValue>
              </TimeColumn>
            ) : (
              <TimeColumnNo>
                <ColumnValue></ColumnValue>
              </TimeColumnNo>
            )}
            <ProblemColumn>
              <ColumnValue>{key}</ColumnValue>
            </ProblemColumn>
            <OrdersColumn>
              <ColumnValue>{parsedOld[key]}</ColumnValue>
            </OrdersColumn>
            <OrdersColumn>
              <ColumnValue>{parsedNew[key]}</ColumnValue>
            </OrdersColumn>
            {i === 0 ? (
              <ProviderColumn>
                <ColumnValue>{user}</ColumnValue>
              </ProviderColumn>
            ) : (
              <ProviderColumnNo>
                <ColumnValue></ColumnValue>
              </ProviderColumnNo>
            )}
          </PastVisitRowContainer>
        </>
      )
    })
  }
  if (auditRecord.oldValues === auditRecord.newValues) {
    return <></>
  }

  return (
    <>
      {isNewDate && (
        <PastVisitRowContainer>
          <DateColumn>
            <ColumnValue>
              {`${format(parseISO(auditRecord.dateTime ?? ''), 'MM/dd/yyyy')}`}
            </ColumnValue>
          </DateColumn>
        </PastVisitRowContainer>
      )}
      {builderRows()}
    </>
  )
}
interface PastVisitRowProps {
  auditRecord: AuditRecord;
  user: string;
  isNewDate: boolean;
}
