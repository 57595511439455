import React from 'react'
import { styled, useTheme } from '@mui/material'
import { isEmpty } from '@/utility/utils'
import { TileInputLabel, TileTextField } from '../components'
import { StyledTextButton, StyledTextField } from '../FreeTextNotes/styles'

const TimeRowContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const TimeRowRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '776px',
})

export const TimeRow = ({
  text,
  value,
  setValue,
  note,
  setNote,
  handleWarn,
}: TimeRowProps): JSX.Element => {
  const [isNoteVisible, setIsNoteVisible] = React.useState(false)
  const theme = useTheme()

  React.useEffect(() => {
    if (!isEmpty(note)) {
      setIsNoteVisible(true)
    }
  }, [note])
  const setTime = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const regex = /^[0-9\b]+$/
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValue(e.target.value)
    }
  }
  return (
    <TimeRowContainer>
      <TimeRowRow>
        <TileInputLabel>{text}</TileInputLabel>
        <TileTextField
          value={value}
          inputProps={{ 'data-testid': `${text}` }}
          onChange={(e) => handleWarn(setTime, e)}
        />
        <StyledTextButton
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            textAlign: 'left',
            textDecorationLine: 'underline',
            color: theme.palette.primary.main,
          }}
          variant="text"
          data-testid={`add${text}Note`}
          onClick={() => setIsNoteVisible(!isNoteVisible)}
        >
          {isNoteVisible
            ? 'Hide Note'
            : isEmpty(note)
            ? 'Add Note'
            : 'Edit Note'}
        </StyledTextButton>
      </TimeRowRow>
      {
        /* prettier-ignore */
        isNoteVisible
          ? (
            /* prettier-ignore */
            <TimeRowRow>
              <StyledTextField
                onChange={e => handleWarn(setNote, e.target.value)}
                value={note}
                minRows={5}
                sx={{ width: '716px' }}
                data-testid={`${text}Note`}
              />
            </TimeRowRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : (<></>)
      }
    </TimeRowContainer>
  )
}
interface TimeRowProps {
  text: string;
  value: string;
  setValue: (value: string) => void;
  note: string;
  setNote: (note: string) => void;
  handleWarn: any;
}
