import { styled, Typography } from '@mui/material'
import React from 'react'

const TitleText = styled(Typography)({
  fontSize: '16px',
  lineHeight: '20px',
  color: '#ADB2C9',
})
export const PopoverTileText = ({
  children,
}: PopoverTileTextProps): JSX.Element => {
  return <TitleText>{children}</TitleText>
}

interface PopoverTileTextProps {
  children: any;
}
