import React from 'react'
import { components } from 'react-select'
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
  useTheme,
} from '@mui/material'
import {
  type Control,
  Controller,
  type UseFormRegister,
  useWatch,
} from 'react-hook-form'
import { useAuth } from '@clerk/nextjs'
import { AddressAutocomplete } from '@/components'

import { isEmpty } from '@/utility/utils'
import { ErrorMessage, RedText } from '@/components/ErrorMessage'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import { useQueryGetStates } from '@/hook'
import { TileTextField } from '../TileTextField'
import { PhotoIdFieldSelectedContext } from '@/context'

const AddressInputContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const AddressOuterRow = styled('div')({
  display: 'flex',
})

const AddressInnerRow = styled(AddressOuterRow)({
  gap: '24px',
  marginTop: '12px',
})

const AddressColumn = styled('div')(({ fullSize }: AddressColumnProps) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '100%',
  alignContent: 'flex-start',
  paddingRight: fullSize ? 0 : '10px',
  paddingBottom: '8px',
  gap: '24px',
}))
const customStyles = {
  control: (provided: any): any => ({
    ...provided,
    height: '36px',
    width: '768px',
  }),

  input: (provided: any): any => ({
    ...provided,
    height: '36px',
    width: '768px',
    marginTop: '-1px',
  }),
  container: (provided: any): any => ({
    ...provided,
    height: '36px',
    width: '768px',
  }),
  label: (provided: any): any => ({
    ...provided,
    height: '36px',
    width: '768px',
  }),
  indicatorSeparator: (provided: any): any => ({
    ...provided,
    display: 'none',
  }),
}

export const AddressInput = ({
  register,
  control,
  sectionName,
  setValue,
  errors,
  setError,
  disabled = false,
  fullSize = false,
  required = false,
}: AddressInputProps): JSX.Element => {
  const { getToken } = useAuth()
  const theme = useTheme()
  const [addressOpen, setAddressOpen] = React.useState<boolean>(false)
  const [inputValue, setInputValue] = React.useState<string>('')
  const { data: states } = useQueryGetStates(getToken)
  const addressWatch = useWatch({
    control,
    name: `${sectionName}street1`,
  })
  const { photoIdFieldSelected } = React.useContext(
    PhotoIdFieldSelectedContext
  )

  React.useEffect(() => {
    if (
      addressWatch !== undefined &&
      addressWatch !== inputValue &&
      !isEmpty(addressWatch)
    ) {
      setInputValue(addressWatch)
    }
    if (isEmpty(addressWatch)) {
      setInputValue('')
    }
  }, [addressWatch])

  React.useEffect(() => {
    const isZipCode = /(\d{5})$/.test(inputValue)

    if (inputValue !== addressWatch && !isZipCode && !isEmpty(inputValue)) {
      setValue(`${sectionName}street1`, inputValue)
    }
  }, [inputValue])

  const DropdownIndicator = (props: any): any => {
    return (
      <components.DropdownIndicator {...props}>
        {addressOpen ? (
          <ArrowDropUpOutlinedIcon />
        ) : (
          <ArrowDropDownOutlinedIcon />
        )}
      </components.DropdownIndicator>
    )
  }

  const handleAddressSelect = (address: any): void => {
    if (address.value) {
      setValue(`${sectionName}street1`, address.value.primary_line)
      setValue(`${sectionName}street2`, address.value.secondary_line)
      setValue(`${sectionName}city`, address.value.city)
      setValue(`${sectionName}state`, address.value.state)
      setValue(`${sectionName}zip`, address.value.zip_code)
      setError(`${sectionName}street1`, { type: 'manual', message: '' })
      setError(`${sectionName}city`, { type: 'manual', message: '' })
      setError(`${sectionName}state`, { type: 'manual', message: '' })
    }
  }

  return (
    <AddressInputContainer>
      <AddressOuterRow>
        <AddressColumn fullSize>
          <div>
            <InputLabel>
              Street 1
              {sectionName === 'demographics' || required ? (
                <RedText>{' *'}</RedText>
              ) : (
                ''
              )}
            </InputLabel>
            <AddressAutocomplete
              id={`${sectionName}street1`}
              apiKey={process.env.LOB_KEY ?? ''}
              onSelection={handleAddressSelect}
              addedStyles={{
                ...customStyles,
                container: {
                  ...customStyles.container,
                  width: fullSize ? '100%' : '768px',
                },
              }}
              inputValue={inputValue}
              setInputValue={setInputValue}
              onMenuClose={() => {
                setAddressOpen(false)
              }}
              onMenuOpen={() => {
                setAddressOpen(true)
              }}
              DropdownIndicator={DropdownIndicator}
              disabled={disabled}
            />
            {!isEmpty(errors[`${sectionName}street1`]) && (
              <ErrorMessage
                error={errors[`${sectionName}street1`].message as string}
              />
            )}
          </div>
          <TileTextField
            id={`${sectionName}street2`}
            label="Street 2"
            register={register}
            errors={errors}
            fullsize
          />
        </AddressColumn>
      </AddressOuterRow>
      <AddressInnerRow>
        <TileTextField
          id={`${sectionName}city`}
          label="City"
          register={register}
          errors={errors}
          required={sectionName === 'demographics' || required}
        />
        <div>
          <InputLabel id="StateLabel">
            State / Province
            {sectionName === 'demographics' || required ? (
              <RedText>{' *'}</RedText>
            ) : (
              ''
            )}
          </InputLabel>

          <Controller
            name={`${sectionName}state`}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Select
                sx={{
                  width: '240px',
                  background: 'white',
                  ...(photoIdFieldSelected.state && {
                    border: `1px solid ${theme?.palette.primary.main}`,
                    borderRadius: '6px',
                  }),
                }}
                {...field}
                labelId="StateLabel"
                tabIndex={11}
                data-testid={`${sectionName}states`}
              >
                {states?.map((state) => {
                  return (
                    <MenuItem
                      key={state.id}
                      value={state.code ?? ''}
                      data-testid={`${sectionName}state-${state.code ?? ''}`}
                    >
                      {state.name}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
          {!isEmpty(errors[`${sectionName}state`]) && (
            <ErrorMessage
              error={errors[`${sectionName}state`].message as string}
            />
          )}
        </div>
        <div>
          <InputLabel>
            Zip / Postal Code
            {sectionName === 'demographics' || required ? (
              <RedText>{' *'}</RedText>
            ) : (
              ''
            )}
          </InputLabel>
          <TextField
            sx={{ width: '240px' }}
            defaultChecked
            style={{ background: 'white' }}
            {...register(`${sectionName}zip`)}
            tabIndex={10}
            disabled={disabled}
            inputProps={{ 'data-testid': `${sectionName}zip` }}
          />
          {!isEmpty(errors[`${sectionName}zip`]) && (
            <ErrorMessage
              error={errors[`${sectionName}zip`].message as string}
            />
          )}
        </div>
      </AddressInnerRow>
    </AddressInputContainer>
  )
}

interface AddressInputProps {
  register: UseFormRegister<any>;
  control: Control<any, any>;
  sectionName: string;
  setValue: any;
  errors?: any;
  setError?: any;
  disabled?: boolean;
  fullSize?: boolean;
  required?: boolean;
}

interface AddressColumnProps {
  fullSize: boolean;
}
