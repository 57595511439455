import { styled } from '@mui/material'
import React from 'react'

const DetailColumnSmallDiv = styled('div')({
  display: 'flex',
  width: '25%',
  alignItems: 'center',
})

export const DetailColumnSmall = ({
  children,
}: DetailColumnSmallProps): JSX.Element => {
  return <DetailColumnSmallDiv>{children}</DetailColumnSmallDiv>
}

interface DetailColumnSmallProps {
  children?: JSX.Element | JSX.Element[] | undefined;
}
