import { styled, Typography } from '@mui/material'
import React from 'react'

const SectionTile = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '24px',
  /* identical to box height, or 150% */
  /* Black & White/Black */
  color: '#000000',
})

export const EFaxSectionTile = ({
  children,
}: EFaxSectionTileProps): JSX.Element => {
  return <SectionTile>{children}</SectionTile>
}

interface EFaxSectionTileProps {
  children: string;
}
