import React from 'react'
import { useAuth } from '@clerk/nextjs'
import {
  updatePatientFamilyHistory,
  deletePatientFamilyHistory,
} from '@/services'
import {
  usePatientFamilyHistoryStore,
  useQueryGetMedicalConditions,
  useQueryGetRelationships,
  useQueryGetPatientFamilyHistory,
  useMutateAddPatientFamilyHistory,
} from '@/hook'
import { PMHTile } from '../PatientHistoryTile'

export const PatientFamilyHistory = ({
  patient,
}: {
  patient: any;
}): JSX.Element => {
  const { getToken } = useAuth()

  const { patientFamilyHistories } = usePatientFamilyHistoryStore()

  const { data: medicalConditionsTypes } =
    useQueryGetMedicalConditions(getToken)

  const { data: relationships } = useQueryGetRelationships(getToken)

  const addNewPatientFamilyHistory = useMutateAddPatientFamilyHistory(getToken)

  const { isLoading: isFamilyHistoryLoading, refetch } =
    useQueryGetPatientFamilyHistory(patient?.id as number, getToken)

  if (isFamilyHistoryLoading) {
    return <div>Loading family history...</div>
  }

  const patientFamilyHistoryArray = Array.isArray(patientFamilyHistories)
    ? patientFamilyHistories
    : []

  const familyHistoryArray = Array.isArray(medicalConditionsTypes)
    ? medicalConditionsTypes
    : []

  const relationshipsArray = Array.isArray(relationships) ? relationships : []

  const columns = [
    { accessor: 'condition', Header: 'Problem' },
    { accessor: 'relationship', Header: 'Relationship' },
  ]

  const patientFamilyHistoriesWithName = patientFamilyHistoryArray.map(
    (history) => ({
      ...history,
      condition:
        history.medicalConditionId !== null
          ? medicalConditionsTypes?.find(
              (condition) => condition.id === history.medicalConditionId
            )?.name
          : history.otherProblem ?? 'Unknown Condition',
      relationship:
        history.relationshipId !== null
          ? relationships?.find((type) => type.id === history.relationshipId)
              ?.name
          : history.otherRelationship ?? '',
    })
  )

  const dialogFields = [
    {
      label: 'Problem',
      accessor: 'medicalConditionId',
      type: 'select',
      options: familyHistoryArray?.map((type) => ({
        id: type.id,
        label: type.name,
      })),
    },
    {
      label: 'Relationship',
      accessor: 'relationshipId',
      type: 'select',
      options: relationshipsArray?.map((relationship) => ({
        id: relationship.id,
        label: relationship.name,
      })),
    },
  ]

  const handleAdd = async (formData: any): Promise<void> => {
    /* eslint-disable @typescript-eslint/naming-convention */
    const {
      medicalConditionId: familyHistoryId,
      relationshipId: relationship,
      medicalConditionId_label: condition,
      relationshipId_label,
    } = formData

    if (!familyHistoryId || !condition) {
      await refetch()
      return
    }

    const newFamilyHistory = {
      patientId: patient.id,
      medicalConditionId:
        familyHistoryId && !isNaN(familyHistoryId)
          ? parseInt(familyHistoryId, 10)
          : null,
      otherProblem:
        familyHistoryId && isNaN(familyHistoryId)
          ? familyHistoryId
          : familyHistoryId
          ? null
          : condition,
      relationshipId:
        relationship && !isNaN(relationship)
          ? parseInt(relationship, 10)
          : null,
      otherRelationship:
        relationship && isNaN(relationship)
          ? relationship
          : relationship
          ? null
          : relationshipId_label,
      id: 0,
    }

    await addNewPatientFamilyHistory.mutateAsync({
      patientFamilyHistories: newFamilyHistory,
    })
    await refetch()
  }

  const handleEdit = async (data: any): Promise<void> => {
    await updatePatientFamilyHistory(data.id, data, getToken)
    await refetch()
  }

  const handleDelete = async (familyHistoryToDelete: any): Promise<void> => {
    if (!familyHistoryToDelete) {
      console.error('Could not find family history to delete')
      return
    }
    await deletePatientFamilyHistory(familyHistoryToDelete.id, getToken)
    await refetch()
  }

  return (
    <PMHTile
      title="Family History"
      data={patientFamilyHistoriesWithName}
      columns={columns}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      dialogFields={dialogFields}
    />
  )
}
