import React from 'react'
import { type WidgetProps } from '@rjsf/utils'
import { TileTextField } from '../../components'
import { InputLabel, styled } from '@mui/material'
import { ErrorMessage } from '@/components/ErrorMessage'

const HeaderField = styled(InputLabel)({
  fontSize: '18px',
  lineHeight: '24px',
  fontWeight: 600,
  color: '#222222',
  minWidth: '766px',
  height: '42px',
  marginTop: '12px',
})

export const CustomTextbox = function (props: WidgetProps): any {
  const [isNDCError, setIsNDCError] = React.useState<boolean>(false)
  const handleNDCChange = (event: any): void => {
    const value = event.target.value
    // Set NDC value
    if (value === '') {
      setIsNDCError(false)
      return
    }
    // Check against regex pattern
    const pattern =
      /^(?:\d{4}-\d{4}-\d{2}|\d{5}-\d{3}-\d{2}|\d{5}-\d{4}-\d{1}|\d{5}-\d{4}-\d{2})$/
    if (pattern.test(value)) {
      setIsNDCError(false)
    } else {
      setIsNDCError(true)
    }
  }

  if (props.disabled) {
    return <HeaderField>{props.label}</HeaderField>
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TileTextField
        id={props.id}
        value={props.value}
        label={props.label}
        inputProps={{ 'data-testid': props.label }}
        onChange={(event: any) => {
          props.label.toLocaleLowerCase() === 'ndc' && handleNDCChange(event)
          props.onChange(event.target.value)
        }}
      />
      {isNDCError && <ErrorMessage error="NDC must be in a valid format" />}
    </div>
  )
}
