import { styled, Typography } from '@mui/material'
import React from 'react'

const TitleContainer = styled(Typography)({
  fontWeight: 700,
  fontSize: '22px',
  lineHeight: '26px',
  paddingBottom: '10px',
})

export const Title = ({ titleText }: TitleProp): JSX.Element => {
  return <TitleContainer>{titleText}</TitleContainer>
}

interface TitleProp {
  titleText: string;
}
