import React from 'react'
import {
  PopoverTileText,
  PopoverValueText,
  PopoverContainer,
} from '@/components'

export const MedicalConditionsHoverOver = ({
  problem,
  activeOrInactive,
}: MedicalConditionsHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Problem</PopoverTileText>
        <PopoverValueText>{problem}</PopoverValueText>
      </div>
      <div>
        <PopoverValueText>{activeOrInactive}</PopoverValueText>
      </div>
    </PopoverContainer>
  )
}

interface MedicalConditionsHoverOverProps {
  problem: string;
  activeOrInactive: string;
}
