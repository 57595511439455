import { styled, Typography } from '@mui/material'
import React from 'react'
import {
  type ExamNode,
  type ExamStatusType,
  type ExamResultIdType,
} from '@/types'
import { SystemRow } from './SystemRow'
import { EXAM_ABNORMAL, EXAM_EMPTY, ROS_EMPTY, ROS_REPORTS } from '@/utility'

const ExamModalContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '450px',
  padding: '24px 24px 24px 0px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.paper,
}))
const ExamModalRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const SystemText = styled(Typography)({
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  alignSelf: 'center',
})
const TitleColumn = styled('div')({
  display: 'flex',
  flex: 1,
})
const RadioColumn = styled('div')({
  display: 'flex',
  width: '75px',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
})

const ResponseColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '500px',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  msOverflowStyle: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
})

export const ExamModal = ({
  examSystemComponents,
  examResponses,
  handleUpdateExam,
  examResultFactorIds,
  type,
  isSaving = false,
}: ExamModalProps): JSX.Element => {
  const STR_EMPTY = React.useMemo(
    () => (type === 'exam' ? EXAM_EMPTY : ROS_EMPTY),
    [type]
  )
  const STR_ABNORMAL = React.useMemo(
    () => (type === 'exam' ? EXAM_ABNORMAL : ROS_REPORTS),
    [type]
  )
  const getExamStatus = (exam: ExamNode): number => {
    if (examResponses[exam.id]?.status === examResultFactorIds[STR_ABNORMAL]) {
      return examResultFactorIds[STR_ABNORMAL]
    }
    for (const child of exam.children) {
      const childStatus = getExamStatus(child)
      if (childStatus === examResultFactorIds[STR_ABNORMAL]) {
        return examResultFactorIds[STR_ABNORMAL]
      }
    }
    return examResponses[exam.id]?.status ?? examResultFactorIds[STR_EMPTY]
  }

  const handleUpdate = async (
    examId: number,
    status: number
  ): Promise<void> => {
    if (status === examResultFactorIds[STR_EMPTY]) {
      const removeExam = async (exam: ExamNode): Promise<void> => {
        await Promise.all(
          exam.children.map(async (child) => await removeExam(child))
        )
        await handleUpdateExam(exam.id, status)
      }
      const rootExam = examSystemComponents.find(({ id }) => id === examId)
      if (rootExam) {
        await removeExam(rootExam)
      }
      return
    }
    await handleUpdateExam(examId, status)
  }

  const buildExamModalRows = (): JSX.Element[] => {
    return examSystemComponents.map((examSystemComponent: ExamNode) => {
      return (
        <ExamModalRow key={`${examSystemComponent.id ?? 0}`}>
          <SystemRow
            examResultFactorIds={examResultFactorIds}
            systomId={examSystemComponent.id ?? 0}
            selectedValue={
              getExamStatus(examSystemComponent) ??
              examResultFactorIds[STR_EMPTY]
            }
            handleChange={handleUpdate}
            type={type}
            isSaving={isSaving}
          />
          <TitleColumn>
            <SystemText>{examSystemComponent.name}</SystemText>
          </TitleColumn>
        </ExamModalRow>
      )
    })
  }

  return (
    <ExamModalContainer>
      {type === 'exam' && (
        <ExamModalRow>
          <RadioColumn>
            <SystemText>
              {type === 'exam' ? 'WNL' : 'Patient Reports'}
            </SystemText>
          </RadioColumn>
          <RadioColumn>
            <SystemText>
              {type === 'exam' ? 'ABN' : 'Patient Denies'}
            </SystemText>
          </RadioColumn>
          <TitleColumn />
        </ExamModalRow>
      )}
      <ResponseColumn>{buildExamModalRows()}</ResponseColumn>
    </ExamModalContainer>
  )
}

interface ExamModalProps {
  examSystemComponents: ExamNode[];
  examResponses: ExamStatusType[];
  handleUpdateExam: any;
  examResultFactorIds: ExamResultIdType;
  type: any;
  isSaving?: boolean;
}
