import { styled } from '@mui/material'
import React from 'react'

const PopoverContainerContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  gap: '12Px',
  minWidth: '450px',
  maxWidth: '630px',
  padding: '12px',
}))

export const PopoverContainer = ({
  children,
}: PopoverContainerProps): JSX.Element => {
  return <PopoverContainerContainer>{children}</PopoverContainerContainer>
}

interface PopoverContainerProps {
  children: any;
}
