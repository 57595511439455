import { Alert, Snackbar } from '@mui/material'
import React from 'react'

export enum TaskToastSeverity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}

export const TaskToast = ({
  message,
  severity,
  onClose,
}: TaskToastProps): JSX.Element | null => {
  if (!message) return null

  return (
    <Snackbar open autoHideDuration={2000} onClose={onClose}>
      <Alert onClose={onClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

interface TaskToastProps {
  onClose: () => void;
  message: string | null;
  severity: TaskToastSeverity;
}
