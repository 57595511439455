import { styled } from '@mui/material'
import React from 'react'

const DetailRowDiv = styled('div')({
  display: 'flex',
  width: '100%',
})

export const DetailRow = ({
  children,
  onClick,
}: DetailRowProps): JSX.Element => {
  return <DetailRowDiv onClick={onClick}>{children}</DetailRowDiv>
}

interface DetailRowProps {
  children: JSX.Element | JSX.Element[] | undefined;
  onClick?: () => void;
}
