import React, { useEffect, useState, useRef } from 'react'
import Select, { components } from 'react-select'
import base64 from 'base-64'
import { CircularProgress, styled } from '@mui/material'

const lobGrayText = {
  color: '#888',
  textDecoration: 'inherit',
}

// We override react-select's default input component in order to let users edit their input value
// and any selected values
const Input = (props: any): JSX.Element => (
  <components.Input {...props} isHidden={false} />
)

const ProgressBox = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
})
function throttle<T extends (...args: any[]) => any>(func: T, wait: number): T {
  let timeout: ReturnType<typeof setTimeout> | null = null
  let lastArgs: Parameters<T> | null = null
  let lastThis: any = null
  let lastCallTime: number = 0

  function invokeFunc(): void {
    if (lastArgs !== null) {
      func.apply(lastThis, lastArgs)
    }
    lastArgs = lastThis = null
    lastCallTime = Date.now()
    timeout = null
  }

  const throttled = function (this: any, ...args: Parameters<T>): void {
    const now = Date.now()
    const remainingTime = wait - (now - lastCallTime)

    lastArgs = args

    if (remainingTime <= 0 || remainingTime > wait) {
      if (timeout) {
        clearTimeout(timeout)
        timeout = null
      }
      lastCallTime = now
      func.apply(this, lastArgs)
      lastArgs = null
    } else if (!timeout) {
      timeout = setTimeout(invokeFunc, remainingTime)
    }
  }

  return throttled as T
}

const postAutocompleteAddress = async (
  apiKey: string,
  addressPrefix: string,
  additionalAddressData: any
): Promise<any> => {
  const url = new URL('https://api.lob.com/v1/us_autocompletions')
  url.searchParams.append('av_integration_origin', window.location.href)
  url.searchParams.append('integration', 'react-address-autocomplete')
  url.searchParams.append('valid_addresses', 'true')
  url.searchParams.append('case', 'proper')
  const init = {
    method: 'POST',
    headers: {
      // eslint-disable-next-line
      Authorization: `Basic ${base64.encode(String(apiKey) + ":")}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      address_prefix: addressPrefix,
      ...additionalAddressData,
      geo_ip_sort: true,
    }),
  }

  return await fetch(url, init)
}

// Highlight the users input in the primary line by comparing char by char. We only check the
// primary line for simplicity sake
const getOptionElement = (suggestion: any, inputValue: any): any => {
  const {
    primary_line: primaryLine,
    city,
    state,
    zip_code: zipCode,
  } = suggestion
  let boldStopIndex = 0

  inputValue.split('').forEach((inputChar: any) => {
    if (
      inputChar.toLowerCase() ===
      primaryLine.charAt(boldStopIndex).toLowerCase()
    ) {
      boldStopIndex += 1
    }
  })

  const primaryLineElement =
    boldStopIndex === 0 ? (
      <span>{primaryLine}, </span>
    ) : boldStopIndex === primaryLine.length ? (
      <span>
        <strong>{primaryLine}, </strong>
      </span>
    ) : (
      <span>
        <strong>{primaryLine.substring(0, boldStopIndex)}</strong>
        {primaryLine.substring(boldStopIndex)},{' '}
      </span>
    )

  return (
    <span>
      {primaryLineElement}
      <span style={lobGrayText}>
        {city},&nbsp;{state.toUpperCase()},&nbsp;{zipCode}
      </span>
    </span>
  )
}

export const AddressAutocomplete = ({
  apiKey,
  onSelection = () => {},
  onMenuClose = () => {},
  onMenuOpen = () => {},
  addedStyles = {},
  DropdownIndicator = {},
  inputValue = '',
  setInputValue = () => {},
  disabled = false,
  id = '',
}: AddressAutocompleteProps): JSX.Element => {
  const [selectValue, setSelectValue] = useState<string>('AD')
  const [autocompleteResults, setAutocompleteResults] = useState<string[]>([])
  const [fetching, setFetching] = useState<boolean>(false)
  const onError = (error: string): void => {
    console.error(error)
  }
  const onInputChange = (value: any, action: any): void => {}
  const primaryLineOnly = false
  const addressComponentValues = {}
  const delaySearch = false
  const delayValue = 800

  const fetchData = async (
    inputValue: any,
    addressComponentValues: any
  ): Promise<any> => {
    setFetching(true)
    try {
      const result = await postAutocompleteAddress(
        apiKey,
        inputValue,
        addressComponentValues
      )
      const { suggestions, error } = await result.json()

      if (error) {
        onError(error.message)
        return
      }

      // Filter out addresses with military state codes
      const filteredSuggestions = suggestions.filter(
        (suggestion: any) => !['AA', 'AE', 'AP'].includes(suggestion.state)
      )

      const newSuggestions = filteredSuggestions.map((x: any) => ({
        value: x,
        label: getOptionElement(x, inputValue),
      }))

      setAutocompleteResults([...newSuggestions])
    } catch (err: any) {
      console.error(err.message)
      onError(err.message)
    } finally {
      setFetching(false)
    }
  }

  const throttledFetchData = useRef(throttle(fetchData, delayValue)).current

  useEffect(() => {
    if (inputValue) {
      if (delaySearch) {
        // We pass inputValue manually because otherwise throttle would create a snapshot of
        // fetchData with the previous state of inputValue instead of the new updated one.
        void throttledFetchData(inputValue, addressComponentValues)
      } else {
        void fetchData(inputValue, addressComponentValues)
      }
    }
    // eslint-disable-next-line
  }, [inputValue, delaySearch]);

  /** Event handlers */
  const updateInputValueFromOption = (option: any): any => {
    if (!option) {
      setInputValue('')
      return
    }

    const {
      primary_line: primaryLine,
      secondary_line: secondaryLine,
      city,
      state,
      zip_code: zipCode,
    } = option.value

    if (primaryLineOnly) {
      setInputValue(primaryLine)
    } else {
      const secondary = secondaryLine ? ' ' + String(secondaryLine) : ''
      setInputValue(
        `${primaryLine as string}${secondary}, ${city as string}, ${
          state as string
        }, ${zipCode as string}`
      )
    }
  }

  // Fire when the user types into the input
  const handleInputChange = (
    newInputValue: string,
    { action }: { action: string }
  ): void => {
    if (action === 'input-change') {
      setInputValue(newInputValue)
      onInputChange(newInputValue, { action })
    }
  }

  // Fires when the select component has changed (as opposed to the input inside the select)
  const handleChange = (option: any): void => {
    // User has pasted an address directly into input, let's call the API
    if (typeof option === 'string') {
      setInputValue(option)
      setSelectValue(option)
      onSelection(option)
      return
    }
    updateInputValueFromOption(option)
    onSelection(option)
    setSelectValue('')
  }

  const handleKeyDown = (e: any): void => {
    if (e.keyCode === 13 && selectValue) {
      // Check if the key is Enter and if there's a selected value
      onSelection(selectValue) // Call your selection handler with the currently highlighted option
      setSelectValue('') // Clear the selected value
    }
  }
  const handleOptionFocus = (option: any): void => {
    setSelectValue(option)
  }

  const customFilter = (candidate: any, input: any): any => {
    return candidate
  }

  // Remove padding from first option which is our Lob label
  const customStyles = {
    control: (provided: any): any => ({
      ...provided,
      height: '36px',
      width: '768px',
      ...(addedStyles.control || {}),
    }),
    input: (provided: any): any => ({
      ...provided,
      height: '36px',
      width: '768px',
      marginTop: '-1px',
      ...(addedStyles.input || {}),
    }),
    container: (provided: any): any => ({
      ...provided,
      height: '36px',
      width: '768px',
      ...(addedStyles.container || {}),
    }),
    label: (provided: any): any => ({
      ...provided,
      height: '36px',
      width: '768px',
      ...(addedStyles.label || {}),
    }),
    indicatorSeparator: (provided: any): any => ({
      ...provided,
      display: 'none',
      ...(addedStyles.indicatorSeparator || {}),
    }),
    option: (styles: any, { data }: { data: any }) => {
      return {
        ...styles,
        ...(addedStyles.option || {}),
      }
    },
  }

  return (
    <Select
      id={id}
      components={{ Input, DropdownIndicator }}
      inputValue={inputValue}
      options={autocompleteResults}
      controlShouldRenderValue={false}
      noOptionsMessage={() =>
        fetching ? (
          <ProgressBox>
            <CircularProgress color="primary" />
          </ProgressBox>
        ) : (
          <div>No results</div>
        )
      }
      placeholder="Start typing an address..."
      value={selectValue}
      // eslint-disable-next-line
      onKeyDown={handleKeyDown}
      onMenuClose={onMenuClose}
      onMenuOpen={onMenuOpen}
      filterOption={customFilter}
      onChange={handleChange}
      onInputChange={handleInputChange}
      // onSelect={handleSelect}
      styles={customStyles}
      onFocus={handleOptionFocus}
      isDisabled={disabled}
    />
  )
}

interface AddressAutocompleteProps {
  apiKey: string;
  onSelection: any;
  onMenuClose: any;
  onMenuOpen: any;
  addedStyles: any | undefined;
  DropdownIndicator: any;
  inputValue: string;
  setInputValue: any;
  disabled: boolean;
  id?: string;
}
