import { styled, Typography } from '@mui/material'
import React from 'react'

export const ModalTitleContainer = styled(Typography)({
  fontWeight: 600,
  letterSpacing: '0.15px',
  fontSize: '16px',
  lineHeight: '24px',
  color: 'rgba(0, 0, 0, 0.87)',
})

export const ModalTitle = ({ children }: ModalTitleProps): JSX.Element => {
  return <ModalTitleContainer>{children}</ModalTitleContainer>
}

interface ModalTitleProps {
  children: string | string[];
}
