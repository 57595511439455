import { styled } from '@mui/material'
import React from 'react'

const TitleSmallDiv = styled('div')(({ avatar }: TitleSmallDivProps) => ({
  display: 'flex',
  width: avatar ? '24%' : '23.75%',
  alignContent: 'flex-end',
  justifyContent: 'left',
}))

export const TitleSmall = ({
  children,
  avatar = false,
}: TitleSmallProps): JSX.Element => {
  return <TitleSmallDiv avatar={avatar}>{children}</TitleSmallDiv>
}

interface TitleSmallProps {
  children: JSX.Element | JSX.Element[] | undefined;
  avatar?: boolean;
}

interface TitleSmallDivProps {
  avatar: boolean;
}
