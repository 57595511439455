import React from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Popover } from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'
import { type PatientMedication } from '@/types'
import {
  RowValue,
  AddColumn,
  AddRow,
  MedicationsHoverOver,
  RowTile,
} from '@/components'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { formatDateOnly } from '@/utility'

export const MedicationRow = ({
  medication,
  medicationName,
  delete: deleteDX,
  edit: editDX,
  isMedicationsMissing = false,
}: MedicationRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <MedicationsHoverOver
          name={medicationName}
          route={medication.route ?? ''}
          date={formatDateOnly(medication.prescriptionDate)}
          isMedicationsMissing={isMedicationsMissing}
        />
      </Popover>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={62}>
          <RowValue>{medicationName}</RowValue>
        </AddColumn>
        <AddColumn width={25}>
          <RowTile>Date Prescribed</RowTile>
          <RowValue>{formatDateOnly(medication.prescriptionDate)}</RowValue>
        </AddColumn>
        {isMedicationsMissing ? (
          <AddColumn width={4}>
            <IconButton color="warning" size="large">
              <WarningAmberIcon />
            </IconButton>
          </AddColumn>
        ) : (
          <AddColumn width={4}>{}</AddColumn>
        )}
        <AddColumn width={4}>
          <IconButton onClick={() => editDX(medication)} size="large">
            <ModeIcon />
          </IconButton>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton
            data-testid={'deleteMedication'}
            onClick={() => deleteDX(medication)}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </AddColumn>
      </AddRow>
    </>
  )
}
interface MedicationRowProps {
  medication: PatientMedication;
  medicationName: string;
  delete: (medication: PatientMedication) => void;
  edit: (medication: PatientMedication) => void;
  isMedicationsMissing?: boolean;
}
