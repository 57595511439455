import { styled } from '@mui/material'
import React from 'react'

const AddRowContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '766px',
  minHeight: '40px',
  backgroundColor: theme.palette.background.paper,
  paddingRight: '12px',
  paddingLeft: '12px',
  '&:first-of-type': {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  '&:last-of-type': {
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
  },
}))

export const AddRow = ({
  children,
  onMouseEnter,
  onMouseLeave,
}: AddRowProps): JSX.Element => {
  return (
    <AddRowContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </AddRowContainer>
  )
}

interface AddRowProps {
  children: JSX.Element | JSX.Element[];
  onMouseEnter?: ((event: React.MouseEvent<HTMLElement>) => void) | undefined;
  onMouseLeave?: (() => void) | undefined;
}
