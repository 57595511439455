import { styled, Typography } from '@mui/material'
import React, { type ReactNode } from 'react'

const TitleTextBox = styled(Typography)({
  fontSize: '14px',
  lineHeight: '16px',
  color: '#000000',
})

export const TitleText = ({ children }: TitleTextProps): JSX.Element => {
  return <TitleTextBox>{children}</TitleTextBox>
}

interface TitleTextProps {
  children: ReactNode;
}
