type ColorGroup = Record<string, string>;

export const TaskStatusColor: ColorGroup = {
  New: 'default',
  InProgress: 'info.main',
  Completed: 'success.main',
}

export const TaskPriorityColor: ColorGroup = {
  None: 'default',
  Low: 'success.main',
  Medium: 'warning.main',
  High: 'error',
}
