/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'
import { format } from 'date-fns'
import { useAuth, useUser } from '@clerk/nextjs'
import { Stack, ThemeProvider, type Theme } from '@mui/material'
import {
  type Patient,
  type Procedure,
  type Vital,
  type AssessmentPlan,
  type Visit,
} from '@/types'
import { formatDateOnly, isEmpty } from '@/utility/utils'
import {
  useClinicStore,
  useQueryGetAccountUsers,
  useQueryGetClinics,
  useQueryGetOrders,
  useQueryGetProcedureTypes,
  useQueryGetVisitsByPatient,
  useQueryGetVisitTypes,
  useTableTheme,
  useVisitStore,
} from '@/hook'
import {
  getAssessmentPlan,
  getInsuranceEligibility,
  getProcedure,
  getVital,
} from '@/services'
import { UnifiedTable } from '@/components/UnifiedTable'
import { useVisitColumns } from '@/components/PatientProfile/PastVisits/VisitColumns'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export function PastVisits({
  patient,
  tableInstanceRef,
  isColumnFixed,
  pinnedColumn,
  selectedTab,
  tabList,
  pagination,
  handleDoubleClickTable,
  handleChangeTab,
  tabsInfo,
  filterList,
  handleRemoveFilter,
  setPagination,
}: PastVisitsProps): JSX.Element {
  const { getToken } = useAuth()
  const { user } = useUser()
  const { clinicId } = useClinicStore()
  const [isVisitTableLoading, setIsVisitTableLoading] =
    React.useState<boolean>(false)
  const { data: accounts } = useQueryGetAccountUsers(getToken)
  const { data: visits } = useQueryGetVisitsByPatient(patient?.id!, getToken)
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const { data: procedureTypes } = useQueryGetProcedureTypes(
    clinicId,
    getToken
  )
  const { data: orders } = useQueryGetOrders(getToken)
  const { data: clinics } = useQueryGetClinics(getToken)
  const { assessmentPlans: visitPlans, setAssessmentPlans: setVisitPlans } =
    useVisitStore()
  const [procedures, setProcedures] = React.useState<Procedure[]>([])
  const [vitals, setVitals] = React.useState<Vital[]>([])
  const tableTheme = useTableTheme()

  const [insuranceStatus, setInsuranceStatus] = React.useState<
    Record<string, any>
  >({})

  const handleVerifyInsurance = async (dateOfVisit: string): Promise<void> => {
    setInsuranceStatus((prevStatus) => ({
      ...prevStatus,
      [dateOfVisit]: { ...insuranceStatus[dateOfVisit], isVerifying: true },
    }))
    try {
      const currentUser = insuranceStatus[dateOfVisit].currentUser
      // Construct insurance data for each insurance item
      const insurances: any[] = []
      patient?.patientInsuranceList?.forEach((insuranceItem): void => {
        insurances.push({
          memberID: insuranceItem?.insuranceNumber ?? '',
          payerName: insuranceItem?.companyName ?? '',
          provider_LastName: currentUser?.lastName ?? 'NEWMAN',
          provider_NPI: currentUser?.npiNumber ?? '1154321594',
          patient_First: insuranceItem.firstName ?? '',
          patient_DOB: formatDateOnly(patient.dateOfBirth),
          patient_Last: insuranceItem.lastName ?? '',
          payerCode: insuranceItem?.pVerifyInsuranceCompanyId ?? '',
          date_Of_Service: format(new Date(dateOfVisit), 'MM/dd/yyyy'),
        })
      })

      // Fetch insurance eligibility for each insurance
      const insuranceResponses = await Promise.all(
        insurances.map(
          async (insurance) =>
            await getInsuranceEligibility(insurance, getToken)
        )
      )

      // Process responses and update state
      const updatedInsuranceStatus = insuranceResponses.map((response) => ({
        copay: response?.isNpiInNetwork
          ? response?.copay
          : response?.copayOut ?? '',
        deductible: response?.isNpiInNetwork
          ? response?.deductible
          : response?.deductibleOut ?? '',
        leftToMeet: response?.isNpiInNetwork
          ? response?.deductibleRemaining
          : response?.deductibleRemainingOut ?? '',
        coinsurance: response?.isNpiInNetwork
          ? response?.coInsurancePercent
          : response?.coInsurancePercentOut ?? '0',
      }))

      const validResults = updatedInsuranceStatus.some(
        (object) =>
          !Object.values(object).every((value) => value === '' || value === '0')
      )
      setInsuranceStatus((prevStatus) => ({
        ...prevStatus,
        [dateOfVisit]: {
          ...insuranceStatus[dateOfVisit],
          isVerified: true,
          isVerifying: false,
          coverage: updatedInsuranceStatus,
          isInsuranceResultsValid: validResults,
        },
      }))
    } catch (err) {
      console.error(err)
      throw err
    }
  }

  const handleOpenChart = React.useCallback((visit: Visit): void => {
    if (isEmpty(visit)) return
    window.location.href = `/charting/${visit?.id! ?? 0}?previousNavOpen=false`
    localStorage.setItem('past-chart', visit?.id?.toString()!)
  }, [])

  const columns = useVisitColumns(
    patient,
    handleOpenChart,
    handleVerifyInsurance,
    vitals,
    procedures,
    insuranceStatus
  )

  const tableData = useMemo(
    () =>
      visits &&
      Array.isArray(visits) &&
      visits.map((visit) => {
        const procedure = procedures?.find(
          (procedure) => procedure?.visitId === visit.id
        )
        const temp: any = {
          ...visit,
          visitType: visitTypes?.find(
            (item) => item.id === visit?.chiefComplaint?.visitTypeId
          )?.name,
        }
        if (procedure !== undefined) {
          const types = procedure?.visitClinicalProcedureList?.map(
            (code: any) => {
              const type = procedureTypes?.find(
                (type) => type.id === code.clinicalProcedureId
              )
              return type?.name
            }
          )
          temp.procedures = types
        }
        if (visit?.providerAccountUserId !== 0) {
          const account = accounts?.find(
            ({ id = -1 }) => id === visit?.providerAccountUserId
          )
          if (account !== undefined) {
            temp.physician = `${account.firstName!} ${account.lastName!}`
          }
        }
        const plan = visitPlans?.find((item) => item?.visitId === visit.id)
        if (plan !== undefined) {
          temp.orders = plan.visitOrderList
            ?.map(
              (item: any) => orders?.find((order) => item.orderId === order.id)?.name
            )
            .filter((order: any) => order)
        }
        if (temp?.roomId !== undefined) {
          temp.location = clinics?.find(
            (clinic) => clinic.id === temp?.clinicId
          )?.name
        }
        const vital = vitals?.find((v) => visit.id === v?.visitId)
        if (vital) temp.vitals = vital
        return temp
      }),
    [
      visits,
      procedures,
      procedureTypes,
      accounts,
      visitTypes,
      visitPlans,
      orders,
      vitals,
    ]
  )

  const loadPlans = async (): Promise<void> => {
    setIsVisitTableLoading(true)
    const filteredVisits = Array.isArray(visits)
      ? visits.filter((visit) => Boolean(visit?.id))
      : []
    const planPromise = Promise.all(
      filteredVisits.map(
        async ({ id }) => await getAssessmentPlan(id!, getToken)
      )
    )
    Promise.resolve(await planPromise)
      .then((res) => {
        if (res !== undefined) {
          if (Array.isArray(res)) {
            setVisitPlans(res as AssessmentPlan[])
          } else {
            setVisitPlans([res])
          }
        } else {
          setVisitPlans([])
        }
      })
      .catch((err) => {
        throw err
      })
    setIsVisitTableLoading(false)
  }

  const loadProcedures = async (): Promise<void> => {
    setIsVisitTableLoading(true)
    const filteredVisits = Array.isArray(visits)
      ? visits.filter((visit) => Boolean(visit?.id))
      : []
    const procedurePromise = Promise.all(
      filteredVisits.map(async ({ id }) => await getProcedure(id!, getToken))
    )
    Promise.resolve(await procedurePromise)
      .then((res) => {
        if (res !== undefined) {
          if (Array.isArray(res)) {
            setProcedures(res as Procedure[])
          } else {
            setProcedures([res])
          }
        } else {
          setProcedures([])
        }
      })
      .catch((err) => {
        throw err
      })
    setIsVisitTableLoading(false)
  }

  async function loadVitals(): Promise<void> {
    const usersPromise = Promise.all(
      Array.isArray(visits)
        ? visits.map(async ({ id }) => await getVital(id ?? 0, getToken))
        : []
    )
    Promise.resolve(await usersPromise)
      .then((res) => {
        if (res !== undefined) {
          if (Array.isArray(res)) {
            setVitals(res)
          } else {
            setVitals([res])
          }
        } else {
          setVitals([])
        }
      })
      .catch((error) => {
        throw error
      })
  }

  React.useEffect(() => {
    loadProcedures().catch((error) => {
      throw error
    })
    loadPlans().catch((error) => {
      throw error
    })
    loadVitals().catch((error) => {
      throw error
    })
    const insuranceStatus: Record<string, any> = {}
    let id = '0'

    if (user?.publicMetadata.account_user_id) {
      id = user.publicMetadata.account_user_id as string
    }
    visits?.forEach((visit) => {
      let isInsuranceValid = false
      const currentUser = accounts?.find((userItem) =>
        userItem?.id === visit?.providerAccountUserId
          ? visit?.providerAccountUserId
          : parseInt(id)
      )
      // const currentUser = accounts?.find(userItem => userItem?.id === 3)
      patient.patientInsuranceList?.forEach((insuranceItem) => {
        if (isInsuranceValid) return
        const isValidMemberID =
          insuranceItem?.insuranceNumber != null &&
          insuranceItem?.insuranceNumber !== ''
        const isValidPayerCode =
          insuranceItem?.pVerifyInsuranceCompanyId != null &&
          insuranceItem?.pVerifyInsuranceCompanyId > 0
        if (isValidMemberID && isValidPayerCode) {
          isInsuranceValid = true
        }
      })
      insuranceStatus[visit?.arrivalDateTime!] = {
        coverage: [],
        isVerified: false,
        isVerifying: false,
        isInsuranceValid,
        currentUser,
      }
    })
    setInsuranceStatus(insuranceStatus)
  }, [visits])

  return (
    <Stack direction="column" style={{ width: '100%', height: '100%' }}>
      <ThemeProvider theme={tableTheme}>
        <UnifiedTable
          columns={columns}
          filteredTableData={tableData}
          tableInstanceRef={tableInstanceRef}
          isTableLoading={isVisitTableLoading}
          isColumnFixed={isColumnFixed}
          pinnedColumn={pinnedColumn}
          selectedTab={selectedTab}
          tabList={tabList}
          pagination={pagination}
          handleDoubleClickTable={handleDoubleClickTable}
          handleChangeTab={handleChangeTab}
          tabsInfo={tabsInfo}
          filterList={filterList}
          handleRemoveFilter={handleRemoveFilter}
          setPagination={setPagination}
          enableTopToolbar={false}
          enableRowActions
          enableColumnResizing
          tableType="past"
        />
      </ThemeProvider>
    </Stack>
  )
}

interface PastVisitsProps {
  patient: Patient;
  previousNavOpen: boolean;
  tableInstanceRef: any;
  filteredAppointments: any;
  isColumnFixed: boolean;
  pinnedColumn: string[];
  selectedTab: string;
  tabList: string[];
  pagination: any;
  handleDoubleClickTable: (data: any) => void;
  handleChangeTab: (event: React.ChangeEvent<{}>, newValue: number) => void;
  tabsInfo: any;
  filterList: any;
  handleRemoveFilter: (id: string) => void;
  setPagination: (page: number, pageSize: number) => void;
}
