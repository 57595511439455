import React from 'react'
import {
  InputLabel,
  MenuItem,
  Select,
  TextField,
  styled,
  Box,
  Stack,
  Typography,
  Skeleton,
} from '@mui/material'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useAuth } from '@clerk/nextjs'
import { getDSPharmacies } from '@/services'
import { PatternFormat } from 'react-number-format'
import { isEmpty } from '@/utility/utils'
import { useClinicStore, useDebounce, useQueryGetStates } from '@/hook'
import { useQueryGetDSToken } from '@/hook/useDoseSpotToken'
import {
  useMutateUpdatePharmacy,
  useQueryGetUrgentIQPharmacy,
} from '@/hook/useDoseSpotPharmacy'
import GoogleMapReact from 'google-map-react'
import { ModalLayout } from '@/components/Charting/Editor/IQBar/modals/ModalLayout'
import { ModalButton } from '@/components/Config'
import { specialties } from '@/utility'

const AddressRow = styled('div')({
  display: 'flex',
  gap: '12px',
  marginTop: '12px',
})

export const PharmacySelectionPopup = ({
  pharmacyId,
  onSelectPharmacy,
  setClose,
  clinicZip,
}: PharmacySelectionPopupProps): JSX.Element => {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const { data: states } = useQueryGetStates(getToken)
  const [dsPharmacies, setDSPharmacies] = React.useState<any[]>([])
  const { register, control, setValue } = useForm()
  const [currentPharmacy, setCurrentPharmacy] = React.useState<any>(null)
  const { data: pharmacy } = useQueryGetUrgentIQPharmacy(
    parseInt(pharmacyId ?? '0'),
    getToken
  )

  const updatePharmacyMutation = useMutateUpdatePharmacy(getToken)
  const nameWatch = useWatch({ control, name: 'name' })
  const cityWatch = useWatch({ control, name: 'city' })
  const stateWatch = useWatch({ control, name: 'state' })
  const zipWatch = useWatch({ control, name: 'zip' })
  const addressWatch = useWatch({ control, name: 'address' })
  const phoneOrFaxWatch = useWatch({ control, name: 'phoneOrFax' })
  const specialtyIdWatch = useWatch({ control, name: 'specialtyId' })
  const name = useDebounce<string>(nameWatch, 500)
  const city = useDebounce<string>(cityWatch, 500)
  const state = useDebounce<string>(stateWatch, 500)
  const zip = useDebounce<string>(zipWatch, 500)
  const address = useDebounce<string>(addressWatch, 500)
  const phoneOrFax = useDebounce<string>(phoneOrFaxWatch, 500)
  const specialtyId = useDebounce<number>(specialtyIdWatch, 500)

  useQueryGetDSToken(clinicId, getToken)

  React.useEffect(() => {
    setValue('zip', parseInt(clinicZip))
  }, [clinicZip])

  React.useEffect(() => {
    searchPharmacy()
  }, [name, city, state, zip, address, phoneOrFax, specialtyId])

  const searchPharmacy = (): void => {
    if (
      isEmpty(name) &&
      isEmpty(city) &&
      isEmpty(state) &&
      isEmpty(zip) &&
      isEmpty(address) &&
      isEmpty(phoneOrFax) &&
      isEmpty(specialtyId)
    ) {
      setDSPharmacies([])
      return
    }
    const pharmacyPromise = getDSPharmacies(
      name,
      city,
      state,
      zip,
      address,
      phoneOrFax,
      specialtyId,
      clinicId,
      getToken
    )
    Promise.resolve(pharmacyPromise)
      .then((res) => {
        if (res !== undefined) {
          setDSPharmacies(res)
        }
      })
      .catch((error) => {
        throw error
      })
  }
  const handleClickPharmacy = (pharmacy: any): void => {
    setCurrentPharmacy(pharmacy)
  }
  const handleSelectPharmacy = (): void => {
    if (isEmpty(currentPharmacy)) return
    updatePharmacyMutation
      .mutateAsync(currentPharmacy)
      .then((res) => {
        if (res !== undefined) {
          onSelectPharmacy(res)
        }
      })
      .catch((error) => {
        throw error
      })
  }
  return (
    <ModalLayout title="Select Pharmacy" handleCancel={setClose} width="unset">
      <Stack direction={'row'} gap={2}>
        <Stack direction={'column'} sx={{ width: '400px', height: '330px' }}>
          <Box>
            <InputLabel style={{ width: 'fit-content' }}>Name</InputLabel>
            <TextField
              defaultValue={pharmacy?.storeName ?? ''}
              {...register('name')}
              tabIndex={1}
              sx={{ width: '100%' }}
              inputProps={{ 'data-testid': 'pharmacyStoreName' }}
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <InputLabel>Address</InputLabel>
            <TextField
              {...register('address')}
              tabIndex={2}
              sx={{ width: '100%' }}
              inputProps={{ 'data-testid': 'pharmacyAddress' }}
            />
          </Box>
          <AddressRow>
            <div style={{ width: '180px' }}>
              <InputLabel>City</InputLabel>
              <TextField
                defaultChecked
                {...register('city')}
                tabIndex={10}
                inputProps={{ 'data-testid': 'pharmacyCity' }}
              />
            </div>
            <div
              style={{
                width: '180px',
                display: 'flex',
                flexDirection: 'column',
                height: 'fit-content',
              }}
            >
              <InputLabel id="StateLabel">State</InputLabel>

              <Controller
                name={'state'}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="StateLabel"
                    tabIndex={11}
                    style={{ width: '164px' }}
                    data-testid={'pharmacyState'}
                  >
                    {states?.map((state) => {
                      return (
                        <MenuItem
                          key={state.id}
                          value={state.code ?? ''}
                          sx={{ fontSize: '16px' }}
                          data-testid={`pharmacyState${
                            state.code ?? ('' as string)
                          }`}
                        >
                          {state.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              />
            </div>

            <div style={{ width: '180px' }}>
              <InputLabel>Zip</InputLabel>
              <Controller
                name={'zip'}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <PatternFormat
                      displayType="input"
                      format="##### ####"
                      mask=""
                      value={value}
                      onValueChange={(target: any) => {
                        onChange(target.floatValue)
                      }}
                      customInput={TextField}
                      tabIndex={12}
                      inputProps={{ 'data-testid': 'pharmacyZip' }}
                    />
                  )
                }}
              />
            </div>
          </AddressRow>
          <AddressRow>
            <div style={{ width: '194px' }}>
              <InputLabel>Phone Or Fax</InputLabel>
              <TextField
                {...register('phoneOrFax')}
                tabIndex={2}
                sx={{ width: '100%' }}
                inputProps={{ 'data-testid': 'pharmacyPhoneOrFax' }}
              />
            </div>
            <div style={{ width: '194px' }}>
              <InputLabel id="specialtyLabel">Specialty</InputLabel>
              <Controller
                name={'specialty'}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="specialtyLabel"
                    tabIndex={11}
                    style={{ width: '194px' }}
                    data-testid={'pharmacySpecialty'}
                  >
                    {specialties.map((specialty) => {
                      return (
                        <MenuItem
                          key={specialty.value}
                          value={specialty.value ?? ''}
                          data-testid={`pharmacySpecialty${specialty.value}`}
                        >
                          {specialty.name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                )}
              />
            </div>
          </AddressRow>
          <Box sx={{ flex: 1 }} />
          <AddressRow style={{ justifyContent: 'flex-end', marginTop: '20px' }}>
            <div style={{ display: 'flex' }}>
              <ModalButton
                variant="contained"
                onClick={handleSelectPharmacy}
                dataTestId={'selectBtn'}
              >
                Select
              </ModalButton>
            </div>
          </AddressRow>
        </Stack>
        {dsPharmacies.length === 0 && (
          <Stack
            direction={'column'}
            sx={{ height: '330px', overflowY: 'auto' }}
          >
            {Array.from({ length: 8 }).map((_, index) => (
              <MenuItem key={index} sx={{ width: '373px' }}>
                <Stack style={{ display: 'flex', flexDirection: 'column' }}>
                  <Skeleton variant="text" width={361} height={24} />
                  <Skeleton variant="text" width={180} height={16} />
                </Stack>
              </MenuItem>
            ))}
          </Stack>
        )}
        {dsPharmacies.length > 0 && (
          <Stack
            direction={'column'}
            sx={{ height: '330px', overflowY: 'auto' }}
          >
            {dsPharmacies?.map((pharmacy: any) => (
              <MenuItem
                key={pharmacy?.doseSpotPharmacyId}
                value={pharmacy?.storeName ?? ''}
                sx={{
                  backgroundColor:
                    currentPharmacy?.doseSpotPharmacyId ===
                    pharmacy?.doseSpotPharmacyId
                      ? 'rgba(40, 100, 255, 0.18)'
                      : '',
                }}
                onClick={() => handleClickPharmacy(pharmacy)}
                data-testId={`pharmacy${
                  pharmacy?.doseSpotPharmacyId as number
                }`}
              >
                <Stack style={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography>{pharmacy?.storeName}</Typography>
                  <Typography sx={{ fontSize: '10px', color: '#999' }}>
                    {`${pharmacy?.address1 as string}, ${
                      pharmacy?.city as string
                    },`
                      .toLowerCase()
                      .split(' ')
                      .map((item) => item[0]?.toUpperCase() + item.slice(1))
                      .join(' ') +
                      ` ${pharmacy?.state as string} ${
                        pharmacy?.zipCode as string
                      }`}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Stack>
        )}
        {currentPharmacy && (
          <Stack width={400}>
            {currentPharmacy && (
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.GOOGLEMAP_API_KEY ?? '' }}
                center={{
                  lat: currentPharmacy?.latitude,
                  lng: currentPharmacy?.longitude,
                }}
                defaultZoom={18}
                draggable={true}
              >
                <Box
                  sx={{
                    border: 2,
                    borderRadius: '50%',
                    borderColor: 'lightblue',
                    width: 50,
                    height: 50,
                  }}
                />
              </GoogleMapReact>
            )}
          </Stack>
        )}
      </Stack>
    </ModalLayout>
  )
}

interface PharmacySelectionPopupProps {
  pharmacyId: string;
  clinicZip: string;
  onSelectPharmacy: any;
  setClose: any;
}
