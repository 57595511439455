import React from 'react'

import { IconButton, Modal, styled } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'

import Image from 'next/image'

const ModalLayoutContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '320px',
  padding: '0 10px 10px 10px',
  borderRadius: '12px',
  backgroundColor: 'white',
  margin: 'auto',
  position: 'relative',
  outline: 'none',
})

const ThumbnailImage = styled(Image)({
  borderRadius: '50%',
})
const FullImage = styled(Image)({
  borderRadius: '12px',
  height: 'auto',
})
const ModalRow = styled('div')({
  display: 'flex',
  justifyContent: 'right',
  width: '100%',
})

export const AvatarImage = ({
  height,
  width,
  src,
  alt,
  loading = 'eager',
  marginRight = '0',
  disableFullImage = false,
  onClick,
  onMouseLeave,
  onMouseEnter,
}: AvatarImageProps): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)

  if (!Number.isSafeInteger(height) || !Number.isSafeInteger(width)) {
    throw new Error('Height or weight is not a safe integer')
  }

  return (
    <>
      <ThumbnailImage
        onClick={disableFullImage ? onClick : handleOpen}
        src={src.includes('ui-avatars.com') ? src + '&format=svg' : src}
        alt={alt}
        width={width}
        height={height}
        loading={loading}
        sx={{ marginRight }}
        data-testid="thumbnail-image"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        data-testid="avatar-modal"
      >
        <ModalLayoutContainer>
          <ModalRow>
            <IconButton
              sx={{ marginRight: '-10px' }}
              onClick={handleClose}
              size="large"
            >
              <ClearIcon data-testid="close-avatar-modal" />
            </IconButton>
          </ModalRow>
          <FullImage
            src={src.includes('ui-avatars.com') ? src + '&format=svg' : src}
            alt={alt}
            width={300} // Adjust this to your needs
            height={0}
            loading={loading}
            data-testid="fullsize-image"
          />
        </ModalLayoutContainer>
      </Modal>
    </>
  )
}

interface AvatarImageProps {
  height: number;
  width: number;
  src: string;
  alt: string;
  loading?: 'lazy' | 'eager';
  marginRight?: string;
  disableFullImage?: boolean;
  onClick?: () => void;
  onMouseEnter?: (e: any) => void;
  onMouseLeave?: () => void;
}
