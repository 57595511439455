import { styled } from '@mui/system'
import React from 'react'

const Column = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

export const FullColumn = ({ children }: FullColumnProps): JSX.Element => {
  return <Column>{children}</Column>
}
interface FullColumnProps {
  children: any;
}
