import React from 'react'

import { styled, Typography } from '@mui/material'
import { type Room } from '@/types'
import { useAuth } from '@clerk/nextjs'
import { useMutateDeleteRoom } from '@/hook'
import { ModalLayout } from '@/components/Charting/Editor/IQBar/modals/ModalLayout'
import { ModalButton } from '../../AddModals'

const DeleteVisitPopUpRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
})

export const DeleteRoomPopup = ({
  room,
  setClose,
  refreshRooms,
}: DeleteRoomPopUpProps): JSX.Element => {
  const { getToken } = useAuth()
  const deleteRoomMutation = useMutateDeleteRoom(getToken)
  const handleDeleteRoom = (): void => {
    deleteRoomMutation
      .mutateAsync(room.id!)
      .then(() => refreshRooms())
      .catch((error) => {
        throw error
      })
      .finally(() => {
        setClose(true)
      })
  }
  return (
    <ModalLayout title="Confirm" handleCancel={setClose} width="unset">
      <Typography style={{ fontSize: '14px', marginBottom: '15px' }}>
        {`Are you sure you want to delete ${room?.name ?? 'xxx'}?`}
      </Typography>
      <DeleteVisitPopUpRow>
        <ModalButton variant="outlined" onClick={() => setClose(true)}>
          No
        </ModalButton>
        <ModalButton variant="contained" onClick={handleDeleteRoom}>
          Yes
        </ModalButton>
      </DeleteVisitPopUpRow>
    </ModalLayout>
  )
}

interface DeleteRoomPopUpProps {
  room: Room;
  setClose: any;
  refreshRooms: any;
}
