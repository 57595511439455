import { useQueryGetDocumentTypes, useMutateUploadNewDocument } from '@/hook'
import { isEmpty } from '@/utility'
import { useAuth, useUser } from '@clerk/nextjs'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  MenuItem,
  Portal,
  Select,
  type SelectChangeEvent,
  Typography,
  styled,
} from '@mui/material'
import Image from 'next/image'
import React from 'react'
import {
  type DefaultExtensionType,
  FileIcon,
  defaultStyles,
} from 'react-file-icon'
import {
  Controller,
  type FieldValues,
  type SubmitHandler,
  useForm,
} from 'react-hook-form'
import { Document, Page } from 'react-pdf'
import { FileUpload } from '../FileUpload'
import { ModalButton, ModalTitle } from '../Config'
import {
  TaskToast,
  TaskToastSeverity,
} from '../TaskTable/components/TaskToast'

const StyledImg = styled(Image)({
  borderRadius: '14px',
})

const InsuranceCardBox = styled('div')({
  width: '350px',
  height: '212px',
  background: '#F7F8FC',
  border: '2px dashed #DDDFE8;',
  borderRadius: '14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const UploadButton = styled(Button)({
  width: '250px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 600,
})

export const DocumentUploadDialog = ({
  open,
  onClose,
  staticType,
  UserTaskId,
  PatientId,
  onSuccess
}: DocumentUploadDialogProps): JSX.Element => {
  const { getToken } = useAuth()
  const { user } = useUser()
  const { data: docTypes } = useQueryGetDocumentTypes(getToken)
  const uploadDocument = useMutateUploadNewDocument(getToken)
  const { handleSubmit, control } = useForm()
  const [uploadFile, setUploadFile] = React.useState<Blob[]>([])
  const [uploadType, setUploadType] = React.useState<number>(staticType ?? 0)
  const [toastMessage, setToastMessage] = React.useState<string | null>(null)
  const [toastSeverity] = React.useState<TaskToastSeverity>(
    TaskToastSeverity.Success
  )
  const [openID, setOpenID] = React.useState<boolean>(false)
  const [uploadCount, setUploadCount] = React.useState(0)
  const [uploadTotal, setUploadTotal] = React.useState(0)

  const container = React.useRef(null)

  const handleUploadTypeChange = (e: SelectChangeEvent<number>): void => {
    setUploadType((e.target as any).value)
  }

  const handleCancelUploadModal = (): void => {
    onClose()
    setUploadFile([])
  }

  const saveOtherDocuments = async (id: string, file: Blob): Promise<any> => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('documentTypeId', id)
    if (UserTaskId) {
      formData.append('userTaskId', `${UserTaskId}`)
    }
    if (PatientId) {
      formData.append('PatientID', `${PatientId}`)
    }
    formData.append(
      'AccountUserId',
      `${
        user?.publicMetadata?.account_user_id
          ? +user?.publicMetadata?.account_user_id
          : 0
      }`
    )

    const data = await uploadDocument.mutateAsync({ document: formData })
    return data.id
  }

  const handleSaveUploadModal = async (): Promise<void> => {
    const docIds = []
    setUploadTotal(uploadFile.length)
    const uploadFileCopy = [...(uploadFile ?? [])]
    while (uploadFileCopy.length > 0) {
      const nextFile = uploadFileCopy.pop()!
      const newDoc = await saveOtherDocuments(`${uploadType}`, nextFile)
      docIds.push(newDoc)
      setUploadCount(uploadCount + 1)
    }

    if (onSuccess) {
      onSuccess(docIds)
      handleCancelUploadModal()
    }
    onClose()
  }

  const handleOpenID = (): void => setOpenID(true)
  const handleCloseID = (): void => setOpenID(false)

  const handleSubmitDoc: SubmitHandler<FieldValues> = async (
    data: any
  ): Promise<void> => {}

  const handleNewFiles = (newFiles: Blob[]): void => {
    const merged = [...uploadFile, ...newFiles]
    setUploadFile(merged)
  }

  return (
    <>
      <Portal container={() => container.current}>
        <TaskToast
          message={toastMessage}
          severity={toastSeverity}
          onClose={() => setToastMessage(null)}
        />
      </Portal>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <FileUpload
          open={openID}
          onClose={handleCloseID}
          setFiles={(files: Blob[]) => handleNewFiles(files)}
          multiple
        />
        <DialogContent>
          <ModalTitle>Upload Document</ModalTitle>
          <form
            style={{
              display: 'flex',
              width: '100%',
              flexWrap: 'wrap',
              marginTop: '12px',
            }}
            onSubmit={(...args): void => {
              handleSubmit(handleSubmitDoc)(...args).catch((error) => {
                throw error
              })
            }}
          >
            {!isEmpty(docTypes) && !staticType && (
              <Controller
                control={control}
                name="document-type"
                render={({ field }) => (
                  <Select
                    {...field}
                    value={uploadType}
                    onChange={handleUploadTypeChange}
                    style={{
                      width: '100%',
                      background: 'white',
                      marginBottom: '10px',
                    }}
                  >
                    {docTypes?.map(({ id, name }: any) => (
                      <MenuItem value={id} key={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            )}
            {uploadFile.length > 0 ? (
              uploadFile[0]?.type?.includes('pdf') ? (
                <Document
                  file={uploadFile[0]}
                  loading={
                    <Box
                      sx={{
                        display: 'flex',
                        width: '350px',
                        height: '212px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                  error={
                    <Box
                      sx={{
                        display: 'flex',
                        width: '70px',
                        marginRight: '15px',
                      }}
                    >
                      <FileIcon
                        extension={'pdf'}
                        {...defaultStyles['pdf' as DefaultExtensionType]}
                        labelUppercase
                      />
                    </Box>
                  }
                >
                  <Page pageNumber={1} width={350} />
                </Document>
              ) : (
                <>
                  <StyledImg
                    width={350}
                    height={350}
                    alt=""
                    src={URL.createObjectURL(uploadFile[0])}
                  />
                </>
              )
            ) : (
              <InsuranceCardBox>
                <UploadButton
                  onClick={handleOpenID}
                  disabled={uploadType <= 0 && !staticType}
                >
                  {' '}
                  Upload Document
                </UploadButton>
              </InsuranceCardBox>
            )}
          </form>
          {uploadFile.length > 2 ?? (
            <Typography>+ {uploadFile.length - 1} more...</Typography>
          )}
          {uploadTotal > 0 ?? (
            <LinearProgress value={uploadCount / uploadTotal} />
          )}
        </DialogContent>
        <DialogActions>
          <ModalButton variant="outlined" onClick={handleCancelUploadModal}>
            Cancel
          </ModalButton>
          <ModalButton
            variant="contained"
            type="submit"
            onClick={() => {
              void handleSaveUploadModal()
            }}
          >
            Save
          </ModalButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

interface DocumentUploadDialogProps {
  open: boolean;
  onClose: any;
  staticType?: number;
  UserTaskId?: number;
  PatientId?: number | null;
  onSuccess?: (ids?: number[]) => void;
}
