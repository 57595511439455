import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { FileUploadSelect } from './FileUploadSelect'

interface Props {
  open: boolean;
  onClose: () => void;
  setFiles: any;
  multiple?: boolean;
  dataTestId?: string;
}

export const FileUpload: React.FC<Props> = ({
  open,
  onClose,
  setFiles,
  multiple,
  dataTestId = 'fileUpload',
}): JSX.Element => {
  const [selectedFiles, setSelectedFiles] = useState<File | FileList>()

  React.useEffect(() => {
    if (selectedFiles) {
      setFiles(selectedFiles)
    }
  }, [selectedFiles])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      data-testId={dataTestId}
    >
      <DialogTitle>Upload Files</DialogTitle>
      <DialogContent sx={{ width: '520px' }}>
        <FileUploadSelect
          multiple={multiple}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Done</Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
