import {
  useClinicStore,
  useQueryGetAccountUsers,
  useQueryGetRooms,
} from '@/hook'
import {
  type AccountUser,
  type Patient,
  type RecurringAppointment,
  type Visit,
} from '@/types'
import { type OnlineAppointment } from '@/types/OnlineAppointment'
import { isEmpty } from '@/utility'
import { useAuth } from '@clerk/nextjs'
import {
  Button,
  Dialog,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Select,
  styled,
  Typography,
} from '@mui/material'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import Link from 'next/link'
import React from 'react'
import { updateVisit, updateRecurringAppointment } from '@/services'
import { DeleteVisitPopUp } from '../DeleteVisitPopUp'

const StyledPaper = styled(Paper)({
  display: 'flex',
  gap: '5px',
  width: 'fit-content',
  alignItems: 'center',
  padding: '10px',
})
const StyledSelect = styled(Select)({
  height: '40px !important',
  backgroundColor: 'white',
  fontSize: '14px',
  borderRadius: 0,
})
export const VisitMenu = ({
  anchorEl,
  isMenuOpen,
  handleMenuClose,
  appointmentType,
  patient,
  id,
  isNavOpen,
  onDeleteVisit,
  onMoveToQueue,
  visit,
  groupBy,
  refresh,
}: VisitMenuProps): JSX.Element => {
  const { getToken } = useAuth()
  const [assignValue, setAssignValue] = React.useState<number | null>(null)
  const { data: clinicians } = useQueryGetAccountUsers(getToken)
  const [open, setOpen] = React.useState<boolean>(false)
  const { data: rooms } = useQueryGetRooms(getToken)
  const { clinicId } = useClinicStore()

  const onDeleteAppointment = (): void => {
    if ('status' in visit) {
      const newOnlineAppointment: RecurringAppointment = {
        ...visit,
        status: 3,
        statusString: 'Cancelled',
      }
      Promise.resolve(
        updateRecurringAppointment(id, newOnlineAppointment, getToken)
      )
        .catch((error) => {
          throw error
        })
        .finally(() => {
          refresh()
        })
      setOpen(false)
    }
  }

  const handleAssign = async (roomMode: boolean): Promise<void> => {
    if ('roomId' in visit && roomMode) {
      visit.roomId = assignValue
    } else if ('providerId' in visit && !roomMode) {
      visit.providerId = assignValue
    } else if ('providerAccountUserId' in visit && !roomMode) {
      visit.providerAccountUserId = assignValue
    }
    if ('patient' in visit) visit.patient = undefined
    if ('chiefComplaint' in visit) visit.chiefComplaint = undefined
    await updateVisit(visit?.id ?? 0, visit as Visit, getToken)
    refresh()
  }

  const goToIntake = (): string => {
    const queryParams = [
      isNavOpen && 'previousNavOpen=true',
      `firstName=${patient.firstName!}`,
      `lastName=${patient.lastName!}`,
      patient?.phoneNumber && `phone=${patient?.phoneNumber}`,
      patient?.email && `email=${patient?.email}`,
      patient?.dateOfBirth &&
        `dateOfBirth=${JSON.stringify(patient?.dateOfBirth)}`,
      `appointmentId=${id}`,
      visit?.id && `appointmentId=${visit?.id ?? 0}`,
      visit &&
        'providerId' in visit &&
        visit?.providerId &&
        `providerId=${visit?.providerId ?? 0}`,
      visit &&
        'roomId' in visit &&
        visit?.roomId &&
        `roomId=${visit?.roomId as unknown as string}`,
      visit &&
        'visitTypeId' in visit &&
        visit?.visitTypeId &&
        `visitTypeId=${visit?.visitTypeId as unknown as string}`,
      visit &&
        'appointmentEndDate' in visit &&
        visit?.appointmentEndDate &&
        `appointmentEndDate=${visit?.appointmentEndDate}`,
      visit &&
        'appointmentStartDate' in visit &&
        visit?.appointmentStartDate &&
        `appointmentStartDate=${visit?.appointmentStartDate}`,
      visit &&
        'visitCategoryId' in visit &&
        visit?.visitCategoryId &&
        `visitCategoryId=${visit?.visitCategoryId}`,
    ]
      .filter(Boolean)
      .join('&') // This filters out any undefined values and joins the rest with '&'

    const urlString = `/intake?${queryParams}`
    return urlString
  }

  const getProviderId = (visit: Visit | OnlineAppointment): number => {
    if (!visit) return 0
    return 'roomId' in visit
      ? visit.roomId!
      : 'providerId' in visit
      ? visit.providerId!
      : 'providerAccountId' in visit
      ? (visit.providerAccountId as number)
      : 0
  }
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!isEmpty(patient.id) && (
        <Link
          href={`/profile/${patient.id ?? 0}?previousNavOpen=${
            isNavOpen ? 'true' : 'false'
          }`}
        >
          <MenuItem onClick={handleMenuClose}>View Profile</MenuItem>
        </Link>
      )}

      {/* Conditional rendering based on appointmentType */}
      {appointmentType !== 'Visit' && (
        <>
          <Link href={goToIntake()}>
            <MenuItem onClick={handleMenuClose}>Check-in Patient</MenuItem>
          </Link>
          <>
            <MenuItem onClick={() => setOpen(true)}>Cancel Visit</MenuItem>
            <Dialog
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{ borderRadius: '12px' }}
            >
              <DeleteVisitPopUp
                handleDelete={onDeleteAppointment}
                setClose={() => setOpen(false)}
              />
            </Dialog>
          </>
        </>
      )}

      {/* Shared MenuItems for all appointment types */}
      <MenuItem
        onClick={() => {
          onMoveToQueue(visit)
          handleMenuClose()
        }}
      >
        Move to Queue
      </MenuItem>
      {appointmentType === 'Visit' && (
        <>
          <Link
            href={`/charting/${id}?previousNavOpen=${
              isNavOpen ? 'true' : 'false'
            }`}
          >
            <MenuItem onClick={handleMenuClose}>Open Charting</MenuItem>
          </Link>
          <MenuItem
            onClick={() => {
              onDeleteVisit(visit)
              handleMenuClose()
            }}
          >
            Delete Visit
          </MenuItem>
        </>
      )}
      <PopupState variant="popover" popupId="assign-popover">
        {(popupState) => (
          <>
            <MenuItem {...bindTrigger(popupState)}>
              Change {groupBy === 'providers' ? 'Room' : 'Provider'}
            </MenuItem>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <StyledPaper>
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {groupBy === 'providers' ? 'Room: ' : 'Provider: '}
                </Typography>
                <StyledSelect
                  fullWidth
                  sx={{ width: '190px' }}
                  defaultValue={
                    groupBy === 'providers' ? getProviderId(visit) : 0
                  }
                >
                  {groupBy === 'rooms'
                    ? clinicians
                        ?.filter(
                          (clinician) => clinician.lastName !== 'ServiceAccount'
                        )
                        .map((provider: AccountUser) => {
                          return (
                            <MenuItem
                              key={provider.username}
                              value={provider.id}
                              sx={{ fontSize: '13px' }}
                              onClick={() => setAssignValue(provider.id!)}
                            >
                              {`${provider.firstName ?? ''} ${
                                provider.lastName ?? ''
                              }`}
                            </MenuItem>
                          )
                        })
                    : rooms &&
                      rooms?.length > 1 &&
                      rooms
                        ?.filter((room) => room.clinicId === clinicId)
                        .map((room) => {
                          return (
                            <MenuItem
                              key={room.id}
                              value={room.id}
                              sx={{ fontSize: '13px' }}
                              onClick={() => setAssignValue(room.id!)}
                            >
                              {room.name}
                            </MenuItem>
                          )
                        })}
                </StyledSelect>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    fontSize: '13px',
                    width: 'fit-content',
                    height: '35px',
                  }}
                  onClick={(): void => {
                    handleAssign(groupBy === 'providers')
                      .catch((e) => {
                        throw e
                      })
                      .finally(() => popupState.close())
                  }}
                >
                  Confirm
                </Button>
              </StyledPaper>
            </Popover>
          </>
        )}
      </PopupState>
    </Menu>
  )
}

interface VisitMenuProps {
  anchorEl: null | HTMLElement;
  isMenuOpen: boolean;
  handleMenuClose: () => void;
  appointmentType: string;
  patient: Patient;
  id: number;
  isNavOpen: boolean;
  groupBy: string;
  onMoveToQueue: any;
  onDeleteVisit: any;
  visit: Visit | OnlineAppointment;
  refresh: any;
}
