import { useAuth } from '@clerk/nextjs'
import { useRouter } from 'next/navigation'
import { Dialog, Link, Menu, MenuItem, styled } from '@mui/material'
import { type ChartTemplate } from '@/types/ChartTemplate'
import React from 'react'
import { type ReviewOfSystem } from '@/types/ReviewOfSystems'
import { type Examination, type VisitExamination } from '@/types/Exam'
import { type AssessmentPlan } from '@/types'
import { type Coding } from '@/types/Coding'
import { DeleteTemplatePopup } from './DeleteTemplatePopup'
import {
  useMutateAddChartTemplate,
  useMutateAddExamination,
  useMutateUpdateAssessmentPlan,
  useMutateUpdateChartTemplate,
  useMutateUpdateCoding,
  useMutateUpdateProcedure,
  useQueryGetAssessmentPlan,
  useQueryGetCoding,
  useQueryGetProcedure,
  useVisitCodingStore,
} from '@/hook'
import { useMutateUpdateROS, useQueryGetROS } from '@/hook/useROS'
import { getExaminations, getVisitExaminations } from '@/services'

const StyledMenuItem = styled(MenuItem)({
  height: '36px',
  fontSize: '14px',
  lineHeight: '20px',
  width: '108px',
  color: '#000000',
  border: 'none',
  borderRadius: '0px',
  textDecoration: 'none',
})

export const TemplateMenu = ({
  template,
  isNavOpen,
  refresh,
  anchorEl,
  open,
  handleClose,
}: TemplateMenuProps): JSX.Element => {
  const { getToken } = useAuth()
  const router = useRouter()
  const [templateId, setTemplateId] = React.useState<number>(0)
  const [openePrescribe, setOpenePrescribe] = React.useState<boolean>(false)
  const handleCloseEPrescribe = (): void => setOpenePrescribe(false)
  const handleOpenEPrescribe = (): void => setOpenePrescribe(true)

  const { data: procedure } = useQueryGetProcedure(templateId, getToken)
  const { data: coding } = useQueryGetCoding(templateId, getToken)
  const { data: ros } = useQueryGetROS(templateId, getToken)
  const { data: assessmentPlan } = useQueryGetAssessmentPlan(
    templateId,
    getToken
  )
  const { setVisitCoding } = useVisitCodingStore()
  const updateProcedureMutation = useMutateUpdateProcedure(getToken)
  const addVisitExamination = useMutateAddExamination(getToken)
  const addNewChartTemplateMutation = useMutateAddChartTemplate(getToken)
  const updateChartTemplateMutation = useMutateUpdateChartTemplate(getToken)
  const updateCodingMutation = useMutateUpdateCoding(getToken)
  const updateAssessmentPlanMutation = useMutateUpdateAssessmentPlan(getToken)
  const updateROSMutation = useMutateUpdateROS(getToken)

  React.useEffect(() => {
    if (
      template.id !== 0 &&
      template.id !== undefined &&
      template.id !== null
    ) {
      setTemplateId(template.id)
    }
  }, [template.id])
  const loadAssessmentPlan = async (id: number): Promise<void> => {
    if (assessmentPlan !== undefined) {
      const newAP: AssessmentPlan = assessmentPlan
      newAP.visitId = id
      if (
        newAP.visitAssessmentDiagnosisDtoList !== undefined &&
        newAP.visitAssessmentDiagnosisDtoList !== null
      ) {
        newAP.visitAssessmentDiagnosisDtoList =
          assessmentPlan?.visitAssessmentDiagnosisDtoList?.map(
            (diagnosis: any) => {
              const newDiagnosis = diagnosis
              newDiagnosis.id = 0
              newDiagnosis.visitId = id
              return newDiagnosis
            }
          )
      }
      if (newAP.visitOrderList !== undefined && newAP.visitOrderList !== null) {
        newAP.visitOrderList = assessmentPlan?.visitOrderList?.map(
          (order: any) => {
            const newOrder = order
            newOrder.id = 0
            newOrder.visitId = id
            return newOrder
          }
        )
      }
      updateAssessmentPlanMutation
        .mutateAsync({ vId: id, assessmentPlan: newAP })
        .catch((error) => {
          throw error
        })
    }
  }
  const loadProcedure = async (id: number): Promise<void> => {
    if (procedure !== undefined) {
      const newProcedure = procedure
      newProcedure.visitId = id
      newProcedure.visitClinicalProcedureList =
        newProcedure?.visitClinicalProcedureList?.map((cpt) => {
          cpt.visitClinicalProcedureCptModifierCodeList =
            cpt.visitClinicalProcedureCptModifierCodeList?.map((modifier) => {
              modifier.id = 0
              return modifier
            })
          cpt.visitClinicalProcedureFieldList =
            cpt.visitClinicalProcedureFieldList?.map((field) => {
              field.id = 0
              return field
            })
          cpt.id = 0
          cpt.visitId = id
          return cpt
        })
      await updateProcedureMutation.mutateAsync({
        vId: id,
        chiefProcedure: newProcedure,
      })
    }
  }

  const loadCoding = async (id: number): Promise<void> => {
    if (coding) {
      const newCoding: Coding = {
        ...coding,
        visitId: id,
        clinicalProcedureDTO: [],
        diagnosisCodeDtoList: [],
      }

      updateCodingMutation
        .mutateAsync({ vId: id, chiefCoding: newCoding })
        .then((res) => {
          setVisitCoding(res)
        })
        .catch((error) => {
          throw error
        })
    }
  }

  const loadROS = async (id: number): Promise<void> => {
    if (ros !== undefined) {
      const newROS = ros.map((ros: ReviewOfSystem) => {
        const newReview = ros
        newReview.visitId = id
        newReview.id = 0
        if (
          newReview.visitRosSymptomList !== undefined &&
          newReview.visitRosSymptomList !== null
        ) {
          newReview.visitRosSymptomList = ros?.visitRosSymptomList?.map(
            (symptom) => {
              const newSymptom = symptom
              newSymptom.id = 0
              newSymptom.visitId = id
              return newSymptom
            }
          )
        }
        return newReview
      })
      updateROSMutation.mutateAsync({ vId: id, ros: newROS }).catch((err) => {
        throw err
      })
    }
  }

  const loadExams = async (id: number, date: Date): Promise<void> => {
    const tempExams: VisitExamination[] =
      (await getVisitExaminations(templateId, getToken)) ?? []
    const allExaminations: Examination[] = await getExaminations(getToken, date)
    const tempLoadPromise = tempExams.map((exam): any => {
      if (!exam.id) {
        return null
      }
      const newExam = allExaminations.find(
        ({ id }) => exam.examinationId === id
      )
      return addVisitExamination.mutateAsync({
        visitExamination: {
          visitId: id,
          examinationId: newExam?.id,
          examResultFactorId: exam.examResultFactorId,
          notes: exam.notes,
        },
      })
    })
    Promise.all(tempLoadPromise).catch((error) => {
      throw error
    })
  }

  const handleCopy = async (): Promise<void> => {
    const newTemplate = template
    newTemplate.templateName = `${template.templateName ?? ''} (copy)`
    newTemplate.id = 0
    addNewChartTemplateMutation
      .mutateAsync({ chartTemplate: newTemplate })
      .then((res) => {
        loadAssessmentPlan(res?.id!).catch((error) => {
          throw error
        })
        loadProcedure(res?.id!).catch((error) => {
          throw error
        })
        loadCoding(res?.id!).catch((error) => {
          throw error
        })
        loadROS(res?.id!).catch((error) => {
          throw error
        })
        loadExams(res?.id!, new Date(res?.createdAt ?? new Date())).catch((error) => {
          throw error
        })
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        refresh()
      })
  }

  const handleDelete = (): void => {
    const newTemplate = template
    newTemplate.templateIsHidden = true
    updateChartTemplateMutation
      .mutateAsync({
        chartTemplateId: template?.id!,
        chartTemplate: newTemplate,
      })
      .then(() => {
        refresh()
        router.push(
          `configurations?${
            isNavOpen ? 'previousNavOpen=true&' : ''
          }tab=template`
        )
        handleCloseEPrescribe()
      })
      .catch((err) => {
        throw err
      })
  }

  return (
    <>
      <Dialog
        open={openePrescribe}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ borderRadius: '12px' }}
      >
        <DeleteTemplatePopup
          name={template.templateName ?? ''}
          handleDelete={handleDelete}
          setClose={handleCloseEPrescribe}
          refreshTemplates={refresh}
        />
      </Dialog>
      <Menu
        id="template-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ marginTop: '-20px', ' & > *': { borderRadius: '8px' } }}
      >
        <Link
          href={`/configurations/templates/${template.id ?? 0}${
            isNavOpen ? '?previousNavOpen=true' : ''
          }`}
          sx={{ textDecoration: 'none' }}
        >
          <StyledMenuItem>Edit Details</StyledMenuItem>
        </Link>
        <StyledMenuItem
          onClick={() => {
            handleCopy().catch((err) => {
              throw err
            })
          }}
        >
          Make a Copy
        </StyledMenuItem>
        <StyledMenuItem onClick={handleOpenEPrescribe}>Delete</StyledMenuItem>
      </Menu>
    </>
  )
}

interface TemplateMenuProps {
  template: ChartTemplate;
  isNavOpen: boolean;
  refresh?: any;
  anchorEl: any;
  open: boolean;
  handleClose: any;
}
