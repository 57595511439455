import React from 'react'
import { type TaskTableCellProps } from './TaskTableCellProps'
import { Box, CircularProgress, Popover, Stack } from '@mui/material'
import { CountCircle, DocumentName, StyledTableText } from './styled'
import {
  type DefaultExtensionType,
  FileIcon,
  defaultStyles,
} from 'react-file-icon'
import { useAuth } from '@clerk/nextjs'
import { DocumentsPopover } from '@/components/Task/DocumentsPopover'
import { useTasksStore } from '@/hook/useTasks'
import { type UIQDocument } from '../../../../types/Document'
import { type Task } from '@/types/Task'
import { useDocument } from '@/hook/useDocument'

export const DocumentCell = ({
  row,
}: TaskTableCellProps): JSX.Element => {
  const { getToken } = useAuth()
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [taskDocument, setTaskDocument] = React.useState<UIQDocument | null>(
    null
  )
  const [task, setTask] = React.useState<Task>()
  const [loading, setLoading] = React.useState<boolean>(false)
  const { taskPage } = useTasksStore()
  const { documents, getDocument } = useDocument()

  React.useEffect(() => {
    const cachedTask = taskPage?.items?.find((task) => task.id === row.original.id)
    setTask(cachedTask)
  }, [taskPage?.items, row, documents])

  React.useEffect(() => {
    if (!task?.documentId || task.documentId.length === 0) return

    setLoading(true)
    const doc = getDocument(task.documentId[0], getToken)
    setTaskDocument(doc)
    setLoading(false)
  }, [task, documents])

  const ext = React.useMemo(() => {
    if (!taskDocument?.visibleDocumentName) {
      return ''
    }
    return taskDocument.visibleDocumentName.split('.').reverse()[0]
  }, [taskDocument])

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {!taskDocument && !loading && <>-</>}
      {loading && (
        <Box>
          <CircularProgress size={20} />
          <StyledTableText>Loading Documents</StyledTableText>
        </Box>
      )}
      {!loading && taskDocument && (
        <>
          <Stack
            direction="row"
            onClick={() => {
              void window.open(taskDocument.presignedUrl!, '_blank')
            }}
          >
            <Box sx={{ display: 'flex', width: '20px', marginRight: '15px' }}>
              <FileIcon
                extension={ext}
                {...defaultStyles[ext as DefaultExtensionType]}
                labelUppercase
              />
            </Box>
            <DocumentName>{taskDocument.visibleDocumentName}</DocumentName>
          </Stack>
          {task?.documentId && task?.documentId?.length > 1 && (
            <>
              <Box onClick={handleClick}>
                <CountCircle id="documents">
                  +{task?.documentId?.length - 1}
                </CountCircle>
              </Box>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  horizontal: 'left',
                  vertical: 'top',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
              >
                <DocumentsPopover documentIds={task.documentId} />
              </Popover>
            </>
          )}
        </>
      )}
    </Stack>
  )
}
