import { Box, keyframes, styled } from '@mui/material'
import React from 'react'

const shimmer = keyframes`
  0% {
    background-position: -768px 0;
  }
  100% {
    background-position: 768px 0;
  }
`

// Styled component for the shimmer effect
const ShimmerBox = styled(Box)`
  display: inline-block;
  height: 65px; // Default height
  width: 766px; // Default width
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  animation: ${shimmer} 2s linear infinite;
  border-radius: 12px;
`
export const Shimmer = ({
  width = '766px',
  height = '65px',
}: {
  width?: string;
  height?: string;
}): JSX.Element => {
  return <ShimmerBox sx={{ width, height }} />
}
