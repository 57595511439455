import React from 'react'
import { useAuth } from '@clerk/nextjs'
import { styled, Typography, IconButton, Stack, Tooltip } from '@mui/material'
import { CalendarColors } from '../../data_helpers/calendercolors'
import { type Visit, type AccountUser, type Room, type Patient } from '@/types'
import { formatAgeWithRules, formatGender, isEmpty } from '@/utility/utils'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import ConstructionIcon from '@mui/icons-material/Construction'
import { AvatarImage } from '../AvatarImage'
import { type OnlineAppointment } from '@/types/OnlineAppointment'
import { VisitMenu } from './VisitMenu'
import { getPatient } from '@/services/PatientRegistrationService'

const PatientNameText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '14px',
  color: theme.palette.black,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))

const PatientInfoText = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '14px',
  color: theme.palette.black,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}))

const VisitRow = styled('div')({
  display: 'flex',
  alignItems: 'center', // Center items vertically in the row
  justifyContent: 'flex-start', // Align items to the start of the row
  gap: '2px',
  overflow: 'hidden',
  flexWrap: 'nowrap',
  flexGrow: 1,
  flexShrink: 1,
})

const VisitContainer = styled('div')(
  ({ visitTypeId }: VisitContainerProps): any => {
    return {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      overFlow: 'hidden',
      alignItems: 'flex-start',
      padding: '4px 8px',
      position: 'relative',
      gap: '4px',
      backgroundColor: `${CalendarColors[visitTypeId ?? 0]?.body}`,
      borderRadius: '3px',
      borderLeft: `3px solid ${CalendarColors[visitTypeId ?? 0]?.highline}`,
    }
  }
)
const EventActions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  justifyContent: 'space-between',
  top: 0,
  right: 0,
  bottom: 0,
})

export const VisitBlock = ({
  visit,
  complaint,
  visitType,
  groupBy,
  onMoveToQueue,
  onDeleteVisit,
  refresh,
  isNavOpen,
}: VisitBlockProps): JSX.Element => {
  const { getToken } = useAuth()
  const [patient, setPatient] = React.useState<Patient>({
    firstName: '',
    lastName: '',
  })
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const isMenuOpen = Boolean(anchorEl)
  const handleMenuOpen = (event: any): void => {
    setAnchorEl(event?.currentTarget)
  }

  const handleMenuClose = (): void => {
    setAnchorEl(null)
  }

  React.useEffect(() => {
    if (!visit) return
    if (
      'patient' in visit &&
      visit.patient !== undefined &&
      visit.patient !== null
    ) {
      setPatient(visit?.patient ?? {})
    } else if ('patientId' in visit) {
      if (
        visit.patientId === 0 ||
        visit.patientId === undefined ||
        visit.patientId === null
      ) {
        if (
          'firstName' in visit &&
          'lastName' in visit &&
          'dateOfBirth' in visit
        ) {
          setPatient({
            firstName: visit.firstName!,
            lastName: visit.lastName!,
            dateOfBirth: visit.dateOfBirth!,
          })
        }
      } else {
        getPatient(visit.patientId ?? 0, getToken)
          .then((res) => {
            setPatient(res)
          })
          .catch((err) => {
            throw err
          })
      }
    }
    setIsLoading(false)
  }, [visit])

  const formatVisitTypeComplaint = (): string => {
    if (
      visitType !== '' &&
      patient?.firstName !== '' &&
      patient?.lastName !== ''
    ) {
      return `${visitType} ${
        complaint.length > 0 ? '(' + complaint + ')' : ''
      }`
    }
    return ''
  }
  const hasChiefComplaint = (
    visit: any
  ): visit is { chiefComplaint: { visitTypeId: number } } =>
    'chiefComplaint' in visit && visit.chiefComplaint !== null

  const hasVisitTypeId = (visit: any): visit is { visitTypeId: number } =>
    'visitTypeId' in visit

  const hasAppointmentTypeId = (
    visit: any
  ): visit is { appointmentTypeId: number } => 'appointmentTypeId' in visit

  const getVisitTypeId = (visit: Visit | OnlineAppointment): number => {
    if (!visit) return 0
    if (hasChiefComplaint(visit)) return visit?.chiefComplaint?.visitTypeId
    if (hasVisitTypeId(visit)) return visit?.visitTypeId
    if (hasAppointmentTypeId(visit)) return visit?.appointmentTypeId
    return 0
  }
  const visitTypeId = getVisitTypeId(visit)
  return (
    <>
      <VisitContainer
        onContextMenu={(e) => {
          e.preventDefault()
        }}
        visitTypeId={visitTypeId ?? 0}
      >
        {' '}
        {patient?.firstName !== '' && patient?.lastName !== '' && !isLoading && (
          <Stack
            direction="row"
            sx={{ alignItems: 'center', width: 'calc(100% - 15px)' }}
          >
            <AvatarImage
              alt="avatar"
              width={28}
              height={28}
              src={patient?.avatarUrl ?? '/user.png'}
              loading="lazy"
            />
            <Stack
              direction="column"
              sx={{ marginLeft: '5px', width: 'calc(100% - 15px)' }}
            >
              <VisitRow>
                <PatientNameText>
                  {`${patient?.firstName!} ${patient?.lastName!} `}
                </PatientNameText>
                <PatientInfoText>{`(${formatAgeWithRules(
                  patient?.dateOfBirth
                )})${!isEmpty(patient?.sex) ? ', ' : ''} ${formatGender(
                  patient?.sex!
                )}`}</PatientInfoText>
              </VisitRow>
              <VisitRow>
                <PatientInfoText>{formatVisitTypeComplaint()}</PatientInfoText>
              </VisitRow>
            </Stack>
          </Stack>
        )}
        <EventActions>
          <Stack
            direction="column"
            height={'100%'}
            justifyContent="space-between"
            sx={{ paddingBottom: '2px' }}
          >
            <>
              <IconButton
                size="small"
                sx={{ width: '17px', height: '17px' }}
                onClick={handleMenuOpen}
              >
                <ConstructionIcon
                  fontSize="inherit"
                  sx={{ width: '15px', height: '15px' }}
                />
              </IconButton>
              <VisitMenu
                anchorEl={anchorEl}
                isMenuOpen={isMenuOpen}
                handleMenuClose={handleMenuClose}
                appointmentType={
                  visit && 'chiefComplaint' in visit
                    ? 'Visit'
                    : 'OnlineAppointment'
                }
                patient={patient}
                id={visit?.id ?? 0}
                isNavOpen={isNavOpen}
                onMoveToQueue={onMoveToQueue}
                onDeleteVisit={onDeleteVisit}
                visit={visit}
                groupBy={groupBy}
                refresh={refresh}
              />
              {visit && 'chiefComplaint' in visit && (
                <Tooltip title="Patient Checked In" arrow>
                  <WhereToVoteIcon
                    fontSize="inherit"
                    sx={{ width: '15px', height: '15px' }}
                  />
                </Tooltip>
              )}
            </>
          </Stack>
        </EventActions>
      </VisitContainer>
    </>
  )
}
interface VisitBlockProps {
  visit: Visit | OnlineAppointment;
  rooms: Room[];
  clinicians: AccountUser[];
  complaint: string;
  visitType: string;
  groupBy: string;
  onMoveToQueue: any;
  onOpenChart: any;
  onDeleteVisit: any;
  onProfileView: any;
  refresh: any;
  isNavOpen: boolean;
}
interface VisitContainerProps {
  visitTypeId: number;
}
