import { styled, Typography } from '@mui/material'
import React from 'react'

const TileTitleText = styled(Typography)({
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '26px',
  paddingBottom: 20,
  color: 'black',
})
export const TileTitleBox = ({ tileTitle }: TileTitleBoxProps): JSX.Element => {
  return <TileTitleText>{tileTitle}</TileTitleText>
}

interface TileTitleBoxProps {
  tileTitle: string;
}
