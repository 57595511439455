import React from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Popover } from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'

import { type PatientMedicalCondition } from '@/types'
import {
  RowValue,
  AddColumn,
  AddRow,
  MedicalConditionsHoverOver,
} from '@/components'

export const MedicalConditionsRow = ({
  medicalCondition,
  delete: deleteDX,
  edit: editDX,
  name,
}: MedicalConditionsRowProps): JSX.Element => {
  const findActiveOrInactive = (): string => {
    if (
      medicalCondition.isActive !== undefined &&
      medicalCondition.isActive !== undefined
    ) {
      return medicalCondition.isActive.toString() === 'true'
        ? 'Active'
        : 'Historical'
    } else {
      return ''
    }
  }
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <MedicalConditionsHoverOver
          problem={name}
          activeOrInactive={findActiveOrInactive()}
        />
      </Popover>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={62}>
          <RowValue>{name}</RowValue>
        </AddColumn>
        <AddColumn width={30}>
          <RowValue>{findActiveOrInactive()}</RowValue>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => editDX(medicalCondition)} size="large">
            <ModeIcon />
          </IconButton>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => deleteDX(medicalCondition)} size="large">
            <DeleteIcon />
          </IconButton>
        </AddColumn>
      </AddRow>
    </>
  )
}
interface MedicalConditionsRowProps {
  name: string;
  medicalCondition: PatientMedicalCondition;
  delete: (medicalCondition: PatientMedicalCondition) => void;
  edit: (medicalCondition: PatientMedicalCondition) => void;
}
