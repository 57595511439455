import { type Patient } from '@/types'
import { Typography, styled } from '@mui/material'
import { AvatarImage } from '../AvatarImage'
import {
  formatAge,
  formatDateOnly,
  formatGender,
  getFullName,
} from '@/utility'
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled'
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined'
import React from 'react'

const PatientResultTileContainer = styled('div')({
  height: '40px',
  padding: '0px 16px',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  display: 'flex',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#EAF3FF',
  },
})

const PatientName = styled(Typography)({
  fontSize: '16px',
  flex: 1,
  fontWeight: 600,
})

const PatientGroup = styled(Typography)({
  color: '#686868',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

const PatientPrivateInfo = styled(Typography)({
  width: '110px',
  fontSize: '12px',
})

export const TaskPatientResultTile = ({
  patient,
  onClick,
}: TaskPatientResultTileProps): JSX.Element => {
  const formatPhoneNumber = (phoneNumberString: string): string => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match != null) {
      return [match[2], '-', match[3], '-', match[4]].join('')
    }
    return ''
  }

  const privateInfo = React.useMemo(() => {
    return [
      formatGender(patient.sex ?? ''),
      `(${formatAge(patient.dateOfBirth)})`,
      formatDateOnly(patient.dateOfBirth),
    ].join(' ')
  }, [patient])

  return (
    <PatientResultTileContainer onClick={onClick}>
      <AvatarImage
        src={patient.avatarUrl ?? '/user.png'}
        alt="Patient Image"
        width={32}
        height={32}
      />
      <PatientName>{getFullName(patient)}</PatientName>
      <PatientGroup>
        <PhoneEnabledIcon />
        {formatPhoneNumber(patient.phoneNumber ?? '')}
      </PatientGroup>
      <PatientGroup>
        <ShieldOutlinedIcon />
        <PatientPrivateInfo>{privateInfo}</PatientPrivateInfo>
      </PatientGroup>
    </PatientResultTileContainer>
  )
}

interface TaskPatientResultTileProps {
  patient: Patient;
  onClick: any;
}
