import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'

export const CancelWarningDialog = ({
  isOpen,
  handleClose,
}: CancelWarningDialogProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState(isOpen)
  React.useEffect(() => {
    setDialogOpen(isOpen)
  }, [isOpen])
  return (
    <Dialog open={dialogOpen}>
      <DialogTitle>
        Are you sure you want to cancel you have unsaved files?
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you cancel you will lose any unsaved files.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
        <Button variant="contained" onClick={handleClose}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}
interface CancelWarningDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}
