import { styled, Typography } from '@mui/material'
import React from 'react'

const NameBoxContainer = styled(Typography)({
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '24px',
  /* identical to box height */
  color: '#000000',
  maxWidth: '190px',
  maxHeight: '50px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const NameBox = ({ children }: NameBoxProps): JSX.Element => {
  return <NameBoxContainer>{children}</NameBoxContainer>
}
interface NameBoxProps {
  children: string;
}
