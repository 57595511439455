import React from 'react'
import {
  Box,
  Stack,
  ThemeProvider,
  type Theme,
  StyledEngineProvider,
} from '@mui/material'
import { useAuth } from '@clerk/nextjs'
import { format } from 'date-fns'
import MaterialReactTable from 'material-react-table'
import { StyledTableText } from '../styles'
import { type Procedure, type Patient, type ProcedureCPTCode } from '@/types'
import { isEmpty } from '@/utility/utils'
import { ProcedureStatusPopup } from '@/components/ProcedureStatusPopup'
import {
  useClinicStore,
  useQueryGetCPTModifiers,
  useQueryGetProcedureTypes,
  useQueryGetVisitsByPatient,
  useTableTheme,
} from '@/hook'
import { useQueryClient } from '@tanstack/react-query'
import {
  getExternalOrder,
  getExternalOrderTypes,
  getProcedure,
} from '@/services'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useLoadProcedures = (visits: any, getToken: any): any => {
  const [isOrdersLoading, setIsOrdersLoading] = React.useState<boolean>(false)
  const [procedures, setProcedures] = React.useState<ProcedureCPTCode[]>([])
  const [chiefProcedures, setChiefProcedures] = React.useState<Procedure[]>([])
  const queryClient = useQueryClient()

  React.useEffect(() => {
    if (visits?.length === 0) return
    loadProcedures().catch((err) => {
      throw err
    })
  }, [visits, getToken, queryClient])

  const loadProcedures = async (): Promise<void> => {
    setIsOrdersLoading(true)
    const filteredVisits = Array.isArray(visits)
      ? visits.filter((visit: { id: number }) => Boolean(visit?.id))
      : []
    const procedurePromise = Promise.all(
      filteredVisits.map(async ({ id }: { id: number }) => {
        const data = await queryClient.fetchQuery(
          ['procedure', id],
          async () => {
            return await getProcedure(id, getToken)
          },
          {
            staleTime: Infinity,
            cacheTime: Infinity,
          }
        )
        return data
      })
    )

    const res = await procedurePromise
    if (res !== undefined) {
      const codeLists = res
        .map((item) => (item as Procedure)?.visitClinicalProcedureList)
        .flat()
      setProcedures(codeLists as ProcedureCPTCode[])
      setChiefProcedures(res as Procedure[])
      setIsOrdersLoading(false)
    }
  }

  return { procedures, chiefProcedures, isOrdersLoading, loadProcedures }
}

export function PatientOrders({ patient }: { patient: Patient }): JSX.Element {
  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()
  const { data: procedureTypes } = useQueryGetProcedureTypes(
    clinicId,
    getToken
  )
  const { data: visits } = useQueryGetVisitsByPatient(patient?.id!, getToken)
  const { data: cptModifiers } = useQueryGetCPTModifiers(getToken)
  const { procedures, chiefProcedures, isOrdersLoading, loadProcedures } =
    useLoadProcedures(visits, getToken)
  const tableTheme = useTableTheme()

  const procedureColumns = React.useMemo(
    (): any[] => [
      {
        id: 'orders',
        header: 'Orders',
        accessorKey: 'name',
        Cell: ({ row }: any) => (
          <StyledTableText>{row?.original?.name}</StyledTableText>
        ),
      },
      {
        id: 'date',
        header: 'Date',
        Cell: ({ row }: any) => (
          <StyledTableText>
            {format(
              new Date(
                row?.original?.updatedAt === '0001-01-01T00:00:00+00:00'
                  ? row?.original?.readOnlyCreatedAt
                  : row?.original?.updatedAt
              ),
              'MM/dd/yyyy'
            )}
          </StyledTableText>
        ),
      },
      {
        id: 'capCode',
        header: 'CptCode',
        Cell: ({ row }: any) => (
          <StyledTableText>{row?.original?.cptCodeOther}</StyledTableText>
        ),
      },
      {
        id: 'modifier',
        header: 'Modifiers',
        Cell: ({ row }: any) => (
          <StyledTableText>
            {row?.original?.visitClinicalProcedureCptModifierCodeList
              ?.map((mods: any) => {
                return (
                  (cptModifiers ?? []).find(
                    (mod: any) => mod.id === mods.cptModifierCodeId
                  )?.code ?? ''
                )
              })
              .join(', ')}
          </StyledTableText>
        ),
      },
      {
        id: 'status',
        header: 'Status',
        Cell: ({ row }: any) => (
          <ProcedureStatusPopup
            patient={patient}
            procedure={row?.original}
            mode="order"
            isProcedure={row?.original?.isProcedure}
            isOrder={row?.original?.isOrder}
            clinicalProcedure={row?.original?.clinicalProcedure}
            chiefProcedures={chiefProcedures}
            onUpdate={onUpdateProcedure}
            providerId={
              visits?.find((visit) => visit.id === row.original.visitId)
                ?.providerAccountUserId ?? 0
            }
          />
        ),
      },
      {
        id: 'externalorders',
        header: 'External Orders',
        Cell: ({ row }: any) => {
          const [orderNames, setOrderNames] = React.useState<string[]>([])

          React.useEffect(() => {
            const visit = visits?.find(
              (visit) => visit.id === row.original.visitId
            )
            if (!visit?.id) return
            getExternalOrderTypes(getToken)
              .then((externalOrderTypes) => {
                getExternalOrder(visit.id ?? 0, getToken)
                  .then((res) => {
                    const externalOrderTypeIds = res.map(
                      (item: any) => item.externalOrderTypeId
                    )
                    const externalOrderNames = externalOrderTypes
                      .filter((item: any) =>
                        externalOrderTypeIds.includes(item.id)
                      )
                      .map((item: any) => item.name)
                    setOrderNames(externalOrderNames)
                  })
                  .catch((err) => {
                    throw err
                  })
              })
              .catch((err) => {
                throw err
              })
          }, [])
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {orderNames.map((name: string) => (
                <StyledTableText key={name}>{name}</StyledTableText>
              ))}
            </Box>
          )
        },
      },
    ],
    [chiefProcedures, patient]
  )
  const tableData = React.useMemo(() => {
    return procedures
      ?.map((item: { clinicalProcedureId: number }) => {
        const type: any = procedureTypes?.find(
          (type) => type.id === (item?.clinicalProcedureId ?? 0)
        )
        return {
          ...item,
          name: type?.name,
          clinicalProcedure: type,
          isProcedure:
            type?.hasProcedureStatus === true ||
            type?.needsProcedureTextResult === true ||
            type?.needsProcedurePdfResult === true,
          isOrder:
            type?.hasOrderStatus === true ||
            type?.needsOrderTextResult === true ||
            type?.needsOrderPdfResult === true,
        }
      })
      .filter((item: { name: string }) => !isEmpty(item?.name))
      .flat()
  }, [procedures, procedureTypes])

  const onUpdateProcedure = (): void => {
    loadProcedures().catch((error: any) => {
      throw error
    })
  }
  return (
    <Stack direction="column" style={{ width: '100%', height: '100%' }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={procedureColumns}
            data={tableData ?? []}
            enableColumnActions={false}
            enableTopToolbar={false}
            initialState={{ sorting: [{ id: 'orders', desc: false }] }}
            muiTableHeadProps={{
              sx: () => ({
                '& tr:nth-of-type(odd)': {
                  backgroundColor: 'rgb(246, 248, 253)',
                },
              }),
            }}
            muiTablePaginationProps={{
              rowsPerPageOptions: [5, 10, 20],
              showFirstButton: false,
              showLastButton: false,
            }}
            positionActionsColumn="last"
            state={{ isLoading: isOrdersLoading }}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </Stack>
  )
}
