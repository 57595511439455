import React from 'react'
import {
  type MedicationConditionType,
  type PatientImmunization,
  type PreventativeMedNotes,
  type SurgeryTypes,
  type PatientAllergy,
  type PatientMedicalCondition,
  type PatientMedication,
  type PatientSocialHistory,
  type PatientSurgery,
  type PatientFamilyHistory
} from '@/types'
import { Title } from '../components/Title'
import { TitleRow } from '../VitalsTile/styles'
import { TileLayout } from '../layout/TileLayout'
import { AllergyHistory } from './Allergies/AllergyHistory'
import { Medications } from './Medications/Medications'
import { Immunizations } from './Immunizations'
import { MedicalConditions } from './MedicalConditions'
import { SurgicalHistorys } from './SurgicalHistory/SurgicalHistory'
import { SocialHistory } from './SocialHistory/SocialHistory'
import { FamilyHistory } from './FamilyHistory/FamilyHistory'
import { PreventativeMedications } from './PreventativeMedications'

export const PastMedicalHistoryTile = ({
  medicalConditions,
  addMedicalCondition,
  deleteMedicalCondition,
  medicationConditionsNotes,
  setMedicationConditionsNotes,
  surgicalProcedures,
  addSurgicalProcedure,
  deleteSurgicalProcedure,
  surgicalProceduresNotes,
  setSurgicalProceduresNotes,
  medications,
  addMedication,
  deleteMedication,
  medicationsNotes,
  setMedicationsNotes,
  allergies,
  addAllergy,
  deleteAllergy,
  allergiesNotes,
  setAllergiesNotes,
  socialHistories,
  addSocialHistory,
  deleteSocialHistory,
  socialHistoriesNotes,
  setSocialHistoriesNotes,
  familyHistories,
  addFamilyHistory,
  deleteFamilyHistory,
  familyHistoriesNotes,
  setFamilyHistoriesNotes,
  patientId,
  ref,
  inFocus,
  setInfocus,
  isLocked = false,
  addImmunization,
  deleteImmunization,
  immunizations,
  immunizationNotes,
  setImmunizationNotes,
  allergen,
  setAllergen,
  medicationName,
  setMedicationName,
  immunizationName,
  setImmunizationName,
  surgery,
  setSurgery,
  problem,
  setProblem,
  socialHistoryType,
  setSocialHistoryType,
  familyHistoryMedicalConditionId,
  setFamilyHistoryMedicalConditionId,
  familyHistoryRelationshipId,
  setFamilyHistoryRelationshipId,
  isSocialHistoryVisible,
  setIsSocialHistoryVisible,
  isFamilyHistoryVisible,
  setIsFamilyHistoryVisible,
  surgeryTypes,
  medicalConditionsTypes,
  hasNKDA,
  setHasNKDA,
  hasChildhoodImmunizations,
  setHasChildhoodImmunizations,
  deniesMedication,
  setDeniesMedication,
  deniesImmunization,
  setDeniesImmunization,
  deniesSurgeries,
  setDeniesSurgeries,
  deniesMedicalConditions,
  setDeniesMedicalConditions,
  preventativeMedications,
  setPreventativeMedications,
}: PastMedicalHistoryProps): JSX.Element => {
  const [
    internalMedicationConditionsNotes,
    setInternalMedicationConditionsNotes,
  ] = React.useState<string>('')
  const [internalSurgicalProceduresNotes, setInternalSurgicalProceduresNotes] =
    React.useState<string>('')
  const [internalMedicationsNotes, setInternalMedicationsNotes] =
    React.useState<string>('')
  const [internalAllergiesNotes, setInternalAllergiesNotes] =
    React.useState<string>('')
  const [internalSocialHistoriesNotes, setInternalSocialHistoriesNotes] =
    React.useState<string>('')
  const [internalFamilyHistoriesNotes, setInternalFamilyHistoriesNotes] =
    React.useState<string>('')
  const [internalImmunizationsNotes, setInternalImmunizationsNotes] =
    React.useState<string>('')
  React.useEffect(() => {
    setInternalMedicationConditionsNotes(medicationConditionsNotes)
    setInternalSurgicalProceduresNotes(surgicalProceduresNotes)
    setInternalMedicationsNotes(medicationsNotes)
    setInternalAllergiesNotes(allergiesNotes)
    setInternalSocialHistoriesNotes(socialHistoriesNotes)
    setInternalFamilyHistoriesNotes(familyHistoriesNotes)
    setInternalImmunizationsNotes(immunizationNotes)
  }, [
    medicationConditionsNotes,
    surgicalProceduresNotes,
    medicationsNotes,
    allergiesNotes,
    socialHistoriesNotes,
    familyHistoriesNotes,
    immunizationNotes,
  ])

  const handleOnBlur = (): void => {
    setMedicationConditionsNotes(internalMedicationConditionsNotes)
    setSurgicalProceduresNotes(internalSurgicalProceduresNotes)
    setMedicationsNotes(internalMedicationsNotes)
    setAllergiesNotes(internalAllergiesNotes)
    setSocialHistoriesNotes(internalSocialHistoriesNotes)
    setFamilyHistoriesNotes(internalFamilyHistoriesNotes)
    setImmunizationNotes(internalImmunizationsNotes)
  }
  return (
    <TileLayout
      ref={ref}
      id={'pmh'}
      inFocus={inFocus}
      setInFocus={setInfocus}
      isLocked={isLocked}
      onBlur={handleOnBlur}
    >
      <TitleRow>
        {' '}
        <Title titleText="Past Medical History" />
      </TitleRow>
      <AllergyHistory
        patientId={patientId}
        allergies={allergies}
        addAllergy={addAllergy}
        deleteAllergy={deleteAllergy}
        notes={internalAllergiesNotes}
        setNotes={setInternalAllergiesNotes}
        allergen={allergen}
        setAllergen={setAllergen}
        hasNKDA={hasNKDA}
        setHasNKDA={setHasNKDA}
      />
      <Medications
        patientId={patientId}
        medications={medications}
        addMedication={addMedication}
        deleteMedication={deleteMedication}
        notes={internalMedicationsNotes}
        setNotes={setInternalMedicationsNotes}
        medicationName={medicationName}
        setMedicationName={setMedicationName}
        deniesMedication={deniesMedication}
        setDeniesMedication={setDeniesMedication}
      />
      <Immunizations
        patientId={patientId}
        immunizations={immunizations}
        addImmunization={addImmunization}
        deleteImmunization={deleteImmunization}
        notes={internalImmunizationsNotes}
        setNotes={setInternalImmunizationsNotes}
        immunizationName={immunizationName}
        setImmunizationName={setImmunizationName}
        hasChildhoodImmunizations={hasChildhoodImmunizations}
        setHasChildhoodImmunizations={setHasChildhoodImmunizations}
        deniesImmunizations={deniesImmunization}
        setDeniesImmunizations={setDeniesImmunization}
      />
      <SurgicalHistorys
        patientId={patientId}
        surgicalProcedures={surgicalProcedures}
        addSurgicalHistory={addSurgicalProcedure}
        deleteSurgicalHistory={deleteSurgicalProcedure}
        notes={internalSurgicalProceduresNotes}
        setNotes={setInternalSurgicalProceduresNotes}
        surgery={surgery}
        setSurgery={setSurgery}
        surgeryTypes={surgeryTypes}
        deniesSurgery={deniesSurgeries}
        setDeniesSurgery={setDeniesSurgeries}
      />
      <MedicalConditions
        medicalConditions={medicalConditions}
        addMedicalCondition={addMedicalCondition}
        deleteMedicalCondition={deleteMedicalCondition}
        patientId={patientId}
        notes={internalMedicationConditionsNotes}
        setNotes={setInternalMedicationConditionsNotes}
        problem={problem}
        setProblem={setProblem}
        medicalConditionsTypes={medicalConditionsTypes}
        deniesMedicationCondition={deniesMedicalConditions}
        setDeniesMedicationCondition={setDeniesMedicalConditions}
      />
      <PreventativeMedications
        preventativeMedications={preventativeMedications}
        setPreventativeMedications={setPreventativeMedications}
      />
      <SocialHistory
        patientId={patientId}
        socialHistories={socialHistories}
        addSocialHistory={addSocialHistory}
        deleteSocialHistory={deleteSocialHistory}
        notes={internalSocialHistoriesNotes}
        setNotes={setInternalSocialHistoriesNotes}
        setSocialHistoryType={setSocialHistoryType}
        socialHistoryType={socialHistoryType}
        isSocialHistoryVisible={isSocialHistoryVisible}
        setIsSocialHistoryVisible={setIsSocialHistoryVisible}
      />
      <FamilyHistory
        patientId={patientId}
        familyHistories={familyHistories}
        addFamilyHistory={addFamilyHistory}
        deleteFamilyHistory={deleteFamilyHistory}
        notes={internalFamilyHistoriesNotes}
        setNotes={setInternalFamilyHistoriesNotes}
        familyHistoryMedicalConditionId={familyHistoryMedicalConditionId}
        setFamilyHistoryMedicalConditionId={setFamilyHistoryMedicalConditionId}
        familyHistoryRelationshipId={familyHistoryRelationshipId}
        setFamilyHistoryRelationshipId={setFamilyHistoryRelationshipId}
        isFamilyHistoryVisible={isFamilyHistoryVisible}
        setIsFamilyHistoryVisible={setIsFamilyHistoryVisible}
        medicalConditionsTypes={medicalConditionsTypes}
      />
    </TileLayout>
  )
}

interface PastMedicalHistoryProps {
  medicalConditions: PatientMedicalCondition[];
  addMedicalCondition: (
    medicalCondition: PatientMedicalCondition
  ) => Promise<void>;
  deleteMedicalCondition: (
    medicalCondition: PatientMedicalCondition
  ) => Promise<void>;
  medicationConditionsNotes: string;
  setMedicationConditionsNotes: any;
  surgicalProcedures: PatientSurgery[];
  addSurgicalProcedure: (surgicalProcedure: PatientSurgery) => Promise<void>;
  deleteSurgicalProcedure: (surgicalProcedure: PatientSurgery) => Promise<void>;
  surgicalProceduresNotes: string;
  setSurgicalProceduresNotes: any;
  socialHistories: PatientSocialHistory[];
  addSocialHistory: (socialHistory: PatientSocialHistory) => Promise<void>;
  deleteSocialHistory: (socialHistory: PatientSocialHistory) => Promise<void>;
  socialHistoriesNotes: string;
  setSocialHistoriesNotes: any;
  familyHistories: PatientFamilyHistory[];
  addFamilyHistory: (familyHistory: PatientFamilyHistory) => Promise<void>;
  deleteFamilyHistory: (familyHistory: PatientFamilyHistory) => Promise<void>;
  familyHistoriesNotes: string;
  setFamilyHistoriesNotes: any;
  medications: PatientMedication[];
  addMedication: (medication: PatientMedication) => Promise<void>;
  deleteMedication: (medication: PatientMedication) => Promise<void>;
  medicationsNotes: string;
  setMedicationsNotes: any;
  allergies: PatientAllergy[];
  addAllergy: (allergy: PatientAllergy) => Promise<void>;
  deleteAllergy: (allergy: PatientAllergy) => Promise<void>;
  allergiesNotes: string;
  setAllergiesNotes: any;
  patientId: number;
  ref: React.RefObject<HTMLDivElement>;
  inFocus: boolean;
  setInfocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
  immunizations: PatientImmunization[];
  addImmunization: (immunization: PatientImmunization) => Promise<void>;
  deleteImmunization: (immunization: PatientImmunization) => Promise<void>;
  immunizationNotes: string;
  setImmunizationNotes: any;
  allergen: { id: number; label: string; code: string };
  setAllergen: any;
  medicationName: { id: number; label: string };
  setMedicationName: any;
  immunizationName: { id: number; label: string };
  setImmunizationName: any;
  surgery: { id: number; label: string };
  setSurgery: any;
  problem: { id: number; label: string };
  setProblem: any;
  socialHistoryType: SocialHistoryType;
  setSocialHistoryType: any;
  familyHistoryRelationshipId: number;
  setFamilyHistoryRelationshipId: any;
  familyHistoryMedicalConditionId: number;
  setFamilyHistoryMedicalConditionId: any;
  isSocialHistoryVisible: boolean;
  setIsSocialHistoryVisible: any;
  isFamilyHistoryVisible: boolean;
  setIsFamilyHistoryVisible: any;
  surgeryTypes: SurgeryTypes[];
  medicalConditionsTypes: MedicationConditionType[];
  hasNKDA: boolean;
  setHasNKDA: any;
  hasChildhoodImmunizations: boolean;
  setHasChildhoodImmunizations: any;
  deniesMedication: boolean;
  setDeniesMedication: any;
  deniesImmunization: boolean;
  setDeniesImmunization: any;
  deniesSurgeries: boolean;
  setDeniesSurgeries: any;
  deniesMedicalConditions: boolean;
  setDeniesMedicalConditions: any;
  preventativeMedications: PreventativeMedNotes;
  setPreventativeMedications: any;
}

interface SocialHistoryType {
  id: number;
  label: string;
}
