import React from 'react'
import { PopoverContainer } from '../layout/PopoverContainer'
import { PopoverTileText } from '../components/PopoverTileText'
import { PopoverValueText } from '../components/PopoverValueText'

export const HPIHoverOver = ({
  name,
  symptons,
  locations,
  modifyingFactors,
  onset,
  severity,
  context,
  notes,
}: HPIHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Present Illness</PopoverTileText>
        <PopoverValueText>{name}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Symptoms</PopoverTileText>
        <PopoverValueText>{symptons}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Modifying Factors</PopoverTileText>
        <PopoverValueText>{modifyingFactors}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Locations</PopoverTileText>
        <PopoverValueText>{locations}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Onset</PopoverTileText>
        <PopoverValueText>{onset}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Pain Scale</PopoverTileText>
        <PopoverValueText>
          {severity ? `${severity} of 10` : 'Not recorded'}
        </PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Context</PopoverTileText>
        <PopoverValueText>{context}</PopoverValueText>
      </div>
    </PopoverContainer>
  )
}

interface HPIHoverOverProps {
  name: string;
  symptons: string;
  locations: string;
  modifyingFactors: string;
  onset: string;
  severity: number | null | undefined;
  context: string;
  notes: string;
}
