import React from 'react'
import { type Vital } from '@/types'
import { isEmpty } from '@/utility/utils'
import { Box, Chip, Popover, Stack, styled, Typography } from '@mui/material'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import { format } from 'date-fns'

const VitalsCard = styled('div')({
  width: '270px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  backgroundColor: 'white',
  boxShadow: 'none',
  borderRadius: '5px',
  padding: '16px',
  yOverflow: 'scroll',
})

const VitalsChip = styled(Chip)(({ bgColor }: VitalsChipProp) => ({
  backgroundColor: bgColor,
  color: '#000',
  fontSize: '14px',
  lineHeight: 'normal',
  height: '26px',
}))

interface VitalsChipProp {
  bgColor: string;
}

const FieldRow = styled('div')({
  display: 'flex',
})
const FieldTitle = styled(Typography)({
  fontSize: '13px',
  lineHeight: '20px',
  color: '#ADB2C9',
})
const FieldTitleContainer = styled('div')({
  display: 'flex',
  width: '136px',
})
const FieldValueContainer = styled('div')({
  display: 'flex',
  width: '192px',
})
const FieldValue = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  color: 'rgba(0, 0, 0, 0.87)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const buildVitalsSummary = (vitals: Vital): any[] => {
  if (isEmpty(vitals)) return []

  const vitalsSummary: any[] = []
  if (!vitals.vitalDetails) {
    return vitalsSummary
  }
  if (vitals.weight !== 0) {
    vitalsSummary.push({
      Weight: `${
        vitals.isMetricWeight
          ? `${vitals.weight ?? 0} kg (${
              vitals.weight !== undefined && vitals.weight > 0
                ? Number(vitals.weight / 0.453592).toFixed(0)
                : 0
            } lb)`
          : `${vitals.weight ?? 0} lb (${
              vitals.weight !== undefined && vitals.weight > 0
                ? Number(vitals.weight * 0.453592).toFixed(2)
                : 0
            } kg)`
      }`,
    })
  }
  if (vitals.heightFeet !== 0 && vitals.heightInches !== 0) {
    vitalsSummary.push({
      Height: `${vitals.heightFeet ?? ''}' ${vitals.heightInches ?? ''}"`,
    })
  }
  if (vitals.bmi !== 0) {
    vitalsSummary.push({ BMI: `${Number(vitals.bmi).toFixed(2) ?? ''}` })
  }
  vitals?.vitalDetails?.forEach((vitalDetail, index) => {
    if (vitalDetail.bpDiastolic && vitalDetail.bpDiastolic !== 0 && vitalDetail.bpSystolic && vitalDetail.bpSystolic !== 0) {
      vitalsSummary.push({
        [`Blood Pressure (Set ${index + 1})`]: `${
          vitalDetail.bpSystolic ?? ''
        }/${vitalDetail.bpDiastolic ?? ''} mmHg`,
      })
    }
    if (vitalDetail.pulse && vitalDetail.pulse !== 0) {
      vitalsSummary.push({ [`Pulse (Set ${index + 1})`]: `${vitalDetail.pulse ?? ''} BPM` })
    }

    if (
      vitalDetail.respiratoryRate !== 0 &&
      !isEmpty(vitalDetail.respiratoryRate)
    ) {
      vitalsSummary.push({
        [`Resp rate (Set ${index + 1})`]: `${
          vitalDetail.respiratoryRate ?? ''
        } per min`,
      })
    }
    if (vitalDetail.temperature && vitalDetail.temperature !== 0) {
      vitalsSummary.push({ [`Temperature (Set ${index + 1})`]: `${vitalDetail.temperature ?? ''}°F` })
    }
    if (vitalDetail.oxygenSaturation && vitalDetail.oxygenSaturation !== 0) {
      vitalsSummary.push({ [`Oxygen (Set ${index + 1})`]: `${vitalDetail.oxygenSaturation ?? ''}%` })
    }
    if (vitalDetail.visualAcuityLeft && vitalDetail.visualAcuityLeft !== 0) {
      vitalsSummary.push({
        [`Visual Acuity Left (Set ${index + 1})`]: `20/${vitalDetail.visualAcuityLeft ?? ''}`
      })
    }
    if (vitalDetail.visualAcuityRight && vitalDetail.visualAcuityRight !== 0) {
      vitalsSummary.push({
        [`Visual Acuity Right (Set ${index + 1})`]: `20/${vitalDetail.visualAcuityRight ?? ''}`
      })
    }
    if (!isEmpty(vitalDetail.vitalNote)) {
      vitalsSummary.push({
        [`Note (Set ${index + 1})`]: `${vitalDetail.vitalNote ?? ''}`,
      })
    }
    if (!isEmpty(vitalDetail.vitalDateTime)) {
      vitalsSummary.push({
        [`Vitals Taken (Set ${index + 1})`]: format(
          new Date(vitalDetail.vitalDateTime ?? ''),
          'M-dd-yyyy h:mm a'
        ),
      })
    }
    vitalsSummary.push({
      'Patient Air Source': vitals.needsSupplementalOxygen ? 'Supplemental O2 tank' : 'Room Air'
    })
  })
  if (vitals.lastMenstrualPeriod) {
    vitalsSummary.push({ LMP: vitals.lastMenstrualPeriod })
  }
  return vitalsSummary
}

const buildRow = (
  name: string,
  value: string,
  outOfRange: any
): JSX.Element => {
  return (
    <FieldRow key={name}>
      <FieldTitleContainer>
        <FieldTitle sx={{ ...(outOfRange && { color: 'red' }) }}>
          {name}
        </FieldTitle>
      </FieldTitleContainer>
      <FieldValueContainer>
        <FieldValue sx={{ ...(outOfRange && { color: 'red' }) }}>
          {value}
        </FieldValue>
      </FieldValueContainer>
    </FieldRow>
  )
}

export const VitalsPopup = ({
  vitals,
  dateOfBirth,
}: VitalsCardProps): JSX.Element => {
  const vitalsSummary = buildVitalsSummary(vitals) ?? []

  const isVitalAbnormal = (key: string): boolean => {
    if (!vitals ?? !vitals.vitalDetails) return false

    const [vitalName, setNumber] = key.split(' (Set ')
    const index = setNumber ? parseInt(setNumber) - 1 : 0
    const vitalDetail = vitals.vitalDetails[index]

    if (!vitalDetail) return false

    switch (vitalName) {
      case 'BMI':
        return vitals.bmiOutOfRange ?? false
      case 'Weight':
        return vitals.weightOutOfRange ?? false
      case 'Blood Pressure':
        return vitalDetail.bloodPressureOutOfRange ?? false
      case 'Pulse':
        return vitalDetail.pulseOutOfRange ?? false
      case 'Resp rate':
        return vitalDetail.respiratoryRateOutOfRange ?? false
      case 'Temperature':
        return vitalDetail.temperatureOutOfRange ?? false
      case 'Oxygen':
        return vitalDetail.oxygenSaturationOutOfRange ?? false
      default:
        return false
    }
  }

  const hasAbnormalVitals = vitalsSummary.some((section) =>
    isVitalAbnormal(Object.keys(section)[0])
  )

  return (
    <PopupState variant="popover" popupId="vitals-popover">
      {(popupState) => (
        <div>
          <Stack
            direction={'row'}
            alignContent={'center'}
            alignItems={'center'}
            justifyContent={'flex-start'}
            {...bindTrigger(popupState)}
          >
            <VitalsChip
              data-testId={'vitals-div'}
              label={
                !vitals?.vitalDetails || vitalsSummary.length === 0
                  ? 'Not Taken'
                  : hasAbnormalVitals
                  ? 'Abnormal'
                  : 'Normal'
              }
              bgColor={
                !vitals?.vitalDetails || vitalsSummary.length === 0
                  ? '#CFD8FD'
                  : hasAbnormalVitals
                  ? '#FBE7E7'
                  : '#D8EBDB'
              }
            />
          </Stack>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <VitalsCard sx={{ width: '300px' }}>
              {vitalsSummary?.length > 0 ? (
                <Box data-testid={'vitals-popup-info'}>
                  {vitalsSummary?.map((section: any) => {
                    const key = Object.keys(section)[0]
                    const value = section[key]
                    const isAbnormal = isVitalAbnormal(key)
                    return (
                      <Box key={key}>{buildRow(key, value, isAbnormal)}</Box>
                    )
                  })}
                </Box>
              ) : (
                <FieldTitle>No Data</FieldTitle>
              )}
            </VitalsCard>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

interface VitalsCardProps {
  vitals: Vital;
  dateOfBirth: Date;
}
