import React from 'react'
import { Chip, IconButton, Modal, Dialog } from '@mui/material'
import { useAuth } from '@clerk/nextjs'
import {
  UserRoleNames,
  type UserRolePermissions,
  type AccountUser,
} from '@/types'
import {
  NameBox,
  DetailRow,
  DetailColumnLarge,
  DetailColumnSmall,
  ValueBox,
  ShowAllButton,
  DeleteVisitPopUp,
  IconBox,
  ManagementRow,
  AddUser,
} from '@/components'
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import StorefrontIcon from '@mui/icons-material/Storefront'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart'
import ComputerIcon from '@mui/icons-material/Computer'
import { useMutateDeleteAccountUser } from '@/hook'
import { AvatarImage } from '@/components/AvatarImage'

export const UserRow = ({
  accountUser,
  refresh,
  rolePermissions,
}: UserRowProps): JSX.Element => {
  const { getToken } = useAuth()

  const deleteAccountUserMutation = useMutateDeleteAccountUser(getToken)

  const [viewAll, setViewAll] = React.useState<boolean>(true)
  const [permissionTotal, setPermissionTotal] = React.useState<number>(0)

  const [openAddUser, setOpenAddUser] = React.useState(false)
  const handleOpenAddUser = (): void => setOpenAddUser(true)
  const handleCloseAddUser = (): void => setOpenAddUser(false)

  const [open, setOpen] = React.useState<boolean>(false)
  const [currentAccount, setCurrentAccount] = React.useState<number>()

  React.useEffect(() => {
    let newpermissionTotal = 0
    if (rolePermissions === undefined || rolePermissions === null) {
      newpermissionTotal = 0
    } else if (
      rolePermissions.permissionList === undefined ||
      rolePermissions.permissionList === null
    ) {
      newpermissionTotal = 0
    } else if (viewAll) {
      newpermissionTotal =
        rolePermissions.permissionList.length > 6
          ? 6
          : rolePermissions.permissionList.length
    } else {
      newpermissionTotal = rolePermissions.permissionList.length
    }
    setPermissionTotal(newpermissionTotal)
  }, [viewAll, rolePermissions?.permissionList])

  const handleDeleteAccount = (): void => {
    deleteAccountUserMutation
      .mutateAsync({ accountUserId: currentAccount! })
      .then(() => refresh())
      .catch((error) => {
        throw error
      })
  }
  const handleOpenPopup = (account: AccountUser): void => {
    setCurrentAccount(account?.id)
    setOpen(true)
  }
  const getIcon = (role: number): JSX.Element => {
    switch (role) {
      case 0:
        return <StorefrontIcon sx={{ color: '#717899', marginRight: '6px' }} />
      case 1:
        return (
          <MonitorHeartIcon sx={{ color: '#717899', marginRight: '6px' }} />
        )
      case 2:
        return (
          <LocalHospitalIcon sx={{ color: '#717899', marginRight: '6px' }} />
        )
      case 4:
        return (
          <AdminPanelSettingsIcon
            sx={{ color: '#717899', marginRight: '6px' }}
          />
        )
      case 5:
        return <ComputerIcon sx={{ color: '#717899', marginRight: '6px' }} />
      case 6:
        return (
          <ManageAccountsIcon sx={{ color: '#717899', marginRight: '6px' }} />
        )
      default:
        return (
          <ElectricalServicesIcon
            sx={{ color: '#717899', marginRight: '6px' }}
          />
        )
    }
  }
  const buildPermission = (): JSX.Element[] => {
    if (rolePermissions === undefined || rolePermissions === null) {
      return [<></>]
    }
    if (
      rolePermissions.permissionList === undefined ||
      rolePermissions.permissionList === null
    ) {
      return [<></>]
    }
    const permissionList = rolePermissions?.permissionList.slice(
      0,
      permissionTotal
    )
    return permissionList.map((rolePermission) => {
      if (rolePermission.id === 13) {
        return <></>
      }
      return (
        <Chip
          key={rolePermission.id}
          sx={{
            height: '22px',
            /* Violet Blue/Shade 6 */
            background: '#E5EBFF',
            borderRadius: '16px',
            fontSize: '12px',
            lineHeight: '16px',
            color: '#000000',
          }}
          label={rolePermission?.name?.replace(/([A-Z][a-z])/g, ' $1')}
        />
      )
    })
  }
  return (
    <ManagementRow>
      <Modal
        open={openAddUser}
        onClose={handleCloseAddUser}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AddUser
          handleClose={handleCloseAddUser}
          exisitingUser={accountUser}
          refresh={refresh}
        />
      </Modal>
      <AvatarImage
        width={50}
        height={50}
        src={accountUser.avatarUrl ?? '/user.png'}
        alt="user"
        marginRight="16px"
      />
      <DetailRow>
        <DetailColumnSmall>
          <NameBox>
            {`${accountUser.firstName!} ${accountUser.lastName!}`}
          </NameBox>
        </DetailColumnSmall>
        <DetailColumnSmall>
          {/* prettier-ignore */}
          {
            /* prettier-ignore */
            accountUser.role !== undefined
              /* prettier-ignore */
              ? accountUser.role !== null
                /* prettier-ignore */
                ? (getIcon(accountUser.role[0] as number))
                /* prettier-ignore */
                : (<></>)
              /* prettier-ignore */
              : (
                /* prettier-ignore */
                <></>
                /* prettier-ignore */
                )
          }
          <ValueBox>
            {`${
              accountUser.role !== undefined
                ? accountUser.role !== null
                  ? UserRoleNames[accountUser.role[0] as number]
                  : ''
                : ''
            }`}
          </ValueBox>
        </DetailColumnSmall>
        <DetailColumnLarge>
          {buildPermission()}
          <ShowAllButton
            onClick={() => setViewAll(!viewAll)}
            showAll={!viewAll}
          />
        </DetailColumnLarge>
      </DetailRow>
      <IconBox>
        <IconButton onClick={() => handleOpenPopup(accountUser)} size="large">
          <DeleteOutlinedIcon color="primary" fontSize="small" />
        </IconButton>
        <IconButton onClick={handleOpenAddUser} size="large">
          <EditOutlinedIcon color="primary" fontSize="small" />
        </IconButton>
      </IconBox>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: '12px' }}
      >
        <DeleteVisitPopUp
          handleDelete={handleDeleteAccount}
          setClose={() => setOpen(false)}
        />
      </Dialog>
    </ManagementRow>
  )
}

interface UserRowProps {
  accountUser: AccountUser;
  rolePermissions: UserRolePermissions | undefined;
  refresh: any;
}
