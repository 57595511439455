import React from 'react'
import { useAuth } from '@clerk/nextjs'
import {
  usePreventativeMedNotesStore,
  useQueryGetPatientPreventativeMedNotes
} from '@/hook'
import { PMHTile } from '../PatientHistoryTile'
import { type DateOnly, type PreventativeMedNotes } from '@/types'
import { formatDateOnly, isDateOnlyValid } from '@/utility'

export const PatientPreventativeMedicine = ({
  patient
}: {
  patient: any
}): JSX.Element => {
  const { getToken } = useAuth()

  const { preventativeMedNotes: preventativeMedications, setPreventativeMedNotes } =
    usePreventativeMedNotesStore()

  const { data: fetchedPreventativeMedNotes, isLoading: isPreventativeMedLoading } =
    useQueryGetPatientPreventativeMedNotes(patient.id, getToken)

  React.useEffect(() => {
    if (fetchedPreventativeMedNotes) {
      setPreventativeMedNotes(fetchedPreventativeMedNotes)
    }
  }, [fetchedPreventativeMedNotes, setPreventativeMedNotes])

  if (isPreventativeMedLoading) {
    return <div>Loading preventative medications...</div>
  }

  if (!preventativeMedications) {
    console.error('No preventative medications available')
    return <div>No preventative medications available.</div>
  }

  function transformPreventativeMedNotes(notes: PreventativeMedNotes) {
    const sortOrder: Record<string, number> = {
      'Last Pap Smear': 1,
      'Last Mammogram': 2,
      'Last Dental Exam': 3,
      'Last Dermatology Exam': 4,
      'Last Ophthalmology Exam': 5,
      'Last Colonoscopy': 6,
      'Last Bone Density Exam': 7,
      'Last Prostate Exam': 8,
      'Last Anxiety Screening': 9,
      'Last Depression Screening': 10,
      'Exercise Habits': 11
    }
  
    return Object.entries(notes)
      .filter(([key, value]) => key !== 'patientId' && (isDateOnlyValid(value) || (typeof value === 'string' && value.trim() !== '')))
      .map(([key, value]) => {
        const name = key
          .replace('prevMed', '')
          .replace(/([A-Z])/g, ' $1')
          .trim()
          .replace('Last Bone Density', 'Last Bone Density Exam')
        return {
          name,
          value: isDateOnlyValid(value)
            ? formatDateOnly(value as DateOnly)
            : value
        }
      })
      .sort((a, b) => {
        return (sortOrder[a.name] ?? 11) - (sortOrder[b.name] ?? 11)
      })
  }
  

  const columns = [
    { accessor: 'name', Header: 'Type' },
    { accessor: 'value', Header: 'Date' }
  ]

  return (
    <PMHTile
      title="Preventative Medicine"
      data={transformPreventativeMedNotes(preventativeMedications)}
      columns={columns}
      disableActions={true}
    />
  )
}