import { Chip, Modal, Dialog } from '@mui/material'
import React from 'react'
import { type Clinic } from '../../../../types/Clinic'
import { type Room } from '../../../../types/Room'
import { AddRoom } from '../../AddModals/AddRoom/AddRoom'
import { DeleteRoomPopup } from './DeleteRoomPopup'

export const RoomChip = ({
  room,
  clinics,
  clinicID,
  refreshRooms,
}: RoomChipProps): JSX.Element => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [currentRoom, setCurrentRoom] = React.useState<Room>()
  const [openEditRoom, setOpenEditRoom] = React.useState(false)
  const handleOpenEditRoom = (): void => setOpenEditRoom(true)
  const handleCloseEditRoom = (): void => setOpenEditRoom(false)
  const onDeleteRoom = React.useCallback((room: Room) => {
    setCurrentRoom(room)
    setOpen(true)
  }, [])
  const handleClose = (): void => {
    setOpen(false)
  }
  return (
    <>
      <Modal
        open={openEditRoom}
        onClose={handleCloseEditRoom}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AddRoom
          handleClose={handleCloseEditRoom}
          clinics={clinics}
          exisitingRoom={room}
          clinicID={clinicID}
          refresh={refreshRooms}
        />
      </Modal>
      <Chip
        sx={{
          height: '27px',

          /* Violet Blue/Shade 6 */
          background: '#E5EBFF',
          borderRadius: '16px',
          fontSize: '12px',
          lineHeight: '16px',

          /* identical to box height, or 133% */

          /* Black & White/Black */
          color: '#000000',
        }}
        label={room.name}
        onDelete={() => onDeleteRoom(room)}
        onClick={handleOpenEditRoom}
      />
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: '12px' }}
      >
        <DeleteRoomPopup
          room={currentRoom!}
          setClose={handleClose}
          refreshRooms={refreshRooms}
        />
      </Dialog>
    </>
  )
}
interface RoomChipProps {
  room: Room;
  clinics: Clinic[];
  clinicID: number;
  refreshRooms: any;
}
