import React from 'react'

import { Checkbox, styled } from '@mui/material'

import {
  TileRow,
  TileColumn,
  TileSubtitle,
  AddTable,
  NotesRow,
  SurgicalHistoryRow,
  TileDatePicker,
  TileSearchBox,
  HeaderPMH,
  Category,
} from '@/components'
import { type PatientSurgery } from '@/types'
import {
  dateOnlyToDate,
  isDateOnlyValid,
  isEmpty,
  parseDateToDateOnly,
} from '@/utility'
import { type SurgeryTypes } from '@/types/SurgeryTypes'
import { useGlobalStore } from '@/hook'

const CheckBoxRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const SurgicalHistorys = ({
  patientId,
  surgicalProcedures,
  addSurgicalHistory,
  deleteSurgicalHistory,
  notes,
  setNotes,
  surgery,
  setSurgery,
  surgeryTypes,
  deniesSurgery,
  setDeniesSurgery,
}: SurgicalHistorysProps): JSX.Element => {
  const [surgeryDate, setSurgeryDate] = React.useState<Date | null>(null)
  const [inputValue, setInputValue] = React.useState<string>('')
  const [lastKeyDown, setLastKeyDown] = React.useState<string>('')
  const { setIsEditing } = useGlobalStore()
  const [internalDeniesSurgery, setInternalDeniesSurgery] =
    React.useState<boolean>(deniesSurgery)

  React.useEffect(() => {
    setInputValue('')
    setSurgeryDate(null)
  }, [patientId])
  React.useEffect(() => {
    setInternalDeniesSurgery(deniesSurgery)
  }, [deniesSurgery])

  React.useEffect(() => {
    if (inputValue) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
  }, [inputValue])

  const handleCheckBoxChange = (event: any): void => {
    setInternalDeniesSurgery(event.target.checked)
    setDeniesSurgery(event.target.checked)
  }

  const handleSurgeryDateChange = (event: any): void => {
    setSurgeryDate(event)
  }
  // prettier-ignore
  const handleAddSurgery = (surgeryDefault: any = null): void => {
    const currentSurgery = surgeryDefault || surgery
    if (currentSurgery.id !== 0 || inputValue !== '') {
      // prettier-ignore
      addSurgicalHistory(
        {
          patientId,
          ...(currentSurgery.id > 0 ? { surgeryId: currentSurgery.id } : { procedure: inputValue }),
          // prettier-ignore
          ...((surgeryDate === null || surgeryDate === undefined)
            // prettier-ignore
            ? {}
            // prettier-ignore
            : {
              // prettier-ignore
                procedureDate: parseDateToDateOnly(surgeryDate)
              // prettier-ignore
              })
        }
      ).catch((error) => {
        throw error
      })
      setSurgery({ id: 0, label: '' })
      setSurgeryDate(null)
      setIsEditing(false)
    }
  }

  const handleDeleteSurgicalHistory = (
    surgicalProcedure: PatientSurgery
  ): void => {
    deleteSurgicalHistory(surgicalProcedure).catch((error) => {
      console.error(error)
    })
  }
  const findSurgeryName = (id: number): string => {
    const surgeryName = surgeryTypes.find(
      (surgeryName) => surgeryName.id === id
    )
    if (surgeryName !== undefined) {
      return surgeryName.name ?? ''
    } else {
      return ''
    }
  }
  const handleEditSurgicalHistory = (
    surgicalProcedure: PatientSurgery
  ): void => {
    setInputValue(findSurgeryName(surgicalProcedure.surgeryId ?? 0) ?? '')
    setSurgery({
      id: surgicalProcedure.surgeryId,
      label: findSurgeryName(surgicalProcedure.surgeryId ?? 0) ?? '',
    })
    if (isDateOnlyValid(surgicalProcedure.procedureDate)) {
      setSurgeryDate(dateOnlyToDate(surgicalProcedure.procedureDate)!)
    }

    handleDeleteSurgicalHistory(surgicalProcedure)
  }
  const buildConditions = (): JSX.Element => {
    return (
      <AddTable>
        {surgicalProcedures.map((condition) => {
          let name = ''
          if (condition.surgeryId === null) {
            name = condition.procedure ?? ''
          } else {
            name =
              surgeryTypes.find((st) => st.id === condition.surgeryId)?.name ??
              ''
          }
          return (
            <SurgicalHistoryRow
              key={condition.procedure}
              surgicaProcedure={condition}
              name={name ?? ''}
              delete={handleDeleteSurgicalHistory}
              edit={handleEditSurgicalHistory}
            />
          )
        })}
      </AddTable>
    )
  }
  const handleProcedureTypeChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    if (newValue === null) {
      setSurgery({ id: 0, label: '' })
    } else {
      const newSurgery = { id: newValue.id ?? 0, label: newValue.label ?? '' }
      setSurgery(newSurgery)
      // add surgery when surgery is picked by keydown
      if (lastKeyDown === 'ArrowDown') {
        handleAddSurgery(newSurgery)
      }
    }
  }
  const buildSurgicalHistories = (): JSX.Element => {
    return (
      <>
        <TileRow key={'surgeries'} id={'surgeries'}>
          <TileSearchBox
            label={'Surgical Procedure'}
            dataTestId={'surgicalProcedure'}
            value={surgery}
            inputValue={inputValue}
            setInputValue={setInputValue}
            twoColumns
            onChange={(event: any, value: any) =>
              handleProcedureTypeChange(event, value)
            }
            options={surgeryTypes.map((st) => {
              return {
                id: st.id ?? 0,
                label: st.name ?? '',
              }
            })}
            onKeyDown={(e) => setLastKeyDown(e.code)}
          />
          <TileDatePicker
            label={'Surgery Date'}
            monthYearFormat={true}
            onChange={handleSurgeryDateChange}
            value={surgeryDate}
            dataTestId={'surgicalDate'}
          />
        </TileRow>
      </>
    )
  }
  return (
    <Category>
      <TileRow>
        <TileColumn>
          <TileRow>
            <HeaderPMH>Surgical History</HeaderPMH>
            <CheckBoxRow>
              <TileSubtitle>Patient Denies</TileSubtitle>
              <Checkbox
                checked={internalDeniesSurgery}
                onChange={handleCheckBoxChange}
                data-testid={'deniesSurger-checkbox'}
                sx={{ padding: 0, pl: 1 }}
              />
            </CheckBoxRow>
          </TileRow>
          {buildSurgicalHistories()}
          <NotesRow
            notes={notes}
            setNotes={setNotes}
            title={'Add Surgery'}
            handleAdd={() => handleAddSurgery()}
            expanded={!isEmpty(notes)}
          />
        </TileColumn>
      </TileRow>
      {
        /* prettier-ignore */
        surgicalProcedures.length > 0
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              {/* prettier-ignore */}
              {buildConditions()}
              {/* prettier-ignore */}
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }
    </Category>
  )
}

interface SurgicalHistorysProps {
  patientId: number;
  surgicalProcedures: PatientSurgery[];
  addSurgicalHistory: (surgicalHistory: PatientSurgery) => Promise<void>;
  deleteSurgicalHistory: (surgicalHistory: PatientSurgery) => Promise<void>;
  notes: string;
  setNotes: (notes: string) => void;
  surgery: { id: number; label: string };
  setSurgery: any;
  surgeryTypes: SurgeryTypes[];
  deniesSurgery: boolean;
  setDeniesSurgery: any;
}
