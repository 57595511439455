import { InputLabel, styled, TextField } from '@mui/material'
import { TileSearchBox } from '../components'

export const HeightRow = styled('div')({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
})

export const StyleInputLabel = styled(InputLabel)({
  fontWeight: '300',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#222222',
})
export const StyledTextField = styled(TextField)({
  width: '240px',
  height: '40px !important',
  borderRadius: '6px',
  '& > div': {
    borderRadius: 0,
    height: '40px',
    backgroundColor: 'white',
  },
})
export const BPTextField = styled(TextField)({
  width: '108px !important',
  height: '40px !important',
  borderRadius: '6px',
  '& > input': {
    width: '108px !important',
  },
  '& > div': {
    borderRadius: 0,
    height: '40px',
    backgroundColor: 'white',
  },
})
export const FeetTextField = styled(TextField)({
  width: '120px !important',
  height: '40px',
  borderTopLeftRadius: '6px',
  borderBottomLeftRadius: '6px',
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px',
})
export const InchesTextField = styled(TextField)({
  width: '120px !important',
  height: '40px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  borderTopRightRadius: '6px',
  borderBottomRightRadius: '6px',
})
export const TitleRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})
export const VisualAcuityTextField = styled(TextField)({
  width: '80px !important',
  height: '40px',
  borderRadius: '6px'
})
export const TemperatureRow = styled('div')({
  display: 'flex',
  gap: '0px'
})
export const TemperatureTextField = styled(TextField)({
  width: '80px !important',
  height: '40px',
  borderTopLeftRadius: '6px',
  borderBottomLeftRadius: '6px',
  borderTopRightRadius: '0px',
  borderBottomRightRadius: '0px',
})
export const MethodSearchBox = styled(TileSearchBox)({
  width: '180px !important',
  height: '40px',
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  borderTopRightRadius: '6px',
  borderBottomRightRadius: '6px',
})
