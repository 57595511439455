import React from 'react'
import { IconButton, Modal, Dialog, styled, Typography } from '@mui/material'
import { useAuth } from '@clerk/nextjs'
import { type ExternalProvider } from '@/types'
import {
  DetailRow,
  DetailColumnSmall,
  IconBox,
  ManagementRow,
  AddExternalProvider,
} from '@/components'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { useMutateDeleteExternalProvider } from '@/hook'
import { DeleteProviderPopup } from './DeleteProviderPopup'

const Text = styled(Typography)({
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '24px',
  /* identical to box height */
  color: 'rgb(69, 69, 69)',
  maxWidth: '190px',
  maxHeight: '50px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const ExternalProviderRow = ({
  externalProvider,
  refresh,
}: ExternalProviderRowProps): JSX.Element => {
  const { getToken } = useAuth()

  const [openAddExternalProvider, setOpenAddExternalProvider] =
    React.useState(false)
  const handleOpenAddExternalProvider = (): void =>
    setOpenAddExternalProvider(true)
  const handleCloseAddExternalProvider = (): void =>
    setOpenAddExternalProvider(false)
  const deleteProviderMutation = useMutateDeleteExternalProvider(getToken)
  const [open, setOpen] = React.useState<boolean>(false)

  const handleDeleteProvider = (): void => {
    if (externalProvider.id) {
      deleteProviderMutation
        .mutateAsync({ ExternalProviderId: externalProvider.id })
        .then(() => refresh())
        .catch((error) => {
          throw error
        })
    }
  }
  return (
    <ManagementRow>
      <Modal
        open={openAddExternalProvider}
        onClose={handleCloseAddExternalProvider}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AddExternalProvider
          handleClose={handleCloseAddExternalProvider}
          existingProvider={externalProvider}
          refresh={refresh}
        />
      </Modal>
      <DetailRow>
        <DetailColumnSmall>
          <Text>
            {`${externalProvider.firstName!} ${externalProvider.lastName!}`}
          </Text>
        </DetailColumnSmall>
        <DetailColumnSmall>
          <Text>{externalProvider.practiceName!}</Text>
        </DetailColumnSmall>
        <DetailColumnSmall>
          <Text>{externalProvider.specialty!}</Text>
        </DetailColumnSmall>
        <DetailColumnSmall>
          <Text>{externalProvider.emailAddress!}</Text>
        </DetailColumnSmall>
        <DetailColumnSmall>
          <Text>{externalProvider.phoneNumber!}</Text>
        </DetailColumnSmall>
        <DetailColumnSmall>
          <Text>{externalProvider.faxNumber!}</Text>
        </DetailColumnSmall>
      </DetailRow>
      <IconBox>
        <IconButton onClick={() => setOpen(true)} size="large">
          <DeleteOutlinedIcon color="primary" fontSize="small" />
        </IconButton>
        <IconButton onClick={handleOpenAddExternalProvider} size="large">
          <EditOutlinedIcon color="primary" fontSize="small" />
        </IconButton>
      </IconBox>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: '12px' }}
      >
        <DeleteProviderPopup
          name={externalProvider.firstName}
          handleDelete={handleDeleteProvider}
          setClose={() => setOpen(false)}
        />
      </Dialog>
    </ManagementRow>
  )
}

interface ExternalProviderRowProps {
  externalProvider: ExternalProvider;
  refresh: any;
}
