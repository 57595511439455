import {
  useAccountUserStore,
  useMutateUpdateTask,
  useQueryGetRolePermissions,
  useTasksStore,
} from '@/hook'
import { type AccountUser, type Task } from '@/types'
import { getFullName, isEmpty } from '@/utility'
import { useAuth } from '@clerk/nextjs'
import { MenuItem, Select } from '@mui/material'
import { Stack } from '@mui/system'

import React from 'react'

interface AssigneeDataItemProps {
  taskId: number;
}

export const AssigneeDataItem = ({
  taskId,
}: AssigneeDataItemProps): JSX.Element => {
  const { getToken } = useAuth()
  const { accountUsers } = useAccountUserStore()
  const updateTaskMutation = useMutateUpdateTask(getToken)
  const  { taskPage } = useTasksStore()
  const [user, setUser] = React.useState<AccountUser | null>(null)
  const { data: rolePermissions } = useQueryGetRolePermissions(getToken)

  React.useEffect(() => {
    const task = taskPage?.items?.find(({ id }) => id === taskId)
    setUser(accountUsers?.find(({ id }) => id === task?.accountUserId) ?? null)
  }, [taskId, taskPage, accountUsers])

  const handleUpdateTask = async (
    taskId: number,
    modification: Partial<Task>
  ): Promise<void> => {
    await updateTaskMutation.mutateAsync({
      id: taskId,
      task: modification,
    })
  }

  const findRole = (role: any): string => {
    if (isEmpty(rolePermissions) || isEmpty(role)) return ''
    return (
      rolePermissions?.find((permission) => permission.id === role[0])?.name ??
      ''
    )
  }

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Select
        fullWidth
        style={{ background: 'white' }}
        value={user?.id ?? 0}
        onChange={(e) => {
          handleUpdateTask(taskId, { accountUserId: +e.target.value }).catch(
            (e) => {
              throw e
            }
          )
        }}
      >
        {accountUsers
          .filter((account) => findRole(account.role) !== 'UrgentIqEmployee')
          .sort((prev, next) => {
            const prevFullName = getFullName(prev) ?? ''
            const nextFullName = getFullName(next) ?? ''
            return prevFullName.localeCompare(nextFullName)
          })
          .map((option) => (
            <MenuItem
              data-testid={`race${option.id ?? 0}`}
              key={option.id}
              value={option.id}
            >{`${option.firstName ?? ''} ${option.lastName ?? ''}`}</MenuItem>
          ))}
      </Select>
    </Stack>
  )
}
