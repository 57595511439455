import React from 'react'
import {
  Stack,
  styled,
  TextField,
  Typography,
  Paper,
  Box,
} from '@mui/material'
import { TileInputLabel } from '../TileInputLabel'
import { useDebounce, useIQBar, useQueryGetDXCodes } from '@/hook'
import { useAuth } from '@clerk/nextjs'

const StyledTextField = styled(TextField)(
  ({ fullsize, twoColumns }: StyledTextFieldProps) => ({
    width: fullsize ? '766px' : twoColumns ? '506px' : '240px',
    '& > div': {
      borderRadius: 0,
      backgroundColor: 'white',
      '& > input': {
        height: '40px',
      },
    },
  })
)

export const ElasticSearchBox = ({
  id = 'tile-search-box',
  label = null,
  value = null,
  onChange,
  fullsize = false,
  twoColumns = false,
  dataTestId,
  fullWidthResults = false
}: TileSearchBoxProps): JSX.Element => {
  const { getToken } = useAuth()
  const [internalInputValue, setInternalInputValue] = React.useState<string>(
    value?.label ?? ''
  )
  const [options, setOptions] = React.useState<
    Array<{ id: number; label: string }>
  >([])

  const debouncedSearchTerm = useDebounce(internalInputValue, 500)
  const { refetch: search } = useIQBar(
    debouncedSearchTerm,
    getToken,
    'DiagnosisCodeId'
  )
  const { data: dxCodes } = useQueryGetDXCodes(getToken)
  const [selectionMade, setSelectionMade] = React.useState(false)

  React.useEffect(() => {
    if (value) {
      setInternalInputValue(value.label)
      setSelectionMade(true)
    }
  }, [value])

  React.useEffect(() => {
    if (!debouncedSearchTerm || selectionMade) {
      // Reset selectionMade state if input changes
      setOptions([])
      setSelectionMade(false)
      return
    }
    Promise.resolve(search())
      .then((response) => {
        const results = (response.data as any).iqBarDTOList || []
        const newOptions = results.map((result: any) => ({
          id: parseInt(result.fieldValueId ?? '') || 0,
          label: result.fieldValueString || '',
        }))

        setOptions(newOptions)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [debouncedSearchTerm])

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInternalInputValue(event.target.value)
  }

  const handleSelectOption = (id: number, label: string): void => {
    setInternalInputValue(label)
    onChange(id, label)
    setOptions([])
    setSelectionMade(true)
  }

  const handleBlur = (): void => {
    setTimeout(() => {
      setOptions([])
    }, 200)
  }

  const handleKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      setOptions([])
      event.preventDefault()
    }
  }

  return (
    <div>
      {label && <TileInputLabel>{label}</TileInputLabel>}
      <StyledTextField
        fullsize={fullsize}
        twoColumns={twoColumns}
        id={id}
        onChange={handleInputChange}
        value={internalInputValue}
        inputProps={{
          'data-testId': dataTestId,
        }}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
      {options.length > 0 && (
        <Paper
          style={{
            position: 'absolute',
            width: fullWidthResults ? '766px' : '240px',
            zIndex: 1,
            marginTop: '10px',
          }}
        >
          <Stack
            direction="column"
            spacing={1}
            sx={{ maxHeight: 450, overflowY: 'auto' }}
          >
            {options.map((option) => {
              const dx = dxCodes?.find((dx) => dx.id === option.id)
              return (
                <Box
                  key={option.id}
                  onClick={() => handleSelectOption(option.id, option.label)}
                  sx={{
                    padding: '10px 0 8px 12px',
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: '#f4f4f4' },
                  }}
                >
                  <Typography
                  variant="subtitle1"
                  sx={{ 
                    marginBottom: '0px',
                    lineHeight: 1.5,
                  }}
                  gutterBottom>
                    {`${option.label} - ${dx?.icd10CmCode!}` || option.label}
                  </Typography>
                </Box>
              )
            })}
          </Stack>
        </Paper>
      )}
    </div>
  )
}

interface TileSearchBoxProps {
  id?: string;
  label?: string | null;
  /* prettier-ignore */
  value?: { id: number, label: string } | null
  onChange: any;
  /* prettier-ignore */
  fullsize?: boolean
  twoColumns?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  dataTestId: string;
  fullWidthResults?: boolean;
  disabled?: boolean;
}

interface StyledTextFieldProps {
  fullsize: boolean;
  twoColumns: boolean;
}
