import React from 'react'
import { styled } from '@mui/material/styles'
import {
  TextareaAutosize,
  Paper,
  Button,
  Typography,
  Box,
} from '@mui/material'
import Checkbox, { type CheckboxProps } from '@mui/material/Checkbox'

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark'
        ? 'rgba(57,75,89,.5)'
        : 'rgba(206,217,224,.5)',
  },
}))

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#0543B0',
  backgroundImage:
    'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#0543B0',
  },
})

// Inspired by blueprintjs
export const BpCheckbox = (props: CheckboxProps): JSX.Element => {
  return (
    <Checkbox
      sx={{
        '&:hover': { bgcolor: 'transparent' },
        padding: '3px',
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ 'aria-label': 'Checkbox demo' }}
      {...props}
    />
  )
}

export const StyledButton = styled(Button)({
  height: '34px',
  maxWidth: '62px',
  minWidth: '54px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
  borderRadius: '20px',
})

export const StyledTextField = styled(TextareaAutosize)({
  fontFamily: 'Lato',
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '6px',
  borderWidth: 1,
  fontSize: '12px',
  borderColor: '#DDDDDD',
  color: '#0A1A32',
  padding: '6px',
  '& > focus:': {
    outline: 0,
  },
  '& > textarea': {
    borderColor: '#808080',
  },
})
export const VitalsCard = styled(Paper)({
  width: '270px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.12)',
  borderRadius: '8px',
  padding: '16px',
})
export const StyledLabel = styled(Typography)({
  fontSize: '12px',
  color: '#717899',
  marginRight: '5px',
  marginBottom: '5px',
})
export const OrdersActionButton = styled(Button)({
  fontSize: '12px',
  lineHeight: '14px',
  color: 'black',
  backgroundColor: '#CFD8FD',
  borderRadius: '25px',
  padding: '5px 10px',
  width: '24px',
  minWidth: '0px',
  textAlign: 'center',
})
export const StyledProcedureStatus = styled(Box)({
  fontSize: '12px',
  width: 'fit-content',
  maxWidth: '130px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  borderRadius: '14px',
  padding: '3px 5px',
  color: 'white',
  backgroundColor: '#D91212',
  cursor: 'pointer',
})
