import React from 'react'
import { AddButton } from '../components/AddButton/AddButton'
import { FreeTextNotes } from '../FreeTextNotes'
import { FreeTextNotesStandaloneButton } from '../FreeTextNotes/FreeTextNotesStandaloneButton'
import { TileRow } from '../layout/TileRow'

/* prettier-ignore */
export const NotesRow = ({ notes, setNotes, title, handleAdd, expanded = false }: NoteRowsProps): JSX.Element => {
  const [isNoteVisible, setIsNoteVisible] = React.useState<boolean>(expanded)
  React.useEffect(() => {
    setIsNoteVisible(expanded)
  }, [expanded])
  return (
    <>
      {
        isNoteVisible
          ? (
            <>
            <TileRow>
              <div style={{ width: '376px' }} />
              <AddButton
                dataTestId={`addPMH${title}`}
                onClick={handleAdd}
                title={title}
              />
            </TileRow>
            <TileRow>
                <FreeTextNotes
                  sectionName='PastMedicalHistoryConditionNotes'
                  notes={notes}
                  setNotes={setNotes}
                  expanded={isNoteVisible}
                  setExpanded={setIsNoteVisible} />
              </TileRow></>
            )
          : (
            <TileRow wide>
              <FreeTextNotesStandaloneButton
                isOpen={isNoteVisible}
                setIsOpen={setIsNoteVisible} />
              <div style={{ width: '264px' }} />
              <AddButton
                dataTestId={`addPMH${title}`}
                onClick={handleAdd}
                title={title}
              />
            </TileRow>
            )
      }
    </>
  )
}

interface NoteRowsProps {
  notes: string;
  setNotes: (notes: string) => void;
  title: string;
  handleAdd: () => void;
  expanded?: boolean;
}
