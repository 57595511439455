import { Button, styled } from '@mui/material'
import React from 'react'

const StyledButton = styled(Button)({
  fontSize: '12px',
  lineHeight: '16px',
  width: '75px',
  height: '22px',
  background: 'none',
  border: 'none',
  color: '#000000',
})

export const ShowAllButton = ({
  onClick,
  showAll,
}: ShowAllButtonProps): JSX.Element => {
  return (
    <StyledButton variant={'text'} onClick={onClick}>
      {showAll ? 'Show Less' : 'Show All'}
    </StyledButton>
  )
}

interface ShowAllButtonProps {
  onClick: () => void;
  showAll: boolean;
}
