import { styled } from '@mui/material'
import React from 'react'

const ChiefComplaintInnerRow = styled('div')({
  gap: '24px',
  marginTop: '12px',
  display: 'flex',
  width: '780px',
})

export const TileInnerRow = ({ children }: TileInnerRowProps): JSX.Element => {
  return <ChiefComplaintInnerRow>{children}</ChiefComplaintInnerRow>
}

interface TileInnerRowProps {
  children:
    | JSX.Element
    | JSX.Element[]
    | undefined
    | null
    | Array<JSX.Element | undefined | null>;
}
