import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { type QueryObserverResult, useQuery } from '@tanstack/react-query'
import { getDSToken } from '@/services'
import { type DoseSpotToken } from '@/types'

interface DoseSpotTokenState {
  token: DoseSpotToken | null;
  setToken: (token: DoseSpotToken) => void;
}

export const useDoseSpotTokenStore = create<DoseSpotTokenState>()(
  devtools(
    persist(
      (set) => ({
        token: null,
        setToken: (token: DoseSpotToken) => set({ token }),
      }),
      {
        name: 'dose-spot-token',
      }
    )
  )
)

export const useQueryGetDSToken = (
  clinicId: number,
  getToken: any
): QueryObserverResult<DoseSpotToken, unknown> => {
  const { setToken } = useDoseSpotTokenStore()

  return useQuery<DoseSpotToken>(
    ['dose-spot-token', clinicId],
    async () => await getDSToken(clinicId, getToken),
    {
      enabled: !!clinicId,
      onSuccess: (data) => {
        setToken(data)
      },
    }
  )
}
