import { styled } from '@mui/material'
import Tab from '@mui/material/Tab'

export const PrimaryStyledTab = styled(Tab)(({ theme }) => ({
  display: 'flex',
  textTransform: 'none',
  fontSize: '14px',
  lineHeight: '20px',
  marginRight: theme.spacing(1),
  color: 'black',
  borderRadius: '6px 6px 0 0',
  border: '1px solid #CFD8FD',
  borderTop: '2px solid rgb(13, 71, 173)',
  borderBottom: '0px',
  padding: '8px 12px 8px 12px',
  fontWeight: '600',
  width: 'fit-content',
  marginBottom: '20px',
  gap: '8px',
  cursor: 'pointer',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    backgroundColor: '#CFD8FD',
  },
}))
