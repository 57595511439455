import React from 'react'
import {
  Eventcalendar,
  setOptions,
  Popup,
  Radio,
  RadioGroup,
  updateRecurringEvent,
  type MbscCalendarEvent,
  type MbscEventcalendarView,
  type MbscRecurrenceRule,
  CalendarNav,
  CalendarPrev,
  CalendarToday,
  CalendarNext,
  type MbscResource,
} from '@mobiscroll/react'
import {
  type AccountUser,
  type Patient,
  type RecurringAppointment,
} from 'types'
import {
  addDays,
  addHours,
  endOfMonth,
  format,
  formatISO,
  isAfter,
  isSameDay,
  startOfDay,
  startOfMonth,
  subDays,
  subHours,
} from 'date-fns'
import { useAuth } from '@clerk/nextjs'
import {
  createRecurringAppointment,
  deleteAppointmentById,
  deleteAppointmentsByGroupId,
  getAllAppointments,
  getAppointmentsByGroupId,
  getPatient,
} from '@/services'
import { formatInUTC, stringToColor } from '@/utility'
import { useClinicStore, useQueryGetClinicDashboard } from '@/hook'
import { eventQueue } from '@/utility/EventQueue'
import { Modal, Snackbar } from '@mui/material'
import { ErrorMessage, Alert } from './AppointmentUtils'
import { getStatusString } from '../Columns/ColumnUtils'
import { QRModal } from '../PatientTile/QRModal'
import { AppointmentScheduler } from './AppointmentScheduler'
import { useScheduleState } from '@/context/SchedulingContext'
import { ViewType } from '@/pages/scheduling'

setOptions({
  theme: 'ios',
  themeVariant: 'light',
})

export const AppointmentsCalendar = ({
  providers,
  currentView,
}: AppointmentsCalendarProps): JSX.Element => {
  const {
    isOpen,
    setOpen,
    setEdit,
    setCurrentPatient,
    setAnchor,
    setOriginalRecurringEvent,
    setEventOccurrence,
    setTempEvent,
    setSelectedRepeat,
    loadPopupForm,
    popupEventNotes,
    popupEventVisitType,
    popupEventProvider,
    setOpenQRCode,
    popupEventDate,
    mySelectedDate,
    setSelectedDate,
    setToastMessage,
    setToastOpen,
    setRecurringText,
    setRecurringDelete,
    setRecurringEditOpen,
    tempEvent,
    resetCustomValues,
    deleteEvent,
    setRecurringEditMode,
    populateMonthDays,
    setMonthlyDay,
    setYearlyDay,
    popupEventOpenQRCode,
    popupEventId,
    isRecurringEditOpen,
    recurringText,
    recurringEditMode,
    toastOpen,
    toastMessage,
    recurringDelete,
    editFromPopup,
    deleteRecurringEvent,
    popupEventTitle,
    getCustomRule,
    eventOccurrence,
    originalRecurringEvent,
    generateRRuleString,
  } = useScheduleState()

  const { getToken } = useAuth()
  const { clinicId } = useClinicStore()

  const [myEvents, setMyEvents] = React.useState<MbscCalendarEvent[]>([])
  const [newEvent, setNewEvent] = React.useState<MbscCalendarEvent>()
  const [calView, setCalView] = React.useState<MbscEventcalendarView>({
    calendar: { labels: true },
  })
  const { data: visits, refetch: loadVisits } = useQueryGetClinicDashboard(
    clinicId,
    format(subDays(startOfMonth(mySelectedDate), 4), 'yyyy-MM-dd'),
    format(addDays(endOfMonth(mySelectedDate), 4), 'yyyy-MM-dd'),
    getToken
  )

  async function fetchAndSetEvents(): Promise<void> {
    try {
      const appointments = await getAllAppointments(
        getToken,
        format(subDays(startOfMonth(mySelectedDate), 4), 'yyyy-MM-dd'),
        format(addDays(endOfMonth(mySelectedDate), 4), 'yyyy-MM-dd'),
        clinicId
      )
      if (!appointments) return

      appointments.sort(
        (
          a: { appointmentStartDate: string | number | Date },
          b: { appointmentStartDate: string | number | Date }
        ) =>
          new Date(a.appointmentStartDate).getTime() -
          new Date(b.appointmentStartDate).getTime()
      )

      const eventsMap: Record<string, any> = {}
      const groupIdsToFetch = new Set<number>()
      const patientDetailsCache: Record<number, Patient> = {}

      for (const item of appointments) {
        if (
          item.patient &&
          item.patient !== null &&
          item.patient !== undefined &&
          item.patientId &&
          item.patientId !== null &&
          item.patientId !== undefined &&
          item.patientId > 0
        ) {
          if (!patientDetailsCache[item.patientId]) {
            try {
              patientDetailsCache[item.patientId] = item.patient
            } catch (error) {
              console.error(
                `Error fetching patient details for patientId ${
                  item.patientId as string
                }:`,
                error
              )
              continue
            }
          }

          const patient = patientDetailsCache[item.patientId]
          const firstName = item.firstName ?? patient.firstName
          const lastName = item.lastName ?? patient.lastName
          const color = stringToColor(
            `${firstName as string} ${lastName as string}`
          )

          if (
            !eventsMap[item.parentAppointmentGroupId] &&
            item.parentAppointmentGroupId
          ) {
            eventsMap[item.parentAppointmentGroupId] = {
              id: item.parentAppointmentGroupId,
              title: `${firstName as string} ${lastName as string}`,
              resource: item.providerId,
              start: new Date(item.appointmentStartDate),
              end: new Date(item.appointmentEndDate),
              allDay: false,
              notes: item.notes,
              status: item.status,
              statusString: getStatusString(item.status),
              patientId: item.patientId,
              visitTypeId: item.visitTypeId,
              providerId: item.providerId,
              recurring: parseRecurrenceRule(item.recurrenceRule),
              color,
              recurringException: [],
              hasQRCode: false,
            }
          }
          groupIdsToFetch.add(item.parentAppointmentGroupId)
        } else {
          console.error(
            `Invalid patientId found in appointment: ${
              item.patientId as number
            }`
          )
        }
      }

      visits?.patients?.forEach((patient) => {
        const visit = patient.visit
        if (visit) {
          const color = stringToColor(
            `${patient?.patient?.firstName!} ${patient?.patient?.lastName!}`
          )
          if (!eventsMap[visit?.id ?? 0] && visit.id) {
            let end
            if (visit?.scheduleEnd) {
              end = new Date(visit.scheduleEnd)
            } else if (visit?.arrivalDateTime) {
              const tempDate = new Date(visit.arrivalDateTime)
              tempDate.setMinutes(tempDate.getMinutes() + 15)
              end = tempDate
            } else {
              end = new Date() // default to current time if none provided
            }
            eventsMap[visit.id] = {
              id: visit.id,
              title: `${patient?.patient?.firstName!} ${patient?.patient
                ?.lastName!}`,
              resource: visit?.providerAccountUserId,
              start: new Date(
                visit?.scheduleStart ?? visit?.arrivalDateTime ?? ''
              ),
              end,
              allDay: false,
              notes: visit.addendum ?? '',
              status: visit.visitStatusId,
              statusString: getStatusString(visit.visitStatusId ?? 0),
              patientId: visit.patientId,
              visitTypeId: visit.chiefComplaint?.visitTypeId,
              providerId: visit.providerAccountUserId,
              recurring: null,
              color,
              recurringException: [],
              hasQRCode: true,
            }
          }
        }
      })

      const fetchPromises = Array.from(groupIdsToFetch).map(async (groupId) => {
        const appts = await getAppointmentsByGroupId(groupId, getToken)
        return {
          groupId,
          appts,
        }
      })

      const results = await Promise.all(fetchPromises)
      results?.forEach(({ groupId, appts }) => {
        const exceptions = appts
          .filter((item: { isExcluded: any }) => item?.isExcluded)
          .map((item: { appointmentStartDate: string | number | Date }) =>
            startOfDay(new Date(item.appointmentStartDate))
          )
        eventsMap[groupId].recurringException = exceptions
        if (appts.length === exceptions?.length) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete eventsMap[groupId]
        }
      })

      setMyEvents(Object.values(eventsMap))
    } catch (err) {
      console.error(err)
    }
  }

  React.useEffect(() => {
    Promise.resolve(loadVisits())
      .catch((error) => {
        throw error
      })
      .finally(() => {
        fetchAndSetEvents().catch((err) => {
          throw err
        })
      })
  }, [new Date(mySelectedDate).getMonth(), isOpen, clinicId, currentView])

  const parseRecurrenceRule = (recurrenceStr: string): RecurringRule | null => {
    if (!recurrenceStr) return null
    const parsedRule: RecurringRule = {
      repeat: '',
    }

    const properties = recurrenceStr.split(';')

    for (const prop of properties) {
      const [key, value] = prop.split('=')

      switch (key) {
        case 'FREQ':
          parsedRule.repeat = value.toLowerCase()
          break
        case 'BYMONTHDAY':
          parsedRule.day = parseInt(value)
          break
        case 'BYDAY':
          parsedRule.weekDays = value
          break
        case 'BYSETPOS':
          parsedRule.pos = parseInt(value)
          break
        case 'BYMONTH':
          parsedRule.month = parseInt(value)
          break
        case 'INTERVAL':
          parsedRule.interval = parseInt(value)
          break
        case 'UNTIL':
          parsedRule.until = new Date(value)
          break
        case 'COUNT':
          parsedRule.count = parseInt(value)
          break
        default:
          break
      }
    }

    return parsedRule
  }

  const onSelectedDateChange = React.useCallback((event: any) => {
    setSelectedDate(event.date)
  }, [])

  const onEventClick = React.useCallback(
    async (args: any): Promise<void> => {
      let event = args.event

      setEdit(true)

      if (event?.patientId) {
        const patient = await getPatient(event.patientId, getToken)
        setCurrentPatient(patient)
        event = {
          ...args.event,
          firstName: patient?.firstName,
          lastName: patient?.lastName,
          dateOfBirth: patient?.dateOfBirth,
        }
        setTempEvent(event)

        if (event.recurring) {
          setOriginalRecurringEvent(event.original)
          setEventOccurrence({ ...event })
          loadPopupForm(event)
        } else {
          setOriginalRecurringEvent({})
          loadPopupForm(event)
        }
        setAnchor(args.domEvent.target)
        setSelectedRepeat(
          event.recurring
            ? `${event.recurring.repeat as string}${
                event.recurring?.pos ? '-pos' : ''
              }`
            : 'norepeat'
        )
        setOpen(true)
      } else {
        setToastMessage(ErrorMessage.PatientNotExisting)
        setToastOpen(true)
      }
    },
    [loadPopupForm]
  )

  const processAppointment = async (event: any): Promise<void> => {
    try {
      const appointment: RecurringAppointment = {
        appointmentStartDate: formatInUTC(
          formatISO(addHours(new Date(event.start), 2))
        ),
        appointmentEndDate: formatInUTC(
          formatISO(subHours(new Date(event.end), 9))
        ),
        patientId: event.id,
        notes: event?.notes ?? '',
        visitTypeId: event?.visitTypeId,
        providerId: event?.providerId,
        status: event?.status,
        statusString: getStatusString(event?.status),
        parentAppointmentGroupId: event?.parentGroupId,
        recurrenceRule: getCustomRule(),
        clinicId,
      }

      await deleteAppointmentById(event.id, getToken)
      const appt = await createRecurringAppointment(appointment, getToken)
      const newEv = {
        ...event,
        id: appt[0].id,
      }

      const newEventList = [...myEvents]
      const index = newEventList?.findIndex((x) => x.id === event.id)
      if (index !== -1) {
        newEventList.splice(index, 1, newEv)
        setMyEvents(newEventList)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const onEventUpdate = React.useCallback(
    (args: any) => {
      const event = args.event
      if (event.recurring) {
        setOriginalRecurringEvent(args.oldEvent)
        setTempEvent(event)
        setEventOccurrence(args.oldEventOccurrence)
        if (args.domEvent.keyCode === 46) {
          setRecurringText('Delete')
          setRecurringDelete(true)
          setRecurringEditOpen(true)
        } else {
          setRecurringText('Edit')
          setRecurringDelete(false)
          setRecurringEditOpen(true)
        }
        return false
      } else {
        eventQueue.enqueue(async () => await processAppointment(event))
      }
    },
    [
      tempEvent,
      setOriginalRecurringEvent,
      setTempEvent,
      setEventOccurrence,
      setRecurringText,
      setRecurringDelete,
      setRecurringEditOpen,
      eventQueue,
      processAppointment,
    ]
  )

  const onEventCreate = React.useCallback((args: any) => {
    const originEvent = args.originEvent
    if (originEvent?.recurring) {
      setNewEvent(args.event)
      return false
    }
  }, [])

  const onEventCreated = React.useCallback(
    (args: any) => {
      setEdit(false)
      resetCustomValues()
      setTempEvent(args.event)
      setCurrentPatient({})
      loadPopupForm(args.event)
      setAnchor(args.target)
      setOpen(true)
    },
    [loadPopupForm, resetCustomValues]
  )

  const onEventDeleted = React.useCallback(
    (args: any) => {
      try {
        deleteEvent(args.event).catch((err) => {
          throw err
        })
      } catch (err) {
        console.error('Error when deleting event:', err)
      }
    },
    [deleteEvent]
  )

  const onEventUpdated = React.useCallback((args: any) => {}, [])

  const recurringEditButtons = React.useMemo<any>(() => {
    if (!tempEvent?.patientId) {
      return []
    }
    return [
      'cancel',
      {
        handler: async () => {
          if (recurringDelete) {
            deleteRecurringEvent()
          } else {
            if (editFromPopup) {
              tempEvent.title = popupEventTitle
              tempEvent.visitType = popupEventVisitType
              tempEvent.provider = popupEventProvider
              tempEvent.description = popupEventNotes
              tempEvent.start = popupEventDate[0]
              tempEvent.end = popupEventDate[1]
              tempEvent.allDay = false
              tempEvent.recurring = getCustomRule()
            }

            if (recurringEditMode === 'current') {
              delete tempEvent.id
              delete tempEvent.recurring
              delete tempEvent.recurringException
            }

            const events = updateRecurringEvent(
              originalRecurringEvent,
              eventOccurrence,
              editFromPopup ? null : newEvent!,
              editFromPopup ? tempEvent : null,
              recurringEditMode
            )

            // update event
            let newEventList = [...myEvents]
            const index = newEventList.findIndex(
              (x) => x.id === events.updatedEvent.id
            )
            newEventList[index] = events.updatedEvent

            // add new event

            const { newEvent: nwEvent, updatedEvent } = events

            // Retrieve patient
            const patient = await getPatient(
              updatedEvent?.patientId as number,
              getToken
            )
            if (!patient) {
              throw new Error('No patient found.')
            }

            // Construct the appointment object
            const appointment: RecurringAppointment = {
              appointmentStartDate: nwEvent?.start
                ? formatInUTC(formatISO(addHours(nwEvent?.start as Date, 2)))
                : formatInUTC(
                    formatISO(addHours(updatedEvent?.start as Date, 2))
                  ),
              appointmentEndDate: nwEvent?.end
                ? formatInUTC(formatISO(subHours(nwEvent?.end as Date, 9)))
                : formatInUTC(
                    formatISO(subHours(updatedEvent?.end as Date, 9))
                  ),
              patientId: patient.id,
              visitTypeId: nwEvent?.visitType as number,
              providerId: nwEvent?.provider as number,
              notes: nwEvent?.notes ?? '',
              status: nwEvent?.status as 0 | 1 | 2 | 3 | 4,
              statusString: getStatusString(nwEvent?.status as number),
              clinicId,
              recurrenceRule: nwEvent?.recurring
                ? generateRRuleString(nwEvent?.recurring)
                : !nwEvent
                ? generateRRuleString(updatedEvent?.recurring)
                : null,
            }

            const createdAppt = await createRecurringAppointment(
              appointment,
              getToken
            )
            setTempEvent({ ...nwEvent, id: createdAppt[0]?.id })

            // Retrieve and filter appointments
            const appointments = await getAppointmentsByGroupId(
              typeof updatedEvent?.id === 'number'
                ? updatedEvent?.id
                : createdAppt[0]?.id,
              getToken
            )
            const isSameDayException = (
              date1: Date,
              exceptions: any[] = []
            ): boolean => {
              return exceptions.some((eItem) =>
                isSameDay(date1, new Date(eItem))
              )
            }

            let filteredAppointments = []

            if (recurringEditMode === 'following') {
              filteredAppointments =
                appointments?.filter((item: any): boolean => {
                  const appointmentStartDate = new Date(
                    item.appointmentStartDate
                  )
                  return (
                    isSameDayException(
                      appointmentStartDate,
                      nwEvent?.recurringException as any[]
                    ) ||
                    isSameDayException(
                      appointmentStartDate,
                      updatedEvent?.recurringException as any[]
                    ) ||
                    isAfter(
                      appointmentStartDate,
                      new Date(
                        (updatedEvent?.recurring as MbscRecurrenceRule)
                          ?.until as string
                      )
                    )
                  )
                }) ?? []
              const deletePromises = filteredAppointments?.map(
                async (item: any): Promise<void> =>
                  await deleteAppointmentById(item.id, getToken)
              )
              await Promise.all(deletePromises)
            } else if (recurringEditMode === 'current') {
              const difference = (
                updatedEvent?.recurringException as any[]
              )?.filter(
                (item) =>
                  !(nwEvent?.recurringException as any[])?.includes(item)
              )

              filteredAppointments = appointments?.filter(
                (item: any): boolean => {
                  const appointmentStartDate = new Date(
                    item.appointmentStartDate
                  )
                  return isSameDayException(appointmentStartDate, difference)
                }
              )

              const deletePromises = filteredAppointments?.map(
                async (item: any): Promise<void> =>
                  await deleteAppointmentById(item.id, getToken)
              )
              await Promise.all(deletePromises)
            } else {
              await deleteAppointmentsByGroupId(
                (updatedEvent?.id as number) ?? 0,
                getToken
              )
            }

            if (events.newEvent) {
              newEventList = [
                ...newEventList,
                { ...nwEvent, id: createdAppt[0]?.id },
              ]
            }

            setMyEvents(newEventList)

            setOpen(false)
            setRecurringEditOpen(false)
          }
        },
        keyCode: 'enter',
        text: 'Ok',
        cssClass: 'mbsc-popup-button-primary',
      },
    ]
  }, [
    deleteRecurringEvent,
    editFromPopup,
    eventOccurrence,
    getCustomRule,
    myEvents,
    newEvent,
    originalRecurringEvent,
    popupEventDate,
    popupEventNotes,
    popupEventTitle,
    popupEventVisitType,
    popupEventProvider,
    recurringDelete,
    recurringEditMode,
    tempEvent,
  ])

  const recurringEditModeChange = React.useCallback((ev: any) => {
    setRecurringEditMode(ev.target.value)
  }, [])

  const onRecurringEditClose = React.useCallback(() => {
    setRecurringEditMode('current')
    setRecurringEditOpen(false)
  }, [])

  React.useEffect(() => {
    populateMonthDays(1, 'monthly')
    setMonthlyDay(1)
    populateMonthDays(1, 'yearly')
    setYearlyDay(1)
  }, [populateMonthDays])

  React.useEffect(() => {
    let myView: MbscEventcalendarView = {
      calendar: { labels: true },
    }
    switch (currentView) {
      case ViewType.MONTHLY_VIEW:
        myView = {
          calendar: { labels: true },
        }
        break
      case ViewType.WEEKLY_VIEW:
        myView = {
          schedule: { type: 'week', allDay: false },
        }
        break
      default:
        myView = {
          calendar: { labels: true },
        }
    }
    setCalView(myView)
  }, [currentView])

  const customWithNavButtons = (): JSX.Element => {
    return (
      <React.Fragment>
        <CalendarNav className="cal-header-nav" />
        <CalendarPrev className="cal-header-prev" />
        <CalendarToday className="cal-header-today" />
        <CalendarNext className="cal-header-next" />
      </React.Fragment>
    )
  }

  const mapProvider = (provider: AccountUser): MbscResource => {
    return {
      id: `${provider.id!}`,
      name: `${provider.firstName!} ${provider.lastName!}`,
    }
  }

  const mapProviders = (): MbscResource[] => {
    return providers?.map(mapProvider)
  }

  return (
    <>
      <Modal
        open={popupEventOpenQRCode}
        onClose={() => setOpenQRCode(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <QRModal vId={popupEventId ?? 0} />
      </Modal>
      <div data-testid="event-calendar">
        <Eventcalendar
          renderHeader={customWithNavButtons}
          view={calView}
          data={myEvents}
          theme=""
          clickToCreate="single"
          dragToCreate={true}
          dragToMove={true}
          dragToResize={false}
          selectedDate={mySelectedDate}
          onSelectedDateChange={onSelectedDateChange}
          onEventClick={(args: any): any => onEventClick(args)}
          onEventUpdate={onEventUpdate}
          onEventCreate={onEventCreate}
          onEventCreated={onEventCreated}
          onEventDeleted={onEventDeleted}
          onEventUpdated={onEventUpdated}
          showToday={true}
          todayText="Today"
          height={'80vh'}
          data-testid="event-calendar"
          resources={mapProviders()}
        />
        <AppointmentScheduler />
        <Popup
          display="anchored"
          contentPadding={false}
          buttons={recurringEditButtons}
          isOpen={isRecurringEditOpen}
          onClose={onRecurringEditClose}
        >
          <div className="mbsc-form-group">
            <div className="mbsc-form-group-title">
              {recurringText} recurring event
            </div>
            <RadioGroup onChange={recurringEditModeChange}>
              <Radio
                label="This event only"
                checked={recurringEditMode === 'current'}
                onChange={recurringEditModeChange}
                value="current"
              />
              <Radio
                label="This and following events"
                checked={recurringEditMode === 'following'}
                onChange={recurringEditModeChange}
                value="following"
              />
              <Radio
                label="All events"
                checked={recurringEditMode === 'all'}
                onChange={recurringEditModeChange}
                value="all"
              />
            </RadioGroup>
          </div>
        </Popup>
        <Snackbar
          open={toastOpen}
          autoHideDuration={6000}
          onClose={() => setToastOpen(false)}
        >
          <Alert
            onClose={() => setToastOpen(false)}
            severity="error"
            sx={{ width: '100%' }}
          >
            {toastMessage}
          </Alert>
        </Snackbar>
      </div>
    </>
  )
}

interface RecurringRule {
  repeat: string;
  weekDays?: string;
  day?: number;
  pos?: number;
  month?: number;
  interval?: number;
  until?: Date;
  count?: number;
}

interface AppointmentsCalendarProps {
  providers: AccountUser[];
  currentView: ViewType;
}
