import { Chip, IconButton, styled, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { TextBlock } from './TextBlock'

const HeaderTile = styled(Typography)({
  size: '14px',
  weight: '700',
  color: 'black',
})

const HeaderRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
})

const TileSectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '16px',
  borderBottom: '1px solid #EDEDED',
})

const TileSectionContent = styled('div')(
  ({ isOpen, isRow }: TileSectionContentProps) => ({
    display: isOpen ? 'flex' : 'none',
    flexDirection: isRow ? 'row' : 'column',
    gap: '8px',
    flexWrap: 'wrap',
  })
)

const StyledChip = styled(Chip)({
  backgroundColor: '#E5EBFF',
  height: '25px',
  size: '14px',
  weight: '400',
})
export const TileSection = ({
  sectionName,
  values,
  handleAdd,
  textBlocks = false,
  name,
}: TileSectionProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState<boolean>(true)
  return (
    <TileSectionContainer>
      <HeaderRow>
        <HeaderTile>{sectionName}</HeaderTile>
        {isOpen ? (
          <IconButton onClick={() => setIsOpen(false)} size="large">
            <KeyboardArrowDownIcon fontSize="small" sx={{ color: '#ADB2C9' }} />
          </IconButton>
        ) : (
          <IconButton onClick={() => setIsOpen(true)} size="large">
            <KeyboardArrowRightIcon
              fontSize="small"
              sx={{ color: '#ADB2C9' }}
            />
          </IconButton>
        )}
      </HeaderRow>
      <TileSectionContent isOpen={isOpen} isRow={!textBlocks}>
        {values.map((value) => {
          if (textBlocks) {
            return (
              <TextBlock
                text={value.name}
                handleAdd={() => handleAdd(value.id, name)}
                key={value}
              />
            )
          } else {
            return (
              <StyledChip
                label={value.name}
                onClick={() => handleAdd(value.id, name)}
                key={value}
              />
            )
          }
        })}
      </TileSectionContent>
    </TileSectionContainer>
  )
}
interface TileSectionProps {
  sectionName: string;
  values: any[];
  handleAdd: any;
  textBlocks?: boolean;
  name: string;
}

interface TileSectionContentProps {
  isOpen: boolean;
  isRow: boolean;
}
