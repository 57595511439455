import React from 'react'
import { type AssessmentPlan } from '../../../../types/AssessmentPlan'
import { type CodingModifier } from '../../../../types/CodingModifier'
import { type DXModifiers } from '../../../../types/DXModifier'
import { TileLayout } from '../layout/TileLayout/TileLayout'
import { TileRow } from '../layout/TileRow/TileRow'
import { Title } from '../components/Title/Title'
import { TileSelect } from '../components/TileSelect'
import { type AssessmentDX } from '../../../../types/AssessmentDX'
import { AddButton } from '../components/AddButton/AddButton'
import { TileTextArea } from '../components/TileTextArea'
import { CircularProgress, styled } from '@mui/material'
import { TileSearchBox } from '../components/TileSearchBox'
import { AddTable } from '../layout/AddTable'
import { DXRow } from './DXRow'
import { type VisitOrder } from '../../../../types/VisitOrder'
import { PrescriptionRow } from './PrescriptionRow'
import { TileInputLabel } from '../components/TileInputLabel'
import { isEmpty } from '@/utility/utils'
import { type PatientPrescription } from '@/types/PatientPrescription'
import {
  useQueryGetDXCodes,
  useQueryGetInsurancePayers,
  useGlobalStore,
} from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { type ExternalOrder } from '@/types/ExternalOrder'
import { ExternalOrderRow } from './ExternalOrderRow'
import { type Patient } from '@/types'
import { Shimmer } from '../Shimmer'
import { ElasticSearchBox } from '../components/ElasticSearchBox/ElasticSearchBox'
import { getExternalOrderTypes } from '@/services'
import { useToastStore } from '@/hook/useToast'

const ButtonRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '768px',
})
const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '768px',
  height: '174px',
  borderRadius: '6px',
  borderColor: '#DDDFE8',
  borderStyle: 'solid',
  borderWidth: '1px',
  '&:hover': {
    borderColor: 'black',
  },
})
export const AssessmentPlanTile = ({
  visitId,
  assessmentPlan,
  setAssessmentPlan,
  dxModifiers,
  codingModifiers,
  documents,
  ref,
  inFocus,
  setInFocus,
  isLocked = false,
  listChange,
  setListChange,
  template = false,
  isDirty = false,
  setIsDirty = () => {},
  dxCode,
  setDxCode,
  codeModifier,
  setCodeModifier,
  dxModifier,
  setDxModifier,
  getSummaryText,
  externalOrderType,
  setExternalOrderType,
  externalOrderExplanation,
  setExternalOrderExplanation,
  externalOrders,
  setExternalOrders,
  patientId,
  externalOrderTemplates,
  addExternalOrder,
  deleteExternalOrder,
  patient,
  provider,
  nPI,
  isExternalOrderLoading,
}: AssessmentPlanTileProps): JSX.Element => {
  const { getToken } = useAuth()
  const { addToast } = useToastStore()
  const { data: dxCodes } = useQueryGetDXCodes(getToken)
  const { data: InsuranceNames } = useQueryGetInsurancePayers(getToken)
  const [explanation, setExplanation] = React.useState<string>('')
  const [treatment, setTreatment] = React.useState<string>('')
  const [orderIds, setOrderIds] = React.useState<VisitOrder[]>([])
  const [externalOrderTypes, setExternalOrderTypes] = React.useState<any[]>([])
  const [selectedExternalOrderType, setSelectedExternalOrderType] =
    React.useState<any>('')
  // const [documentIds, setDocumentIds] = React.useState<number[]>([])
  const [assessmentDXs, setAssessmentDXs] = React.useState<AssessmentDX[]>([])
  const [rxS, setRxs] = React.useState<PatientPrescription[]>([])
  const [isSummaryLoading, setIsSummaryLoading] =
    React.useState<boolean>(false)
  const { setIsEditing } = useGlobalStore()
  React.useEffect(() => {
    if (
      assessmentPlan?.patientPrescriptions !== null &&
      assessmentPlan?.patientPrescriptions !== undefined &&
      assessmentPlan?.patientPrescriptions.length > 0
    ) {
      setRxs(assessmentPlan?.patientPrescriptions)
    }
  }, [assessmentPlan?.patientPrescriptions])

  React.useEffect(() => {
    getExternalOrderTypes(getToken)
      .then((res: any) => {
        setExternalOrderTypes(res)
      })
      .catch((err) => {
        addToast({ message: err.message, status: 'error' })
      })
  }, [])

  const handleDXChange = (id: number, label: string): void => {
    setDxCode({ id, label })
    setIsEditing(true)
  }
  const handleDXModChange = (event: any): void => {
    const {
      target: { value },
    } = event
    setDxModifier(value as number)
    setIsEditing(true)
  }
  const handleCodeModChange = (event: any): void => {
    const {
      target: { value },
    } = event
    setCodeModifier(value as number)
    setIsEditing(true)
  }
  const addTreatmentPlan = (): void => {
    setIsSummaryLoading(true)
    Promise.resolve(getSummaryText())
      .then((res) => {
        setTreatment(res)
        setIsSummaryLoading(false)
      })
      .catch((err) => {
        throw err
      })
  }

  const handleExternalOrderChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    if (newValue === null) {
      setExternalOrderType({ label: '', id: 0 })
    } else {
      setExternalOrderType({ label: newValue.label, id: newValue.id ?? 0 })
    }
    setIsEditing(true)
  }

  const handleExternalOrderTypeChange = (e: any): void => {
    setSelectedExternalOrderType(e.target.value)
  }

  // const handleDocumentsChanges = (
  //   event: any,
  //   newValue: {
  //     id: number | undefined
  //     label: string | null | undefined
  //   } | null
  // ): void => {
  //   if (newValue !== null) {
  //     setDocumentIds([...documentIds, newValue.id as number])
  //   }
  // }

  const handleInternalAddExternalOrder = (): void => {
    if (externalOrderType.id === -1) return
    const newOrder: ExternalOrder = {
      visitId,
      documentId: externalOrderType.id,
      orderName: externalOrderType.label,
      relevantInfo: externalOrderExplanation,
      externalOrderTypeId: selectedExternalOrderType,
    }
    addExternalOrder(newOrder)
    setExternalOrderType({ id: 0, label: '' })
    setExternalOrderExplanation('')
    setIsEditing(false)
  }
  const handleAddAssessmentDX = (): void => {
    if (dxCode.id === 0) return
    const newAssessmentDX: AssessmentDX = {
      visitId,
      diagnosisCodeId: dxCode.id,
      diagnosisCodingModifierId: codeModifier,
      diagnosisModifierId: dxModifier,
    }
    setIsDirty(true)
    setAssessmentDXs([...assessmentDXs, newAssessmentDX])
    const newAssessmentPlan = assessmentPlan
    newAssessmentPlan.visitAssessmentDiagnosisDtoList = assessmentDXs
    setDxCode({ id: 0, label: '' })
    setDxModifier(1)
    setCodeModifier(1)
    setListChange(!listChange)
    setIsEditing(false)
    // setDocumentIds([])
  }

  const handleOnBlur = (event: any): void => {
    const treatmentParsed = isEmpty(assessmentPlan?.treatment)
      ? ''
      : assessmentPlan?.treatment
    const dirty =
      treatmentParsed !== treatment ||
      assessmentPlan?.explanation !== explanation ||
      assessmentPlan?.visitOrderList?.length !== orderIds.length ||
      isDirty
    setIsDirty(dirty)
    const newAssessmentPlan: AssessmentPlan = {
      visitId,
      explanation,
      treatment,
      visitAssessmentDiagnosisDtoList: assessmentDXs,
      visitOrderList: orderIds,
      // documentIds
    }
    if (
      newAssessmentPlan.explanation !== assessmentPlan?.explanation ||
      newAssessmentPlan.visitAssessmentDiagnosisDtoList !==
        assessmentPlan?.visitAssessmentDiagnosisDtoList ||
      newAssessmentPlan.treatment !== assessmentPlan?.treatment ||
      newAssessmentPlan.visitOrderList !== assessmentPlan?.visitOrderList
    ) {
      setAssessmentPlan(newAssessmentPlan)
    }
    setInFocus(false)
  }

  React.useEffect(() => {
    setTreatment(assessmentPlan?.treatment ?? '')
    setAssessmentDXs(assessmentPlan?.visitAssessmentDiagnosisDtoList ?? [])
    setExplanation(assessmentPlan?.explanation ?? '')
    setOrderIds(assessmentPlan?.visitOrderList ?? [])
    // setDocumentIds(assessmentPlan.documentIds ?? [])
  }, [assessmentPlan])

  React.useEffect(() => {
    const treatmentParsed = isEmpty(assessmentPlan?.treatment)
      ? ''
      : assessmentPlan?.treatment
    const dirty =
      treatmentParsed !== treatment ||
      assessmentPlan?.explanation !== explanation ||
      assessmentPlan?.visitOrderList?.length !== orderIds.length
    setIsDirty(dirty)
  }, [treatment, explanation, orderIds])

  const handleDXDelete = (
    dxCodeId: number,
    dxModifierId: number,
    codingModifierId: number
  ): void => {
    setIsDirty(true)
    setAssessmentDXs(
      assessmentDXs.filter(
        (dx) =>
          /* prettier-ignore */
          dx.diagnosisCodeId !== dxCodeId
      )
    )
    const newAssessmentPlan = assessmentPlan
    newAssessmentPlan.visitAssessmentDiagnosisDtoList = assessmentDXs.filter(
      (dx) =>
        /* prettier-ignore */
        dx.diagnosisCodeId !== dxCodeId
    )
    setAssessmentPlan(newAssessmentPlan)
    setListChange(!listChange)
  }
  const handleDXDEdit = (
    dxCodeId: number,
    dxModifierId: number,
    codingModifierId: number
  ): void => {
    const dxCode = dxCodes?.find((code) => code.id === dxCodeId)
    setDxCode({
      id: dxCodeId,
      label: `${dxCode?.name ?? ''} - ${dxCode?.icd10CmCode ?? ''}`,
    })
    setDxModifier(dxModifierId)
    setCodeModifier(codingModifierId)
    setAssessmentDXs(
      assessmentDXs.filter(
        (dx) =>
          /* prettier-ignore */
          dx.diagnosisCodeId !== dxCodeId
      )
    )
  }
  const buildExternalOrders = (): JSX.Element => {
    const dxName =
      assessmentDXs
        .map((dx) => {
          return dxCodes?.find((code) => code.id === dx.diagnosisCodeId)?.name
        })
        .join(', ') ?? ''

    const dxCode =
      assessmentDXs
        .map((dx) => {
          return dxCodes?.find((code) => code.id === dx.diagnosisCodeId)
            ?.icd10CmCode
        })
        .join(', ') ?? ''

    if (isExternalOrderLoading) {
      return (
        <AddTable>
          <Shimmer
            width={'768px'}
            height={`${40 * externalOrders.length - 1}px`}
          />
        </AddTable>
      )
    }
    return (
      <AddTable>
        {externalOrders.map((order) => {
          const externalOrderTypeName = externalOrderTypes.find(
            (type) => type.id === order.externalOrderTypeId
          )?.name
          return (
            <ExternalOrderRow
              key={order.id ?? 0}
              externalOrderId={order.id ?? 0}
              externalOrderName={order.orderName ?? externalOrderTypeName ?? ''}
              externalOrderType={externalOrderTypeName ?? order.orderName ?? ''}
              externalOrderExplaination={order.relevantInfo ?? ''}
              documentId={order.documentId ?? 0}
              visitId={visitId}
              patientId={patientId}
              delete={handleDeleteExternalOrder}
              edit={handleEditExternalOrder}
              patient={patient}
              dxName={dxName}
              dxCode={dxCode}
              provider={provider}
              nPI={nPI}
              insuranceNames={InsuranceNames ?? []}
            />
          )
        })}
      </AddTable>
    )
  }

  const buildAssessmentDXs = (): JSX.Element => {
    return (
      <AddTable>
        {assessmentDXs.map((dx) => {
          const dxCode = dxCodes?.find(
            (code) => code.id === dx.diagnosisCodeId
          )
          return (
            <DXRow
              key={dx.diagnosisCodeId}
              dxCodeId={dx.diagnosisCodeId ?? 0}
              dxCode={`${dxCode?.name ?? ''} - ${dxCode?.icd10CmCode ?? ''}`}
              dxModifierId={dx.diagnosisModifierId ?? 0}
              dxModifier={
                dxModifiers.find((code) => code.id === dx.diagnosisModifierId)
                  ?.name ?? ''
              }
              codingModifierId={dx.diagnosisCodingModifierId ?? 0}
              codingModifier={
                codingModifiers.find(
                  (code) => code.id === dx.diagnosisCodingModifierId
                )?.name ?? ''
              }
              delete={handleDXDelete}
              edit={handleDXDEdit}
            />
          )
        })}
      </AddTable>
    )
  }
  const buildPrescriptions = (): JSX.Element => {
    return (
      <AddTable>
        {rxS.map((dx) => {
          const newDX = {
            ...dx,
            visitId,
          }
          return <PrescriptionRow key={dx.id} prescription={newDX} />
        })}
      </AddTable>
    )
  }

  const handleDeleteExternalOrder = (id: number): void => {
    setExternalOrders(externalOrders.filter((order) => order.id !== id))
    deleteExternalOrder(id)
  }

  const handleEditExternalOrder = (
    id: number,
    documentId: number,
    name: string,
    explanation: string
  ): void => {
    handleDeleteExternalOrder(id)
    setExternalOrderType({ id: documentId, label: name })
    setExternalOrderExplanation(explanation)
  }

  return (
    <TileLayout
      id="assessmentplan"
      inFocus={inFocus}
      setInFocus={setInFocus}
      ref={ref}
      onBlur={handleOnBlur}
    >
      <Title titleText="Assessment & Plan" />
      <TileRow>
        <ElasticSearchBox
          dataTestId="diagnosis"
          label="Diagnosis"
          value={dxCode}
          onChange={handleDXChange}
          fullWidthResults
        />
        <TileSelect
          dataTestId="dxMod"
          label="Diagnosis Modifier"
          value={dxModifier}
          onChange={handleDXModChange}
          options={dxModifiers}
          template={template}
        />
        <TileSelect
          dataTestId="codeMod"
          label="Code Modifier"
          value={codeModifier}
          onChange={handleCodeModChange}
          options={codingModifiers}
          template={template}
        />
      </TileRow>
      {
        /* prettier-ignore */
        assessmentDXs.length > 0
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              {/* prettier-ignore */}
              {buildAssessmentDXs()}
              {/* prettier-ignore */}
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }
      <ButtonRow>
        <AddButton
          onClick={handleAddAssessmentDX}
          title="Add Diagnosis"
          dataTestId="addDx"
        />
      </ButtonRow>
      <TileRow>
        <TileTextArea
          label="Explanation"
          value={explanation}
          onChange={(e: any) => {
            setIsEditing(true)
            setExplanation(e.target.value)
          }}
          fullsize
          dataTestId="Explanation"
          disabled={isLocked}
        />
      </TileRow>
      <TileRow>
        {isSummaryLoading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <TileTextArea
            label="Treatment"
            value={treatment}
            onChange={(e: any) => {
              setIsEditing(true)
              setTreatment(e.target.value)
            }}
            fullsize
            dataTestId="Treatment"
            disabled={isLocked}
          />
        )}
      </TileRow>
      {getSummaryText !== null && process.env.SHOW_TREATMENT_PLAN_BUTTON ? (
        <ButtonRow>
          <AddButton
            onClick={addTreatmentPlan}
            title="Generate Treatment Plan"
            dataTestId="generateTreatmentPlan"
            disabled={isLocked}
          />
        </ButtonRow>
      ) : (
        <></>
      )}
      {
        /* prettier-ignore */

        rxS.length > 0
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              <div>
                <TileInputLabel>Prescriptions</TileInputLabel>
                {/* prettier-ignore */}
                {buildPrescriptions()}
                {/* prettier-ignore */}
              </div>
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }
      <Title titleText="External Order" />
      <TileRow>
        <TileSelect
          dataTestId="externalOrderType"
          label="Order Type"
          value={selectedExternalOrderType}
          onChange={handleExternalOrderTypeChange}
          options={externalOrderTypes}
          template={template}
          fullsize
        />
      </TileRow>
      <TileRow>
        <TileSearchBox
          dataTestId="externalOrder"
          label="External Order"
          value={externalOrderType}
          options={externalOrderTemplates}
          onChange={handleExternalOrderChange}
          fullsize
          disabled={isLocked}
        />
      </TileRow>
      <TileRow>
        <TileTextArea
          label="Relevant Information"
          value={externalOrderExplanation}
          onChange={(e: any) => setExternalOrderExplanation(e.target.value)}
          fullsize
          dataTestId="externalOrderExplanation"
          disabled={isLocked}
        />
      </TileRow>
      <ButtonRow>
        <AddButton
          onClick={handleInternalAddExternalOrder}
          title="Add External Order"
          dataTestId="addExternalOrder"
          disabled={isLocked}
        />
      </ButtonRow>
      {externalOrders.length > 0 ? (
        <TileRow>
          <div>
            <TileInputLabel>External Orders</TileInputLabel>
            {buildExternalOrders()}
          </div>
        </TileRow>
      ) : (
        <></>
      )}
    </TileLayout>
  )
}

interface AssessmentPlanTileProps {
  visitId: number;
  assessmentPlan: AssessmentPlan;
  setAssessmentPlan: any;
  dxModifiers: DXModifiers[];
  codingModifiers: CodingModifier[];
  documents: any[];
  ref: React.RefObject<HTMLDivElement>;
  inFocus: boolean;
  setInFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
  listChange: boolean;
  setListChange: React.Dispatch<React.SetStateAction<boolean>>;
  template?: boolean;
  isDirty?: boolean;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  dxCode: { id: number; label: string };
  setDxCode: React.Dispatch<
    React.SetStateAction<{ id: number; label: string }>
  >;
  dxModifier: number;
  setDxModifier: React.Dispatch<React.SetStateAction<number>>;
  codeModifier: number;
  setCodeModifier: React.Dispatch<React.SetStateAction<number>>;
  getSummaryText: any;
  externalOrderType: any;
  setExternalOrderType: any;
  externalOrderExplanation: string;
  setExternalOrderExplanation: any;
  externalOrders: ExternalOrder[];
  setExternalOrders: any;
  patientId: number;
  externalOrderTemplates: any[];
  addExternalOrder: (order: ExternalOrder) => void
  deleteExternalOrder: any;
  patient: Patient;
  provider: string;
  nPI: string;
  isExternalOrderLoading: boolean;
}
