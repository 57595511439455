import { LinearProgress, Select, styled, Typography } from '@mui/material'

export const SelectFilesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '10px',
  gap: '8px',
})

export const Container = styled('div')(
  ({ fullWidth }: { fullWidth: boolean | undefined }) => ({
    ...(fullWidth && {
      width: '764px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '8px',
    }),
  })
)

export const FileListHeader = styled('div')({
  fontWeight: 'bold',
  marginBottom: '8px',
  width: '500px',
  display: 'flex',
  justifyContent: 'space-between',
})

export const FileContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingBottom: '8px',
  borderBottom: '1px solid #DDDFE8',
})

export const ExamCircularProgress = styled(LinearProgress)({
  width: '450px !important',
  height: '2px !important',
})

export const FileRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
})

export const StatusRow = styled('div')({
  display: 'flex',
  gap: '10px',
  alignItems: 'flex-start',
})

export const FileNameTypography = styled(Typography)({
  minWidth: '460px',
})

export const FileTypeSelect = styled(Select)({
  minWidth: '250px',
})

export const StatusIcon = styled('span')(({ color }: { color: string }) => ({
  color,
  display: 'flex',
  alignItems: 'center',
}))
