import { IconButton, styled } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'
import { ModalTitle } from '@/components'

const ModalLayoutContainer = styled('div')(
  ({ width, height }: ModalLayoutContainerProps) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    padding: '20px',
    borderRadius: '12px',
    backgroundColor: 'white',
    top: width === '100%' ? '12.5%' : 0,
    left: width === '100%' ? '22%' : 0,
    margin: 'auto',
    width,
    height,
    position: 'relative',
    overflow: 'auto'
  })
)

const ModalRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})

export const ModalLayout = ({
  title,
  handleCancel,
  children,
  width = '100%',
  height = 'auto',
}: ModalLayoutProps): JSX.Element => {
  return (
    <ModalLayoutContainer width={width} height={height}>
      <ModalRow>
        <ModalTitle>{title}</ModalTitle>
        <IconButton onClick={handleCancel} size="large">
          <ClearIcon fontSize="small" />
        </IconButton>
      </ModalRow>
      {children}
    </ModalLayoutContainer>
  )
}

interface ModalLayoutProps {
  title: string;
  handleCancel: () => void;
  width?: string;
  height?: string;
  children: JSX.Element | JSX.Element[] | undefined;
}

interface ModalLayoutContainerProps {
  width: string | number;
  height?: string | number;
}
