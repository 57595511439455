import React from 'react'
import {
  InputLabel,
  Snackbar,
  Alert,
  Divider,
  styled,
  CircularProgress,
} from '@mui/material'
import { type SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { useAuth } from '@clerk/nextjs'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from '@/utility'
import { type ExternalProvider } from '@/types'
import {
  ModalContainer,
  ModalRow,
  FullColumn,
  ModalButton,
  ModalTitle,
  ModalFullTextField,
  ModalButtonRow,
  HalfColumn,
  RedText,
  ErrorMessage,
  AddressInput,
  PhoneNumberInput,
} from '@/components'
import {
  useQueryGetStates,
  useMutateAddExternalProvider,
  useMutateUpdateExternalProvider,
} from '@/hook'

const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 24,
  paddingTop: '20px',
})

const validationSchema = (existingProvider: any): any =>
  Yup.object().shape({
    providerFirstName: Yup.string().required('First Name is required'),
    providerLastName: Yup.string().required('Last name is required'),
    providerPracticeName: Yup.string().required('Practice Name is required'),
    providerSpecialty: Yup.string().required('Specialty is required'),
    providerstreet1: Yup.string().required('Street1 is required'),
    providercity: Yup.string().required('City is required'),
    providerstate: Yup.string().required('State is required'),
    providerzip: Yup.string().required('ZipCode is required'),
    providerPhoneNumber: Yup.string().required('Phone number is required'),
    faxPhoneNumber: Yup.string().required('Fax number is required'),
  })

interface AddExternalProviderForm {
  providerFirstName?: string | null;
  providerLastName?: string | null;
  providerPracticeName?: string | null;
  providerSpecialty?: string | null;
  providerNPI?: string | null;
  providerstreet1?: string | null;
  providerstreet2?: string | null;
  providercity?: string | null;
  providerstate?: string | null;
  providerzip?: string | null;
  providerEmail?: string | null;
  providerPhoneNumber?: string | null;
  faxPhoneNumber?: string | null;
}

export const AddExternalProvider = ({
  handleClose,
  existingProvider = null,
  refresh,
  onAddProvider,
}: AddExternalProviderProps): JSX.Element => {
  const { getToken } = useAuth()
  const [isProcessing, setIsProcessing] = React.useState<boolean>(false)
  const [toastMessage, setToastMessage] = React.useState<string>('')
  const { data: states } = useQueryGetStates(getToken)
  const addNewProviderMutation = useMutateAddExternalProvider(getToken)
  const updateProviderMutation = useMutateUpdateExternalProvider(getToken)
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    setError,
    formState: { errors },
    trigger,
  } = useForm<AddExternalProviderForm>({
    mode: 'all',
    resolver: yupResolver(validationSchema(existingProvider)),
    ...(existingProvider !== null && {
      defaultValues: {
        providerFirstName: existingProvider.firstName,
        providerLastName: existingProvider.lastName,
        providerPracticeName: existingProvider.practiceName,
        providerSpecialty: existingProvider.specialty,
        providerNPI: existingProvider.npi,
        providerstreet1: existingProvider.address1,
        providerstreet2: existingProvider.address2,
        providercity: existingProvider.city,
        providerstate:
          states?.find((state) => state.id === existingProvider?.stateId)
            ?.code ?? '',
        providerzip: existingProvider.zipCode,
        providerEmail: existingProvider.emailAddress,
        providerPhoneNumber: existingProvider.phoneNumber,
        faxPhoneNumber: existingProvider.faxNumber,
      },
    }),
  })
  React.useEffect(() => {
    const stateCode =
      states?.find((state) => state.id === existingProvider?.stateId)?.code ??
      ''
    setValue('providerstreet1', existingProvider?.address1)
    setValue('providerstreet2', existingProvider?.address2)
    setValue('providerstate', stateCode)
    setValue('providercity', existingProvider?.city)
    setValue('providerzip', existingProvider?.zipCode)
  }, [existingProvider])

  const handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return
    }
    setToastMessage('')
  }

  const handleReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault()
    reset()
    handleClose()
  }
  const onSubmit: SubmitHandler<AddExternalProviderForm> = (data): void => {
    const stateId =
      states?.find((state) => state.code === data.providerstate)?.id ?? 0
    const provider: ExternalProvider = {
      firstName: data.providerFirstName,
      lastName: data.providerLastName,
      practiceName: data.providerPracticeName,
      specialty: data.providerSpecialty,
      npi: data.providerNPI ?? '',
      address1: data.providerstreet1,
      address2: data.providerstreet2 ?? '',
      city: data.providercity,
      stateId,
      zipCode: data.providerzip,
      emailAddress: data.providerEmail,
      phoneNumber: data.providerPhoneNumber ?? '',
      faxNumber: data.faxPhoneNumber ?? '',
      id: existingProvider?.id ?? 0,
      notes: '',
    }
    const response =
      existingProvider === null
        ? addNewProviderMutation.mutateAsync({ ExternalProvider: provider })
        : updateProviderMutation.mutateAsync({
            ExternalProviderId: provider?.id!,
            ExternalProvider: provider,
          })
    setIsProcessing(true)
    Promise.resolve(response)
      .then((response) => {
        if (refresh) {
          refresh()
        }
        if (onAddProvider) onAddProvider(provider)
        handleClose()
      })
      .catch((error) => {
        if (!isEmpty(error)) {
          if (error.includes('=>')) {
            setToastMessage(JSON.parse(error).Message.split('=>')[1])
          } else {
            setToastMessage(error)
          }
        }
        throw error
      })
      .finally(() => {
        setIsProcessing(false)
      })
  }

  const handleSubmitClick = (): void => {
    trigger()
      .then((result) => {
        if (result) {
          handleSubmit(onSubmit)().catch((error) => {
            throw error
          })
        }
      })
      .catch((error) => {
        console.error('Validation failed:', error)
      })
  }

  return (
    <>
      <ModalContainer>
        <form
          style={{ width: '100%' }}
          // I don't put submit button as type="submit" because it triggers submission
          // on parent component's form as well
        >
          <ModalTitle>
            {existingProvider === null ? 'Add Provider' : 'Edit Provider'}
          </ModalTitle>
          <ModalRow>
            <HalfColumn>
              <InputLabel>
                Provider First Name<RedText>*</RedText>
              </InputLabel>
              <ModalFullTextField
                name={'providerFirstName'}
                register={register}
                error={
                  errors.providerFirstName !== undefined &&
                  errors.providerFirstName !== null
                }
              />
              {!isEmpty(errors.providerFirstName) && (
                <ErrorMessage error={errors.providerFirstName?.message ?? ''} />
              )}
            </HalfColumn>
            <HalfColumn>
              <InputLabel>
                Provider Last Name<RedText>*</RedText>
              </InputLabel>
              <ModalFullTextField
                name={'providerLastName'}
                register={register}
                error={
                  errors.providerLastName !== undefined &&
                  errors.providerLastName !== null
                }
              />
              {!isEmpty(errors.providerLastName) && (
                <ErrorMessage error={errors.providerLastName?.message ?? ''} />
              )}
            </HalfColumn>
          </ModalRow>
          <ModalRow>
            <HalfColumn>
              <InputLabel>
                Practice Name<RedText>*</RedText>
              </InputLabel>
              <ModalFullTextField
                name={'providerPracticeName'}
                register={register}
                error={
                  errors.providerPracticeName !== undefined &&
                  errors.providerPracticeName !== null
                }
              />
              {!isEmpty(errors.providerPracticeName) && (
                <ErrorMessage
                  error={errors.providerPracticeName?.message ?? ''}
                />
              )}
            </HalfColumn>
            <HalfColumn>
              <InputLabel>
                Specialty<RedText>*</RedText>
              </InputLabel>
              <ModalFullTextField
                name={'providerSpecialty'}
                register={register}
                error={
                  errors.providerSpecialty !== undefined &&
                  errors.providerSpecialty !== null
                }
              />
              {!isEmpty(errors.providerSpecialty) && (
                <ErrorMessage error={errors.providerSpecialty?.message ?? ''} />
              )}
            </HalfColumn>
          </ModalRow>
          <ModalRow>
            <HalfColumn>
              <InputLabel>NPI</InputLabel>
              <ModalFullTextField name={'providerNPI'} register={register} />
            </HalfColumn>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <Divider />
            </FullColumn>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <AddressInput
                control={control}
                register={register}
                sectionName="provider"
                setValue={setValue}
                setError={setError}
                errors={errors}
                fullSize
                required
              />
            </FullColumn>
          </ModalRow>
          <ModalRow>
            <FullColumn>
              <Divider />
            </FullColumn>
          </ModalRow>
          <Row>
            <FullColumn>
              <InputLabel>Email</InputLabel>
              <ModalFullTextField name={'providerEmail'} register={register} />
            </FullColumn>
            <FullColumn>
              <PhoneNumberInput
                control={control}
                sectionName="provider"
                labelName="Phone"
                required
                error={errors.providerPhoneNumber}
                setError={setError}
              />
            </FullColumn>
            <FullColumn>
              <PhoneNumberInput
                control={control}
                sectionName="fax"
                labelName="Fax"
                required
                error={errors.faxPhoneNumber}
                setError={setError}
              />
            </FullColumn>
          </Row>
          <ModalButtonRow>
            <ModalButton
              variant="outlined"
              type="reset"
              onClick={(event) => handleReset(event)}
            >
              Cancel
            </ModalButton>
            {isProcessing ? (
              <CircularProgress />
            ) : (
              <ModalButton
                variant="contained"
                color="primary"
                onClick={handleSubmitClick}
              >
                {existingProvider === null ? 'Add' : 'Save'}
              </ModalButton>
            )}
          </ModalButtonRow>
        </form>
      </ModalContainer>
      <Snackbar
        open={!isEmpty(toastMessage)}
        autoHideDuration={6000}
        onClose={handleToastClose}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

interface AddExternalProviderProps {
  handleClose: any;
  existingProvider?: ExternalProvider | null;
  refresh?: any;
  onAddProvider?: (provider: ExternalProvider) => void;
}
