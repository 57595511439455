import { styled, Typography } from '@mui/material'
import React from 'react'

const SubTitleContainer = styled(Typography)({
  fontSize: '16px',
  lineHeight: '24px',
  minHeight: '25px',
  variant: 'h3',
  fontWeight: '600',
})

export const HeaderPMH = ({ children }: HeaderProps): JSX.Element => {
  return <SubTitleContainer>{children}</SubTitleContainer>
}

interface HeaderProps {
  children: string;
}
