import React from 'react'
import {
  Stack,
  ThemeProvider,
  type Theme,
  StyledEngineProvider,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  Modal,
  Snackbar,
  Alert,
} from '@mui/material'
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { DeletePayerPopup } from './DeletePayerPopup'
import { type PVerifyInsurance, type State } from '@/types'
import { useSecondaryTableTheme } from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { isEmpty } from '@/utility'
import { deleteInsurancePayer } from '@/services'
import { AddInsurancePayer } from '@/components'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const InsurancePayerTable = ({
  insurancePayers,
  states,
  refresh,
}: InsurancePayerTableProps): JSX.Element => {
  const theme = useSecondaryTableTheme()
  const { getToken } = useAuth()
  const tableInstanceRef = React.useRef<any>()
  const [toastMessage, setToastMessage] = React.useState<string>('')
  const [selectedPayer, setSelectedPayer] = React.useState<PVerifyInsurance>()
  const [openAddInsurancePayer, setOpenAddInsurancePayer] =
    React.useState(false)
  const handleOpenAddInsurancePayer = (): void =>
    setOpenAddInsurancePayer(true)
  const handleCloseInsurancePayer = (): void => setOpenAddInsurancePayer(false)
  const [open, setOpen] = React.useState<boolean>(false)
  const columns = React.useMemo<Array<MRT_ColumnDef<PVerifyInsurance>>>(
    () => [
      {
        id: 'insurance_payer',
        header: 'Insurance Name',
        enableSorting: true,
        accessorFn: (row) => `${row.payerName!}`,
        sortingFn: 'alphanumeric',
        size: 250,
        minSize: 250,
      },
      {
        id: 'address',
        header: 'Address',
        enableSorting: true,
        accessorFn: (row) => row?.address1 ?? '',
        sortingFn: 'alphanumeric',
        size: 200,
        minSize: 200,
      },
      {
        id: 'city',
        header: 'City',
        enableSorting: true,
        accessorFn: (row) => row?.city ?? '',
        sortingFn: 'alphanumeric',
        size: 100,
        minSize: 100,
      },
      {
        id: 'state',
        header: 'State',
        enableSorting: true,
        accessorFn: (row) => {
          const state = states.find((state) => state.id === row.stateId)
          return state?.name ?? ''
        },
        sortingFn: 'alphanumeric',
        size: 100,
        minSize: 100,
      },
      {
        id: 'zip',
        header: 'Zip',
        enableSorting: true,
        accessorFn: (row) => row?.zip ?? '',
        sortingFn: 'alphanumeric',
        size: 100,
        minSize: 100,
      },
      {
        id: 'pVerifyID',
        header: 'pverify ID',
        enableSorting: true,
        accessorFn: (row) => row?.pVerifyPayerCode ?? '',
        sortingFn: 'alphanumeric',
        size: 100,
        minSize: 100,
      },
      {
        id: 'global_rate',
        header: 'Global Rate',
        enableSorting: true,
        accessorFn: (row) => (row?.globalFeeEnabled === true ? 'Yes' : 'No'),
        sortingFn: 'alphanumeric',
        size: 30,
        minSize: 30,
      },
      {
        id: 'in_network',
        header: 'In Network',
        enableSorting: true,
        accessorFn: (row) => (row?.inNetwork === true ? 'Yes' : 'No'),
        sortingFn: 'alphanumeric',
        size: 30,
        minSize: 30,
      },
      {
        header: 'Actions',
        accessorFn: () => 'actions',
        id: 'actions',
        enableSorting: false,
        minSize: 30,
        size: 30,
        maxSize: 30,
        Cell: ({ row }: any) => (
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Tooltip title="Edit Payer" arrow>
              <IconButton
                onClick={() => {
                  setSelectedPayer(row.original as PVerifyInsurance)
                  handleOpenAddInsurancePayer()
                }}
                size="large"
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Payer" arrow>
              <IconButton
                onClick={() => {
                  setSelectedPayer(row.original as PVerifyInsurance)
                  setOpen(true)
                }}
                size="large"
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      },
    ],
    []
  )
  const handleToastClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return
    }
    setToastMessage('')
  }

  const handleDeletePayer = (): void => {
    if (selectedPayer?.id) {
      deleteInsurancePayer(selectedPayer.id, getToken)
        .then((response) => {
          if (refresh) {
            refresh()
          }
        })
        .catch((error) => {
          if (!isEmpty(error)) {
            if (error.includes('=>')) {
              setToastMessage(JSON.parse(error).Message.split('=>')[1])
            } else {
              setToastMessage(error)
            }
          }
          throw error
        })
      setOpen(false)
    }
  }
  return (
    <Box sx={{ width: '100%' }}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <MaterialReactTable
            columns={columns}
            data={insurancePayers}
            enableColumnActions={false}
            enableRowActions={false}
            enableGlobalFilterModes
            enableDensityToggle={false}
            globalFilterModeOptions={['fuzzy', 'contains']}
            tableInstanceRef={tableInstanceRef}
            muiTablePaginationProps={{
              rowsPerPageOptions: [5, 10, 20],
              showFirstButton: false,
              showLastButton: false,
            }}
            muiLinearProgressProps={({ isTopToolbar }) => ({
              sx: {
                display: isTopToolbar ? 'block' : 'none',
              },
            })}
          />
        </ThemeProvider>
      </StyledEngineProvider>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: '12px' }}
      >
        <DeletePayerPopup
          name={selectedPayer?.payerName}
          handleDelete={handleDeletePayer}
          setClose={() => setOpen(false)}
        />
      </Dialog>
      <Modal
        open={openAddInsurancePayer}
        onClose={handleCloseInsurancePayer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AddInsurancePayer
          handleClose={handleCloseInsurancePayer}
          insurancePayer={selectedPayer}
          refresh={refresh}
        />
      </Modal>
      <Snackbar
        open={!isEmpty(toastMessage)}
        autoHideDuration={6000}
        onClose={handleToastClose}
      >
        <Alert
          onClose={handleToastClose}
          severity="error"
          sx={{ width: '100%' }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

interface InsurancePayerTableProps {
  insurancePayers: PVerifyInsurance[];
  states: State[];
  refresh: any;
}
