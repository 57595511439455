import React from 'react'
import { RowTile } from '../components/RowTile'
import { RowValue } from '../components/RowValue'
import { AddColumn } from '../layout/AddColumn'
import { AddRow } from '../layout/AddRow'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Popover } from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'
import { DXHoverOver } from './DXPopover'

export const DXRow = ({
  dxCode,
  dxCodeId,
  dxModifier,
  dxModifierId,
  codingModifier,
  codingModifierId,
  delete: deleteDX = null,
  edit: editDX = null,
}: DXRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <DXHoverOver
          dxName={dxCode}
          dxModifier={dxModifier}
          codingModifier={codingModifier}
        />
      </Popover>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={22}>
          <RowValue>{dxCode}</RowValue>
        </AddColumn>
        <AddColumn width={35}>
          <RowTile>Diagnosis Modifier</RowTile>
          <RowValue>{dxModifier}</RowValue>
        </AddColumn>
        <AddColumn width={35}>
          <RowTile>Coding Modifier</RowTile>
          <RowValue maxWidth="52%">{codingModifier}</RowValue>
        </AddColumn>
        {
          // prettier-ignore
          editDX !== null
          // prettier-ignore
          ? (
              <AddColumn width={4}>
                <IconButton
                  onClick={() => editDX(dxCodeId, dxModifierId, codingModifierId)}
                  size="large">
                  <ModeIcon />
                </IconButton>
              </AddColumn>)
          // prettier-ignore
          : <></>
        }
        {
          // prettier-ignore
          deleteDX !== null
          // prettier-ignore
          ? (
              <AddColumn width={4}>
                <IconButton
                  onClick={() => deleteDX(dxCodeId, dxModifierId, codingModifierId)}
                  data-testid={`deleteDX${dxCodeId}${dxModifierId}${codingModifierId}`}
                  size="large">
                  <DeleteIcon />
                </IconButton>
              </AddColumn>)
          // prettier-ignore
          : <></>
        }
      </AddRow>
    </>
  )
}
interface DXRowProps {
  dxCode: string;
  dxCodeId: number;
  dxModifier: string;
  dxModifierId: number;
  codingModifier: string;
  codingModifierId: number;
  // prettier-ignore
  delete?: ((
    dxCodeId: number,
    dxModifierId: number,
    codingModifierId: number
  ) => void)
  | null
  // prettier-ignore
  edit?: ((
    dxCodeId: number,
    dxModifierId: number,
    codingModifierId: number
  ) => void)
  | null
}
