import { styled, Typography } from '@mui/material'
import React from 'react'

const NameBoxContainer = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  fontSize: '16px',
  lineHeight: '24px',
  /* identical to box height */
  color: '#000000',
  maxWidth: '240px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxHeight: '40px',
})

export const ValueBox = ({ children }: NameBoxProps): JSX.Element => {
  return <NameBoxContainer>{children}</NameBoxContainer>
}
interface NameBoxProps {
  children: string;
}
