import React from 'react'
import { StyledSelect } from '../..'
import { MenuItem } from '@mui/material'
import { useQueryGetClinics } from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { useMutateUpdateTask } from '@/hook/useTasks'

export const DetailsClinic = ({
  taskId,
  taskClinicId,
}: DetailsClinicProps): JSX.Element => {
  const { getToken } = useAuth()
  const { data: clinics = [] } = useQueryGetClinics(getToken)
  const updateTaskMutation = useMutateUpdateTask(getToken)

  return (
    <StyledSelect
      value={taskClinicId}
      onChange={(e) => {
        void updateTaskMutation.mutateAsync({
          id: taskId,
          task: { clinicId: e.target.value as number },
        })
      }}
    >
      {clinics?.map(({ id, name }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

interface DetailsClinicProps {
  taskId: number;
  taskClinicId: number;
}
