import React from 'react'
import { useAuth } from '@clerk/nextjs'
import {
  updatePatientSocialHistory,
  deletePatientSocialHistory,
} from '@/services'
import {
  usePatientSocialHistoryStore,
  useQueryGetPatientSocialHistory,
  useMutateAddPatientSocialHistory,
} from '@/hook'
import { PMHTile } from '../PatientHistoryTile'

export const PatientSocialHistory = ({
  patient,
}: {
  patient: any;
}): JSX.Element => {
  const { getToken } = useAuth()

  const { patientSocialHistories } = usePatientSocialHistoryStore()

  const addNewPatientSocialHistory = useMutateAddPatientSocialHistory(getToken)

  const { isLoading: isSocialHistoryLoading, refetch } =
    useQueryGetPatientSocialHistory(patient?.id as number, getToken)

  const socialHistoryTypes = [
    { id: 1, name: 'Alcohol' },
    { id: 2, name: 'Tobacco' },
    { id: 3, name: 'Drugs' },
    { id: 4, name: 'Other' },
  ]

  if (isSocialHistoryLoading) {
    return <div>Loading social history...</div>
  }

  const patientSocialHistoryArray = Array.isArray(patientSocialHistories)
    ? patientSocialHistories
    : []

  const socialHistoryArray = Array.isArray(socialHistoryTypes)
    ? socialHistoryTypes
    : []

  const columns = [
    { accessor: 'socialHistoryId', Header: 'Type' },
    { accessor: 'frequency', Header: 'Frequency' },
  ]

  const patientSocialHistoriesWithName = patientSocialHistoryArray.map(
    (history) => ({
      ...history,
      socialHistoryId:
        history.socialHistoryId !== null
          ? socialHistoryTypes.find(
              (type) => type.id === history.socialHistoryId
            )?.name
          : history.otherSocialHistory ?? 'Unknown Social History',
    })
  )

  const dialogFields = [
    {
      label: 'Type',
      accessor: 'socialHistoryId',
      type: 'select',
      options: socialHistoryArray.map((type) => ({
        id: type.id,
        label: type.name,
      })),
    },
    { label: 'Frequency', accessor: 'frequency', type: 'text' },
  ]

  const handleAdd = async (data: any): Promise<void> => {
    const {
      socialHistoryId: id,
      frequency,
      socialHistoryId_label: label,
    } = data
    const newSocialHistory = {
      patientId: patient?.id,
      socialHistoryId: id && !isNaN(id) ? parseInt(id, 10) : null,
      otherSocialHistory: id && isNaN(id) ? id : id ? null : label,
      frequency,
      id: 0,
    }

    await addNewPatientSocialHistory.mutateAsync({ history: newSocialHistory })
    await refetch()
  }

  const handleEdit = async (data: any): Promise<void> => {
    const {
      socialHistoryId: id,
      frequency,
      socialHistoryId_label: label,
    } = data
    const newSocialHistory = {
      patientId: patient?.id,
      socialHistoryId: id && !isNaN(id) ? parseInt(id, 10) : null,
      otherSocialHistory: id && isNaN(id) ? id : id ? null : label,
      frequency,
      id: data.id,
    }

    await updatePatientSocialHistory(data.id, newSocialHistory, getToken)
    await refetch()
  }

  const handleDelete = async (socialHistoryToDelete: any): Promise<void> => {
    await deletePatientSocialHistory(socialHistoryToDelete.id, getToken)
    await refetch()
  }

  return (
    <PMHTile
      title="Social History"
      data={patientSocialHistoriesWithName}
      columns={columns}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      dialogFields={dialogFields}
    />
  )
}
