import {
  Button,
  styled,
  CircularProgress,
  Box,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import { FileUpload } from '@/components/FileUpload'
import Image from 'next/image'
import React from 'react'
import {
  FileIcon,
  defaultStyles,
  type DefaultExtensionType,
} from 'react-file-icon'
import { Document, Page, pdfjs } from 'react-pdf'
import DeleteIcon from '@mui/icons-material/Delete'

const InsuranceCardBox = styled('div')({
  width: '350px',
  height: '212px',
  background: '#F7F8FC',
  border: '2px dashed #DDDFE8;',
  borderRadius: '14px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
const StyledIconButton = styled(Button)({
  width: '24px',
  height: '24px',
  minWidth: 'unset',
})
const StyledImg = styled(Image)({
  width: '350px',
  height: '212px',
  borderRadius: '14px',
})
const InsuranceCardContainer = styled('div')({
  display: 'flex',
  height: '212px',
  overflow: 'hidden',
  justifyContent: 'center',
  position: 'relative',
})
const UploadButton = styled(Button)({
  width: '250px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: 600,
})

export const InsuranceCard = ({
  idFile,
  setPhotoFile,
  register,
  disabled = false,
  isTextEntered = false,
}: InsuranceCardProps): JSX.Element => {
  const [openID, setOpenID] = React.useState(false)
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const handleOpenID = (): void => {
    isTextEntered ? setDialogOpen(true) : setOpenID(true)
  }
  const handleCloseID = (): void => setOpenID(false)
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
    pdfjs.version ?? ''
  }/pdf.worker.js`

  React.useEffect(() => {
    handleCloseID()
  }, [idFile])

  const onDeleteCard = (): void => {
    setPhotoFile(undefined)
  }

  return (
    <InsuranceCardContainer id={'insuranceCardContainer'}>
      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen}>
        <DialogTitle>Previous insurance information entered</DialogTitle>
        <DialogContent>
          Any information will be overwritten by the information from the
          insurance card. Are you sure you want to proceed?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              setDialogOpen(false)
              setOpenID(true)
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <FileUpload
        open={openID}
        onClose={handleCloseID}
        setFiles={setPhotoFile}
        dataTestId={'insuranceCardModal'}
      />
      {
        // prettier-ignore
        idFile !== null && idFile !== undefined
          ? (
              idFile?.type.includes('pdf')
                ? (
                  <Document
                    file={idFile}
                    loading={<Box sx={{ display: 'flex', height: '212px', alignItems: 'center' }}><CircularProgress /></Box>}
                    error={
                      <Box sx={{ display: 'flex', width: '70px', marginRight: '15px' }}>
                        <FileIcon extension={'pdf'} {...defaultStyles['pdf' as DefaultExtensionType]} labelUppercase />
                      </Box>
                    }
                  >
                    <Page pageNumber={1} width={350} onClick={handleOpenID}/>
                  </Document>
                  )
                : (
                  <StyledImg data-testid='insuranceCardStyledImg' alt={'insuranceCard'} src={URL.createObjectURL(idFile)} width={350} height={212} onClick={disabled ? () => {} : handleOpenID} />
                  )
            )
          : (
            <InsuranceCardBox data-testid={'insuranceCardBox'}>
              <UploadButton
                data-testid={'insuranceUploadButton'}
                onClick={handleOpenID}
                disabled={disabled}
              >
                {' '}
                Upload Insurance Card
              </UploadButton>
            </InsuranceCardBox>
            )
      }
      {idFile !== null && idFile !== undefined && !disabled && (
        <Stack
          style={{
            flexDirection: 'column',
            position: 'absolute',
            right: '4px',
            top: '4px',
            height: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <StyledIconButton onClick={onDeleteCard}>
            <DeleteIcon sx={{ fontSize: 16 }} />
          </StyledIconButton>
        </Stack>
      )}
    </InsuranceCardContainer>
  )
}

export interface InsuranceCardProps {
  idFile?: Blob | null | undefined;
  setPhotoFile: (file: Blob | null | undefined) => void;
  register?: any;
  disabled?: boolean;
  isTextEntered?: boolean;
}
