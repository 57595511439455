import { styled } from '@mui/material'
import React from 'react'

const UserManagmentListBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  width: '100%',
  overflow: 'auto',
  maxHeight: 'calc(100vh - 200px)',
  paddingBottom: '100px',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  position: 'relative',
})

export const ListBox = ({ children }: ListBoxProps): JSX.Element => {
  return <UserManagmentListBox>{children}</UserManagmentListBox>
}

interface ListBoxProps {
  children: JSX.Element | JSX.Element[] | undefined;
}
