import { Button, Popover, styled } from '@mui/material'
import React from 'react'
import {
  type ExamStatusType,
  type ReviewOfSystem,
  type RosSymptom,
  type RosSystem,
  type ExamResultIdType,
} from '@/types'
import { Title, TileLayout, AddButton } from '@/components'
import ArrowDropDownOutlined from '@mui/icons-material/ArrowDropDownOutlined'
import { ExamModal } from './ExamModal'
import { ExamList } from './ExamList'
import { StyledTextButton } from '../FreeTextNotes/styles'
import { ROS_EMPTY, convertToRoSTree } from '@/utility'

const DropdownButton = styled(Button)({
  width: '100%',
  backgroundColor: 'transparent',
  borderRadius: '5px',
  color: '#A7A7A7',
  border: '1px solid #DDDFE8 !important',
  flex: 'display',
  justifyContent: 'space-between',
  textTransform: 'lowercase',
})

const TileRow = styled('div')({
  justifyContent: 'space-between',
  display: 'flex',
  gap: '24px',
  scrollMarginTop: '60px',
  width: '768px',
  minHeight: '24px',
  alignContent: 'center',
  alignItems: 'center',
})

export const NewRosTile = ({
  examSystems,
  examSystemComponents,
  ref,
  visitId,
  inFocus,
  setInFocus,
  isLocked = false,
  rosResultFactorIds,
  rosResponses,
  setReviewOfSystems,
  isSaving,
  setIsROSListRendering,
}: NewRosTileProps): JSX.Element => {
  const rosItems = React.useMemo(
    () => convertToRoSTree(examSystems, examSystemComponents),
    [examSystems, examSystemComponents]
  )

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  )

  const handleUpdateExam = async (
    examId: number,
    status: number
  ): Promise<void> => {
    const curExamResponse = rosResponses[examId]
    if (!curExamResponse) {
      if (status === rosResultFactorIds[ROS_EMPTY]) {
        return
      }
      if (examId < 0) {
        setReviewOfSystems((prev: ReviewOfSystem[]) => {
          const temp = [
            ...prev,
            {
              visitId,
              rosSystemId: -examId,
              visitRosSymptomList: [],
              note: '',
            },
          ]
          return temp
        })
      } else {
        setReviewOfSystems((prev: ReviewOfSystem[]) => {
          const rosSystemId = examSystemComponents.find(
            ({ id }) => id === examId
          )?.rosSystemId
          return prev.map((item: ReviewOfSystem) =>
            item.rosSystemId === rosSystemId
              ? {
                  ...item,
                  visitRosSymptomList: [
                    ...(item.visitRosSymptomList ?? []),
                    {
                      visitId,
                      rosSymptomId: examId,
                      rosSystemId,
                      rosResultFactorId: status,
                    },
                  ],
                }
              : item
          )
        })
      }
    } else {
      if (status === curExamResponse.status) {
        return
      }
      if (status === rosResultFactorIds[ROS_EMPTY]) {
        if (examId < 0) {
          setReviewOfSystems((prev: ReviewOfSystem[]) => {
            return prev.filter(({ rosSystemId }) => rosSystemId !== -examId)
          })
        } else {
          setReviewOfSystems((prev: ReviewOfSystem[]) => {
            const rosSystemId = examSystemComponents.find(
              ({ id }) => id === examId
            )?.rosSystemId
            return prev.map((item: ReviewOfSystem) =>
              item.rosSystemId === rosSystemId
                ? {
                    ...item,
                    visitRosSymptomList: (
                      item.visitRosSymptomList ?? []
                    ).filter(({ rosSymptomId }) => rosSymptomId !== examId),
                  }
                : item
            )
          })
        }
      } else {
        if (examId >= 0) {
          setReviewOfSystems((prev: ReviewOfSystem[]) => {
            const rosSystemId = examSystemComponents.find(
              ({ id }) => id === examId
            )?.rosSystemId
            return prev.map((item: ReviewOfSystem) =>
              item.rosSystemId === rosSystemId
                ? {
                    ...item,
                    visitRosSymptomList: (item.visitRosSymptomList ?? []).map(
                      (item) =>
                        item.rosSymptomId === examId
                          ? {
                              ...item,
                              rosResultFactorId: status,
                            }
                          : item
                    ),
                  }
                : item
            )
          })
        }
      }
    }
  }

  const handleAddExamNote = (examId: number, note: string): void => {
    const curExamResponse = rosResponses[examId]
    if (!curExamResponse) {
      return
    }
    setReviewOfSystems((prev: ReviewOfSystem[]) => {
      return prev.map((item: ReviewOfSystem) =>
        item.rosSystemId === -examId
          ? {
              ...item,
              note,
            }
          : item
      )
    })
  }

  const handlePopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (isLocked) {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const popoverOpen = Boolean(anchorEl)
  const popoverId = popoverOpen ? 'simple-popover' : undefined

  if (!Object.keys(rosResultFactorIds).length) {
    return <></>
  }

  return (
    <TileLayout
      ref={ref}
      inFocus={inFocus}
      setInFocus={setInFocus}
      id={'review'}
      isLocked={isLocked}
    >
      <>
        <Title titleText="Review of Systems" />
        <ExamList
          type="row"
          examinations={rosItems ?? []}
          examResponses={rosResponses}
          isLocked={isLocked}
          examResultFactorIds={rosResultFactorIds}
          handleUpdateExam={handleUpdateExam}
          handleAddExamNote={handleAddExamNote}
          isSaving={isSaving}
          setIsExamListRendering={setIsROSListRendering}
        />
        <DropdownButton
          variant="outlined"
          endIcon={<ArrowDropDownOutlined />}
          onClick={handlePopoverClick}
        >
          select one or multiple systems
        </DropdownButton>
        <Popover
          id={popoverId}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <ExamModal
            examResultFactorIds={rosResultFactorIds}
            examSystemComponents={rosItems}
            examResponses={rosResponses}
            handleUpdateExam={handleUpdateExam}
            type="ros"
            isSaving={isSaving}
          />
        </Popover>
        {process.env.SHOW_EXAM_LINK_BUTTON === 'true' && (
          <TileRow>
            <StyledTextButton
              sx={{
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '16px',
                textAlign: 'left',
                textDecorationLine: 'underline',
                color: '#0543B0',
                width: 'auto',
                minWidth: '0px',
              }}
              variant="text"
            >
              Link
            </StyledTextButton>
            <AddButton
              dataTestId="add-exam"
              title="Add Exam"
              auto
              onClick={() => {}}
            />
          </TileRow>
        )}
      </>
    </TileLayout>
  )
}

interface NewRosTileProps {
  examSystems: RosSystem[];
  examSystemComponents: RosSymptom[];
  ref: React.RefObject<HTMLDivElement>;
  visitId: number;
  inFocus: boolean;
  setInFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
  rosResultFactorIds: ExamResultIdType;
  rosResponses: ExamStatusType[];
  setReviewOfSystems: any;
  isSaving: boolean;
  setIsROSListRendering: (value: boolean) => void;
}
