import React from 'react'
import { useAuth, useUser } from '@clerk/nextjs'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { IconButton, Stack, styled, Typography } from '@mui/material'
import { useForm, type FieldValues, type SubmitHandler } from 'react-hook-form'
import {
  type PatientInsurance,
  type Patient,
  type State,
  type PVerifyInsurance,
  type Insurance,
  type AccountUser,
} from '@/types'
import { parseDateToDateOnly } from '@/utility'
import { StyledEditButton } from '../styles'
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined'
import { InsuranceInfoRow } from '@/components/PatientIntake/Tiles/InsuranceTile/InsuranceInfoRow'
import {
  useQueryGetInsuranceType,
  useQueryGetAccountUsers,
  useQueryGetPatientDocuments,
  useMutateUpdatePatient,
  useGlobalStore,
} from '@/hook'
import {
  createInsuranceList,
  resetInsuranceField,
  setInsurance,
} from '@/helpers'

const insuranceNames = ['Primary', 'Secondary', 'Tertiary']

const ChiefComplaintContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '812px',
  padding: '24px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  border: '1px solid #CDD0E3',
  gap: 8,
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
}))

const SectionHeader = styled(Typography)({
  fontSize: '14px',
  fontWeight: '600',
})

const InsuranceContainer = styled(Stack)({
  backgroundColor: '#FFFFFF',
  borderRadius: '14px',
  width: '100%',
  padding: '8px',
  gap: '24px',
  marginTop: '10px',
  border: '1px solid #E3E3E3',
  minHeight: '450px',
})
const InsuranceRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  width: '100%',
})
// prettier-ignore
export function PatientInsurances ({
  patient,
  getPatient,
  states,
  insurancesNames
}: {
  patient: Patient
  getPatient: any
  states: State[]
  insurancesNames: PVerifyInsurance[]
}): JSX.Element {
  const { getToken } = useAuth()
  const { isEditing, setIsEditing } = useGlobalStore()
  const [insurances, setInsurances] = React.useState<PatientInsurance[]>(patient.patientInsuranceList!)
  const { user } = useUser()
  const { data: clinicians } = useQueryGetAccountUsers(getToken)
  const { data: insuranceTypes } = useQueryGetInsuranceType(getToken)
  const { data: documents } = useQueryGetPatientDocuments(patient?.id!, getToken)
  const updatePatientMutation = useMutateUpdatePatient(getToken)
  const [insuranceFile, setInsuranceFile] = React.useState<Blob | undefined>()
  const [secondaryFile, setSecondaryFile] = React.useState<Blob | undefined>()
  const [tertiaryFile, setTertiaryFile] = React.useState<Blob | undefined>()
  const [currentUser, setCurrentUser] = React.useState<AccountUser>()
  const validationSchema = Yup.object().shape({
    ...(Array.isArray(insurances) && insurances.length > 0 && {
      PrimaryinsuranceNumber: Yup.string().required('Member ID is required'),
      Primarycompany: Yup.object().shape({
        id: Yup.number().required('Insurance Name is required'),
        label: Yup.string().required('Insurance Name is required')
      }).nullable().required('Insurance Name is required'),
      PrimaryInsuranceBirthDate: Yup.date().required('Policy holder\'s birth date is required').nullable().typeError('Primary insurance birth date is required'),
      PrimaryFirstNameOfHolder: Yup.string().required('Policy holder\'s first name is required'),
      PrimaryLastNameOfHolder: Yup.string().required('Policy holder\'s last name is required'),
      PrimarySex: Yup.string().when('PrimaryInsuranceHolder', {
        is: (holder: any) => holder !== 'self',
        then: Yup.string().required('Policy holder\'s sex is required'),
        otherwise: Yup.string()
      })
    }),
    ...(Array.isArray(insurances) && insurances.length > 1 && {
      SecondaryinsuranceNumber: Yup.string().required('Member ID is required'),
      Secondarycompany: Yup.object().shape({
        id: Yup.number().required('Insurance Name is required'),
        label: Yup.string().required('Insurance Name is required')
      }).nullable().required('Insurance Name is required'),
      SecondaryInsuranceBirthDate: Yup.date().required('Policy holder\'s birth date is required').nullable().typeError('Secondary insurance birth date is required'),
      SecondaryFirstNameOfHolder: Yup.string().required('Policy holder\'s first name is required'),
      SecondaryLastNameOfHolder: Yup.string().required('Policy holder\'s last name is required'),
      SecondarySex: Yup.string().when('SecondaryInsuranceHolder', {
        is: (holder: any) => holder !== 'self',
        then: Yup.string().required('Policy holder\'s sex is required'),
        otherwise: Yup.string()
      })
    }),
    ...(Array.isArray(insurances) && insurances.length > 2 && {
      TertiaryinsuranceNumber: Yup.string().required('Member ID is required'),
      Tertiarycompany: Yup.object().shape({
        id: Yup.number().required('Insurance Name is required'),
        label: Yup.string().required('Insurance Name is required')
      }).nullable().required('Insurance Name is required'),
      TertiaryInsuranceBirthDate: Yup.date().required('Policy holder\'s birth date is required').nullable().typeError('Tertiary insurance birth date is required'),
      TertiaryFirstNameOfHolder: Yup.string().required('Policy holder\'s first name is required'),
      TertiaryLastNameOfHolder: Yup.string().required('Policy holder\'s last name is required'),
      TertiarySex: Yup.string().when('TertiaryInsuranceHolder', {
        is: (holder: any) => holder !== 'self',
        then: Yup.string().required('Policy holder\'s sex is required'),
        otherwise: Yup.string()
      })
    })
  })
  const { handleSubmit, control, register, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  })

  React.useEffect(() => {
    const cUser = clinicians?.find((userItem: any) => (userItem?.username ?? '').toLowerCase() === (user?.username ?? '').toLowerCase())
    setCurrentUser(cUser)
  }, [clinicians, user])

  React.useEffect(() => {
    getPatient()
  }, [])

  const insuranceCompany = (index: number): { id: number, label: string } => {
    if (patient === undefined || patient === null) {
      return { id: 0, label: 'Select Insurance Company' }
    } else if (
      patient?.patientInsuranceList === undefined ||
      patient?.patientInsuranceList === null
    ) {
      return { id: 0, label: 'Select Insurance Company' }
    } else if (patient.patientInsuranceList.length === 0) {
      return { id: 0, label: 'Select Insurance Company' }
    } else if (
      patient.patientInsuranceList[index] === undefined ||
      patient.patientInsuranceList[index] === null
    ) {
      return { id: 0, label: 'Select Insurance Company' }
    } else if (
      patient.patientInsuranceList[index].companyName === undefined ||
      patient.patientInsuranceList[index].companyName === null
    ) {
      return { id: 0, label: 'Select Insurance Company' }
    } else if (
      patient.patientInsuranceList[index].companyName !== undefined &&
      patient.patientInsuranceList[index].companyName !== null
    ) {
      return {
        id:
          patient.patientInsuranceList[index].pVerifyInsuranceCompanyId ?? 0,
        label: patient.patientInsuranceList[index].companyName ?? ''
      }
    }
    return { id: 0, label: 'Select Insurance Company' }
  }

  React.useEffect(() => {
    setInsurance(patient, setValue, insuranceCompany)
  }, [patient])

  React.useEffect(() => {
    const insurances = patient?.patientInsuranceList
    insurances?.forEach((insurance: any) => {
      const document = documents?.find(doc => doc.id === insurance.documentId)

      if (document !== undefined) {
        fetch(document?.presignedUrl)
          .then(async response => await response.blob())
          .then((blob: any) => {
            if (insurance.insuranceLevel === 1) {
              setInsuranceFile(blob)
            } else if (insurance.insuranceLevel === 2) {
              setSecondaryFile(blob)
            } else if (insurance.insuranceLevel === 3) {
              setTertiaryFile(blob)
            }
          })
          .catch((error) => { throw error })
      }
    })
  }, [patient, documents])

  const saveOtherDocuments = async (
    id: string,
    file: Blob,
    patientId: string
  ): Promise<any> => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('patientId', patientId)
    formData.append('documentTypeId', id)
    formData.append('AccountUserId', `${currentUser?.id!}`)
    const documentId = await fetch(`${process.env.API_URL ?? ''}/api/Document`, {
      method: 'POST',
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({
          template: 'UrgentIQ'
        })) ?? ''
          }`
      },
      body: formData
    })
      .then(async res => await res.json())
      .catch((error) => {
        throw error
      })
    return documentId
  }

  const handleEditClicked = (e: React.MouseEvent): void => {
    e.preventDefault()
    setIsEditing(true)
  }
  const handleAddInsurance = (e: React.MouseEvent): void => {
    e.preventDefault()
    const newInsurance: PatientInsurance = {

      insuranceNumber: '',
      groupNumber: '',
      companyName: '',
      plan: '',
      payerId: '0',
      effectiveDate: parseDateToDateOnly(new Date()),
      rxBinNumber: '',
      typeId: 0,
      id: -new Date().getTime(),
      relationToInsured: '',
      addressLine1: '',
      addressLine2: '',
      afterDeductible: '',
      city: '',
      copay: '',
      dateOfBirth: patient.dateOfBirth,
      deductible: '',
      insuranceLevel: (insurances.length + 1) as 1 | 2 | 3 | 4 | 5 | 6,
      leftToMeet: '',
      oopMax: '',
      patientId: patient.id,
      pcn: '123',
      stateId: null,
      zip: null,
      lastName: patient.lastName,
      firstName: patient.firstName,
      documentId: null
    }
    if (insurances.length === 1) {
      setValue('SecondaryFirstNameOfHolder', patient?.firstName)
      setValue('SecondaryLastNameOfHolder', patient?.lastName)
      setValue('SecondaryInsuranceBirthDate', patient?.dateOfBirth)
    } else {
      setValue('TertiaryFirstNameOfHolder', patient?.firstName)
      setValue('TertiaryLastNameOfHolder', patient?.lastName)
      setValue('TertiaryInsuranceBirthDate', patient?.dateOfBirth)
    }
    setInsurances([...insurances, newInsurance])
  }

  const handleDeleteInsurance = (index: number): void => {
    if (insurances.length === 1) {
      setInsurances([])
      setInsuranceFile(undefined)
      resetInsuranceField(setValue, 'Primary')
      return
    }
    const newInsurances = insurances.filter((_, i) => i !== index)
    const newPatient = {
      ...patient,
      patientInsuranceList: newInsurances
    }
    setInsurance(newPatient, setValue, insuranceCompany)
    switch (index) {
      case 0:
        // If the first insurance is deleted, move the second to the first position, and the third to the second position.
        setInsuranceFile(secondaryFile)
        setSecondaryFile(tertiaryFile)
        setTertiaryFile(undefined)
        setValue('Primarycompany', insuranceCompany(1))
        setValue('PrimaryEffectiveDate', newInsurances[0].effectiveDate)
        if (newInsurances.length > 1) {
          setValue('Secondarycompany', insuranceCompany(2))
          setValue('SecondaryEffectiveDate', newInsurances[1].effectiveDate)
          resetInsuranceField(setValue, 'Tertiary')
        } else {
          resetInsuranceField(setValue, 'Secondary')
        }
        break
      case 1:
        // If the second insurance is deleted, move the third to the second position.
        setSecondaryFile(tertiaryFile)
        setTertiaryFile(undefined)
        if (newInsurances.length > 1) {
          setValue('Secondarycompany', insuranceCompany(2))
          setValue('SecondaryEffectiveDate', newInsurances[1].effectiveDate)
          resetInsuranceField(setValue, 'Tertiary')
        }
        if (newInsurances.length > 2) {
          setValue('Tertiarycompany', insuranceCompany(3))
          setValue('TertiaryEffectiveDate', newInsurances[2].effectiveDate)
        }
        break
      case 2:
        // If the third insurance is deleted, just clear it.
        setTertiaryFile(undefined)
        resetInsuranceField(setValue, 'Tertiary')
        break
      default:
        // Handle invalid index, if necessary
        console.error('Invalid index for insurance deletion')
    }
    setInsurances(newPatient.patientInsuranceList)
  }

  const handleCancelEdit = (): void => {
    const ins = insurances?.filter((insurance: any) => insurance.id > 0)
    setInsurances(ins)
    setIsEditing(false)
  }

  const handleSubmitInsurance: SubmitHandler<FieldValues> = async (
    data
  ): Promise<void> => {
    let primaryDocResponse = null; let secondaryDocResponse = null; let tertiaryDocResponse = null
    if (insuranceFile !== null && insuranceFile !== undefined && (insuranceFile as File)?.name !== undefined) {
      primaryDocResponse = await saveOtherDocuments('1', insuranceFile, `${patient.id ?? 0}`).catch(err => { throw err })
    }
    if (secondaryFile !== null && secondaryFile !== undefined && (secondaryFile as File)?.name !== undefined) {
      secondaryDocResponse = await saveOtherDocuments('1', secondaryFile, `${patient.id ?? 0}`).catch(err => { throw err })
    }
    if (tertiaryFile !== null && tertiaryFile !== undefined && (tertiaryFile as File)?.name !== undefined) {
      tertiaryDocResponse = await saveOtherDocuments('1', tertiaryFile, `${patient.id ?? 0}`).catch(err => { throw err })
    }
    const primaryDocId = primaryDocResponse?.id ?? insurances?.[0]?.documentId ?? null
    const secondaryDocId = secondaryDocResponse?.id ?? insurances?.[1]?.documentId ?? null
    const tertiaryDocId = tertiaryDocResponse?.id ?? insurances?.[2]?.documentId ?? null

    let insuranceList: Insurance[] = []

    if (insurances.length > 0) {
      insuranceList = createInsuranceList(
        data,
        patient.id ?? 0,
        insurances?.map(insurance => (insurance?.id ?? 0)),
        states,
        insurances.length,
        [primaryDocId, secondaryDocId, tertiaryDocId]
      )
    }

    setInsurances(insuranceList)
    const newPatient = {
      ...patient,
      patientInsuranceList: insuranceList
    }
    await updatePatientMutation.mutateAsync({ patientId: patient?.id!, patient: newPatient })
    getPatient()
    setIsEditing(false)
  }

  return <>
    <form
      style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}
      onSubmit={(...args): void => {
        handleSubmit(handleSubmitInsurance)(...args).catch((error) => {
          throw error
        })
      }}
    >
      <Stack
        direction="column"
        style={{ width: '100%', height: '100%' }}
      >
        <Stack
          direction="row"
          style={{ gap: '49px' }}
        >
          <Stack
            direction="column"
            style={{ width: '829px' }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: '100%' }}
            >
              <SectionHeader>Insurance</SectionHeader>
              <Stack
                direction="row"
                alignItems="center"
                gap="12px"
              >
                {isEditing
                  // prettier-ignore
                  ? (
                    <>
                      <StyledEditButton onClick={handleCancelEdit}>Cancel</StyledEditButton>
                      <StyledEditButton variant="contained" type="submit">Save</StyledEditButton>
                    </>
                    )
                  // prettier-ignore
                  : (
                    // prettier-ignore
                    <StyledEditButton variant="contained" onClick={handleEditClicked}>
                      Edit
                    </StyledEditButton>
                    // prettier-ignore
                    )}
                {isEditing && insurances.length < 3 && (
                  <StyledEditButton variant="contained" onClick={handleAddInsurance}>
                    Add
                  </StyledEditButton>
                )}
              </Stack>
            </Stack>
              <InsuranceContainer>
                {Array.isArray(insurances) && insurances.map((insurance, index) => {
                  return (
                    <ChiefComplaintContainer key={insurance.id}>
                     <InsuranceRow>
                       {
                         isEditing && (
                           <IconButton onClick={() => handleDeleteInsurance(index)} size="large">
                             <DeleteIcon />
                           </IconButton>
                         )
                       }
                     </InsuranceRow>
                     <InsuranceInfoRow
                       data-testid="insurance-info-row"
                       control={control}
                       register={register}
                       idFile={index === 0 ? insuranceFile : index === 1 ? secondaryFile : tertiaryFile}
                       setPhotoFile={index === 0 ? setInsuranceFile : index === 1 ? setSecondaryFile : setTertiaryFile}
                       insuranceType={insuranceNames[index]}
                       setValue={setValue}
                       insuranceTypes={insuranceTypes ?? []}
                       insuranceNames={insurancesNames}
                       isEditing={isEditing}
                       errors={errors}
                     />
                    </ChiefComplaintContainer>
                  )
                })}
              </InsuranceContainer>
            </Stack>
        </Stack>
      </Stack>
    </form>
  </>
}
