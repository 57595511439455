import { type Visit } from '@/types/Visit'
import React from 'react'
import { styled, Typography } from '@mui/material'
import { PastVisitRow } from './PastVisitRow'
import { type AccountUser } from '@/types'

const PastVisitsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '764px',
})
const PastVisitHeader = styled('div')({
  display: 'flex',
  background: '#F6F8FD',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: '#DDDFE8',
  justifyContent: 'center',
  paddingLeft: '20px',
})
const DateColumn = styled('div')({
  width: '20%',
})
const ProblemColumn = styled('div')({
  width: '20%',
})
const DiagnosisColumn = styled('div')({
  width: '20%',
})
const OrdersColumn = styled('div')({
  width: '20%',
})
const ProviderColumn = styled('div')({
  width: '20%',
})
const ColumnHeader = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '20px',

  /* identical to box height, or 143% */

  color: 'black',
})

export const PastVisits = ({
  visits,
  users,
  user,
  visitTypes,
}: PastVisitsProps): JSX.Element => {
  const findProvider = (id: number): string => {
    if (id === 0) {
      if (
        user?.publicMetadata.account_user_id !== undefined &&
        user?.publicMetadata.account_user_id !== null
      ) {
        id = user.publicMetadata.account_user_id as number
      }
    }
    const provider = users.find((user) => user.id === id)
    if (provider !== undefined) {
      const firstName: string = provider.firstName ?? ''
      const lastName: string = provider.lastName ?? ''
      return `${firstName} ${lastName}`
    } else {
      return ''
    }
  }
  return (
    <PastVisitsContainer>
      <PastVisitHeader>
        <DateColumn>
          <ColumnHeader>Date</ColumnHeader>
        </DateColumn>
        <OrdersColumn>
          <ColumnHeader>Visit Type</ColumnHeader>
        </OrdersColumn>
        <ProviderColumn>
          <ColumnHeader>Provider</ColumnHeader>
        </ProviderColumn>
        <ProblemColumn>
          <ColumnHeader></ColumnHeader>
        </ProblemColumn>
        <DiagnosisColumn>
          <ColumnHeader></ColumnHeader>
        </DiagnosisColumn>
      </PastVisitHeader>
      {visits.map((visit) => (
        <PastVisitRow
          key={visit.id}
          visit={visit}
          provider={findProvider(visit.providerAccountUserId ?? 0)}
          visitTypes={visitTypes}
        />
      ))}
    </PastVisitsContainer>
  )
}
interface PastVisitsProps {
  visits: Visit[];
  users: AccountUser[];
  user: any;
  visitTypes: any;
}
