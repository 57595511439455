import React from 'react'
import { RowTile } from '../components/RowTile'
import { RowValue } from '../components/RowValue'
import { AddColumn } from '../layout/AddColumn'
import { AddRow } from '../layout/AddRow'
import { Popover } from '@mui/material'
import { type Prescription } from '@/types/Prescription'
import { PrescriptionHoverOver } from './PrescriptionPopover'

export const PrescriptionRow = ({ prescription }: DXRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <div style={{ position: 'relative' }}>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={50}>
          <RowValue>{prescription.displayName ?? ''}</RowValue>
        </AddColumn>
        <AddColumn width={15}>
          <RowTile>Route</RowTile>
          <RowValue>{prescription.route ?? ''}</RowValue>
        </AddColumn>
        <AddColumn width={35}>
          <RowTile>Strength</RowTile>
          <RowValue maxWidth="52%">{prescription.strength ?? ''}</RowValue>
        </AddColumn>
      </AddRow>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <PrescriptionHoverOver prescription={prescription} />
      </Popover>
    </div>
  )
}
interface DXRowProps {
  prescription: Prescription;
}
