import React from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { useAuth } from '@clerk/nextjs'
import { useMutateUpdateTask } from '@/hook/useTasks'
import { StyledTextField } from '../..'
import { parseDateToDateOnly } from '@/utility'

export const AssignedData: React.FC<AssignedDataProps> = ({
  taskId,
  assignedOn,
}: AssignedDataProps): JSX.Element => {
  const { getToken } = useAuth()
  const updateTaskMutation = useMutateUpdateTask(getToken)

  return (
    <DatePicker
      value={assignedOn}
      onChange={(value) => {
        void updateTaskMutation.mutateAsync({
          id: taskId,
          task: { dateAssigned: parseDateToDateOnly(new Date(value ?? '')) },
        })
      }}
      renderInput={(params) => (
        <StyledTextField {...params} inputProps={params.inputProps} />
      )}
    />
  )
}

interface AssignedDataProps {
  taskId: number;
  assignedOn: string;
}
