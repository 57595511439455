import React from 'react'
import { InputLabel, MenuItem, Select } from '@mui/material'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { useAuth } from '@clerk/nextjs'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { type Room, type Clinic } from '@/types'
import {
  ModalContainer,
  ModalRow,
  FullColumn,
  ModalButton,
  ModalTitle,
  ModalFullTextField,
  ModalButtonRow,
} from '@/components'
import { useMutateAddNewRoom, useMutateUpdateRoom } from '@/hook'

const validationSchema = Yup.object().shape({
  roomName: Yup.string().required('Room name is required'),
  roomDescription: Yup.string().required('Room description is required'),
})

interface AddRoomForm {
  roomName?: string | null;
  roomDescription?: string | null;
  roomClinicId?: number | null;
}

export const AddRoom = ({
  clinics,
  handleClose,
  exisitingRoom = null,
  clinicID,
  refresh,
}: AddRoomProps): JSX.Element => {
  const { getToken } = useAuth()
  const addRoomMutation = useMutateAddNewRoom(getToken)
  const updateRoomMutation = useMutateUpdateRoom(getToken)

  const { register, handleSubmit, reset, control } = useForm<AddRoomForm>({
    resolver: yupResolver(validationSchema),
    ...(exisitingRoom !== null && {
      defaultValues: {
        roomName: exisitingRoom.name,
        roomDescription: exisitingRoom.description,
        roomClinicId: clinicID,
      },
    }),
  })

  const handleReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault()
    reset()
    handleClose()
  }

  const onSubmit: SubmitHandler<AddRoomForm> = async (data): Promise<void> => {
    const room: Room = {
      name: data.roomName,
      description: data.roomDescription,
      id: exisitingRoom?.id ?? 0,
      clinicId: data.roomClinicId ?? 0,
    }
    const response =
      exisitingRoom === null
        ? addRoomMutation.mutateAsync({ room })
        : updateRoomMutation.mutateAsync({ roomId: room?.id!, room })
    Promise.resolve(await response)
      .then((response) => {
        refresh()
        handleClose()
      })
      .catch((error) => {
        throw error
      })
  }
  return (
    <ModalContainer>
      <form
        style={{ width: '100%' }}
        onSubmit={(...args): void => {
          handleSubmit(onSubmit)(...args).catch((error) => {
            throw error
          })
        }}
      >
        <ModalTitle>
          {exisitingRoom === null ? 'Add Room' : 'Edit Room'}
        </ModalTitle>
        <ModalRow>
          <FullColumn>
            <InputLabel>Name</InputLabel>
            <ModalFullTextField name="roomName" register={register} />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <InputLabel>Description</InputLabel>
            <ModalFullTextField name="roomDescription" register={register} />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <InputLabel>Clinic</InputLabel>
            <Controller
              name="roomClinicId"
              control={control}
              defaultValue={clinicID}
              render={({ field }) => (
                <Select {...field} labelId="GenderLabel">
                  {clinics.map((clinic) => {
                    return (
                      <MenuItem key={clinic.id} value={clinic.id}>
                        {clinic.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            />
          </FullColumn>
        </ModalRow>
        <ModalButtonRow>
          <ModalButton
            variant="outlined"
            type="reset"
            onClick={(event) => handleReset(event)}
          >
            Cancel
          </ModalButton>
          <ModalButton variant="contained" color="primary" type="submit">
            {exisitingRoom === null ? 'Add' : 'Save'}
          </ModalButton>
        </ModalButtonRow>
      </form>
    </ModalContainer>
  )
}

interface AddRoomProps {
  handleClose: any;
  exisitingRoom?: Room | null;
  clinics: Clinic[];
  clinicID: number;
  refresh: any;
}
