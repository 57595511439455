import React from 'react'
import { type WidgetProps } from '@rjsf/utils'
import { Checkbox, styled } from '@mui/material'
import { TileInputLabel } from '../../components'

const CheckBoxRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  marginTop: '-12px',
})

export const CustomCheckbox = function (props: WidgetProps): any {
  // Function to handle change for the 'positive' and 'negative' checkboxes
  const handlePositiveNegativeChange = (
    newValue: boolean,
    isPositive: boolean
  ): void => {
    if (isPositive && newValue) {
      props.onChange(true)
    } else if (!isPositive && newValue) {
      props.onChange(false)
    } else {
      // Set to undefined if neither checkbox is checked
      props.onChange(undefined)
    }
  }

  return props.label.toLocaleLowerCase() !== 'positive' ? (
    <CheckBoxRow
      sx={{ marginTop: '16px', marginLeft: '-11px', minWidth: '248px' }}
    >
      <Checkbox
        checked={props.value}
        onChange={(event: any) => props.onChange(event.target.checked)}
      />
      <TileInputLabel>{props.label}</TileInputLabel>
    </CheckBoxRow>
  ) : (
    <div
      style={{ maxWidth: '248px', display: 'flex', flexDirection: 'column' }}
    >
      <TileInputLabel>Test Results</TileInputLabel>
      <div style={{ maxWidth: '248px', display: 'flex', marginTop: '-20px' }}>
        <CheckBoxRow
          sx={{ marginTop: '16px', marginLeft: '-11px', minWidth: '144px' }}
        >
          <Checkbox
            checked={props.value === true}
            onChange={(event) =>
              handlePositiveNegativeChange(event.target.checked, true)
            }
          />
          <TileInputLabel>Positive</TileInputLabel>
        </CheckBoxRow>
        <CheckBoxRow
          sx={{ marginTop: '16px', marginLeft: '-11px', minWidth: '144px' }}
        >
          <Checkbox
            checked={props.value === false}
            onChange={(event) =>
              handlePositiveNegativeChange(event.target.checked, false)
            }
          />
          <TileInputLabel>Negative</TileInputLabel>
        </CheckBoxRow>
      </div>
    </div>
  )
}
