import { type OnlineAppointment } from '../types/OnlineAppointment'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { type QueryObserverResult, useQuery } from '@tanstack/react-query'
import { getPatientAppointments } from '@/services/OnlineAppointments'
import type { GetToken } from '@clerk/types'

interface PatientAppointmentState {
  patientAppointments: Map<number, OnlineAppointment[]>;
  getPatientAppointments: (patientId: number) => OnlineAppointment[];
  setPatientAppointments: (
    patientId: number,
    appointments: OnlineAppointment[]
  ) => void;
}

export const usePatientAppointmentStore = create<PatientAppointmentState>()(
  devtools((set, get) => ({
    patientAppointments: new Map<number, OnlineAppointment[]>(),
    getPatientAppointments: (patientId: number) => {
      const appointments = get().patientAppointments.get(patientId)
      return appointments ?? []
    },
    setPatientAppointments: (
      patientId: number,
      appointments: OnlineAppointment[]
    ) => {
      const patientAppointments = get().patientAppointments
      patientAppointments.set(patientId, appointments)
      set({ patientAppointments })
    },
  }))
)

export const useQueryGetPatientAppointments = (
  patientId: number,
  getToken: GetToken,
  startDate?: string,
  endDate?: string
): QueryObserverResult<OnlineAppointment[], unknown> => {
  const { setPatientAppointments } = usePatientAppointmentStore()
  return useQuery<OnlineAppointment[]>(
    ['online-appointment', patientId, startDate, endDate],
    async () =>
      await getPatientAppointments(patientId, getToken, startDate, endDate),
    {
      onSuccess: (data) => {
        setPatientAppointments(patientId, data ?? [])
      },
      onError: (error) => {
        throw error
      },
    }
  )
}
