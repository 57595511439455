import { InputLabel, styled } from '@mui/material'
import React, { type ReactNode } from 'react'

const StyleInputLabel = styled(InputLabel)({
  fontSize: '14px',
  lineHeight: '24px',
  color: '#222222',
})
// prettier-ignore
export const TileInputLabel = ({
  children
}: TileInputLabelProps): JSX.Element => {
  // prettier-ignore
  return {
    // prettier-ignore
    ...(// prettier-ignore
      typeof children === 'string' && children === 'BMI'
        ? <StyleInputLabel sx={{ marginTop: '-4px' }}> BMI (lb/inches<sup>2</sup>) </StyleInputLabel>
        : <StyleInputLabel> {children} </StyleInputLabel>)
  }
}

interface TileInputLabelProps {
  children: ReactNode | string;
}
