import * as React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/system'
import { TagWithTooltip } from '@/components'
import { TileInputLabel } from '../TileInputLabel'
import { useTheme } from '@mui/material'

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 0px',
  margin: '0px 8px',
  color: 'rgba(0, 0, 0, 0.90)',
  backgroundColor: 'white',
  borderBottomColor: 'rgba(0, 0, 0, 0.20)',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  fontSize: '12px',
}))

const GroupItems = styled('ul')({
  padding: 0,
})

const StyledTextField = styled(TextField)(
  ({ singleColumn }: StyledTextFieldProps) => ({
    width: singleColumn ? '502px' : '766px',
    height: '40px !important',
    '& > div': {
      borderRadius: 0,
      height: '40px',
      backgroundColor: 'white',

      '& > input': {
        marginTop: '-10px',
      },
    },
  })
)

interface TileSearchBoxProps {
  label?: string | null;
  /* prettier-ignore */
  values?: Array<{
    id: number
    label: string
  }>
  onChange: any;
  /* prettier-ignore */
  options: IQSuggestionOptionType[]
  singleColumn?: boolean;
  multiple?: boolean;
  loading?: boolean;
  dataTestId: string;
}

interface StyledTextFieldProps {
  singleColumn: boolean;
}
export interface IQSuggestionOptionType {
  id: number;
  label: string;
  category: string;
}

export const IQSuggestionAutoComplete = ({
  label = null,
  values,
  onChange,
  options,
  multiple = true,
  singleColumn = false,
  dataTestId,
  loading,
}: TileSearchBoxProps): JSX.Element => {
  const thisValues = [] as IQSuggestionOptionType[]
  const theme = useTheme()

  values?.forEach((item) => {
    const myOption = options.find((option) => option.id === item.id)
    if (myOption) {
      thisValues.push(myOption)
    }
  })
  return (
    <div
      data-testid={`${dataTestId}-div`}
      style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}
    >
      {label !== null && (
        <TileInputLabel data-testid={`${dataTestId}-label`}>
          {label}
        </TileInputLabel>
      )}
      <Autocomplete
        sx={{
          display: 'flex',
          height: '40px',
          '& .MuiChip-root': {
            marginTop: '-11.5px',
          },
          '& .MuiOutlinedInput-root': {
            paddingTop: 2.5,
            height: '40px',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        id="grouped-demo"
        disableCloseOnSelect
        onChange={onChange}
        value={multiple ? thisValues : thisValues?.[0] ?? null}
        multiple={multiple}
        options={options}
        loading={loading}
        groupBy={(option) => option.category}
        getOptionLabel={(option) => option.label}
        renderTags={(value: IQSuggestionOptionType[], getTagProps: any) =>
          value.map((option, index) => (
            <TagWithTooltip
              key={getTagProps({ index }).key}
              label={option.label}
              color={'rgba(5, 67, 176, 0.08)'}
              fontColor={theme.palette.primary.main}
              onDelete={getTagProps({ index }).onDelete}
            />
          ))
        }
        renderInput={(params) => (
          <StyledTextField
            singleColumn={singleColumn}
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testid': `${dataTestId}-textfield`,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
      />
    </div>
  )
}
