import { styled } from '@mui/system'
import React from 'react'

const TileColumnContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
})

export const TileColumn = ({ children }: TileColumnProps): JSX.Element => {
  return <TileColumnContainer>{children}</TileColumnContainer>
}

interface TileColumnProps {
  children: JSX.Element | JSX.Element[];
}
