import React from 'react'

import { styled, Typography } from '@mui/material'
import { ModalLayout } from '@/components/Charting/Editor/IQBar/modals/ModalLayout'
import { ModalButton } from '../Config'

const UnsavedDataPopupRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
})

export const UnsavedDataPopup = ({
  leaveAnyway,
}: UnsavedDataPopupProps): JSX.Element => {
  return (
    <ModalLayout
      title="Confirm"
      handleCancel={() => leaveAnyway(false)}
      width="300px"
    >
      <Typography style={{ fontSize: '14px', marginBottom: '15px' }}>
        You have some unsaved changes. Are you sure you want to leave the page?
      </Typography>
      <UnsavedDataPopupRow>
        <ModalButton variant="outlined" onClick={() => leaveAnyway(true)}>
          Leave Anyway
        </ModalButton>
        <ModalButton variant="contained" onClick={() => leaveAnyway(false)}>
          Cancel
        </ModalButton>
      </UnsavedDataPopupRow>
    </ModalLayout>
  )
}

interface UnsavedDataPopupProps {
  leaveAnyway: any;
}
