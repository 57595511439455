import React from 'react'

import { Checkbox, styled } from '@mui/material'

import {
  TileRow,
  TileColumn,
  TileSubtitle,
  NotesRow,
  AddTable,
  ImmunizationRow,
  TileDatePicker,
  TileSearchBox,
  HeaderPMH,
  Category,
} from '@/components'
import { type PatientImmunization } from '@/types'
import {
  dateOnlyToDate,
  isDateOnlyValid,
  isEmpty,
  parseDateToDateOnly,
} from '@/utility'
import { useImmunizationStore, useGlobalStore } from '@/hook'

const CheckBoxRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
const MultipleCheckBoxRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
})

export const Immunizations = ({
  patientId,
  immunizations,
  addImmunization,
  deleteImmunization,
  notes,
  setNotes,
  immunizationName,
  setImmunizationName,
  hasChildhoodImmunizations,
  setHasChildhoodImmunizations,
  deniesImmunizations,
  setDeniesImmunizations,
}: ImmunizationProps): JSX.Element => {
  const { immunizations: immunizationNames } = useImmunizationStore()
  const { setIsEditing } = useGlobalStore()
  const [
    internalHasChildhoodImmunizations,
    setInternalHasChildhoodImmunizations,
  ] = React.useState<boolean>(hasChildhoodImmunizations)
  const [inputValue, setInput] = React.useState<string>('')
  const [lastKeyDown, setLastKeyDown] = React.useState<string>('')
  const [internalDeniesImmunizations, setInternalDeniesImmunizations] =
    React.useState<boolean>(deniesImmunizations)
  const [immunizationDate, setPrescriptionDate] = React.useState<Date | null>(
    null
  )
  React.useEffect(() => {
    setInput('')
    setPrescriptionDate(null)
  }, [patientId])

  React.useEffect(() => {
    setInternalDeniesImmunizations(deniesImmunizations)
  }, [deniesImmunizations])

  React.useEffect(() => {
    setInternalHasChildhoodImmunizations(hasChildhoodImmunizations)
  }, [hasChildhoodImmunizations])

  React.useEffect(() => {
    if (inputValue) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
  }, [inputValue])

  const handleProcedureTypeChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    if (newValue === null) {
      setImmunizationName({ id: 0, label: '' })
    } else {
      const newImmunization = {
        id: newValue.id ?? 0,
        label: newValue.label ?? '',
      }
      setImmunizationName(newImmunization)
      // add immunization when immunization is picked by keydown
      if (lastKeyDown === 'ArrowDown') {
        handleAddImmunization(newImmunization)
      }
    }
  }

  const handlePrescriptionDate = (event: any): void => {
    setPrescriptionDate(event)
  }

  const handleDeniesCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInternalDeniesImmunizations(event.target.checked)
    setDeniesImmunizations(event.target.checked)
  }

  const handleAddImmunization = (immunizationDefault: any = null): void => {
    const currentImmunization = immunizationDefault || immunizationName
    if (currentImmunization.id !== 0 || inputValue !== '') {
      addImmunization({
        patientId,
        ...(currentImmunization.id > 0
          ? { immunizationId: currentImmunization.id }
          : { otherImmunization: inputValue }),
        ...(immunizationDate === null || immunizationDate === undefined
          ? {}
          : {
              date: parseDateToDateOnly(immunizationDate),
            }),
      }).catch((error) => {
        throw error
      })
      setImmunizationName({ id: 0, label: '' })
      setPrescriptionDate(null)
      setInput('')
      setIsEditing(false)
    }
  }

  const handleDeleteImmunization = (
    immunization: PatientImmunization
  ): void => {
    deleteImmunization(immunization).catch((error) => {
      throw error
    })
  }

  const findImmunizationName = (id: number): string => {
    const immunizationName = immunizationNames.find(
      (immunizationName) => immunizationName.id === id
    )
    if (immunizationName !== undefined) {
      return immunizationName.name ?? ''
    } else {
      return ''
    }
  }
  const handleEditImmunization = (immunization: PatientImmunization): void => {
    setInput(findImmunizationName(immunization.immunizationId ?? 0))
    setImmunizationName({
      id: immunization.immunizationId ?? 0,
      label: findImmunizationName(immunization.immunizationId ?? 0),
    })

    if (!isDateOnlyValid(immunization?.date)) {
      setPrescriptionDate(dateOnlyToDate(immunization?.date)!)
    }
    handleDeleteImmunization(immunization)
  }

  const buildConditions = React.useMemo((): JSX.Element => {
    return (
      <AddTable>
        {immunizations.map((condition) => {
          let name = ''
          if (condition.immunizationId === null) {
            name = condition.otherImmunization ?? ''
          } else {
            name = findImmunizationName(condition.immunizationId ?? 0)
          }
          // return (
          //   <MedicalConditionsRow
          //     key={condition.problem}
          //     medicalCondition={condition}
          //     name={name}
          //     delete={handleDeleteMedicalCondition}
          //     edit={handleEditMedicalCondition}
          //   />
          // )

          return (
            <ImmunizationRow
              key={condition.id}
              immunization={condition}
              immunizationName={name}
              delete={handleDeleteImmunization}
              edit={handleEditImmunization}
            />
          )
        })}
      </AddTable>
    )
  }, [immunizations, immunizationNames])

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInternalHasChildhoodImmunizations(event.target.checked)
    setHasChildhoodImmunizations(event.target.checked)
  }

  const buildImumunizationHistories = (): JSX.Element => {
    return (
      <>
        <TileRow key={'immunizations'} id={'immunizations'}>
          <TileSearchBox
            label={'Immunization'}
            dataTestId={'immunization'}
            value={immunizationName}
            inputValue={inputValue}
            setInputValue={setInput}
            twoColumns
            onChange={(event: any, value: any) =>
              handleProcedureTypeChange(event, value)
            }
            options={immunizationNames.map((immunization) => {
              return {
                id: immunization.id ?? 0,
                label: immunization.name ?? '',
              }
            })}
            onKeyDown={(e) => setLastKeyDown(e.code)}
          />
          <TileDatePicker
            label={'Date Immunization Given'}
            onChange={handlePrescriptionDate}
            value={immunizationDate}
            monthYearFormat
            dataTestId={'immuDate'}
          />
        </TileRow>
      </>
    )
  }
  return (
    <Category>
      <TileRow>
        <TileColumn>
          <TileRow>
            <HeaderPMH>Immunization</HeaderPMH>
            <MultipleCheckBoxRow>
              <CheckBoxRow>
                <TileSubtitle>Has Childhood Vaccines</TileSubtitle>
                <Checkbox
                  checked={internalHasChildhoodImmunizations}
                  onChange={handleCheckBoxChange}
                  data-testid={'hasChildhoodVaccines-checkbox'}
                  sx={{ padding: 0, pl: 1 }}
                />
              </CheckBoxRow>
              <CheckBoxRow>
                <TileSubtitle>Patient Denies</TileSubtitle>
                <Checkbox
                  checked={internalDeniesImmunizations}
                  onChange={handleDeniesCheckBoxChange}
                  data-testid={'deniesImmunizations-checkbox'}
                  sx={{ padding: 0, pl: 1 }}
                />
              </CheckBoxRow>
            </MultipleCheckBoxRow>
          </TileRow>
          {buildImumunizationHistories()}
          <NotesRow
            notes={notes}
            setNotes={setNotes}
            title={'Add Immunization'}
            handleAdd={() => handleAddImmunization()}
            expanded={!isEmpty(notes)}
          />
        </TileColumn>
      </TileRow>
      {
        /* prettier-ignore */
        immunizations.length > 0
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              {/* prettier-ignore */}
              {buildConditions}
              {/* prettier-ignore */}
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }
    </Category>
  )
}

interface ImmunizationProps {
  patientId: number;
  immunizations: PatientImmunization[];
  addImmunization: (immunization: PatientImmunization) => Promise<void>;
  deleteImmunization: (immunization: PatientImmunization) => Promise<void>;
  notes: string;
  setNotes: (notes: string) => void;
  immunizationName: { id: number; label: string };
  setImmunizationName: any;
  hasChildhoodImmunizations: boolean;
  setHasChildhoodImmunizations: any;
  deniesImmunizations: boolean;
  setDeniesImmunizations: any;
}
