import React from 'react'
import { type TaskTableCellProps } from './TaskTableCellProps'
import { Stack } from '@mui/system'
import { StyledTableText } from './styled'

export const TitleCell = ({ row }: TaskTableCellProps): JSX.Element => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        gap: '1rem',
      }}
    >
      <StyledTableText sx={{ fontSize: '14px', width: '150px' }}>
        {row?.original?.title}
      </StyledTableText>
    </Stack>
  )
}
