import { Button, styled, type Theme, useTheme } from '@mui/material'
import React from 'react'

const StyledButton = styled(Button)(({ smaller, auto }: StyledButtonProps) => ({
  height: '40px',
  borderRadius: '30px',
  fontWeight: '500',
  width: auto ? 'auto' : smaller ? '240px' : '320px',
}))
export const AddButton = ({
  title,
  onClick,
  smaller = false,
  disabled = false,
  dataTestId,
  auto = false,
}: AddButtonProps): JSX.Element => {
  const theme = useTheme()
  return (
    <StyledButton
      smaller={smaller}
      theme={theme}
      onClick={onClick}
      variant="outlined"
      disabled={disabled}
      data-testId={dataTestId}
      auto={auto}
    >
      {' '}
      {title}{' '}
    </StyledButton>
  )
}

interface AddButtonProps {
  title: string;
  onClick: () => void;
  smaller?: boolean;
  disabled?: boolean;
  dataTestId?: string;
  auto?: boolean;
}

interface StyledButtonProps {
  smaller: boolean;
  theme: Theme;
  auto: boolean;
}
