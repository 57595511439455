import React from 'react'
import { StyledTextField } from '../..'
import { useMutateUpdateTask } from '@/hook/useTasks'
import { useAuth } from '@clerk/nextjs'
import { DatePicker } from '@mui/x-date-pickers'
import { parseDateToDateOnly } from '@/utility'

export const CompletedData = ({
  taskId,
  completedOn,
}: CompletedDataProps): JSX.Element => {
  const { getToken } = useAuth()
  const updateTaskMutation = useMutateUpdateTask(getToken)

  return (
    <DatePicker
      value={completedOn}
      onChange={(value: string | null) => {
        void updateTaskMutation.mutateAsync({
          id: taskId,
          task: { dateCompleted: parseDateToDateOnly(new Date(value ?? '')) },
        })
      }}
      renderInput={(params: any) => (
        <StyledTextField {...params} inputProps={params.inputProps} />
      )}
    />
  )
}

interface CompletedDataProps {
  taskId: number;
  completedOn: string;
}
