import { Button, Stack, styled, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { TileInputLabel } from '../TileInputLabel'
import React from 'react'
import { isValid, parse, add } from 'date-fns'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

const StyledTextField = styled(TextField)({
  width: '240px',
  height: '40px !important',
  '& > div': {
    borderRadius: 0,
    height: '40px',
    backgroundColor: 'white',
  },
})

const parseDateInput = (
  input: string,
  monthYearFormat: boolean,
  onChange: any
): string => {
  const filterFormats = (formats: string[], monthYearFormat: boolean): any => {
    return formats.filter((format: string) => {
      if (monthYearFormat) {
        return !format.includes('dd') && !format.includes('d')
      } else {
        return format.includes('dd') || format.includes('d')
      }
    })
  }

  const formats = [
    'M/d/yyyy',
    'MM/dd/yyyy',
    'M/d/yy',
    'MM/dd/yy',
    'Mdyy',
    'Mdyyyy',
    'MMddyy',
    'mmyy',
    'myy',
    'MMddyyyy',
    'M-d-yyyy',
    'MM-dd-yyyy',
    'M-d-yy',
    'MM-dd-yy',
    'M.d.yyyy',
    'MM.dd.yyyy',
    'M.d.yy',
    'MM.dd.yy',
    'M/yyyy',
    'MM/yyyy',
    'Myy',
    'Myyyy',
    'MMyy',
    'MMyyyy',
    'M-yyyy',
    'MM-yyyy',
    'M-yy',
    'MM-yy',
    'M.yyyy',
    'MM.yyyy',
    'M.yy',
    'MM.yy',
  ]

  const filteredFormats = filterFormats(formats, monthYearFormat)
  filteredFormats.forEach((fmt: string) => {
    const date = parse(input, fmt, new Date())
    if (isValid(date)) {
      let updatedDate = date
      if (date.getFullYear() < 100) {
        updatedDate = add(date, { years: 1900 })
      }
      const currentYear = new Date().getFullYear()
      if (updatedDate.getFullYear() + 100 < currentYear) {
        updatedDate = add(updatedDate, { years: 100 })
      }
      onChange(updatedDate)
    }
  })
  return ''
}

export const TileDateTimePicker = ({
  value,
  onChange,
  label,
  monthYearFormat = false,
  dataTestId,
}: TileDatePickerProps): JSX.Element => {
  const setCurrentDateTime = (): void => {
    onChange(new Date()) // This sets the picker's value to the current date and time
  }
  return (
    <div
      style={{ display: 'inline-block', flexDirection: 'column', gap: '4px' }}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          marginBottom: '2px',
          justifyContent: 'space-between',
        }}
      >
        <TileInputLabel>{label}</TileInputLabel>
        <Button
          startIcon={<AccessTimeIcon />}
          variant="text"
          sx={{
            fontSize: '14px',
            color: '#0543B0',
            border: 'none',
            textDecoration: 'underline',
            padding: '0px',
            height: '20px',
          }}
          onClick={setCurrentDateTime}
        >
          Now
        </Button>
      </Stack>
      <DateTimePicker
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testid': dataTestId,
              placeholder: monthYearFormat
                ? 'mm/yyyy hh:mm'
                : 'mm/dd/yyyy hh:mm',
            }}
            onBlur={(e) =>
              parseDateInput(e.target.value, monthYearFormat, onChange)
            }
          />
        )}
      />
    </div>
  )
}

interface TileDatePickerProps {
  value: Date | null;
  onChange: any;
  label: string;
  monthYearFormat?: boolean;
  dataTestId: string;
}
