import React from 'react'
import { StyledTextButton } from './styles'

export const FreeTextNotesStandaloneButton = ({
  isOpen,
  setIsOpen,
}: FreeTextNotesStandaloneButtonProps): JSX.Element => {
  return (
    <StyledTextButton
      sx={{
        fontWeight: 600,
        fontSize: '16px',
        textAlign: 'left',
        textDecorationLine: 'underline',
        color: '#0543B0',
      }}
      variant="text"
      onClick={() => setIsOpen(!isOpen)}
    >
      {isOpen ? 'Close' : 'Add Note'}
    </StyledTextButton>
  )
}

interface FreeTextNotesStandaloneButtonProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
