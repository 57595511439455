import React from 'react'
import {
  PopoverTileText,
  PopoverValueText,
  PopoverContainer,
} from '@/components'

export const SurgicalHistoryHoverOver = ({
  procedure,
  date,
}: SurgicalHistoryHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Surgery</PopoverTileText>
        <PopoverValueText>{procedure}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Date</PopoverTileText>
        <PopoverValueText>{date}</PopoverValueText>
      </div>
    </PopoverContainer>
  )
}

interface SurgicalHistoryHoverOverProps {
  procedure: string;
  date: string;
}
