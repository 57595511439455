import React from 'react'
import theme from '@/styles/theme/theme'
import {
  IconButton,
  Typography,
  Paper,
  Box,
  Badge,
  Stack,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { PMHDialog } from '@/components/PatientProfile/PatientMedicalHistory/PatientHistoryDialog'

export const PMHTile = ({
  title,
  data,
  columns,
  onAdd,
  onEdit,
  onDelete,
  dialogFields,
  fullSize = false,
  disableActions = false
}: {
  data: any[];
  title: string;
  columns: any[];
  onAdd?: any;
  onEdit?: any;
  onDelete?: any;
  dialogFields?: any;
  fullSize?: boolean;
  disableActions?: boolean;
}): JSX.Element => {
  const [open, setOpen] = React.useState(false)
  const [dialogMode, setDialogMode] = React.useState('add')
  const [selectedItem, setSelectedItem] = React.useState(null)

  const handleOpenAddDialog = (): void => {
    setDialogMode('add')
    setOpen(true)
    setSelectedItem(null)
  }

  // const handleOpenEditDialog = (item: any): void => {
  //   setDialogMode('edit')
  //   setOpen(true)
  //   setSelectedItem(item)
  // }

  const handleOpenDeleteDialog = (item: React.SetStateAction<null>): void => {
    setDialogMode('delete')
    setOpen(true)
    setSelectedItem(item)
  }

  const handleCloseDialog = (): void => {
    setOpen(false)
    setSelectedItem(null)
  }

  return (
    <Box minWidth={fullSize ? '100%' : '550px'}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="12px"
        width="100%"
      >
        <Box display="flex" alignItems="center">
          <Typography
            variant="h4"
            style={{ fontSize: '1.1rem', fontWeight: 'bold' }}
          >
            {title}
          </Typography>
          <Badge
            badgeContent={data.length}
            sx={{
              marginLeft: '16px',
              '& .MuiBadge-badge': {
                backgroundColor: '#ECF3FE',
              },
            }}
          />
        </Box>
        {!disableActions && (
        <Typography
          style={{
            cursor: 'pointer',
            display: 'flex',
            color: theme.palette.primary.main,
            alignItems: 'center',
            textDecoration: 'underline',
            textUnderlineOffset: '4px',
            textDecorationThickness: '0.5px',
            fontSize: '0.9rem',
            paddingRight: '6px',
          }}
          onClick={handleOpenAddDialog}
        >
          <AddIcon fontSize="small" sx={{ marginRight: '2px' }} />
          {title}
        </Typography>
        )}
      </Box>
      <Stack
        component={Paper}
        sx={{
          boxShadow: '0 0 1px rgba(0, 0, 0, 0.3)',
          border: 'none',
          borderRadius: 0,
          marginBottom: '32px',
        }}
        width="100%"
      >
        {data.length > 0 ? (
          <Stack direction="column" spacing={0} width="100%">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                backgroundColor: '#ECF3FE',
                padding: '6px 17px'
              }}
            >
              {columns.map((column, idx) => (
                <Typography
                  key={column.accessor}
                  sx={{
                    flex: column.flex ?? 1,
                    textAlign: idx === 1 ? 'left' : 'inherit',
                    paddingLeft: '2px'
                  }}
                >
                  {column.Header}
                </Typography>
              ))}
              {!disableActions && (
              <Typography sx={{ paddingRight: '0px', paddingLeft: '0px' }}>
                Actions
              </Typography>
              )}
            </Stack>
            <Stack width="100%">
              {data.map((row, index) => (
                <Stack
                  key={index}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    boxShadow: '0 0 1px rgba(0, 0, 0, 0.2)',
                    '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
                    padding: '2px',
                    paddingLeft: '20px',
                    paddingRight: '20px',
                    paddingTop: disableActions ? '12px' : '0px',
                    paddingBottom: disableActions ? '12px' : '0px',
                    width: '100%',
                  }}
                >
                  {columns.map((column, idx) => (
                    <Typography
                      key={column.accessor}
                      sx={{
                        flex: column.flex ?? 1,
                        textAlign: idx === 1 ? 'left' : 'inherit',
                      }}
                    >
                      {column.accessor === 'isActive'
                        ? row[column.accessor]
                          ? 'Active'
                          : 'Historical'
                        : row[column.accessor]}
                    </Typography>
                  ))}
                  {!disableActions && (
                  <Box display="flex" alignItems="center">
                    {/* <IconButton
                      onClick={() => handleOpenEditDialog(row)}
                      aria-label="edit"
                    >
                      <EditOutlinedIcon />
                    </IconButton> */}
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(row)}
                      aria-label="delete"
                      size="large"
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Box>
                  )}
                </Stack>
              ))}
            </Stack>
          </Stack>
        ) : (
          <Stack>
            <Typography align="left" padding="8px" paddingLeft="20px">
              No Record
            </Typography>
          </Stack>
        )}
      </Stack>
      {dialogMode && (
        <PMHDialog
          open={open}
          onClose={handleCloseDialog}
          mode={dialogMode}
          title={title}
          onAdd={onAdd}
          onEdit={onEdit}
          onDelete={() => onDelete(selectedItem)}
          fields={dialogFields}
          selectedItem={selectedItem}
        />
      )}
    </Box>
  )
}
