/* eslint-disable multiline-ternary */
import React from 'react'
import {
  type ChiefComplaint,
  type Complaint,
  type InformationSource,
  type VisitType,
} from '@/types'
import {
  TileTextField,
  TileSelect,
  FreeTextNotes,
  Title,
  TileLayout,
  TileRow,
  PillSearchBox,
} from '@/components'
import { useChiefComplaintStore, useMutateUpdateChiefComplaint, useToastStore } from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { type Timeout } from 'react-number-format/types/types'

export const ChiefComplaintTile = ({
  visitTypes,
  complaints,
  informationSources,
  ref,
  inFocus,
  setInFocus,
  isLocked = false,
  isDirty,
  setIsDirty,
}: ChiefComplaintProps): JSX.Element => {
  const { getToken } = useAuth()
  const { chiefComplaint } = useChiefComplaintStore()
  const { addToast } = useToastStore()
  const [notes, setNotes] = React.useState(chiefComplaint?.notes ?? '')
  const updateChiefComplaintMutation = useMutateUpdateChiefComplaint(getToken)

  const [selectComplaint, setSelectComplaint] = React.useState<
    Array<{ id: number; label: string }>
  >([])
  const [selectVisitType, setSelectVisitType] = React.useState<number>(
    chiefComplaint?.visitTypeId ?? 0
  )
  const [selectInformationSource, setSelectInformationSource] =
    React.useState<number>(chiefComplaint?.informationSourceId ?? 0)
  const [sourceOther, setSourceOther] = React.useState<string>('')

  const saveChiefComplaint = (): void => {
    const newChiefComplaint: ChiefComplaint = {}
    newChiefComplaint.complaintList = selectComplaint.map((c) => c.id ?? 0)
    newChiefComplaint.visitTypeId = selectVisitType
    newChiefComplaint.informationSourceId = selectInformationSource
    newChiefComplaint.sourceOther = sourceOther
    newChiefComplaint.notes = notes

    void updateChiefComplaintMutation.mutateAsync({
      vId: chiefComplaint?.visitId ?? 0,
      chiefComplaint: { ...chiefComplaint, ...newChiefComplaint }
    }).catch(() => {
      addToast({
        status: 'error',
        message: 'Failed to update Chief Complaint'
      })
    }).finally(() => setIsDirty(false))
  }

  React.useEffect(() => {
    let timer: Timeout
    if (isDirty && chiefComplaint !== undefined && chiefComplaint !== null) {
      if (notes === chiefComplaint.notes) {
        saveChiefComplaint()
      } else {
        timer = setTimeout(() => {
          saveChiefComplaint()
        }, 500)
      }
    }

    return () => clearTimeout(timer)
  }, [selectComplaint, selectVisitType, selectInformationSource, notes, sourceOther])

  React.useEffect(() => {
    setSelectInformationSource(chiefComplaint?.informationSourceId ?? 0)
    setSelectVisitType(chiefComplaint?.visitTypeId ?? 0)
    const filterComplaints = (chiefComplaint?.complaintList ?? []).map(
      (complaintId: number) => {
        const value = complaints.find(
          (complaint: Complaint) => complaint.id === complaintId
        ) ?? { id: 0, name: '' }
        return { id: value.id ?? 0, label: value.name ?? '' }
      }
    )
    setSelectComplaint(filterComplaints)

    setSourceOther(
      chiefComplaint?.sourceOther === '0' ? '' : chiefComplaint?.sourceOther ?? ''
    )
    setNotes(chiefComplaint?.notes ?? '')
    setIsDirty(false)
  }, [chiefComplaint])

  const complaintsList = React.useMemo(() => {
    return complaints.map((complaint) => {
      return { id: complaint.id ?? 0, label: complaint.name ?? '' }
    })
  }, [complaints])

  const handleOnBlur = (): void => {
    if (isDirty && chiefComplaint !== undefined) {
      saveChiefComplaint()
    }
  }

  const handleVisitTypeChange = (event: any): void => {
    const {
      target: { value },
    } = event
    setIsDirty(true)
    setSelectVisitType(value as number)
  }
  const handleInformationSourceChange = (event: any): void => {
    const {
      target: { value },
    } = event
    setIsDirty(true)
    setSelectInformationSource(value as number)
  }
  const handleSetNotes = (event: any): void => {
    setIsDirty(true)
    setNotes(event ?? '')
  }

  const handleSetSourceOther = (event: any): void => {
    setIsDirty(true)
    setSourceOther(event.target.value)
  }

  const handleComplaintChange = (
    event: any,
    newValue: Array<{
      id: number;
      label: string;
    }> | null
  ): void => {
    setIsDirty(true)
    setSelectComplaint(newValue ?? [])
  }

  return (
    <TileLayout
      id="chief-complaint"
      onBlur={handleOnBlur}
      ref={ref}
      inFocus={inFocus}
      setInFocus={setInFocus}
      isLocked={isLocked}
    >
      <Title titleText="Chief Complaint" />
      <TileRow>
        <TileSelect
          dataTestId="visittype"
          label="Visit Type"
          value={selectVisitType}
          onChange={handleVisitTypeChange}
          options={visitTypes}
        />
        <div>
          <PillSearchBox
            label="Reason for Visit"
            values={selectComplaint}
            onChange={handleComplaintChange}
            options={complaintsList}
            dataTestId="complaint"
            singleColumn
          />
        </div>
      </TileRow>
      <TileRow>
        <TileSelect
          label="Source"
          value={selectInformationSource}
          onChange={handleInformationSourceChange}
          options={informationSources}
          dataTestId="complaintSource"
        />
        {/* prettier-ignore */}
        {selectInformationSource === 3 ? (
          /* prettier-ignore */
          /* prettier-ignore */
          <div>
                {/* prettier-ignore */}
                <div style={{ minHeight: '26px' }} />
                {/* prettier-ignore */}
                <TileTextField fullsize value={sourceOther} onChange={handleSetSourceOther} />
                {/* prettier-ignore */}
              </div>
        ) : (
          /* prettier-ignore */
          /* prettier-ignore */
          /* prettier-ignore */
          <> </>
          /* prettier-ignore */
        )}
      </TileRow>
      <TileRow>
        <FreeTextNotes
          data-testid="notes"
          sectionName="chiefComplaint"
          notes={notes}
          setNotes={handleSetNotes}
        />
      </TileRow>
    </TileLayout>
  )
}

interface ChiefComplaintProps {
  chiefComplaint: ChiefComplaint;
  visitTypes: VisitType[];
  complaints: Complaint[];
  informationSources: InformationSource[];
  setChiefComplaint: any;
  ref: React.RefObject<HTMLDivElement>;
  inFocus: boolean;
  setInFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
  isDirty: boolean;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
}
