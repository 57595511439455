import React from 'react'
import { type AccountUser } from '@/types'
import { type AuditRecord } from '@/types/AudtiRecord'
import { ModalLayout } from '../ModalLayout'
import { TimeStampTable } from './TimeStampTable'
import { CSVLink } from 'react-csv'
import { styled } from '@mui/material'
import { TileSelect } from '@/components/Charting/Editor/components/TileSelect'
import { TileDatePicker } from '@/components/Charting/Editor/components/TileDatePicker'
import { isAfter, isBefore, isSameDay } from 'date-fns'

const FilterRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  width: '100%',
})

export const TimeStampsModal = ({
  auditRecords,
  users,
  handleClose,
}: TimeStampsProps): JSX.Element => {
  const [selectedUser, setSelectedUser] = React.useState<number>(0)
  const [startDate, setStartDate] = React.useState<Date>(
    new Date(auditRecords[0].dateTime ?? '')
  )
  const [endDate, setEndDate] = React.useState<Date>(new Date(Date.now()))
  const [sorting, setSorting] = React.useState<number>(2)
  const [filterAuditRecords, setFilterAuditRecords] = React.useState<
    AuditRecord[]
  >([])
  interface Record {
    Date: string;
    'Old Value': string;
    'New Value': string;
    User: string;
  }
  const auditMap: Record[] = auditRecords.map((auditRecord: AuditRecord) => {
    const user = users.find(
      (user) => user.id === auditRecord.accountUserId
    ) ?? { firstName: '', lastName: '' }
    return {
      Date: auditRecord.dateTime ?? '',
      'Old Value': auditRecord.oldValues?.replaceAll(',', ' ') ?? '',
      'New Value': auditRecord.newValues?.replaceAll(',', ' ') ?? '',
      User: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
    }
  })

  React.useEffect(() => {
    let tempFilteredAuditRecords = auditRecords.filter((auditRecord) => {
      const date = new Date(auditRecord.dateTime ?? '')
      return (
        (isBefore(date, endDate) || isSameDay(date, endDate)) &&
        (isAfter(date, startDate) || isSameDay(date, startDate))
      )
    })
    if (selectedUser === 0) {
      tempFilteredAuditRecords = auditRecords
    } else {
      tempFilteredAuditRecords = auditRecords.filter(
        (auditRecord) => auditRecord.accountUserId === selectedUser
      )
    }
    if (sorting === 1) {
      setFilterAuditRecords(
        tempFilteredAuditRecords.sort((a, b) => {
          return (
            new Date(a.dateTime ?? '').getTime() -
            new Date(b.dateTime ?? '').getTime()
          )
        })
      )
    } else {
      setFilterAuditRecords(
        tempFilteredAuditRecords.sort((a, b) => {
          return (
            new Date(b.dateTime ?? '').getTime() -
            new Date(a.dateTime ?? '').getTime()
          )
        })
      )
    }
  }, [selectedUser, startDate, endDate, sorting])
  return (
    <ModalLayout
      title="Time Stamps"
      handleCancel={handleClose}
      width={'1028px'}
    >
      <CSVLink data={auditMap} filename="timestamps.csv" target="_blank">
        Download CSV
      </CSVLink>
      <FilterRow>
        <TileDatePicker
          dataTestId="timeStampStart"
          label="Start Date"
          value={startDate}
          onChange={(date: any) => setStartDate(date)}
        />
        <TileDatePicker
          dataTestId="timeStampEnd"
          label="End Date"
          value={endDate}
          onChange={(date: any) => setEndDate(date)}
        />
        <TileSelect
          dataTestId="filterByUser"
          label="Filter by User"
          options={users.map((user) => {
            return {
              name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
              id: user.id,
            }
          })}
          value={selectedUser}
          onChange={(event) => setSelectedUser(event.target.value as number)}
        />
        <TileSelect
          dataTestId="sort"
          label="Sort"
          options={[
            {
              name: 'Ascending',
              id: 1,
            },
            {
              name: 'Descending',
              id: 2,
            },
          ]}
          value={sorting}
          onChange={(event) => setSorting(event.target.value as number)}
        />
      </FilterRow>

      <TimeStampTable auditRecords={filterAuditRecords} users={users} />
    </ModalLayout>
  )
}

interface TimeStampsProps {
  auditRecords: AuditRecord[];
  users: AccountUser[];
  handleClose: () => void;
}
