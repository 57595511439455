import { Autocomplete, styled, TextField } from '@mui/material'
import { TileInputLabel } from '../TileInputLabel'
import React from 'react'
import { TagWithTooltip } from '@/components'

const StyledTextField = styled(TextField)(
  ({ singleColumn, isFullWidth }: StyledTextFieldProps) => ({
    width: isFullWidth ? '100%' : singleColumn ? '502px' : '766px',
    height: '40px !important',
    '& > div': {
      borderRadius: 0,
      height: '40px',
      backgroundColor: 'white',

      '& > input': {
        marginTop: '-10px',
      },
    },
  })
)

export const PillSearchBox = ({
  label = null,
  values,
  onChange,
  options,
  multiple = true,
  singleColumn = false,
  dataTestId,
  isFullWidth = false,
}: TileSearchBoxProps): JSX.Element => {
  return (
    <div
      data-testid={`${dataTestId}-div`}
      style={{
        display: 'flex',
        width: isFullWidth ? '100%' : 'auto',
        flexDirection: 'column',
        gap: '2px',
      }}
    >
      {label !== null && (
        <TileInputLabel data-testid={`${dataTestId}-label`}>
          {label}
        </TileInputLabel>
      )}
      <Autocomplete
        sx={{
          display: 'flex',
          height: '40px',
          '& .MuiChip-root': {
            marginTop: '-11.5px',
          },
          '& .MuiOutlinedInput-root': {
            paddingTop: 2.5,
            height: '40px',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        value={multiple ? values : values?.[0] ?? null}
        multiple={multiple}
        id="tags-standard"
        onChange={onChange}
        options={options}
        getOptionLabel={(option) =>
          Array.isArray(option) ? option?.[0].label : option?.label
        }
        renderTags={(value: OptionType[], getTagProps: any) =>
          value.map((option, index) => (
            <TagWithTooltip
              key={getTagProps({ index }).key}
              label={option.label}
              color={'rgba(5, 67, 176, 0.08)'}
              fontColor={'#0543B0'}
              onDelete={getTagProps({ index }).onDelete}
            />
          ))
        }
        isOptionEqualToValue={(option, value) => {
          const optionId = Array.isArray(option) ? option?.[0]?.id : option?.id
          const valueId = Array.isArray(value) ? value?.[0]?.id : value?.id
          return optionId === valueId
        }}
        renderInput={(params) => (
          <StyledTextField
            singleColumn={singleColumn}
            isFullWidth={isFullWidth}
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testid': `${dataTestId}-textfield`,
            }}
          />
        )}
        renderOption={(props, option) => (
          <li
            {...props}
            data-testid={(Array.isArray(option) ? option?.[0] : option)?.label}
          >
            {(Array.isArray(option) ? option?.[0] : option)?.label}
          </li>
        )}
        handleHomeEndKeys
        data-testid={dataTestId}
      />
    </div>
  )
}

interface TileSearchBoxProps {
  label?: string | null;
  /* prettier-ignore */
  values?: Array<{ id: number, label: string, code?: string }>
  onChange: any;
  /* prettier-ignore */
  options: Array<{ id: number, label: string, code?: string }>
  singleColumn?: boolean;
  multiple?: boolean;
  dataTestId: string;
  isFullWidth?: boolean;
}

interface StyledTextFieldProps {
  singleColumn: boolean;
  isFullWidth?: boolean;
}
interface OptionType {
  id: number;
  label: string;
  code?: string | undefined;
}
