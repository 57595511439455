import { Checkbox, CircularProgress, styled } from '@mui/material'
import React from 'react'
import { type ExamResultIdType } from '@/types'
import {
  EXAM_ABNORMAL,
  EXAM_EMPTY,
  EXAM_NORMAL,
  ROS_DENIES,
  ROS_EMPTY,
  ROS_REPORTS,
} from '@/utility'

const RadioColumn = styled('div')({
  display: 'flex',
  width: '75px',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
})

const ExamCircularProgress = styled(CircularProgress)({
  width: '24px !important',
  height: '24px !important',
})

export const SystemRow = ({
  systomId,
  selectedValue,
  examResultFactorIds,
  handleChange,
  type = 'exam',
  isSaving = false,
}: SystemRowProps): JSX.Element => {
  const STR_EMPTY = React.useMemo(
    () => (type === 'exam' ? EXAM_EMPTY : ROS_EMPTY),
    [type]
  )
  const STR_ABNORMAL = React.useMemo(
    () => (type === 'exam' ? EXAM_ABNORMAL : ROS_REPORTS),
    [type]
  )
  const STR_NORMAL = React.useMemo(
    () => (type === 'exam' ? EXAM_NORMAL : ROS_DENIES),
    [type]
  )
  const [isLoading, setIsLoading] = React.useState<number>(0)
  const [positiveValue, setPositiveValue] = React.useState(
    selectedValue === examResultFactorIds[STR_NORMAL]
  )
  const [negativeValue, setNegativeValue] = React.useState(
    selectedValue === examResultFactorIds[STR_ABNORMAL]
  )
  const handlePositiveChange = (event: any): void => {
    setIsLoading(1)
    setPositiveValue(event.target.checked)
    setNegativeValue(false)
  }
  const handleNegativeChange = (event: any): void => {
    setIsLoading(2)
    setNegativeValue(event.target.checked)
    setPositiveValue(false)
  }
  React.useEffect(() => {
    if (positiveValue) {
      handleChange(systomId, examResultFactorIds[STR_NORMAL]).finally(() =>
        setIsLoading(0)
      )
    } else if (negativeValue) {
      handleChange(systomId, examResultFactorIds[STR_ABNORMAL]).finally(() =>
        setIsLoading(0)
      )
    } else {
      handleChange(systomId, examResultFactorIds[STR_EMPTY]).finally(() =>
        setIsLoading(0)
      )
    }
  }, [positiveValue, negativeValue])

  React.useEffect(() => {
    setPositiveValue(selectedValue === examResultFactorIds[STR_NORMAL])
    setNegativeValue(selectedValue === examResultFactorIds[STR_ABNORMAL])
  }, [selectedValue])

  return (
    <>
      <RadioColumn>
        {isLoading === 1 ? (
          <ExamCircularProgress />
        ) : (
          <Checkbox
            inputProps={{ alt: 'positiveCheckbox' }}
            checked={
              type === 'exam' ? positiveValue : positiveValue || negativeValue
            }
            onChange={handlePositiveChange}
            disabled={isSaving}
          />
        )}
      </RadioColumn>
      {type === 'exam' && (
        <RadioColumn>
          {isLoading === 2 ? (
            <ExamCircularProgress />
          ) : (
            <Checkbox
              inputProps={{ alt: 'negativeCheckbox' }}
              checked={negativeValue}
              onChange={handleNegativeChange}
            />
          )}
        </RadioColumn>
      )}
    </>
  )
}

interface SystemRowProps {
  systomId: number;
  selectedValue: number;
  examResultFactorIds: ExamResultIdType;
  handleChange: any;
  type?: string;
  isSaving: boolean;
}
