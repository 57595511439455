import React from 'react'

import { styled, Typography } from '@mui/material'
import { ModalLayout } from '@/components/Charting/Editor/IQBar/modals/ModalLayout'
import { ModalButton } from '../../AddModals'

const DeleteVisitPopUpRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
})

export const DeleteTemplatePopup = ({
  name,
  setClose,
  handleDelete,
}: DeleteTemplatePopUpProps): JSX.Element => {
  return (
    <ModalLayout title="Confirm" handleCancel={setClose} width="300px">
      <Typography style={{ fontSize: '14px', marginBottom: '15px' }}>
        {`Are you sure you want to delete ${name}?`}
      </Typography>
      <DeleteVisitPopUpRow>
        <ModalButton variant="outlined" onClick={setClose}>
          No
        </ModalButton>
        <ModalButton variant="contained" onClick={handleDelete}>
          Yes
        </ModalButton>
      </DeleteVisitPopUpRow>
    </ModalLayout>
  )
}

interface DeleteTemplatePopUpProps {
  name: string;
  setClose: any;
  refreshTemplates: any;
  handleDelete: () => void;
}
