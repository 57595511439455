import { styled } from '@mui/material'
import React from 'react'

const IconBoxContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  size: '10%',
  position: 'relative',
})

export const IconBox = ({
  children,
  onMouseEnter,
  onMouseLeave,
}: IconBoxProps): JSX.Element => {
  return (
    <IconBoxContainer onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {children}
    </IconBoxContainer>
  )
}

interface IconBoxProps {
  children: JSX.Element | JSX.Element[] | undefined;
  onMouseEnter?: any;
  onMouseLeave?: any;
}
