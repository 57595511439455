import { styled } from '@mui/material'
import React from 'react'

const AddRowContainer = styled('div')(({ width }: AddColumnContainerProps) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  maxWidth: `${width}%`,
  minWidth: `${width}%`,
}))

export const AddColumn = ({ children, width }: AddColumnProps): JSX.Element => {
  return <AddRowContainer width={width}>{children}</AddRowContainer>
}

interface AddColumnProps {
  children?: JSX.Element | JSX.Element[];
  width: number;
}
interface AddColumnContainerProps {
  width: number;
}
