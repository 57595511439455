import { TitleRow } from '@/components/Charting/Editor/VitalsTile/styles'
import { IconButton } from '@mui/material'
import { useWatch, type Control } from 'react-hook-form'
import { TileTitleBox } from '..'
import { TileContainer } from '../styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import React from 'react'
import { TileTextArea } from '@/components/Charting'

export const StickyNotesTile = ({
  innerRef,
  control,
  isOpen,
  setIsOpen,
  setValue,
}: FinancialResponsiblePartyProps): JSX.Element => {
  const notesWatch = useWatch({ control, name: 'stickyNote' })
  const [notes, setNotes] = React.useState<string>(notesWatch)

  React.useEffect(() => {
    setValue('stickyNote', notes)
  }, [notes])

  React.useEffect(() => {
    setNotes(notesWatch)
  }, [notesWatch])

  return (
    <TileContainer id={'sticky-notes'} ref={innerRef} onMouseOver={() => {}}>
      <TitleRow>
        <TileTitleBox tileTitle="Patient Notes" />
        <IconButton
          size={'small'}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          {
            // prettier-ignore
            isOpen
              ? (<KeyboardArrowDownIcon fontSize='small' />)
              : (<KeyboardArrowRightIcon fontSize='small' />)
          }
        </IconButton>
      </TitleRow>
      {isOpen ? (
        <>
          <TileTextArea
            value={notes}
            onChange={(e: any) => setNotes(e.target.value)}
            fullsize
            dataTestId={'sticky-notes-input'}
          />
        </>
      ) : (
        <></>
      )}
    </TileContainer>
  )
}

interface FinancialResponsiblePartyProps {
  innerRef: React.RefObject<HTMLDivElement>;
  control: Control<any, any>;
  isOpen: boolean;
  setIsOpen: any;
  setValue: any;
}
