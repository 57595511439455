import React from 'react'
import { Stack, Typography, styled } from '@mui/material'
import { SecurityInput } from '@/components/SecurityInput/SecurityInput'
import { useAuth } from '@clerk/nextjs'
import { isEmpty } from '@/utility/utils'
import { type AccountUser } from '@/types'
import { useMutateUpdateAccountUserSelf } from '@/hook'
import { ModalLayout } from '../Charting/Editor/IQBar/modals/ModalLayout'
import { ModalButton } from '../Config'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'

const StyledLabel = styled(Typography)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '125px',
  fontSize: '14px',
})
const RedText = styled('span')({
  color: 'red',
  fontSize: '12px',
})

export default function ResetPasswordModal({
  user,
  setClose,
  setToastOpen,
  setToastMessage,
  setToastSeverity,
}: ResetPasswordModalProps): JSX.Element {
  const { getToken } = useAuth()
  const [oldPassword, setOldPassword] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [confirmPassword, setConfirmPassword] = React.useState<string>('')
  const [error, setError] = React.useState<string>('')

  const updateAccountUser = useMutateUpdateAccountUserSelf(getToken)

  const onConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (password !== event.target.value) {
      setError('The password does not match.')
    } else {
      setError('')
    }
    setConfirmPassword(event.target.value)
  }
  const onPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPassword(event.target.value)
  }
  const onOldPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setOldPassword(event.target.value)
  }
  const onResetPassword = (): void => {
    const { id: accountUserId } = user ?? {}
    if (!accountUserId) {
      setToastMessage('Could not find appropriate account id.')
      setToastOpen(true)
      setToastSeverity('error')
      return
    }
    if (password !== confirmPassword) {
      setToastMessage(
        'The password you entered does not match. Please check again!'
      )
      setToastOpen(true)
      setToastSeverity('error')
      return
    }
    const newUser = { ...user, password }
    updateAccountUser
      .mutateAsync({ accountUserId, user: newUser })
      .then(() => {
        setToastMessage('The password is updated successfully!')
        setToastOpen(true)
        setToastSeverity('success')
        setClose()
      })
      .catch((err) => {
        setToastMessage(err as string)
        setToastOpen(true)
        setToastSeverity('error')
      })
  }

  return (
    <ModalLayout title="Reset Password" handleCancel={setClose} width="unset">
      <Stack direction="row" spacing={2}>
        <AdminPanelSettingsIcon style={{ fontSize: 100, color: '#666' }} />
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            <StyledLabel>Old Password:</StyledLabel>
            <SecurityInput
              value={oldPassword}
              onChange={onOldPasswordChange}
              inputProps={{ 'data-testid': 'old-password-input' }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <StyledLabel>New Password:</StyledLabel>
            <SecurityInput
              value={password}
              onChange={onPasswordChange}
              inputProps={{ 'data-testid': 'new-password-input' }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <StyledLabel>Confirm Password:</StyledLabel>
            <SecurityInput
              value={confirmPassword}
              onChange={onConfirmPasswordChange}
              inputProps={{ 'data-testid': 'confirm-password-input' }}
            />
          </Stack>
          {!isEmpty(error) && (
            <RedText style={{ alignSelf: 'flex-end' }}>{error}</RedText>
          )}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        style={{ justifyContent: 'flex-end', marginTop: '15px' }}
      >
        <ModalButton variant="outlined" onClick={setClose}>
          Cancel
        </ModalButton>
        <ModalButton variant="contained" onClick={onResetPassword}>
          Reset
        </ModalButton>
      </Stack>
    </ModalLayout>
  )
}

interface ResetPasswordModalProps {
  user?: AccountUser;
  setClose: any;
  setToastOpen: any;
  setToastMessage: any;
  setToastSeverity: any;
}
