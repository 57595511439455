import { Autocomplete, styled, TextField } from '@mui/material'
import { TileInputLabel } from '../TileInputLabel'
import React from 'react'

const StyledTextField = styled(TextField)(
  ({ fullsize, twoColumns, customWidth }: StyledTextFieldProps) => ({
    width: customWidth? `${customWidth}px` : fullsize ? '766px' : twoColumns ? '506px' : '240px',
    height: '40px !important',
    '& > div': {
      borderRadius: 0,
      height: '40px',
      backgroundColor: 'white',
      '& > input': {
        marginTop: '-2px',
      },
    },
  })
)

export const TileSearchBox = ({
  id = 'tile-search-box',
  label = null,
  value = null,
  onChange,
  options,
  fullsize = false,
  twoColumns = false,
  onKeyDown,
  inputValue = null,
  setInputValue,
  dataTestId,
  fullWidthResults = false,
  disabled = false,
  defaultValue,
  customWidth
}: TileSearchBoxProps): JSX.Element => {
  const [internalInputValue, setInternalInputValue] =
    React.useState<string>('')
  React.useEffect(() => {
    if (value !== null) {
      if (setInputValue !== undefined) {
        if (value.label === null || value.label === undefined) {
          setInputValue(value)
        } else {
          setInputValue(value.label)
        }
      } else {
        setInternalInputValue(value.label)
      }
    }
  }, [value])

  const handleInputChange = (event: any): void => {
    if (event !== null && value !== null) {
      if (setInputValue !== undefined) {
        setInputValue(event.target.value)
      } else {
        setInternalInputValue(event.target.value)
      }
    }
  }
  const handleFilter = (
    options: Array<{ id: number; label: string }>
  ): any[] => {
    const input = (
      inputValue === null
        ? internalInputValue === undefined
          ? ''
          : internalInputValue
        : inputValue
    )?.toLocaleLowerCase()

    if (options.length < 250) {
      return options
        .filter((option) => option?.label?.toLocaleLowerCase().includes(input))
        .slice(0, 50)
    } else {
      if (input.length > 2) {
        return options
          .filter((option) => option.label?.toLocaleLowerCase().includes(input))
          .slice(0, 50)
      }
    }
    return []
  }
  return (
    <div>
      {label !== null && <TileInputLabel>{label}</TileInputLabel>}
      <Autocomplete
        id={id}
        disabled={disabled}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        value={value}
        onChange={onChange}
        options={options}
        getOptionLabel={(option) => option.label}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        inputValue={inputValue === null ? internalInputValue : inputValue}
        filterOptions={handleFilter}
        onInputChange={handleInputChange}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        noOptionsText="Enter a value to search"
        componentsProps={{
          paper: {
            style: fullWidthResults
              ? { width: '766px', marginTop: '6px', marginBottom: '6px' }
              : { marginTop: '6px', marginBottom: '6px' },
          },
        }}
        renderOption={(props, option) => (
          <li
            style={{ ...(fullWidthResults && { width: '766px ' }) }}
            {...props}
            data-testid={option.label}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <StyledTextField
            fullsize={fullsize}
            twoColumns={twoColumns}
            customWidth={customWidth}
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testId': dataTestId,
            }}
          />
        )}
      />
    </div>
  )
}
interface TileSearchBoxProps {
  id?: string;
  label?: string | null;
  /* prettier-ignore */
  value?: { id: number, label: string } | null
  onChange: any;
  /* prettier-ignore */
  options: Array<{ id: number, label: string }>
  fullsize?: boolean;
  twoColumns?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  inputValue?: string | null;
  setInputValue?: any | undefined;
  dataTestId: string;
  fullWidthResults?: boolean;
  disabled?: boolean;
  defaultValue?: { id: number; label: string };
  customWidth?: number;
}

interface StyledTextFieldProps {
  fullsize: boolean;
  twoColumns: boolean;
  customWidth?: number;
}
