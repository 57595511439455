import React from 'react'

import { useAuth, useUser } from '@clerk/nextjs'
import {
  Alert,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  styled,
} from '@mui/material'
import { format } from 'date-fns'
import { formatPhoneNumber } from 'react-phone-number-input'

import { PDFViewer } from '@/components/PDFViewer/PDFViewer'
import { useQueryGetClinicLetter } from '@/hook/useDocuments'
import { getDocument } from '@/services'
import { type Patient, type State, type UrgentIQDocument } from '@/types'
import { dateOnlyToDate, isEmpty } from '@/utility'

import { ModalLayout } from './ModalLayout'

const LetterContainer = styled('div')({
  display: 'flex',
  gap: '20px',
})

const ListBoxContainer = styled(List)({
  border: '1px solid lightgray',
  borderRadius: '12px',
  height: '80vh',
  overflowY: 'scroll',
  overflowX: 'hidden',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const FileListItem = styled(ListItemButton)(({ theme }) => ({
  cursor: 'pointer',
  width: '300px',
  '&.selected': {
    backgroundColor: theme.palette.action.selected,
  },
}))

const LoadingDiv = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60vh',
  width: '985px',
  borderRadius: '12px',
})

export const LettersModal = ({
  handleClose,
  visitId,
  patient,
  states,
  provider,
  dx,
}: LettersProps): JSX.Element => {
  const { user } = useUser()
  const { getToken } = useAuth()
  const {
    data: documents,
    isLoading,
    error,
  } = useQueryGetClinicLetter(getToken)
  const [filteredDocuments, setFilteredDocuments] = React.useState<
    UrgentIQDocument[]
  >([])
  const [nameState, setNameState] = React.useState<string>('')
  const nameStateRef = React.useRef(nameState)

  React.useEffect(() => {
    const filtered = documents?.documentPageList
    setFilteredDocuments(
      (filtered ?? []).sort((a, b) =>
        (a.visibleDocumentName ?? '').localeCompare(b.visibleDocumentName ?? '')
      )
    )
    if (filtered?.length) {
      handleFileClick(filtered[0])
    }
  }, [documents])

  const [selectedFile, setSelectedFile] = React.useState<UrgentIQDocument>({})

  const handleFileClick = React.useCallback(
    (fileName: UrgentIQDocument): void => {
      setNameState(fileName.visibleDocumentName ?? '')
      nameStateRef.current = fileName.visibleDocumentName ?? ''
      Promise.resolve(
        getDocument(fileName.id ?? 0, getToken).then((doc) => {
          setSelectedFile({ ...doc })
        })
      ).catch((err) => {
        throw err
      })
    },
    [getDocument, getToken]
  )

  const saveOtherDocuments = async (file: Blob): Promise<void> => {
    let id = ''

    if (user?.publicMetadata.account_user_id) {
      id = user.publicMetadata.account_user_id as string
    }

    const formData = new FormData()
    formData.append('file', file, `${nameStateRef.current}`)
    formData.append('patientId', `${patient.id ?? ''}`)
    formData.append('documentTypeId', '14')
    formData.append('visibleDocumentName', `${nameStateRef.current}`)
    formData.append('accountUserId', id)
    formData.append('visitId', `${visitId ?? ''}`)

    fetch(`${process.env.API_URL ?? ''}/api/Document`, {
      method: 'POST',
      headers: {
        origin: 'null',
        Authorization: `Bearer ${
          (await getToken({
            template: 'UrgentIQ',
          })) ?? ''
        }`,
      },
      body: formData,
    }).catch((error) => {
      throw error
    })
  }

  const formFields = {
    patientName: `${patient.firstName ?? ''} ${patient.lastName ?? ''}`,
    patientDOB_af_date: format(
      dateOnlyToDate(patient.dateOfBirth)!,
      'MM/dd/yyyy'
    ),
    patientEmail: patient.email ?? '',
    patientStreet: `${patient.street1 ?? ''} ${
      !isEmpty(patient.street2) ? `${patient.street2 ?? ''}` : ''
    }`,
    patientCityStateZip: `${patient.city ?? ''}, ${
      states.find((state: State) => state.id === patient.stateId)?.code ?? ''
    } ${patient.zipcode ?? ''}`,
    patientPhoneNumber: formatPhoneNumber(patient.phoneNumber ?? ''),
    date: format(new Date(Date.now()), 'MM/dd/yyyy'),
    provider,
    dx,
    patientDemographics: `${patient.firstName ?? ''} ${
      patient.lastName ?? ''
    }\n${patient.street1 ?? ''}\n${
      !isEmpty(patient.street2) ? `${patient.street2 ?? ''}\n` : ''
    }${patient.city ?? ''}, ${
      states.find((state: State) => state.id === patient.stateId)?.code ?? ''
    } ${patient.zipcode ?? ''}\n${formatPhoneNumber(
      patient.phoneNumber ?? ''
    )}\n${format(dateOnlyToDate(patient.dateOfBirth)!, 'MM/dd/yyyy')}`,
  }

  return (
    <ModalLayout
      title="Letters & Documents"
      handleCancel={handleClose}
      width={'1348px'}
    >
      {error ? (
        <Alert severity="error">
          Error loading documents, please try again and if this issue continues
          please contact support
        </Alert>
      ) : (
        <LetterContainer>
          {isLoading ? (
            <LoadingDiv>
              <CircularProgress />
            </LoadingDiv>
          ) : (
            <PDFViewer
              url={selectedFile.presignedUrl ?? ''}
              handleSave={saveOtherDocuments}
              formFieldsValues={formFields}
              name={nameStateRef.current}
            />
          )}
          <ListBoxContainer>
            {filteredDocuments.map((file) => {
              return (
                <FileListItem
                  key={file.id}
                  selected={file.id === selectedFile.id}
                  onClick={() => handleFileClick(file)}
                >
                  <ListItemText primary={file.visibleDocumentName} />
                </FileListItem>
              )
            })}
          </ListBoxContainer>
        </LetterContainer>
      )}
    </ModalLayout>
  )
}

interface LettersProps {
  handleClose: () => void;
  patient: Patient;
  states: State[];
  dx: string;
  provider: string;
  visitId: number;
}
