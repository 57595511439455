import React from 'react'
import { Tooltip, Chip, styled, useTheme } from '@mui/material'
import { lighten } from 'polished'

const StyledTag = styled(Chip)<StyledTagProps>`
  && {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${({ fontColor }) => fontColor ?? 'inherit'};
    background-color: ${({ bgColor }) => bgColor ?? 'inherit'};
    cursor: pointer;
    height: 30px;
    margin-right: 2px;

    & .MuiSvgIcon-root {
      color: #fff;
    }

    &:hover {
      background-color: ${({ bgColor }) =>
        bgColor ? lighten(0.2, bgColor) : 'inherit'};
    }
  }
`

export const TagWithTooltip = ({
  label,
  color,
  fontColor,
  onDelete,
}: TagWithTooltipProps): JSX.Element => {
  const theme = useTheme()
  const defaultColor = theme.palette.primary.main
  return (
    <Tooltip title={label} arrow>
      <StyledTag
        bgColor={color ?? defaultColor}
        fontColor={fontColor ?? '#fff'}
        label={label}
        onDelete={onDelete}
      />
    </Tooltip>
  )
}

interface StyledTagProps {
  bgColor: string;
  fontColor: string;
}

interface TagWithTooltipProps {
  label: string;
  color?: string;
  fontColor?: string;
  onDelete?: (event: any) => void;
}
