import React from 'react'
import { Checkbox, styled } from '@mui/material'
import {
  TileRow,
  TileColumn,
  TileSubtitle,
  NotesRow,
  AddTable,
  AllergyRow,
  TileSearchBox,
  HeaderPMH,
  Category,
} from '@/components'
import { useAllergenStore, useGlobalStore } from '@/hook'
import { type PatientAllergy } from '@/types'
import { isEmpty } from '@/utility/utils'

const CheckBoxRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const AllergyHistory = ({
  patientId,
  allergies,
  addAllergy,
  deleteAllergy,
  notes,
  setNotes,
  allergen,
  setAllergen,
  hasNKDA,
  setHasNKDA,
}: AllergyHistoryProps): JSX.Element => {
  const { allergens, allergyReactions } = useAllergenStore()
  const [inputValue, setInput] = React.useState<string>('')
  const [internalHasNKDA, setInternalHasNKDA] =
    React.useState<boolean>(hasNKDA)
  const [lastKeyDown, setLastKeyDown] = React.useState<string>('')
  const [reactionId, setReactionId] = React.useState<number | null>(null)
  const [otherReaction, setOtherReaction] = React.useState<string>('')
  const { setIsEditing } = useGlobalStore()

  React.useEffect(() => {
    setInternalHasNKDA(hasNKDA)
  }, [hasNKDA])

  React.useEffect(() => {
    setReactionId(null)
    setOtherReaction('')
  }, [patientId])

  React.useEffect(() => {
    if (inputValue || reactionId !== null || otherReaction) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
  }, [inputValue, reactionId, otherReaction])

  const handleAllergenChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
      code: string | null | undefined;
    } | null
  ): void => {
    if (newValue === null) {
      setAllergen({ id: 0, label: '', code: '' })
    } else {
      const newAllergen = {
        id: newValue.id ?? 0,
        label: newValue.label ?? '',
        code: newValue.code ?? '',
      }
      setAllergen(newAllergen)
      // add alergy when allergen is picked by keydown
      if (lastKeyDown === 'ArrowDown') {
        handleAddAllergy(newAllergen)
      }
    }
  }

  const handleReactionChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    if (newValue === null) {
      setReactionId(null)
      setOtherReaction('')
    } else if (typeof newValue === 'string') {
      setReactionId(null)
      setOtherReaction(newValue)
    } else {
      setReactionId(newValue.id ?? null)
      setOtherReaction(newValue.label ?? '')
    }
  }

  const handleAddAllergy = (allergenDefault: any = null): void => {
    let rxCUI = 0
    let codeType = 0
    const currentAllergen = allergenDefault || allergen
    if (!isEmpty(currentAllergen.code)) {
      codeType = Math.floor(currentAllergen.id / (1000 * 1000 * 1000 * 1000))
      rxCUI = Math.floor(
        currentAllergen.id - codeType * 1000 * 1000 * 1000 * 1000
      )
    }
    if (inputValue) {
      addAllergy({
        patientId,
        name: currentAllergen.label || inputValue,
        ...(codeType > 0 && {
          rxCUI,
          code: currentAllergen.code ?? '',
          codeType,
        }),
        ...(reactionId !== null ? { reactionId } : { otherReaction }),
      }).catch((error) => {
        throw error
      })
      setAllergen({ id: 0, label: '', code: '' })
      setReactionId(null)
      setOtherReaction('')
      setInput('')
      setIsEditing(false)
    }
  }

  const findAllergenName = (id: number): string => {
    const allergen = allergens.find((allergen) => allergen.id === id)
    return allergen?.name ?? ''
  }

  const handleEditAllergy = (allergy: PatientAllergy): void => {
    if (!isEmpty(allergy.name)) {
      setInput(allergy?.name ?? '')
      const matchedAllergen = allergens.find((a) => a.name === allergy.name)
      if (matchedAllergen) {
        const codeType =
          (matchedAllergen.codeType ?? 0) * 1000 * 1000 * 1000 * 1000
        const id = isEmpty(matchedAllergen.code)
          ? matchedAllergen.id ?? 0
          : codeType + parseInt(matchedAllergen.rxCUI ?? '0')
        setAllergen({
          id,
          label: matchedAllergen.name ?? '',
          code: matchedAllergen.code ?? '',
        })
      } else {
        setAllergen({ id: 0, label: allergy.name, code: '' })
      }
    }
    if (allergy.reactionId) {
      setReactionId(allergy.reactionId)
      const matchedReaction = allergyReactions.find(
        (r) => r.id === allergy.reactionId
      )
      setOtherReaction(matchedReaction?.displayValue ?? '')
    } else if (allergy.otherReaction) {
      setReactionId(null)
      setOtherReaction(allergy.otherReaction)
    } else {
      setReactionId(null)
      setOtherReaction('')
    }
    setIsEditing(true)
    handleDeleteAllergy(allergy)
  }

  const handleDeleteAllergy = (allergy: PatientAllergy): void => {
    deleteAllergy(allergy).catch((error) => {
      throw error
    })
  }

  const reactionOptions = React.useMemo(() => {
    return allergyReactions.map((reaction) => ({
      id: reaction.id ?? 0,
      label: reaction.displayValue ?? '',
    }))
  }, [allergyReactions])

  const buildConditions = (): JSX.Element => {
    return (
      <AddTable>
        {allergies.map((condition) => {
          return (
            <AllergyRow
              key={condition.id}
              allergy={condition}
              allergyName={
                isEmpty(condition.name)
                  ? findAllergenName(condition.id ?? 0)
                  : condition?.name?.split(' - d')[0] ?? ''
              }
              delete={handleDeleteAllergy}
              edit={handleEditAllergy}
              isAllergyMissing={
                !condition.codeType || !condition?.doseSpotAllergyId
              }
            />
          )
        })}
      </AddTable>
    )
  }
  /* prettier-ignore */
  const options = React.useMemo(() => {
    return allergens.filter((allergen) => {
      if (hasNKDA && !isEmpty(allergen.code)) {
        return false
      } else {
        return true
      }
    }).map((allergen) => {
      const codeType = (allergen.codeType ?? 0) * 1000 * 1000 * 1000 * 1000
      return {
        id: isEmpty(allergen.code) ? allergen.id ?? 0 : codeType + parseInt(allergen.rxCUI ?? '0'),
        label: allergen.name ?? '',
        code: allergen.code ?? ''
      }
    })
  }, [allergens, hasNKDA])

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInternalHasNKDA(event.target.checked)
    setHasNKDA(event.target.checked)
  }

  const buildAllergiesHistory = (): JSX.Element => {
    return (
      <>
        <TileRow key={'allergies'} id={'allergies'}>
          <TileSearchBox
            dataTestId="allergies-input"
            label={'Allergies'}
            value={allergen}
            inputValue={inputValue}
            setInputValue={setInput}
            onChange={(event: any, value: any) =>
              handleAllergenChange(event, value)
            }
            onKeyDown={(e) => setLastKeyDown(e.code)}
            options={options}
            fullsize
          />
        </TileRow>
        <TileRow key={'reaction'} id={'reaction'}>
          <TileSearchBox
            dataTestId="reaction"
            label={'Reaction'}
            value={
              reactionId !== null
                ? { id: reactionId, label: otherReaction }
                : { id: 0, label: otherReaction }
            }
            inputValue={otherReaction}
            setInputValue={setOtherReaction}
            onChange={handleReactionChange}
            options={reactionOptions}
            fullsize
            onKeyDown={(e) => setLastKeyDown(e.code)}
          />
        </TileRow>
      </>
    )
  }

  return (
    <Category>
      <TileRow>
        <TileColumn>
          <TileRow>
            <HeaderPMH>Allergy</HeaderPMH>
            <CheckBoxRow>
              <TileSubtitle>No Known Drug Allergies</TileSubtitle>
              <Checkbox
                checked={internalHasNKDA}
                onChange={handleCheckBoxChange}
                data-testid={'hasNKDA-checkbox'}
                sx={{ padding: 0, pl: 1 }}
              />
            </CheckBoxRow>
          </TileRow>
          {buildAllergiesHistory()}
          <NotesRow
            notes={notes}
            setNotes={setNotes}
            title={'Add Allergy'}
            handleAdd={() => handleAddAllergy()}
            expanded={!isEmpty(notes)}
          />
        </TileColumn>
      </TileRow>
      {
        /* prettier-ignore */
        allergies.length > 0
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              {/* prettier-ignore */}
              {buildConditions()}
              {/* prettier-ignore */}
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }
    </Category>
  )
}

interface AllergyHistoryProps {
  patientId: number;
  allergies: PatientAllergy[];
  addAllergy: (allergy: PatientAllergy) => Promise<void>;
  deleteAllergy: (allergy: PatientAllergy) => Promise<void>;
  notes: string;
  setNotes: (notes: string) => void;
  allergen: { id: number; label: string; code: string };
  setAllergen: any;
  hasNKDA: boolean;
  setHasNKDA: any;
}
