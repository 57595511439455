import { styled } from '@mui/material'
import React from 'react'

const CategoryContainer = styled('div')(() => ({
  paddingBottom: '20px',
}))

export const Category = ({ id = '', children }: TileRowProps): JSX.Element => {
  return <CategoryContainer id={id}>{children}</CategoryContainer>
}

interface TileRowProps {
  children:
    | JSX.Element
    | JSX.Element[]
    | undefined
    | null
    | Array<JSX.Element | undefined | null>;
  id?: string;
}
