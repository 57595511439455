import React from 'react'
import { styled, TextField } from '@mui/material'
import { TileInputLabel } from '@/components'
import { type FieldValues, type UseFormRegister } from 'react-hook-form'

const StyledTextField = styled(TextField)({
  width: '100%',
  maxHeight: '40px !important',
  '& > div': {
    borderRadius: 0,
    height: '40px',
    backgroundColor: 'white',
  },
})

export const EFaxTextBox = ({
  label,
  name,
  register,
  ...props
}: TileTextFieldProps): JSX.Element => {
  return (
    <div>
      <TileInputLabel>{label}</TileInputLabel>
      <StyledTextField {...register(name)} {...props} />
    </div>
  )
}

interface TileTextFieldProps {
  label: string;
  name: string;
  register: UseFormRegister<FieldValues>;
}
