import { Checkbox, styled } from '@mui/material'
import React from 'react'

const RadioColumn = styled('div')({
  display: 'flex',
  width: '75px',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
})

export const SystemRow = ({
  systomId,
  selectedValue,
  handleChange,
}: SystemRowProps): JSX.Element => {
  const [positveValue, setPositiveValue] = React.useState(selectedValue === 1)
  const [negativeValue, setNegativeValue] = React.useState(selectedValue === 2)
  const handlePositiveChange = (event: any): void => {
    setPositiveValue(event.target.checked)
    setNegativeValue(false)
  }
  const handleNegativeChange = (event: any): void => {
    setNegativeValue(event.target.checked)
    setPositiveValue(false)
  }
  React.useEffect(() => {
    if (positveValue) {
      handleChange(systomId, 1)
    } else if (negativeValue) {
      handleChange(systomId, 2)
    } else {
      handleChange(systomId, 3)
    }
  }, [positveValue, negativeValue])

  React.useEffect(() => {
    setPositiveValue(selectedValue === 1)
    setNegativeValue(selectedValue === 2)
  }, [selectedValue])

  return (
    <>
      <RadioColumn>
        <Checkbox
          inputProps={{ alt: 'positiveCheckbox' }}
          checked={positveValue}
          onChange={handlePositiveChange}
        />
      </RadioColumn>
      <RadioColumn>
        <Checkbox
          inputProps={{ alt: 'negativeCheckbox' }}
          checked={negativeValue}
          onChange={handleNegativeChange}
        />
      </RadioColumn>
    </>
  )
}

interface SystemRowProps {
  systomId: string;
  selectedValue: number;
  handleChange: (key: string, value: number) => void;
}
