/* eslint-disable @typescript-eslint/indent */
import React from 'react'
import { type TaskTableCellProps } from './TaskTableCellProps'
import { AssignAction, StyledTableText } from './styled'
import {
  Box,
  IconButton,
  MenuItem,
  Paper,
  Popover,
  Tooltip,
} from '@mui/material'
import { useAuth } from '@clerk/nextjs'
import { useMutateUpdateTask, useTasksStore } from '@/hook/useTasks'
import AddIcon from '@mui/icons-material/Add'
import { type AccountUser, type Task } from '@/types'
import { usePatientStore } from '@/hook'
import { getPatient } from '@/services'

const noName = '-'

export const PatientCell = ({ row, cell }: TaskTableCellProps): JSX.Element => {
  const { getToken } = useAuth()
  const [hoverAssignPatientId, setHoverAssignPatientId] =
    React.useState<number>(-1)
  const updateTaskMutation = useMutateUpdateTask(getToken)
  const { patients } = usePatientStore()
  const { taskPage } = useTasksStore()
  const [fullName, setFullName] = React.useState<string>(noName)
  const [menuOpen, setMenuOpen] = React.useState<boolean>(false)
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  )

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setMenuOpen(true)
    setMenuAnchorEl(event.currentTarget)
  }

  React.useEffect(() => {
    const task = taskPage?.items?.find(({ id }) => id === row?.original?.id)
    const patient = patients?.find(({ id }) => id === task?.patientId)
    // patient should be coming from the zustand store via the parent, but it only loads this
    // clinic's patients, so we need to fetch the patient if it's not in the store
    if (task?.patientId && !patient) {
      getPatient(task.patientId, getToken)
        .then((data) => {
          setFullName(`${data?.firstName ?? ''} ${data?.lastName ?? ''}`)
        })
        .catch((err): void => {
          console.error('failed to load patient: ', err)
        })
    } else {
      const fullName = patient
        ? `${patient?.firstName ?? ''} ${patient?.lastName ?? ''}`
        : noName
      setFullName(fullName)
    }
  }, [row, taskPage, patients])

  const handleUpdateTask = async (
    taskId: number,
    modification: Partial<Task>
  ): Promise<void> => {
    await updateTaskMutation.mutateAsync({
      id: taskId,
      task: modification,
    })
  }

  return (
    <AssignAction
      sx={{ width: '170px' }}
      className={row?.original?.id === hoverAssignPatientId ? 'hover' : ''}
      onClick={(e) => {
        e.stopPropagation()
        setHoverAssignPatientId(row?.original?.id)
      }}
    >
      <Tooltip title={fullName} arrow>
        <StyledTableText
          sx={{ fontSize: '15px', color: '#454545', width: '170px' }}
        >
          {fullName}
        </StyledTableText>
      </Tooltip>
      <>
        <Box className="btn-assign-provider-action">
          <IconButton onClick={(e) => handleMenuOpen(e)} size="large">
            <AddIcon
              style={{
                cursor: 'pointer',
                width: '17px',
                height: '17px',
                fill: 'rgb(38, 47, 98)',
              }}
            />
          </IconButton>
        </Box>
        <Popover
          open={menuOpen}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          anchorEl={menuAnchorEl}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          onClose={(ev: any): void => {
            setMenuOpen(false)
            setHoverAssignPatientId(-1)
            ev.stopPropagation()
          }}
        >
          <Paper
            sx={{
              width: '225px',
              border: '1px solid rgba(0, 0, 0, 0.12)',
              borderRadius: '10px',
            }}
          >
            {patients?.map((patient: AccountUser) => {
              return (
                <MenuItem
                  key={patient.id}
                  value={patient.id}
                  sx={{ fontSize: '14px' }}
                  onClick={() => {
                    handleUpdateTask(row?.original?.id, {
                      patientId: patient.id,
                    })
                      .catch((err: any) => {
                        throw err
                      })
                      .finally(() => setMenuOpen(false))
                  }}
                >
                  {patient
                    ? `${patient?.firstName ?? ''} ${patient?.lastName ?? ''}`
                    : noName}
                </MenuItem>
              )
            })}
          </Paper>
        </Popover>
      </>
    </AssignAction>
  )
}
