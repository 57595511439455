import React from 'react'
import {
  Select,
  Popup,
  Button,
  Input,
  Textarea,
  Radio,
  RadioGroup,
  Datepicker,
  SegmentedGroup,
  SegmentedItem,
} from '@mobiscroll/react'
import { type Patient } from '@/types'
import { TaskPatientSearch as PatientSearch } from '../TaskPatientSearch'
import {
  months,
  dayInputProps,
  monthInputProps,
  dateInputProps,
  responsivePopup,
  selectResponsive,
} from './AppointmentUtils'
import { useScheduleState } from '@/context/SchedulingContext'
import {
  useClinicStore,
  useQueryGetAccountUsers,
  useQueryGetVisitTypes,
} from '@/hook'
import { useAuth } from '@clerk/nextjs'
import { Typography } from '@mui/material'
import { isEmpty } from '@/utility'

export const AppointmentScheduler: React.FC = () => {
  const { clinicId } = useClinicStore()
  const { getToken } = useAuth()
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const { data: clinicians } = useQueryGetAccountUsers(getToken)

  const {
    headerText,
    anchor,
    submitError,
    popupButtons,
    isOpen,
    onPopupClose,
    currentPatient,
    setCurrentPatient,
    popupEventNotes,
    descriptionChange,
    visitTypeChange,
    popupEventVisitType,
    providerChange,
    popupEventProvider,
    setOpenQRCode,
    popupEventHasQRCode,
    statusChange,
    popupEventStatus,
    startRef,
    endRef,
    start,
    end,
    dateChange,
    popupEventDate,
    selectedRepeat,
    repeatChange,
    repeatType,
    repeatTypeChange,
    repeatNr,
    repeatNrChange,
    monthlyDays,
    monthlyDay,
    monthlyDayChange,
    yearlyDays,
    yearlyDay,
    yearlyDayChange,
    selectedMonth,
    monthsChange,
    weekDays,
    weekDayChange,
    condition,
    conditionChange,
    untilDate,
    untilDateChange,
    occurrences,
    occurrencesChange,
    isEdit,
    onDeleteClick,
    repeatData,
    controls,
    respSetting,
  } = useScheduleState()

  const buildProviders = (): Array<{ text: string; value: number }> => {
    const providerNames = (clinicians ?? [])
      .filter((clinician) => !isEmpty(clinician.npiNumber))
      .map((clinician) => {
        return {
          text: `${clinician.firstName!} ${clinician.lastName!}`,
          value: clinician.id!,
        }
      })
    return providerNames as Array<{ text: string; value: number }>
  }

  return (
    <Popup
      display="anchored"
      contentPadding={false}
      fullScreen={true}
      theme="material"
      scrollLock={false}
      height={580}
      headerText={headerText}
      anchor={anchor}
      buttons={popupButtons}
      isOpen={isOpen}
      onClose={onPopupClose}
      responsive={responsivePopup}
      cssClass="md-recurring-event-editor-popup"
    >
      <div
        className="mbsc-material-theme"
        style={{ marginBottom: '20px', border: 'none', cursor: 'pointer' }}
      >
        <PatientSearch
          initialPatientId={currentPatient?.id ?? null}
          handleChange={(patient: Patient) => setCurrentPatient(patient)}
          onClearTile={() => {}}
        />
        {submitError && (
          <Typography sx={{ fontSize: '10px', color: 'red' }}>
            {submitError}
          </Typography>
        )}
      </div>
      <Textarea
        style={{ cursor: 'pointer' }}
        label="Notes"
        value={popupEventNotes}
        responsive={selectResponsive}
        onChange={descriptionChange}
        startIcon="line-note"
        endIcon="pencil"
      />
      <Select
        data={
          visitTypes?.map((item) => ({
            text: item.name ?? '',
            value: item.id ?? 0,
          })) ?? []
        }
        label="Visit Type"
        value={popupEventVisitType}
        responsive={selectResponsive}
        onChange={visitTypeChange}
        startIcon="heart2"
      />
      <Select
        data={buildProviders()}
        label="Provider"
        value={popupEventProvider}
        responsive={selectResponsive}
        onChange={providerChange}
        startIcon="office"
      />
      {!popupEventHasQRCode && (
        <Select
          data={[
            { value: 0, text: 'Pending' },
            { value: 1, text: 'Confirmed' },
            { value: 2, text: 'Booked' },
            { value: 3, text: 'Cancelled' },
            { value: 4, text: 'No Show' },
          ]}
          label="Status"
          value={popupEventStatus}
          responsive={selectResponsive}
          onChange={statusChange}
          startIcon="tag"
        />
      )}
      <Input
        style={{ cursor: 'pointer' }}
        ref={startRef}
        responsive={selectResponsive}
        label="Starts"
        startIcon="calendar"
        endIcon="pencil"
      />
      <Input
        style={{ cursor: 'pointer' }}
        ref={endRef}
        responsive={selectResponsive}
        label="Ends"
        startIcon="calendar"
        endIcon="pencil"
      />
      <Datepicker
        select="range"
        controls={controls}
        touchUi={true}
        startInput={start}
        endInput={end}
        showRangeLabels={false}
        responsive={respSetting}
        onChange={dateChange}
        value={popupEventDate}
        stepMinute={5}
      />
      <Select
        data={repeatData}
        label="Repeats"
        value={selectedRepeat}
        responsive={selectResponsive}
        onChange={repeatChange}
        startIcon="loop2"
      />
      <div className="mbsc-material-theme">
        {(selectedRepeat === 'custom' || selectedRepeat === 'custom-value') && (
          <div>
            <SegmentedGroup onChange={repeatTypeChange}>
              <SegmentedItem value="daily" checked={repeatType === 'daily'}>
                Daily
              </SegmentedItem>
              <SegmentedItem value="weekly" checked={repeatType === 'weekly'}>
                Weekly
              </SegmentedItem>
              <SegmentedItem value="monthly" checked={repeatType === 'monthly'}>
                Monthly
              </SegmentedItem>
              <SegmentedItem value="yearly" checked={repeatType === 'yearly'}>
                Yearly
              </SegmentedItem>
            </SegmentedGroup>

            <div className="md-recurrence-options">
              <span>Repeat every</span>
              <span className="md-recurrence-input md-recurrence-input-nr">
                <Input
                  min="1"
                  value={repeatNr}
                  onChange={repeatNrChange}
                  inputStyle="outline"
                />
              </span>
              {repeatType === 'daily' && <span>days</span>}
              {repeatType === 'weekly' && <span>weeks</span>}
              {repeatType === 'monthly' && (
                <span>
                  month(s) on day
                  <span className="md-recurrence-input md-recurrence-input-nr">
                    <Select
                      data={monthlyDays}
                      value={monthlyDay}
                      onChange={monthlyDayChange}
                      inputProps={dayInputProps}
                    />
                  </span>
                </span>
              )}
              {repeatType === 'yearly' && (
                <span>
                  year(s) <br />
                  on day
                  <span className="md-recurrence-input md-recurrence-input-nr">
                    <Select
                      data={yearlyDays}
                      value={yearlyDay}
                      onChange={yearlyDayChange}
                      inputProps={dayInputProps}
                    />
                  </span>
                  <span>of</span>
                  <span className="md-recurrence-input">
                    <Select
                      data={months}
                      value={selectedMonth}
                      onChange={monthsChange}
                      inputProps={monthInputProps}
                    />
                  </span>
                </span>
              )}

              {repeatType === 'daily' && (
                <p className="md-recurrence-desc">
                  The event will be repeated every day or every x days,
                  depending on the value
                </p>
              )}
              {repeatType === 'weekly' && (
                <p className="md-recurrence-desc">
                  The event will be repeated every x weeks on specific weekdays
                </p>
              )}
              {repeatType === 'monthly' && (
                <p className="md-recurrence-desc">
                  The event will be repeated every x month on specific day of
                  the month
                </p>
              )}
              {repeatType === 'yearly' && (
                <p className="md-recurrence-desc">
                  The event will be repeated every x years on specific day of a
                  specific month
                </p>
              )}
            </div>

            {repeatType === 'weekly' && (
              <SegmentedGroup select="multiple" onChange={weekDayChange}>
                <SegmentedItem value="SU" checked={weekDays.includes('SU')}>
                  Sun
                </SegmentedItem>
                <SegmentedItem value="MO" checked={weekDays.includes('MO')}>
                  Mon
                </SegmentedItem>
                <SegmentedItem value="TU" checked={weekDays.includes('TU')}>
                  Tue
                </SegmentedItem>
                <SegmentedItem value="WE" checked={weekDays.includes('WE')}>
                  Wed
                </SegmentedItem>
                <SegmentedItem value="TH" checked={weekDays.includes('TH')}>
                  Thu
                </SegmentedItem>
                <SegmentedItem value="FR" checked={weekDays.includes('FR')}>
                  Fri
                </SegmentedItem>
                <SegmentedItem value="SA" checked={weekDays.includes('SA')}>
                  Sat
                </SegmentedItem>
              </SegmentedGroup>
            )}

            <div className="md-recurrence-ends">Ends</div>

            <div className="mbsc-form-group">
              <RadioGroup>
                <Radio
                  label="Never"
                  position="start"
                  description="The event will repeat indefinitely"
                  checked={condition === 'never'}
                  onChange={conditionChange}
                  value="never"
                />
                <Radio
                  checked={condition === 'until'}
                  onChange={conditionChange}
                  value="until"
                  position="start"
                >
                  Until
                  <span className="mbsc-description">
                    The event will run until it reaches a specific date
                  </span>
                </Radio>
                <Radio
                  checked={condition === 'count'}
                  onChange={conditionChange}
                  value="count"
                  position="start"
                >
                  After X occurances
                  <span className="mbsc-description">
                    The event will repeat until it reaches a certain amount of
                    occurrences
                  </span>
                </Radio>
              </RadioGroup>
            </div>
            {condition === 'until' && (
              <Datepicker
                inputProps={dateInputProps}
                controls={['calendar']}
                display="anchored"
                touchUi={false}
                dateFormat="YYYY-MM-DD"
                returnFormat="iso8601"
                value={untilDate}
                onChange={untilDateChange}
              />
            )}
            {condition === 'count' && (
              <Input
                inputStyle="outline"
                value={occurrences}
                onChange={occurrencesChange}
              />
            )}
          </div>
        )}
        {isEdit && !popupEventHasQRCode && (
          <div className="mbsc-button-group">
            <Button
              className="mbsc-button-block"
              color="danger"
              variant="outline"
              onClick={onDeleteClick}
            >
              Delete event
            </Button>
          </div>
        )}
        {popupEventHasQRCode && (
          <Button
            className="mbsc-button-block"
            onClick={() => {
              setOpenQRCode(true)
              onPopupClose()
            }}
          >
            View QR Code
          </Button>
        )}
      </div>
    </Popup>
  )
}
