import React, { type RefObject } from 'react'
import { Button, IconButton, styled } from '@mui/material'
import { useAuth } from '@clerk/nextjs'
import {
  type Control,
  type FieldValues,
  type UseFormRegister,
} from 'react-hook-form'
import { TileTitleBox, TitleCheckBox } from '@/components'
import { InsuranceInfoRow } from './InsuranceInfoRow'
import { type PVerifyInsurance } from '@/types'
import DeleteIcon from '@mui/icons-material/Delete'
import { useQueryGetInsuranceType } from '@/hook'

const InsuranceContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '812px',
  padding: '24px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  gap: 8,
  border: '1px solid #CDD0E3',
  scrollMarginTop: '100px',
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
}))

const StyleTextButton = styled(Button)({
  width: '200px',
  fontWeight: '600',
  border: 'none',
})

const TitleRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
})
const SelfPayVerifyRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const InsuranceTile = ({
  innerRef,
  control,
  register,
  primaryFile,
  setPrimaryFile,
  secondaryFile,
  setSecondaryFile,
  TertiaryFile,
  setTertiaryFile,
  onFocus,
  insuranceCount,
  setInsuranceCount,
  setValue,
  selfPay,
  setSelfPay,
  verifyInsurance,
  errors,
  insuranceEligibility,
  insuranceNames,
  isVerifying,
  reset,
  primaryFullResponse,
  secondaryFullResponse,
  tertiaryFullResponse,
}: InsuranceProps): JSX.Element => {
  const { getToken } = useAuth()
  const { data: insuranceTypes } = useQueryGetInsuranceType(getToken)

  const handleVerifyInsurance = (): void => {
    verifyInsurance('Primary')
    if (insuranceCount >= 2) {
      verifyInsurance('Secondary')
    }
    if (insuranceCount >= 3) {
      verifyInsurance('Tertiary')
    }
  }
  const [internalPrimaryFile, setInternalPrimaryFile] = React.useState<
    Blob | undefined
  >()
  const [internalSecondaryFile, setInternalSecondaryFile] = React.useState<
    Blob | undefined
  >()
  const [internalTertiaryFile, setInternalTertiaryFile] = React.useState<
    Blob | undefined
  >()

  React.useEffect(() => {
    if (primaryFile !== null) {
      setInternalPrimaryFile(primaryFile)
    }
  }, [primaryFile])

  React.useEffect(() => {
    if (secondaryFile !== null) {
      setInternalSecondaryFile(secondaryFile)
    }
  }, [secondaryFile])

  React.useEffect(() => {
    if (TertiaryFile !== null) {
      setInternalTertiaryFile(TertiaryFile)
    }
  }, [TertiaryFile])

  return (
    <div>
      {/* First Insurance */}
      {
        <InsuranceContainer ref={innerRef} onMouseOver={onFocus}>
          <TitleRow>
            <TileTitleBox tileTitle="Primary Insurance" />
            <SelfPayVerifyRow>
              <TitleCheckBox
                title="Self Pay"
                value={selfPay}
                setValue={setSelfPay}
                data-testid="self-pay-checkbox"
              />
            </SelfPayVerifyRow>
          </TitleRow>
          {!selfPay && (
            <InsuranceInfoRow
              data-testid="insurance-info-row"
              control={control}
              register={register}
              idFile={internalPrimaryFile}
              setPhotoFile={setPrimaryFile}
              insuranceType={'Primary'}
              setValue={setValue}
              insuranceTypes={insuranceTypes!}
              errors={errors}
              insuranceEligibility={insuranceEligibility}
              verifyInsurance={handleVerifyInsurance}
              insuranceNames={insuranceNames}
              isIntake
              isVerifying={isVerifying}
              reset={reset}
              fullResponse={primaryFullResponse}
            />
          )}
        </InsuranceContainer>
      }
      {/* Second Insurance */}
      {insuranceCount <= 1 && !selfPay && (
        <StyleTextButton
          variant="text"
          sx={{ marginTop: '16px', width: '320px' }}
          onClick={() => setInsuranceCount(2)}
        >
          Add Secondary Insurance
        </StyleTextButton>
      )}

      {insuranceCount >= 2 && !selfPay && (
        <InsuranceContainer
          ref={innerRef}
          onMouseOver={onFocus}
          style={{ marginTop: '16px' }}
        >
          <TitleRow>
            <TileTitleBox tileTitle="Secondary Insurance" />
            {insuranceCount <= 2 && (
              <IconButton onClick={() => setInsuranceCount(0)} size="large">
                <DeleteIcon />
              </IconButton>
            )}
          </TitleRow>
          {insuranceCount >= 2 && (
            <InsuranceInfoRow
              data-testid="insurance-info-row"
              control={control}
              register={register}
              idFile={internalSecondaryFile}
              setPhotoFile={setSecondaryFile}
              insuranceType={'Secondary'}
              setValue={setValue}
              insuranceTypes={insuranceTypes!}
              insuranceNames={insuranceNames}
              errors={errors}
              isIntake
              isVerifying={isVerifying}
              reset={reset}
              fullResponse={secondaryFullResponse}
            />
          )}
        </InsuranceContainer>
      )}

      {/* Third Insurance */}

      {insuranceCount === 2 && !selfPay && (
        <StyleTextButton
          variant="text"
          sx={{ marginTop: '16px', width: '320px' }}
          onClick={() => setInsuranceCount(3)}
        >
          Add Tertiary Insurance
        </StyleTextButton>
      )}

      {insuranceCount >= 3 && !selfPay && (
        <InsuranceContainer
          ref={innerRef}
          onMouseOver={onFocus}
          style={{ marginTop: '16px' }}
        >
          <TitleRow>
            <TileTitleBox tileTitle="Tertiary Insurance" />
            {insuranceCount <= 3 && (
              <IconButton onClick={() => setInsuranceCount(1)} size="large">
                <DeleteIcon />
              </IconButton>
            )}
          </TitleRow>
          {insuranceCount >= 2 && (
            <InsuranceInfoRow
              data-testid="insurance-info-row"
              control={control}
              register={register}
              idFile={internalTertiaryFile}
              setPhotoFile={setTertiaryFile}
              insuranceType={'Tertiary'}
              setValue={setValue}
              insuranceTypes={insuranceTypes!}
              insuranceNames={insuranceNames}
              errors={errors}
              isIntake
              isVerifying={isVerifying}
              reset={reset}
              fullResponse={tertiaryFullResponse}
            />
          )}
        </InsuranceContainer>
      )}
    </div>
  )
}
interface InsuranceProps {
  innerRef: RefObject<HTMLDivElement>;
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  primaryFile: Blob | undefined;
  setPrimaryFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  secondaryFile: Blob | undefined;
  setSecondaryFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  TertiaryFile: Blob | undefined;
  setTertiaryFile: React.Dispatch<React.SetStateAction<Blob | undefined>>;
  onFocus: any;
  insuranceCount: number;
  setInsuranceCount: any;
  setValue: any;
  selfPay: boolean;
  setSelfPay: any;
  verifyInsurance: any;
  errors?: any;
  visitType?: number;
  insuranceEligibility?: any;
  insuranceNames: PVerifyInsurance[];
  isVerifying: boolean;
  reset: boolean;
  primaryFullResponse?: string;
  secondaryFullResponse?: string;
  tertiaryFullResponse?: string;
}
