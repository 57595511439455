import React from 'react'
import { Radio, RadioGroup, styled, useTheme } from '@mui/material'
import { StyledTextButton, StyledTextField } from '../FreeTextNotes/styles'
import { TileInputLabel } from '../components/TileInputLabel'
import { isEmpty } from '@/utility/utils'

const MDMRowContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const MDMRowRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '776px',
})

const TileRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '502px',
  gap: '2px',
})
const StyledRadio = styled(Radio)(({ theme, required, value }) => ({
  color: required && value === 5 ? 'red' : '#A7A7A7',
}))

export const MDMRow = ({
  text,
  value,
  setValue,
  note,
  setNote,
  required,
  handleWarn,
}: MDMRowProps): JSX.Element => {
  const [isNoteVisible, setIsNoteVisible] = React.useState(false)
  const theme = useTheme()

  React.useEffect(() => {
    if (!isEmpty(note)) {
      setIsNoteVisible(true)
    }
  }, [note])

  return (
    <MDMRowContainer>
      <MDMRowRow>
        <TileRow>
          <TileInputLabel>{text}</TileInputLabel>
        </TileRow>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          {[1, 2, 3, 4].map((val) => (
            <StyledRadio
              key={val}
              value={value}
              checked={value === val}
              onChange={() => handleWarn(setValue, val)}
              data-testid={`${text}${val}`}
              required={required} // Assuming you have a way to determine if the radio selection is required
              // The `value` prop on StyledRadio is not automatically passed down from the MUI Radio component. You need to manually handle it if it's necessary for the styling logic.
            />
          ))}
        </RadioGroup>
        <StyledTextButton
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            textAlign: 'left',
            textDecorationLine: 'underline',
            color: theme.palette.primary.main,
          }}
          data-testid={`${text}NoteButton`}
          variant="text"
          onClick={() => setIsNoteVisible(!isNoteVisible)}
        >
          {isNoteVisible
            ? 'Hide Note'
            : isEmpty(note)
            ? 'Add Note'
            : 'Edit Note'}
        </StyledTextButton>
      </MDMRowRow>
      {
        /* prettier-ignore */
        isNoteVisible
          ? (
            /* prettier-ignore */
            <MDMRowRow>
              <StyledTextField
                onChange={e => handleWarn(setNote, e.target.value)}
                value={note}
                minRows={5}
                sx={{ width: '716px' }}
                data-testid={`${text}Note`}
              />
            </MDMRowRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : (
            /* prettier-ignore */
            <></>
            /* prettier-ignore */
            )
      }
    </MDMRowContainer>
  )
}

interface MDMRowProps {
  text: string;
  tooltipText: string;
  value: number;
  setValue: (value: number) => void;
  note: string;
  setNote: (note: string) => void;
  required: boolean;
  handleWarn: any;
}
