import { IconButton, styled, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import React from 'react'
import { values } from './values'
import { TileSection } from './TileSection'
import { type Complaint } from '@/types'
import { useAuth } from '@clerk/nextjs'
import { useQueryGetComplaints } from '@/hook'

const HeaderTile = styled(Typography)({
  size: '16px',
  weight: 'semibold',
  color: 'black',
})

const IQPanelContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  gap: '16px',
})
const HeaderRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  alignItems: 'center',
})
export const IQPanel = ({
  handleAdd,
  handleClose,
  complaints,
}: IQPanelProps): JSX.Element => {
  const { getToken } = useAuth()
  const { data: ccs } = useQueryGetComplaints(getToken)
  interface DataObject {
    field_name?: string;
    value?: string;
    value_id?: number;
    result_factor?: string;
    exam_system_id?: number;
    exam_system_name?: string;
    ros_system_id?: number;
    ros_system_name?: string;
    code?: string;
    category?: string;
  }
  const [examSystems, setExamSystem] = React.useState<DataObject[]>([])
  const [rOSSystems, setROSSystem] = React.useState<DataObject[]>([])
  const [emCodes, setEmCodes] = React.useState<DataObject[]>([])

  const handleCCAdd = (value: number, name: string): void => {
    const subArrays = values.filter((subArray) => {
      // Assert that the first item in the subarray is an object
      const firstItem = subArray[0] as { value_id: number; [key: string]: any }
      return firstItem.value_id === value
    })
    const data: any[] = []
    subArrays.forEach((subArray) => {
      data.push(subArray[2])
    })
    setROSSystem(
      data.filter(
        (item: any): item is DataObject =>
          typeof item === 'object' &&
          item.field_name === 'ReviewOfSystemSymptom'
      )
    )
    setExamSystem(
      data.filter(
        (item: any): item is DataObject =>
          typeof item === 'object' && item.field_name === 'ExamSystemComponent'
      )
    )
    setEmCodes(
      data.filter(
        (item: any): item is DataObject =>
          typeof item === 'object' && item.category === 'E/M'
      )
    )
  }
  const handleExamAdd = (value: number, name: string): void => {
    handleAdd('Exam', value)
    handleCCAdd(value, 'ExamSystemComponent')
  }
  const handleROSAdd = (value: number, name: string): void => {
    handleAdd('ReviewOfSystems', value)
    handleCCAdd(value, 'ReviewOfSystemSymptom')
  }
  const handleEmAdd = (value: number, name: string): void => {
    handleAdd('E/M', value)
    handleCCAdd(value, 'E/M')
  }
  return (
    <IQPanelContainer>
      <HeaderRow>
        <HeaderTile>IQ Panel</HeaderTile>
        <IconButton onClick={handleClose} size="large">
          <ClearIcon />
        </IconButton>
      </HeaderRow>
      <TileSection
        sectionName="Chief Complaint"
        values={complaints.map((cc) => {
          const ccObject = ccs?.find((c: Complaint) => c.id === cc) ?? {
            name: '',
            id: 0,
          }
          return {
            name: ccObject.name ?? '',
            id: ccObject.id ?? 0,
          }
        })}
        name="ChiefComplaint"
        handleAdd={handleCCAdd}
      />
      {rOSSystems.length > 0 && (
        <TileSection
          sectionName="Review of System"
          values={rOSSystems.map((ros) => {
            return {
              name: `${ros.ros_system_name ?? ''}: ${ros.value ?? ''}`,
              id: ros.value_id ?? 0,
            }
          })}
          handleAdd={handleROSAdd}
          textBlocks
          name="ReviewOfSystemSymptom"
        />
      )}
      {examSystems.length > 0 && (
        <TileSection
          sectionName="Exam"
          values={examSystems.map((ros) => {
            return {
              name: `${ros.exam_system_name ?? ''}: ${ros.value ?? ''}`,
              id: ros.value_id ?? 0,
            }
          })}
          handleAdd={handleExamAdd}
          textBlocks
          name="ExamSystemComponent"
        />
      )}
      {emCodes.length > 0 && (
        <TileSection
          sectionName="E/M Code"
          values={emCodes.map((ros) => {
            return {
              name: `${ros.code ?? ''}: ${ros.value ?? ''}`,
              id: ros.value_id,
            }
          })}
          handleAdd={handleEmAdd}
          name="E/M"
        />
      )}
    </IQPanelContainer>
  )
}

interface IQPanelProps {
  handleAdd: any;
  handleClose: any;
  complaints: number[];
}
