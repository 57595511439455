import React from 'react'
import { styled, TextareaAutosize } from '@mui/material'
import { TileInputLabel } from '@/components'
import { type FieldValues, type UseFormRegister } from 'react-hook-form'

const StyledTextField = styled(TextareaAutosize)(({ theme }) => ({
  fontFamily: 'Lato',
  width: '100%',
  maxWidth: '100%',
  color: '#222222',
  fontWeight: '500',
  fontSize: '16px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '6px',
  borderColor: '#DDDFE8',
  borderStyle: 'solid',
  borderWidth: '1px',
  paddingLeft: '10px',
  '&:hover': {
    borderColor: 'black',
  },
  '&:focus': {
    borderColor: '#0543B0',
    borderWidth: '2px',
    outline: 'none',
  },
}))

export const EFaxTextArea = ({
  label,
  name,
  register,
  ...props
}: TileTextFieldProps): JSX.Element => {
  return (
    <div>
      <TileInputLabel>{label}</TileInputLabel>
      <StyledTextField {...register(name)} {...props} maxRows={6} minRows={6} />
    </div>
  )
}

interface TileTextFieldProps {
  label: string;
  name: string;
  register: UseFormRegister<FieldValues>;
}
