import { PatientVisits } from '@/components/PatientProfile/PatientVisits'
import React from 'react'
import { type Patient } from '@/types'
import { ModalLayout } from './ModalLayout'

export const VisitsModal = ({
  patient,
  handleClose,
}: VisitsProps): JSX.Element => {
  return (
    <ModalLayout title="Visits" handleCancel={handleClose} width={'1028px'}>
      <PatientVisits patient={patient} />
    </ModalLayout>
  )
}

interface VisitsProps {
  patient: Patient;
  handleClose: () => void;
}
