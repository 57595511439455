import React from 'react'
import { Stack, Paper, styled } from '@mui/material'

const DashboardTopContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
  alignItems: 'center',
  alignContent: 'center',
  backgroundColor: '#EAF3FF',
  padding: '20px',
})
const StyledPaper = styled(Paper)(({ theme }) => ({
  elevation: 0,
  backgroundColor: theme.palette.background.default,
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  padding: '14px 30px',
  display: 'flex',
  borderRadius: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
}))
const CardTitle = styled('div')(({ theme }) => ({
  display: 'flex',
  fontSize: '16px',
  lineHeight: '19px',
  color: theme.palette.text.primary,
  alignItems: 'center',
}))
const TimeCard = styled('div')(({ theme }) => ({
  padding: '0 10px',
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  alignItems: 'baseline',
  borderRadius: '8px',
  marginLeft: '15px',
  height: 'fit-content',
  fontWeight: 700,
}))
const TimeAmount = styled('div')(({ theme }) => ({
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '44px',
  textAlign: 'center',
  letterSpacing: '-0.03em',
  color: theme.palette.primary.main,
  marginRight: '5px',
}))

export const DashboardTopPane = ({
  waitingsCount,
  openChartCount,
  setSelectedTab,
  averageCurrentPatientTimeInClinic,
}: DashboardTopContainerProps): JSX.Element => {
  return (
    <DashboardTopContainer>
      <Stack direction={'row'} style={{ width: '100%' }} spacing={4}>
        <StyledPaper
          elevation={0}
          onClick={() => setSelectedTab('Waiting Room')}
          sx={{ flex: '1' }}
        >
          <CardTitle>Patients in Waiting Room</CardTitle>
          <TimeCard>
            <TimeAmount>{waitingsCount}</TimeAmount>
          </TimeCard>
        </StyledPaper>
        <StyledPaper
          elevation={0}
          sx={{ flex: '1' }}
          onClick={() => setSelectedTab('Exam Started')}
        >
          <CardTitle>Open Charts</CardTitle>
          <TimeCard>
            <TimeAmount>{openChartCount}</TimeAmount>
          </TimeCard>
        </StyledPaper>
        <StyledPaper elevation={0} sx={{ flex: '1' }}>
          <CardTitle>Current Patients Avg Time in Clinic</CardTitle>
          <TimeCard>
            <TimeAmount>
              {Math.floor(averageCurrentPatientTimeInClinic)}
            </TimeAmount>
            min
          </TimeCard>
        </StyledPaper>
      </Stack>
    </DashboardTopContainer>
  )
}

interface DashboardTopContainerProps {
  waitingsCount: number;
  openChartCount: number;
  setSelectedTab: any;
  averageCurrentPatientTimeInClinic: number;
}
