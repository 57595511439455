import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Popover } from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'
import {
  RowTile,
  RowValue,
  AddColumn,
  AddRow,
  AllergyHoverOver,
} from '@/components'
import { type PatientAllergy } from '@/types'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export const AllergyRow = ({
  allergy,
  allergyName,
  delete: deleteDX,
  edit: editDX,
  isAllergyMissing = false,
}: AllergyRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <AllergyHoverOver
          name={allergyName}
          reaction={allergy.reaction ?? ''}
          isAllergyMissing={isAllergyMissing}
        />
      </Popover>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={62}>
          <RowValue>{allergyName}</RowValue>
        </AddColumn>
        <AddColumn width={25}>
          <RowTile>Reaction</RowTile>
          <RowValue maxWidth={'65%'}>{allergy.reaction ?? ''}</RowValue>
        </AddColumn>
        {isAllergyMissing ? (
          <AddColumn width={4}>
            <IconButton color="warning" size="large">
              <WarningAmberIcon />
            </IconButton>
          </AddColumn>
        ) : (
          <AddColumn width={4}>{}</AddColumn>
        )}
        <AddColumn width={4}>
          <IconButton onClick={() => editDX(allergy)} size="large">
            <ModeIcon />
          </IconButton>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => deleteDX(allergy)} size="large">
            <DeleteIcon />
          </IconButton>
        </AddColumn>
      </AddRow>
    </>
  )
}
interface AllergyRowProps {
  allergy: PatientAllergy;
  allergyName: string;
  delete: (allergy: PatientAllergy) => void;
  edit: (allergy: PatientAllergy) => void;
  isAllergyMissing?: boolean;
}
