import React from 'react'
import { TextField, styled, IconButton } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const SecurityInputContainer = styled('div')((): any => ({
  position: 'relative',
}))
const StyledIconButton = styled(IconButton)((): any => ({
  position: 'absolute',
  right: 1,
}))

export function SecurityInput({
  value,
  inputProps = {},
  onChange,
}: SecurityInputProps): JSX.Element {
  const [isVisible, setIsVisible] = React.useState<boolean>(false)
  const onToggle = (): void => {
    setIsVisible(!isVisible)
  }
  return (
    <SecurityInputContainer>
      <TextField
        tabIndex={1}
        sx={{ width: '100%' }}
        value={value}
        onChange={onChange}
        type={isVisible ? 'text' : 'password'}
        inputProps={inputProps}
      />
      <StyledIconButton>
        {isVisible ? (
          <VisibilityOffIcon onClick={onToggle} fontSize="small" />
        ) : (
          <RemoveRedEyeIcon onClick={onToggle} fontSize="small" />
        )}
      </StyledIconButton>
    </SecurityInputContainer>
  )
}

interface SecurityInputProps {
  value: string;
  onChange: any;
  inputProps?: any;
}
