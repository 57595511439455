import React from 'react'
import { useAuth } from '@clerk/nextjs'
import { updatePatientAllergy, deletePatientAllergy } from '@/services'
import {
  usePatientAllergyStore,
  useQueryGetAllergen,
  useQueryGetPatientAllergy,
  useMutateAddPatientAllergy,
  useAllergenStore,
  useQueryGetAllergyReaction,
} from '@/hook'
import { PMHTile } from '../PatientHistoryTile'
import { isEmpty } from '@/utility'

export const PatientAllergies = ({
  patient,
}: {
  patient: any;
}): JSX.Element => {
  const { getToken } = useAuth()

  const { patientAllergy } = usePatientAllergyStore()

  useQueryGetAllergyReaction(getToken)
  const { allergyReactions } = useAllergenStore()

  const { data: allergens } = useQueryGetAllergen(getToken)

  const addPatientAllergyMutation = useMutateAddPatientAllergy(getToken)

  const { isLoading: isAllergyLoading, refetch } = useQueryGetPatientAllergy(
    patient?.id as number,
    getToken
  )

  if (isAllergyLoading) {
    return <div>Loading allergies...</div>
  }

  if (!allergens || allergens.length === 0) {
    console.error('No allergens available')
    return <div>No allergen data available.</div>
  }

  const patientAllergyArray = Array.isArray(patientAllergy)
    ? patientAllergy
    : []

  const allergensArray = Array.isArray(allergens) ? allergens : []

  const columns = [
    { accessor: 'allergenId', Header: 'Allergies' },
    { accessor: 'reaction', Header: 'Reaction' },
  ]

  const patientAllergiesWithName = patientAllergyArray.map((allergy: any) => {
    const allergenName =
      allergy.allergenId !== undefined && allergy.allergenId !== null
        ? allergens?.find((type) => type.id === allergy.allergenId)?.name
        : allergy.name ?? 'Unknown Allergen'
    let reactionName
    if (allergy.reactionId !== null) {
      reactionName = allergyReactions.find(
        (r) => r.id === allergy.reactionId
      )?.displayValue
    } else {
      reactionName = allergy.otherReaction
    }
    return {
      ...allergy,
      allergenId: allergenName,
      reaction: reactionName ?? 'Unknown Reaction',
    }
  })

  const dialogFields = [
    {
      label: 'Allergies',
      accessor: 'allergenId',
      type: 'select',
      options: allergensArray.map((allergen) => {
        const codeType = (allergen.codeType ?? 0) * 1000 * 1000 * 1000 * 1000
        return {
          id: isEmpty(allergen.code)
            ? allergen.id ?? 0
            : codeType + parseInt(allergen.rxCUI ?? '0'),
          label: allergen.name ?? '',
          code: allergen.code ?? '',
        }
      }),
    },
    {
      label: 'Reaction',
      accessor: 'reaction',
      type: 'select',
      options: allergyReactions.map((reaction) => ({
        id: reaction.id,
        label: reaction.displayValue,
      })),
    },
  ]

  const handleAdd = async (data: any): Promise<void> => {

    /* eslint-disable @typescript-eslint/naming-convention */
    const { allergenId_label, allergenId_id, reaction, reaction_label } = data
    /* eslint-disable @typescript-eslint/naming-convention */
    if (!allergenId_label) {
      return
    }
    let rxCUI = 0
    let codeType = 0
    if (!isEmpty(data.allergenId_code)) {
      codeType = Math.floor(allergenId_id / (1000 * 1000 * 1000 * 1000))
      rxCUI = Math.floor(allergenId_id - codeType * 1000 * 1000 * 1000 * 1000)
    }

    const newAllergy = {
      patientId: patient?.id,
      name: allergenId_label,
      ...(codeType > 0 && {
        rxCUI,
        code: data.allergenId_code ?? '',
        codeType,
      }),
      reactionId: reaction !== null ? parseInt(reaction) : null,
      otherReaction: reaction === null ? reaction_label : null,
    }

    await addPatientAllergyMutation.mutateAsync({ patientAllergy: newAllergy })
    await refetch()
  }

  const handleEdit = async (data: any): Promise<void> => {
    /* eslint-disable @typescript-eslint/naming-convention */
    const { allergenId_label, allergenId_id, reaction, reaction_label, id } =
      data
    /* eslint-disable @typescript-eslint/naming-convention */
    if (!allergenId_label) {
      return
    }
    let rxCUI = 0
    let codeType = 0
    if (!isEmpty(data.allergenId_code)) {
      codeType = Math.floor(allergenId_id / (1000 * 1000 * 1000 * 1000))
      rxCUI = Math.floor(allergenId_id - codeType * 1000 * 1000 * 1000 * 1000)
    }
    const updatedAllergy = {
      patientId: patient?.id,
      id,
      name: allergenId_label,
      ...(codeType > 0 && {
        rxCUI,
        code: data.allergenId_code ?? '',
        codeType,
      }),
      reactionId: reaction !== null ? parseInt(reaction) : null,
      otherReaction: reaction === null ? reaction_label : null,
    }
    await updatePatientAllergy(id, updatedAllergy, getToken)
    await refetch()
  }

  const handleDelete = async (allergyToDelete: any): Promise<void> => {
    if (!allergyToDelete) {
      console.error('Could not find allergy to delete')
      return
    }
    await deletePatientAllergy(allergyToDelete.id, getToken)
    await refetch()
  }

  return (
    <PMHTile
      title="Allergies"
      data={patientAllergiesWithName}
      columns={columns}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      dialogFields={dialogFields}
    />
  )
}
