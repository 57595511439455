import {
  styled,
  Typography,
  Checkbox,
  MenuItem,
  FormControlLabel,
} from '@mui/material'
import React from 'react'
import { type Room, type AccountUser } from '@/types'
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import AirlineSeatLegroomReducedIcon from '@mui/icons-material/AirlineSeatLegroomReduced'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { ViewType } from '@/pages/scheduling'

const BackgroundOverlay = styled('div')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 99,
})
const FilterContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  position: 'relative',
  cursor: 'pointer',
})
const FilterButton = styled('div')({
  background: '#FFFFFF',
  /* Yankees Blue/Shade 5 */
  border: ' 1px solid #DDDFE8',
  borderRadius: '6px',
  width: '200px',
  height: '36px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: '6px 8px 6px 12px',
})
const FilterText = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  lineHeight: '20px',
  color: '#000000',
  userSelect: 'none',
})

const FilterOptions = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '12px 1px',
  position: 'absolute',
  width: '272px',
  marginTop: '42px',
  userSelect: 'none',
  zIndex: 100,

  /* Black & White/White */
  background: '#FFFFFF',

  /* Indigo/Shade 3 */
  border: '1px solid #CDD0E3',
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
  borderRadius: '8px',
})
const FilterContentBox = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '8px 12px',
  borderBorder: '1px solid #EBF0F9',
  width: '100%',
  gap: '4px',
})
const CheckBoxGroup = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
})

export const RoomsCalendarFilter = ({
  rooms,
  providers,
  setSelectProviders,
  setSelectRooms,
  selectProviders,
  selectRooms,
  setFilterValue,
  filterValue,
  viewType,
}: RoomCalendarFilterProps): JSX.Element => {
  const [isFilterVisible, setIsFilterVisible] = React.useState<boolean>(false)
  const [boxTitle, setBoxTitle] = React.useState<string>('Group By Exam Room')

  React.useEffect(() => {
    if (filterValue === 'rooms') {
      setBoxTitle('Group By Exam Room')
      setSelectProviders([])
    } else {
      setBoxTitle('Group by Providers')
      setSelectRooms([])
    }
  }, [filterValue])
  React.useEffect(() => {
    localStorage.setItem('selectedRooms', JSON.stringify(selectRooms))
  }, [selectRooms])

  React.useEffect(() => {
    localStorage.setItem('selectedProviders', JSON.stringify(selectProviders))
  }, [selectProviders])

  const handleCheckProvider = (providerAccountUser: AccountUser): void => {
    if (
      selectProviders.some(
        (provider: AccountUser) => provider.id === providerAccountUser.id
      )
    ) {
      setSelectProviders(
        selectProviders.filter(
          (provider: AccountUser) => provider.id !== providerAccountUser.id
        )
      )
    } else {
      setSelectProviders([...selectProviders, providerAccountUser])
    }
  }
  const handleCheckRoom = (room: Room): void => {
    if (selectRooms.some((item: Room) => item.id === room.id)) {
      setSelectRooms(selectRooms.filter((item: Room) => item.id !== room.id))
    } else {
      setSelectRooms([...selectRooms, room])
    }
  }

  return (
    <>
      <FilterContainer>
        <FilterButton
          onClick={() => {
            setIsFilterVisible(!isFilterVisible)
          }}
          onBlur={() => {
            setIsFilterVisible(false)
          }}
        >
          <FilterText>{boxTitle}</FilterText>
          {
            // prettier-ignore
            isFilterVisible
              // prettier-ignore
              ? (<ArrowDropUpOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />)
              // prettier-ignore
              : (<ArrowDropDownOutlinedIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />)
          }
        </FilterButton>
        {isFilterVisible && (
          <FilterOptions>
            {viewType === ViewType.DAY_VIEW && (
              <>
                <FilterContentBox
                  onClick={() => {
                    setFilterValue('rooms')
                  }}
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: boxTitle.includes('Room')
                      ? '#EEE'
                      : 'white',
                  }}
                >
                  <FilterText>
                    <AirlineSeatLegroomReducedIcon />
                    Group By Exam Room
                  </FilterText>
                </FilterContentBox>
                <CheckBoxGroup sx={{ width: '100%' }}>
                  {rooms.map((room: Room) => {
                    return (
                      <MenuItem
                        key={room.id}
                        sx={{ width: '100%' }}
                        onClick={() => {
                          setFilterValue('rooms')
                        }}
                      >
                        <FormControlLabel
                          sx={{ width: '100%' }}
                          label={`${room.name!}`}
                          control={
                            <Checkbox
                              size="small"
                              checked={
                                selectRooms.some(
                                  (item: Room) => item.id === room.id
                                ) ||
                                (selectRooms.length === 0 &&
                                  filterValue === 'rooms')
                              }
                              onChange={() => handleCheckRoom(room)}
                            />
                          }
                        />
                      </MenuItem>
                    )
                  })}
                </CheckBoxGroup>
              </>
            )}
            <FilterContentBox
              onClick={() => {
                setFilterValue('providers')
              }}
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: boxTitle.includes('Provider')
                  ? '#EEE'
                  : 'white',
              }}
            >
              <FilterText>
                <AccountCircleIcon /> Group by Providers
              </FilterText>
            </FilterContentBox>
            <CheckBoxGroup sx={{ width: '100%' }}>
              {providers.map((provider: AccountUser) => {
                return (
                  <MenuItem
                    key={provider.username}
                    sx={{ width: '100%', fontSize: '13px' }}
                    onClick={() => {
                      setFilterValue('providers')
                    }}
                  >
                    <FormControlLabel
                      sx={{ width: '100%' }}
                      label={`${provider.firstName ?? ''} ${
                        provider.lastName ?? ''
                      }`}
                      control={
                        <Checkbox
                          size="small"
                          checked={selectProviders.some(
                            (item) => item.id === provider.id
                          )}
                          onChange={() => handleCheckProvider(provider)}
                        />
                      }
                    />
                  </MenuItem>
                )
              })}
            </CheckBoxGroup>
          </FilterOptions>
        )}
      </FilterContainer>
      {isFilterVisible && (
        <BackgroundOverlay onClick={() => setIsFilterVisible(false)} />
      )}
    </>
  )
}

interface RoomCalendarFilterProps {
  filterValue: string;
  setFilterValue: any;
  rooms: Room[];
  selectRooms: Room[];
  providers: AccountUser[];
  setSelectProviders: any;
  setSelectRooms: any;
  selectProviders: AccountUser[];
  viewType: ViewType;
}
