import React, { useMemo } from 'react'
import { useAuth } from '@clerk/nextjs'
import {
  BaseInfo,
  ContactIcon,
  ContactItem,
  ContactSection,
  ContactText,
  HeaderBadge,
  HeaderItem,
  HeaderWrapper,
  ModalText,
  Name,
  NameAvatarContainer,
  NameContainer,
  StyledBadge,
  WarningTooltip,
} from '../styles'
import {
  Box,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  Modal,
  Button,
} from '@mui/material'
import { type DateOnly, type Patient, type Visit } from 'types'
import { format } from 'date-fns'
import {
  formatAgeWithRules,
  formatDateOnly,
  formatPhoneNumber,
  isEmpty,
} from 'utility/utils'
import {
  useClinicStore,
  useQueryGetAllergen,
  useQueryGetComplaints,
  useQueryGetPatientAllergy,
  useQueryGetVisitTypes,
} from '@/hook'
import { AvatarImage } from '@/components/AvatarImage'
import { StickyNotesModal } from '@/components/StickyNotesModal'
import { ModalContainer } from '@/components/Config'
import { EPrescribePopUp } from '@/components/Charting/Editor/IQBar/ePrescribePopUp'
import { RxIconSmaller } from '@/components/Charting/Editor/IQBar/Icons'
import ErrorIcon from '@mui/icons-material/Error'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'

const contacts = [
  ['email', EmailOutlinedIcon],
  ['phoneNumber', PhoneOutlinedIcon],
]

export const PatientProfileHeader = ({
  patient,
  lastVisit,
  getPatient,
  isNavOpen,
}: PatientProfileHeaderProps): JSX.Element => {
  const { getToken } = useAuth()

  const {
    avatarUrl,
    firstName,
    lastName,
    dateOfBirth,
    gender,
  }: HeaderDataProps = patient as HeaderDataProps
  const { clinicId } = useClinicStore()
  const { data: visitTypes } = useQueryGetVisitTypes(clinicId, getToken)
  const { data: complaints } = useQueryGetComplaints(getToken)
  const { data: allergens } = useQueryGetAllergen(getToken)
  const { data: allergies } = useQueryGetPatientAllergy(patient?.id!, getToken)
  const [stickyNotesOpen, setStickyNotesOpen] = React.useState<boolean>(false)
  const [openEPrescribe, setOpenEPrescribe] = React.useState<boolean>(false)
  const isAllergyMissing = React.useMemo(() => {
    if (!Array.isArray(allergies)) return false
    return allergies.some(
      (allergy) => !allergy?.doseSpotAllergyId || !allergy?.codeType
    )
  }, [allergies])

  const isDoseSpotIdMissing = React.useMemo(() => {
    if (!isEmpty(patient?.doseSpotPatientId)) return false
    return true
  }, [patient])

  const RXElement = (): JSX.Element => {
    if (isDoseSpotIdMissing) {
      return (
        <ModalContainer>
          <ModalText>
            <ErrorIcon sx={{ color: 'white' }} />
            Unable to create patient, if the patient is under 19 make sure
            weight & height are entered in last visit
          </ModalText>
        </ModalContainer>
      )
    }
    if (!patient?.doseSpotPatientId) return <></>
    return (
      <EPrescribePopUp
        url={process.env.DOSESPOT_URL ?? ''}
        patientId={patient?.doseSpotPatientId}
      />
    )
  }

  const rightSectionData = useMemo(() => {
    allergies?.sort((allergy1, allergy2) => {
      if (allergy1.rxCUI && allergy2.rxCUI) {
        return 0
      } else if (allergy1.rxCUI && !allergy2.rxCUI) {
        return 1
      } else {
        return -1
      }
    })
    return [
      ['MRN', patient?.id],
      [
        'Allergies',
        allergies?.map((allergy) => {
          // prettier-ignore
          return isEmpty(allergy.name)
            ? allergens?.find(
              (allergen: any) => allergen?.id === allergy.id
            )?.name!
            : allergy.name ?? ''
        }),
      ],
      [
        'Last Visit Date',
        lastVisit?.arrivalDateTime !== undefined
          ? format(new Date(lastVisit.arrivalDateTime!), 'MM/dd/yyyy')
          : undefined,
      ],
    ]
  }, [patient, lastVisit, allergens, allergies, complaints, visitTypes])

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{
        width: '100%',
        background: '#EAF3FF',
        borderBottom: '1px solid #E6E7EF',
      }}
    >
      <Modal
        open={openEPrescribe}
        onClose={() => setOpenEPrescribe(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingLeft: '358px',
        }}
      >
        <Box sx={{ width: '100%', height: '100%', paddingTop: '200px' }}>
          <RXElement />
        </Box>
      </Modal>
      <HeaderWrapper>
        <NameAvatarContainer sx={{ marginRight: '20px' }}>
          <AvatarImage
            src={avatarUrl ?? '/user.png'}
            alt="patient avatar"
            width={45}
            height={45}
            marginRight="8px"
          />
          <NameContainer>
            <Name>
              {firstName} {lastName}
              <Tooltip title="Patient Notes" arrow>
                <IconButton
                  onClick={() => {
                    setStickyNotesOpen(true)
                  }}
                >
                  <FeedOutlinedIcon
                    fontSize="small"
                    sx={{
                      fill: patient?.stickyNote?.length
                        ? '#2E3353'
                        : '#2E335360',
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={
                  isAllergyMissing ? (
                    <WarningTooltip>
                      <div>Warning:</div>
                      <div>
                        Allergies:{' '}
                        {allergies?.map((allergy) => allergy?.name)?.join(', ')}{' '}
                        will not provide interaction alerts in DoseSpot due to
                        insufficient information.
                      </div>
                    </WarningTooltip>
                  ) : (
                    'Rx'
                  )
                }
              >
                <StyledBadge
                  color="warning"
                  badgeContent={isAllergyMissing ? '!' : ''}
                  invisible={!isAllergyMissing}
                >
                  <IconButton
                    sx={{ marginLeft: '-14px' }}
                    size="small"
                    onClick={() => setOpenEPrescribe(true)}
                  >
                    <RxIconSmaller />
                  </IconButton>
                </StyledBadge>
              </Tooltip>
            </Name>
            <BaseInfo>
              ({formatDateOnly(dateOfBirth)}
              {' - '}
              {formatAgeWithRules(dateOfBirth)}) {gender}
            </BaseInfo>
          </NameContainer>
        </NameAvatarContainer>
        <HeaderItem>
          <ContactSection>
            {contacts.map(([contact, IconComponent]: any) => (
              <ContactItem key={contact as string}>
                <ContactIcon>
                  <IconComponent
                    style={{
                      fontSize: 16,
                      color: '#717899',
                      cursor: 'pointer',
                    }}
                  />
                </ContactIcon>
                <ContactText>
                  {contact === 'phoneNumber'
                    ? formatPhoneNumber(
                        (patient as any)[contact as string] as string
                      )
                    : (patient as any)[contact as string]?.length > 0
                    ? (patient as any)[contact as string]
                    : 'N/A'}
                </ContactText>
              </ContactItem>
            ))}
          </ContactSection>
        </HeaderItem>
      </HeaderWrapper>
      <Stack direction="row" alignItems="center" marginRight="8px">
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            window.location.href = `/intake?patientId=${patient.id!}&previousNavOpen=${
              isNavOpen ? 'true' : 'false'
            }`
          }}
          sx={{
            marginRight: '12px',
            padding: '14px',
          }}
        >
          Add Visit
        </Button>
        {rightSectionData?.map((item: any) => (
          <HeaderItem key={item[0]}>
            <Stack direction="column" style={{ margin: '0 12px 0 12px' }}>
              <Typography
                style={{ fontWeight: 400, fontSize: '12px', color: '#717899' }}
              >
                {item[0] ?? ''}
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                height="21px"
                justifyContent="center"
              >
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    color: item[0] === 'Allergies' ? 'red' : 'black',
                  }}
                >
                  {item[1]?.length !== undefined
                    ? typeof item[1] === 'string'
                      ? item[1]
                      : item[1]?.[0]
                    : item[1]}
                </Typography>
                {typeof item[1] !== 'string' && item[1]?.length >= 2 && (
                  <Tooltip
                    title={
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '5px',
                        }}
                      >
                        {item[1]
                          ?.slice(1)
                          ?.map((cur: string, index: number) => (
                            <Box key={`${cur}-${index}`}>{cur}</Box>
                          ))}
                      </Box>
                    }
                    arrow
                  >
                    <HeaderBadge>{(item[1].length as number) - 1}</HeaderBadge>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </HeaderItem>
        ))}
      </Stack>
      <Modal
        open={stickyNotesOpen}
        onClose={() => setStickyNotesOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <StickyNotesModal
          patientId={patient?.id!}
          handleClose={() => setStickyNotesOpen(false)}
          getPatient={getPatient}
        />
      </Modal>
    </Stack>
  )
}

interface PatientProfileHeaderProps {
  patient: Patient;
  lastVisit: Visit;
  getPatient: any;
  isNavOpen: boolean;
}

interface HeaderDataProps {
  avatarUrl: string;
  firstName: string;
  lastName: string;
  dateOfBirth: DateOnly;
  gender: string;
}
