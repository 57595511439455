import {
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  Typography,
  styled,
} from '@mui/material'
import React from 'react'
import {
  type ExamNode,
  type ExamStatusType,
  type ExamResultIdType,
} from '@/types'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { EXAM_ABNORMAL, EXAM_EMPTY, EXAM_NORMAL } from '@/utility'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { makeStyles } from '@mui/styles'

const ExamSelectContainer = styled(MenuList)(({ theme }) => ({
  width: '450px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.paper,
}))

const ExamSelectItems = styled('div')({
  maxHeight: '300px',
  width: '100%',
  overflowY: 'scroll',
})

const ExamSelectRow = styled(Box)({
  padding: '10px',
})

const SearchText = styled(Typography)({
  color: '#686868',
  fontSize: '14px',
})

const SearchTextField = styled(TextField)({
  width: '100%',
})

const ExamSelectDivider = styled(Divider)({
  margin: '0px !important',
})

const CurrentExamItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  height: '52px',
}))

const ExamItem = styled(MenuItem)({
  height: '52px',
})

type ExamNodeWithIndex = ExamNode & {
  index: number;
};

const useStyles = makeStyles(() => ({
  menuItem: {
    '& .add-icon': {
      visibility: 'hidden',
      transition: 'visibility 0s, opacity 0.2s linear',
      marginRight: '12px',
      minWidth: 'auto',
    },
    '&:hover .add-icon': {
      visibility: 'visible',
      opacity: 1,
    },
  },
}))

export const ExamSelect = ({
  examination,
  handleUpdateExam = () => {},
  examResultFactorIds,
  isSaving,
  examResponses,
  defaultStatus,
}: ExamSelectProps): JSX.Element => {
  const [currentPath, setCurrentPath] = React.useState<number[]>([])
  const [baseSearch, setBaseSearch] = React.useState<string>('')
  const [userSearch, setUserSearch] = React.useState<string>('')
  const classes = useStyles()

  const currentExam = React.useMemo(() => {
    return currentPath.reduce((exam, cur) => {
      return exam.children[cur]
    }, examination)
  }, [currentPath])

  const isItemVisible = (exam: ExamNode): boolean => {
    // Check if the item has no visible children and its status is 1 or 2
    const status = examResponses[exam.id]?.status
    if (
      status === examResultFactorIds[EXAM_NORMAL] ||
      status === examResultFactorIds[EXAM_ABNORMAL]
    ) {
      if (!exam.children.length) {
        return false
      }
    }
    if (!exam.children.length) {
      return true
    }
    if (!exam.children.some((child) => isItemVisible(child))) {
      return false
    }
    return true
  }

  const childrenExams = React.useMemo(() => {
    return currentExam.children
      .map((exam, index) => ({ ...exam, index }))
      .sort((prev, cur) => {
        if (!prev.children.length && cur.children.length) {
          return -1
        } else if (prev.children.length && !cur.children.length) {
          return 1
        } else {
          return prev.id - cur.id
        }
      })
      .filter(
        (exam) =>
          exam.name.toLowerCase().includes(userSearch.toLowerCase()) &&
          isItemVisible(exam)
      )
  }, [currentExam, userSearch, examResponses, examResultFactorIds])

  const handleGoBack = (): void => {
    setCurrentPath((prev) => [...prev.slice(0, -1)])
    if (baseSearch.slice(-2) !== ', ') {
      setBaseSearch((prev) => {
        const paths = prev.split(/, | - /)
        return prev.slice(0, prev.length - paths[paths.length - 2].length - 3)
      })
    }
  }

  const handleAddExam = (exam: ExamNodeWithIndex) => () => {
    setCurrentPath((prev) => [...prev, exam.index])
    setBaseSearch((prev) => `${prev}${exam.name} - `)
    setUserSearch('')
  }

  const handleAddNode = (exam: ExamNodeWithIndex) => () => {
    if (
      examResponses[exam.id] &&
      examResponses[exam.id].status !== examResultFactorIds[EXAM_EMPTY]
    ) {
      return
    }
    const curState = defaultStatus ?? examResponses[examination.id]?.status
    if (!curState || curState === examResultFactorIds[EXAM_EMPTY]) {
      return
    }
    handleUpdateExam(exam.id, curState)
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value
    if (inputValue.slice(0, baseSearch.length) !== baseSearch) {
      return
    }
    setUserSearch(inputValue.slice(baseSearch.length))
  }

  React.useEffect(() => {
    if (!childrenExams.length && baseSearch !== '' && userSearch === '') {
      handleGoBack()
    }
  }, [childrenExams])

  return (
    <ExamSelectContainer>
      <ExamSelectRow>
        <SearchText>Type to search or Select an Option</SearchText>
        <SearchTextField
          value={`${baseSearch}${userSearch}`}
          onChange={handleSearchChange}
        />
      </ExamSelectRow>
      <ExamSelectDivider />
      <CurrentExamItem
        onClick={currentPath.length > 0 ? handleGoBack : undefined}
        disabled={!currentPath.length}
      >
        {currentPath.length > 0 && (
          <ListItemIcon>
            <ChevronLeftIcon color="primary" />
          </ListItemIcon>
        )}
        <ListItemText inset={currentPath.length < 1}>
          {currentExam.name}
        </ListItemText>
      </CurrentExamItem>
      <ExamSelectDivider />
      <ExamSelectItems>
        {childrenExams.map((exam) => (
          <ExamItem
            className={classes.menuItem}
            key={exam.id}
            onClick={
              exam.children.length > 0
                ? handleAddExam(exam)
                : handleAddNode(exam)
            }
          >
            {exam.children.length > 0 && (
              <ListItemIcon className="add-icon">
                <AddCircleIcon
                  color="primary"
                  onClick={(e) => {
                    handleAddNode(exam)()
                    e.stopPropagation()
                  }}
                />
              </ListItemIcon>
            )}
            <ListItemText inset={exam.children.length <= 0}>
              {exam.name}
            </ListItemText>
            {exam.children.length > 0 && (
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
            )}
          </ExamItem>
        ))}
      </ExamSelectItems>
    </ExamSelectContainer>
  )
}

interface ExamSelectProps {
  examination: ExamNode;
  examResponses: ExamStatusType[];
  examResultFactorIds: ExamResultIdType;
  handleUpdateExam?: any;
  defaultStatus?: number;
  isSaving: boolean;
}
