import { Chip, styled, Typography } from '@mui/material'
import React from 'react'

const PopoverContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: '12px',
  height: '57px',
  /* Yankees Blue/Shade 2 */
  background: '#2E3353',
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
}))
const StyledChip = styled(Chip)(({ theme }) => ({
  height: '25px',
  background: '#717899',
}))
const PopoverTileText = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '17px',
  /* Black & White/White */
  color: theme.palette.white,
}))
export const AllergyPopover = ({
  allergies,
}: AllergyPopoverProps): JSX.Element => {
  return (
    <PopoverContainer>
      {allergies.map((allergy) => (
        <StyledChip
          key={allergy}
          label={<PopoverTileText>{allergy}</PopoverTileText>}
        />
      ))}
    </PopoverContainer>
  )
}

interface AllergyPopoverProps {
  allergies: string[];
}
