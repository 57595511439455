import React, { useState } from 'react'
import { IconButton, styled, Typography, TextField } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { addDays, format, subDays } from 'date-fns'

const DateSelectorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: 'auto',
  height: '60px',
  padding: '12px',
  gap: 4,
  alignItems: 'center',
}))

const DateText = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '19px',
  color: 'black',
  minWidth: '100px',
}))

export const DateSelector = ({
  selectedDate,
  setSelectedDate,
}: DateSelectorProps): JSX.Element => {
  const [open, setOpen] = useState(false)

  const leftOnClick = (): void => {
    setSelectedDate(subDays(selectedDate, 1))
  }
  const rightOnClick = (): void => {
    setSelectedDate(addDays(selectedDate, 1))
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateSelectorContainer>
        <IconButton onClick={() => setOpen(true)} size="large">
          <CalendarMonthIcon fontSize="small" color="primary" />
        </IconButton>
        <DatePicker
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          value={selectedDate}
          onChange={(newValue) => {
            setSelectedDate(newValue)
            setOpen(false)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{
                position: 'absolute',
                opacity: 0,
                width: '1px',
                height: '1px',
              }}
            />
          )}
        />
        <DateText>{format(selectedDate, 'MMM d, yyyy')}</DateText>
        <IconButton
          onClick={leftOnClick}
          sx={{ marginRight: '13px' }}
          size="large"
        >
          <ChevronLeftIcon fontSize="small" color="primary" />
        </IconButton>
        <IconButton
          onClick={rightOnClick}
          sx={{ marginLeft: '-20px' }}
          size="large"
        >
          <ChevronRightIcon fontSize="small" color="primary" />
        </IconButton>
      </DateSelectorContainer>
    </LocalizationProvider>
  )
}
interface DateSelectorProps {
  selectedDate: Date;
  setSelectedDate: any;
}
