import { type paths } from '../generated/schema'
import { Fetcher } from 'openapi-typescript-fetch'
import { type GetToken } from '@clerk/types'

const fetcher = Fetcher.for<paths>()

const setupSendNewMessaging = fetcher
  .path('/api/Messaging')
  .method('post')
  .create({ 'api-version': undefined })

export const sendNewPatientMessage = async (
  getToken: GetToken,
  patientId: number,
  clinicId: number,
  message: string,
  documentId: number
): Promise<boolean> => {
  fetcher.configure({
    baseUrl: process.env.API_URL,
    init: {
      headers: {
        origin: 'null',
        Authorization: `Bearer ${(await getToken({ template: 'UrgentIQ' }))!}`,
      },
    },
  })

  const success = (
    await setupSendNewMessaging({
      toPatientId: patientId,
      fromClinicId: clinicId,
      message,
      documentId,
    })
  ).data

  return success.statusCode === 200
}
