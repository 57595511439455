import { styled } from '@mui/material'
import React from 'react'

const ChiefComplaintContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '812px',
  padding: '24px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  border: '1px solid #CDD0E3',
  gap: 8,
  boxShadow:
    '0px 8px 4px rgba(221, 223, 232, 0.25), 0px 4px 15px rgba(64, 71, 118, 0.1)',
  scrollMarginTop: '100px',
}))

export const TileContainer = ({
  id,
  ref,
  onMouseOver,
  children,
}: TileContainerProps): JSX.Element => {
  return (
    <ChiefComplaintContainer id={id} ref={ref} onMouseOver={onMouseOver}>
      {children}
    </ChiefComplaintContainer>
  )
}

interface TileContainerProps {
  id: string;
  ref?: any;
  onMouseOver?: any;
  children?:
    | JSX.Element
    | JSX.Element[]
    | undefined
    | null
    | Array<JSX.Element | undefined | null>;
}
