import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { type UseMutationResult, useMutation } from '@tanstack/react-query'
import { addNewUserPin, updateUserPin } from '@/services'
import { type UserPin } from '@/types/UserPin'

interface UserPinState {
  userPin: UserPin | null;
  setUserPin: (userPin: UserPin) => void;
}

export const useUserPinStore = create<UserPinState>()(
  devtools(
    persist(
      (set) => ({
        userPin: null,
        setUserPin: (userPin: UserPin) => set({ userPin }),
      }),
      {
        name: 'user-pin',
      }
    )
  )
)

export const useMutateAddUserPin = (
  getToken: any
): UseMutationResult<UserPin, unknown, { user: UserPin }> => {
  const { setUserPin } = useUserPinStore()

  return useMutation<UserPin, unknown, { user: UserPin }>(
    async ({ user }) => await addNewUserPin(user, getToken),
    {
      onSuccess: (data) => {
        setUserPin(data)
      },
    }
  )
}

export const useMutateUpdateUserPin = (
  getToken: any
): UseMutationResult<UserPin, unknown, { user: UserPin }> => {
  const { setUserPin } = useUserPinStore()

  return useMutation<UserPin, unknown, { user: UserPin }>(
    async ({ user }) => await updateUserPin(user, getToken),
    {
      onSuccess: (data) => {
        setUserPin(data)
      },
    }
  )
}
