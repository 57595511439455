import React, { type ReactNode } from 'react'
import { IconButton, styled, Tooltip, Typography } from '@mui/material'
import { type Summary } from '@/types/Summary'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const SummaryTileContainer = styled('div')(
  ({ isOpen, inFocus }: SummaryContainerProps): any => ({
    display: 'flex',
    flexDirection: 'column',
    width: '358px',
    ...((isOpen || inFocus) && { borderRadius: '8px' }),
    ...(isOpen &&
      !inFocus && { border: '1px solid rgba(255, 255, 255, 0.16)' }),
    ...(!isOpen &&
      !inFocus && { borderBottom: '1px solid rgba(255, 255, 255, 0.16)' }),
    padding: '12px',
    gap: '6px',
    ...(inFocus && { backgroundColor: 'rgba(255, 255, 255, 0.08)' }),
    ...(inFocus && !isOpen && { height: '44px' }),
  })
)

const SummaryTileTitle = styled('div')(({ inFocus }: TitleProps) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  width: '334px',
  marginTop: '-6px',
}))

const TiltText = styled(Typography)({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  color: '#FFFFFF',
  width: '328px',
  '&:hover': {
    cursor: 'pointer',
  },
})

const SubTiltText = styled(Typography)({
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
  color: '#FFFFFF',
})

const FieldTitle = styled(Typography)({
  fontSize: '13px',
  lineHeight: '20px',
  color: '#ADB2C9',
})

const FieldValue = styled(Typography)({
  fontSize: '14px',
  lineHeight: '20px',
  color: '#FFFFFF',
  maxWidth: '282px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre-line',
})

const FieldTitleContainer = styled('div')({
  display: 'flex',
  width: '136px',
})

const FieldValueContainer = styled('div')({
  display: 'flex',
  maxWidth: '192px',
  overflow: 'hidden',
})
const FieldRow = styled('div')(({ note }: FieldRowProps): any => ({
  display: 'flex',
  ...(note && { maxHeight: '40px' }),
}))

const Warning = styled(Typography)({
  fontStyle: 'italic',
  fontSize: '9.5px',
  lineHeight: '20px',
  color: '#FFFFFF',
})

const Indicator = styled('div')({
  width: '3px',
  height: '20px',
  left: '0px',
  marginLeft: '-12px',

  /* Cobalt Blue/Shade 1 */
  background: '#0543B0',
  borderRadius: '0px 16px 16px 0px',
  marginBottom: '-42px',
  marginRight: '-3px',
})

const getDisplayName = (name: string): ReactNode => {
  switch (name) {
    case 'BMI':
      return (
        <>
          BMI (lb/inches<sup>2</sup>)
        </>
      )
    case 'Oxygen':
      return (
        <>
          O<sub>2</sub> Sat
        </>
      )
  }
  return name
}

const buildRow = (
  name: string,
  value: string,
  title: string,
  outOfRange: boolean = false
): JSX.Element => {
  return (
    <FieldRow note={name === 'Note'} key={name + value}>
      <FieldTitleContainer sx={title === 'Exam' ? { width: '96px' } : {}}>
        {
          // prettier-ignore
          <FieldTitle sx={{ ...(outOfRange && { color: 'red' }) }}>{getDisplayName(name)}</FieldTitle>
        }
      </FieldTitleContainer>
      <FieldValueContainer>
        {/* prettier-ignore */}
        {
          /* prettier-ignore */
          name === 'Note' ||
            /* prettier-ignore */
            name === 'Explanation' ||
            /* prettier-ignore */
            name === 'Treatment'
            /* prettier-ignore */
            ? (
              /* prettier-ignore */
              <Tooltip
                title={value}
                placement="right"
              >
                <FieldValue sx={{ ...(outOfRange && { color: 'red' }) }}>
                  {value}
                </FieldValue>
              </Tooltip>
              /* prettier-ignore */
              )
            /* prettier-ignore */
            : (
              <FieldValue sx={{ ...(outOfRange && { color: 'red' }) }}>{value}</FieldValue>
              /* prettier-ignore */
              )
        }
      </FieldValueContainer>
    </FieldRow>
  )
}

export const SummaryTile = ({
  summary,
  isOpen,
  setIsOpen,
  inFocus,
  title,
  outOfRanges = {},
}: SummaryTileProps): JSX.Element => {
  const showSubsectionName =
    summary.sections?.length > 1 ||
    (summary.sections?.length === 1 && summary.sectionName === 'Exam') ||
    (summary.sections?.length === 1 &&
      summary.sectionName === 'Review of Systems') ||
    (summary.sections?.length === 1 &&
      summary.sectionName === 'History of Present Illness') ||
    (summary.sections?.length > 0 && summary.sectionName === 'Vitals')

  return (
    <SummaryTileContainer isOpen={isOpen} inFocus={inFocus} data-testid="summary-tile">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {inFocus && <Indicator />}
        <SummaryTileTitle inFocus={inFocus}>
          <TiltText
            onClick={() => {
              document?.getElementById(title)?.scrollIntoView()
            }}
          >
            {summary.sectionName}
          </TiltText>
          <IconButton
            size={'small'}
            onClick={() => {
              setIsOpen(!isOpen)
            }}
          >
            {
              // prettier-ignore
              isOpen
                ? (<KeyboardArrowDownIcon fontSize='small' sx={{ color: '#FFFFFF' }} />)
                : (<KeyboardArrowRightIcon fontSize='small' sx={{ color: '#FFFFFF' }} />)
            }
          </IconButton>
        </SummaryTileTitle>
      </div>
      {isOpen && (
        <>
          <div
            style={{
              backgroundColor: 'rgba(255, 255, 255, 0.16)',
              height: '1px',
            }}
          />
          {
            // prettier-ignore
            summary.sections?.length > 0
              // prettier-ignore
              ? (
                  // prettier-ignore
                  summary.sections.map((section) => {
                    return (
                      <div key="section.name">
                        {showSubsectionName && (
                          <SubTiltText> {section.name}</SubTiltText>
                        )}
                        {
                          section.patientDenies && (
                            <FieldTitle>
                              Patient denies
                            </FieldTitle>
                          )
                        }
                        {
                          section.hasNKDA && (
                            <FieldTitle>
                              No Known Drug Allergies
                            </FieldTitle>
                          )
                        }
                        {
                          section.hasChildhoodVaccines && (
                            <FieldTitle>
                              Has Childhood Vaccines
                            </FieldTitle>
                          )
                        }
                        {section.value?.length === 0 && !section.patientDenies
                          ? <FieldTitle>No Data</FieldTitle>
                          : section.value?.map((value) => {
                            const key = Object.keys(value)[0]
                            return buildRow(key, value[key], section.name, key in outOfRanges && outOfRanges[key])
                          })
                        }
                      </div>
                    )
                  }))
              // prettier-ignore
              : (
                <FieldTitle>No Data</FieldTitle>
                )
          }
          {summary.sectionName === 'Review of Systems' && (
            <Warning>
              All non-documented systems have been reviewed and are considered
              negative
            </Warning>
          )}
          {summary.sectionName === 'Past Medical History' && (
            <Warning>
              Reviewed past medical history and relevant medical records, all
              known pertinent history is documented
            </Warning>
          )}
        </>
      )}
    </SummaryTileContainer>
  )
}

interface SummaryTileProps {
  summary: Summary;
  isOpen: boolean;
  setIsOpen: any;
  inFocus: boolean;
  title: string;
  outOfRanges?: any;
}

interface SummaryContainerProps {
  isOpen: boolean;
  inFocus: boolean;
}

interface FieldRowProps {
  note: boolean;
}
interface TitleProps {
  inFocus: boolean;
}
