import { styled, Typography } from '@mui/material'
import React from 'react'

const ValueText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  color: 'black',
}))

export const PopoverValueText = ({
  children,
}: PopoverValueTextProps): JSX.Element => {
  return <ValueText>{children}</ValueText>
}

interface PopoverValueTextProps {
  children: any;
}
