import { Checkbox, IconButton, styled, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { type ExamSystemComponent, type RosSymptom } from '@/types'
import { SystemRow } from './SystemRow'
import ClearIcon from '@mui/icons-material/Clear'

const ExamModalContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '450px',
  padding: '24px 24px 24px 0px',
  borderRadius: '12px',
  backgroundColor: theme.palette.background.default,
}))
const ExamModalRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
})

const SystemText = styled(Typography)({
  fontSize: '14px',
  lineHeight: '16px',
  alignSelf: 'center',
})
const TitleColumn = styled('div')({
  display: 'flex',
  flex: 1,
})
const RadioColumn = styled('div')({
  display: 'flex',
  width: '75px',
  alignContent: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
})

const ResponseColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '500px',
  overflowY: 'auto',
  scrollbarWidth: 'thin',
  msOverflowStyle: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
})

export const ExamModal = ({
  examSystemComponents,
  examResponses,
  setExamResponses,
  type,
  handleClose,
}: ExamModalProps): JSX.Element => {
  const isAllPositive = useMemo(
    () =>
      examSystemComponents
        .map(({ id }) => id)
        .every((id) => examResponses[id ?? '0'] === 1),
    [examSystemComponents, examResponses]
  )
  const isAllNegative = useMemo(
    () =>
      examSystemComponents
        .map(({ id }) => id)
        .every((id) => examResponses[id ?? '0'] === 2),
    [examSystemComponents, examResponses]
  )
  const buildExamModalRows = (): JSX.Element[] => {
    const handleChange = (key: string, value: number): void => {
      const newResponse = { ...examResponses }
      newResponse[key] = value
      setExamResponses(newResponse)
    }
    return examSystemComponents.map(
      (examSystemComponent: ExamSystemComponent | RosSymptom) => {
        return (
          <ExamModalRow key={`${examSystemComponent.id ?? 0}`}>
            <SystemRow
              systomId={`${examSystemComponent.id ?? 0}`}
              selectedValue={examResponses[examSystemComponent.id ?? '']}
              handleChange={handleChange}
            />
            <TitleColumn>
              <SystemText>{examSystemComponent.name}</SystemText>
            </TitleColumn>
          </ExamModalRow>
        )
      }
    )
  }

  const handleAllChange = (value: number): void => {
    const newResponse = { ...examResponses }
    examSystemComponents.forEach(({ id }) => {
      newResponse[id ?? '0'] = value
    })
    setExamResponses(newResponse)
  }

  const handleAllPositiveChange = (event: any): void => {
    const value = event.target.checked ? 1 : 3
    handleAllChange(value)
  }

  const handleAllNegativeChange = (event: any): void => {
    const value = event.target.checked ? 2 : 3
    handleAllChange(value)
  }

  return (
    <ExamModalContainer>
      <ExamModalRow sx={{ borderBottom: '1px solid grey' }}>
        <RadioColumn>
          <Checkbox
            inputProps={{
              alt: `Mark all as ${type === 'exam' ? 'normal' : 'denies'}`,
            }}
            checked={type === 'exam' ? isAllPositive : isAllNegative}
            onChange={
              type === 'exam'
                ? handleAllPositiveChange
                : handleAllNegativeChange
            }
          />
        </RadioColumn>
        <TitleColumn>
          <SystemText>
            Mark all as {type === 'exam' ? 'normal' : 'denies'}
          </SystemText>
        </TitleColumn>
        <IconButton onClick={handleClose} size="large">
          <ClearIcon />
        </IconButton>
      </ExamModalRow>
      <ExamModalRow>
        <RadioColumn>
          <SystemText>{type === 'exam' ? 'WNL' : 'Patient Reports'}</SystemText>
        </RadioColumn>
        <RadioColumn>
          <SystemText>{type === 'exam' ? 'ABN' : 'Patient Denies'}</SystemText>
        </RadioColumn>
        <TitleColumn />
      </ExamModalRow>
      <ResponseColumn>{buildExamModalRows()}</ResponseColumn>
    </ExamModalContainer>
  )
}

interface ExamModalProps {
  examSystemComponents: ExamSystemComponent[] | RosSymptom[];
  examResponses: any;
  setExamResponses: any;
  type: any;
  handleClose: any;
}
