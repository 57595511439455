import React from 'react'
import { PopoverContainer } from '../layout/PopoverContainer'
import { PopoverTileText } from '../components/PopoverTileText'
import { PopoverValueText } from '../components/PopoverValueText'

export const DXHoverOver = ({
  dxName,
  dxModifier,
  codingModifier,
}: DXHoverOverProps): JSX.Element => {
  return (
    <PopoverContainer>
      <div>
        <PopoverTileText>Diagnosis</PopoverTileText>
        <PopoverValueText>{dxName}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Diagnosis Modifier</PopoverTileText>
        <PopoverValueText>{dxModifier}</PopoverValueText>
      </div>
      <div>
        <PopoverTileText>Coding Modifer</PopoverTileText>
        <PopoverValueText>{codingModifier}</PopoverValueText>
      </div>
    </PopoverContainer>
  )
}

interface DXHoverOverProps {
  dxName: string;
  dxModifier: string;
  codingModifier: string;
}
