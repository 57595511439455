import React from 'react'
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from '@mui/material'
import {
  TileInputLabel,
  TileTextField,
  Title,
  TitleCheckBox,
} from '@/components'
import { type VitalDetail, type Vital } from '@/types'
import { IS_METRIC_STORAGE_KEY, isEmpty } from '@/utility'
import { FreeTextNotes } from '../FreeTextNotes'
import { TileLayout } from '../layout/TileLayout'
import { TileRow } from '../layout/TileRow'
import {
  HeightRow,
  BPTextField,
  FeetTextField,
  InchesTextField,
  VisualAcuityTextField,
  TitleRow,
  TemperatureTextField,
  TemperatureRow,
  MethodSearchBox,
} from './styles'
import { useAuth } from '@clerk/nextjs'
import { Stack } from '@mui/system'
import { TileDateTimePicker } from '../components/TileDateTimePicker'
import { useClinicStore, useVitalsStore, useQueryGetTemperatureMethods, mergeDetails, useDebounce } from '@/hook'
import { LMPInput } from '../components/LMPInput/LMPInput'

const ValueContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
})

const OxygenBox = styled('div')({
  marginTop: '-4px',
})

export const VitalsTile = ({
  visitId,
  patientId,
  ref,
  refetch,
  inFocus,
  setInFocus,
  isLocked = false,
  isLastMenstrualPeriodEnabled,
  isLastMenstrualPeriodRequired,
}: VitalsProp): JSX.Element => {
  const { getToken } = useAuth()
  const { clinic } = useClinicStore()
  const { chiefVital, mutateChiefVital } = useVitalsStore()

  let initialIsMetricWeight = false
  if (chiefVital?.isMetricWeight !== null && chiefVital?.isMetricWeight !== undefined) {
    initialIsMetricWeight = chiefVital.isMetricWeight
  } else if (clinic?.measurementUnitId === 3) {
    initialIsMetricWeight = JSON.parse(localStorage.getItem(IS_METRIC_STORAGE_KEY) ?? 'false')
  } else {
    initialIsMetricWeight = clinic?.measurementUnitId === 2
  }

  const initialVitals = {
    isMetricWeight: initialIsMetricWeight,
    ...chiefVital
  }

  const [vitals, setVitals] = React.useState<Vital>(initialVitals)
  const debouncedVitals = useDebounce<Vital>(vitals, 500)
  const [weightBuffer, setWeightBuffer] = React.useState<string>(initialVitals?.weight?.toString() ?? '')
  const [heightInchesBuffer, setHeightInchesBuffer] = React.useState<string>(initialVitals?.heightInches?.toString() ?? '')
  const [temperatureBuffers, setTemperatureBuffers] = React.useState<string[]>(initialVitals?.vitalDetails?.map(vitalDetail => vitalDetail.temperature?.toString() ?? '') ?? [])

  const { data: temperatureMethods } = useQueryGetTemperatureMethods(getToken)

  React.useEffect(() => {
    void mutateChiefVital(visitId, { ...debouncedVitals, visitId, patientId }, getToken)
  }, [debouncedVitals])

  const handleVitalsChange = (updates: Partial<Vital>): void => {
    if (!('isMetricWeight' in updates)) {
      updates.isMetricWeight = clinic?.measurementUnitId === 3 ? initialIsMetricWeight : clinic?.measurementUnitId === 2
    }
    // const fullUpdate = { visitId, patientId, ...updates }

    // void mutateChiefVital(visitId, fullUpdate, getToken)

    setVitals({ ...vitals, ...updates, vitalDetails: mergeDetails(vitals?.vitalDetails ?? [], updates.vitalDetails ?? []) })
  }

  const handleVitalsDetailChange = (
    index: number,
    updates: Partial<VitalDetail>
  ): void => {
    const detailUpdates = vitals?.vitalDetails?.map((_, i) => {
      if (i === index) {
        return updates
      } else {
        return {}
      }
    }) ?? []

    if (index >= detailUpdates.length) {
      detailUpdates.push(updates)
    }

    // void mutateChiefVital(visitId, { vitalDetails: detailUpdates, visitId, patientId }, getToken)
    setVitals({ ...vitals, vitalDetails: mergeDetails(vitals?.vitalDetails ?? [], detailUpdates) })
  }

  const addVitalSet = (): void => {
    handleVitalsChange({
      vitalDetails: [
        ...(vitals?.vitalDetails ?? []),
        {},
      ],
    })
  }

  const handleChangeMethod = (
    index: number,
    event: React.SyntheticEvent,
    newValue: { id: number; label: string } | null
  ): void => {
    if (newValue) {
      handleVitalsDetailChange(index, {
        temperatureMethodId: newValue.id,
      })
    } else {
      handleVitalsDetailChange(index, { temperatureMethodId: null })
    }
  }

  // needs to cover a few cases: details exist, details is empty list, and details is undefined
  const details = isEmpty(vitals?.vitalDetails) ? [{}] : vitals?.vitalDetails ?? [{}]
  return (
    <TileLayout
      ref={ref}
      id="internalVitals"
      inFocus={inFocus}
      setInFocus={setInFocus}
      isLocked={isLocked}
    >
      <TitleRow>
        <Title titleText="Vitals" />
        <TitleCheckBox
          title="Refused"
          value={vitals?.refused ?? false}
          setValue={(refused: boolean) => handleVitalsChange({ refused })}
        />
      </TitleRow>
      <>
        {details.map((vitalSet, index) => (
          <div key={index}>
            <TileRow>
              <Stack
                direction={'row'}
                justifyContent={'flex-start'}
                flexWrap={'wrap'}
                gap={'20px'}
                width="100%"
                paddingBottom={'8px'}
              >
                <ValueContainer>
                  <TileInputLabel>BP (mmHg)</TileInputLabel>
                  <HeightRow>
                    <BPTextField
                      value={vitalSet.bpSystolic === 0 || vitalSet.bpSystolic === null ? '' : vitalSet.bpSystolic}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const regex = /^[0-9\b]+$/
                        if (
                          e.target.value === '' ||
                          regex.test(e.target.value)
                        ) {
                          handleVitalsDetailChange(index, {
                            bpSystolic: +e.target.value,
                          })
                        }
                      }}
                      placeholder="Systolic"
                      inputProps={{ maxLength: 3, 'data-testid': 'bps' }}
                    />
                    /
                    <BPTextField
                      value={vitalSet.bpDiastolic === 0 || vitalSet.bpDiastolic === null ? '' : vitalSet.bpDiastolic}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const regex = /^[0-9\b]+$/
                        if (
                          e.target.value === '' ||
                          regex.test(e.target.value)
                        ) {
                          handleVitalsDetailChange(index, {
                            bpDiastolic: +e.target.value,
                          })
                        }
                      }}
                      inputProps={{ maxLength: 3, 'data-testid': 'bpd' }}
                      placeholder="Diastolic"
                    />
                  </HeightRow>
                </ValueContainer>
                <ValueContainer>
                  <TileInputLabel>Pulse (BPM)</TileInputLabel>
                  <TileTextField
                    value={vitalSet.pulse === 0 || vitalSet.pulse === null ? '' : vitalSet.pulse}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const regex = /^[0-9\b]+$/
                      if (e.target.value === '' || regex.test(e.target.value)) {
                        handleVitalsDetailChange(index, {
                          pulse: +e.target.value,
                        })
                      }
                    }}
                    inputProps={{ maxLength: 3, 'data-testid': 'pulse' }}
                  />
                </ValueContainer>
                {index === 0 && (
                  <>
                    <ValueContainer>
                      <TileRow>
                        <TileInputLabel>
                          {'Weight ' +
                            (vitals?.isMetricWeight ? '(kg)' : '(lb)')}
                        </TileInputLabel>
                        {clinic?.measurementUnitId === 3 ? (
                          <FormControl>
                            <RadioGroup
                              row
                              value={vitals?.isMetricWeight ? 'kg' : 'lb'}
                              onChange={(e) => {
                                const newIsMetricWeight =
                                  e.target.value === 'kg'
                                handleVitalsChange({
                                  isMetricWeight: newIsMetricWeight,
                                })
                                localStorage.setItem(
                                  IS_METRIC_STORAGE_KEY,
                                  newIsMetricWeight.toString()
                                )
                              }}
                            >
                              <FormControlLabel
                                value="lb"
                                control={<Radio data-testid="lb" />}
                                label="lb"
                              />
                              <FormControlLabel
                                value="kg"
                                control={<Radio data-testid="kg" />}
                                label="kg"
                              />
                            </RadioGroup>
                        </FormControl>
                          )
                        : null
                      }
                    </TileRow>
                    <TileTextField
                      value={weightBuffer === '0' ? '': weightBuffer}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const regex = /^\d{0,3}(\.\d{0,2})?$/
                        if (regex.test(e.target.value) && e.target.value !== '') {
                          setWeightBuffer(e.target.value)
                          if (!isNaN(parseFloat(e.target.value))) {
                            handleVitalsChange({ weight: parseFloat(e.target.value) })
                          }
                        } else if (e.target.value === '') {
                          setWeightBuffer('')
                          handleVitalsChange({ weight: undefined })
                        }
                      }}
                      inputProps={{ maxLength: 6, 'data-testid': 'weight' }}
                    />
                  </ValueContainer>
                  <ValueContainer>
                    <TileInputLabel>Height (Feet and Inches)</TileInputLabel>
                    <HeightRow sx={{ gap: '0px' }}>
                      <FeetTextField
                        placeholder="Feet"
                        value={vitals?.heightFeet && vitals?.heightFeet > 0 ? vitals?.heightFeet : '' }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const regex = /^\d{0,1}(\.\d{0,2})?$/
                          if (e.target.value === '' || regex.test(e.target.value)) {
                            handleVitalsChange({ heightFeet: +e.target.value })
                          }
                        }}
                        inputProps={{ maxLength: 1, 'data-testid': 'height' }}
                      />
                      <InchesTextField
                        placeholder="Inches"
                        value={heightInchesBuffer === '0' ? '' : heightInchesBuffer}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const regex = /^\d{0,2}(\.\d{0,2})?$/
                          if (regex.test(e.target.value) && e.target.value !== '') {
                            setHeightInchesBuffer(e.target.value)
                            if (!isNaN(parseFloat(e.target.value))) {
                              handleVitalsChange({ heightInches: parseFloat(e.target.value) })
                            }
                          } else if (e.target.value === '') {
                            setHeightInchesBuffer('')
                            handleVitalsChange({ heightInches: 0 })
                          }
                        }}
                        inputProps={{ 'data-testid': 'inches' }}
                      />
                    </HeightRow>
                  </ValueContainer>
                  <ValueContainer>
                    <TileInputLabel>BMI</TileInputLabel>
                    <TileTextField
                      disabled
                      value={chiefVital?.bmi && chiefVital.bmi > 0 ? chiefVital?.bmi.toFixed(2) : ''}
                      placeholder='Auto Calculated'
                    />
                  </ValueContainer>
                </>
              )
            }
            <ValueContainer>
              <TileInputLabel>Respiratory Rate (Per Min)</TileInputLabel>
              <TileTextField
                value={vitalSet.respiratoryRate === 0 || vitalSet.respiratoryRate === null ? '' : vitalSet.respiratoryRate}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const regex = /^[0-9\b]+$/
                  if (e.target.value === '' || regex.test(e.target.value)) {
                    handleVitalsDetailChange(index, { respiratoryRate: +e.target.value })
                  }
                }}
                inputProps={{ maxLength: 2, 'data-testid': 'respiratoryRate' }}
              />
            </ValueContainer>
            <TileRow key={index}>
            <ValueContainer>
                    <TileInputLabel>Temperature (°F) and Method</TileInputLabel>
                    <TemperatureRow>
                      <TemperatureTextField
                        placeholder="°F"
                        value={(temperatureBuffers[index] && temperatureBuffers[index] !== '0') ? temperatureBuffers[index] : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const regex = /^[0-9]{0,3}(\.[0-9]{0,1})?$/
                          if (regex.test(e.target.value) && e.target.value !== '') {
                            const newTempBuffer = [...temperatureBuffers]
                            newTempBuffer[index] = e.target.value
                            setTemperatureBuffers(newTempBuffer)
                            if (!isNaN(parseFloat(e.target.value))) {
                              handleVitalsDetailChange(index, { temperature: parseFloat(e.target.value) })
                            }
                          } else if (e.target.value === '') {
                            const newTempBuffer = [...temperatureBuffers]
                            newTempBuffer[index] = ''
                            setTemperatureBuffers(newTempBuffer)
                            handleVitalsDetailChange(index, { temperature: 0 })
                          }
                        }}
                        inputProps={{
                          maxLength: 5,
                          'data-testid': 'temperature',
                        }}
                      />
                      <MethodSearchBox
                        options={
                          temperatureMethods?.map((method) => ({
                            id: method.id ?? 0,
                            label: method.displayValue ?? '',
                          })) ?? []
                        }
                        value={{
                          id: vitalSet?.temperatureMethodId ?? 0,
                          label:
                            temperatureMethods?.find(
                              (method) =>
                                method.id ===
                                  vitalSet?.
                                    temperatureMethodId ?? 0
                            )?.displayValue ?? '',
                        }}
                        onChange={(event: any, newValue: any) =>
                          handleChangeMethod(index, event, newValue)
                        }
                        dataTestId={`temperatureMethod-${index}`}
                        customWidth={160}
                      />
                    </TemperatureRow>
                  </ValueContainer>
            </TileRow>
            <ValueContainer>
              <TileInputLabel>O<sub>2</sub> Sat (%)</TileInputLabel>
              <OxygenBox>
                <TileTextField
                  inputProps={{ maxLength: 3, 'data-testid': 'o2' }}
                  value={vitalSet.oxygenSaturation === 0 || vitalSet.oxygenSaturation === null ? '' : vitalSet.oxygenSaturation}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const regex = /^[0-9\b]+$/
                    if (e.target.value === '' || (regex.test(e.target.value) && Number(e.target.value) <= 100)) {
                      handleVitalsDetailChange(index, { oxygenSaturation: +e.target.value })
                    }
                  }}
                />
              </OxygenBox>
            </ValueContainer>
            {
              isLastMenstrualPeriodEnabled && index === 0
                ? <LMPInput visitId={visitId} patientId={patientId}/>
                : null
            }
            <ValueContainer>
              <TileInputLabel>Visual Acuity</TileInputLabel>
              <HeightRow sx={{ gap: '2px', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ marginRight: '4px' }}>20 /</Typography>
                <VisualAcuityTextField
                  placeholder="Left"
                  value={vitalSet.visualAcuityLeft === 0 || isEmpty(vitalSet.visualAcuityLeft) ? '' : vitalSet.visualAcuityLeft}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!isNaN(+e.target.value)) {
                      handleVitalsDetailChange(index, {
                        visualAcuityLeft: +e.target.value
                      })
                    }
                  }}
                  inputProps={{
                    maxLength: 3,
                    'data-testid': 'visual-acuity-left'
                  }}
                  sx={{ width: '50%' }}
                />
                <Typography variant="body1" sx={{ marginLeft: '10px', marginRight: '4px' }}>20 /</Typography>
                <VisualAcuityTextField
                  placeholder="Right"
                  value={vitalSet.visualAcuityRight === 0 || isEmpty(vitalSet.visualAcuityRight) ? '' : vitalSet.visualAcuityRight}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (!isNaN(+e.target.value)) {
                      handleVitalsDetailChange(index, {
                        visualAcuityRight: +e.target.value
                      })
                    }
                  }}
                  inputProps={{
                    maxLength: 3,
                    'data-testid': 'visual-acuity-right'
                  }}
                  sx={{ width: '50%' }}
                />
              </HeightRow>
            </ValueContainer>
            <TileDateTimePicker
              value={vitalSet?.vitalDateTime ? new Date(vitalSet?.vitalDateTime ?? '') : null}
              onChange={(date: any) => {
                if (date instanceof Date && !isNaN(date.getTime())) {
                  handleVitalsDetailChange(index, { vitalDateTime: date.toISOString() })
                } else if (date === null) {
                  handleVitalsDetailChange(index, { vitalDateTime: '' })
                }
              }}
              label='Date Taken'
              dataTestId={`vitalDateTime-${index}`}
            />
          </Stack>
          </TileRow>
          {index === 0 &&
          (<Stack direction={'row'} width={760} alignItems='center' gap={2}>
            <TileInputLabel>Patient Air Source:</TileInputLabel>
            <RadioGroup
              row
              value={vitals?.needsSupplementalOxygen ? 'yes' : 'no'}
              onChange={(e) => {
                handleVitalsChange({ needsSupplementalOxygen: e.target.value === 'yes' })
              }}
              sx={{ paddingTop: '12px' , paddingBottom: '8px', fontSize: '14px !important', fontWeight: 400}}
            >
              <FormControlLabel
                value="no"
                control={<Radio />}
                label="Room Air"
                sx={{  '& .MuiTypography-root': { fontSize: '14px !important', fontWeight: 400}}}
              />
              <FormControlLabel
                sx={{  '& .MuiTypography-root': { fontSize: '14px !important', fontWeight: 400}}}
                value="yes" control={<Radio />}
                label="Supplemental O2 Tank" />
            </RadioGroup>
          </Stack>)}
          <TileRow>
            <FreeTextNotes
              notes={vitalSet.vitalNote ?? ''}
              setNotes={(note: string) => {
                handleVitalsDetailChange(index, { vitalNote: note })
              }}
              expanded={!isEmpty(vitalSet.vitalNote)}
              sectionName={`internalVitals-${index}`}
            />
          </TileRow>
        </div>
      ))}
      </>
      <Stack justifyContent={'flex-end'} width="100%" direction={'row'}>
        <Button onClick={addVitalSet}>Add Another Vitals Set</Button>
      </Stack>
    </TileLayout>
  )
}

interface VitalsProp {
  visitId: number;
  patientId: number;
  ref: React.RefObject<HTMLDivElement>;
  refetch: any;
  inFocus: boolean;
  setInFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
  isLastMenstrualPeriodRequired: boolean;
  isLastMenstrualPeriodEnabled: boolean;
}
