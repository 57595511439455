import React from 'react'
import styled from '@emotion/styled'
import { Stack } from '@mui/system'
import {
  type AccountUser,
  type ClinicDashboard,
  type Insurance,
  type Room,
} from '@/types'
import {
  Tooltip,
  IconButton,
  Typography,
  Box,
  Popover,
  Chip,
  Paper,
  MenuItem,
  type Theme,
  Menu,
} from '@mui/material'
import { ProcedureStatusPopup, VitalsPopup } from '@/components'
import {
  isEmpty,
  formatPhoneNumber,
  formatDateOnly,
  isDateOnlyValid,
  formatAgeWithRules,
} from '@/utility'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import {
  getProviderFullName,
  gotoProfile,
  getStatusIcon,
  getStatusString,
  getAppointmentStatusColor,
  getVisitStatusString,
  getVisitStatusColor,
  visitStatus,
} from '@/components/Columns/ColumnUtils'
import { type QueryObserverResult } from '@tanstack/react-query'
import { type MRT_ColumnDef } from 'material-react-table'
import { format } from 'date-fns'
import { AvatarImage } from '@/components/AvatarImage'
import AddIcon from '@mui/icons-material/Add'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined'
import RemoveModeratorOutlinedIcon from '@mui/icons-material/RemoveModeratorOutlined'
import TagOutlinedIcon from '@mui/icons-material/TagOutlined'

const StyledTableText = styled(Typography)({
  fontSize: '14px',
  color: '#454545',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const Tag = styled(Typography)({
  backgroundColor: '#EBF0F9',
  fontSize: '12px',
  fontWeight: 500,
  borderRadius: '5px',
  textAlign: 'center',
  marginRight: '10px',
  width: 'fit-content',
  height: 'fit-content',
  padding: '2px 4px',
  color: 'black',
  cursor: 'pointer',
})

const AssignAction = styled(Box)({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '110px',
  gap: '10px',
  '& div': {
    display: 'none',
  },
  '& p': {
    display: 'block',
  },
  '&:hover, &.hover': {
    '& .btn-assign-action, .btn-assign-provider-action': {
      display: 'flex',
    },
  },
})

const getInsuranceNames = (insuranceList: Insurance[]): string =>
  insuranceList.reduce((acc, obj, index) => {
    if (obj.companyName) {
      return acc + (index > 0 ? ', ' : '') + obj.companyName
    }
    return acc
  }, '')

export const useGetOriginalColumns = (
  setCurrentVisit: any,
  setIsSendRecordsOpen: any,
  setCreateTaskOpen: any,
  setCurrentTask: any,
  setStickyNotesOpen: any,
  setCurrentPatientId: any,
  setOpen: any,
  handleAssignRoom: any,
  handleAssignProvider: any,
  setHoverAssignRoomId: any,
  setHoverAssignProviderId: any,
  loadClinicDashboardData: () => Promise<
    QueryObserverResult<ClinicDashboard, unknown>
  >,
  loadDashboardAppointments: () => any,
  rooms: Room[],
  clinicians: AccountUser[],
  clinicId: number,
  handleDoubleClickTable: any,
  hoverAssignProviderId: any,
  hoverAssignRoomId: any,
  updateVisitStatus: any,
  theme: Theme
): Array<MRT_ColumnDef<{}>> => {
  const onUpdate = (): void => {
    loadClinicDashboardData().catch((error: any) => {
      throw error
    })
    loadDashboardAppointments().catch((error: any) => {
      throw error
    })
  }

  const getOriginalColumns = React.useCallback(() => {
    return [
      {
        header: 'Actions',
        accessorFn: () => 'actions',
        id: 'clinic-actions',
        enableSorting: false,
        size: 160,
        minSize: 160,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: '#EBF0F9',
          },
        },
        Cell: ({ row }: any) => (
          <Stack direction="row" sx={{ alignItems: 'center' }}>
            <Tooltip title="View Profile" arrow>
              <IconButton
                onClick={() =>
                  gotoProfile(row?.original?.allDetails?.patient?.id as number)
                }
                size="large"
              >
                <PersonOutlineIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Send Record" arrow>
              <IconButton
                onClick={() => {
                  setCurrentVisit(row?.original?.allDetails?.visit ?? {})
                  setIsSendRecordsOpen(true)
                }}
                size="large"
              >
                <SendOutlinedIcon />
              </IconButton>
            </Tooltip>
            {process.env.SHOW_TASK_MANAGEMENT_BUTTON === 'true' && (
              <Tooltip title="Create Task" arrow>
                <IconButton
                  onClick={() => {
                    if (isEmpty(row?.original?.allDetails?.visit?.id)) return
                    setCurrentTask({
                      visitId: row?.original?.allDetails?.visit?.id,
                      patientId: row?.original?.allDetails?.patient?.id,
                      accountUserId: row?.original?.providerId,
                    })
                    setCreateTaskOpen(true)
                  }}
                  size="large"
                >
                  <AssignmentOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Patient Notes" arrow>
              <IconButton
                onClick={() => {
                  setCurrentPatientId(
                    row?.original?.allDetails?.patient?.id ?? 0
                  )
                  setStickyNotesOpen(true)
                }}
                size="large"
              >
                <FeedOutlinedIcon
                  sx={{
                    fill: row?.original?.allDetails?.patient?.stickyNote?.length
                      ? '#2E3353'
                      : '#2E335360',
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Record" arrow>
              <IconButton
                onClick={() => {
                  setCurrentVisit(row.original.allDetails.visit)
                  setOpen(true)
                }}
                disabled={process.env.DISABLE_PATIENT_REMOVE_BUTTON === 'true'}
                aria-label="Delete Record"
                size="large"
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ),
      },
      {
        id: 'patient_details',
        header: 'Patient Details',
        enableSorting: true,
        accessorFn: (row: any): string =>
          `${row?.patientDetails?.firstName as string} ${row?.patientDetails?.lastName as string}`,
        sortingFn: 'alphanumeric',
        size: 170,
        minSize: 210,
        maxSize: 230,
        muiTableHeadCellProps: {
          sx: {
            backgroundColor: '#EBF0F9',
          },
        },
        Cell: ({ row }: any) => (
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <AvatarImage
              src={row.original.patientDetails.avatar ?? '/user.png'}
              alt="avatar"
              width={32}
              height={32}
              loading="lazy"
            />
            <Stack direction="column">
              <Typography
                sx={{ mb: 1, fontWeight: 600 }}
                onClick={() => handleDoubleClickTable(row)}
              >
                {`${row?.original?.patientDetails?.firstName as string} ${
                  row?.original?.patientDetails?.lastName as string
                }`}
              </Typography>
              <Stack direction="row">
                <Tooltip title={row?.original?.patientDetails?.id} arrow>
                  <Stack direction="row" alignItems="center">
                    <TagOutlinedIcon fontSize="small" sx={{ color: '#AAA' }} />
                  </Stack>
                </Tooltip>
                <Tooltip title={row?.original?.patientDetails?.phone} arrow>
                  <Stack direction="row" alignItems="center">
                    <LocalPhoneOutlinedIcon
                      fontSize="small"
                      sx={{ color: '#AAA' }}
                    />
                  </Stack>
                </Tooltip>
                <Tooltip
                  title={
                    !row?.original?.allDetails?.visit?.selfPay &&
                    row?.original?.allDetails?.patient?.patientInsuranceList
                      .length > 0
                      ? getInsuranceNames(
                          row?.original?.allDetails?.patient
                            ?.patientInsuranceList as Insurance[]
                        )
                      : 'Self Pay'
                  }
                  arrow
                >
                  <Stack direction="row" alignItems="center">
                    {!row?.original?.allDetails?.visit?.selfPay &&
                    row?.original?.allDetails?.patient?.patientInsuranceList
                      .length > 0 ? (
                      <HealthAndSafetyOutlinedIcon
                        fontSize="small"
                        sx={{ color: '#AAA' }}
                      />
                    ) : (
                      <RemoveModeratorOutlinedIcon
                        fontSize="small"
                        sx={{ color: '#AAA' }}
                      />
                    )}
                  </Stack>
                </Tooltip>
                <Typography
                  sx={{ color: theme.palette.text.secondary, fontSize: '12px' }}
                >
                  {isDateOnlyValid(row?.original?.patientDetails?.dateOfBirth)
                    ? `(${formatDateOnly(
                        row?.original?.patientDetails?.dateOfBirth
                      )} - ${formatAgeWithRules(
                        row?.original?.patientDetails?.dateOfBirth
                      )})`
                    : ''}
                  {`${
                    !isEmpty(row.original.patientDetails.gender) ? ', ' : ''
                  } ${row.original.patientDetails.gender as string}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        ),
      },
      {
        id: 'reason_type_of_visit',
        header: 'Reason/Type of Visit',
        accessorFn: (row: any) => {
          const visitType = row.reasonVisitType?.visitType || ''
          const reasonForVisit = row.reasonVisitType?.reasonForVisit?.join(' ') || ''
          return `${visitType} ${reasonForVisit}`
        },
        size: 160,
        minSize: 160,
        maxSize: 170,
        enableSorting: true,
        sortingFn: 'alphanumeric',
        Cell: ({ row }: any) => (
          <Stack direction="column" sx={{ width: '170px' }}>
            <Stack direction="row" sx={{ marginBottom: '5px' }}>
              <Tooltip
                title={
                  <>
                    {row?.original?.reasonVisitType?.reasonForVisit.includes(
                      'Other'
                    )
                      ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        row.original.reasonVisitType.notes
                        ? `Other - ${row.original.reasonVisitType.notes}`
                        : 'Other'
                      : row?.original?.reasonVisitType?.reasonForVisit.map(
                          (reasonItem: string) => (
                            <div key={reasonItem}>{reasonItem}</div>
                          )
                        )}
                  </>
                }
                arrow
              >
                <StyledTableText>
                  {row?.original?.reasonVisitType?.reasonForVisit.includes(
                    'Other'
                  )
                    ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                      row.original.reasonVisitType.notes
                      ? `Other - ${row.original.reasonVisitType.notes}`
                      : 'Other'
                    : row?.original?.reasonVisitType?.reasonForVisit?.join(
                        ', '
                      )}
                </StyledTableText>
              </Tooltip>
            </Stack>
            <Tag>{`${
              row?.original?.reasonVisitType?.visitType as string
            }`}</Tag>
          </Stack>
        ),
      },
      {
        id: 'time_since_arrival',
        header: 'Total Time',
        accessorKey: 'timeSinceArrival',
        accessorFn: (row: any) => row.timeSinceArrival,
        size: 110,
        minSize: 100,
        maxSize: 150,
        enableSorting: true,
        sortingFn: 'basic',
        Cell: ({ row }: any) => {
          return (
            <StyledTableText>{`${row.original.timeSinceArrival} min`}</StyledTableText>
          )
        },
      },
      {
        id: 'date_of_service',
        header: 'Date of Service',
        accessorKey: 'scheduleStart',
        size: 110,
        minSize: 100,
        maxSize: 150,
        Cell: ({ row }: any) => (
          <StyledTableText
            sx={{ fontSize: '14px', color: '#454545', width: '100px' }}
          >
            {row?.original?.dateOfService}
          </StyledTableText>
        ),
      },
      {
        id: 'room',
        header: 'Room',
        accessorKey: 'room',
        maxSize: 120,
        size: 120,
        enableSorting: false,
        Cell: ({ row, cell }: any) => {
          return (
            <>
              <AssignAction
                className={
                  row?.original?.allDetail?.visit?.id === hoverAssignRoomId
                    ? 'hover'
                    : ''
                }
                onClick={() =>
                  setHoverAssignRoomId(row?.original?.allDetails.visit.id)
                }
              >
                <Tooltip title={cell.getValue() ?? ''} arrow>
                  <StyledTableText id={cell.id}>
                    {cell.getValue() ?? '-'}
                  </StyledTableText>
                </Tooltip>
                <PopupState variant="popover" popupId="assignroom-popover">
                  {(popupState) => (
                    <>
                      <Box
                        className="btn-assign-action"
                        {...bindTrigger(popupState)}
                      >
                        <IconButton size="large">
                          <AddIcon />
                        </IconButton>
                      </Box>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        anchorEl={document.getElementById(cell.id)}
                        onClose={(ev: any): void => {
                          popupState.close()
                          setHoverAssignRoomId('')
                          ev.stopPropagation()
                        }}
                      >
                        <Paper
                          sx={{
                            width: '275px',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRadius: '10px',
                          }}
                        >
                          {rooms
                            ?.filter((room) => room.clinicId === clinicId)
                            .map((room) => {
                              return (
                                <MenuItem
                                  key={room.id}
                                  value={room.id}
                                  sx={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                  }}
                                  onClick={() => {
                                    handleAssignRoom(
                                      row?.original?.allDetails,
                                      room?.id!,
                                      setHoverAssignRoomId
                                    )
                                      .catch((err: Error) => {
                                        throw err
                                      })
                                      .finally(() => popupState.close())
                                  }}
                                >
                                  {room.name}
                                </MenuItem>
                              )
                            })}
                        </Paper>
                      </Popover>
                    </>
                  )}
                </PopupState>
              </AssignAction>
            </>
          )
        },
      },
      {
        id: 'provider',
        header: 'Provider',
        accessorKey: 'provider',
        size: 120,
        minSize: 120,
        enableSorting: true,
        sortingFn: (rowA: any, rowB: any, columnId: any) => {
          const providerA = getProviderFullName(rowA.original.providerId, clinicians) || ''
          const providerB = getProviderFullName(rowB.original.providerId, clinicians) || ''
          return providerA.localeCompare(providerB)
        },
        Cell: ({ row, cell }: any) => {
          return (
            <>
              <AssignAction
                className={
                  row?.original?.allDetails?.visit?.id === hoverAssignProviderId
                    ? 'hover'
                    : ''
                }
                onClick={() =>
                  setHoverAssignProviderId(row?.original?.allDetails.visit.id)
                }
              >
                <Tooltip
                  title={
                    row.original.providerId
                      ? getProviderFullName(row.original.providerId, clinicians)
                      : ''
                  }
                  arrow
                >
                  <StyledTableText id={cell.id}>
                    {row.original.providerId
                      ? getProviderFullName(row.original.providerId, clinicians)
                      : '-'}
                  </StyledTableText>
                </Tooltip>
                <PopupState variant="popover" popupId="assignprovider-popover">
                  {(popupState) => (
                    <>
                      <Box
                        className="btn-assign-provider-action"
                        {...bindTrigger(popupState)}
                      >
                        <IconButton size="large">
                          <AddIcon />
                        </IconButton>
                      </Box>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        anchorEl={document.getElementById(cell.id)}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        onClose={(ev: any): void => {
                          popupState.close()
                          setHoverAssignProviderId('')
                          ev.stopPropagation()
                        }}
                      >
                        <Paper
                          sx={{
                            width: '275px',
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRadius: '10px',
                          }}
                        >
                          {clinicians
                            ?.filter(
                              (clinician) =>
                                clinician.role?.includes(2) === true ||
                                clinician.npiNumber
                            ).sort((a, b) => (a.firstName ?? '').localeCompare(b.firstName ?? ''))
                            .map((provider: AccountUser) => {
                              return (
                                <MenuItem
                                  key={provider.username}
                                  value={getProviderFullName(
                                    provider.id!,
                                    clinicians
                                  )}
                                  sx={{ fontSize: '14px' }}
                                  onClick={() => {
                                    handleAssignProvider(
                                      row?.original?.allDetails,
                                      provider.id!,
                                      row?.original?.setToastMessage
                                    )
                                      .catch((err: Error) => {
                                        throw err
                                      })
                                      .finally(() => popupState.close())
                                  }}
                                >
                                  {getProviderFullName(
                                    provider.id!,
                                    clinicians
                                  )}
                                </MenuItem>
                              )
                            })}
                        </Paper>
                      </Popover>
                    </>
                  )}
                </PopupState>
              </AssignAction>
            </>
          )
        },
      },
      {
        id: 'staff',
        header: 'Staff',
        accessorFn: ({ row }: any) => row?.staff.join(' '),
        minSize: 150,
        size: 150,
        enableSorting: false,
        Cell: ({ row }: any) =>
          !isEmpty(row?.original?.staff) ? (
            <Stack
              direction="row"
              sx={{ alignItems: 'center', gap: '5px', width: '100px' }}
            >
              <Stack direction="column">
                <StyledTableText>
                  {!isEmpty(row?.original?.staff) && row.original?.staff[0]}
                </StyledTableText>
                <StyledTableText>
                  {!isEmpty(row?.original?.staff) &&
                    row?.original?.staff.length > 1 &&
                    row?.original?.staff[1]}
                </StyledTableText>
              </Stack>
              <Tooltip
                title={
                  <>
                    {row?.original?.staff.slice(2).map((staff: string) => (
                      <div key={staff}>{staff}</div>
                    ))}
                  </>
                }
                arrow
              >
                {row?.original?.staff.length > 2 ? (
                  <Tag>+{row?.original?.staff?.length - 2}</Tag>
                ) : (
                  <div />
                )}
              </Tooltip>
            </Stack>
          ) : (
            <StyledTableText>-</StyledTableText>
          ),
      },
      {
        id: 'vitals',
        header: 'Vitals',
        size: 40,
        Cell: ({ row }: any) => (
          <VitalsPopup
            vitals={row?.original?.vitals}
            dateOfBirth={row?.original?.patient_DOB}
          />
        ),
      },
      {
        id: 'order',
        header: 'Orders & Procedures',
        size: 130,
        maxSize: 130,
        Cell: ({ row }: any) => {
          const updateStatus = (): void => {
            onUpdate()
          }
          return (
            <Stack
              direction="column"
              sx={{ justifyContent: 'center', gap: '5px', width: '130px' }}
            >
              {row?.original?.orders?.map((order: any) => (
                <Tooltip key={order?.name} title={order?.name} arrow>
                  <ProcedureStatusPopup
                    patient={row?.original?.allDetails.patient}
                    procedure={order}
                    mode="order"
                    isProcedure={order?.isProcedure}
                    isOrder={order?.isOrder}
                    showStatus={false}
                    clinicalProcedure={order?.clinicalProcedure}
                    chiefProcedures={order?.chiefProcedures}
                    onUpdate={updateStatus}
                    providerId={row.original.providerId}
                    visitId={row.original.allDetails.visit.id}
                  />
                </Tooltip>
              ))}
            </Stack>
          )
        },
      },
      {
        id: 'status',
        header: 'Status',
        enableSorting: true,
        accessorFn: ({ row }: any) => {
          const visitDetails = row?.original?.allDetails?.visit
          if (visitDetails) {
            const statusId = visitDetails.visitStatusId ?? 'no-status'
            const visitId = visitDetails.id ?? 'no-id'
            return `${statusId as number}_${visitId as number}`
          }
          return 'missing-details'
        },
        sortingFn: 'alphanumeric',
        size: 100,
        minSize: 100,
        Cell: ({ row }: any) => {
          const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
            null
          )
          let tooltipOpen = false
          const menuOpen = Boolean(anchorEl)

          const statusId = row?.original?.allDetails?.visit?.visitStatusId
          const status = getVisitStatusString(statusId)

          const calculatedStatus =
            row.original.allDetails?.visit?.isVisitProcedurePendingResult &&
            !row.original.allDetails.visit.isLocked
              ? 'Pending Results'
              : status
          const statusColor = getVisitStatusColor(calculatedStatus)
          const handleChipClick = (
            event: React.MouseEvent<HTMLElement>
          ): void => {
            tooltipOpen = false
            if (!isEmpty(row?.original?.allDetails?.visit?.lockingDateTime)) {
              return
            }
            setAnchorEl(event.currentTarget)
          }

          const handleClose = (): void => {
            setAnchorEl(null)
          }

          const handleStatusChange = async (
            statusId: number,
            visitId: number
          ): Promise<void> => {
            try {
              await updateVisitStatus.mutateAsync({
                visitId,
                visitStatusId: statusId,
              })
              onUpdate()
              handleClose()
            } catch (error) {
              console.error('Error updating status:', error)
            } finally {
              onUpdate()
              handleClose()
            }
          }

          const tooltipTitle = !isEmpty(
            row?.original?.allDetails?.visit?.lockingDateTime
          )
            ? 'Cannot edit locked status'
            : 'Click to edit status'

          return (
            <Tooltip title={tooltipTitle} placement="top" arrow open={tooltipOpen} onClose={() => { tooltipOpen = false }}>
              <Stack
                direction={'row'}
                alignContent={'center'}
                alignItems={'center'}
                justifyContent={'flex-start'}
              >
                <div style={{ textAlign: 'center', width: '100%' }}>
                  <Chip
                    key={row?.original?.allDetails?.visit?.id}
                    label={calculatedStatus}
                    onClick={handleChipClick}
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: statusColor,
                    }}
                    onMouseOver={() => { tooltipOpen = true }}
                    icon={getStatusIcon(calculatedStatus)}
                  />
                  <Menu
                    open={menuOpen}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    sx={{ zIndex: 9999 }}
                  >
                    {visitStatus.map((status, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          handleStatusChange(
                            index + 1,
                            row?.original?.allDetails?.visit?.id
                          ).catch((error: any) => {
                            throw error
                          })
                        }}
                      >
                        {status}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </Stack>
            </Tooltip>
          )
        },
      },
    ]
  }, [
    clinicians,
    clinicId,
    hoverAssignProviderId,
    hoverAssignRoomId,
    rooms,
    theme,
  ])
  return getOriginalColumns()
}

export const useGetAppointmentColumns = (
  goToIntake: any,
  setAppointmentId: any,
  setOpen: any,
  updateAppointmentStatus: any,
  refresh: any,
  clinicians: AccountUser[],
  theme: Theme
): Array<MRT_ColumnDef<{}>> => {
  const onUpdate = (): void => {
    refresh()
  }
  return [
    {
      header: 'Actions',
      accessorFn: () => 'actions',
      id: 'appointment-actions',
      enableSorting: false,
      minSize: 100,
      size: 100,
      maxSize: 100,
      Cell: ({ row }: any) => (
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <Tooltip title="Go to Intake" arrow>
            <IconButton onClick={goToIntake(row?.original)} size="large">
              <SendOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Appointment" arrow>
            <IconButton
              onClick={() => {
                setAppointmentId(row?.original?.id)
                setOpen(true)
              }}
              disabled={process.env.DISABLE_PATIENT_REMOVE_BUTTON === 'true'}
              size="large"
            >
              <DeleteOutlineOutlinedIcon
                style={{
                  cursor: 'pointer',
                  width: '17px',
                  height: '17px',
                  fill: 'rgb(38, 47, 98)',
                }}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
    {
      id: 'patient_details',
      header: 'Patient Details',
      enableSorting: true,
      accessorFn: ({ row }: any) =>
        `${row?.patientDetails?.firstName as string} ${
          row?.patientDetails?.lastName as string
        }`,
      sortingFn: 'alphanumeric',
      size: 250,
      minSize: 250,
      Cell: ({ row }: any) => (
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Stack direction="column">
            <Typography sx={{ mb: 1, fontWeight: 600 }}>
              {`${row?.original?.patientDetails?.firstName as string} ${
                row?.original?.patientDetails?.lastName as string
              }`}
            </Typography>
            <Typography
              sx={{ color: theme.palette.text.secondary, fontSize: '12px' }}
            >
              {isDateOnlyValid(row?.original?.patientDetails?.dateOfBirth)
                ? `(${formatDateOnly(
                    row?.original?.patientDetails?.dateOfBirth
                  )} - ${formatAgeWithRules(
                    row?.original?.patientDetails?.dateOfBirth
                  )})`
                : ''}
            </Typography>
          </Stack>
        </Stack>
      ),
    },
    {
      id: 'appointment_start_date',
      header: 'Visit Time',
      enableSorting: true,
      accessorFn: ({ row }: any) => row?.appointmentStartDate ?? '',
      sortingFn: 'alphanumeric',
      size: 200,
      minSize: 200,
      Cell: ({ row }: any) => {
        if (isEmpty(row?.original?.appointmentStartDate)) return <></>
        return (
          <StyledTableText
            sx={{ fontSize: '14px', color: '#454545', width: '150px' }}
          >
            {format(
              new Date(row?.original?.appointmentStartDate),
              'MM/dd/yyyy hh:mm a'
            )}
          </StyledTableText>
        )
      },
    },
    {
      id: 'note',
      header: 'Note',
      accessorFn: ({ row }: any) => row?.notes ?? '',
      size: 200,
      minSize: 200,
      Cell: ({ row }: any) => (
        <Tooltip title={row?.original?.notes} arrow>
          <StyledTableText
            sx={{ fontSize: '14px', color: '#454545', width: '150px' }}
          >
            {row?.original?.notes}
          </StyledTableText>
        </Tooltip>
      ),
    },
    {
      id: 'phone',
      header: 'Phone',
      accessorFn: ({ row }: any) => row?.phone ?? '',
      sortingFn: 'alphanumeric',
      size: 200,
      minSize: 200,
      enableSorting: false,
      Cell: ({ row }: any) => (
        <StyledTableText
          sx={{ fontSize: '14px', color: '#454545', width: '150px' }}
        >
          {formatPhoneNumber(row?.original?.phone) ?? ''}
        </StyledTableText>
      ),
    },
    {
      id: 'email',
      header: 'Email',
      accessorFn: ({ row }: any) => row?.email ?? '',
      sortingFn: 'alphanumeric',
      size: 200,
      minSize: 200,
      enableSorting: false,
      Cell: ({ row }: any) => (
        <StyledTableText
          sx={{ fontSize: '14px', color: '#454545', width: '200px' }}
        >
          {row?.original?.email ?? ''}
        </StyledTableText>
      ),
    },
    {
      id: 'provider',
      header: 'Provider',
      size: 120,
      minSize: 120,
      enableSorting: true,
      accessorFn: ({ row }: any) => {
        return row?.providerId
          ? getProviderFullName(row.providerId, clinicians)
          : ''
      },
      Cell: ({ row, cell }: any) => {
        return (
          <>
            <AssignAction
              className={''}
            >
              <Tooltip
                title={
                  row.original.providerId
                    ? getProviderFullName(row.original.providerId, clinicians)
                    : ''
                }
                arrow
              >
                <StyledTableText id={cell.id}>
                  {row.original.providerId
                    ? getProviderFullName(row.original.providerId, clinicians)
                    : '-'}
                </StyledTableText>
              </Tooltip>
            </AssignAction>
          </>
        )
      },
    },
    {
      id: 'status',
      header: 'Status',
      enableSorting: true,
      accessorFn: ({ row }: any) => {
        const appointmentStatusString = getStatusString(row?.original?.status)
        const statusColor = getAppointmentStatusColor(row?.original?.status)
        return `${appointmentStatusString}_${statusColor}`
      },
      sortingFn: 'alphanumeric',
      size: 100,
      minSize: 100,
      Cell: ({ row }: any) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
          null
        )
        const menuOpen = Boolean(anchorEl)

        const appointmentStatusString = getStatusString(row.original.status)
        const statusColor = getAppointmentStatusColor(row.original.status)

        const handleChipClick = (
          event: React.MouseEvent<HTMLElement>
        ): void => {
          setAnchorEl(event.currentTarget)
        }

        const handleClose = (): void => {
          setAnchorEl(null)
        }

        const handleAppointmentStatusChange = async (
          newStatus: number
        ): Promise<void> => {
          try {
            await updateAppointmentStatus.mutateAsync({
              onlineAppointmentId: row.original.id,
              statusId: newStatus,
            })
          } catch (error) {
            console.error('Error updating appointment status:', error)
          } finally {
            onUpdate()
            handleClose()
          }
        }

        const tooltipTitle = row.original.isClinicAppointment ? (
          'Click to edit status'
        ) : (
          <div style={{ textAlign: 'center' }}>
            {' '}
            Cannot edit status of
            <br />
            publicly created appointment
          </div>
        )
        return (
          <Tooltip title={tooltipTitle} placement="top" arrow>
            <Stack
              direction={'row'}
              alignContent={'center'}
              alignItems={'center'}
              justifyContent={'flex-start'}
            >
              <div style={{ textAlign: 'center', width: '100%' }}>
                <Chip
                  label={appointmentStatusString}
                  key={
                    `${row?.original?.id as number}` +
                    `${appointmentStatusString}`
                  }
                  onClick={handleChipClick}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: statusColor,
                  }}
                  icon={getStatusIcon(row.original.status)}
                />
                <Menu
                  open={menuOpen && row.original.isClinicAppointment}
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  sx={{ zIndex: 9999 }}
                >
                  {[
                    'Pending',
                    'Confirmed',
                    'Booked',
                    'Cancelled',
                    'No Show',
                  ].map((status, index) => (
                    <MenuItem
                      key={status}
                      onClick={() => {
                        void handleAppointmentStatusChange(index)
                      }}
                    >
                      {status}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </Stack>
          </Tooltip>
        )
      },
    },
  ] // }, [goToIntake, setOpen, setAppointmentId, theme])
  // return getAppointmentColumns()
}
