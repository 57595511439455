import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Popover,
  styled
} from '@mui/material'
import ModeIcon from '@mui/icons-material/Mode'
import { AddRow } from '../layout/AddRow'
import { type HistoryOfPresentIllness } from '../../../../types'
import { AddColumn } from '../layout/AddColumn'
import { HPIHoverOver } from './HPIHover'
import { formatDateOnly } from '@/utility'
import { StyledEditButton } from '@/components/PatientProfile'

const AddValue = styled('div')`
  padding: 15px 10px 15px 10px;
`

export const HPIRow = ({
  illness,
  hpi,
  symptoms,
  locations,
  modifyingFactors,
  delete: deleteDX,
  edit: editDX
}: FamilyHistoryRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const handleDialogOpen = (): void => {
    setDialogOpen(true)
  }

  const handleDialogClose = (): void => {
    setDialogOpen(false)
  }

  const handleDeleteConfirm = (): void => {
    deleteDX(hpi)
    handleDialogClose()
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <HPIHoverOver
          name={illness}
          symptons={symptoms}
          locations={locations}
          modifyingFactors={modifyingFactors}
          onset={formatDateOnly(hpi.onset)}
          severity={hpi.severity}
          context={hpi.context ?? ''}
          notes={''}
        />
      </Popover>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={92}>
          <AddValue>
            The patient presents today with <b>{illness}</b>.{' '}
            {modifyingFactors ? (
              <span>
                Modifying factors include <b>{modifyingFactors}</b>.{' '}
              </span>
            ) : null}
            {symptoms ? (
              <span>
                Patient reports <b>{symptoms}</b>,{' '}
              </span>
            ) : null}
            {symptoms ? 'symptoms' : 'Symptoms'} started on{' '}
            <b>{formatDateOnly(hpi.onset)}</b>
            {locations ? ' and are located in the ' : ''}
            <b>{locations ?? ''}</b>.
            {typeof hpi.severity === 'number' ? (
              <>
                {' '}
                The patient reports severity of <b>{hpi.severity}</b>/10.
              </>
            ) : (
              ''
            )}
            {hpi.context && (
              <>
                <br />
                Additional Context:<b>{hpi.context}</b>
              </>
            )}
          </AddValue>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton
            onClick={() => editDX(hpi.id)}
            size="large"
          >
            <ModeIcon />
          </IconButton>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton
            onClick={handleDialogOpen}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </AddColumn>
      </AddRow>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>Delete HPI</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`Are you sure you want to delete ${illness}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledEditButton onClick={handleDialogClose}>
            Cancel
          </StyledEditButton>
          <StyledEditButton
            variant="contained"
            onClick={handleDeleteConfirm}
          >
            Delete
          </StyledEditButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
interface FamilyHistoryRowProps {
  illness: string
  hpi: HistoryOfPresentIllness
  symptoms: string
  locations: string
  modifyingFactors: string
  delete: (hpi: HistoryOfPresentIllness) => void
  edit: (hpiId: number | undefined) => void
}
