import { InputLabel, MenuItem, Select, styled } from '@mui/material'
import React from 'react'
import {
  type Exam,
  type ExamSystem,
  type ExamSystemComponent,
  type ExamSystemComponentResponse,
  type ReviewOfSystem,
  type RosSystemComponentResponse,
} from '@/types'
import { Title, TileLayout } from '@/components'
import { ExamRow } from './ExamRow'

const StyledSelected = styled(Select)({
  width: '240px',
  height: '40px !important',
  backgroundColor: 'white',
  borderRadius: 0,
})
const StyleInputLabel = styled(InputLabel)({
  fontWeight: '300',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#222222',
})

export const ExamTile = ({
  examSystems,
  examSystemComponents,
  type,
  output,
  setOutPut,
  ref,
  visitId,
  inFocus,
  setInFocus,
  isLocked = false,
  isExternalDirty,
  setExternalIsDirty,
  removeAllRowsFlag = false,
}: ExamTileProps): JSX.Element => {
  const [examCount, setExamCount] = React.useState<number>(1)
  const [systemResults, setSystemResults] = React.useState<any>({})
  const [notesValues, setNotesValue] = React.useState<any>({})
  const [selectedId, setSelectedId] = React.useState<number>(0)
  const [isDirty, setIsDirty] = React.useState<boolean>(false)
  const [firstOutput, setFirstOutput] = React.useState<any>([])
  const [dropdownValue, setDropdownValue] = React.useState<any[]>([
    examSystems,
  ])
  const [displayPopup, setDisplayPopup] = React.useState<number>(-1)

  React.useEffect(() => {
    setDropdownValue(examSystems)
  }, [examSystems])

  React.useEffect(() => {
    if (removeAllRowsFlag) {
      removeAllRows()
    }
  }, [removeAllRowsFlag])

  React.useEffect(() => {
    setFirstOutput(output)
  }, [])

  React.useEffect(() => {
    setDropdownValue(examSystems.filter((e) => !(`${e.id!}` in systemResults)))
  }, [systemResults])

  const handleChange = (event: any): void => {
    setSelectedId(event.target.value)
    setDisplayPopup(event.target.value)
  }
  React.useEffect(() => {
    if (selectedId !== 0) {
      if (type === 'exam') {
        output.push({
          visitId,
          examSystemId: selectedId,
          visitExamSystemComponentList: [],
          note: '',
        })
      } else {
        output.push({
          visitId,
          rosSystemId: selectedId,
          visitRosSymptomList: [],
          note: '',
        })
      }
      setDropdownValue(
        examSystems
          .filter((e) => e.id !== selectedId)
          .filter((e) => !(`${e.id!}` in systemResults))
      )
      setSelectedId(0)
    }
  }, [selectedId])

  React.useEffect(() => {
    if (output.length > 0) {
      const newSystemResults = systemResults
      output.forEach((examSystemComponentResponse: Exam | ReviewOfSystem) => {
        const key =
          type === 'exam'
            ? (examSystemComponentResponse as Exam).examSystemId ?? 0
            : (examSystemComponentResponse as ReviewOfSystem).rosSystemId ?? 0
        newSystemResults[`${key}`] = {}
        if (type === 'exam') {
          (
            examSystemComponentResponse as Exam
          ).visitExamSystemComponentList?.forEach(
            (visitExamSystemComponent: ExamSystemComponentResponse) => {
              newSystemResults[`${key}`][
                `${visitExamSystemComponent.examSystemComponentId ?? 0}`
              ] = visitExamSystemComponent.examResultFactorId ?? 0
            }
          )
        } else {
          (
            examSystemComponentResponse as ReviewOfSystem
          ).visitRosSymptomList?.forEach(
            (visitRosSystemComponent: RosSystemComponentResponse) => {
              newSystemResults[`${key}`][
                `${visitRosSystemComponent.rosSymptomId ?? 0}`
              ] = visitRosSystemComponent.rosResultFactorId ?? 0
            }
          )
        }
      })
      setDropdownValue(
        examSystems.filter((e) => !(`${e.id!}` in newSystemResults))
      )
      setSystemResults(newSystemResults)
    }
  }, [output])

  React.useEffect(() => {
    if (isDirty) {
      if (firstOutput !== output) {
        setExternalIsDirty(true)
        setFirstOutput(output)
      }
      const newOutput = []
      for (const property in systemResults) {
        newOutput.push({
          visitId,
          id:
            type === 'exam'
              ? (output as Exam[])?.find(
                  (exam: Exam) => exam.examSystemId === parseInt(property)
                )?.id ?? 0
              : (output as ReviewOfSystem[])?.find(
                  (ros: ReviewOfSystem) =>
                    ros.rosSystemId === parseInt(property)
                )?.id,
          ...(type === 'exam'
            ? { examSystemId: parseInt(property) }
            : { rosSystemId: parseInt(property) }),
          ...(type === 'exam'
            ? {
                /* prettier-ignore */
                visitExamSystemComponentList: examRepsoneList(
                  systemResults[property],
                  (output as Exam[])?.find((exam: Exam) => exam.examSystemId !== null && exam.examSystemId !== undefined && exam.examSystemId === parseInt(property)) ?? {}
                ),
                /* prettier-ignore */
              }
            : {
                visitRosSymptomList: rOSResponseList(
                  systemResults[property],
                  (output as ReviewOfSystem[])?.find(
                    (ros: ReviewOfSystem) =>
                      ros.rosSystemId === parseInt(property)
                  ) ?? {}
                ),
              }),
          ...(property in notesValues
            ? { note: notesValues[property] }
            : { note: '' }),
        })
      }
      setOutPut(newOutput)
      setIsDirty(false)
    }
  }, [isDirty])

  React.useEffect(() => {
    setExamCount(output.length + 1)
  }, [])

  React.useEffect(() => {
    if (output.length > 0) {
      setExamCount(output.length)
    }
  }, [output, selectedId])

  const removeAllRows = (): void => {
    setSystemResults({}) // Also clear the systemResults
    setNotesValue({}) // And the notesValues
    setSelectedId(0) // Reset the selectedId
    setNotesValue({}) // And the notesValues
    setIsDirty(true) // Mark the component as 'dirty' to trigger the necessary updates
  }

  const examRepsoneList = (
    response: any,
    exam: Exam
  ): ExamSystemComponentResponse[] => {
    const newResponse = []
    for (const property in response) {
      newResponse.push({
        visitId,
        examSystemComponentId: parseInt(property),
        examResultFactorId: parseInt(response[property]),
        id:
          exam?.visitExamSystemComponentList?.find(
            (examSystemComponent: ExamSystemComponentResponse) =>
              examSystemComponent.examSystemComponentId === parseInt(property)
          )?.id ?? 0,
      })
    }
    return newResponse
  }
  const rOSResponseList = (
    response: any,
    ros: ReviewOfSystem
  ): RosSystemComponentResponse[] => {
    const newResponse: RosSystemComponentResponse[] = []
    for (const property in response) {
      newResponse.push({
        visitId,
        rosSymptomId: parseInt(property),
        rosResultFactorId: parseInt(response[property]),
        id:
          ros?.visitRosSymptomList?.find(
            (rosSystemComponent: RosSystemComponentResponse) =>
              rosSystemComponent.rosSymptomId === parseInt(property)
          )?.id ?? 0,
      })
    }
    return newResponse
  }
  const addToNotesList = (systemId: number, value: string): void => {
    const newNotesValues = notesValues
    newNotesValues[`${systemId}`] = value
    setNotesValue(newNotesValues)
    setIsDirty(true)
  }

  const handleResultsChange = (
    systemId: number,
    componentId: string,
    value: number
  ): void => {
    const newSystemResults = systemResults
    const newResponse = newSystemResults
    if (!(systemId in newResponse)) {
      newResponse[systemId] = {}
    }
    newResponse[systemId][componentId] = value
    setIsDirty(true)
    setSystemResults(newResponse)
  }
  const handleDelete = (index: number): void => {
    setExternalIsDirty(true)
    setIsDirty(true)
    const newSystemResults: any = {}
    if (type === 'exam') {
      const newOutput =
        (output as Exam[]).filter((e) => (e.examSystemId ?? 0) !== index) ?? []
      setOutPut(newOutput)
    } else {
      setOutPut(
        (output as ReviewOfSystem[]).filter(
          (e) => (e.rosSystemId ?? 0) !== index
        )
      )
    }
    for (const property in systemResults) {
      if (parseInt(property) !== index) {
        newSystemResults[property] = systemResults[property]
      }
    }
    setSystemResults(newSystemResults)
  }

  const buildRows = React.useMemo((): JSX.Element[] => {
    setNotesValue({})

    setDropdownValue(
      examSystems.filter(
        (e) =>
          !output
            .map((o) =>
              type === 'exam'
                ? (o as Exam).examSystemId
                : (o as ReviewOfSystem).rosSystemId
            )
            .includes(e.id)
      )
    )
    if (output.length > 0) {
      const newNotesValues = notesValues
      output.forEach((examSystemComponentResponse: Exam | ReviewOfSystem) => {
        const key =
          type === 'exam'
            ? (examSystemComponentResponse as Exam).examSystemId ?? 0
            : (examSystemComponentResponse as ReviewOfSystem).rosSystemId ?? 0
        newNotesValues[`${key}`] = examSystemComponentResponse.note ?? ''
      })
      setNotesValue(newNotesValues)
    }
    const rows: JSX.Element[] = []
    if (output.length === 0) {
      return rows
    }
    for (let i = 0; i < output.length; i++) {
      const id =
        (type === 'exam'
          ? (output[i] as Exam).examSystemId
          : (output[i] as ReviewOfSystem).rosSystemId) ?? 0
      rows.push(
        <ExamRow
          key={id}
          examSystems={examSystems}
          filterExamedSystems={examSystems.filter(
            (e) => !(`${e.id!}` in systemResults)
          )}
          index={id}
          examSystemComponents={examSystemComponents}
          count={examCount}
          setCount={setExamCount}
          type={type}
          outPut={output}
          setOutPut={setOutPut}
          handleResultChange={handleResultsChange}
          handleDelete={handleDelete}
          addToNotesList={addToNotesList}
          notesList={notesValues}
          systemResults={systemResults}
          previousId={id}
          displayPopup={id === displayPopup}
        />
      )
    }
    setDisplayPopup(-1)
    return rows
  }, [output, output.length, examCount])
  return (
    <TileLayout
      ref={ref}
      inFocus={inFocus}
      setInFocus={setInFocus}
      id={type}
      isLocked={isLocked}
    >
      <>
        <Title titleText={type === 'exam' ? 'Exam' : 'Review of Systems'} />
        <div>
          {buildRows}
          {dropdownValue.length > 0 && (
            <>
              <StyleInputLabel>System</StyleInputLabel>
              <StyledSelected
                value={selectedId}
                labelId="StateLabel"
                onChange={handleChange}
              >
                {dropdownValue.map((examSystem) => {
                  return (
                    <MenuItem key={examSystem.id} value={examSystem.id}>
                      {examSystem.name}
                    </MenuItem>
                  )
                })}
              </StyledSelected>
            </>
          )}
        </div>
      </>
    </TileLayout>
  )
}

interface ExamTileProps {
  examSystems: ExamSystem[];
  examSystemComponents: ExamSystemComponent[];
  type: string;
  output: Exam[] | ReviewOfSystem[];
  setOutPut: any;
  ref: React.RefObject<HTMLDivElement>;
  visitId: number;
  inFocus: boolean;
  setInFocus: React.Dispatch<React.SetStateAction<boolean>>;
  isLocked?: boolean;
  isExternalDirty: boolean;
  setExternalIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  removeAllRowsFlag?: boolean;
}
