import React from 'react'
import {
  Stack,
  Button,
  Typography,
  styled,
  Select,
  MenuItem,
  TextField,
  Snackbar,
  Alert,
  type AlertColor,
  Box,
  Popover,
  Portal,
} from '@mui/material'
import { useAuth, useUser } from '@clerk/nextjs'
import {
  useMutateAddNewTask,
  useMutateUpdateTask,
  useTasksStore,
} from '@/hook/useTasks'
import { type Task } from '@/types/Task'
import AssignmentIcon from '@mui/icons-material/Assignment'
import {
  useClinicStore,
  useMutateUpdateDocument,
  useQueryGetAccountUsers,
  useQueryGetRolePermissions,
  useQueryGetTaskPriority,
  useQueryGetTaskTypes,
} from '@/hook'
import { isDate, subDays } from 'date-fns'
import {
  convertToSpaceSeparated,
  dateOnlyToDate,
  isDateOnlyValid,
  isEmpty,
  parseDateToDateOnly,
} from '@/utility'
import Link from 'next/link'
import {
  type DefaultExtensionType,
  FileIcon,
  defaultStyles,
} from 'react-file-icon'
import UploadIcon from '@mui/icons-material/Upload'
import { DocumentUploadDialog } from './DocumentUploadDialog'
import { makeStyles } from '@mui/styles'
import { DatePicker } from '@mui/x-date-pickers'
import { DocumentsPopover } from './DocumentsPopover'
import { type Patient, type UrgentIQDocument } from '@/types'
import { TaskPatientSearch } from '../TaskPatientSearch'
import { executeSequentially } from '@/helpers'
import { getDocument } from '@/services'
import { ModalTitleContainer } from '../Config/AddModals/components/ModalTitle/ModalTitle'

const CreateTaskModalContainer = styled('div')({
  minWidth: '840px',
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
})
const StyledLabel = styled(Typography)({
  display: 'flex',
  justifyContent: 'flex-start',
  minWidth: '95px',
  width: '95px',
  fontSize: '14px',
})

const StyledTextField = styled(TextField)({
  height: '40px !important',
  '& > div': {
    borderRadius: 0,
    height: '40px',
    backgroundColor: 'white',
  },
})

const CountCircle = styled('div')(({ theme }): any => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  background: '#EAF3FF',
  fontWeight: 700,
  fontSize: '10x',
  lineHeight: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '252D63',
  marginTop: '-4px',
}))

const useStyles = makeStyles({
  largeInput: {
    '& .MuiInputBase-root': {
      height: '96px !important',
    },
    '& .MuiInputBase-inputMultiline': {
      height: '90px !important',
    },
  },
})

const DocumentName = styled(Typography)({
  fontSize: '14px',
  color: '#454545',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '150px',
  whiteSpace: 'nowrap',
})

const RedText = styled(Typography)({
  fontSize: '14px',
  color: 'red',
})

interface CreateTaskModalProps {
  setClose: any;
  currentTask?: number;
  defaultTask?: Task;
}

export const CreateTaskModal = ({
  setClose,
  currentTask,
  defaultTask
}: CreateTaskModalProps): JSX.Element => {
  const { getToken } = useAuth()
  const classes = useStyles()
  const { user } = useUser()
  const { data: userTaskTypes } = useQueryGetTaskTypes(getToken)
  const { data: userTaskPriority } = useQueryGetTaskPriority(getToken)
  const addNewUserTask = useMutateAddNewTask(getToken)
  const updateUserTask = useMutateUpdateTask(getToken)
  const updateDocument = useMutateUpdateDocument(getToken)
  const { clinicId } = useClinicStore()
  const { taskPage } = useTasksStore()
  const initialTask = taskPage?.items?.find((task) => task.id === currentTask) ?? {
    dateAssigned: undefined,
    dateCompleted: undefined
  }
  const [task, setTask] = React.useState<Task>({
    ...defaultTask,
    ...initialTask,
    documentId: defaultTask?.documentId ?? initialTask?.documentId ?? [],
  })
  const { data: accounts = [] } = useQueryGetAccountUsers(getToken)
  const { data: rolePermissions } = useQueryGetRolePermissions(getToken)
  const [toastMessage, setToastMessage] = React.useState<string>('')
  const [toastOpen, setToastOpen] = React.useState<boolean>(false)
  const [toastSeverity, setToastSeverity] = React.useState<AlertColor>('error')
  const [uploadOpen, setUploadOpen] = React.useState<boolean>(false)
  const [document, setDocument] = React.useState<UrgentIQDocument>()
  const [ext, setExt] = React.useState<string>('')

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const findRole = (role: any): string => {
    if (isEmpty(rolePermissions) || isEmpty(role)) return ''
    return (
      rolePermissions?.find((permission) => permission.id === role[0])?.name ??
      ''
    )
  }

  React.useEffect(() => {
    if (defaultTask) {
      setTask(defaultTask)
    }
  }, [])

  React.useEffect(() => {
    if (task.documentId && task.documentId.length > 0) {
      getDocument(task.documentId[0], getToken)
        .then((doc) => {
          setDocument(doc)
          setExt(doc.visibleDocumentName?.split('.').reverse()[0] ?? '')
        })
        .catch((error) => {
          throw error
        })
    }
  }, [task, getToken])

  const handleChangeTask = (field: string, value: any): void => {
    setTask((prev) => {
      const newTask: Task = { ...prev } ?? {}
      if (field === 'statusId') {
        if (newTask.statusId === 1) {
          setToastOpen(true)
          setToastSeverity('error')
          setToastMessage('You need to assign')
          return newTask
        }
      } else if (field === 'accountUserId') {
        if (newTask.statusId === 1) {
          newTask.statusId = 2
        }
      }

      return { ...newTask, [field]: value }
    })
  }

  const handleCreateTask = (): void => {
    if (isDateOnlyValid(task.dateDue)) {
      if (
        dateOnlyToDate(task.dateDue)!.getTime() <
        subDays(new Date(), 1).getTime()
      ) {
        setToastOpen(true)
        setToastSeverity('error')
        setToastMessage('Due date must be in the future.')
        return
      }
    }
    if (!task.typeId || !task.notes) {
      setToastOpen(true)
      setToastSeverity('error')
      setToastMessage(
        !task.typeId
          ? 'Please fill the type field.'
          : 'Please fill the notes field.'
      )
      return
    }
    if (task.id) {
      const newTask: Task = task
      if (!task.dateCompleted && task.statusId === 4) {
        newTask.dateCompleted = parseDateToDateOnly(new Date())
      }
      if (task.accountUserId ?? task.patientId) {
        if (!task.dateAssigned) {
          newTask.dateAssigned = parseDateToDateOnly(new Date(Date.now()))
        }
      }
      updateUserTask
        .mutateAsync({ id: task.id, task: newTask })
        .then(() => {
          setToastOpen(true)
          setToastSeverity('success')
          setToastMessage('Task updated successfully!')
          setTimeout(() => {
            setClose()
          }, 2000)
        })
        .catch((e) => {
          setToastOpen(true)
          setToastSeverity('error')
          setToastMessage('Failed to update task.')
        })
    } else {
      const currentAccountId = accounts.find(
        ({ username }) => username === user?.username
      )?.id
      const newTask: Task = {
        ...task,
        createdById: currentAccountId,
        clinicId,
        statusId: 1,
      }
      if (task.accountUserId ?? task.patientId) {
        newTask.dateAssigned = parseDateToDateOnly(new Date(Date.now()))
      }
      if (isDate(task.dateDue)) {
        newTask.dateDue = parseDateToDateOnly(task.dateDue as Date)
      }
      addNewUserTask
        .mutateAsync({ task: newTask })
        .then((res): void => {
          setToastOpen(true)
          setToastSeverity('success')
          setToastMessage('Task created successfully!')
          if (task.documentId) {
            const updateDocTasks = task.documentId.map((id) => {
              return async () => {
                const doc: UrgentIQDocument = (await getDocument(
                  id,
                  getToken
                )) ?? { userTaskId: 0 }

                await updateDocument.mutateAsync({
                  documentId: id,
                  document: { ...doc, userTaskId: res?.id ?? 0, file: null },
                })
              }
            })
            executeSequentially(updateDocTasks).finally(() => {
              setTimeout(() => {
                setClose()
              }, 2000)
            })
          } else {
            setTimeout(() => {
              setClose()
            }, 2000)
          }
        })
        .catch((e) => {
          setToastOpen(true)
          setToastSeverity('error')
          setToastMessage('Failed to create task.')
        })
    }
  }

  const handleToastClose = (): void => {
    setToastOpen(false)
  }

  const handleCloseUploadModal = (): void => {
    setUploadOpen(false)
  }

  const handlePopoverToggle = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl((prev) => (prev ? null : event.currentTarget))
  }

  return (
    <CreateTaskModalContainer>
      <ModalTitleContainer>{task.id ? 'Edit' : 'Create'} Task</ModalTitleContainer>

      <Stack direction="row" spacing={4} marginTop={2}>
        <AssignmentIcon style={{ fontSize: 100, color: '#666' }} />
        <Stack direction="column" flex={1} spacing={3}>
          <Stack direction="row" spacing={2} alignItems="center">
            <StyledLabel>Title:</StyledLabel>
            <TextField
              sx={{ mr: 1 }}
              style={{ width: '90%', background: 'white' }}
              value={task?.title ?? ''}
              onChange={(e) => handleChangeTask('title', e.target.value)}
            />
          </Stack>
          <Stack direction="row" spacing={4}>
            <Stack direction="column" flex={7} spacing={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                <StyledLabel>
                  Type:<RedText>*</RedText>
                </StyledLabel>
                <Select
                  sx={{ mr: 1 }}
                  style={{ width: '90%', background: 'white' }}
                  value={task?.typeId ?? -1}
                  onChange={(e) => handleChangeTask('typeId', e.target.value)}
                >
                  {userTaskTypes?.map(({ id, name }) => (
                    <MenuItem
                      data-testid={`type-${id ?? ''}`}
                      key={id}
                      value={id}
                    >
                      {convertToSpaceSeparated(name ?? '')}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <StyledLabel>Priority:</StyledLabel>
                <Select
                  sx={{ mr: 1 }}
                  style={{ width: '90%', background: 'white' }}
                  value={task?.priorityId ?? -1}
                  onChange={(e) =>
                    handleChangeTask('priorityId', e.target.value)
                  }
                >
                  {userTaskPriority?.map(({ id, name }) => (
                    <MenuItem
                      data-testid={`type-${id ?? ''}`}
                      key={id}
                      value={id}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Stack>
            <Stack direction="column" flex={10} spacing={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                <StyledLabel>Assignee:</StyledLabel>
                <Select
                  sx={{ mr: 1 }}
                  style={{ width: '90%', background: 'white' }}
                  value={task?.accountUserId ?? -1}
                  onChange={(e) =>
                    handleChangeTask('accountUserId', e.target.value)
                  }
                >
                  {accounts
                    .filter(
                      (account) => findRole(account.role) !== 'UrgentIqEmployee'
                    )
                    .sort(
                      (prev, next) =>
                        prev.username?.localeCompare(next.username ?? '') ?? 0
                    )
                    .map((option) => (
                      <MenuItem
                        data-testid={`race${option.id ?? 0}`}
                        key={option.id}
                        value={option.id}
                      >{`${option.firstName ?? ''} ${
                        option.lastName ?? ''
                      }`}</MenuItem>
                    ))}
                </Select>
              </Stack>
              <Stack direction="row" spacing={2} alignItems="center">
                <StyledLabel>Due Date:</StyledLabel>

                <DatePicker
                  value={task?.dateDue ?? null}
                  onChange={(e) => {
                    handleChangeTask('dateDue', e)
                  }}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      inputProps={params.inputProps}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
              <StyledLabel>Patient:</StyledLabel>
              <TaskPatientSearch
                initialPatientId={task?.patientId ?? null}
                handleChange={(patient: Patient) => {
                  if (task.patientId !== patient.id) handleChangeTask('patientId', patient.id)
                }}
                onClearTile={() => {
                  handleChangeTask('patientId', null)
                }}
              />
            </Stack>
          <Stack direction="column" spacing={2}>
            <StyledLabel>
              Notes:<RedText>*</RedText>
            </StyledLabel>
            <TextField
              value={task?.notes ?? ''}
              onChange={(e) => handleChangeTask('notes', e.target.value)}
              className={classes.largeInput}
              multiline
            />
          </Stack>
          <Stack direction="row" spacing={4}>
            <Stack
              direction="row"
              sx={{
                alignItems: 'center',
              }}
            >
              {task.documentId?.length ? (
                <>
                  <Link href={document?.presignedUrl ?? '#'} target="_blank">
                    <Stack direction="row">
                      <Box
                        sx={{
                          display: 'flex',
                          width: '20px',
                          marginRight: '15px',
                        }}
                      >
                        <FileIcon
                          extension={ext}
                          {...defaultStyles[ext as DefaultExtensionType]}
                          labelUppercase
                        />
                      </Box>
                      <DocumentName>
                        {document?.visibleDocumentName}
                      </DocumentName>
                    </Stack>
                  </Link>
                  {task.documentId?.length > 1 && (
                    <>
                      <Box onClick={handlePopoverToggle}>
                        <CountCircle id="documents-modal" >
                          +{task.documentId?.length ?? 0 - 1}
                        </CountCircle>
                      </Box>
                      <Popover
                        open={Boolean(anchorEl)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        anchorEl={anchorEl}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        onClose={(ev: any): void => {
                          setAnchorEl(null)
                          ev.stopPropagation()
                        }}
                      >
                        <DocumentsPopover documentIds={task.documentId ?? []} />
                      </Popover>
                    </>
                  )}
                </>
              ) : (
                ''
              )}
            </Stack>
            <Button
              sx={{ width: 'fit-content', fontSize: '14px' }}
              onClick={() => setUploadOpen(true)}
            >
              <UploadIcon
                style={{
                  cursor: 'pointer',
                  width: '17px',
                  height: '17px',
                  marginRight: '5px',
                }}
              />
              Upload Document
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        style={{ justifyContent: 'flex-end', marginTop: '15px' }}
      >
        <Button variant="outlined" onClick={setClose}>
          Close
        </Button>
        <Button variant="contained" onClick={handleCreateTask}>
          {task.id ? 'Update' : 'Create'}
        </Button>
      </Stack>
      <Portal>
        <Snackbar
          open={toastOpen}
          autoHideDuration={2000}
          onClose={handleToastClose}
        >
          <Alert
            onClose={handleToastClose}
            severity={toastSeverity ?? 'error'}
            sx={{ width: '100%' }}
          >
            {toastMessage}
          </Alert>
        </Snackbar>
      </Portal>

      <DocumentUploadDialog
        UserTaskId={task?.id}
        PatientId={task?.patientId}
        open={uploadOpen}
        onClose={handleCloseUploadModal}
        onSuccess={(ids) => {
          handleChangeTask('documentId', [
            ...(task?.documentId ?? []),
            ...(ids ?? []),
          ])
        }}
      />
    </CreateTaskModalContainer>
  )
}
