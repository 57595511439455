import { type AccountUser } from '../../../../types'
import React from 'react'
import { styled } from '@mui/material'
import { UserRow } from './UserRow'
import { type UserRolePermissions } from '../../../../types/UserRoles'
import { ListContainer } from '../../layout/list/ListContainer'
import { TitleText } from '../../components/list/TitleText'
import { ListBox } from '../../layout/list/ListBox'
import { TitleEnd } from '../../layout/list/TitleEnd'
import { TitleLarge } from '../../layout/list/TitleLarge'
import { TitleSmall } from '../../layout/list/TitleSmall'
import { ListTitleRow } from '../../layout/list/ListTitleRow'

const AvatarPadding = styled('div')({
  minWidth: '66px',
})
const OuterRow = styled('div')({
  display: 'flex',
})
export const UserManagementList = ({
  accountUsers,
  rolePermissions,
  refresh,
}: UserManagmentListProps): JSX.Element => {
  const findRole = (user: AccountUser): number => {
    return user.role !== undefined
      ? user.role !== null
        ? (user.role[0] as number)
        : 0
      : 0
  }
  return (
    <ListContainer>
      <OuterRow>
        <AvatarPadding />
        <ListTitleRow>
          <TitleSmall avatar>
            <TitleText>Name</TitleText>
          </TitleSmall>
          <TitleSmall avatar>
            <TitleText>Role</TitleText>
          </TitleSmall>
          <TitleLarge>
            <TitleText>Permissions</TitleText>
          </TitleLarge>
          <TitleEnd />
        </ListTitleRow>
      </OuterRow>
      <ListBox>
        {accountUsers?.map((user) => {
          return (
            <UserRow
              accountUser={user}
              rolePermissions={rolePermissions?.find(
                (rp) => rp.id === findRole(user)
              )}
              key={user.id}
              refresh={refresh}
            />
          )
        })}
      </ListBox>
    </ListContainer>
  )
}

interface UserManagmentListProps {
  accountUsers: AccountUser[];
  refresh: any;
  rolePermissions: UserRolePermissions[];
}
