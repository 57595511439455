import React, { useState } from 'react'
import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
} from '@mui/material'
import { Controller, type SubmitHandler, useForm } from 'react-hook-form'
import { useAuth } from '@clerk/nextjs'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  type Clinic,
  type DocumentType,
  type MeasurementUnit,
  type State,
  type TimeZone,
} from '@/types'
import {
  ModalContainer,
  ModalRow,
  FullColumn,
  ModalButton,
  ModalTitle,
  ModalFullTextField,
  ModalButtonRow,
  PhoneNumberInput,
  AddressInput,
  ErrorMessage,
  RedText,
} from '@/components'
import {
  useMutateAddNewClinic,
  useMutateUpdateClinic,
  useQueryGetDocument,
  useQueryGetDocumentTypes,
  useQueryGetMeasurementUnits,
} from '@/hook'
import { isEmpty, isInputValid } from '@/utility'
import UploadIcon from '@mui/icons-material/Upload'
import { DocumentUploadDialog } from '@/components/Task/DocumentUploadDialog'

const LogoContainer = styled('div')({
  border: '1px solid #DDDFE8',
  padding: '5px',
  borderRadius: '5px',
})

const validationSchema = Yup.object().shape({
  clinicName: Yup.string().required('Clinic Name is required'),
  clinicstreet1: Yup.string().required('Address Line 1 is required'),
  cliniccity: Yup.string().required('City is required'),
  clinicstate: Yup.string().required('State is required'),
  cliniczip: Yup.string().required('Zip Code is required'),
  PhoneNumber: Yup.string()
    .nullable()
    .min(12, 'Valid phone number is required'),
  faxPhoneNumber: Yup.lazy((value) =>
    !isEmpty(value)
      ? Yup.string().nullable().min(12, 'Fax number must be valid')
      : Yup.string().nullable().notRequired()
  ),
  timeZoneId: Yup.number().notOneOf([0], 'Time Zone is required'),
})

interface AddClinicForm {
  clinicName: string;
  clinicstreet1: string;
  clinicstreet2: string;
  cliniccity: string;
  timeZoneId: number;
  taxId: string;
  dosespotClincId: number;
  dosespotClinicKey: string;
  PhoneNumber: string;
  faxPhoneNumber: string;
  clinicstate: string;
  cliniczip: string;
  clinicLogoDocumentId?: number;
  measurementUnitId?: number;
}

export const AddClinic = ({
  handleClose,
  exisitingClinic = null,
  refresh,
  timeZones,
  states,
}: AddClinicProps): JSX.Element => {
  const [logoDocumentId, setLogoDocumentId] = useState<number | null>(
    exisitingClinic?.clinicLogoDocumentId ?? null
  )
  const [uploadOpen, setUploadOpen] = React.useState<boolean>(false)
  const { getToken } = useAuth()
  const addNewClinicMutation = useMutateAddNewClinic(getToken)
  const updateClinicMutation = useMutateUpdateClinic(getToken)
  const { data: docTypes } = useQueryGetDocumentTypes(getToken)
  const { data: document = null } = useQueryGetDocument(
    logoDocumentId ?? 0,
    getToken
  )
  const { data: measurementUnits } = useQueryGetMeasurementUnits(getToken)
  const {
    register,
    handleSubmit,
    reset,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm<AddClinicForm>({
    resolver: yupResolver(validationSchema),
    ...(exisitingClinic !== null && {
      defaultValues: {
        clinicName: exisitingClinic.name!,
        clinicstreet1: exisitingClinic.address!,
        clinicstreet2: exisitingClinic.address2!,
        clinicstate: states.find(
          (state) => state.id === exisitingClinic.stateId
        )?.code!,
        cliniczip: exisitingClinic.zip!,
        cliniccity: exisitingClinic.city!,
        timeZoneId: exisitingClinic.timeZoneId!,
        taxId: exisitingClinic.taxId!,
        dosespotClincId: exisitingClinic.dosespotClinicId!,
        dosespotClinicKey: exisitingClinic.dosespotClinicKey!,
        PhoneNumber: exisitingClinic.phoneNumber ?? '',
        faxPhoneNumber: exisitingClinic.faxNumber ?? '',
        clinicLogoDocumentId: exisitingClinic.clinicLogoDocumentId ?? undefined,
        measurementUnitId: exisitingClinic.measurementUnitId ?? 3,
      },
    }),
  })

  const logoDocumentTypeId = docTypes?.find(
    (docType: DocumentType) => docType.name === 'ClinicLogo'
  )?.id

  const handleReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault()
    reset()
    handleClose()
  }

  const handleNewLogo = (documentIds?: number[]): void => {
    if (!documentIds || documentIds.length < 1) return

    const documentId = documentIds[0]
    setLogoDocumentId(documentId)
  }

  const onSubmit: SubmitHandler<AddClinicForm> = (data): void => {
    if (!isInputValid(data.PhoneNumber)) {
      setError('PhoneNumber', { message: 'Valid phone number is required' })
    }
    if (!isInputValid(data.faxPhoneNumber)) {
      setError('faxPhoneNumber', { message: 'Valid phone number is required' })
    }
    const clinic: Clinic = {
      name: data.clinicName,
      address: data.clinicstreet1,
      address2: data.clinicstreet2,
      city: data?.cliniccity ?? '',
      stateId:
        states.find((state) => state.code === data?.clinicstate)?.id ?? 0,
      zip: data?.cliniczip ?? '',
      timeZoneId: data.timeZoneId,
      id: exisitingClinic?.id ?? 0,
      taxId: data.taxId,
      dosespotClinicId: data.dosespotClincId,
      dosespotClinicKey: data.dosespotClinicKey,
      clinicLogoDocumentId: logoDocumentId,
      measurementUnitId: data.measurementUnitId,
      ...(data.PhoneNumber !== '' && { phoneNumber: data.PhoneNumber }),
      ...(data.faxPhoneNumber !== '' && { faxNumber: data.faxPhoneNumber }),
    }

    const response =
      exisitingClinic === null
        ? addNewClinicMutation.mutateAsync({ clinic })
        : updateClinicMutation.mutateAsync({
            clinicId: clinic.id!,
            clinic,
          })
    // eslint-disable-line
    Promise.resolve(response)
      .then(() => {
        refresh()
        handleClose()
      })
      .catch((error) => {
        throw error
      })
  }

  const handleCloseUploadModal = (): void => {
    setUploadOpen(false)
  }

  return (
    <ModalContainer>
      <form
        style={{ width: '100%', height: '640px', overflowY: 'auto' }}
        onSubmit={(...args): void => {
          handleSubmit(onSubmit)(...args).catch((error) => {
            throw error
          })
        }}
      >
        <ModalTitle>
          {exisitingClinic === null ? 'Add Clinic' : 'Edit Clinic'}
        </ModalTitle>
        <ModalRow>
          <FullColumn>
            <InputLabel>
              Name<RedText>{' *'}</RedText>
            </InputLabel>
            <ModalFullTextField name="clinicName" register={register} />
            <ErrorMessage error={errors.clinicName?.message ?? ''} />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <AddressInput
              control={control}
              register={register}
              sectionName="clinic"
              setValue={setValue}
              setError={setError}
              errors={errors}
              fullSize
              required
            />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <PhoneNumberInput
              control={control}
              error={errors.PhoneNumber}
              width="100%"
              setError={setError}
              required
            />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <PhoneNumberInput
              control={control}
              error={errors.faxPhoneNumber}
              width="100%"
              labelName="Fax Number"
              sectionName="fax"
              setError={setError}
            />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <InputLabel>
              Timezone<RedText>{' *'}</RedText>
            </InputLabel>
            <Controller
              name="timeZoneId"
              control={control}
              defaultValue={exisitingClinic?.timeZoneId ?? 0}
              render={({ field }) => (
                <Select {...field} labelId="GenderLabel">
                  {timeZones.map((timeZone: TimeZone) => {
                    return (
                      <MenuItem key={timeZone.id} value={timeZone.id}>
                        {timeZone.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            />
            <ErrorMessage error={errors.timeZoneId?.message ?? ''} />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <InputLabel>Tax ID</InputLabel>
            <ModalFullTextField name="taxId" register={register} />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <InputLabel>DoseSpot Clinic ID</InputLabel>
            <ModalFullTextField name="dosespotClincId" register={register} />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <InputLabel>DoseSpot Clinic Key</InputLabel>
            <ModalFullTextField name="dosespotClinicKey" register={register} />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <InputLabel>
              Measurement Unit<RedText>{' *'}</RedText>
            </InputLabel>
            <Controller
              name="measurementUnitId"
              control={control}
              defaultValue={exisitingClinic?.measurementUnitId ?? 3}
              render={({ field }) => (
                <Select {...field} labelId="Measurement">
                  {measurementUnits?.map((measurementUnit: MeasurementUnit) => {
                    return (
                      <MenuItem
                        key={measurementUnit.id}
                        value={measurementUnit.id}
                      >
                        {measurementUnit.name}
                      </MenuItem>
                    )
                  })}
                </Select>
              )}
            />
            <ErrorMessage error={errors.timeZoneId?.message ?? ''} />
          </FullColumn>
        </ModalRow>
        <ModalRow>
          <FullColumn>
            <InputLabel>Clinic Logo</InputLabel>
            <Stack direction="row" spacing={2} alignItems="center">
              {document && (
                <LogoContainer>
                  <img
                    src={document?.presignedUrl}
                    alt="clinic logo"
                    width={80}
                    height={80}
                  />
                </LogoContainer>
              )}
              <Button
                color="primary"
                sx={{ width: '120px', fontSize: '12px', height: '30px' }}
                onClick={() => setUploadOpen(true)}
              >
                <UploadIcon />
                Update Logo
              </Button>
            </Stack>
          </FullColumn>
        </ModalRow>
        <ModalButtonRow>
          <ModalButton
            variant="outlined"
            type="reset"
            onClick={(event) => handleReset(event)}
          >
            Cancel
          </ModalButton>
          <ModalButton variant="contained" color="primary" type="submit">
            {exisitingClinic === null ? 'Add' : 'Save'}
          </ModalButton>
        </ModalButtonRow>
      </form>
      <DocumentUploadDialog
        open={uploadOpen}
        staticType={logoDocumentTypeId}
        onClose={handleCloseUploadModal}
        onSuccess={(docIds?: number[]) => handleNewLogo(docIds)}
      />
    </ModalContainer>
  )
}

interface AddClinicProps {
  handleClose: any;
  exisitingClinic?: Clinic | null;
  timeZones: TimeZone[];
  refresh: any;
  states: State[];
}
