import React from 'react'
import {
  IconButton,
  Popover,
  styled,
  type Theme,
  Typography,
  useTheme,
} from '@mui/material'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined'
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import { RecentCharts } from '../RecentCharts/RecentCharts'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import Link from 'next/link'

const LeftOuterNavContainer = styled('div')(
  ({ index, theme, isNavOpen }: NavIconBoxProp): any => ({
    marginTop: index === 6 || index === 8 ? 0 : '60px',
    display: `${isNavOpen ? 'flex' : 'none'}`,
    alignContent: 'center',
    justifyContent: 'start',
    flexDirection: 'column',
    width: '81px',
    height: '100%',
    backgroundColor: theme.palette.background.default,
    zIndex: '10',
    position: 'fixed',
    borderRight: `1px solid ${theme.palette.background.paper}`,
  })
)

const NavBoxes = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'start',
})

const NavColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignContent: 'center',
  justifyContent: 'space-between',
  height: '100%',
})

const NavIconButton = styled(IconButton)({
  borderRadius: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center', // Center align items
  justifyContent: 'center', // Center justify content
  width: '100%',
})

export const LeftOuterNav = ({
  selectedIndex,
  isNavOpen,
}: LeftOuterNavProp): JSX.Element => {
  const NavIconBox = styled('div')(({ index, theme }: NavIconBoxProp): any => ({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '60px',
    backgroundColor:
      index === selectedIndex
        ? theme.palette.primary.main
        : theme.palette.background.default,
  }))
  const PageTitle = styled(Typography)(
    ({ index, theme }: NavIconBoxProp): any => ({
      fontSize: '11px',
      lineHeight: '13px',
      letterSpacing: '0em',
      textAlign: 'center',
      color:
        index === selectedIndex ? theme.palette.background.default : 'black',
      marginTop: '-8px',
    })
  )
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const theme = useTheme()
  const open = Boolean(anchorEl)
  return (
    <>
      <Popover
        sx={{
          marginLeft: '44px',
          pointerEvents: 'none',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <></>
      </Popover>

      <LeftOuterNavContainer
        theme={theme}
        index={selectedIndex}
        isNavOpen={isNavOpen}
      >
        <NavColumn>
          <NavBoxes>
            <NavIconBox theme={theme} index={1} isNavOpen={isNavOpen}>
              <Link
                href={`/${isNavOpen ? '?previousNavOpen=true' : ''}`}
                passHref
              >
                <NavIconButton>
                  <DashboardOutlinedIcon
                    sx={{
                      color:
                        selectedIndex === 1 ? theme.palette.white : 'disabled',
                    }}
                    data-testid="dashboardIcon"
                  />
                  <PageTitle
                    theme={theme}
                    index={1}
                    isNavOpen={isNavOpen}
                    sx={{ marginLeft: '-4px', marginTop: '0px' }}
                  >
                    Dashboard
                  </PageTitle>
                </NavIconButton>
              </Link>
            </NavIconBox>
            <NavIconBox theme={theme} index={2} isNavOpen={isNavOpen}>
              <Link
                href={`/intake${isNavOpen ? '?previousNavOpen=true' : ''}`}
                passHref
              >
                <NavIconButton>
                  <PersonAddAltOutlinedIcon
                    sx={{
                      color:
                        selectedIndex === 2 ? theme.palette.white : 'disabled',
                    }}
                  />
                  <PageTitle
                    theme={theme}
                    index={2}
                    isNavOpen={isNavOpen}
                    sx={{ marginLeft: '-4px', marginTop: '0px' }}
                  >
                    Intake
                  </PageTitle>
                </NavIconButton>
              </Link>
            </NavIconBox>
            <NavIconBox theme={theme} index={3} isNavOpen={isNavOpen}>
              <Link
                href={`/patientlist${isNavOpen ? '?previousNavOpen=true' : ''}`}
                passHref
              >
                <NavIconButton>
                  <PeopleOutlineOutlinedIcon
                    sx={{
                      color:
                        selectedIndex === 3 ? theme.palette.white : 'disabled',
                    }}
                  />
                  <PageTitle
                    theme={theme}
                    index={3}
                    isNavOpen={isNavOpen}
                    sx={{ marginLeft: '-4px', marginTop: '0px' }}
                  >
                    Patients
                  </PageTitle>
                </NavIconButton>
              </Link>
            </NavIconBox>
            <NavIconBox theme={theme} index={4} isNavOpen={isNavOpen}>
              <Link
                href={`/scheduling${isNavOpen ? '?previousNavOpen=true' : ''}`}
                passHref
              >
                <NavIconButton>
                  <DateRangeOutlinedIcon
                    sx={{
                      color:
                        selectedIndex === 4 ? theme.palette.white : 'disabled',
                    }}
                  />
                  <PageTitle
                    theme={theme}
                    index={4}
                    isNavOpen={isNavOpen}
                    sx={{ marginTop: '0px' }}
                  >
                    Scheduling
                  </PageTitle>
                </NavIconButton>
              </Link>
            </NavIconBox>
            {process.env.SHOW_TASK_MANAGEMENT_BUTTON === 'true' && (
              <NavIconBox index={7} theme={theme} isNavOpen={isNavOpen}>
                <Link
                  href={`/task-management${
                    isNavOpen ? '?previousNavOpen=true' : ''
                  }`}
                  passHref
                >
                  <NavIconButton>
                    <AssignmentOutlinedIcon
                      sx={{
                        color:
                          selectedIndex === 7
                            ? theme.palette.white
                            : 'disabled',
                      }}
                    />
                    <PageTitle
                      theme={theme}
                      index={7}
                      isNavOpen={isNavOpen}
                      sx={{ marginTop: '0px' }}
                    >
                      Tasks
                    </PageTitle>
                  </NavIconButton>
                </Link>
              </NavIconBox>
            )}
            <NavIconBox index={8} theme={theme} isNavOpen={isNavOpen}>
              <Link
                href={`/documents${isNavOpen ? '?previousNavOpen=true' : ''}`}
                passHref
              >
                <NavIconButton>
                  <FolderOpenOutlinedIcon
                    sx={{
                      color:
                        selectedIndex === 8 ? theme.palette.white : 'disabled',
                    }}
                  />
                  <PageTitle
                    theme={theme}
                    index={8}
                    isNavOpen={isNavOpen}
                    sx={{ marginTop: '0px' }}
                  >
                    Documents
                  </PageTitle>
                </NavIconButton>
              </Link>
            </NavIconBox>
            <NavIconBox index={6} theme={theme} isNavOpen={isNavOpen}>
              <Link
                href={`/configurations${
                  isNavOpen ? '?previousNavOpen=true' : ''
                }`}
                passHref
              >
                <NavIconButton>
                  <SettingsIcon
                    sx={{
                      color:
                        selectedIndex === 6 ? theme.palette.white : 'disabled',
                    }}
                  />
                  <PageTitle
                    theme={theme}
                    index={6}
                    isNavOpen={isNavOpen}
                    sx={{ marginTop: '0px' }}
                  >
                    Configurations
                  </PageTitle>
                </NavIconButton>
              </Link>
            </NavIconBox>
          </NavBoxes>
          <RecentCharts />
        </NavColumn>
      </LeftOuterNavContainer>
    </>
  )
}
interface LeftOuterNavProp {
  selectedIndex: number;
  isNavOpen: boolean;
}

interface NavIconBoxProp {
  isNavOpen: boolean;
  index: number;
  theme: Theme;
}
