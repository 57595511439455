class EventQueue {
  private readonly queue: Array<() => Promise<void>> = []
  private isExecuting: boolean = false

  enqueue(fn: () => Promise<void>): void {
    this.queue.push(fn)
    this.run().catch((err) => {
      throw err
    })
  }

  private async run(): Promise<void> {
    if (this.isExecuting || this.queue.length === 0) return
    this.isExecuting = true
    try {
      await this.queue[0]()
      this.queue.shift()
      this.isExecuting = false
      await this.run()
    } catch (error) {
      console.error(error)
    }
  }
}

export const eventQueue = new EventQueue()
