import React from 'react'
import { type WidgetProps } from '@rjsf/utils'
import { TileDatePicker } from '../../components'

export const CustomDate = function (props: WidgetProps): any {
  const [value, setValue] = React.useState<any>(props.value ?? null)
  const handleChange = (newDate: any): void => {
    setValue(newDate)
    props.onChange(newDate)
  }

  return (
    <TileDatePicker
      label={props.label}
      onChange={handleChange}
      value={value}
      dataTestId={props.label}
    />
  )
}
