import { useClinicStore } from '@/hook'
import { useQueryGetDSToken } from '@/hook/useDoseSpotToken'
import { useAuth } from '@clerk/nextjs'
import { styled } from '@mui/material'
import React from 'react'

const EPrescribeContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  position: 'relative',
  width: '80%',
  height: '75%',
})

const StyleIframe = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 'none',
  borderRadius: '12px',
})
export const EPrescribePopUp = ({
  url,
  patientId = 0,
  type = 'doseSpot',
}: ePrescribePopUpProps): JSX.Element => {
  const getToken = useAuth()
  const { clinicId } = useClinicStore()
  const { data: dsToken } = useQueryGetDSToken(clinicId, getToken)
  const [url2, setUrl2] = React.useState<string>('')

  React.useEffect(() => {
    if (type === 'doseSpot') {
      const newUrl = `${url}SingleSignOnClinicId=${
        dsToken?.doseSpotClinicId ?? 0
      }
        &SingleSignOnUserId=${
          dsToken?.doseSpotUserId ?? 0
        }&PatientId=${patientId}
        &SingleSignOnPhraseLength=32&SingleSignOnCode=${
          dsToken?.clinicSingleSignOnEncoded ?? ''
        }
        &SingleSignOnUserIdVerify=${dsToken?.userSingleSignOnEncoded ?? ''}`
      setUrl2(newUrl)
    } else {
      setUrl2(url)
    }
  }, [dsToken, patientId, type, url])
  return (
    <EPrescribeContainer>
      <StyleIframe src={url2} />
    </EPrescribeContainer>
  )
}

interface ePrescribePopUpProps {
  url: string;
  patientId?: number;
  type?: 'doseSpot' | 'healthGorilla';
}
