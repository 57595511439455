import { styled } from '@mui/material'
import React from 'react'

const AddTableContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const AddTable = ({ children }: AddTableProps): JSX.Element => {
  return <AddTableContainer>{children}</AddTableContainer>
}

interface AddTableProps {
  children: JSX.Element | JSX.Element[];
}
