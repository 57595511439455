import React from 'react'
import { TaskDrawerSection } from '../TaskDrawerSection'
import { InputRow, InputGroup } from '../..'
import { Typography, styled } from '@mui/material'

const InputLabel = styled(Typography)({
  color: '#686868',
  fontSize: '14px',
})

export const TaskDataItem = ({
  label,
  render,
}: TaskDataItemProps): JSX.Element => {
  return (
    <InputGroup>
      <InputLabel>{label}</InputLabel>
      {render()}
    </InputGroup>
  )
}

interface TaskDataItemGroupProps {
  itemProps: TaskDataItemProps[][];
  groupTitle: string;
}
export const TaskDataItemGroup: React.FC<TaskDataItemGroupProps> = ({
  itemProps,
  groupTitle,
}: TaskDataItemGroupProps) => {
  return (
    <TaskDrawerSection sectionName={groupTitle}>
      {itemProps.map((propsGroup: TaskDataItemProps[]) => {
        const groupKey = propsGroup
          .map((props: TaskDataItemProps) => props.label)
          .join('-')
        return (
          <InputRow key={groupKey}>
            {propsGroup.map((props: TaskDataItemProps) => {
              const key = `${groupKey}-${props.label}`
              return (
                <InputGroup key={key}>
                  <TaskDataItem {...props} />
                </InputGroup>
              )
            })}
          </InputRow>
        )
      })}
    </TaskDrawerSection>
  )
}

export interface TaskDataItemProps {
  label: string;
  render: () => JSX.Element;
}
