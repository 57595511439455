import React from 'react'

import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, Popover } from '@mui/material'
import { type PatientSurgery } from '@/types'
import {
  RowValue,
  AddColumn,
  AddRow,
  SurgicalHistoryHoverOver,
  RowTile,
} from '@/components'
import ModeIcon from '@mui/icons-material/Mode'
import { formatDateOnly } from '@/utility'

export const SurgicalHistoryRow = ({
  surgicaProcedure: surgicalProcedure,
  name,
  delete: deleteDX,
  edit: editDX,
}: SurgicalHistorysRowProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = (): void => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          borderRadius: '12px',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <SurgicalHistoryHoverOver
          procedure={name}
          date={formatDateOnly(surgicalProcedure.procedureDate)}
        />
      </Popover>
      <AddRow
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <AddColumn width={62}>
          <RowValue>{name}</RowValue>
        </AddColumn>
        <AddColumn width={30}>
          <RowTile>Surgery Date</RowTile>
          <RowValue>{formatDateOnly(surgicalProcedure.procedureDate)}</RowValue>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => editDX(surgicalProcedure)} size="large">
            <ModeIcon />
          </IconButton>
        </AddColumn>
        <AddColumn width={4}>
          <IconButton onClick={() => deleteDX(surgicalProcedure)} size="large">
            <DeleteIcon />
          </IconButton>
        </AddColumn>
      </AddRow>
    </>
  )
}
interface SurgicalHistorysRowProps {
  name: string;
  surgicaProcedure: PatientSurgery;
  delete: (surgicaProcedure: PatientSurgery) => void;
  edit: (surgicaProcedure: PatientSurgery) => void;
}
