import { type IntakeContentProps, type StyledTabProps } from '@/props'
import { SpeedDial, styled, Tab } from '@mui/material'

export const IntakeRightInnerPane = styled('div')(
  ({ isNavOpen, theme }: IntakeContentProps): any => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    flexWrap: 'nowrap',
    backgroundColor: theme.palette.background.paper,
    paddingTop: '16px',
    paddingBottom: '68px',
    paddingLeft: '166px',
    gap: '16px',
    flex: '1',
    overflowY: 'scroll',
    overflowX: 'none',
    maxHeight: '85%',
    width: '100vw',
    marginLeft: `${isNavOpen ? '362px' : '282px'}`,
  })
)
export const IntakeRightOutterPane = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 'none',
  height: '100%',
  justifyContent: 'flex-start',
  width: '100%',
  alignItems: 'center',
  alignContent: 'center',
})
export const ButtonRow = styled('div')({
  display: 'flex',
  height: '60px',
  gap: 8,
  padding: '4px',
  marginRight: '4px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: '#F7F8FC',
  overflow: 'hidden',
  position: 'fixed',
  bottom: 0,
  width: '100vw',
  zIndex: 8,
})
export const SubmittingModalContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  backgroundColor: 'white',
  padding: '16px',
  borderRadius: '8px',
  gap: '16px',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
})

export const StyledTab = styled(Tab)(({ isSelected }: StyledTabProps) => ({
  display: 'flex',
  padding: '12px',
  width: '318px',
  height: '44px !important',
  alignContent: 'start',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textTransform: 'capitalize',
  flexDirection: 'row',
  marginTop: '12px',
  backgroundColor: isSelected ? '#F7F8FC' : '#FFFFFF',
  border: '1px solid #DDDFE8',
  borderRadius: '8px',
}))

export const IntakeContent = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: theme.palette.background.paper,
  gap: 2,
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))
export const IntakeContainer = styled('div')({
  minHeight: '100%',
  width: '100%',
  overflow: 'auto',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

export const StyledSpeedDial = styled(SpeedDial)({
  position: 'fixed',
  bottom: '10px',
  right: '110px',
})

export const FormRow = styled('div')({
  width: '812px',
  display: 'flex',
  justifyContent: 'flex-end',
})
