import { type BadgeProps } from '@/types'
import { Box, Typography, styled } from '@mui/material'

export const AssignAction = styled(Box)({
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '110px',
  gap: '10px',
  '& div': {
    display: 'none',
  },
  '& p': {
    display: 'block',
  },
  '&:hover, &.hover': {
    '& .btn-assign-action, .btn-assign-provider-action': {
      display: 'flex',
    },
  },
})

export const StyledTableText = styled(Typography)({
  fontSize: '14px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

export const Tabs = styled('div')({
  display: 'flex',
})

export const StyledTab = styled('div')(({ theme }) => ({
  display: 'flex',
  textTransform: 'none',
  fontSize: '14px',
  lineHeight: '20px',
  marginRight: theme.spacing(1),
  color: 'black',
  borderRadius: '6px 6px 0 0',
  border: '1px solid #CFD8FD',
  borderBottom: '0px',
  padding: '8px 12px 8px 12px',
  fontWeight: '600',
  gap: '8px',
  cursor: 'pointer',
  '&.selected': {
    color: '#0543B0',
    backgroundColor: '#CFD8FD',
    borderTop: '3px solid #0543B0',
  },
}))

export const Space = styled('div')({
  flex: 1,
})

export const Badge = styled('div')(({ dark = false }: BadgeProps): any => ({
  color: dark ? 'white' : '#252D63',
  backgroundColor: dark ? '#0543B0' : '#E5EBFF',
  width: '22px',
  height: '22px',
  borderRadius: '50%',
  fontSize: '12px',
  fontWeight: 600,
  marginLeft: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

export const CountCircle = styled('div')(({ theme }): any => ({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  background: '#EAF3FF',

  fontWeight: 700,
  fontSize: '10x',
  lineHeight: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '252D63',
  marginTop: '-4px',
}))

export const DocumentName = styled(Typography)({
  fontSize: '14px',
  color: '#454545',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '150px',
  whiteSpace: 'nowrap',
})
