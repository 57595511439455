import React from 'react'

import { styled, Typography } from '@mui/material'
import { type DocumoFax } from '@/types'
import { ModalButton } from '@/components/Config'

const ConfirmationDialogContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '400px',
  padding: '24px',
  backgroundColor: theme.palette.background.default,
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  gap: 8,
}))

const DeleteVisitPopUpRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '10px',
})
const DialogTile = styled(Typography)({
  fontWeight: 600,
  fontSize: '18px',
  lineHeight: '26px',
  color: 'black',
})
export const ConfirmationDialog = ({
  fax,
  handleSend,
  handleClose,
}: ConfirmationDialogProps): JSX.Element => {
  return (
    <ConfirmationDialogContainer>
      <DialogTile>Confirmation</DialogTile>
      <Typography style={{ fontSize: '14px', marginBottom: '15px' }}>
        {`Send records to  ${fax.recipientName ?? ''}?`}
      </Typography>
      <DeleteVisitPopUpRow>
        <ModalButton
          variant="contained"
          color="secondary"
          onClick={handleClose}
        >
          Cancel
        </ModalButton>
        <ModalButton
          variant="contained"
          color="primary"
          onClick={() => handleSend(fax)}
        >
          Send
        </ModalButton>
      </DeleteVisitPopUpRow>
    </ConfirmationDialogContainer>
  )
}

interface ConfirmationDialogProps {
  fax: DocumoFax;
  handleSend: (fax: DocumoFax) => void;
  handleClose: any;
}
