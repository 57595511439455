import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import {
  type QueryObserverResult,
  useQuery,
  useMutation,
  type UseMutationResult,
} from '@tanstack/react-query'
import {
  getDSPharmacies,
  updatePharmacy,
  getUrgentIQPharmacy,
} from '@/services'
import { type Pharmacy } from '@/types/Pharmacy'

interface PharmacyState {
  dsPharmacies: Pharmacy[] | [];
  urgentIQPharmacy: Pharmacy | null;
  setDSPharmacies: (pharmacies: Pharmacy[]) => void;
  setUrgentIQPharmacy: (pharmacy: Pharmacy | null) => void;
}

export const usePharmacyStore = create<PharmacyState>()(
  devtools(
    persist(
      (set) => ({
        dsPharmacies: [],
        urgentIQPharmacy: null,
        setDSPharmacies: (pharmacies: Pharmacy[]) =>
          set({ dsPharmacies: pharmacies }),
        setUrgentIQPharmacy: (pharmacy: Pharmacy | null) =>
          set({ urgentIQPharmacy: pharmacy }),
      }),
      {
        name: 'pharmacy',
      }
    )
  )
)

export const useQueryGetDSPharmacies = (
  name: string,
  city: string,
  state: string,
  zip: string,
  address: string,
  phoneOrFax: string,
  specialtyId: number,
  clinicId: number,
  getToken: any
): QueryObserverResult<Pharmacy[], unknown> => {
  const { setDSPharmacies } = usePharmacyStore()

  return useQuery<Pharmacy[]>(
    ['ds-pharmacies', clinicId],
    async () =>
      await getDSPharmacies(
        name,
        city,
        state,
        zip,
        address,
        phoneOrFax,
        specialtyId,
        clinicId,
        getToken
      ),
    {
      enabled: !!clinicId,
      onSuccess: (data) => {
        setDSPharmacies(data)
      },
      initialData: [],
    }
  )
}

export const useMutateUpdatePharmacy = (
  getToken: any
): UseMutationResult<Pharmacy, unknown, Pharmacy | undefined> => {
  const { dsPharmacies, setDSPharmacies } = usePharmacyStore()

  return useMutation<Pharmacy, unknown, Pharmacy | undefined>(
    async (pharmacy) => await updatePharmacy(pharmacy, getToken),
    {
      onSuccess: (data) => {
        const newPharmacies = dsPharmacies.map(
          (pharmacy: Pharmacy): Pharmacy => {
            if (pharmacy?.id === data?.id) {
              return data
            }
            return pharmacy
          }
        )
        setDSPharmacies(newPharmacies)
      },
    }
  )
}

export const useQueryGetUrgentIQPharmacy = (
  pharmacyId: number,
  getToken: any
): QueryObserverResult<Pharmacy, unknown> => {
  const { setUrgentIQPharmacy } = usePharmacyStore()

  return useQuery<Pharmacy>(
    ['urgent-iq-pharmacy', pharmacyId],
    async () => await getUrgentIQPharmacy(pharmacyId, getToken),
    {
      enabled: !!pharmacyId,
      onSuccess: (data) => {
        setUrgentIQPharmacy(data)
      },
    }
  )
}
