import { styled } from '@mui/material'
import React from 'react'

const ModalRowContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 12,
  paddingTop: '16px',
})

export const ModalRow = ({ children }: ModalRowProps): JSX.Element => {
  return <ModalRowContainer>{children}</ModalRowContainer>
}

interface ModalRowProps {
  children: JSX.Element | JSX.Element[] | undefined;
}
